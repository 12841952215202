const IMAGES_DONTS = {
  BAT_CHE: require("./donts/BAT_CHE.jpg").default,
  BI_CHOI_SANG: require("./donts/BI_CHOI_SANG.jpg").default,
  CAY_CHE: require("./donts/CAY_CHE.jpg").default,
  CHUA_TO_HO: require("./donts/CHUA_TO_HO.jpg").default,
  CHUP_LAI_MAN_HINH_1: require("./donts/CHUP_LAI_MAN_HINH_1.jpg").default,
  CHUP_LAI_MAN_HINH: require("./donts/CHUP_LAI_MAN_HINH.png").default,
  CHUP_XA_CONG_TRINH: require("./donts/CHUP_XA_CONG_TRINH.jpg").default,
  GHI_CHU_QUA_NHIEU: require("./donts/GHI_CHU_QUA_NHIEU.jpg").default,
  LUOI_CHE: require("./donts/LUOI_CHE.jpg").default,
  MAT_CAT: require("./donts/MAT_CAT.jpg").default,
  MAT_BANG: require("./donts/MAT_BANG.jpg").default,
  NGUOC_SANG: require("./donts/NGUOC_SANG.jpg").default,
  PHOI_CANH_KEM_CHAT_LUONG: require("./donts/PHOI_CANH_KEM_CHAT_LUONG.jpg")
    .default,
  wrongMark: require("./donts/wrongmark.png").default,
};

export default IMAGES_DONTS;
