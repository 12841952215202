import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  FilterDateSideBarDiv,
  FilterDateAddtext,
  FilterDateAddUseImage,
  FilterDateDiv,
  ByTime,
  AdminFilterDateSearchDateDiv,
  SelectedDateCheck,
  SelectedDateText,
  DoneButton,
  DateRow,
  FilterDateModalBarDiv,
} from "../../styles/AdminDashboardStyles";
import dayjs from "dayjs";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardFilterSideBar = ({
  showFilter,
  setFromDate,
  setToDate,
  toggleShowFilter,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const times = [
    "Today",
    "Last 7 days",
    "Last 30 days",
    "Last Month",
    "Last year",
  ];

  const [selectedTime, setSelectedTime] = useState(null);
  const [tempFromDate, setTempFromDate] = useState(
    dayjs().format("DD/MM/YYYY")
  );
  const [tempToDate, setTempToDate] = useState(dayjs().format("DD/MM/YYYY"));

  const applyFilterHandler = () => {
    if (selectedTime === "Today") {
      setFromDate(dayjs().format("DD/MM/YYYY"));
      setToDate(dayjs().format("DD/MM/YYYY"));
    } else if (selectedTime === "Last 7 days") {
      setFromDate(dayjs().subtract(7, "day").format("DD/MM/YYYY"));
      setToDate(dayjs().format("DD/MM/YYYY"));
    } else if (selectedTime === "Last 30 days") {
      setFromDate(dayjs().subtract(30, "day").format("DD/MM/YYYY"));
      setToDate(dayjs().format("DD/MM/YYYY"));
    } else if (selectedTime === "Last Month") {
      setFromDate(
        dayjs().subtract(1, "month").startOf("month").format("DD/MM/YYYY")
      );
      setToDate(
        dayjs().subtract(1, "month").endOf("month").format("DD/MM/YYYY")
      );
    } else if (selectedTime === "Last year") {
      setFromDate(
        dayjs().subtract(1, "year").startOf("year").format("DD/MM/YYYY")
      );
      setToDate(dayjs().subtract(1, "year").endOf("year").format("DD/MM/YYYY"));
    } else if (selectedTime === "Custom") {
      setFromDate(dayjs(tempFromDate).format("DD/MM/YYYY"));
      setToDate(dayjs(tempToDate).format("DD/MM/YYYY"));
    }
    toggleShowFilter();
  };

  if (width < 1100) {
    return (
      <FilterDateModalBarDiv openSideMenu={showFilter}>
        <FilterDateDiv>
          <FilterDateAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <FilterDateAddtext><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FILTER_ANALYTICS"}
            /></FilterDateAddtext>
        </FilterDateDiv>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BY_TIME"}
            /> </ByTime>


        {times.map((value, index) => {
          return (
            <AdminFilterDateSearchDateDiv key={`${index}`}>
              <SelectedDateCheck
                src={
                  selectedTime === value
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => setSelectedTime(value)}
              />

{value == "Today" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TODAY"}
            /> </SelectedDateText>
            )}

{value == "Last 7 days" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_7_DAYS"}
            /> </SelectedDateText>
            )}


{value == "Last 30 days" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_30_DAYS"}
            /> </SelectedDateText>
            )}



{value == "Last Month" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_MONTH"}
            /> </SelectedDateText>
            )}


{value == "Last year" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_YEAR"}
            /> </SelectedDateText>
            )}
            </AdminFilterDateSearchDateDiv>
          );
        })}
        <AdminFilterDateSearchDateDiv style={{ marginTop: "20px" }}>
          <SelectedDateCheck
            src={
              selectedTime === "Custom"
                ? GREEN_CHECKBOX_CHECKED_IMAGE
                : GREEN_CHECKBOX_UNCHECKED_IMAGE
            }
            alt="Check"
            onClick={() => setSelectedTime("Custom")}
          />
          <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOM"}
            /></SelectedDateText>
        </AdminFilterDateSearchDateDiv>
        <DateRow>
          <div
            className="form-control"
            style={{ display: "flex", alignSelf: "center" }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={tempFromDate}
              onChange={(event) => setTempFromDate(event.target.value)}
            />
            <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FROM"}
            /></label>
            <div className="border-around"></div>
          </div>
          <div style={{ width: "30px" }} />
          <div
            className="form-control"
            style={{
              display: "flex",
              alignSelf: "center",
              margin: "0 20px",
            }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={tempToDate}
              onChange={(event) => setTempToDate(event.target.value)}
            />
            <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TO"}
            /></label>
            <div className="border-around"></div>
          </div>
        </DateRow>

        <DoneButton onClick={applyFilterHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
      </FilterDateModalBarDiv>
    );
  } else {
    return (
      <FilterDateSideBarDiv openSideMenu={showFilter}>
        <FilterDateDiv>
          <FilterDateAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <FilterDateAddtext><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FILTER_ANALYTICS"}
            /></FilterDateAddtext>
        </FilterDateDiv>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BY_TIME"}
            /></ByTime>
        {times.map((value, index) => {
          return (
            <AdminFilterDateSearchDateDiv key={`${index}`}>
              <SelectedDateCheck
                src={
                  selectedTime === value
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => setSelectedTime(value)}
              />
             {value == "Today" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TODAY"}
            /> </SelectedDateText>
            )}

{value == "Last 7 days" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_7_DAYS"}
            /> </SelectedDateText>
            )}


{value == "Last 30 days" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_30_DAYS"}
            /> </SelectedDateText>
            )}



{value == "Last Month" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_MONTH"}
            /> </SelectedDateText>
            )}


{value == "Last year" && (
              <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LAST_YEAR"}
            /> </SelectedDateText>
            )}
            </AdminFilterDateSearchDateDiv>
          );
        })}
        <AdminFilterDateSearchDateDiv style={{ marginTop: "20px" }}>
          <SelectedDateCheck
            src={
              selectedTime === "Custom"
                ? GREEN_CHECKBOX_CHECKED_IMAGE
                : GREEN_CHECKBOX_UNCHECKED_IMAGE
            }
            alt="Check"
            onClick={() => setSelectedTime("Custom")}
          />
          <SelectedDateText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOM"}
            /></SelectedDateText>
        </AdminFilterDateSearchDateDiv>
        <DateRow>
          <div
            className="form-control"
            style={{ display: "flex", alignSelf: "center" }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={tempFromDate}
              onChange={(event) => setTempFromDate(event.target.value)}
            />
            <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FROM"}
            /></label>
            <div className="border-around"></div>
          </div>
          <div style={{ width: "30px" }} />
          <div
            className="form-control"
            style={{
              display: "flex",
              alignSelf: "center",
              margin: "0 20px",
            }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={tempToDate}
              onChange={(event) => setTempToDate(event.target.value)}
            />
            <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TO"}
            /></label>
            <div className="border-around"></div>
          </div>
        </DateRow>

        <DoneButton onClick={applyFilterHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
      </FilterDateSideBarDiv>
    );
  }
};

export default AdminDashboardFilterSideBar;
