import React, { useEffect, useState } from "react";
import ARROW_RIGHT_IMAGE from "../../images/arrow-right.svg";
import DROPDOWN from "../../images/dropdown.svg";
import { MenuItem } from "../../styles/HomeStyles";
import {
  AKZONOBEL_URL,
  ID_ABOUT_DULUX_URL,
  ID_ACCESSIBLITY_URL,
  ID_CONTACT_US_URL,
  ID_DECORATION_IDEAS_URL,
  ID_DULUX_COLOURS_URL,
  ID_EXPERT_HELP_URL,
  ID_FIND_DULUX_STORE_URL,
  ID_PRODUCTS_URL,
  ID_SITEMAP_URL,
  ID_COLOUR_ACCURACY_URL,
  ML_ABOUT_DULUX_URL,
  ML_ACCESSIBLITY_URL,
  ML_COLOUR_ACCURACY_URL,
  ML_CONTACT_US_URL,
  ML_DECORATION_IDEAS_URL,
  ML_DULUX_COLOURS_URL,
  ML_EXPERT_HELP_URL,
  ML_FIND_DULUX_STORE_URL,
  ML_PRODUCTS_URL,
  ML_SITEMAP_URL,
  SG_ABOUT_DULUX_URL,
  SG_ACCESSIBLITY_URL,
  SG_COLOUR_ACCURACY_URL,
  SG_CONTACT_US_URL,
  SG_DECORATION_IDEAS_URL,
  SG_DULUX_COLOURS_URL,
  SG_EXPERT_HELP_URL,
  SG_FIND_DULUX_STORE_URL,
  SG_PRODUCTS_URL,
  SG_SITEMAP_URL,
  VIETNAM_ABOUT_DULUX_URL,
  VIETNAM_ACCESSIBLITY_URL,
  VIETNAM_COLOUR_ACCURACY_URL,
  VIETNAM_CONTACT_US_URL,
  VIETNAM_DECORATION_IDEAS_URL,
  VIETNAM_DULUX_COLOURS_URL,
  VIETNAM_EXPERT_HELP_URL,
  VIETNAM_FIND_DULUX_STORE_URL,
  VIETNAM_PRODUCTS_URL,
  VIETNAM_SITEMAP_URL,
  INDIA_EXPERT_HELP_URL,
  INDIA_ABOUT_DULUX_URL,
  INDIA_CONTACT_US_URL,
  INDIA_SITEMAP_URL,
  INDIA_DULUX_COLOURS_URL,
  INDIA_PRODUCTS_URL,
  INDIA_DECORATION_IDEAS_URL,
  INDIA_ACCESSIBLITY_URL,
  INDIA_COLOUR_ACCURACY_URL,
  INDIA_FIND_DULUX_STORE_URL,
  PAKISTAN_FIND_DULUX_STORE_URL,
  PAKISTAN_EXPERT_HELP_URL,
  PAKISTAN_ABOUT_DULUX_URL,
  PAKISTAN_CONTACT_US_URL,
  PAKISTAN_DULUX_COLOURS_URL,
  PAKISTAN_PRODUCTS_URL,
  PAKISTAN_DECORATION_IDEAS_URL,
  PAKISTAN_ACCESSIBLITY_URL,
  PAKISTAN_COLOUR_ACCURACY_URL,
  PAKISTAN_SITEMAP_URL,
  SRI_LANKA_FIND_DULUX_STORE_URL,
  SRI_LANKA_EXPERT_HELP_URL,
  SRI_LANKA_ABOUT_DULUX_URL,
  SRI_LANKA_CONTACT_US_URL,
  SRI_LANKA_DULUX_COLOURS_URL,
  SRI_LANKA_PRODUCTS_URL,
  SRI_LANKA_DECORATION_IDEAS_URL,
  SRI_LANKA_ACCESSIBLITY_URL,
  SRI_LANKA_COLOUR_ACCURACY_URL,
  SRI_LANKA_SITEMAP_URL,
  THAILAND_FIND_DULUX_STORE_URL,
  THAILAND_EXPERT_HELP_URL,
  THAILAND_ABOUT_DULUX_URL,
  THAILAND_CONTACT_US_URL,
  THAILAND_SITEMAP_URL,
  THAILAND_DULUX_COLOURS_URL,
  THAILAND_PRODUCTS_URL,
  THAILAND_DECORATION_IDEAS_URL,
  THAILAND_ACCESSIBLITY_URL,
  THAILAND_COLOUR_ACCURACY_URL,
  THAILAND_EXPERT_HELP_URL_SERVICE,
} from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const HomeSubFooter = ({ selectedCountry, width }) => {
  const [valueHeaderList, setHeaderList] = useState([]);

  useEffect(() => {
    setHeaderList([]);
    const countryValue = selectedCountry.value;
    const headerList =
      language["SUB_FOOTER_HEADERS"][
        countryValue.charAt(0).toLowerCase() + countryValue.slice(1)
      ];
    if (headerList == null) {
      setHeaderList(language["SUB_FOOTER_HEADERS"]["singapore"]);
    } else {
      setHeaderList(headerList);
    }
  }, [selectedCountry]);

  if (width < 1100) {
    return (
      <div
        style={{
          backgroundColor: "#012169",
          display: "flex",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        {valueHeaderList.map((item) => {
          return (
            <HomeSubFooterItem
              key={item[0]}
              title={item[0]}
              list={item[1]}
              selectedCountry={selectedCountry}
              width={width}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: "#012169",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "5vh 10vw",
        }}
      >
        {valueHeaderList.map((item, index) => {
          return (
            <HomeSubFooterItem
              key={index}
              title={item[0]}
              list={item[1]}
              index={index}
              selectedCountry={selectedCountry}
              width={width}
            />
          );
        })}
      </div>
    );
  }
};

const HomeSubFooterItem = ({ index, title, list, selectedCountry, width }) => {
  const menuClickHandler = (item) => {
    if (item === list[0] && index === 0) {
      if (selectedCountry.value === "Singapore") {
        return SG_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_ABOUT_DULUX_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_ABOUT_DULUX_URL;
      }
    } else if (item === list[1] && index === 0) {
      if (selectedCountry.value === "Singapore") {
        return SG_CONTACT_US_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_CONTACT_US_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_CONTACT_US_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_CONTACT_US_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_CONTACT_US_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_CONTACT_US_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_CONTACT_US_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_CONTACT_US_URL;
      }
    } else if (item === list[2] && index === 0) {
      if (selectedCountry.value === "Singapore") {
        return SG_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_FIND_DULUX_STORE_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_FIND_DULUX_STORE_URL;
      }
    } else if (item === list[3] && index === 0) {
      if (selectedCountry.value === "Singapore") {
        return SG_SITEMAP_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_SITEMAP_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_SITEMAP_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_SITEMAP_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_SITEMAP_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_SITEMAP_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_SITEMAP_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_SITEMAP_URL;
      }
    } else if (item === list[0] && index === 1) {
      if (selectedCountry.value === "Singapore") {
        return SG_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_DULUX_COLOURS_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_DULUX_COLOURS_URL;
      }
    } else if (item === list[1] && index === 1) {
      if (selectedCountry.value === "Singapore") {
        return SG_PRODUCTS_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_PRODUCTS_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_PRODUCTS_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_PRODUCTS_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_PRODUCTS_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_PRODUCTS_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_PRODUCTS_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_PRODUCTS_URL;
      }
    } else if (item === list[2] && index === 1) {
      if (selectedCountry.value === "Singapore") {
        return SG_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_DECORATION_IDEAS_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_DECORATION_IDEAS_URL;
      }
    } else if (item === list[3] && index === 1) {
      if (selectedCountry.value === "Singapore") {
        return SG_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_EXPERT_HELP_URL;
      }
    } else if (item === list[4] && index === 1) {
      if (selectedCountry.value === "Singapore") {
        return SG_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_EXPERT_HELP_URL_SERVICE;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_EXPERT_HELP_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_EXPERT_HELP_URL;
      }
    } else if (item === list[0] && index === 2) {
      if (selectedCountry.value === "Singapore") {
        return SG_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_ACCESSIBLITY_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_ACCESSIBLITY_URL;
      }
    } else if (item === list[1] && index === 2) {
      if (selectedCountry.value === "Singapore") {
        return SG_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Malaysia") {
        return ML_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Indonesia") {
        return ID_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Pakistan") {
        return PAKISTAN_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "SriLanka") {
        return SRI_LANKA_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Thailand") {
        return THAILAND_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "Vietnam") {
        return VIETNAM_COLOUR_ACCURACY_URL;
      } else if (selectedCountry.value === "India") {
        return INDIA_COLOUR_ACCURACY_URL;
      }
    } else if (item === "Dulux Professional") {
    } else if (item === "AkzoNobel") {
      return AKZONOBEL_URL;
    }
  };

  const [selectedDropDownItem, setSelectedDropDownItem] = useState("");

  if (width < 1100) {
    return (
      <>
        <div style={{ margin: "0 10px" }}>
          <div
            style={{
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontFamily: "Colour-Sans",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "24px",
                lineHeight: "29px",
                color: "#FFFFFF",
                margin: "20px 0px 5px 5px",
              }}
            >
              {title}
            </p>
            <img
              onClick={() => {
                setSelectedDropDownItem(title);
              }}
              src={DROPDOWN}
              alt="Dropdown"
              style={{ margin: "20px 0px 5px 5px" }}
            />
          </div>
          {selectedDropDownItem === title &&
            list.map((item) => {
              return (
                <div
                  key={item}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ARROW_RIGHT_IMAGE}
                    alt="Right Arrow"
                    style={{
                      width: "10px",
                      height: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <MenuItem onClick={() => menuClickHandler(item)}>
                    {item}
                  </MenuItem>
                </div>
              );
            })}
        </div>
        {title !== "Other AkzoNobel Sites" && <hr style={{ width: "100%" }} />}
      </>
    );
  } else {
    return (
      <div style={{ paddingBottom: "10px" }}>
        <p
          style={{
            fontFamily: "Colour-Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "24px",
            lineHeight: "29px",
            color: "#FFFFFF",
            margin: "20px 0 5px 0",
          }}
        >
          {title}
        </p>
        {list.map((item) => {
          return (
            <div
              key={item}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={ARROW_RIGHT_IMAGE}
                alt="Right Arrow"
                style={{ width: "10px", height: "10px", marginRight: "10px" }}
              />
              <a
                href={menuClickHandler(item)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <MenuItem>{item}</MenuItem>
              </a>
            </div>
          );
        })}
      </div>
    );
  }
};

export default HomeSubFooter;
