/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import AdminUserManageUser from "../../components/admin-users/AdminUserManageUser";
import AdminUsersAddUserSideBar from "../../components/admin-users/AdminUsersAddUserSideBar";
import AdminUsersList from "../../components/admin-users/AdminUsersList";
import { Context as AdminContext } from "../../context/AdminContext";
import {
  AdminUserBody,
  AdminUserTitleDiv,
  AdminUserImage,
  AdminUserText,
  HoirzontalStatsDivider,
  AdminUserHeader,
  AddMemberButton,
  HoirzontalCustomerDivider,
  FooterDiv,
  ErrorMessage,
  DownloadButton,
} from "../../styles/AdminUserStyles";
import GLOBAL_PREVIEW_TEAM_IMAGE from "../../images/global_preview_team.svg";
import AdminUserSearchBody from "../../components/admin-users/AdminUserSearchBody";
import AdminUsersPaginationList from "../../components/admin-users/AdminUsersPaginationList";
import AdminUserSearchRequest from "../../components/admin-users/AdminUserSearchRequest";
import LanguageSelector from "../../language/LanguageSelector";

const AdminUsers = ({
  addUser,
  manageUser,
  toggleAddUser,
  toggleManageUser,
  selectedUser,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const dropDownList = ["Latest First", "Oldest First"];

  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [textFilterName, setTextFilterName] = useState("");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    dropDownList[0]
  );
  const [selectedPage, setSelectedPage] = useState(1);
  const [paginationList, setPaginationList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [adminSearchRequest, setAdminSearchrequest] = useState(false);

  const toggleFilterDropDown = () =>
    setFilterDropDown((previousState) => !previousState);
  const toggleUserSearchRequest = () =>
    setAdminSearchrequest((previousState) => !previousState);

  const {
    adminAddUser,
    adminManageUser,
    adminDeleteUser,
    adminUsers,
    adminUserExcelDownload,
  } = useContext(AdminContext);

  const updatePagination = (tempRequests) => {
    setPaginationList([]);
    const division = Math.ceil(tempRequests.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  const adminUsersSuccessCallback = (data) => {
    setErrorMessage(null);
    setUsers(data.users);
    setTempUsers(data.users);
    updatePagination(data.users);
  };

  const adminUserFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  useEffect(() => {
    adminUsers({ adminUsersSuccessCallback, adminUserFailureCallback });
  }, []);

  const adminUserExcelSuccessCallback = (data) => {
    var blob = data;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "report.xlsx");
    } else {
      var downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      downloadLink.download = "report.xlsx";
      document.body.appendChild(downloadLink);
      window.open(downloadLink, "_blank");
    }
  };

  const adminUserExcelFailureCallback = (msg) => {
    alert(msg);
  };

  const downloadReport = () => {
    adminUserExcelDownload({
      adminUserExcelSuccessCallback,
      adminUserExcelFailureCallback,
    });
  };

  return (
    <>
      {addUser && (
        <AdminUsersAddUserSideBar
          toggleAddUser={toggleAddUser}
          adminAddUser={adminAddUser}
          adminUsers={adminUsers}
          adminUsersSuccessCallback={adminUsersSuccessCallback}
          adminUserFailureCallback={adminUserFailureCallback}
          errorMessage={errorMessage}
          width={width}
        />
      )}
      {adminSearchRequest && (
        <AdminUserSearchRequest
          openSideMenu={adminSearchRequest}
          toggleSearchRequest={toggleUserSearchRequest}
          users={users}
          setTempUsers={setTempUsers}
          setSelectedPage={setSelectedPage}
          setPaginationList={setPaginationList}
          width={width}
        />
      )}
      {manageUser && (
        <AdminUserManageUser
          toggleManageUser={toggleManageUser}
          adminManageUser={adminManageUser}
          adminDeleteUser={adminDeleteUser}
          user={selectedUser}
          adminUsers={adminUsers}
          adminUsersSuccessCallback={adminUsersSuccessCallback}
          adminUserFailureCallback={adminUserFailureCallback}
          errorMessage={errorMessage}
          width={width}
        />
      )}
      <AdminUserBody applyFilter={addUser || manageUser || adminSearchRequest}>
        <AdminUserHeader>
          <AdminUserTitleDiv>
            <AdminUserImage src={GLOBAL_PREVIEW_TEAM_IMAGE} />
            <AdminUserText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"GLOBAL_PREVIEW_TEAM"}
        /></AdminUserText>
          </AdminUserTitleDiv>
          <AddMemberButton onClick={toggleAddUser}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ADD_MEMBER"}
        /></AddMemberButton>
        </AdminUserHeader>
        <HoirzontalStatsDivider />
        <AdminUserSearchBody
          toggleUserSearchRequest={toggleUserSearchRequest}
          textFilterName={textFilterName}
          setTextFilterName={setTextFilterName}
          toggleFilterDropDown={toggleFilterDropDown}
          selectedFilterOption={selectedFilterOption}
          filterDropDown={filterDropDown}
          dropDownList={dropDownList}
          setSelectedFilterOption={setSelectedFilterOption}
        />
        <HoirzontalCustomerDivider />
        {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <AdminUsersList
          textFilterName={textFilterName}
          selectedFilterOption={selectedFilterOption}
          users={tempUsers.slice(
            selectedPage === 1 ? 0 : 15 * (selectedPage - 1),
            15 * selectedPage
          )}
          toggleManageUser={toggleManageUser}
          allUsers={tempUsers}
          setPaginationList={setPaginationList}
        />
        {users.length > 0 && (
          <FooterDiv>
            <AdminUsersPaginationList
              paginationList={paginationList}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />

            <DownloadButton onClick={downloadReport}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DOWNLOAD"}
        /></DownloadButton>
          </FooterDiv>
        )}
      </AdminUserBody>
    </>
  );
};

export default AdminUsers;
