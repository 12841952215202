import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ColorText,
  ModalPaleeteDiv,
  ToolTip,
  OriginalImageBodyText,
  ReferenceImageBodyContainer,
  ImageComparisonContainer,
  OriginalImageBodyContainer,
  ReferenceImageBodyText,
  PaletteBodyText,
  OriginalBodyImage,
  PaletteListDiv,
} from "../../styles/PreviewSupervisorPendingRequestThemeDetailStyles";
import LanguageSelector from "../../language/LanguageSelector";

const PendingRequestLeadColourDetailOriginal = ({ state }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <ImageComparisonContainer>
      <OriginalImageBodyContainer>
        <OriginalImageBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ORIGINAL_IMAGE"}
            /></OriginalImageBodyText>
        <OriginalBodyImage
          alt="Original"
          src={state.data && state.data?.result?.request_detail?.raw_image}
        />
      </OriginalImageBodyContainer>
      <ReferenceImageBodyContainer>
        <ReferenceImageBodyText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            />{`: ${
            state.data && state.data?.result?.request_detail?.colour_name
          }`}
        </ReferenceImageBodyText>
        {state.data?.colourSuggestion?.colour_suggestion_details
          ?.lead_colour && (
          <>
            <PaletteListDiv>
              <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEUTRAL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>
              <div key="netural_conbinations_1">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .lead_colour?.colour_code
                  }
                </ColorText>
              </div>
              <div key="netural_conbinations_2">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour1.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour1.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour1.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.neutral_colour1.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .neutral_colour1?.colour_code
                  }
                </ColorText>
              </div>
              <div key="netural_conbinations_3">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour2.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour2.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.neutral_colour2.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.neutral_colour2.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .neutral_colour2?.colour_code
                  }
                </ColorText>
              </div>
            </PaletteListDiv>
            <PaletteListDiv>
              <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TONAL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>

              <div key="tonal_conbinations_1">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .lead_colour?.colour_code
                  }
                </ColorText>
              </div>
              <div key="tonal_conbinations_2">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour1.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour1.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour1.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.tonal_colour1.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .tonal_colour1?.colour_code
                  }
                </ColorText>
              </div>
              <div key="tonal_conbinations_3">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour2.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour2.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.tonal_colour2.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.tonal_colour2.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .tonal_colour2?.colour_code
                  }
                </ColorText>
              </div>
            </PaletteListDiv>
            <PaletteListDiv>
              <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DESIGNERL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>

              <div key="designer_conbinations_1">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.lead_colour.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .lead_colour?.colour_code
                  }
                </ColorText>
              </div>
              <div key="designer_conbinations_2">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour1.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour1.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour1.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.designer_colour1.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .designer_colour1?.colour_code
                  }
                </ColorText>
              </div>
              <div key="designer_conbinations_3">
                <ModalPaleeteDiv
                  style={{
                    backgroundColor: `rgb(
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour2.red_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour2.green_value},
                      ${state.data.colourSuggestion.colour_suggestion_details.designer_colour2.blue_value}
                    )`,
                  }}
                >
                  <ToolTip>{`${state.data.colourSuggestion.colour_suggestion_details.designer_colour2.colour_name}`}</ToolTip>
                </ModalPaleeteDiv>
                <ColorText>
                  {
                    state.data.colourSuggestion.colour_suggestion_details
                      .designer_colour2?.colour_code
                  }
                </ColorText>
              </div>
            </PaletteListDiv>
          </>
        )}
      </ReferenceImageBodyContainer>
      {/* <ThemePaletteDiv>
          <ThemePaletteText>Theme Palette</ThemePaletteText>
         
        </ThemePaletteDiv> */}
    </ImageComparisonContainer>
  );
};

export default PendingRequestLeadColourDetailOriginal;
