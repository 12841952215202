import React from "react";
import {
  RangeSliderTextDiv,
  RangeSliderText,
  RangerInputSlider,
  RangeProgressBar,
  QuestionarrieText,
} from "../../styles/ConsumerWriteReviewStyles";

const ConsumerWriteReviewSlider = ({
  slider,
  setSlider,
  width,
  sliderMultipler,
  startingText,
  endingText,
  titleText,
  maxRange,
  textWidth,
}) => {
  return (
    <>
      <QuestionarrieText>{titleText}</QuestionarrieText>
      <RangeSliderTextDiv width={textWidth}>
        <RangeSliderText>{startingText}</RangeSliderText>
        <RangeSliderText>{endingText}</RangeSliderText>
      </RangeSliderTextDiv>
      <div style={{ position: "relative", width, marginBottom: "20px" }}>
        <RangerInputSlider
          type="range"
          min="0"
          max={maxRange}
          value={slider}
          onChange={(event) => {
            setSlider(event.target.value);
          }}
        />
        <RangeProgressBar sliderValue={slider * sliderMultipler} />
      </div>
    </>
  );
};

export default ConsumerWriteReviewSlider;
