import React from "react";
import {
  RecommendedProductsColumnDiv,
  BottomText,
  RecommendedProductsDiv,
  RecommendedProductsImage,
  RecommendedProductsText,
  RecommendedProductAnchor,
} from "../../styles/ConsumerDetailReportStyles";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportRecommendedProduct = ({ list, selectedCountry }) => {
  return (
    <RecommendedProductsColumnDiv>
      <BottomText>
        <LanguageSelector selectedCountry={selectedCountry} text={"RECOMMENDED_PRODUCTS"} />
      </BottomText>
      <RecommendedProductsDiv>
        {list !== undefined &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <RecommendedProductAnchor
                key={index}
                target="_blank"
                href={item?.product_url}
              >
              {item?
              <>
                <RecommendedProductsImage src={item.product_image} />
                <RecommendedProductsText>
                  {item.product_name}
                </RecommendedProductsText>
              </>  
              :null}
              </RecommendedProductAnchor>
            );
          })}
      </RecommendedProductsDiv>
    </RecommendedProductsColumnDiv>
  );
};

export default ConsumerDetailReportRecommendedProduct;
