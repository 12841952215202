import React from "react";
import { Link } from "react-router-dom";
import {
  ConsumerHeader,
  DeluxePreviewServiceImage,
  StepOneDiv,
  StepOneContainer,
  StepContainer,
  StepText,
  StpeSubTitle,
  StepTwoContainer,
  StepThreeContainer,
  StepTwoDiv,
  StepThreeDiv,
  LogoutButton,
} from "../../styles/ConsumerImageOptionStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import LanguageSelector from "../../language/LanguageSelector";
import BackButton from "../../components/BackButton";

const ConsumerImageOptionHeader = ({
  decodedToken,
  logOutHandler,
  width,
  selectedCountry,
  goBackHandler,
}) => {
  if (width < 1100) {
    return (
      <>
        <ConsumerHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          {decodedToken && (
            <LogoutButton onClick={logOutHandler}>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"LOGOUT"}
              />
            </LogoutButton>
          )}
        </ConsumerHeader>
        <BackButton goBackHandler={goBackHandler} />
        <StepContainer>
          <StepOneContainer>
            <StepOneDiv>
              <StepText>1</StepText>
            </StepOneDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"UPLOAD_IMAGE"}
              />
            </StpeSubTitle>
          </StepOneContainer>
          <StepTwoContainer>
            <StepTwoDiv>
              <StepText>2</StepText>
            </StepTwoDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CHOOSE_RE_COLOUR"}
              />
            </StpeSubTitle>
          </StepTwoContainer>
          <StepThreeContainer>
            <StepThreeDiv>
              <StepText>3</StepText>
            </StepThreeDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CONFIRM_REQUEST"}
              />
            </StpeSubTitle>
          </StepThreeContainer>
        </StepContainer>
      </>
    );
  } else {
    return (
      <ConsumerHeader>
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage
            src={DELUXE_PREVIEW_SERVICE_IMAGE}
            alt="Deluxe Preview"
          />
        </Link>
        <StepContainer>
          <StepOneContainer>
            <StepOneDiv>
              <StepText>1</StepText>
            </StepOneDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"UPLOAD_IMAGE"}
              />
            </StpeSubTitle>
          </StepOneContainer>
          <StepTwoContainer>
            <StepTwoDiv>
              <StepText>2</StepText>
            </StepTwoDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CHOOSE_RE_COLOUR"}
              />
            </StpeSubTitle>
          </StepTwoContainer>
          <StepThreeContainer>
            <StepThreeDiv>
              <StepText>3</StepText>
            </StepThreeDiv>
            <StpeSubTitle>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CONFIRM_REQUEST"}
              />
            </StpeSubTitle>
          </StepThreeContainer>
        </StepContainer>
        {decodedToken && (
          <LogoutButton onClick={logOutHandler}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"LOGOUT"}
            />
          </LogoutButton>
        )}
      </ConsumerHeader>
    );
  }
};

export default ConsumerImageOptionHeader;
