import React, { useState, useRef, useEffect } from "react";
import {
  HOME_PAGE_ROUTE,
  ID_CHOOSE_PRODUCT_URL,
  ID_EXPERT_HELP_URL,
  ID_FIND_COLOUR_URL,
  ID_FIND_STORE_URL,
  ID_IDEAS_URL,
  INDONESIA_HOME_PAGE_ROUTE,
  MALAYSIA_HOME_PAGE_ROUTE,
  SINGAPORE_HOME_PAGE_ROUTE,
  PAKISTAN_HOME_PAGE_ROUTE,
  SRILANKA_HOME_PARGE_ROUTE,
  THAILAND_HOME_PAGE_ROUTE,
  VIETNAM_HOME_PAGE_ROUTE,
  INDIA_HOME_PAGE_ROUTE,
  ML_CHOOSE_PRODUCT_URL,
  ML_EXPERT_HELP_URL,
  ML_FIND_COLOUR_URL,
  ML_FIND_STORE_URL,
  ML_IDEAS_URL,
  SG_CHOOSE_PRODUCT_URL,
  SG_EXPERT_HELP_URL,
  SG_FIND_COLOUR_URL,
  SG_FIND_STORE_URL,
  SG_IDEAS_URL,
  PAKISTAN_CHOOSE_PRODUCT_URL,
  PAKISTAN_EXPERT_HELP_URL,
  PAKISTAN_FIND_COLOUR_URL,
  PAKISTAN_FIND_STORE_URL,
  PAKISTAN_IDEAS_URL,
  SRI_LANKA_CHOOSE_PRODUCT_URL,
  SRI_LANKA_EXPERT_HELP_URL,
  SRI_LANKA_FIND_COLOUR_URL,
  SRI_LANKA_FIND_STORE_URL,
  SRI_LANKA_IDEAS_URL,
  THAILAND_CHOOSE_PRODUCT_URL,
  THAILAND_EXPERT_HELP_URL,
  THAILAND_FIND_COLOUR_URL,
  THAILAND_FIND_STORE_URL,
  THAILAND_IDEAS_URL,
  INDIA_CHOOSE_PRODUCT_URL,
  INDIA_EXPERT_HELP_URL,
  INDIA_FIND_COLOUR_URL,
  INDIA_FIND_STORE_URL,
  INDIA_IDEAS_URL,
  VIETNAM_CHOOSE_PRODUCT_URL,
  VIETNAM_EXPERT_HELP_URL,
  VIETNAM_FIND_COLOUR_URL,
  VIETNAM_FIND_STORE_URL,
  VIETNAM_IDEAS_URL,
} from "../../utils/Constants";
import Select from "react-select";
import {
  Header,
  DeluxePreviewServiceImage,
  AkzoNobelContainer,
  AkzonobelImage,
  HeaderOptions,
  HeaderOptionsText,
  HeaderLoginButton,
  HeaderLoginText,
  CountryText,
  HamburgerHeader,
} from "../../styles/HomeStyles";
import "../../styles/header.css";
import { Link } from "react-router-dom";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import AKZONOBEL_IMAGE from "../../images/akzonobel.png";
import DROP_DOWN_IMAGE from "../../images/drop_down.svg";
import LOGIN_IMAGE from "../../images/login.svg";
import HAMBURGER_IMAGE from "../../images/hamburger_menu.svg";
import LanguageSelector from "../../language/LanguageSelector";

const HomeHeader = ({
  toggleSideMenu,
  setSelectedCountry,
  selectedCountry,
  showLogin,
  width,
  history,
}) => {
  const options = [
    { value: "Singapore", label: "Singapore" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "SriLanka", label: "Sri Lanka" },
    { value: "Thailand", label: "Thailand" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "India", label: "India" },
  ];

  const selectedCountryChange = (selectedOption) => {
    if (selectedOption.value === "Singapore") {
      history.push(SINGAPORE_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Malaysia") {
      history.push(MALAYSIA_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Indonesia") {
      history.push(INDONESIA_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Pakistan") {
      history.push(PAKISTAN_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "SriLanka") {
      history.push(SRILANKA_HOME_PARGE_ROUTE);
    } else if (selectedOption.value === "Thailand") {
      history.push(THAILAND_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Vietnam") {
      history.push(VIETNAM_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "India") {
      history.push(INDIA_HOME_PAGE_ROUTE);
    }
    setSelectedCountry(selectedOption);
    localStorage.setItem("country", JSON.stringify(selectedOption));
  };

  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [findColorUrl, setFindColorUrl] = useState(SG_FIND_COLOUR_URL);
  const [chooseProductUrl, setChooseProductUrl] = useState(
    SG_CHOOSE_PRODUCT_URL
  );
  const [ideasUrl, setIdeasUrl] = useState(SG_IDEAS_URL);
  const [expertHelpUrl, setExpertHelpUrl] = useState(SG_EXPERT_HELP_URL);
  const [findStoreUrl, setFindStoreUrl] = useState(SG_FIND_STORE_URL);

  const toggleMenu = () => setShowMenu((previousState) => !previousState);

  const ref = useRef(null);
  // const handleScroll = () => {
  //   if (ref.current) {
  //     setSticky(ref.current.getBoundingClientRect().top <= 0);
  //   }
  // };

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  useEffect(() => {
    if (selectedCountry.value === "Singapore") {
      setFindColorUrl(SG_FIND_COLOUR_URL);
      setChooseProductUrl(SG_CHOOSE_PRODUCT_URL);
      setIdeasUrl(SG_IDEAS_URL);
      setExpertHelpUrl(SG_EXPERT_HELP_URL);
      setFindStoreUrl(SG_FIND_STORE_URL);
    } else if (selectedCountry.value === "Malaysia") {
      setFindColorUrl(ML_FIND_COLOUR_URL);
      setChooseProductUrl(ML_CHOOSE_PRODUCT_URL);
      setIdeasUrl(ML_IDEAS_URL);
      setExpertHelpUrl(ML_EXPERT_HELP_URL);
      setFindStoreUrl(ML_FIND_STORE_URL);
    } else if (selectedCountry.value === "Indonesia") {
      setFindColorUrl(ID_FIND_COLOUR_URL);
      setChooseProductUrl(ID_CHOOSE_PRODUCT_URL);
      setIdeasUrl(ID_IDEAS_URL);
      setExpertHelpUrl(ID_EXPERT_HELP_URL);
      setFindStoreUrl(ID_FIND_STORE_URL);
    } else if (selectedCountry.value === "Pakistan") {
      setFindColorUrl(PAKISTAN_FIND_COLOUR_URL);
      setChooseProductUrl(PAKISTAN_CHOOSE_PRODUCT_URL);
      setIdeasUrl(PAKISTAN_IDEAS_URL);
      setExpertHelpUrl(PAKISTAN_EXPERT_HELP_URL);
      setFindStoreUrl(PAKISTAN_FIND_STORE_URL);
    } else if (selectedCountry.value === "SriLanka") {
      setFindColorUrl(SRI_LANKA_FIND_COLOUR_URL);
      setChooseProductUrl(SRI_LANKA_CHOOSE_PRODUCT_URL);
      setIdeasUrl(SRI_LANKA_IDEAS_URL);
      setExpertHelpUrl(SRI_LANKA_EXPERT_HELP_URL);
      setFindStoreUrl(SRI_LANKA_FIND_STORE_URL);
    } else if (selectedCountry.value === "Thailand") {
      setFindColorUrl(THAILAND_FIND_COLOUR_URL);
      setChooseProductUrl(THAILAND_CHOOSE_PRODUCT_URL);
      setIdeasUrl(THAILAND_IDEAS_URL);
      setExpertHelpUrl(THAILAND_EXPERT_HELP_URL);
      setFindStoreUrl(THAILAND_FIND_STORE_URL);
    } else if (selectedCountry.value === "Vietnam") {
      setFindColorUrl(VIETNAM_FIND_COLOUR_URL);
      setChooseProductUrl(VIETNAM_CHOOSE_PRODUCT_URL);
      setIdeasUrl(VIETNAM_IDEAS_URL);
      setExpertHelpUrl(VIETNAM_EXPERT_HELP_URL);
      setFindStoreUrl(VIETNAM_FIND_STORE_URL);
    } else if (selectedCountry.value === "India") {
      setFindColorUrl(INDIA_FIND_COLOUR_URL);
      setChooseProductUrl(INDIA_CHOOSE_PRODUCT_URL);
      setIdeasUrl(INDIA_IDEAS_URL);
      setExpertHelpUrl(INDIA_EXPERT_HELP_URL);
      setFindStoreUrl(INDIA_FIND_STORE_URL);
    }
  }, [selectedCountry]);

  if (width < 1100) {
    return (
      <div>
        <Header
          className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
          ref={ref}
        >
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview Service "
            />
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Select
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <img
                    src={DROP_DOWN_IMAGE}
                    alt="Country select"
                    style={{
                      marginRight: "3px",
                      marginBottom: width < 500 ? "18px" : "0px",
                    }}
                  />
                ),
              }}
              value={selectedCountry}
              onChange={selectedCountryChange}
              options={options}
              styles={{
                singleValue: (styles) => ({
                  color: "#012169",
                }),
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "white",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "150%",
                  color: "#012169",
                  minHeight: 28,
                  height: 28,
                  cursor: "pointer",
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: "white",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  // fontWeight: "600",
                  fontSize: "10px",
                  // lineHeight: "150%",
                  color: "#012169",
                  cursor: "pointer",
                }),
              }}
            />
            <HeaderLoginButton onClick={toggleSideMenu}>
              <img
                src={LOGIN_IMAGE}
                alt="Login"
                style={{
                  width: "30px",
                  height: "30px",
                  // marginRight: "10px",
                  // marginLeft: "10px",
                  objectFit: "fill",
                }}
              />
            </HeaderLoginButton>
            <HeaderLoginButton onClick={toggleMenu}>
              <img
                src={HAMBURGER_IMAGE}
                alt="Login"
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  objectFit: "fill",
                }}
              />
            </HeaderLoginButton>
          </div>
        </Header>
        {showMenu && (
          <HamburgerHeader>
            <a
              href={findColorUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HeaderOptionsText>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"FIND_A_COLOUR"}
                />
              </HeaderOptionsText>
            </a>
            <a
              href={chooseProductUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HeaderOptionsText>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"CHOOSE_A_PRODUCT"}
                />
              </HeaderOptionsText>
            </a>
            <a
              href={ideasUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HeaderOptionsText>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"IDEAS"}
                />
              </HeaderOptionsText>
            </a>
            <a
              href={expertHelpUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HeaderOptionsText>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"EXPERT_HELP"}
                />
              </HeaderOptionsText>
            </a>
            <a
              href={findStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HeaderOptionsText>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"FIND_A_STORE"}
                />
              </HeaderOptionsText>
            </a>
          </HamburgerHeader>
        )}
      </div>
    );
  } else {
    return (
      // <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
      <Header
        className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
        ref={ref}
      >
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage
            src={DELUXE_PREVIEW_SERVICE_IMAGE}
            alt="Deluxe Preview Service "
          />
        </Link>
        <div style={{ flex: 1 }}>
          <AkzoNobelContainer>
            <CountryText>Country</CountryText>
            <div style={{ width: "8vw" }}>
              <Select
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => (
                    <img
                      src={DROP_DOWN_IMAGE}
                      alt="Country select"
                      style={{ paddingRight: "5px" }}
                    />
                  ),
                }}
                value={selectedCountry}
                onChange={selectedCountryChange}
                options={options}
                styles={{
                  singleValue: (styles) => ({
                    color: "#012169",
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#012169",
                    minHeight: 28,
                    height: 28,
                    cursor: "pointer",
                  }),
                  option: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#012169",
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <AkzonobelImage src={AKZONOBEL_IMAGE} alt="Akzonobel" />
          </AkzoNobelContainer>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <HeaderOptions>
              <a
                href={findColorUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <HeaderOptionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"FIND_A_COLOUR"}
                  />
                </HeaderOptionsText>
              </a>
              <a
                href={chooseProductUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <HeaderOptionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"CHOOSE_A_PRODUCT"}
                  />
                </HeaderOptionsText>
              </a>
              <a
                href={ideasUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <HeaderOptionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"IDEAS"}
                  />
                </HeaderOptionsText>
              </a>
              <a
                href={expertHelpUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <HeaderOptionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"EXPERT_HELP"}
                  />
                </HeaderOptionsText>
              </a>
              <a
                href={findStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <HeaderOptionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"FIND_A_STORE"}
                  />
                </HeaderOptionsText>
              </a>
            </HeaderOptions>
            <div
              style={{
                flex: "0.55",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {showLogin && (
                <HeaderLoginButton onClick={toggleSideMenu}>
                  <img src={LOGIN_IMAGE} alt="Login" />
                  <HeaderLoginText>
                    <LanguageSelector
                      selectedCountry={selectedCountry}
                      text={"LOGIN"}
                    />
                  </HeaderLoginText>
                </HeaderLoginButton>
              )}
            </div>
          </div>
        </div>
      </Header>
      // </div>
    );
  }
};

export default HomeHeader;
