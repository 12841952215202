/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import AdminCustomerPaginationList from "../../components/admin-customer/AdminCustomerPaginationList";
import AdminCustomerTable from "../../components/admin-customer/AdminCustomerTable";
import { Context as AdminContext } from "../../context/AdminContext";
import {
  AdminCustomerBody,
  AdminCustomerTitleDiv,
  AdminCustomersImage,
  AdminCustomersText,
  HoirzontalStatsDivider,
  HoirzontalCustomerDivider,
  FooterDiv,
  ErrorMessage,
  DownloadButton,
} from "../../styles/AdminCustomersStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Context as AuthContext } from "../../context/AuthContext";
import BLACK_STAR_IMAGE from "../../images/black_star.svg";
import AdminCustomerSearchBody from "../../components/admin-customer/AdminCustomerSearchBody";
import AdminCustomerSearchRequests from "../../components/admin-customer/AdminCustomerSearchRequests";
import AdminConsumerOtpScreen from "../../components/admin-customer/AdminConsumerOtpScreen";
import { TOKEN_NAME } from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector";

const AdminCustomers = ({ width }) => {
  const dropDownList = ["Latest First", "Oldest First"];

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const { verifyEmail, verifyAdminEmailOtp } = useContext(AuthContext);

  const [selectedPage, setSelectedPage] = useState(1);
  const [textFilterName, setTextFilterName] = useState("");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    dropDownList[0]
  );
  const [requestList, setRequestList] = useState([]);
  const [tempRequestList, setTempRequestList] = useState([]);
  const [paginationList, setPaginationList] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [customerSearchRequest, setCustomerSearchRequest] = useState(false);
  const [showOtpSideBar, setShowOtpSideBar] = useState(true);

  const toggleFilterDropDown = () =>
    setFilterDropDown((previousState) => !previousState);
  const toggleCustomerSearchRequest = () =>
    setCustomerSearchRequest((previousState) => !previousState);

  const { adminCustomersList, adminConsumerExcelDownload } =
    useContext(AdminContext);

  const updatePagination = (tempRequests) => {
    setPaginationList([]);
    const division = Math.ceil(tempRequests.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  const adminCustomersSuccessCallback = (data) => {
    setRequestList(data.consumers);
    setTempRequestList(data.consumers);
    updatePagination(data.consumers);
  };

  const adminCustomerFailureCallback = (msg) => {
    setErrorMsg(msg);
  };

  useEffect(() => {
    if (!showOtpSideBar) {
      adminCustomersList({
        adminCustomersSuccessCallback,
        adminCustomerFailureCallback,
      });
    }
  }, [showOtpSideBar]);

  const adminConsumerExcelSuccessCallback = (data) => {
    var blob = data;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "report.xlsx");
    } else {
      var downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      downloadLink.download = "report.xlsx";
      document.body.appendChild(downloadLink);
      window.open(downloadLink, "_blank");
    }
  };

  const adminConsumerFailureCallback = (msg) => {
    alert(msg);
  };

  const downloadReport = () => {
    adminConsumerExcelDownload({
      adminConsumerExcelSuccessCallback,
      adminConsumerFailureCallback,
    });
  };

  return (
    <>
      {customerSearchRequest && (
        <AdminCustomerSearchRequests
          openSideMenu={customerSearchRequest}
          toggleCustomerSearchRequest={toggleCustomerSearchRequest}
          requestList={requestList}
          setTempRequestList={setTempRequestList}
          setPaginationList={setPaginationList}
          setSelectedPage={setSelectedPage}
          width={width}
        />
      )}
      {showOtpSideBar && (
        <AdminConsumerOtpScreen
          email={decodedToken?.email}
          showOtpSideBar={showOtpSideBar}
          setShowOtpSideBar={setShowOtpSideBar}
          verifyEmail={verifyEmail}
          verifyAdminEmailOtp={verifyAdminEmailOtp}
          width={width}
        />
      )}
      <AdminCustomerBody applyFilter={customerSearchRequest || showOtpSideBar}>
        <AdminCustomerTitleDiv>
          <AdminCustomersImage src={BLACK_STAR_IMAGE} />
          <AdminCustomersText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMERS"}
        /></AdminCustomersText>
        </AdminCustomerTitleDiv>
        <HoirzontalStatsDivider />
        <AdminCustomerSearchBody
          textFilterName={textFilterName}
          setTextFilterName={setTextFilterName}
          toggleCustomerSearchRequest={toggleCustomerSearchRequest}
          toggleFilterDropDown={toggleFilterDropDown}
          selectedFilterOption={selectedFilterOption}
          filterDropDown={filterDropDown}
          dropDownList={dropDownList}
          setSelectedFilterOption={setSelectedFilterOption}
        />

        <HoirzontalCustomerDivider />
        {errorMsg != null && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <AdminCustomerTable
          // customers={tempRequestList.slice(
          //   selectedPage === 1 ? 0 : 15 * (selectedPage - 1),
          //   15 * selectedPage
          // )}
          selectedFilterOption={selectedFilterOption}
          textFilterName={textFilterName}
          setPaginationList={setPaginationList}
          allCustomers={tempRequestList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
        {tempRequestList.length > 0 && (
          <FooterDiv>
            <AdminCustomerPaginationList
              paginationList={paginationList}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />

            <DownloadButton onClick={downloadReport}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DOWNLOAD"}
        /></DownloadButton>
          </FooterDiv>
        )}
      </AdminCustomerBody>
    </>
  );
};

export default AdminCustomers;
