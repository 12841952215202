import { language } from "./language";
import { BodyText } from "../styles/BodyText";
const LanguageSelector = ({ selectedCountry, text }) => {
  var return_text;
  // var
  if (
    selectedCountry?.value === "Singapore" ||
    selectedCountry === "Singapore"
  ) {
    return_text = language[text]["singapore"];
  } else if (
    selectedCountry?.value === "Indonesia" ||
    selectedCountry === "Indonesia"
  ) {
    return_text = language[text]["indonesia"];
  } else if (
    selectedCountry?.value === "Thailand" ||
    selectedCountry === "Thailand"
  ) {
    return_text = language[text]["thailand"];
  } else if (
    selectedCountry?.value === "Vietnam" ||
    selectedCountry === "Vietnam"
  ) {
    return_text = language[text]["vietnam"];
  } else if (
    selectedCountry?.value === "India" ||
    selectedCountry === "India"
  ) {
    return_text = language[text]["india"] || language[text]["singapore"];
  } else if (
    selectedCountry?.value === "Malaysia" ||
    selectedCountry === "Malaysia"
  ) {
    return_text = language[text]["malaysia"] || language[text]["singapore"];
  } 
  else {
    console.log("error language selector");
    return_text = language[text]["singapore"];
  }

  return <BodyText>{return_text}</BodyText>;
};

export default LanguageSelector;
