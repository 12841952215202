import styled from "styled-components";

export const ConsumerHomeContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100px;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const StepContainer = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 1vh 20vw 0 0;
  @media (max-width: 1100px) {
    margin: 20px 0;
  }
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const AppropriateTextStyle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #545850;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 10vh 0;
`;

export const SituationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20vw;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    padding: 0 10px;
  }
`;

// border-bottom: ${(props) => props.border || "1px solid black"};

export const SituationContainer = styled.button`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  outline: none;
  border: none;
  align-items: center;
  padding-top: 5vh;
  height: ${(props) => (props.isComingSoon ? "30vh" : "31vh")};
  width: 12vw;
  padding-left: 0;
  padding-right: 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 45%;
    margin: 10px 10px;
  }
`;

export const SituationImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 10px;
`;

export const SituationText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #545850;
  margin: 15px 0px;
`;

export const ComingSoonDiv = styled.div`
  background: #d7d4cb;
  display: flex;
  /* align-self: flex-end; */
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const ComingSoonText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #000000;
`;
