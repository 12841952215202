import React from "react";
import {
  InteriorExteriorDiv,
  InteriorButton,
  ExteriorButton,
  FilterImage,
  InteriorExteriorTextDiv,
  FilterOptionDiv,
  ThemeDiv,
  FilterOptionText,
  FilterDiv,
} from "../../styles/ConsumerReferenceImageStyles";
import FILTER_IMAGE from "../../images/filter.svg";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { TOKEN_NAME } from "../../utils/Constants";
import { language } from "../../language/language";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerReferenceImageTheme = ({
  interiorThemeHandler,
  selectedTheme,
  exteriorThemeHandler,
  toggleFilterApplied,
  filterApplied,
  interiorFilterList,
  exteriorFilterList,
  selectedFilter,
  setSelectedFilter,
  selectedCountry,
  imageDescription
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem('country'));
  let lowercase_country = countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
  const interiorFilterClickHandler = (item) => {
    setSelectedFilter({
      theme: "Interior",
      filterName: item,
    });
  };

  const exteriorFilterClickHandler = (item) => {
    setSelectedFilter({
      theme: "Exterior",
      filterName: item,
    });
  };

  return (
    <ThemeDiv>
      <InteriorExteriorDiv>
        <InteriorExteriorTextDiv>
          <InteriorButton
            onClick={interiorThemeHandler}
            selectedTheme={selectedTheme === language["INTERIOR"][lowercase_country] ? true : false}
          >
            <LanguageSelector
              selectedCountry={
                countryValue
              }
              text={"INTERIOR"}
            />
          </InteriorButton>
          {/* DISABLED INDIA CHANGE */}
          {/* {(decodedToken.country || countryValue.value) !== "India" ? */}
            <ExteriorButton
              onClick={exteriorThemeHandler}
              selectedTheme={selectedTheme === language["EXTERIOR"][lowercase_country] ? true : false}
            >
             <LanguageSelector
                selectedCountry={
                  countryValue
                }
                text={"EXTERIOR"}
              />
            </ExteriorButton> 
          {/* : null} */}
        </InteriorExteriorTextDiv>
        <FilterImage
          src={FILTER_IMAGE}
          onClick={toggleFilterApplied}
          alt="Filter"
        />
      </InteriorExteriorDiv>
      {filterApplied && (
        <FilterDiv>
          {selectedTheme === language["INTERIOR"][lowercase_country]
            ? interiorFilterList.map((item, index) => {
              console.log(item, interiorFilterList, interiorFilterList.item, interiorFilterList[4])
                return (
                  <FilterOptionDiv
                  selectedReset={item == language["RESET"][lowercase_country] ? true : false}
                    key={`${index}`}
                    filterSelected={
                      selectedFilter.theme === language["INTERIOR"][lowercase_country] &&
                      selectedFilter.filterName === item
                    }
                    onClick={() => {
                      interiorFilterClickHandler(item);
                    }}
                  >
                    <FilterOptionText selectedReset={item == language["RESET"][lowercase_country] ? true : false}>{item.replace(/Calm|Bold|Playful|Restful|Bedroom|Study Room|Living Room/g, m => imageDescription[m])}</FilterOptionText>
                  </FilterOptionDiv>
                );
              })
            : exteriorFilterList.map((item, index) => {
                return (
                  <FilterOptionDiv
                  selectedReset={item == language["RESET"][lowercase_country] ? true : false}
                    key={`${index}`}
                    filterSelected={
                      selectedFilter.theme === language["INTERIOR"][lowercase_country] &&
                      selectedFilter.filterName === item
                    }
                    onClick={() => {
                      exteriorFilterClickHandler(item);
                    }}
                  >
                    <FilterOptionText selectedReset={item == language["RESET"][lowercase_country] ? true : false}>{item.replace(/Calm|Bold|Playful|Restful|Bedroom|Study Room|Living Room/g, m => imageDescription[m])}</FilterOptionText>
                  </FilterOptionDiv>
                );
              })}
        </FilterDiv>
      )}
    </ThemeDiv>
  );
};

export default ConsumerReferenceImageTheme;