import React from "react";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import CANCEL_IMAGE from "../../images/cancel.svg";
import {
  ModalPaleeteDiv,
  ColorText,
  ToolTip,
  ResultModal,
  ResultBodyModal,
  CloseModalImage,
  LeftArrowImage,
  ModalImageDiv,
  ModalImage,
  ModalPalette,
  ModalImageDownloadButton,
  RightArrowImage,
  ResultBottomModalDiv,
} from "../../styles/PreviewSupervisorResultStyles";

const AdminPSResultModal = ({
  toggleModal,
  previousModalImageClickHandler,
  state,
  selectedModalIndex,
  downloadSingleImageHandler,
  nextModalImageClickHandler,
  width,
}) => {
  if (width < 1100) {
    return (
      <ResultBottomModalDiv>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "60px 0 0 0 ",
            }}
          >
            <LeftArrowImage
              src={GREEN_ARROW_IMAGE}
              onClick={previousModalImageClickHandler}
            />
            <ModalImage
              src={
                state.data?.result?.report_details?.results[selectedModalIndex]
                  .result_image
              }
            />
            <RightArrowImage
              src={GREEN_ARROW_IMAGE}
              onClick={nextModalImageClickHandler}
            />
          </div>
          <ModalImageDiv>
            <ModalPalette>
              {state.data?.result?.report_details?.results[
                selectedModalIndex
              ].colours.map((item2, index2) => {
                return (
                  <div key={`${index2}`}>
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>{item2?.colour_code}</ColorText>
                  </div>
                );
              })}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  state.data?.result?.report_details?.results[
                    selectedModalIndex
                  ].result_image
                );
              }}
            >
              Download Image
            </ModalImageDownloadButton>
          </ModalImageDiv>
        </ResultBodyModal>
      </ResultBottomModalDiv>
    );
  } else {
    return (
      <ResultModal>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
          <LeftArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={previousModalImageClickHandler}
          />
          <ModalImageDiv>
            <ModalImage
              src={
                state.data?.result?.report_details?.results[selectedModalIndex]
                  .result_image
              }
            />
            <ModalPalette>
              {state.data?.result?.report_details?.results[
                selectedModalIndex
              ].colours.map((item2, index2) => {
                return (
                  <div key={`${index2}`}>
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>{item2?.colour_code}</ColorText>
                  </div>
                );
              })}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  state.data?.result?.report_details?.results[
                    selectedModalIndex
                  ].result_image
                );
              }}
            >
              Download Image
            </ModalImageDownloadButton>
          </ModalImageDiv>
          <RightArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={nextModalImageClickHandler}
          />
        </ResultBodyModal>
      </ResultModal>
    );
  }
};

export default AdminPSResultModal;
