import React, {  } from "react";
import {
  ToolTip,
  PaletteListDiv,
  PaletteDiv,
  OriginalRefernceImageDiv,
  OriginalBodyColumn,
  ReferenceBodyColumn,
  OriginalReferenceImageText,
  OriginalReferenceImage,
} from "../../styles/ConsumerDetailReportStyles";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportOriginal = ({ state, selectedCountry }) => {
  console.log(state.data?.result)
  return (
    <OriginalRefernceImageDiv>
      <OriginalBodyColumn>
        <OriginalReferenceImageText><LanguageSelector selectedCountry={selectedCountry} text={"ORIGINAL"} /></OriginalReferenceImageText>
        <OriginalReferenceImage
          src={state.data && state.data?.result?.request_details?.raw_image}
        />
      </OriginalBodyColumn>

      <ReferenceBodyColumn>
        <OriginalReferenceImageText>
          
        {state.data &&
            (state.data?.result?.request_details?.colourization_type == "Theme" ? <LanguageSelector selectedCountry={selectedCountry} text={"THEME"} /> 
            : ((state.data?.result?.request_details?.colourization_type == "Reference")? 
            <LanguageSelector selectedCountry={selectedCountry} text={"REFERENCE"} />:<LanguageSelector selectedCountry={selectedCountry} text={"LEAD_COLOUR"} /> ))}
        </OriginalReferenceImageText>
        {/* if */}
        {state.data?.result?.request_details?.colourization_type === "Theme" ? 
        // do
        (
          <>
            {state.data?.palette?.palette_1_colours.length > 0 && (
              <PaletteListDiv>
                {state.data?.palette?.palette_1_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
                {state.data?.palette?.palette_2_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
                {state.data?.palette?.palette_3_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
              </PaletteListDiv>
            )}
          </>
        )  
        // else
        :( 
            // if
            (state.data?.result?.request_details?.colourization_type === "Lead Colour" ? 
            // do
            (
              <>
              
                          <PaletteDiv
                            style={{
                              backgroundColor: 
                              //"red"
                              `rgb(${state.data?.result?.report_details?.results[0]?.colours[0]?.red_value},${state.data?.result?.report_details?.results[0]?.colours[0]?.green_value},${state.data?.result?.report_details?.results[0]?.colours[0]?.blue_value})`,
                            }}
                          >
                            <ToolTip>{`${state.data?.result?.request_details?.colour_name}`}</ToolTip>
                          </PaletteDiv>
                 </>
            ) 
            // else
              : <OriginalReferenceImage
            src={
              state.data && state.data?.result?.request_details?.reference_image
            }
          /> ))}
      </ReferenceBodyColumn>
    </OriginalRefernceImageDiv>
  );
};

export default ConsumerDetailReportOriginal;
