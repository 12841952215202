/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ReassignRequestSideBar,
  CancelImage,
  MyTeamSideBarHeaderDiv,
  MyTeamSideBearHeaderImage,
  MyTeamSideBarHeaderText,
  ErrorMessage,
  ReassignRequestModalBarDiv,
} from "../../styles/AdminRequestStyles";
import styled from "styled-components";
import "../../styles/floatingLabelInput.css";
import Select from "react-select";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardReassingRequestSideBar = ({
  openReAssignRequestMenu,
  toggleOpenReAssignRequestMenu,
  reassignRequest,
  selectedItem,
  getAdminRequests,
  adminTeam,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const selectedCountry= decodedToken
      ? {
          value: decodedToken?.country,
          label: decodedToken?.country,
        }
      : countryValue
  const selectText = selectedCountry.value == "Vietnam" ? "Lựa chọn..." : "Select...";

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const reassignRequestFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const reassignRequestSuccessCallback = () => {
    toggleOpenReAssignRequestMenu();
    getAdminRequests();
  };

  const adminTeamSuccessCallback = (data) => {
    data.users.map((user) => {
      return setUsers((oldArray) => [
        ...oldArray,
        { value: user.email, label: user.email },
      ]);
    });
  };

  const saveHandler = () => {
    if (
      selectedUser?.value === undefined &&
      selectedUser?.value === null &&
      selectedUser?.value === false
    ) {
      setErrorMessage("Select a member");
    } else {
      reassignRequest({
        request_id: selectedItem.request_id,
        email: selectedUser.value,
        reassignRequestFailureCallback,
        reassignRequestSuccessCallback,
      });
    }
  };

  useEffect(() => {
    adminTeam({
      adminTeamSuccessCallback,
    });
  }, []);

  if (width < 1100) {
    return (
      <ReassignRequestModalBarDiv openSideMenu={openReAssignRequestMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleOpenReAssignRequestMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REASSIGN_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <TitleSubTitleDiv>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></TitleText>
          <SubTitleText>{selectedItem?.request_id}</SubTitleText>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOMER"}
            /></TitleText>
          <SubTitleText>{selectedItem?.email}</SubTitleText>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOLOUR_TYPE"}
            /></TitleText>
          <SubTitleText>{selectedItem?.colourization_type}</SubTitleText>
          <Select
            value={selectedUser}
            placeholder={selectText}
            onChange={setSelectedUser}
            options={users}
            styles={{
              singleValue: (styles) => ({
                color: "#012169",
              }),
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
              }),
              option: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
              }),
            }}
          />
          {errorMessage != null && (
            <ErrorMessage style={{ marginTop: "10px" }}>
              {errorMessage}
            </ErrorMessage>
          )}
          <ButtonBar>
            <CancelButton onClick={toggleOpenReAssignRequestMenu}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CANCEL_LOGOUT"}
            />
            </CancelButton>
            <SaveButton type="submit" onClick={saveHandler}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SAVE"}
            />
            </SaveButton>
          </ButtonBar>
        </TitleSubTitleDiv>
      </ReassignRequestModalBarDiv>
    );
  } else {
    return (
      <ReassignRequestSideBar openSideMenu={openReAssignRequestMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleOpenReAssignRequestMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REASSIGN_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <TitleSubTitleDiv>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></TitleText>
          <SubTitleText>{selectedItem?.request_id}</SubTitleText>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOMER"}
            /></TitleText>
          <SubTitleText>{selectedItem?.email}</SubTitleText>
          <TitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOLOUR_TYPE"}
            /></TitleText>
          <SubTitleText>{selectedItem?.colourization_type}</SubTitleText>
          <Select
            value={selectedUser}
            placeholder={selectText}
            onChange={setSelectedUser}
            options={users}
            styles={{
              singleValue: (styles) => ({
                color: "#012169",
              }),
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
              }),
              option: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
              }),
            }}
          />
          {errorMessage != null && (
            <ErrorMessage style={{ marginTop: "10px" }}>
              {errorMessage}
            </ErrorMessage>
          )}
          <ButtonBar>
            <CancelButton onClick={toggleOpenReAssignRequestMenu}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CANCEL_LOGOUT"}
            />
            </CancelButton>
            <SaveButton type="submit" onClick={saveHandler}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SAVE"}
            />
            </SaveButton>
          </ButtonBar>
        </TitleSubTitleDiv>
      </ReassignRequestSideBar>
    );
  }
};

const TitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 30px 0 0 0;
`;

const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0 0 30px 0;
`;

const TitleSubTitleDiv = styled.div`
  margin: 20px 40px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  padding: 6px 30px;
  color: #545850;
  outline: none;
`;

const SaveButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 6px 32px;
  outline: none;
  border: none;
  margin: 0px 20px;
`;

export default AdminDashboardReassingRequestSideBar;
