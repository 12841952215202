import React from "react";
import {
  PaginationDiv,
  PaginationLeftImage,
  PaginationNumberDiv,
  PaginationRightImage,
  PaginationArrowContainer,
} from "../../styles/PreviewStaffDashboardStyles";
// import PAGINATION_LEFT_IMAGE from "../../images/pagination_left.svg";
// import PAGINATION_RIGHT_IMAGE from "../../images/pagination_right.svg";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";

const PreviewStaffDashboardPagination = ({
  paginationList,
  selectedPageForResolved,
  selectedPageForPending,
  setSelectedPageForPending,
  setSelectedPageForResolved,
  pendingClicked,
}) => {
  const rightIncrementHandler = () => {
    if (pendingClicked) {
      if (selectedPageForPending <= paginationList.length) {
        selectedPageForPending++;
        setSelectedPageForPending(selectedPageForPending);
      }
    } 
    else {
      if (selectedPageForResolved <= paginationList.length) {
        selectedPageForResolved++;
        setSelectedPageForResolved(selectedPageForResolved);
      }
    }
  };

  const leftDecrementHandler = () => {
    if (pendingClicked) {
      if (selectedPageForPending > 0) {
        selectedPageForPending--;
        setSelectedPageForPending(selectedPageForPending);
      }
    } 
    else {
      if (selectedPageForResolved > 0) {
        selectedPageForResolved--;
        setSelectedPageForResolved(selectedPageForResolved);
      }
    }
    console.log(selectedPageForResolved)
  };

  const LeftArrow = () => {
      return (
        <PaginationArrowContainer>
          <PaginationLeftImage
          onClick={leftDecrementHandler}
          src={BLACK_ARROW_IMAGE}
          alt="Pagination left"
          />
        </PaginationArrowContainer>
      );
  }

  const RightArrow = () => {
    return (
      <PaginationArrowContainer>
        <PaginationRightImage
        onClick={rightIncrementHandler}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination right"
        />
      </PaginationArrowContainer>
    );
  }

  const ShowLeftArrow = () => {
    if (pendingClicked) {
      if (selectedPageForPending > 1) {
        return <LeftArrow/>;
      }
    } 
    else {
      if (selectedPageForResolved > 1) {
        return <LeftArrow/>;
      }
    } 
    return <PaginationArrowContainer/>
  }

  const ShowRightArrow = () => {
    if (pendingClicked) {
      if (selectedPageForPending < paginationList.length) {
        return <RightArrow/>;
      }
    }
    else {
      if (selectedPageForResolved < paginationList.length) {
        return <RightArrow/>;
      }
    } 
    return <PaginationArrowContainer/>
  }

  return (
    <PaginationDiv>
      <ShowLeftArrow/>
      <PaginationNumber
        key={ pendingClicked ? selectedPageForPending 
            : selectedPageForResolved }
        selectedPage={ pendingClicked ? selectedPageForPending 
                      : selectedPageForResolved } 
        paginationList={paginationList}
        setSelectedPage={
          pendingClicked
            ? setSelectedPageForPending
            : setSelectedPageForResolved
        }
      />
      <ShowRightArrow/>
    </PaginationDiv>
  );
};

const PaginationNumber = ({
  paginationList,
  setSelectedPage,
  selectedPage,
}) => {
  return (
    <>
    <div style={{flexDirection: "row", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
      {paginationList.map((item, index) => {
        return (
          <PaginationNumberDiv
            key={`${index}`}
            onClick={() => setSelectedPage(index + 1)}
            isSelected={selectedPage === index + 1}
          >
            {index + 1}
          </PaginationNumberDiv>
        );
      })}
      </div>
    </>
  );
};

export default PreviewStaffDashboardPagination;
