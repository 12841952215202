import styled from "styled-components";

export const PreviewSupervisorSelectorContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PaginationArrowContainer = styled.div`
  width : 40px;
  height : 20px;
  color : #ffffff;
`;

export const PaginationLeftImage = styled.img`
  padding: 0 10px;
  display: 'none';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  :hover {
    cursor: pointer;
  }
`;

export const PaginationRightImage = styled.img`
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const PreviewSupervisorSelectorHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  /* width: 120px;
  height: 60px; */
  /* @media (max-width: 1500px) {
    visibility: hidden;
    padding: 0px;
  } */
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const PreviewSupervisorSelectorBodyContainer = styled.div`
  display: flex;
  margin: 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: start;
  margin: 60px 0;
  height: calc(100vh - 10px);
`;

export const VerticalDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1500px) {
    padding: 0px;
  }
  margin: 20px 0;
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  margin: 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const ChosseProductText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  margin: 10px 10px 0 10px;
`;


export const ConsumerDetailsText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #666666;
  margin: 20px 20px 0 20px;
`;

export const ConsumerDetailsDiv = styled.p`
display: flex;
flex-direction: row;
@media (max-width: 1100px) {
  flex-direction: column;
}
`;

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const RecommendedProductsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 10px 0 0 0;
`;

export const RecommendedProductsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RecommendedProductCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  padding: 4px 10px;
`;

export const RecommendedProductImage = styled.img`
  width: 100px;
  height: 100px;
  :hover {
    cursor: pointer;
  }
`;

export const RecommendedProductText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0;
  max-width: 100px;
  word-wrap: break-word;
  :hover {
    cursor: pointer;
  }
`;

export const SelectDiv = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const SelectCheckBox = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const SelectCheckBoxText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #012169;
  margin: 0 10px;
`;

export const NearByStoresText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin: 10px 0 0 0;
  color: #000000;
`;

export const NearByStoresDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NearByStoresContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const NearByStoresImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const NearByTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export const NearByTitle = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const NearBySubTitle = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
`;

export const RecommendedProductsBodyDiv = styled.div`
  margin: 0px 0 0 20px;
  flex: 2;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
`;

export const BackButton = styled.button`
  outline: none;
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  margin: 0 20px 0 0;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const NextButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;
