import React from "react";
import {
  HowItWorksContainer,
  HowItWorksSubTitleText,
  HowItWorksText,
  HowItWorksContainerColumn,
} from "../../styles/HomeStyles";
import HomeWorkComponent from "./HomeWorkComponent";
import MAGIC_HAPPENS_IMAGE from "../../images/magic_happens.svg";
import CHOOSE_METHOD_IMAGE from "../../images/choose_method.svg";
import GET_RESULTS_IMAGE from "../../images/get_results.svg";
import GET_STARTED_IMAGE from "../../images/get_started.svg";
import LanguageSelector from "../../language/LanguageSelector"

const HomeHowItWorks = ({ width, selectedCountry }) => {
  if (width < 1100) {
    return (
      <div
        style={{
          backgroundColor: "#F9F9F9",
          padding: "20px",
          margin: 0,
        }}
      >
        <HowItWorksText>
          <LanguageSelector selectedCountry={selectedCountry} text={"HOW_IT_WORKS"}/>
        </HowItWorksText>
        <HowItWorksSubTitleText>
          <LanguageSelector selectedCountry={selectedCountry} text={"GET_FREE_AND_PERSONALISED_small"}/>
        </HowItWorksSubTitleText>
        <HowItWorksContainerColumn>
          <HomeWorkComponent
            image={GET_STARTED_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"UPLOAD_IMAGE_OF_YOUR_small"}/>
              </>
            }
            width={width}
          />
          <HomeWorkComponent
            image={CHOOSE_METHOD_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"CHOOSE_A_METHOD"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"UPLOAD_A_REFERENCE_IMAGE_small"}/>
              </>
            }
            width={width}
          />
          <HomeWorkComponent
            image={MAGIC_HAPPENS_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"MAGIC_HAPPENS"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"OUR_DULUX_COLOUR_EXPERTS_small"}/>
              </>
            }
            width={width}
          />
          <HomeWorkComponent
            image={GET_RESULTS_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"GET_RESULTS"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"GET_A_PREVIEW_small"}/>
              </>
            }
            width={width}
          />
        </HowItWorksContainerColumn>
      </div>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: "#e5e5e5",
          padding: "8vh 5vw 4vh 10vw",
          margin: 0,
        }}
      >
        <HowItWorksText>
          <LanguageSelector selectedCountry={selectedCountry} text={"HOW_IT_WORKS"}/>
        </HowItWorksText>
        <HowItWorksSubTitleText>
          <LanguageSelector selectedCountry={selectedCountry} text={"GET_FREE_AND_PERSONALISED"}/>
        </HowItWorksSubTitleText>
        <HowItWorksContainer>
          <HomeWorkComponent
            image={GET_STARTED_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"UPLOAD_IMAGE_OF_YOUR"}/>
              </>
            }
          />
          <HomeWorkComponent
            image={CHOOSE_METHOD_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"CHOOSE_A_METHOD"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"UPLOAD_A_REFERENCE_IMAGE"}/>
              </>
            }
          />
          <HomeWorkComponent
            image={MAGIC_HAPPENS_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"MAGIC_HAPPENS"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"OUR_DULUX_COLOUR_EXPERTS"}/>
              </>
            }
          />
          <HomeWorkComponent
            image={GET_RESULTS_IMAGE}
            title={<LanguageSelector selectedCountry={selectedCountry} text={"GET_RESULTS"}/>}
            subTitle={
              <>
                <LanguageSelector selectedCountry={selectedCountry} text={"GET_A_PREVIEW"}/>
              </>
            }
          />
        </HowItWorksContainer>
      </div>
    );
  }
};

export default HomeHowItWorks;
