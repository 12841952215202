import React from "react";
import {
  ShadesContainer,
  OptionText,
  ColorPaletteText,
  ColorPaletteDivContainer,
  ColorPaletteDiv,
  ToolTip,
  SelectColourButton,
} from "../../styles/PreviewSupervisorTouchUpStyles";

const PreviewSupervisorTouchUpShades = ({
  selectedColourFromDrawer,
  useOptionOne,
  toggleOptionOne,
  toggleOptionTwo,
  toggleSideMenu,
}) => {

  const onClickHandler = () =>{
    toggleOptionOne();
    toggleOptionTwo();
  };

  return (
    <ShadesContainer>
      {useOptionOne ? null:
      <div style={{
        content: "",
        position: "absolute",
        backgroundColor: "#ccc",
        filter: "blur(5px)",
        marginTop: "250px",
        zIndex:0,
      }}>
      {/* <ColorPaletteDivContainer>
        {selectedColourFromDrawer !== null && (
          <ColorPaletteDiv
            style={{
              backgroundColor: `rgb(${selectedColourFromDrawer.red_value},${selectedColourFromDrawer.green_value},${selectedColourFromDrawer.blue_value})`,
            }}
          >
            <ToolTip>{selectedColourFromDrawer.colour_name}</ToolTip>
          </ColorPaletteDiv>
        )}
        <SelectColourButton onClick={toggleSideMenu}>
          Select colour
        </SelectColourButton>
      </ColorPaletteDivContainer> */}
      </div>}
    </ShadesContainer>
  );
};

export default PreviewSupervisorTouchUpShades;
