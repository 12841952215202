import React from "react";
import {
  PrivacyPolicyContainer,
  PrivacyPolicyHeader,
  DeluxePreviewServiceImage,
  PrivacyPolicyBody,
  PrivacyPolicyHeaderText,
  PrivacyPolicyText,
  BodyText,
  BoldBodyText,
  CookieBodySpacer,
} from "../../styles/PrivacyPolicyStyles";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";

const PrivacyPolicyBodyThailand = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyBody>
        <PrivacyPolicyHeaderText>นโยบายความเป็นส่วนตัว</PrivacyPolicyHeaderText>
        <PrivacyPolicyText>นโยบายความเป็นส่วนตัว</PrivacyPolicyText>
        <BodyText>
          เราตระหนักดีว่าความเป็นส่วนตัวของคุณมีความสำคัญอย่างยิ่งและเราให้ความสำคัญกับเรื่องนี้
          นโยบายความเป็นส่วนตัวนี้ (“นโยบายความเป็นส่วนตัว”)
          อธิบายถึงนโยบายและกระบวนการของเราในการรวบรวม ใช้
          และเปิดเผยข้อมูลของคุณเมื่อคุณใช้งานเว็บไซต์
          เราจะไม่ใช้หรือแบ่งปันข้อมูลของคุณกับผู้อื่น
          ยกเว้นตามที่ได้อธิบายไว้ในนโยบายความเป็นส่วนตัวนี้
          นโยบายความเป็นส่วนตัวนี้เป็นส่วนหนึ่งและรวมไว้ภายในข้อกำหนดการใช้งาน
          (“ข้อกำหนด”) ซึ่งต้องอ่านควบคู่กัน
          คำที่ใช้ตัวอักษรพิมพ์ใหญ่ในนโยบายความเป็นส่วนตัวนี้
          แต่ไม่ได้ให้คำนิยามไว้ในที่นี้
          จะมีความหมายตามที่ได้ให้ไว้กับคำดังกล่าวในข้อกำหนดนี้
          นโยบายความเป็นส่วนตัวนี้มีผลบังคับใช้เมื่อใด
          นโยบายความเป็นส่วนตัวนี้มีผลบังคับใช้กับ (ก) ผู้ใช้ที่ลงทะเบียนแล้ว
          และ (ข) ทุกคน (รวมถึงบุคคลธรรมดา)
          นอกเหนือจากผู้ใช้ที่ลงทะเบียนแล้วที่เข้าถึงเว็บไซต์โดยตรงหรือโดยอ้อม
          เราประมวลผลข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ใด
          เราใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ต่างๆ
          คุณสามารถดูข้อมูลสรุปเกี่ยวกับประเภทของข้อมูลส่วนบุคคลและวัตถุประสงค์ที่เราใช้ข้อมูลส่วนบุคคลได้ที่ด้านล่าง
          เราประมวลผลข้อมูลทางเทคนิค เช่น ที่อยู่ IP ของคุณ เบราว์เซอร์ที่คุณใช้
          เพจที่คุณเข้าชม
          เว็บไซต์ที่คุณเข้าชมก่อนหน้า/หลังจากนี้และระยะเวลาการเข้าชมเพื่อปรับปรุงฟังก์ชันการทำงานของเว็บไซต์และช่วยให้คุณสามารถค้นหาข้อมูลที่ต้องการได้อย่างง่ายดายและรวดเร็ว
          ข้อมูลดังกล่าวยังถูกนำมาใช้ในการแก้ไขปัญหาทางเทคนิคหรือเพื่อปรับปรุงความสามารถในการเข้าถึงบางส่วนของเว็บไซต์
          นอกจากนี้ เรายังใช้คุกกี้เพื่อวัตถุประสงค์นี้
          เมื่อคุณเลือกที่จะลงทะเบียนกับเรา คุณจำเป็นต้องให้ข้อมูลส่วนบุคคล เช่น
          ชื่อของคุณ ที่อยู่อีเมลที่ถูกต้อง ที่อยู่บ้านหรือสำนักงาน
          และข้อมูลที่เกี่ยวข้องอื่นๆ เพื่อให้เราสามารถสร้างบัญชีให้กับคุณได้
          ข้อมูลส่วนบุคคลของคุณจะช่วยให้เราสามารถจัดการบัญชี
          รับประกันถึงการรักษาความลับ และทำให้เข้าถึงได้อย่างปลอดภัย นอกจากนี้
          เรายังประมวลผลข้อมูล เช่น ชื่อที่แสดงของคุณ บทบาทของคุณ
          และข้อมูลการเข้าถึงของคุณ ตัวอย่างเช่น
          เราสามารถเปลี่ยนรหัสผ่านให้คุณได้ อย่างไรก็ตาม
          เราไม่สามารถดูรหัสผ่านของคุณได้
          เราใช้ข้อมูลส่วนบุคคลรวมในการวิเคราะห์พฤติกรรมของผู้เข้าชมและปรับเปลี่ยนข้อเสนอของเราให้สอดคล้องกันเพื่อให้แน่ใจว่าจะสัมพันธ์กับลูกค้าของเรา
          ซึ่งหมายความว่าเราวิเคราะห์ว่าคุณเข้าชมแอป/เว็บไซต์ของเราบ่อยเพียงใด
          จากข้อมูลนี้ เราสามารถปรับเปลี่ยนข้อเสนอและจดหมายข่าวได้ นอกจากนี้
          เราอาจทำการสำรวจแนวโน้มตลาดผ่านการวิเคราะห์ทางสถิติเพื่อประเมินและดัดแปลงผลิตภัณฑ์และโฆษณาของเราตามการพัฒนาใหม่ๆ
          แต่ผลการสำรวจจะเปิดเผยเป็นข้อมูลรวมเท่านั้น
          เราอาจซื้อข้อมูลเสริมจากแหล่งข้อมูลสาธารณะเพื่อเสริมฐานข้อมูลของเราโดยมีวัตถุประสงค์ตามที่ได้อธิบายไว้ด้านบน
          เราสามารถส่งอีเมลที่มีโปรโมชัน ข่าวสาร และคำเชิญให้ร่วมกิจกรรมได้
          หากคุณเลือกที่จะเข้าร่วมกิจกรรมใดๆ เหล่านี้
          เราต้องการข้อมูลส่วนบุคคลของคุณเพื่อให้สามารถโฆษณาและจัดกิจกรรมดังกล่าวได้
          ด้วยเหตุนี้ เราจึงประมวลผลชื่อ ที่อยู่ ที่อยู่อีเมลของคุณ
          และการเข้าร่วมกิจกรรมของคุณ เราอาจโทรและ/หรือส่งข้อความ SMS
          เพื่อรวบรวมรายละเอียดของการใช้งานหรือประสบการณ์การใช้งานแพลตฟอร์ม/เว็บไซต์ของคุณ
          รวมถึงรายละเอียดของผลิตภัณฑ์ที่ใช้สำหรับบริการสี
          คุณยอมรับและยินยอมว่าเราอาจรวบรวม จัดการ ประมวลผล ใช้ จัดเก็บ ถ่ายโอน
          แลกเปลี่ยน แบ่งปัน เผยแพร่ ทำลาย หรือใช้งานข้อมูลส่วนบุคคลของคุณ
          แม้ว่าจะมีข้อความขัดแย้ง
          แต่คุณยังยอมรับและยินยอมโดยปราศจากความเคลือบคลุมสงสัยว่าเราอาจใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ที่ให้ไว้ด้านบนและแบ่งปันข้อมูลส่วนบุคคลดังกล่าวโดยไม่จำกัดกับบุคคลภายนอกในลักษณะที่อธิบายไว้ด้านล่าง
        </BodyText>
        <br />
        <BoldBodyText>ผู้ใดสามารถเข้าถึงข้อมูลของคุณได้?</BoldBodyText>
        <br />
        <BodyText>
          เราปฏิบัติต่อข้อมูลของคุณด้วยความระมัดระวังและเก็บรักษาเป็นความลับ
          และเราไม่แบ่งปันข้อมูลดังกล่าวกับบุคคลภายนอกที่ไม่ได้ระบุไว้โดยชัดแจ้งในนโยบายความเป็นส่วนตัวนี้
          • บริษัทอื่นๆ ภายในกลุ่มอั๊คโซ่ โนเบล:
          เราอาจแบ่งปันข้อมูลเกี่ยวกับผู้บริโภคของเราโดยใช้นามแฝง ไม่ระบุชื่อ
          หรือในรูปแบบข้อมูลรวม กับบริษัทอื่นๆ ในกลุ่มอั๊คโซ่ โนเบล ตัวอย่างเช่น
          เพื่อใช้ในการวิเคราะห์แนวโน้ม
          โปรดทราบว่าข้อมูลนี้อาจถูกถ่ายโอนและ/หรือถูกโฮสต์ออกจากประเทศไทยไปยังสถานที่หลายแห่ง
          • ผู้ให้บริการ: เราใช้บริษัทหลายแห่งซึ่งประมวลผลข้อมูลในนามของเรา เช่น
          บริษัทโฮสต์ บริการจัดส่ง บริษัทเหล่านี้ใช้ข้อมูลของคุณในนามของเรา
          และต้องปฏิบัติตามกฎที่เข้มงวดเมื่อประมวลผลข้อมูลของคุณและมาตรฐานที่คล้ายคลึงกันในการปกป้องข้อมูลเช่นเดียวกับเรา
          เราไม่อนุญาตให้บริษัทดังกล่าวใช้ข้อมูลในลักษณะอื่นใดที่ไม่ได้เป็นไปตามคำสั่งของเรา
          • บุคคลภายนอกอื่นๆ:
          เราอาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลตามคำขอของหน่วยงานรัฐหรือเพื่อรักษาความสงบเรียบร้อยของประชาชน
          นอกจากนี้
          เราอาจแบ่งปันข้อมูลของคุณกับพันธมิตรด้านช่องทางจัดจำหน่ายของเรา
          ซึ่งรวมถึงผู้จัดจำหน่าย ตัวแทนจำหน่าย และ/หรือผู้ค้าปลีก
          เราจะแบ่งปันข้อมูลของคุณกับบริษัทหรือบุคคลอื่นเมื่อเราได้รับความยินยอมจากคุณให้ดำเนินการได้เท่านั้น
          วิธีการจัดการกับลิงก์ไปยังเว็บไซต์อื่นๆ และโซเชียลมีเดีย
        </BodyText>
        <br />
        <CookieBodySpacer>
          <BoldBodyText>
            เว็บไซต์ภายนอก
            <br />
          </BoldBodyText>
          <BodyText>
            ในเว็บไซต์นี้ คุณอาจพบลิงก์ต่างๆ ไปยังเว็บไซต์อื่น
            แม้ว่าเว็บไซต์เหล่านี้จะได้รับการคัดเลือกอย่างระมัดระวังแล้ว
            แต่เราจะไม่รับผิดชอบต่อการประมวลผลข้อมูลส่วนบุคคลของคุณผ่านเว็บไซต์อื่นๆ
            ดังกล่าว ดังนั้น
            ข้อกำหนดและนโยบายความเป็นส่วนตัวนี้จะไม่มีผลบังคับใช้กับการใช้งานเว็บไซต์ดังกล่าว
          </BodyText>
          <BoldBodyText>
            โซเชียลมีเดีย
            <br />
          </BoldBodyText>
          <BodyText>
            เว็บไซต์นี้อาจมีการใช้โซเชียลปลั๊กอินจากโซเชียลมีเดียต่างๆ
            หากคุณเลือกที่จะติดต่อกับเครือข่ายสังคม (ตัวอย่างเช่น
            ด้วยการลงทะเบียนบัญชี)
            กิจกรรมของคุณบนเว็บไซต์นี้จะปรากฏบนเครือข่ายสังคม เช่น Facebook และ
            Twitter ด้วยเช่นกัน หากคุณเชื่อมต่อกับเครือข่ายสังคมใดๆ
            เหล่านี้ในระหว่างที่เข้าชมเว็บไซต์
            เครือข่ายสังคมนั้นจะสามารถเพิ่มข้อมูลดังกล่าวไว้บนโปรไฟล์ที่เกี่ยวข้องบนแพลตฟอร์มนั้นได้
            หากคุณกำลังติดต่อกับโซเชียลปลั๊กอินใดๆ
            ข้อมูลนั้นจะถูกถ่ายโอนไปยังเครือข่ายสังคม
            หากคุณไม่ต้องการให้มีการถ่ายโอนข้อมูลดังกล่าว
            ให้ยกเลิกการเชื่อมต่อกับเครือข่ายสังคมของคุณก่อนเข้าสู่เว็บไซต์ของเรา
            เราปกป้องข้อมูลอย่างไร
            ข้อมูลส่วนบุคคลของคุณจะได้รับการรักษาเป็นความลับอย่างเคร่งครัด
            และเราใช้มาตรการรักษาความปลอดภัยในเชิงเทคนิคและเชิงองค์กรที่ครอบคลุมและเหมาะสมในการป้องกันการสูญเสียหรือการประมวลผลข้อมูลดังกล่าวโดยไม่ชอบด้วยกฎหมาย
            ตามข้อกำหนดของกฎหมายด้านเทคโนโลยีสารสนเทศ ด้วยวัตถุประสงค์นี้
            เราได้ใช้เทคนิคการรักษาความปลอดภัยหลายรูปแบบ
            รวมถึงเซิร์ฟเวอร์ที่ปลอดภัย ไฟร์วอลล์ และการเข้ารหัส
            นอกเหนือจากการป้องกันทางกายภาพของสถานที่ที่จัดเก็บข้อมูล
            <br />
          </BodyText>
          <BoldBodyText>
            เราปกป้องข้อมูลอย่างไร
            <br />
          </BoldBodyText>
          <BodyText>
            ข้อมูลส่วนบุคคลของคุณจะได้รับการรักษาเป็นความลับอย่างเคร่งครัด
            และเราใช้มาตรการรักษาความปลอดภัยในเชิงเทคนิคและเชิงองค์กรที่ครอบคลุมและเหมาะสมในการป้องกันการสูญเสียหรือการประมวลผลข้อมูลดังกล่าวโดยไม่ชอบด้วยกฎหมาย
            ตามข้อกำหนดของกฎหมายด้านเทคโนโลยีสารสนเทศ ด้วยวัตถุประสงค์นี้
            เราได้ใช้เทคนิคการรักษาความปลอดภัยหลายรูปแบบ
            รวมถึงเซิร์ฟเวอร์ที่ปลอดภัย ไฟร์วอลล์ และการเข้ารหัส
            นอกเหนือจากการป้องกันทางกายภาพของสถานที่ที่จัดเก็บข้อมูล
            <br />
          </BodyText>
          <BoldBodyText>
            การเก็บรักษาข้อมูล
            <br />
          </BoldBodyText>
          <BodyText>
            เราจะไม่เก็บข้อมูลส่วนบุคคลของคุณเป็นระยะเวลาเกินกว่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ของการเก็บรวบรวมข้อมูลดังกล่าวหรือตามระยะเวลาสูงสุดที่กฎหมายกำหนด
            หากคุณเป็นผู้ใช้ที่ลงทะเบียนแล้ว
            คุณมีสิทธิตามนโยบายความเป็นส่วนตัวดังนี้
            <br />
            (a) สิทธิในข้อมูลส่วนบุคคล: เมื่อร้องขอ
            คุณจะมีสิทธิที่จะเข้าถึงและทบทวน “ข้อมูลส่วนบุคคลที่ละเอียดอ่อน”
            ที่คุณได้มอบให้เราและดูแลโดยเรา เมื่อค้นพบว่า
            “ข้อมูลส่วนบุคคลที่ละเอียดอ่อน”
            ที่ได้มอบให้นั้นไม่ถูกต้องหรือไม่เพียงพอไม่ว่าในลักษณะใดก็ตาม
            คุณจะมีสิทธิที่จะขอให้มีการเปลี่ยนแปลงหรือการแก้ไขที่เหมาะสมกับข้อมูลดังกล่าว
            หากสามารถดำเนินการดังกล่าวได้
            เราอาจดำเนินการเปลี่ยนแปลงที่เหมาะสมในฐานข้อมูลเพื่อแสดงให้เห็นถึงการแก้ไขที่ร้องขอดังกล่าว
            <br />
            (b) สิทธิในบัญชี:
            คุณสามารถควบคุมการตั้งค่าบัญชีของคุณและจัดการการสื่อสารที่คุณได้รับจากเราและวิธีการรับการสื่อสารจากเราได้
            นอกจากนี้
            คุณอาจแก้ไขรหัสผ่านของคุณหรือปิดบัญชีของคุณหากคุณเลือกที่จะดำเนินการดังกล่าว
            <br />
            (c) สิทธิในการให้ความยินยอม: นอกจากนี้
            คุณมีตัวเลือกที่จะเพิกถอนความยินยอมที่ได้ให้เราไว้ก่อนหน้านี้
            ในกรณีดังกล่าว
            เราอาจไม่จำเป็นต้องให้บริการแพลตฟอร์ม/เว็บไซต์อีกต่อไป
          </BodyText>
        </CookieBodySpacer>{" "}
      </PrivacyPolicyBody>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyBodyThailand;
