import React, { useState } from "react";
import {
  InteriorDiv,
  ThemeImagesContainer,
  ThemeItemsContainer,
  ThemeText,
  InteriorThemeImage,
  PaleeteeDiv,
} from "../../styles/ConsumerDuluxThemeStyles";
import "../../styles/checkBox2.css";
import LanguageSelector from "../../language/LanguageSelector";


const ConsumerDuluxInteriorList = ({
  themeList,
  colorOfYearList,
  selectImageHandler,
  selectedImage,
  themePalette,
  selectedCountry
}) => {
  const [list, setList] = useState(null);

  const themePaletteSuccessCallback = (data) => {
    console.log("***********", data)

    setList(data);
  };

  const themePaletteFailureCallback = (msg) => {
    setList(null);
    alert(msg);
  };

  return (
    <InteriorDiv>
      <h3><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"AMBIANCE_HD"} /></h3>
      <ThemeItemsContainer>
        {themeList.map((data, index) => {
          return (
            <ThemeImagesContainer
            key={`${index}`}
            onClick={() => {
              themePalette({
                themeName: data.imageName,
                themePaletteSuccessCallback,
                themePaletteFailureCallback,
              });
              selectImageHandler(data.imageName);
            }}
            >
              <div style={{ position: "relative", display: "flex" }}>
                {selectedImage === data.imageName && (
                  <label className="container">
                    {""}
                    <input
                      type="checkbox"
                      checked="checked"
                      onChange={(e) => true}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "35px",
                        zIndex: "100",
                        top: "10px",
                      }}
                      className="checkmark"
                    ></span>
                  </label>
                )}
                <InteriorThemeImage
                  selectedImage={
                    selectedImage === data.imageName ? true : false
                  }
                  src={data.imageUrl}
                  alt="Theme"
                />
              </div>
              <ThemeText>{data.imageName}</ThemeText>
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.temp_palette[0].map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.temp_palette[1].map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.temp_palette[2].map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </ThemeImagesContainer>
          );
        })}
      </ThemeItemsContainer>
      <h3><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"COLOR_OF_THE_YEAR"} /></h3>
      <ThemeItemsContainer>
        {colorOfYearList.map((data, index) => { 
          return (
            <ThemeImagesContainer
              key={`${index}`}
              onClick={() => {
                themePalette({
                  themeName: data.imageName,
                  themePaletteSuccessCallback,
                  themePaletteFailureCallback,
                });
                selectImageHandler(data.imageName);
              }}
            >
              <div style={{ position: "relative", display: "flex" }}>
                {selectedImage === data.imageName && (
                  <label className="container">
                    {""}
                    <input
                      type="checkbox"
                      checked="checked"
                      onChange={(e) => true}
                    />
                    <span
                      className="checkmark"
                      style={{
                        position: "absolute",
                        right: "35px",
                        zIndex: "100",
                        top: "10px",
                      }}
                    ></span>
                  </label>
                )}
                <InteriorThemeImage
                  selectedImage={
                    selectedImage === data.imageName ? true : false
                  }
                  src={data.imageUrl}
                  alt="Theme"
                />
              </div>
              <ThemeText>{data.imageName}</ThemeText>
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.palette_1_colours.map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.palette_2_colours.map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
              {selectedImage === data.imageName && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {list != null &&
                    list.palette_3_colours.map((item, index) => {
                      return (
                        <PaleeteeDiv
                          key={`${index}`}
                          style={{
                            backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </ThemeImagesContainer>
          );
        })}
      </ThemeItemsContainer>
    </InteriorDiv>
  );
};

export default ConsumerDuluxInteriorList;
