import React, { useReducer } from "react";
import { CHANGE_PREVIEW_SUPERVISOR_DETAILS } from "../utils/Constants";

const initialState = {
  previewSupervisorDetails: {},
};

const PreviewSupervisorDetailsContext = React.createContext({
  previewSupervisorDetails: {},
  setPreviewSupervisorDetails: (previewSupervisorDetails) => {},
});

function previewSupervisorDetailReducer(state, action) {
  switch (action.type) {
    case CHANGE_PREVIEW_SUPERVISOR_DETAILS:
      return {
        ...state,
        previewSupervisorDetails: action.payload,
      };
    default:
      return state;
  }
}

function PreviewSupervisorDetailsProvider(props) {
  const [state, dispatch] = useReducer(
    previewSupervisorDetailReducer,
    initialState
  );

  function setPreviewSupervisorDetails(previewSupervisorDetails) {
    dispatch({
      type: CHANGE_PREVIEW_SUPERVISOR_DETAILS,
      payload: previewSupervisorDetails,
    });
  }

  return (
    <PreviewSupervisorDetailsContext.Provider
      value={{
        previewSupervisorDetails: state.previewSupervisorDetails,
        setPreviewSupervisorDetails,
      }}
      {...props}
    />
  );
}

export { PreviewSupervisorDetailsContext, PreviewSupervisorDetailsProvider };
