import styled from "styled-components";

export const PreviewSupervisorPendingRequestDetailContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PreviewSupervisorPendingRequestDetailHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const PreviewSupervisorPendingRequestBodyContainer = styled.div`
  display: flex;
  margin: 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 60px 0;
  height: calc(100vh - 10px);
`;

export const VerticalDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1500px) {
    padding: 0px;
  }
  margin: 20px 0;
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  margin: 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const MainBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
`;

export const RequestDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 20px;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RequestIdText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
`;

export const ReferenceImageContainer = styled.div`
  display: flex;
  margin: 0px 80px;
  align-items: center;
  @media (max-width: 1100px) {
    margin: 0;
  }
`;

export const CustomerImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ReferenceImage = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 2px 0 0;
`;

export const CustomerImage = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 2px 0 0;
`;

export const ReferenceImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const CustomerEmailText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const RequestDetailsDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0px 10px;
`;

export const ImageComparisonContainer = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const OriginalImageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const OriginalImageBodyText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  margin: 0;
`;

export const OriginalBodyImage = styled.img`
  width: 200px;
  height: 200px;
  margin: 10px 0;
  object-fit: contain;
`;

export const ReferenceImageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const ReferenceImageBodyText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  margin: 0px;
`;

export const ReferenceBodyImage = styled.img`
  width: 200px;
  height: 200px;
  margin: 10px 0;
  object-fit: contain;
`;

export const RequestMadeText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  margin: 20px 20px 0 20px;
`;

export const ResultGeneratedText = styled.p`
  display:flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  margin: 0 20px 60px 20px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
`;

export const PleaseChooseText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
  margin-left: 15px;
`;

export const OptionContainerBody = styled.div`
  background: #f1f1f1;
  padding: 10px 3px 10px 10px;
  border-radius: 7px;
`;

export const OptionBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;
  position: relative;
`;

export const OptionText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const PaletteListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PaletteImage = styled.img`
  width: 200px;
  height: 200px;
  :hover {
    cursor: pointer;
  }
  margin: 10px 0;
  object-fit: contain;
  border: ${(props) =>
    props.isSelected === true ? "2px solid #EFBF42" : "none"};
`;

export const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const PaletteDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin: 0 4px;
  position: relative;
  display: inline-block;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin: 20px 0px;
`;

export const BackButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin: 0 10px;
  padding: 4px 10px;
  :hover {
    cursor: pointer;
  }
`;

export const NextButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const DownloadImage = styled.button`
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 150px;
  padding: 8px 0;
  background: #2fc38c;
  margin-left: 40px;
  margin-bottom: 30px;
  :hover {
    cursor: pointer;
  }
`;