import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminUserSearchRequestSideBarContainer,
  AdminUserSearchRequestHeader,
  AdminUserCountryText,
  AdminUserSearchRequestsText,
  SelectedCountryCheck,
  ByCountryText,
  DoneButton,
  ResetFilterButton,
  AdminUserRequestSearchCountryDiv,
  AdminUsersButtonBar,
  AdminUserSearchRequestModalBarDiv,
} from "../../styles/AdminUserStyles";
import SEARCH_FILTER from "../../images/search_filter.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import "../../styles/floatingLabelInput.css";
import LanguageSelector from "../../language/LanguageSelector";

const AdminUserSearchRequest = ({
  openSideMenu,
  toggleSearchRequest,
  users,
  setTempUsers,
  setSelectedPage,
  setPaginationList,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const countries = [
    "Singapore",
    "India",
    "Indonesia",
    "Pakistan",
    "Vietnam",
    "Thailand",
    "Malaysia",
    "Sri Lanka"
  ];
  const position = ["Preview Supervisor", "Preview Staff"];

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);

  const countrySelectedHandler = (item) => {
    if (item === "All") {
    } else {
      if (selectedCountries.includes(item)) {
        setSelectedCountries(
          selectedCountries.filter((filteredItem) => filteredItem !== item)
        );
      } else {
        setSelectedCountries((oldArray) => [...oldArray, item]);
      }
    }
  };

  const positionSelectedHandler = (item) => {
    if (selectedPosition.includes(item)) {
      setSelectedPosition(
        selectedPosition.filter((filteredItem) => filteredItem !== item)
      );
    } else {
      setSelectedPosition((oldArray) => [...oldArray, item]);
    }
  };

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  const resetFilterClickHandler = () => {
    setSelectedCountries([]);
    setSelectedPosition([]);
    setTempUsers(users);
    setSelectedPage(1);
    updatePagination(users);
    toggleSearchRequest();
  };

  const applyFilterHandler = () => {
    let newList = [],
      filteredList = [];

    if (selectedCountries.length > 0 && selectedPosition.length > 0) {
      for (let i = 0; i < selectedCountries.length; i++) {
          filteredList = users.filter(
            (data) => data.country === selectedCountries[i] && data.user_type === selectedPosition[i]
          );
          newList.push(...filteredList);
        }
      
      setSelectedPage(1);
      setTempUsers(newList);
      updatePagination(newList);
      toggleSearchRequest();
    }
    
    else if (selectedCountries.length > 0) {
      for (let i = 0; i < selectedCountries.length; i++) {
        filteredList = users.filter(
          (data) => data.country === selectedCountries[i]
        );
        newList.push(...filteredList);
      }
      setSelectedPage(1);
    setTempUsers(newList);
    updatePagination(newList);
    toggleSearchRequest(); 
    }

    else if (selectedPosition.length > 0) {
      for (let i = 0; i < selectedPosition.length; i++) {
        filteredList = users.filter(
          (data) => data.user_type === selectedPosition[i]
        );
        newList.push(...filteredList);
      }
      setSelectedPage(1);
    setTempUsers(newList);
    updatePagination(newList);
    toggleSearchRequest(); 
    }

    
    
    else {
      resetFilterClickHandler();
    }
  };

  if (width < 1100) {
    return (
      <AdminUserSearchRequestModalBarDiv openSideMenu={openSideMenu}>
        <AdminUserSearchRequestHeader>
          <img src={SEARCH_FILTER} alt="Search" />
          <AdminUserSearchRequestsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_TEAM"}
        /></AdminUserSearchRequestsText>
        </AdminUserSearchRequestHeader>
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
        {countries.map((value, index) => {
          return (
            <AdminUserRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedCountries.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => countrySelectedHandler(value)}
              />
              <AdminUserCountryText isSelected={true}>
                {value}
              </AdminUserCountryText>
            </AdminUserRequestSearchCountryDiv>
          );
        })}
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_POSITION"}
        /></ByCountryText>
        {position.map((value, index) => {
          return (
            <AdminUserRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedPosition.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => positionSelectedHandler(value)}
              />

              {value == "Preview Staff" && (
              <AdminUserCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF"}
            /> </AdminUserCountryText>
            )}

            {value == "Preview Supervisor" && (
              <AdminUserCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR"}
            /> </AdminUserCountryText>
            )}
            </AdminUserRequestSearchCountryDiv>
          );
        })}
        <AdminUsersButtonBar>
          <ResetFilterButton onClick={resetFilterClickHandler}>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
          </ResetFilterButton>
          <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
        </AdminUsersButtonBar>
      </AdminUserSearchRequestModalBarDiv>
    );
  } else {
  }

  return (
    <AdminUserSearchRequestSideBarContainer openSideMenu={openSideMenu}>
      <AdminUserSearchRequestHeader>
        <img src={SEARCH_FILTER} alt="Search" />
        <AdminUserSearchRequestsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_TEAM"}
        /></AdminUserSearchRequestsText>
      </AdminUserSearchRequestHeader>
      <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
      {countries.map((value, index) => {
        return (
          <AdminUserRequestSearchCountryDiv key={`${index}`}>
            <SelectedCountryCheck
              src={
                selectedCountries.includes(value)
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Check"
              onClick={() => countrySelectedHandler(value)}
            />
            <AdminUserCountryText isSelected={true}>
              {value}
            </AdminUserCountryText>
          </AdminUserRequestSearchCountryDiv>
        );
      })}
      <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_POSITION"}
        /></ByCountryText>
      {position.map((value, index) => {
        return (
          <AdminUserRequestSearchCountryDiv key={`${index}`}>
            <SelectedCountryCheck
              src={
                selectedPosition.includes(value)
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Check"
              onClick={() => positionSelectedHandler(value)}
            />
              {value == "Preview Staff" && (
              <AdminUserCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF"}
            /> </AdminUserCountryText>
            )}

            {value == "Preview Supervisor" && (
              <AdminUserCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR"}
            /> </AdminUserCountryText>
            )}
          </AdminUserRequestSearchCountryDiv>
        );
      })}
      <AdminUsersButtonBar>
        <ResetFilterButton onClick={resetFilterClickHandler}>
        <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
        </ResetFilterButton>
        <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
      </AdminUsersButtonBar>
    </AdminUserSearchRequestSideBarContainer>
  );
};

export default AdminUserSearchRequest;
