import React, { useContext, useState } from "react";
import {
  ConsumerWriteReviewContainer,
  ConsumerWriteReviewHeader,
  DeluxePreviewServiceImage,
  ConsumerWriteReviewBodyRow,
  BackDiv,
  BackText,
  BackImage,
  WriteReviewImage,
  WriteReviewBodyMainDiv,
  TitleText,
  QuestionnarieDiv,
  SubmitButton,
  LogoutButton,
  ErrorMessage,
} from "../../styles/ConsumerWriteReviewStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import {
  CONSUMER_HOME_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import LEFT_ARROW_IMAGE from "../../images/left_arrow_image.svg";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import WRITE_REVIEW_IMAGE from "../../images/write_review.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ConsumerWriteReviewSlider from "../../components/consumer-write-review/ConsumerWriteReviewSlider";
import { ColourizationContext } from "../../context/ColourizationContext";
import { Link } from "react-router-dom";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerWriteReview = ({ history, location }) => {
  const { submitSurvey } = useContext(ConsumerContext);
  const colourizationContext = useContext(ColourizationContext);
  const value = colourizationContext.colourizationDetails;

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const goBackHandler = () => {
    history.goBack();
  };

  const { width } = useWindowDimensions();

  const [sliderOne, setSliderOne] = useState(2);
  // const [sliderTwo, setSliderTwo] = useState(5);
  const [sliderThree, setSliderThree] = useState(5);
  const [sliderFour, setSliderFour] = useState(5);
  // const [sliderFive, setSliderFive] = useState(5);
  const [sliderSix, setSliderSix] = useState(5);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const submitSuverySuccessCallback = () => {
    history.push(CONSUMER_HOME_ROUTE);
  };

  const submitSurveyFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const submitSurveyHandler = () => {
    submitSurvey({
      request_id: location.state.request_id,
      email: value?.email || decodedToken?.email,
      recommend: sliderOne,
      // ease_of_access: sliderTwo,
      ease_of_use: sliderThree,
      detailing: sliderFour,
      // fulfill_requirement: sliderFive,
      time_to_deliver: sliderSix,
      submitSuverySuccessCallback,
      submitSurveyFailureCallback,
    });
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
      />
      <ConsumerWriteReviewContainer applyFilter={openLogoutMenu}>
        <ConsumerWriteReviewHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage src={DELUXE_PREVIEW_SERVICE_IMAGE} />
          </Link>
          {decodedToken && (
            <LogoutButton onClick={toggleLogoutMenu}>         
            <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"LOGOUT"}
          /></LogoutButton>
          )}
        </ConsumerWriteReviewHeader>
        <ConsumerWriteReviewBodyRow>
          {width > 1100 && (
            <BackDiv onClick={goBackHandler}>
              <BackImage src={LEFT_ARROW_IMAGE} />
              <BackText><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"BACK"}
          /></BackText>
            </BackDiv>
          )}

          <WriteReviewBodyMainDiv>
            <TitleText><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"WE_WOULD_LOVE_TO_HEAR_FROM_YOU"}
          /></TitleText>
            <QuestionnarieDiv>
              <ConsumerWriteReviewSlider
                slider={sliderOne}
                setSlider={setSliderOne}
                width={"90%"}
                textWidth={"100%"}
                sliderMultipler={20}
                startingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"NOT_LIKELY_AT_ALL"}
                />}
                endingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"VERY_LIKELY"}
                />}
                titleText={
                  <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"HOW_LIKELY_ARE_YOU_TO_SUGGEST"}
          />
                }
                maxRange={"5"}
              />
              {/* <ConsumerWriteReviewSlider
              slider={sliderTwo}
              setSlider={setSliderTwo}
              width={"90%"}
              textWidth={"100%"}
              sliderMultipler={10}
              startingText={"Not likely at all"}
              endingText={"Very likely"}
              titleText={"Ease of accessing"}
              maxRange={"10"}
            /> */}
              <ConsumerWriteReviewSlider
                slider={sliderThree}
                setSlider={setSliderThree}
                width={"90%"}
                textWidth={"100%"}
                sliderMultipler={10}
                startingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"VERY_POOR"}
                />}
                endingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"EXCELLENT"}
                />}
                titleText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"EASE_OF_USE"}
                />}
                maxRange={"10"}
              />
              <ConsumerWriteReviewSlider
                slider={sliderFour}
                setSlider={setSliderFour}
                width={"90%"}
                textWidth={"100%"}
                sliderMultipler={10}
                startingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"VERY_POOR"}
                />}
                endingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"EXCELLENT"}
                />}
                titleText={
                  <LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"ABILITY_TO_PROVIDE_MINUTE_DETAILS"}
                />}
                maxRange={"10"}
              />
              {/* <ConsumerWriteReviewSlider
              slider={sliderFive}
              setSlider={setSliderFive}
              width={"90%"}
              textWidth={"100%"}
              sliderMultipler={10}
              startingText={"Very poor"}
              endingText={"Excellent"}
              titleText={"Range of requirements covered"}
              maxRange={"10"}
            /> */}
              <ConsumerWriteReviewSlider
                slider={sliderSix}
                setSlider={setSliderSix}
                width={"90%"}
                textWidth={"100%"}
                sliderMultipler={10}
                startingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"VERY_POOR"}
                />}
                endingText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"EXCELLENT"}
                />}
                titleText={<LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"TIME_TAKEN_TO_RECEIVE_THE_RESULTS"}
                />}
                maxRange={"10"}
              />
            </QuestionnarieDiv>
            <SubmitButton onClick={submitSurveyHandler}><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"SUBMIT_BUTTON"}
          /></SubmitButton>
            {errorMessage != null && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </WriteReviewBodyMainDiv>

          {width > 1100 && <WriteReviewImage src={WRITE_REVIEW_IMAGE} />}
        </ConsumerWriteReviewBodyRow>
      </ConsumerWriteReviewContainer>
    </>
  );
};

export default ConsumerWriteReview;
