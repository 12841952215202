import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  PreviewSupervisorResultSideBarDiv,
  CancelDrawerImage,
  DrawerResultsText,
  OptionDrawerText,
  DrawerPaleetteRow,
  MainDrawerImage,
  DrawerPalette,
  ToolTip,
  DrawerButtonRow,
  DrawerDownloadImage,
  DrawerDoneButton,
  DrawerColorText,
  PaginationDiv,
  PreviewSupervisorResultModalBarDiv,
} from "../../styles/PreviewSupervisorResultStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
// import PAGINATION_LEFT_IMAGE from "../../images/pagination_left.svg";
// import PAGINATION_RIGHT_IMAGE from "../../images/pagination_right.svg";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import {
  PaginationLeftImage,
  PaginationNumberDiv,
  PaginationRightImage,
} from "../../styles/PreviewStaffDashboardStyles";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorResultSideBar = ({
  openResultMenu,
  toggleResultMenuDrawer,
  results,
  selectedItem,
  setSelectedItem,
  downloadSingleImageHandler,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const rightIncrementHandler = () => {
    if (selectedItem < results.length - 1) {
      setSelectedItem(selectedItem + 1);
    }
  };

  const leftDecrementHandler = () => {
    if (selectedItem > 0) {
      setSelectedItem(selectedItem - 1);
    }
  };

  if (width < 1100) {
    return (
      <PreviewSupervisorResultModalBarDiv openSideMenu={openResultMenu}>
        <CancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toggleResultMenuDrawer}
        />
        <DrawerResultsText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULTS"}
            /></DrawerResultsText>
        <OptionDrawerText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            /> {selectedItem + 1}</OptionDrawerText>
        <MainDrawerImage
          src={
            results && results.length > 0 && results[selectedItem]?.result_image
          }
        />
        <PaginationDiv>
          <PaginationLeftImage
            onClick={leftDecrementHandler}
            src={BLACK_ARROW_IMAGE}
            alt="Pagination left"
          />
          <PaginationNumber
            key={selectedItem}
            selectedPage={selectedItem}
            paginationList={results}
            setSelectedPage={setSelectedItem}
          />
          <PaginationRightImage
            onClick={rightIncrementHandler}
            src={BLACK_ARROW_IMAGE}
            alt="Pagination right"
          />
        </PaginationDiv>
        <DrawerPaleetteRow>
          {results &&
            results.length > 0 &&
            results[selectedItem]?.colours.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <DrawerPalette
                    style={{
                      backgroundColor: `rgb(${item?.red_value},${item?.green_value},${item?.blue_value})`,
                    }}
                  >
                    <ToolTip>{item?.colour_name}</ToolTip>
                  </DrawerPalette>
                  <DrawerColorText>{item?.colour_code}</DrawerColorText>
                </div>
              );
            })}
        </DrawerPaleetteRow>
        <DrawerButtonRow>
          <DrawerDownloadImage
            onClick={() => {
              downloadSingleImageHandler(results[selectedItem]?.result_image);
            }}
          >
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD_IMAGE"}
            />
          </DrawerDownloadImage>
          <DrawerDoneButton onClick={toggleResultMenuDrawer}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            />
          </DrawerDoneButton>
        </DrawerButtonRow>
      </PreviewSupervisorResultModalBarDiv>
    );
  } else {
    return (
      <PreviewSupervisorResultSideBarDiv openSideMenu={openResultMenu}>
        <CancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toggleResultMenuDrawer}
        />
        <DrawerResultsText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULTS"}
            /></DrawerResultsText>
        <OptionDrawerText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            /> {selectedItem + 1}</OptionDrawerText>
        <MainDrawerImage
          src={
            results && results.length > 0 && results[selectedItem]?.result_image
          }
        />

        <PaginationDiv>
          <PaginationLeftImage
            onClick={leftDecrementHandler}
            src={BLACK_ARROW_IMAGE}
            alt="Pagination left"
          />
          <PaginationNumber
            key={selectedItem}
            selectedPage={selectedItem}
            paginationList={results}
            setSelectedPage={setSelectedItem}
          />
          <PaginationRightImage
            onClick={rightIncrementHandler}
            src={BLACK_ARROW_IMAGE}
            alt="Pagination right"
          />
        </PaginationDiv>
        <DrawerPaleetteRow>
          {results &&
            results.length > 0 &&
            results[selectedItem]?.colours.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <DrawerPalette
                    style={{
                      backgroundColor: `rgb(${item?.red_value},${item?.green_value},${item?.blue_value})`,
                    }}
                  >
                    <ToolTip>{item?.colour_name}</ToolTip>
                  </DrawerPalette>
                  <DrawerColorText>{item?.colour_code}</DrawerColorText>
                </div>
              );
            })}
        </DrawerPaleetteRow>
        <DrawerButtonRow>
          <DrawerDownloadImage
            onClick={() => {
              downloadSingleImageHandler(results[selectedItem]?.result_image);
            }}
          >
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD_IMAGE"}
            />
          </DrawerDownloadImage>
          <DrawerDoneButton onClick={toggleResultMenuDrawer}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            />
          </DrawerDoneButton>
        </DrawerButtonRow>
      </PreviewSupervisorResultSideBarDiv>
    );
  }
};

const PaginationNumber = ({
  paginationList,
  setSelectedPage,
  selectedPage,
}) => {
  return (
    <>
      {paginationList &&
        paginationList.length > 0 &&
        paginationList.map((item, index) => {
          return (
            <PaginationNumberDiv
              key={`${index}`}
              onClick={() => setSelectedPage(index)}
              isSelected={selectedPage === index}
            >
              {index + 1}
            </PaginationNumberDiv>
          );
        })}
    </>
  );
};

export default PreviewSupervisorResultSideBar;
