//Routes
export const HOME_PAGE_ROUTE = "/";
export const SINGAPORE_HOME_PAGE_ROUTE = "/sg";
export const MALAYSIA_HOME_PAGE_ROUTE = "/my";
export const INDONESIA_HOME_PAGE_ROUTE = "/id";
export const INDIA_HOME_PAGE_ROUTE = "/in";
export const PAKISTAN_HOME_PAGE_ROUTE = "/pk";
export const THAILAND_HOME_PAGE_ROUTE = "/th";
export const VIETNAM_HOME_PAGE_ROUTE = "/vn";
export const SRILANKA_HOME_PARGE_ROUTE = "/sl";
export const CONSUMER_UPLOAD_IMAGE_ROUTE = "/consumer-upload-image";
export const CONSUMER_IMAGE_OPTIONS_ROUTE = "/consumer-image-options";
export const CONSUMER_BROWSE_COLOUR_ROUTE = "/consumer-browse-colour";
export const CONSUMER_DULUX_THEME_ROUTE = "/consumer-dulux-theme";
export const CONSUMER_DULUX_PREVIEW_ROUTE = "/consumer-dulux-preview-route";
export const CONSUMER_DULUX_LOGIN = "/login";
export const CONSUMER_REFERENCE_IMAGE_ROUTE = "/consumer-reference-image-route";
export const CONSUMER_CHOOSE_IMAGE_TYPE_ROUTE = "/consumer-choose-image-type";
export const CONSUMER_RESULT_ROUTE = "/consumer-result";
export const CONSUMER_HOME_ROUTE = "/consumer-home";
export const PREVIEW_SUPERVISOR_COLOUR_PICKER_ROUTE =
  "/preview-supervisor-colour-picker";
export const CONSUMER_DETAIL_ROUTE = "/consumer-detail-report";
export const CONSUMER_CHOOSE_COLOUR_ROUTE = "/consumer-choose-colour";
export const PREVIEW_SUPERVISOR_DASHBOARD_ROUTE =
  "/preview-supervisor-dashboard";
export const PREVIEW_SUPERVISOR_TOUCH_UP_ROUTE = "/touch-up";
export const PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE =
  "/pending-request-reference-image-detail";
export const PENDING_REQUEST_THEME_DETAIL_ROUTE =
  "/pending-request-theme-detail";
export const PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE =
  "/pending-request-lead-colour-detail";
export const PREVIEW_SUPERVISOR_RESULT_ROUTE = "/preview-supervisor-result";
export const PREVIEW_STAFF_RESULT_ROUTE = "/preview-staff-result";
export const PREVIEW_SUPERVISOR_CONSUMER_RESULT_ROUTE =
  "/admin-ps-consumer-result";
// export const PREVIEW_SUPERVISOR_REFERENCE_IMAGE_DETAIL =
//   "/preview-supervisor-reference-image-detail";
export const PREVIEW_SUPERVISOR_SELECTOR = "/selector";
export const CONSUMER_WRITE_REVIEW = "/consumer-write-review";
export const PREVIEW_STAFF_DASHBOARD_ROUTE = "/preview-staff-dashboard";
export const ADMIN_DASHBOARD_ROUTE = "/admin-dashboard";
export const ADMIN_RESULT_ROUTE = "/admin-result";
//export const RESULT_ROUTE = "/result";
// export const ADMIN_PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE =
//   "/admin-pending-request-reference-image";
export const ADMIN_PENDING_REQUEST_THEME_DETAIL_ROUTE =
  "/admin-pending-request-theme-detail";
export const ADMIN_PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE =
  "/admin-pending-request-lead-colour-detail";
export const ADMIN_SUPERVISOR_SELECTOR = "/admin-supervisor-selector";
export const ADMIN_CONSUMER_RESULT_ROUTE = "/admin-consumer-result";
export const COOKIE_MALYASIA_ROUTE = "/my-cookie";
export const COOKIE_SINGAPORE_ROUTE = "/sg-cookie";
export const COOKIE_INDONESIA_ROUTE = "/id-cookie";
export const COOKIE_PAKISTAN_ROUTE = "/pk-cookie";
export const COOKIE_SRILANKA_ROUTE = "/sl-cookie";
export const COOKIE_THAILAND_ROUTE = "/th-cookie";
export const COOKIE_INDIA_ROUTE = "/in-cookie";
export const COOKIE_VIETNAM_ROUTE = "/vn-cookie";
export const COOKIE_ROUTE = "/cookie";
export const LEGAL_ROUTE = "/legal";
export const LEGAL_MALYASIA_ROUTE = "/my-legal";
export const LEGAL_SINGAPORE_ROUTE = "/sg-legal";
export const LEGAL_INDONESIA_ROUTE = "/id-legal";
export const LEGAL_PAKISTAN_ROUTE = "/pk-legal";
export const LEGAL_SRILANKA_ROUTE = "/sl-legal";
export const LEGAL_THAILAND_ROUTE = "/th-legal";
export const LEGAL_INDIA_ROUTE = "/in-legal";
export const LEGAL_VIETNAM_ROUTE = "/vn-legal";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const PRIVACY_POLICY_MALYASIA_ROUTE = "/my-privacy-policy";
export const PRIVACY_POLICY_SINGAPORE_ROUTE = "/sg-privacy-policy";
export const PRIVACY_POLICY_INDONESIA_ROUTE = "/id-privacy-policy";
export const PRIVACY_POLICY_PAKISTAN_ROUTE = "/pk-privacy-policy";
export const PRIVACY_POLICY_SRILANKA_ROUTE = "/sl-privacy-policy";
export const PRIVACY_POLICY_THAILAND_ROUTE = "/th-privacy-policy";
export const PRIVACY_POLICY_INDIA_ROUTE = "/in-privacy-policy";
export const PRIVACY_POLICY_VIETNAM_ROUTE = "/vn-privacy-policy";
export const INTERNAL_LOGIN_ROUTE = "/internal-login";
export const USER_LOGIN_ROUTE = "/user-login";

//Apis
export const BASE_API_URL = "http://localhost:8020";
export const VERIFY_EMAIL_API = "/email/verify";
export const VERIFY_EMAIL_OTP_API = "/email/verify/otp";
export const USER_CREATE_API = "/user/create";
export const IMAGE_GALLERY_API = "/image/gallery";
export const CONSUMER_REFRESH_TOKEN_API = "/user/refreshtoken";
export const PREVIEW_SUPERVISOR_DASHBOARD_SUMMARY_API = "/ps/summary";
// export const IMAGE_GALLERY_API = "/image/gallery/get";
export const COLOURIZATION_REQUEST_API = "/colour/request";
export const COLOURIZATION_REQUEST_REPORT_DOWNLOAD_API =
  "/colour/request/report/download";
export const CONSUMER_USER_DETAIL_API = "/user/detail";
export const PREVIEW_SUPERVISOR_REQUEST_LIST_API = "/ps/colour/request/list";
export const PREVIEW_SUPERVISOR_REASSIGN_REQUEST_API =
  "/ps/colour/request/reassign";
export const PREVIEW_SUPERVISOR_RESOLVE_REQUEST_API =
  "/ps/colour/request/resolve";
export const PREVIEW_SUPETRVISOR_RESULT_API = "/ps/colour/request/result";
export const GET_COLOURS_API = "/colour/rgbvalue";
export const PREVIEW_SUPERVISOR_REPORT_API = "/ps/colour/request/report";
export const CONSUMER_REQUEST_HISTORY_API = "/colour/request/history";
export const CONSUMER_REQUEST_REPORT_API = "/colour/request/report";
export const USER_SURVEY_API = "/user/survey";
export const ADMIN_ADD_USER_API = "/sa/user/add";
export const PREVIEW_SUPERVISOR_ADD_USER_API = "/ps/user/add";
export const ADMIN_MANAGE_USER_API = "/sa/user/update";
export const ADMIN_DELETE_USER_API = "/sa/user/remove";
export const ADMIN_SERVICE_HIGHLIGHTS_API = "/sa/service/highlight";
export const ADMIN_REQUEST_API = "sa/colour/request/list";
export const ADMIN_ARCHIVE_API = "/ps/colour/request/archive";
export const ADMIN_CONSUMER_LIST_API = "/sa/consumer/list";
export const ADMIN_USERS_LIST_API = "/sa/user/list";
export const COLOUR_THEME_PALETTE_API = "/colour/theme/palette";
export const TEAM_LIST_API = "/ps/team/list";
export const USER_LOCATION_INFO_API = "/ps/user/info";
export const CHECK_SURVEY_API = "/user/survey/check";
export const FAMILY_COLOUR_API = "/colour/family";
export const FAMILY_COLOUR_SUGGESTION_API = "/colour/family/suggestion";
export const SEARCH_COLOUR_API = "/colour/search";
export const PREVIEW_SUPERVISOR_MY_TEAM = "/ps/myteam";
export const PS_PRODUCT_LIST = "/ps/product/list";
export const PS_SHOP_LIST = "/ps/shop/list";
export const PS_PAINTER_LIST = "/ps/painter/list";
export const PS_CONSUMER_LIST_DOWNLOAD = "/ps/colour/request/list/download";
export const ADMIN_CONSUMER_LIST_DOWNLOAD = "/sa/consumer/list/download";
export const ADMIN_REQUEST_LIST_DOWNLOAD = "/sa/colour/request/list/download";
export const ADMIN_FEEDBACK_DETAILS_API = "/sa/feedback/details";
export const ADMIN_ANALYTICS_API = "/sa/analytics";
export const ADMIN_BILLING_API = "/sa/billing";
export const CHECK_REQUEST_LIMIT_API = "/colour/request/limit";
export const ADMIN_USER_LIST_DOWNLOAD = "/sa/user/list/download";
export const ADMIN_INVOICE_DOWNLOAD = "/sa/billing/invoice";
export const PS_RAW_IMG = "/ps/color/rawimage";
export const PS_UPLOAD_EDITED_IMG = "/colour/editedresultimage";
export const COLOR_SWAP_EXTRACT = "/colour/colorswapextract";
export const COLOR_SWAP_CHANGE = "/colour/colorswapchange";
export const AUTO_COLOR_PICKER = "/colour/autocolorpicker";
export const PS_EDIT_PALETTE = "/colour/editpalette";
export const GEOCODING_API =
  "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&latlng=";
export const GEOCODING_API_KEY = "AIzaSyBn17VuZ03XrNgA4Pr7lbt2eidrDwynVPw";
export const IP_CONFIG_API = "http://www.geoplugin.net/json.gp";

//Constants
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const CHANGE_COLOURIZATION_DETAILS = "Change_Colourization_Details";
export const REFERENCE = "Reference";
export const THEME = "Theme";
export const LEAD_COLOUR = "Lead Colour";
export const CHANGE_PREVIEW_SUPERVISOR_DETAILS =
  "Change_Preview_Supervisor_Details";
export const CHANGE_ADMIN_DETAILS = "Change_Admin_Details";
export const TOKEN_NAME = "token";
export const ACCESS_KEY_ID = "AKIASWZED675CRV7ENEL";
export const SECRET_ACCESS_KEY = "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";
export const BUCKET_NAME = "duluxpreviewservice-com";

//Urls
export const AKZONOBEL_URL = "https://www.akzonobel.com/en";
export const ML_FIND_COLOUR_URL = "https://www.dulux.com.my/en/colour-palettes";
export const ML_CHOOSE_PRODUCT_URL = "https://www.dulux.com.my/en/products";
export const ML_IDEAS_URL = "https://www.dulux.com.my/en/colour-inspiration";
export const ML_EXPERT_HELP_URL =
  "https://www.dulux.com.my/en/decorating-tips-and-advice";
export const ML_FIND_STORE_URL = "https://www.dulux.com.my/en/find-a-retailer";
export const ML_FACEBOOK_URL = "https://www.facebook.com/DuluxMalaysia/";
export const ML_YOUTUBE_URL = "https://www.youtube.com/user/DuluxMalaysia";
export const ML_INSTAGRAM_URL = "http://www.instagram.com/duluxmalaysia";
export const ML_ABOUT_DULUX_URL = "https://www.dulux.com.my/en/about-us";
export const ML_CONTACT_US_URL = "https://www.dulux.com.my/en/contact-us";
export const ML_FIND_DULUX_STORE_URL =
  "https://www.dulux.com.my/en/find-a-retailer";
export const ML_SITEMAP_URL = "https://www.dulux.com.my/en/html-site-map";
export const ML_DULUX_COLOURS_URL =
  "https://www.dulux.com.my/en/colour-palettes";
export const ML_PRODUCTS_URL = "https://www.dulux.com.my/en/products";
export const ML_DECORATION_IDEAS_URL =
  "https://www.dulux.com.my/en/colour-inspiration";
export const ML_ACCESSIBLITY_URL = "https://www.dulux.com.my/en/accessibility";
export const ML_COLOUR_ACCURACY_URL =
  "https://www.dulux.com.my/en/colour-accuracy";

export const SG_FIND_COLOUR_URL = "https://www.dulux.com.sg/en/colour-palettes";
export const SG_CHOOSE_PRODUCT_URL =
  "https://www.dulux.com.sg/en/product-overview";
export const SG_IDEAS_URL = "https://www.dulux.com.sg/en/colour-inspiration";
export const SG_EXPERT_HELP_URL =
  "https://www.dulux.com.sg/en/decorating-tips-and-advice";
export const SG_FIND_STORE_URL = "https://www.dulux.com.sg/en/storefinder";
export const SG_FACEBOOK_URL = "https://www.facebook.com/dulux.sg";
export const SG_YOUTUBE_URL = "https://www.youtube.com/user/DuluxSingapore";
export const SG_INSTAGRAM_URL = "http://www.instagram.com/duluxsg";
export const SG_ABOUT_DULUX_URL = "https://www.dulux.com.sg/en/about-us";
export const SG_CONTACT_US_URL = "https://www.dulux.com.sg/en/contact-us";
export const SG_FIND_DULUX_STORE_URL =
  "https://www.dulux.com.sg/en/storefinder";
export const SG_SITEMAP_URL = "https://www.dulux.com.sg/en/html-site-map";
export const SG_DULUX_COLOURS_URL =
  "https://www.dulux.com.sg/en/colour-palettes";
export const SG_PRODUCTS_URL = "https://www.dulux.com.sg/en/product-overview";
export const SG_DECORATION_IDEAS_URL =
  "https://www.dulux.com.sg/en/colour-inspiration";
export const SG_ACCESSIBLITY_URL = "https://www.dulux.com.sg/en/accessibility";
export const SG_COLOUR_ACCURACY_URL =
  "https://www.dulux.com.sg/en/colour-accuracy";

export const ID_FIND_COLOUR_URL = "https://www.dulux.co.id/id/palet-warna";
export const ID_CHOOSE_PRODUCT_URL =
  "https://www.dulux.co.id/id/product-overview";
export const ID_IDEAS_URL = "https://www.dulux.co.id/id/inspirasi-warna";
export const ID_EXPERT_HELP_URL =
  "https://www.dulux.co.id/id/tips-dan-saran-dekorasi";
export const ID_FIND_STORE_URL = "https://www.dulux.co.id/id/storefinder";
export const ID_FACEBOOK_URL = "https://www.facebook.com/LetsColourIndonesia/";
export const ID_YOUTUBE_URL = "https://www.youtube.com/user/LetsColourID";
export const ID_INSTAGRAM_URL = "http://www.instagram.com/letscolourid/";
export const ID_ABOUT_DULUX_URL = "https://www.dulux.co.id/id/tentang-kami";
export const ID_CONTACT_US_URL = "https://www.dulux.co.id/id/hubungi-kami";
export const ID_FIND_DULUX_STORE_URL = "https://www.dulux.co.id/id/storefinder";
export const ID_SITEMAP_URL = "https://www.dulux.co.id/id/peta-situs-html";
export const ID_DULUX_COLOURS_URL = "https://www.dulux.co.id/id/palet-warna";
export const ID_PRODUCTS_URL = "https://www.dulux.co.id/id/product-overview";
export const ID_DECORATION_IDEAS_URL =
  "https://www.dulux.co.id/id/inspirasi-warna";
export const ID_ACCESSIBLITY_URL = "https://www.dulux.co.id/id/aksesibilitas";
export const ID_COLOUR_ACCURACY_URL =
  "https://www.dulux.co.id/id/akurasi-warna";

export const VIETNAM_FIND_COLOUR_URL =
  "https://www.dulux.vn/vi/mau-sac-bang-mau/h_white?brand=Dulux&hue-auto-load=1";
export const VIETNAM_CHOOSE_PRODUCT_URL =
  "https://www.dulux.vn/vi/san-pham/b_Dulux?brand=Dulux";
export const VIETNAM_IDEAS_URL = "https://www.dulux.vn/vi/y-tuong?brand=Dulux";
export const VIETNAM_EXPERT_HELP_URL =
  "https://www.dulux.vn/vi/meo-va-loi-khuyen-trang-tri-nha?brand=Dulux";
export const VIETNAM_FIND_STORE_URL =
  "https://www.dulux.vn/vi/storefinder?brand=Dulux";
export const VIETNAM_FACEBOOK_URL = "https://www.facebook.com/duluxvn/";
export const VIETNAM_YOUTUBE_URL = "https://www.youtube.com/user/duluxvietnam";
export const VIETNAM_ABOUT_DULUX_URL = "https://www.dulux.vn/vi/ve-flourish";
export const VIETNAM_CONTACT_US_URL = "https://www.dulux.vn/vi/lien-he";
export const VIETNAM_FIND_DULUX_STORE_URL =
  "https://www.dulux.vn/vi/storefinder";
export const VIETNAM_SITEMAP_URL = "https://www.dulux.vn/vi/html-so-do-trang";
export const VIETNAM_DULUX_COLOURS_URL =
  "https://www.dulux.vn/vi/mau-sac-bang-mau";
export const VIETNAM_PRODUCTS_URL = "https://www.dulux.vn/vi/san-pham";
export const VIETNAM_DECORATION_IDEAS_URL = "https://www.dulux.vn/vi/y-tuong";
export const VIETNAM_ACCESSIBLITY_URL =
  "https://www.dulux.vn/vi/kh-nng-truy-cp-thong-tin";
export const VIETNAM_COLOUR_ACCURACY_URL =
  "https://www.dulux.vn/vi/tinh-chinh-xac-ve-mau-sac?brand=Dulux";

export const INDIA_FIND_COLOUR_URL =
  "https://www.dulux.in/en/colour-palettes/h_white?brand=Dulux&hue-auto-load=1";
export const INDIA_CHOOSE_PRODUCT_URL =
  "https://www.dulux.in/en/products/b_Dulux?brand=Dulux";
export const INDIA_IDEAS_URL =
  "https://www.dulux.in/en/colour-inspiration?brand=Dulux";
export const INDIA_EXPERT_HELP_URL =
  "https://www.dulux.in/en/decorating-tips-and-advice?brand=Dulux";
export const INDIA_FIND_STORE_URL =
  "https://www.dulux.in/en/storefinder?brand=Dulux";
export const INDIA_FACEBOOK_URL = "https://www.facebook.com/Dulux.In/";
export const INDIA_YOUTUBE_URL = "https://www.youtube.com/user/duluxindia";
export const INDIA_INSTAGRAM_URL = "https://www.instagram.com/dulux.india/";
export const INDIA_ABOUT_DULUX_URL = "https://www.dulux.in/en/about-dulux";
export const INDIA_CONTACT_US_URL = "https://www.dulux.in/";
export const INDIA_FIND_DULUX_STORE_URL =
  "https://www.dulux.in/en/storefinder?brand=Dulux";
export const INDIA_SITEMAP_URL = "https://www.dulux.in/en/site-map";
export const INDIA_DULUX_COLOURS_URL =
  "https://www.dulux.in/en/colour-palettes/h_white?hue-auto-load=1";
export const INDIA_PRODUCTS_URL = "https://www.dulux.in/en/product-overview";
export const INDIA_DECORATION_IDEAS_URL =
  "https://www.dulux.in/en/colour-inspiration";
export const INDIA_ACCESSIBLITY_URL = "https://www.dulux.in/en/accessibility";
export const INDIA_COLOUR_ACCURACY_URL =
  "https://www.dulux.in/en/colour-accuracy";

export const PAKISTAN_FIND_COLOUR_URL =
  "https://www.dulux.com.pk/en/colour-palettes";
export const PAKISTAN_CHOOSE_PRODUCT_URL =
  "https://www.dulux.com.pk/en/products";
export const PAKISTAN_IDEAS_URL =
  "https://www.dulux.com.pk/en/colour-inspiration";
export const PAKISTAN_EXPERT_HELP_URL =
  "https://www.dulux.com.pk/en/decorating-tips-and-advice";
export const PAKISTAN_FIND_STORE_URL =
  "https://www.dulux.com.pk/en/storefinder";
export const PAKISTAN_FACEBOOK_URL = "https://www.facebook.com/duluxpakistan/";
export const PAKISTAN_YOUTUBE_URL =
  "https://www.youtube.com/channel/UCOYzUeDJ6HsldK_l6Vg_auQ";
export const PAKISTAN_INSTAGRAM_URL = "https://www.instagram.com/duluxpk/";
export const PAKISTAN_ABOUT_DULUX_URL = "https://www.dulux.com.pk/en/about-us";
export const PAKISTAN_CONTACT_US_URL = "https://www.dulux.com.pk/en/contact-us";
export const PAKISTAN_FIND_DULUX_STORE_URL =
  "https://www.dulux.com.pk/en/storefinder";
export const PAKISTAN_SITEMAP_URL = "https://www.dulux.com.pk/en/html-site-map";
export const PAKISTAN_DULUX_COLOURS_URL =
  "https://www.dulux.com.pk/en/colour-palettes";
export const PAKISTAN_PRODUCTS_URL = "https://www.dulux.com.pk/en/products";
export const PAKISTAN_DECORATION_IDEAS_URL =
  "https://www.dulux.com.pk/en/colour-inspiration";
export const PAKISTAN_ACCESSIBLITY_URL =
  "https://www.dulux.com.pk/en/accessibility";
export const PAKISTAN_COLOUR_ACCURACY_URL =
  "https://www.dulux.com.pk/en/colour-accuracy";

export const SRI_LANKA_FIND_COLOUR_URL =
  "https://www.dulux.lk/en/colour-palettes";
export const SRI_LANKA_CHOOSE_PRODUCT_URL = "https://www.dulux.lk/en/products";
export const SRI_LANKA_IDEAS_URL = "https://www.dulux.lk/en/colour-inspiration";
export const SRI_LANKA_EXPERT_HELP_URL =
  "https://www.dulux.lk/en/decorating-tips-and-advice";
export const SRI_LANKA_FIND_STORE_URL =
  "https://www.dulux.lk/en/find-a-stockist";
export const SRI_LANKA_FACEBOOK_URL = "https://www.facebook.com/dulux.lk/";
export const SRI_LANKA_YOUTUBE_URL =
  "https://www.youtube.com/channel/UC1bFQZWYFpI-x2WmFTlKaXw";
export const SRI_LANKA_ABOUT_DULUX_URL = "https://www.dulux.lk/en/about-us";
export const SRI_LANKA_CONTACT_US_URL = "https://www.dulux.lk/en/contact-us";
export const SRI_LANKA_FIND_DULUX_STORE_URL =
  "https://www.dulux.lk/en/find-a-stockist";
export const SRI_LANKA_SITEMAP_URL = "https://www.dulux.lk/en/html-site-map";
export const SRI_LANKA_DULUX_COLOURS_URL =
  "https://www.dulux.lk/en/colour-palettes#white";
export const SRI_LANKA_PRODUCTS_URL = "https://www.dulux.lk/en/products";
export const SRI_LANKA_DECORATION_IDEAS_URL =
  "https://www.dulux.lk/en/colour-inspiration";
export const SRI_LANKA_ACCESSIBLITY_URL =
  "https://www.dulux.lk/en/accessibility";
export const SRI_LANKA_COLOUR_ACCURACY_URL =
  "https://www.dulux.lk/en/colour-accuracy";

export const THAILAND_FIND_COLOUR_URL =
  "https://www.dulux.co.th/th/colour-palettes";
export const THAILAND_CHOOSE_PRODUCT_URL =
  "https://www.dulux.co.th/th/products";
export const THAILAND_IDEAS_URL =
  "https://www.dulux.co.th/th/colour-inspiration";
export const THAILAND_EXPERT_HELP_URL =
  "https://www.dulux.co.th/th/decorating-tips-and-advice";
export const THAILAND_FIND_STORE_URL = "https://www.dulux.co.th/th/storefinder";
export const THAILAND_FACEBOOK_URL = "https://www.facebook.com/DuluxThailand";
export const THAILAND_YOUTUBE_URL =
  "https://www.youtube.com/user/DuluxThailand";
export const THAILAND_INSTAGRAM_URL =
  "https://www.instagram.com/dulux.thailand/?hl=en";
export const THAILAND_LINE_URL = "https://line.me/R/ti/p/%40pct1997v";
export const THAILAND_ABOUT_DULUX_URL = "https://www.dulux.co.th/th/about-us";
export const THAILAND_CONTACT_US_URL = "https://www.dulux.co.th/th/contact-us";
export const THAILAND_FIND_DULUX_STORE_URL =
  "https://www.dulux.co.th/th/storefinder";
export const THAILAND_SITEMAP_URL = "";
export const THAILAND_DULUX_COLOURS_URL =
  "https://www.dulux.co.th/th/colour-palettes#white";
export const THAILAND_PRODUCTS_URL = "https://www.dulux.co.th/th/products";
export const THAILAND_DECORATION_IDEAS_URL =
  "https://www.dulux.co.th/th/colour-inspiration";
export const THAILAND_ACCESSIBLITY_URL = "";
export const THAILAND_COLOUR_ACCURACY_URL =
  "https://www.dulux.co.th/th/colour-accuracy";
export const THAILAND_EXPERT_HELP_URL_SERVICE =
  "https://www.dulux.co.th/th/decorating-tips-and-advice/preview-service";
