import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  OriginalRefernceImageDiv,
  OriginalBodyColumn,
  OriginalReferenceImageText,
  OriginalReferenceImage,
  PaletteDiv,
  ReferenceBodyColumn,
  PaletteListDiv,
  ToolTip,
} from "../../styles/PreviewSupervisorResultStyles";
import LanguageSelector from "../../language/LanguageSelector";

const ResultOriginal = ({ state }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <OriginalRefernceImageDiv>
      <OriginalBodyColumn>
        <OriginalReferenceImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ORIGINAL"}
            /></OriginalReferenceImageText>
        <OriginalReferenceImage
          src={state.data && state.data?.result?.request_details?.raw_image}
        />
      </OriginalBodyColumn>

      <ReferenceBodyColumn>
      {state.data?.result?.request_details?.colourization_type == "Reference" && (
        <OriginalReferenceImageText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE"}
            />
        </OriginalReferenceImageText>)}

        {state.data?.result?.request_details?.colourization_type == "Theme" && (
        <OriginalReferenceImageText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            />
        </OriginalReferenceImageText>)}

        {state.data?.result?.request_details?.colourization_type == "Lead Colour" && (
        <OriginalReferenceImageText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            />
        </OriginalReferenceImageText>)}


        {getReferenceImage({ data: state.data })}
      </ReferenceBodyColumn>
    </OriginalRefernceImageDiv>
  );
};

const getReferenceImage = ({ data }) => {
  if (data?.result?.request_details?.colourization_type === "Theme") {
    return (
      <>
        {data?.palette?.palette_1_colours.length > 0 && (
          <PaletteListDiv>
            {data?.palette?.palette_1_colours.map((paletteItem, index) => {
              return (
                <PaletteDiv
                  style={{
                    backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                  }}
                  key={`${index}`}
                >
                  <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                </PaletteDiv>
              );
            })}
            {data?.palette?.palette_2_colours.map((paletteItem, index) => {
              return (
                <PaletteDiv
                  style={{
                    backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                  }}
                  key={`${index}`}
                >
                  <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                </PaletteDiv>
              );
            })}
            {data?.palette?.palette_3_colours.map((paletteItem, index) => {
              return (
                <PaletteDiv
                  style={{
                    backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                  }}
                  key={`${index}`}
                >
                  <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                </PaletteDiv>
              );
            })}
          </PaletteListDiv>
        )}
      </>
    );
  } else if (
    data?.result?.request_details?.colourization_type === "Reference"
  ) {
    return (
      <OriginalReferenceImage
        src={data && data?.result?.request_details?.reference_image}
        alt="Theme"
      />
    );
  } else {
    return (<PaletteDiv
      style={{
        backgroundColor: 
        `rgb(${data?.result?.report_details?.results[0]?.colours[0]?.red_value},${data?.result?.report_details?.results[0]?.colours[0]?.green_value},${data?.result?.report_details?.results[0]?.colours[0]?.blue_value})`,
      }}
    >
      <ToolTip>{`${data?.result?.request_details?.colour_name}`}</ToolTip>
    </PaletteDiv>)
  }
};

export default ResultOriginal;
