export const language = {
  FIND_A_COLOUR: {
    singapore: "Find a colour",
    indonesia: "Temukan warna",
    thailand: "ค้นหาสี",
    vietnam: "Tìm màu sắc",
  },
  CHOOSE_A_PRODUCT: {
    singapore: "Choose a product",
    indonesia: "Pilih produk",
    thailand: "เลือกผลิตภัณฑ์",
    vietnam: "Chọn sản phẩm",
  },
  IDEAS: {
    singapore: "Ideas",
    indonesia: "Ide",
    thailand: "ไอเดียการตกแต่ง",
    vietnam: "Ý tưởng",
  },
  EXPERT_HELP: {
    singapore: "Expert Help",
    indonesia: "Bantuan Ahli",
    thailand: "ความช่วยเหลือจากผู้เชี่ยวชาญ",
    vietnam: "Trợ giúp từ chuyên gia",
  },
  FIND_A_STORE: {
    singapore: "Find a store",
    indonesia: "Temukan toko",
    thailand: "ค้นหาร้านค้า",
    vietnam: "Tìm một cửa hàng",
  },
  LOGIN: {
    singapore: "Login",
    indonesia: "Masuk",
    thailand: "เข้าสู่ระบบ",
    vietnam: "Đăng nhập",
  },
  DULUX_PREVIEW_SERVICE: {
    malaysia: "Dulux Colour Preview Service",
    singapore: "Dulux Preview Service",
    indonesia: "Dulux Preview Service",
    thailand: "บริการออกแบบสีบ้าน \nจากดูลักซ์",
    vietnam: "Dịch vụ tư vấn phối màu Dulux",
  },
  FIRST_OF_ITS_KIND: {
    singapore:
      "A novel concept that delivers personalised designs to you in 3 easy steps: SNAP, PREVIEW and PAINT.",
    indonesia:
      "Konsep baru yang memberikan desain yang dipersonalisasi untuk Anda dalam 3 langkah mudah: SNAP, PREVIEW, dan CAT.",
    thailand:
      "แนวคิดใหม่ในการออกแบบสีบ้านด้วยตัวคุณเอง \nเพียง 2 ขั้นตอนง่ายๆ ส่งรูปบ้าน และเลือกสีที่ชอบ\nสอบถามข้อมูลเพิ่มเติม",
    vietnam:
      "Để đội ngũ thiết kế chuyên nghiệp của chúng tôi giúp bạn thiết kế màu sắc ngôi nhà theo phong cách bạn thích. Miễn phí!",
  },
  USER_GEOLOCATION_DENIED: {
    singapore: "User denied the request for Geolocation.",
    indonesia: "User denied the request for Geolocation.",
    thailand: "User denied the request for Geolocation.",
    vietnam: "Chức năng này không thể thực hiện vì bạn đã chặn chia sẻ vị trí",
  },
  GEOLOCATION_NOT_SUPPORTED: {
    singapore: "Geolocation is not supported on this browser.",
    indonesia: "Geolocation is not supported on this browser.",
    thailand: "Geolocation is not supported on this browser.",
    vietnam: "Trình duyệt này không hỗ trợ định vị địa lý",
  },
  SET_CITY_MANUALLY: {
    singapore: "City not found, set city manually.",
    indonesia: "City not found, set city manually.",
    thailand: "City not found, set city manually.",
    vietnam: "Không tìm thấy thành phố, nhập thành phố theo cách thủ công",
  },
  UPLOAD_IMAGE_TYPE: {
    singapore: "Upload image and choose a type",
    indonesia: "Upload image and choose a type",
    thailand: "Upload image and choose a type",
    vietnam: "Phân loại người dùng không có quyền truy cập",
  },
  LOCATION_INFO_UNAVAIL: {
    singapore: "Location information is unavailable.",
    indonesia: "Location information is unavailable.",
    thailand: "Location information is unavailable.",
    vietnam: "Thông tin vị trí không khả dụng",
  },
  LOCATION_INFO_FAILED: {
    singapore: "Fail to get location",
    indonesia: "Fail to get location",
    thailand: "Fail to get location",
    vietnam: "Không tìm được vị trí",
  },
  USER_LOCATION_REQ_TIMEOUT: {
    singapore: "The request to get user location timed out.",
    indonesia: "The request to get user location timed out.",
    thailand: "The request to get user location timed out.",
    vietnam: "Yêu cầu truy cập vị trí hết hạn",
  },
  ENTER_VALID_CONTACT: {
    singapore: "Enter a valid contact number.",
    indonesia: "Enter a valid contact number.",
    thailand: "Enter a valid contact number.",
    vietnam: "Nhập số điện thoại liên hệ",
  },
  ENTER_DETAILS: {
    singapore: "Enter name, city, address and contact details.",
    indonesia: "Enter name, city, address and contact details.",
    thailand: "Enter name, city, address and contact details.",
    vietnam: "Nhập tên, thành phố, địa chỉ và thông tin liên hệ",
  },
  UNKNOWN_ERROR_WENT_WRONG: {
    singapore: "An unknown error occurred.",
    indonesia: "An unknown error occurred.",
    thailand: "An unknown error occurred.",
    vietnam: "Có lỗi xảy ra",
  },
  SOMETHING_WENT_WRONG: {
    singapore: "Something went wrong.",
    indonesia: "Something went wrong.",
    thailand: "Something went wrong.",
    vietnam: "Đã có lỗi xảy ra",
  },
  PERSONALISED_DESIGNS: {
    singapore: "Personalised designs within \n24 hours*",
    indonesia: "Desain sesuai keinginan Anda dalam \n24 jam*",
    thailand: "รอรับแบบสีบ้านตามสไตล์ที่คุณชอบได้ภายใน \n24 ชั่วโมง",
    vietnam: "Tạo bản phối màu riêng trong vòng \n24 giờ",
  },
  PERSONALISED_DESIGNS_small: {
    singapore: "Personalised designs within 24 hours*",
    indonesia: "Desain sesuai keinginan Anda dalam 24 jam*",
    thailand: "รอรับแบบสีบ้านตามสไตล์ที่คุณชอบได้ภายใน 24 ชั่วโมง",
    vietnam: "Tạo bản phối màu riêng trong vòng 24 giờ",
  },
  GET_FREE_AND_PERSONALISED: {
    singapore:
      "Get free and personalised colour recommendations for your home from experts in under 24 hours",
    indonesia:
      "Dapatkan rekomendasi warna untuk rumah \nAnda dalam waktu 24 jam, gratis!",
    thailand:
      "รับคำแนะนำฟรีภายใน 24 ชั่วโมงจากผู้เชี่ยวชาญด้านสีสันเพื่อบ้านของคุณโดยเฉพาะ",
    vietnam:
      "Nhận tư vấn phối màu miễn phí từ các chuyên gia dành riêng cho ngôi nhà của bạn trong vòng 24 giờ",
  },
  GET_FREE_AND_PERSONALISED_small: {
    singapore:
      "Get free and personalised colour recommendations for your home from experts in under 24 hours",
    indonesia:
      "Dapatkan rekomendasi warna untuk rumah Anda dalam waktu 24 jam, gratis!",
    thailand:
      "รับคำแนะนำฟรีภายใน 24 ชั่วโมงจากผู้เชี่ยวชาญด้านสีสันเพื่อบ้านของคุณโดยเฉพาะ",
    vietnam:
      "Nhận tư vấn phối màu miễn phí từ các chuyên gia dành riêng cho ngôi nhà của bạn trong vòng 24 giờ",
  },
  PERSONALISED_TERMS_AND_CONDITIONS_ONE: {
    singapore: "*Find out more at our ",
    indonesia: "*Cari tahu lebih lanjut di ",
    thailand: "*ค้นหาข้อมูลเพิ่มเติมได้ที่ ",
    vietnam: "Tìm hiểu thêm tại ",
  },
  PERSONALISED_TERMS_AND_CONDITIONS_ONE_small: {
    singapore: "*Find out more at our ",
    indonesia: "*Cari tahu lebih lanjut di ",
    thailand: "*ค้นหาข้อมูลเพิ่มเติมได้ที่ ",
    vietnam: "Tìm hiểu thêm tại ",
  },
  PERSONALISED_TERMS_AND_CONDITIONS_TWO: {
    singapore: "Terms & Conditions",
    indonesia: "Syarat dan Ketentuan kami",
    thailand: "ข้อตกลงและเงื่อนไข",
    vietnam: "Điều khoản và điều kiện",
  },
  PERSONALISED_TERMS_AND_CONDITIONS_TWO_small: {
    singapore: "Terms & Conditions",
    indonesia: "Syarat dan Ketentuan kami",
    thailand: "ข้อตกลงและเงื่อนไข",
    vietnam: "Điều khoản và điều kiện",
  },
  FOR_REQUESTS_SENT_DURING: {
    singapore:
      "*For requests sent during business days only. If the request falls \non weekends or public holidays, it will be counted \ntowards the next business day.",
    indonesia:
      "*For requests sent during business days only. If the request falls \non weekends or public holidays, it will be counted \ntowards the next business day.",
    thailand:
      "*สำหรับคำขอที่ส่งในวันทำการเท่านั้น หากคำขอตรงกับ  nในวันหยุดสุดสัปดาห์หรือวันหยุดราชการคำขอจะถูกนับ  nในวันทำการถัดไป",
    vietnam:
      "*Chỉ dành cho các yêu cầu được gửi trong ngày làm việc. \n Nếu yêu cầu rơi vào cuối tuần hoặc ngày lễ, \n yêu cầu sẽ được tính vào ngày làm việc tiếp theo.",
  },
  FOR_REQUESTS_SENT_DURING_small: {
    singapore:
      "*For requests sent during business days only. If the request falls on weekends or public holidays, it will be counted towards the next business day.",
    indonesia:
      "*For requests sent during business days only. If the request falls on weekends or public holidays, it will be counted towards the next business day.",
    thailand:
      "*สำหรับคำขอที่ส่งในวันทำการเท่านั้น หากคำขอตรงกับวันหยุดสุดสัปดาห์หรือวันหยุดนักขัตฤกษ์คำขอจะถูกนับในวันทำการถัดไป",
    vietnam:
      "Chỉ dành cho các yêu cầu được gửi trong ngày làm việc. Nếu yêu cầu rơi vào cuối tuần hoặc ngày lễ, yêu cầu sẽ được tính vào ngày làm việc tiếp theo.",
  },
  HOW_IT_WORKS: {
    singapore: "How it works",
    indonesia: "Cara kerjanya",
    thailand: "วิธีการทำงาน",
    vietnam: "Quy trình làm việc",
  },
  GET_STARTED: {
    singapore: "Get Started",
    indonesia: "Langkah Awal",
    thailand: "เริ่มต้นใช้งาน",
    vietnam: "Bắt đầu",
  },
  UPLOAD_IMAGE_OF_YOUR: {
    singapore:
      "Upload image of your room \nor house that you want to \nrecolour and preview.",
    indonesia:
      "Unggah gambar kamar/ruangan \natau rumah yang ingin Anda \ncat ulang dan pratinjau.",
    thailand: "อัปโหลดภาพห้องหรือบ้านที่คุณต้องการ\nทาสีใหม่",
    vietnam:
      "Tải lên hình ảnh căn phòng \nhoặc ngôi nhà mà bạn muốn \nsơn lại và xem phối màu trước.",
  },
  UPLOAD_IMAGE_OF_YOUR_small: {
    singapore:
      "Upload image of your room or \nhouse that you want to recolour and preview.",
    indonesia:
      "Unggah gambar kamar/ruangan atau \nrumah yang ingin Anda cat ulang dan pratinjau.",
    thailand: "อัปโหลดภาพห้องหรือบ้านที่คุณต้องการ\nทาสีใหม่",
    vietnam:
      "Tải lên hình ảnh căn phòng hoặc \nngôi nhà mà bạn muốn sơn lại và xem phối màu trước.",
  },
  CHOOSE_A_METHOD: {
    singapore: "Choose a method",
    indonesia: "Pilih metode",
    thailand: "เลือกวิธี",
    vietnam: "Chọn phương thức",
  },
  UPLOAD_A_REFERENCE_IMAGE: {
    singapore:
      "Upload a reference image, \nchoose a colour shade \nor a preset theme.",
    indonesia:
      "Unggah gambar referensi, \ntentukan nuansa warna \natau tema warna.",
    thailand: "อัปโหลดรูปภาพอ้างอิง หรือเลือกธีมสี/\nเฉดสีที่กำหนดไว้",
    vietnam:
      "Tải lên hình ảnh đối chiếu, \nchọn màu sắc \nhoặc chủ đề thiết lập sẵn.",
  },
  UPLOAD_A_REFERENCE_IMAGE_small: {
    singapore:
      "Upload a reference image, choose a \ncolour shade or a preset theme.",
    indonesia:
      "Unggah gambar referensi, tentukan \nnuansa warna atau tema warna.",
    thailand: "อัปโหลดรูปภาพอ้างอิง หรือเลือกธีมสี/\nเฉดสีที่กำหนดไว้",
    vietnam:
      "Tải lên hình ảnh đối chiếu, chọn \nmàu sắc hoặc chủ đề thiết lập sẵn.",
  },
  MAGIC_HAPPENS: {
    singapore: "Magic happens",
    indonesia: "Keajaiban terjadi",
    thailand: "สิ่งมหัศจรรย์จะเกิดขึ้น",
    vietnam: "Hô biến màu sắc",
  },
  OUR_DULUX_COLOUR_EXPERTS: {
    singapore:
      "Our Dulux colour experts \ncurate the generated results \ncatering to your needs.",
    indonesia:
      "Pakar warna Dulux akan \nmelakukan penilaian yang disesuaikan \ndengan keinginan Anda.",
    thailand:
      "ผู้เชี่ยวชาญด้านสีสันจากดูลักซ์จะคัดสรร\nผลลัพธ์ที่สร้างขึ้นตามความต้องการของคุณ",
    vietnam:
      "Các chuyên gia về màu sắc của Dulux sẽ \nsắp xếp các kết quả có được cho \nphù hợp với nhu cầu của bạn.",
  },
  OUR_DULUX_COLOUR_EXPERTS_small: {
    singapore:
      "Our Dulux colour experts curate the \ngenerated results catering to your needs.",
    indonesia:
      "Pakar warna Dulux akan melakukan \npenilaian yang disesuaikan dengan keinginan Anda.",
    thailand:
      "ผู้เชี่ยวชาญด้านสีสันจากดูลักซ์จะคัดสรร\nผลลัพธ์ที่สร้างขึ้นตามความต้องการของคุณ",
    vietnam:
      "Các chuyên gia về màu sắc của Dulux sẽ sắp xếp các \nkết quả có được cho phù hợp với nhu cầu của bạn.",
  },
  GET_RESULTS: {
    singapore: "Get results",
    indonesia: "Dapatkan hasilnya",
    thailand: "รับแบบสี",
    vietnam: "Nhận kết quả",
  },
  GET_A_PREVIEW: {
    singapore:
      "Get a preview, store \nrecommendations and more to \nchoose better.",
    indonesia:
      "Dapatkan simulasi warna, \nrekomendasi toko dan lainnya \nuntuk pemilihan yang lebih baik.",
    thailand: "รับแบบสี พร้อมคำแนะนำเกี่ยวกับร้านค้า\n และอื่นๆอีกมากมาย",
    vietnam:
      "Nhận bản phối màu trước, lưu lại các ý tưởng \nđề xuất và nội dung khác giúp lựa \nchọn hiệu quả hơn.",
  },
  GET_A_PREVIEW_small: {
    singapore:
      "Get a preview, store recommendations \nand more to choose better.",
    indonesia:
      "Dapatkan simulasi warna, rekomendasi \ntoko dan lainnya untuk pemilihan yang lebih baik.",
    thailand: "รับแบบสี พร้อมคำแนะนำเกี่ยวกับร้านค้า\n และอื่นๆอีกมากมาย",
    vietnam:
      "Nhận bản phối màu trước, lưu lại các ý tưởng đề xuất \nvà nội dung khác giúp lựa chọn hiệu quả hơn.",
  },
  LATEST_COLOUR_TRENDS: {
    singapore: "Latest colour trends",
    indonesia: "Warna tren terkin",
    thailand: "เทรนด์สีล่าสุด",
    vietnam: "Xu hướng sắc màu mới nhất",
  },
  DISCLAIMER_AI: {
    singapore:
      "We have made every effort to make the colours on screen as accurate as possible. \n Unfortunately, we cannot guarantee an exact colour match of the on screen colour to the colours of the actual products and the colour appearing on screen should not be relied on as being such. \n Colours on screen may vary depending on your screen settings and resolution. \n If you are unsure of a colour, please refer to Dulux Colour Card at the dealer outlet for more accurate colours.",
    indonesia:
      "We have made every effort to make the colours on screen as accurate as possible. \n Unfortunately, we cannot guarantee an exact colour match of the on screen colour to the colours of the actual products and the colour appearing on screen should not be relied on as being such. \n Colours on screen may vary depending on your screen settings and resolution. \n If you are unsure of a colour, please refer to Dulux Colour Card at the dealer outlet for more accurate colours.",
    thailand:
      "We have made every effort to make the colours on screen as accurate as possible. \n Unfortunately, we cannot guarantee an exact colour match of the on screen colour to the colours of the actual products and the colour appearing on screen should not be relied on as being such. \n Colours on screen may vary depending on your screen settings and resolution. \n If you are unsure of a colour, please refer to Dulux Colour Card at the dealer outlet for more accurate colours.",
    vietnam:
      "Chúng tôi cố gắng hết sức để làm cho màu sắc minh họa trên màn hình chính xác nhất có thể. Rất tiếc, chúng tôi không thể đảm bảo màu hiển thị trên màn hình trùng khớp hoàn toàn với màu của sản phẩm thực tế. Chúng tôi khuyên bạn không nên phụ thuộc hoàn toàn vào màu xuất hiện trên màn hình. Màu sắc trên màn hình có thể khác nhau tùy thuộc vào chế độ cài đặt và độ phân giải màn hình của bạn. Nếu bạn không chắc chắn về màu sắc, vui lòng tham khảo Bảng màu Dulux tại cửa hàng đại lý để biết màu chính xác hơn",
  },
  GET_EXPERT_RECOMMENDATIONS: {
    singapore:
      "Get expert recommendations and try different colours \nand patterns for your walls from the comfort of your home",
    indonesia:
      "Dapatkan rekomendasi dari ahli dan coba berbagai warna \nserta pola untuk dinding rumah Anda",
    thailand:
      "รับคำแนะนำจากผู้เชี่ยวชาญและลองใช้สีสันและลวดลายต่างๆ สำหรับผนังของคุณได้จากที่บ้านของคุณเอง",
    vietnam:
      "Nhận ý tưởng đề xuất từ chuyên gia và thử các màu sắc \ncùng mẫu trang trí khác nhau cho những bức tường từ chính ngôi nhà ấm cúng của bạn",
  },
  KNOW_MORE: {
    singapore: "Know More",
    indonesia: "Ketahui Selengkapnya",
    thailand: "รับทราบข้อมูลเพิ่มเติม",
    vietnam: "Tìm hiểu thêm",
  },
  CUSTOMISE_LIVING_SPACES: {
    singapore: "Customise living spaces",
    indonesia: "Personalisasikan ruang keluarga",
    thailand: "ตกแต่งพื้นที่อยู่อาศัยตามต้องการ",
    vietnam: "Tùy chỉnh không gian sống",
  },
  GET_YOUR_HOUSE_AND_OFFICE: {
    singapore:
      "Get your house and office walls virtually coloured to re-create \nyour interiors, before even brushing a stroke!",
    indonesia:
      "Desain kembali warna dinding rumah atau kantor Anda secara \nvirtual sebelum memulai pengecatan!",
    thailand:
      "ช่วยออกแบบให้ผนังบ้านและสำนักงานของคุณมีสีสันเสมือนจริง เพื่อสร้างการตกแต่งภายในของคุณขึ้นมาใหม่ก่อนที่จะทาสีจริง",
    vietnam:
      "Phủ màu cho không gian ảo mô phỏng tường nhà và văn phòng để tái sáng tạo \nnội thất trước khi quét sơn!",
  },
  GET_YOUR_HOUSE_AND_OFFICE_small: {
    singapore:
      "Get your house and office walls virtually coloured to re-create your interiors, before even brushing a stroke!",
    indonesia:
      "Desain kembali warna dinding rumah atau kantor Anda secara virtual sebelum memulai pengecatan!",
    thailand:
      "เพิ่มสีสันเสมือนจริงให้ผนังบ้านและออฟฟิศของคุณเพื่อจำลองการตกแต่งภายในใหม่ก่อนที่จะทาสีจริง!",
    vietnam:
      "Phủ màu cho không gian ảo mô phỏng tường nhà và văn phòng để tái sáng tạo nội thất trước khi quét sơn!",
  },
  ADD_SOME_COLOUR_TO_YOUR_FEED: {
    singapore: "Add some colour to your feed",
    indonesia: "Tambahkan beberapa warna ke foto Anda",
    thailand: " ติดตามเราได้ที่",
    vietnam: "Theo dõi chúng tôi tại",
  },

  // Add all sub footer headers and options:
  SUB_FOOTER_HEADERS: {
    singapore: [
      ["Dulux", ["About Dulux", "Contact Us", "Find a Dulux store", "Sitemap"]],
      [
        "Popular Categories",
        ["Dulux Colours", "Products", "Decoration Ideas", "Expert Help"],
      ],
      ["Access", ["Accessibility", "Colour Accuracy"]],
      ["Other Akzonobel Sites", ["AkzoNobel"]],
    ],
    indonesia: [
      [
        "Dulux",
        ["Tentang Dulux", "Hubungi Kami", "Temukan Toko Dulux", "Peta Situs"],
      ],
      [
        "Kategori Populer",
        ["Warna Dulux", "Produk", "Ide Dekorasi", "Expert Help"],
      ],
      ["Akses", ["Aksesibilitas", "Akurasi Warna"]],
      ["Situs Akzonobel Lainnya", ["AkzoNobel"]],
    ],
    thailand: [
      ["ดูลักซ์", ["เกี่ยวกับดูลักซ์", "ติดต่อเรา", "ค้นหาร้านค้า"]],
      [
        "หมวดหมู่ยอดนิยม",
        [
          "เฉดสี",
          "ผลิตภัณฑ์",
          "ไอเดียการตกแต่ง",
          "คำแนะนำจากผู้เชี่ยวชาญ",
          "บริการออกแบบสี",
        ],
      ],
      ["การเข้าถึง", ["ความแม่นยำของสี"]],
    ],
    vietnam: [
      [
        "Dulux",
        [
          "Giới thiệu về Dulux",
          "Liên hệ chúng tôi",
          "Tìm cửa hàng Dulux",
          "Sơ đồ trang web",
        ],
      ],
      [
        "Danh mục phổ biến",
        ["Màu sắc Dulux", "Sản phẩm", "Ý tưởng trang trí", "Expert Help"],
      ],
      ["Tiếp cận", ["Khả năng tiếp cận", "Độ chính xác của màu sắc"]],
      ["Các trang khác của Akzonobel", ["AkzoNobel"]],
    ],
  },
  COOKIES: {
    singapore: "Cookies",
    indonesia: "Cookies",
    thailand: "คุกกี้",
    vietnam: "Cookies",
    india: "Cookies",
    malaysia: "Cookies",
    srilanka: "Cookies",
    pakistan: "Cookies",
  },
  PRIVACY_POLICY: {
    singapore: "Privacy Policy",
    indonesia: "Kebijakan Privasi",
    thailand: "นโยบายความเป็นส่วนตัว",
    vietnam: "Chính sách bảo mật",
    india: "Privacy Policy",
    malaysia: "Privacy Policy",
    srilanka: "Privacy Policy",
    pakistan: "Privacy Policy",
  },
  LEGAL: {
    singapore: "Legal",
    indonesia: "Legal",
    thailand: "ข้อมูลด้านกฎหมาย",
    vietnam: "Pháp lý",
    india: "Legal",
    malaysia: "Legal",
    srilanka: "Legal",
    pakistan: "Legal",
  },
  COPYRIGHT2020: {
    singapore: "Copyright © 2021 Akzonobel Paints",
    indonesia: "Hak Cipta © 2021 Akzonobel Paints",
    thailand: "Copyright © 2021 Akzonobel Paints",
    vietnam: "Bản quyền 2022 thuộc về Công Ty TNHH Akzo Nobel Việt Nam",
  },
  WELCOME_BACK: {
    singapore: "Welcome back!",
    indonesia: "Selamat datang kembali!",
    thailand: "ยินดีต้อนรับกลับมา!",
    vietnam: "Chào mừng bạn quay trở lại!",
  },
  ENTER_THE_EMAIL: {
    singapore:
      "Enter the email you used before to use Dulux Preview Service. \nWe will e-mail you an OTP verification code to login.",
    indonesia:
      "Masukkan email yang Anda gunakan sebelumnya untuk menggunakan Dulux Preview Service. \nKami akan mengirim kode verifikasi OTP ke email Anda untuk masuk",
    thailand:
      "กรอกอีเมลที่คุณใช้ก่อนหน้านี้เพื่อใช้บริการออกแบบสีบ้านจากดูลักซ์ เราจะส่งอีเมลรหัสยืนยันแบบ OTP ให้คุณเพื่อเข้าสู่ระบบ",
    vietnam:
      "Nhập email bạn đã dùng để sử dụng Dịch vụ tư vấn phối màu Dulux. Chúng tôi sẽ gửi cho bạn một mã xác minh OTP qua email để đăng nhập.",
  },
  EMAIL: {
    singapore: "Email",
    indonesia: "Email",
    thailand: "อีเมล",
    vietnam: "Email",
  },
  SEND_OTP: {
    singapore: "Send OTP",
    indonesia: "Kirim OTP",
    thailand: "ส่ง OTP",
    vietnam: "Gửi mã OTP",
  },
  LOGOUT: {
    singapore: "Logout",
    indonesia: "Keluar",
    thailand: "ออกจากระบบ",
    vietnam: "Đăng xuất",
  },
  HI: {
    singapore: "Hi ",
    indonesia: "Hai ",
    thailand: "สวัสดี ",
    vietnam: "Hi ",
  },
  WELCOME_BACK_START_RE_COLOURING: {
    singapore: "Welcome back! Start re-colouring your dreams now.",
    indonesia: "Selamat datang kembali! Yuk, pilih warna impian Anda sekarang!",
    thailand:
      "ยินดีต้อนรับกลับมา! เริ่มแต่งแต้มสีสันใหม่ตามความฝันของคุณได้ตอนนี้เลย",
    vietnam:
      "Chào mừng trở lại! Bắt đầu tô màu lại ước mơ của bạn ngay bây giờ.",
  },
  YOU_HAVE_NOT_RECOLOURED: {
    singapore: "You have not recoloured any images yet. Start now!",
    indonesia:
      "Anda belum memulai simulasi warna pada foto apapun. Mulai sekarang!",
    thailand: "คุณยังไม่ได้เปลี่ยนสีภาพใดๆ เริ่มตอนนี้!",
    vietnam: "Bạn vẫn chưa lấy lại được bất kỳ hình ảnh nào. Bắt đầu bây giờ!",
  },
  RE_COLOUR_IMAGE: {
    singapore: "Re-colour image",
    indonesia: "Re-colour image",
    thailand: "เปลี่ยนสีภาพ",
    vietnam: "Chọn màu khác cho hình ảnh",
  },
  WELCOME_YOU_HAVE_MADE: {
    singapore: "Welcome! You have made ",
    indonesia: "Selamat datang! Sejauh ini, Anda sudah mengajukan ",
    thailand: "ยินดีต้อนรับ! คุณส่งคำขอแบบสี ",
    vietnam: "Chào bạn! Bạn hiện đã thực hiện ",
  },
  PREVIEW_REQUESTS_SO_FAR: {
    singapore: " preview requests so far.",
    indonesia: " simulasi",
    thailand: " แบบแล้ว",
    vietnam: " yêu cầu phối màu.",
  },
  CLICK_HERE_TO_SUBMIT: {
    singapore: "Click here to submit \nanother request",
    indonesia: "Klik di sini untuk \npermintaan simulasi lainnya",
    thailand: "คลิกที่นี่เพื่อส่งคำขออื่น",
    vietnam: "Nhấp vào đây để gửi \nmột yêu cầu khác",
  },
  REQUEST_ID: {
    singapore: "Request ID",
    indonesia: "ID Permintaan Simulasi",
    thailand: "รหัสคำขอ",
    vietnam: "ID yêu cầu",
  },
  RE_COLOUR_BY_THEME: {
    singapore: "Re-colour by: Theme",
    indonesia: "Warnai ulang berdasarkan: Tema",
    thailand: "เปลี่ยนสีตาม: ธีม",
    vietnam: "Chọn màu khác theo: Chủ đề",
  },
  RE_COLOUR_BY_REFERENCE: {
    singapore: "Re-colour by: Reference",
    indonesia: "Warnai ulang berdasarkan: Referensi",
    thailand: "เปลี่ยนสีตาม: ข้อมูลอ้างอิง",
    vietnam: "Chọn màu khác theo: Tham khảo",
  },

  RE_COLOUR_BY_LEAD_COLOUR: {
    singapore: "Re-colour by: Lead Colour",
    indonesia: "Re-colour by: Lead Colour",
    thailand: "Re-colour by: Lead Colour",
    vietnam: "Yêu cầu phối màu theo Màu chủ đạo",
  },

  REQUEST_RECIEVED: {
    singapore: "Request received",
    indonesia: "Permintaan diterima",
    thailand: "ได้รับคำขอแล้ว",
    vietnam: "Đã nhận yêu cầu",
  },
  RE_COLOUR_AGAIN: {
    singapore: "Re-colour again",
    indonesia: "Ulang lagi",
    thailand: "เปลี่ยนสีอีกครั้ง",
    vietnam: "Chọn lại màu khác",
  },
  VIEW_RESULT: {
    singapore: "View Result",
    indonesia: "Lihat Hasil",
    thailand: "ดูผลลัพธ์",
    vietnam: "Xem kết quả",
  },
  RESULTS: {
    singapore: "Results",
    indonesia: "Results",
    thailand: "Results",
    vietnam: "Kết quả",
  },
  UPLOAD_IMAGE: {
    singapore: "Upload Image",
    indonesia: "Unggah Foto",
    thailand: "อัปโหลดรูปภาพ",
    vietnam: "Tải hình ảnh lên",
  },
  CHOOSE_RE_COLOUR: {
    singapore: "Choose re-colour",
    indonesia: "Pilih warna baru",
    thailand: "เลือกลงสีใหม่",
    vietnam: "Chọn màu khác",
  },
  CONFIRM_REQUEST: {
    singapore: "Confirm request",
    indonesia: "Konfirmasi permintaan",
    thailand: "ยืนยันคำขอ",
    vietnam: "Xác nhận yêu cầu",
  },
  DRAG_AND_DROP_IMAGE_HERE: {
    singapore: "Drag and drop images here, or upload files from your computer",
    indonesia: "Tarik dan taruh foto di sini atau unggah dari komputer Anda",
    thailand: "ลากและวางภาพที่นี่ หรืออัปโหลดไฟล์จากคอมพิวเตอร์ของคุณ",
    vietnam: "Kéo và thả hình ảnh tại đây hoặc tải tệp lên từ máy tính của bạn",
  },
  YOUR_IMAGE_MUST_BE_5_MB: {
    singapore: "Your image must be 5 MB or less. Got higher? ",
    indonesia: "Gambar Anda harus 5 MB atau lebih kecil.",
    thailand: "รูปภาพของคุณต้องมีขนาดไม่เกิน 5 MB หากมีรูปใหญ่กว่านั้น",
    vietnam: "Hình ảnh của bạn phải có dung lượng từ 800Kb đến dưới 5Mb.",
  },
  CLICK_HERE_TO_REDUCE_THE_SIZE: {
    singapore: "Click here to reduce the size.",
    indonesia: "Klik di sini untuk mengecilkan ukurannya.",
    thailand: "คลิกที่นี่เพื่อลดขนาด",
    vietnam: "Nhấp vào đây để giảm kích thước.",
  },
  MAKE_SURE_THE_IMAGE_UPLOADED: {
    singapore:
      "Make sure the image uploaded is not \nblurry or taken in a low light \nenvironment.",
    indonesia:
      "Pastikan foto yang diunggah tidak \nburam dan tidak terlalu gelap",
    thailand:
      "ตรวจสอบให้แน่ใจว่ารูปภาพที่อัปโหลดไม่เบลอหรือถ่ายในสภาพแวดล้อมที่มีแสงน้อย",
    vietnam:
      "Đảm bảo hình ảnh tải lên không bị mờ hoặc chụp trong môi trường thiếu sáng.",
  },
  PLEASE_UPLOAD_IMAGES_OF_YOUR_INTERIOR: {
    singapore:
      "Please upload images of your \ninterior or exterior walls that \nyou wish to re-paint.",
    indonesia:
      "Unggah foto dinding interior \natau eksterior yang ingin Anda \ncat ulang.",
    thailand: "โปรดอัปโหลดภาพผนังภายในหรือภายนอกที่คุณต้องการทาสีใหม่",
    vietnam:
      "Vui lòng tải lên hình ảnh các \nbức tường nội thất hoặc ngoại thất mà bạn \nmuốn sơn lại.",
    india:
      "Please upload images of your \ninterior walls that \nyou wish to re-paint.",
  },
  CHANGE: {
    singapore: "Change",
    indonesia: "Ubah",
    thailand: "เปลี่ยน",
    vietnam: "Thay đổi",
  },
  NEXT: {
    singapore: "Next",
    indonesia: "Berikutnya",
    thailand: "ถัดไป",
    vietnam: "Tiếp",
  },
  CHOOSE_THE_IMAGE_TYPE: {
    singapore: "Choose the appropriate type for your image",
    indonesia: "Choose the appropriate type for your image",
    thailand: "Choose the appropriate type for your image",
    vietnam: "Choose the appropriate type for your image",
    india: "Choose the appropriate type for your image",
  },
  EXTRIOR_CONTACT_US: {
    singapore: "Please contact us for painting exterior walls:",
    indonesia: "Please contact us for painting exterior walls:",
    thailand: "Please contact us for painting exterior walls:",
    vietnam: "Please contact us for painting exterior walls:",
    india: "Please contact us for painting exterior walls:",
  },
  EXTRIOR_CONTACT: {
    singapore: " ",
    indonesia: " ",
    thailand: " ",
    vietnam: " ",
    india:
      "Email: customercare.india@akzonobel.com \n Contact: 1800 3000 4455 \n",
  },
  EXTRIOR_WRITE_TO_US: {
    singapore: " ",
    indonesia: " ",
    thailand: " ",
    vietnam: " ",
    india: "Write to us for Customer Support: ",
  },
  EXTRIOR_ADDRESS: {
    singapore: " ",
    indonesia: " ",
    thailand: " ",
    vietnam: " ",
    india:
      "AkzoNobel India Limited \n\n Magnum Towers, 9th Floor,\n Sector 58, Golf Course ext. road,\n Gurugram, Haryana 122011,\n India",
  },
  CHOOSE_THE_MOST_APPROPRIATE: {
    singapore: "Choose the most appropriate situation for you",
    indonesia: "Pilih situasi paling sesuai untuk Anda",
    thailand: "เลือกสถานการณ์ที่เหมาะสมที่สุดสำหรับคุณ",
    vietnam: "Chọn tình huống phù hợp nhất với bạn",
  },
  I_HAVE_AN_IMAGE_WITH_ME: {
    singapore: "I have an image \nwith me",
    indonesia: "Saya punya \ngambarnya",
    thailand: "ฉันมีแบบบ้านที่ชอบ",
    vietnam: "Tôi có sẵn hình ảnh",
  },
  I_D_SIMPLY_LIKE_TO: {
    singapore: "I’d simply like \nto explore Dulux \nThemes",
    indonesia: "Saya hanya ingin \nmencari warnanya",
    thailand: "ธีมสีแนะนำจากดูลักซ์",
    vietnam: "Tôi chỉ muốn \nxem qua các màu",
  },
  I_HAVE_A_COLOUR_IN_MIND: {
    singapore: "I have a colour \nin mind",
    indonesia: "Saya sudah membayangkan \nwarnanya.",
    thailand: "ฉันมีสีในใจอยู่แล้ว",
    vietnam: "Tôi đang cân nhắc một màu",
  },
  THE_REFERENCE_IMAGE_YOU_UPLOAD: {
    singapore:
      "The reference image you upload will be used to replicate the colour on your walls",
    indonesia:
      "Warna pada gambar referensi yang Anda unggah akan digunakan untuk simulasi warna pada dinding Anda",
    thailand: "รูปภาพอ้างอิงที่คุณอัปโหลดจะถูกนำไปใช้เพื่อจำลองสีบนผนังของคุณ",
    vietnam:
      "Hình ảnh đối chiếu bạn tải lên sẽ được sử dụng để áp dụng cho màu sắc bức tường của bạn",
  },
  CHOOSE_FROM_GALLERY: {
    singapore: "Choose from Gallery",
    indonesia: "Pilih dari Galeri",
    thailand: "เลือกจากแกลเลอรี",
    vietnam: "Chọn từ Thư viện ảnh",
  },
  IMAGE_GALLERY: {
    singapore: "Image gallery",
    indonesia: "Galeri gambar",
    thailand: "แกลเลอรีรูปภาพ",
    vietnam: "Thư viện hình ảnh",
  },
  SELECTED_SHADE: {
    singapore: "Selected Shade",
    indonesia: "Selected Shade",
    thailand: "Selected Shade",
    vietnam: "Màu được chọn",
  },
  CHOOSE_FROM_A_RANGE_OF: {
    singapore: "Choose from a range of curated inspirations",
    indonesia: "Pilih dari inspirasi pilihan",
    thailand: "เลือกจากหลากหลายแรงบันดาลใจที่คัดสรรมา",
    vietnam: "Chọn hình ảnh từ thư viện có sẵn",
  },
  DONE: {
    singapore: "Done",
    indonesia: "Selesai",
    thailand: "เสร็จสิ้น",
    vietnam: "Hoàn tất",
  },
  INTERIOR: {
    singapore: "Interior",
    indonesia: "Interior",
    thailand: "ภายใน",
    vietnam: "Nội thất",
  },
  EXTERIOR: {
    singapore: "Exterior",
    indonesia: "Eksterior",
    thailand: "ภายนอก",
    vietnam: "Ngoại thất",
  },
  AMBIANCE_HD: {
    singapore: "Ambiance HD Color",
    indonesia: "Ambiance HD Color",
    thailand: "ธีมสีแนะนำ",
    vietnam: "Bộ sưu tập màu sắc Ambiance HD",
  },
  RECOMMENDED_COLOR_THEMES: {
    singapore: "Recommended Color Themes",
    indonesia: "Recommended Color Themes",
    thailand: "ธีมสีแนะนำ",
    vietnam: "Bộ sưu tập màu sắc gợi ý từ Dulux",
  },
  COLOR_OF_THE_YEAR: {
    singapore: "Color of the year 2021",
    indonesia: "Color of the year 2021",
    thailand: "ธีมสีแห่งปี 2021 จากดูลักซ์",
    vietnam: "Màu Dulux của năm 2021",
  },
  COASTAL_VILLA: {
    singapore: "Coastal Villa",
    indonesia: "Villa Pantai",
    thailand: "วิลล่าริมทะเล",
    vietnam: "Biệt thự bãi biển",
  },
  TROPICAL: {
    singapore: "Tropical",
    indonesia: "Tropis",
    thailand: "เมืองเขตร้อน",
    vietnam: "Nhiệt đới",
  },
  TROPICAL_RETREAT: {
    singapore: "Tropical Retreat",
    indonesia: "Tropis",
    thailand: "เมืองเขตร้อน",
    vietnam: "Ốc đảo nhiệt đới",
  },
  MEDITERRANEAN_CHARM: {
    singapore: "Mediterranean Charm",
    indonesia: "Pesona Mediterania",
    thailand: "สเน่ห์เมดิเตอร์เรเนียน",
    vietnam: "Địa Trung Hải quyến rũ",
  },
  MODERN_CONTEMPORARY: {
    singapore: "Modern Contemporary",
    indonesia: "Kontemporer Modern",
    thailand: "โมเดิร์นร่วมสมัย",
    vietnam: "Đương đại thành thị",
  },
  MODERN_TWIST: {
    singapore: "Modern Twist",
    indonesia: "Unik & Modern",
    thailand: "โมเดิร์นทวิสต์",
    vietnam: "Thiết kế hiện đại",
  },
  CLASSIC_ELEGANCE: {
    singapore: "Classic Elegance",
    indonesia: "Anggun Klasik",
    thailand: "หรูหราคลาสสิก",
    vietnam: "Cổ điển quý phái",
  },
  BOLD: {
    singapore: "Bold",
    indonesia: "Berani",
    thailand: "โดดเด่น",
    vietnam: "Cá tính",
  },
  CALM: {
    singapore: "Calm",
    indonesia: "Lembut",
    thailand: "สงบเงียบ",
    vietnam: "Êm dịu",
  },
  PLAYFUL: {
    singapore: "Playful",
    indonesia: "Ceria",
    thailand: "สนุกสนาน",
    vietnam: "Vui tươi",
  },
  RESTFUL: {
    singapore: "Restful",
    indonesia: "Tenang",
    thailand: "ผ่อนคลาย",
    vietnam: "Yên bình",
  },
  BEDROOM: {
    singapore: "Bedroom",
    indonesia: "Bedroom",
    thailand: "Bedroom",
    vietnam: "Phòng ngủ",
  },
  LIVING_ROOM: {
    singapore: "Living Room",
    indonesia: "Living Room",
    thailand: "Living Room",
    vietnam: "Phòng khách",
  },
  STUDY_ROOM: {
    singapore: "Study Room",
    indonesia: "Study Room",
    thailand: "Study Room",
    vietnam: "Phòng học",
  },
  WARM_NEUTRAL: {
    singapore: "Warm Neutral",
    indonesia: "Warm Neutral",
    thailand: "Warm Neutral",
    vietnam: "Họ màu trung tính ấm",
  },
  WHITE: {
    singapore: "White",
    indonesia: "White",
    thailand: "White",
    vietnam: "Họ màu trắng",
  },
  RED: {
    singapore: "Red",
    indonesia: "Red",
    thailand: "Red",
    vietnam: "Họ màu đỏ",
  },
  ORANGE: {
    singapore: "Orange",
    indonesia: "Orange",
    thailand: "Orange",
    vietnam: "Họ màu cam",
  },
  GOLD: {
    singapore: "Gold",
    indonesia: "Gold",
    thailand: "Gold",
    vietnam: "Họ màu vàng kim",
  },
  YELLOW: {
    singapore: "Yellow",
    indonesia: "Yellow",
    thailand: "Yellow",
    vietnam: "Họ màu vàng",
  },
  LIME: {
    singapore: "Lime",
    indonesia: "Lime",
    thailand: "Lime",
    vietnam: "Họ màu vàng chanh",
  },
  GREEN: {
    singapore: "Green",
    indonesia: "Green",
    thailand: "Green",
    vietnam: "Họ màu xanh lá",
  },
  TEAL: {
    singapore: "Teal",
    indonesia: "Teal",
    thailand: "Teal",
    vietnam: "Họ màu xanh mòng két",
  },
  BLUE: {
    singapore: "Blue",
    indonesia: "Blue",
    thailand: "Blue",
    vietnam: "Họ màu xanh dương",
  },
  VIOLET: {
    singapore: "Violet",
    indonesia: "Violet",
    thailand: "Violet",
    vietnam: "Họ màu tím",
  },
  COOL_NEUTRAL: {
    singapore: "Cool Neutral",
    indonesia: "Cool Neutral",
    thailand: "Cool Neutral",
    vietnam: "Họ màu thiên nhiên",
  },
  EXPLORE_DULUX_THEMES: {
    singapore: "Explore Dulux Themes",
    indonesia: "Telusuri Tema dari Dulux",
    thailand: "สำรวจธีมดูลักซ์",
    vietnam: "Khám phá các Chủ đề của Dulux",
  },
  COMING_SOON: {
    singapore: "(Coming Soon!)",
    indonesia: "(Segera Hadir!)",
    thailand: "(พบกันเร็วๆ นี้!)",
    vietnam: "(Sắp ra mắt!)",
  },
  CHOOSE_A_COLOUR: {
    singapore: "Choose a colour",
    indonesia: "Pilih warna",
    thailand: "เลือกสี",
    vietnam: "Chọn màu sắc",
  },
  DULUX_HAS_A_RANGE_OF_THEMES: {
    singapore: "Dulux has a range of themes to suit all tastes. ",
    indonesia: "Dulux memiliki serangkaian tema yang sesuai berbagai selera.",
    thailand: "ดูลักซ์มีธีมสีมากมายเพื่อตอบโจทย์ทุกรสนิยม",
    vietnam: "Dulux cung cấp nhiều chủ đề phù hợp với mọi thị hiếu.",
  },
  CHOOSE_THE_ENVIROMENT_AND: {
    singapore: "Choose the environment and then a theme to work with.",
    indonesia: "Pilih suasana, kemudian tema pilihan Anda.",
    thailand: "ระบุพื้นที่ทาสีภายใน/ภายนอก จากนั้นเลือกธีมสีที่ต้องการ",
    vietnam: "Chọn không gian và chủ đề để kết hợp.",
  },
  SELECT_A_LEAD_COLOUR: {
    singapore: "1. Select a lead colour of your choice",
    indonesia: "1. Pilih warna utama",
    thailand: "1. เลือกสีหลักที่คุณต้องการ",
    vietnam: "1. Chọn màu sắc chủ đạo mong muốn",
  },
  COLOUR_SEARCH_ERROR: {
    singapore: "The colour you searched for does not exist",
    indonesia: "Warna yang Anda cari tidak ada",
    thailand: "ไม่มีสีที่คุณค้นหา",
    vietnam: "Màu bạn đã tìm kiếm không tồn tại",
  },
  SELECT_A_COLOUR_FAMILY: {
    singapore: "2. Select a colour family from below",
    indonesia: "2. Pilih kelompok warna di bawah",
    thailand: "2. เลือกตระกูลสีจากด้านล่าง",
    vietnam: "2. Chọn một tông màu dưới đây",
  },
  SUGGESTED_PALETTES: {
    singapore: "Suggested Palettes",
    indonesia: "Palet yang Disarankan",
    thailand: "พาเลทท์สีที่แนะนำ",
    vietnam: "Bảng màu đề xuất",
  },
  YOU_MAY_REMOVE_THE_ONES: {
    singapore: "You may remove the ones you do not like.",
    indonesia: "Anda dapat menghapus warna yang tidak disukai.",
    thailand: "คุณสามารถลบพาเลทท์สีที่คุณไม่ชอบออกได้",
    vietnam: "Bạn có thể loại bỏ bảng màu mà bạn không thích.",
  },
  NEUTRAL_COMBINATIONS: {
    singapore: "Neutral Combinations",
    indonesia: "Neutral Combinations",
    thailand: "ชุดค่าผสมที่เป็นกลาง",
    vietnam: "Phối màu trung tính",
  },
  TONAL_COMBINATIONS: {
    singapore: "Tonal Combinations",
    indonesia: "Kombinasi Warna",
    thailand: "ผสมผสานโทนสี",
    vietnam: "Phối nhiều tông màu",
  },
  DESIGNERL_COMBINATIONS: {
    singapore: "Designer Combinations",
    indonesia: "Designer Combinations",
    thailand: "การรวมกันของนักออกแบบ",
    vietnam: "Thiết kế phối màu",
  },
  COMBINATIONS_WITH_NEUTRALS: {
    singapore: "Combinations with Neutrals",
    indonesia: "Kombinasi dengan warna netral",
    thailand: "ผสมผสานกับเฉดสีกลาง",
    vietnam: "Phối màu trung tính",
  },
  DESIGNERS_CHOICE: {
    singapore: "Designers’ choice",
    indonesia: "Pilihan desainer",
    thailand: "ถูกใจนักออกแบบ",
    vietnam: "Lựa chọn của nhà thiết kế",
  },
  THANK_YOU_FOR_USING: {
    singapore:
      "Thank you for using Dulux Preview service. We will \ne-mail you the results within 24 hours.",
    indonesia:
      "Terima kasih telah menggunakan Dulux Preview Service. \nKami akan mengirimkan hasilnya ke email Anda dalam waktu 24 jam.",
    thailand:
      "ขอขอบคุณที่ใช้บริการออกแบบสีบ้านจากดูลักซ์ เราจะส่งอีเมลผลลัพธ์ให้คุณภายใน 48 ชั่วโมง",
    vietnam:
      "Cảm ơn quý khách đã chọn Dịch vụ phối màu nhà Dulux. \nYêu cầu của quý khách sẽ được phản hồi trong vòng 48 giờ qua email",
  },
  UP_TO_3_REQUESTS_PER: {
    singapore:
      "* Up to 3 requests per customer per day only. \nUpon exceeding 3 requests, the 4th request will be reverted \nto the consumer within 48 hours.",
    indonesia:
      "* Hanya hingga 3 permintaan per pelanggan per hari. \nSetelah melebihi 3 permintaan, permintaan ke-4 akan dikembalikan \nke konsumen dalam waktu 48 jam.",
    thailand:
      "*ไม่เกิน 3 คำขอ (3 รูปภาพ) ต่อลูกค้าต่อวันเท่านั้น เมื่อมีคำขอที่เกินกว่า 3 คำขอ แบบสีจะส่งให้ท่านภายใน 72 ชั่วโมง ",
    vietnam: "",
  },
  SUBMIT_REQUEST: {
    singapore: "Submit Request",
    indonesia: "Kirim Permintaan",
    thailand: "ส่งคำขอ",
    vietnam: "Gửi yêu cầu",
  },
  ADDITIONAL_REQUEST_INFO: {
    singapore:
      "Do provide us with the following additional information where possible:",
    indonesia:
      "Do provide us with the following additional information where possible:",
    thailand:
      "Do provide us with the following additional information where possible:",
    vietnam:
      "Do provide us with the following additional information where possible:",
  },
  THANK_YOU_FOR_SENDING: {
    singapore: "Thank you for your sending your request, our team is on it!",
    indonesia:
      "Terima kasih telah mengirimkan permintaan Anda. Tim kami sedang memprosesnya!",
    thailand: "ขอขอบคุณสำหรับการส่งคำขอของคุณ\nทีมงานของเรากำลังดำเนินการ!",
    vietnam: "Cảm ơn bạn đã gửi yêu cầu, nhóm chúng tôi sẽ xử lý ngay!",
  },
  SIT_BACK_AND_RELAX_ONE: {
    singapore:
      "Sit back and relax, our experts are working on the preview images foryou.",
    thailand:
      "เอนหลังและผ่อนคลาย ผู้เชี่ยวชาญของเรากำลังดำเนินการกับภาพแบบสีตัวอย่างของคุณ",
    indonesia:
      "Sit back and relax, our experts are working on the preview images for you.",
    vietnam: "Cảm ơn quý khách đã chọn Dịch vụ phối màu nhà Dulux.",
  },
  SIT_BACK_AND_RELAX_TWO: {
    singapore: "We will notify you by email once your picture is re-coloured.",
    thailand:
      "เราจะแจ้งกลับคุณทางอีเมล เมื่อแบบสีของคุณดำเนินการเสร็จเรียบร้อยแล้ว",
    indonesia: "We will notify you by email once your picture is re-coloured.",
    vietnam:
      "Yêu cầu của quý khách sẽ được phản hồi trong vòng 48 giờ qua email.",
  },
  REQUEST_LIMIT_ALERT: {
    singapore: "",
    thailand: "",
    indonesia: "",
    vietnam:
      "Yêu cầu của quý khách đã được ghi nhận, tuy nhiên số lượng yêu cầu đã vượt mức giới hạn trong ngày và sẽ được xếp thời gian xử lý với thứ tự ưu tiên thấp hơn.",
  },
  COLOR_REQUEST_FAIL_ALERT: {
    singapore: "",
    thailand: "",
    indonesia: "",
    vietnam:
      "Phân loại người dùng không có quyền truy cập",
  },
  BACK_TO_HOME: {
    singapore: "Back to home",
    thailand: "กลับสู่หน้าหลัก",
    vietnam: "Quay lại trang chủ",
    indonesia: "Kembali ke halaman utama",
  },
  BACK: {
    singapore: "Back",
    thailand: "Back",
    vietnam: "Quay lại",
    indonesia: "Back",
  },
  SUBMIT_ANOTHER_REQUEST: {
    singapore: "Submit another request",
    thailand: "ส่งคำขอเพิ่มเติม",
    vietnam: "Gửi yêu cầu khác",
    indonesia: "Kirim permintaan lain",
  },
  YOU_CHOSE_THEME: {
    singapore: "You just chose to re-colour through a theme",
    thailand: "คุณได้เลือกที่จะเปลี่ยนสีผ่านธีมสีแนะนำ",
    vietnam: "",
    indonesia: "You just chose to re-colour through a theme",
  },
  YOU_CHOSE_REF: {
    singapore: "You just chose to re-colour through a reference image",
    thailand: "คุณได้เลือกที่จะเปลี่ยนสีผ่านแบบบ้านที่ชอบ",
    vietnam: "",
    indonesia: "You just chose to re-colour through a reference image",
  },
  YOU_CHOSE_LEAD: {
    singapore: "You just chose to re-colour through a lead colour image",
    thailand: "คุณได้เลือกที่จะเปลี่ยนสีผ่านแบบบ้านที่ชอบ",
    vietnam: "",
    indonesia: "You just chose to re-colour through a reference image",
  },
  HOW_ABOUT_TRY_SOME_LEAD: {
    singapore: "How about try some lead colours?",
    thailand: "ลองใช้แบบบ้านที่ชอบดูบ้างไหม?",
    vietnam: "Bạn có muốn gửi thêm yêu cầu phối màu khác không?",
    indonesia: "How about trying some reference images?",
  },

  HOW_ABOUT_TRY_SOME_REF: {
    singapore: "How about try some reference images?",
    thailand: "ลองใช้แบบบ้านที่ชอบดูบ้างไหม?",
    vietnam: "Bạn có muốn gửi thêm yêu cầu phối màu khác không?",
    indonesia: "How about trying some reference images?",
  },
  HOW_ABOUT_TRY_SOME_THEMES: {
    singapore: "How about try some themes?",
    indonesia: "Mau mencoba tema lainnya?",
    thailand: "ลองใช้ธีมสีแนะนำดูบ้างไหม?",
    vietnam: "Bạn có muốn thử một số chủ đề không?",
  },
  WE_HAVE_SENT_A_4_DIGIT: {
    singapore: "We have sent a 4 digit one time password to ",
    indonesia: "Kami telah mengirim kata sandi 4 digit sekali pakai ke ",
    thailand: "เราได้ส่งรหัส OTP 4 หลัก สำหรับใช้ครั้งเดียว ไปที่อีเมล",
    vietnam: "Chúng tôi vừa gửi mật khẩu một lần gồm 4 chữ số tới ",
  },
  CHECK_JUNK_MAIL: {
    singapore: " ",
    thailand: "ถ้าไม่พบอีเมลใน Inbox โปรดตรวจสอบใน Junk mail",
    vietnam: " ",
    indonesia: " ",
  },
  RESEND_OTP: {
    singapore: "Resend OTP",
    indonesia: "Kirim ulang OTP",
    thailand: "ขอรหัส OTP",
    vietnam: "Gửi lại mã OTP",
  },
  RESEND_OTP_IN: {
    singapore: "Resend OTP in ",
    indonesia: "Kirim ulang OTP dalam ",
    thailand: "ขอรหัส OTP ใหม่ ",
    vietnam: "Gửi lại mã OTP sau ",
  },
  VERIFY_EMAIL: {
    singapore: "Verify Email",
    indonesia: "Verifikasi Email",
    thailand: "ยืนยันอีเมล",
    vietnam: "Xác nhận email",
  },
  CONGRATS_EMAIL_VERIFICATION_SUCCESSFUL: {
    singapore: "Congrats! Email verification successful. ",
    indonesia: "Selamat! Verifikasi email berhasil. ",
    thailand: "ยินดีด้วย! ยืนยันอีเมลสำเร็จ",
    vietnam: "Xin chúc mừng! Xác minh email thành công. ",
  },
  WE_JUST_NEED: {
    singapore: "We just need a few more details from you",
    indonesia: "Kami perlu sedikit detail Anda",
    thailand: "เราต้องการรายละเอียดเพิ่มเติมเล็กน้อยจากคุณ",
    vietnam: "Chúng tôi cần bạn cung cấp thêm vài thông tin chi tiết",
  },
  NAME_DETAILS: {
    singapore: "Name",
    indonesia: "Name",
    thailand: "ชื่อ-นามสกุล",
    vietnam: "Tên",
  },
  CONTACT_DETAILS: {
    singapore: "Contact number",
    indonesia: "Contact number",
    thailand: "หมายเลขโทรศัพท์",
    vietnam: "Điện thoại liên hệ",
  },
  ADDRESS_DETAILS: {
    singapore: "Address",
    indonesia: "Address",
    thailand: "ที่อยู่",
    vietnam: "Địa chỉ",
  },
  CITY_DETAILS: {
    singapore: "City",
    indonesia: "City",
    thailand: "จังหวัด",
    vietnam: "Tỉnh thành",
  },
  LOCATE_ME: {
    singapore: "Locate me",
    indonesia: "Locate me",
    thailand: "ค้นหาตำแหน่งของฉัน",
    vietnam: "Quét vị trí tự động",
  },
  REMARKS_DETAILS: {
    singapore: "Remarks",
    indonesia: "Remarks",
    thailand: "หมายเหตุ",
    vietnam: "Nhận xét",
  },
  SUBMIT_BUTTON: {
    singapore: "Submit",
    indonesia: "Submit",
    thailand: "ส่งคำขอ",
    vietnam: "Gửi",
  },
  RE_COLOUR_ANOTHER: {
    singapore: "Re-colour another",
    indonesia: "Simulasikan lainnya",
    thailand: "เปลี่ยนสีภาพอื่น",
    vietnam: "Chọn lại màu khác",
  },
  HERE_ARE_YOUR_RESULT: {
    singapore: "Here are your results for Request ID",
    indonesia: "Inilah hasil Anda untuk ID Permintaan Simulasi",
    thailand: "ต่อไปนี้คือผลลัพธ์สำหรับรหัสคำขอ",
    vietnam: "Đây là kết quả của bạn cho ID Yêu cầu",
  },
  OPTION: {
    singapore: "Option ",
    indonesia: "PIlihan ",
    thailand: "ตัวเลือกที่ ",
    vietnam: "Phương án ",
  },
  ORIGINAL: {
    singapore: "Original",
    indonesia: "Sebelum",
    thailand: "ต้นฉบับ",
    vietnam: "Hình ảnh ban đầu",
  },
  REFERENCE: {
    singapore: "Reference",
    indonesia: "Referensi",
    thailand: "ข้อมูลอ้างอิง",
    vietnam: "Hình ảnh tham khảo",
  },
  THEME: {
    singapore: "Theme",
    indonesia: "Theme",
    thailand: "Theme",
    vietnam: "Chủ đề",
  },
  LEAD_COLOUR: {
    singapore: "Lead Colour",
    indonesia: "Lead Colour",
    thailand: "Lead Colour",
    vietnam: "Màu chủ đạo",
  },
  RECOMMENDED_PRODUCTS: {
    singapore: "Recommended Products",
    indonesia: "Produk yang Direkomendasikan",
    thailand: "ผลิตภัณฑ์ที่แนะนำ",
    vietnam: "Sản phẩm đề xuất",
  },
  NEARBY_STORES: {
    singapore: "Nearby stores",
    indonesia: "Toko terdekat",
    thailand: "ร้านค้าใกล้เคียง",
    vietnam: "Cửa hàng gần đây",
  },
  NEARBY_PAINTERS: {
    singapore: "Nearby painters",
    indonesia: "Tukang cat terdekat",
    thailand: "ช่างทาสีใกล้เคียง",
    vietnam: "Nhà Thầu gần đây",
  },
  HELP_US_GET_BETTER: {
    singapore: "Help us get better, rate your experience",
    indonesia: "Bantu kami menjadi lebih baik.",
    thailand:
      "โปรดให้คะแนนประสบการณ์การใช้งานของคุณเพื่อช่วยให้บริการของเราดีขึ้น",
    vietnam: "Đánh giá trải nghiệm của bạn để giúp chúng tôi cải thiện",
  },
  RATE_YOUR_EXPERIENCE: {
    singapore: "rate your experience",
    indonesia: "Berikan nilai untuk pengalaman Anda.",
    thailand: "โปรดให้คะแนนประสบการณ์การใช้งานของคุณ",
    vietnam: "đánh giá trải nghiệm của bạn",
  },
  SHARE_ON_SOCIALS: {
    singapore: "Share on socials",
    indonesia: "Bagikan ke media sosial",
    thailand: "แชร์บนโซเชียล",
    vietnam: "Chia sẻ trên mạng xã hội",
  },
  VIEW_DETAILS: {
    singapore: "View details",
    indonesia: "Lihat detail",
    thailand: "ดูรายละเอียด",
    vietnam: "Xem chi tiết",
  },
  DOWNLOAD: {
    singapore: "Download",
    indonesia: "Unduh",
    thailand: "ดาวน์โหลด",
    vietnam: "Tải về",
  },
  DOWNLOAD_IMAGE: {
    singapore: "Download image",
    indonesia: "Unduh hasilnya",
    thailand: "ดาวน์โหลดรูปภาพ",
    vietnam: "Tải hình ảnh",
  },
  WARN_IMAGE_SIZE_TOO_SMALL: {
    singapore:
      "Please upload an image with size larger than 256 X 256. Current dimension is ",
    indonesia:
      "Please upload an image with size larger than 256 X 256. Current dimension is ",
    thailand:
      "Please upload an image with size larger than 256 X 256. Current dimension is ",
    vietnam:
      "Vui lòng tải ảnh với độ phân giải tối thiểu là 256x256. Hình ảnh hiện tại có độ phân giải là ",
  },
  xxxxxxxx: {
    singapore: "xxxxxxxx",
    indonesia: "xxxxxxxx",
    thailand: "",
    vietnam: "",
  },
  xxxxxxxx: {
    singapore: "xxxxxxxx",
    indonesia: "xxxxxxxx",
    thailand: "",
    vietnam: "",
  },
  xxxxxxxx: {
    singapore: "xxxxxxxx",
    indonesia: "xxxxxxxx",
    thailand: "",
    vietnam: "",
  },
  FINISHED_PUTTY_APPLICATION: {
    singapore: "Finished putty application",
    indonesia: "Aplikasi dempul selesai.",
    thailand: "ฉาบปูนสำเร็จรูป.",
    vietnam: "Đã hoàn thành ứng dụng putty.",
  },
  MINIMIZED_OBSTACLE_BLOCKING_CONSTRUCTION: {
    singapore: "Minimized obstacle blocking construction (Scaffold, tree...)",
    indonesia:
      "Konstruksi penghalang rintangan yang diminimalkan (Perancah, pohon ...)",
    thailand: "ลดการก่อสร้างสิ่งกีดขวาง (นั่งร้าน ต้นไม้...)",
    vietnam: "Giảm thiểu việc xây dựng chướng ngại vật (Giàn che, cây ...)",
  },
  HI_RESOLUTION_FILE: {
    singapore:
      "Hi-resolution file, not allow re-taking photo from printed paper, PC, mobile...",
    indonesia:
      "File resolusi tinggi, tidak mengizinkan pengambilan ulang foto dari kertas cetak, PC, ponsel...",
    thailand:
      "ไฟล์ความละเอียดสูง ไม่อนุญาตให้ถ่ายภาพซ้ำจากกระดาษที่พิมพ์ พีซี มือถือ...",
    vietnam:
      "Tệp có độ phân giải cao, không cho phép chụp lại ảnh từ giấy in, PC, điện thoại di động ...",
  },

  ASK_LOGOUT: {
    singapore: "We are sad to see you go! Are you sure you want to logout?",
    indonesia: "We are sad to see you go! Are you sure you want to logout?",
    thailand: "We are sad to see you go! Are you sure you want to logout?",
    vietnam:
      "Chúng tôi rất tiếc vì bạn muốn dừng lại! Bạn có chắc chắn bạn muốn thoát?",
  },

  CANCEL_LOGOUT: {
    singapore: "Cancel",
    indonesia: "Cancel",
    thailand: "Cancel",
    vietnam: "Huỷ",
  },

  YES_LOGOUT: {
    singapore: "Yes, Logout",
    indonesia: "Yes, Logout",
    thailand: "Yes, Logout",
    vietnam: "Vâng, tôi muốn đăng xuất",
  },

  COUNTRY_DETAILS: {
    singapore: "Select Country",
    indonesia: "Select Country",
    thailand: "Select Country",
    vietnam: "Quốc gia",
  },

  OR: {
    singapore: "OR",
    indonesia: "OR",
    thailand: "OR",
    vietnam: "HOẶC",
  },

  SEARCH: {
    singapore: "Search",
    indonesia: "Search",
    thailand: "Search",
    vietnam: "Tìm kiếm",
  },

  SEARCH_SHADE: {
    singapore: "Search the shade by name or number",
    indonesia: "Search the shade by name or number",
    thailand: "Search the shade by name or number",
    vietnam: "Tìm màu sắc theo tên hoặc mã màu",
  },

  INPUT_WALL_OWNER_DETAILS: {
    singapore: "Input the details of the wall owner here:",
    indonesia: "Input the details of the wall owner here:",
    thailand: "Input the details of the wall owner here:",
    vietnam: "Nhập thông tin chủ nhà:",
  },

  WALL_OWNER: {
    singapore: "Wall Owner",
    indonesia: "Wall Owner",
    thailand: "Wall Owner",
    vietnam: "Chủ nhà",
  },

  PAINTER: {
    singapore: "Painter",
    indonesia: "Painter",
    thailand: "Painter",
    vietnam: "Nhà Thầu",
  },

  RETAILER: {
    singapore: "Retailer",
    indonesia: "Retailer",
    thailand: "Retailer",
    vietnam: "Cửa hàng",
  },

  EMPLOYEE: {
    singapore: "Employee",
    indonesia: "Employee",
    thailand: "Employee",
    vietnam: "Nhân viên nội bộ",
  },

  SELECTED_SHADE: {
    singapore: "Selected Shade",
    indonesia: "Selected Shade",
    thailand: "Selected Shade",
    vietnam: "Màu được chọn",
  },

  WE_WOULD_LOVE_TO_HEAR_FROM_YOU: {
    singapore: "We would love to hear from you",
    indonesia: "We would love to hear from you",
    thailand: "We would love to hear from you",
    vietnam: "Chúng tôi rất mong nhận được phản hồi từ bạn",
  },

  HOW_LIKELY_ARE_YOU_TO_SUGGEST: {
    singapore: "How likely are you to suggest Dulux Preview service to your friends and family?",
    indonesia: "How likely are you to suggest Dulux Preview service to your friends and family?",
    thailand: "How likely are you to suggest Dulux Preview service to your friends and family?",
    vietnam: "Khả năng bạn sẽ giới thiệu Dịch vụ Phối Màu Nhà Chuyên Nghiệp Dulux đến bạn bè và người thân là như thế nào?",
  },

  NOT_LIKELY_AT_ALL: {
    singapore: "Not likely at all",
    indonesia: "Not likely at all",
    thailand: "Not likely at all",
    vietnam: "Hoàn toàn không",
  },

  VERY_LIKELY: {
    singapore: "Very likely",
    indonesia: "Very likely",
    thailand: "Very likely",
    vietnam: "Rất có khả năng",
  },

  EASE_OF_USE: {
    singapore: "Ease of use",
    indonesia: "Ease of use",
    thailand: "Ease of use",
    vietnam: "Dễ sử dụng",
  },

  VERY_POOR: {
    singapore: "Very poor",
    indonesia: "Very poor",
    thailand: "Very poor",
    vietnam: "Rất tệ",
  },

  EXCELLENT: {
    singapore: "Excellent",
    indonesia: "Excellent",
    thailand: "Excellent",
    vietnam: "Xuất sắc",
  },

  ABILITY_TO_PROVIDE_MINUTE_DETAILS: {
    singapore: "Ability to provide minute details (e.g. for colours, textures, etc.)",
    indonesia: "Ability to provide minute details (e.g. for colours, textures, etc.)",
    thailand: "Ability to provide minute details (e.g. for colours, textures, etc.)",
    vietnam: "Khả năng cphối màu các chi tiết nhỏ (ví dụ: về màu sắc, kết cấu, v.v.)",
  },

  TIME_TAKEN_TO_RECEIVE_THE_RESULTS: {
    singapore: "Time taken to receive the results",
    indonesia: "Time taken to receive the results",
    thailand: "Time taken to receive the results",
    vietnam: "Thời gian nhận kết quả",
  },

  THANK_YOU_FOR_RATING: {
    singapore: "Thank you for rating your experience, your feedback was recorded!",
    indonesia: "Thank you for rating your experience, your feedback was recorded!",
    thailand: "Thank you for rating your experience, your feedback was recorded!",
    vietnam: "Cảm ơn bạn đã đánh giá trải nghiệm của mình, phản hồi của bạn đã được ghi lại!",
  },

  RESET: {
    singapore: "Reset",
    indonesia: "Reset",
    thailand: "Reset",
    vietnam: "Cài lại"
  },

  PENDING: {
    singapore: "Pending ",
    indonesia: "Pending ",
    thailand: "Pending ",
    vietnam: "Chưa giải quyết "
  },

  RESOLVED: {
    singapore: "Resolved ",
    indonesia: "Resolved ",
    thailand: "Resolved ",
    vietnam: "giải quyết "
  },

  WELCOME_DULUX: {
    singapore: " Welcome to Dulux Preview Service",
    indonesia: " Welcome to Dulux Preview Service",
    thailand: " Welcome to Dulux Preview Service",
    vietnam: " welcome vietnam"
  },
  SEARCH_NAME: {
    singapore: "Search name",
    indonesia: "Search name",
    thailand: "Search name",
    vietnam: "Tim kiêm tên"
  },

  FILTERS: {
    singapore: "Filters",
    indonesia: "Filters",
    thailand: "Filters",
    vietnam: "bộ lọc"
  },

  REQUESTER_EMAIL: {
    singapore: "Requester Email",
    indonesia: "Requester Email",
    thailand: "Requester Email",
    vietnam: "Email người yêu cầu"
  },

  REQUESTER_TYPE: {
    singapore: "Requester Type",
    indonesia: "Requester Type",
    thailand: "Requester Type",
    vietnam: "Loại người yêu cầu"
  },

  RECOLOUR_TYPE: {
    singapore: "Recolour Type",
    indonesia: "Recolour Type",
    thailand: "Recolour Type",
    vietnam: "Loại đổi màu"
  },

  STATUS: {
    singapore: "Status",
    indonesia: "Status",
    thailand: "Status",
    vietnam: "Trạng thái"
  },

  RESOLVE_REQUEST: {
    singapore: "Resolve Request",
    indonesia: "Resolve Request",
    thailand: "Resolve Request",
    vietnam: "Giải quyết yêu cầu"
  },

  OVERDUE: {
    singapore: "Overdue",
    indonesia: "Overdue",
    thailand: "Overdue",
    vietnam: "Quá hạn"
  },

  UNDER_24_HOURS: {
    singapore: "Under 24 hours",
    indonesia: "Under 24 hours",
    thailand: "Under 24 hours",
    vietnam: "Dưới 24 giờ"
  },

  LATEST_FIRST: {
    singapore: "Latest First",
    indonesia: "Latest First",
    thailand: "Latest First",
    vietnam: "mới nhất đầu tiên"
  },

  OLDEST_FIRST: {
    singapore: "Oldest First",
    indonesia: "Oldest First",
    thailand: "Oldest First",
    vietnam: "Cũ nhất đầu tiên"
  },

  ORIGINAL_IMAGE: {
    singapore: "Original Image",
    indonesia: "Original Image",
    thailand: "Original Image",
    vietnam: "Ảnh gốc"
  },

  REQUEST_MADE: {
    singapore: "Request made: ",
    indonesia: "Request made: ",
    thailand: "Request made: ",
    vietnam: "Yêu cầu được thực hiện: "
  },


  RESULTS_GENERATED: {
    singapore: "Results generated: ",
    indonesia: "Results generated: ",
    thailand: "Results generated: ",
    vietnam: "Kết quả tạo ra: "
  },

  CUSTOMER: {
    singapore: "Customer",
    indonesia: "Customer",
    thailand: "Customer",
    vietnam: "khách hàng"
  },


  CHOOSE_THE_FOLLOWING_OPTIONS: {
    singapore: "Please choose the following options to view and proceed",
    indonesia: "Please choose the following options to view and proceed",
    thailand: "Please choose the following options to view and proceed",
    vietnam: "Please choose the following options to view and proceed viet"
  },

  CHOOSE_IMAGE: {
    singapore: "Choose Image",
    indonesia: "Choose Image",
    thailand: "Choose Image",
    vietnam: "Chọn hình ảnh"
  },

  RECOMMENDATIONS: {
    singapore: "Recommendations",
    indonesia: "Recommendations",
    thailand: "Recommendations",
    vietnam: "khuyến nghị"
  },

  CONFIRM_RESULTS: {
    singapore: "Confirm Results",
    indonesia: "Confirm Results",
    thailand: "Confirm Results",
    vietnam: "Xác nhận kết quả"
  },

  CHOOSE_FROM_AI_GENERATED: {
    singapore: "Choose from the AI generated options to resolve this request.",
    indonesia: "Choose from the AI generated options to resolve this request.",
    thailand: "Choose from the AI generated options to resolve this request.",
    vietnam: "Choose from the AI generated options to resolve this request. Viet"
  },

  PALETTE: {
    singapore: "Palette",
    indonesia: "Palette",
    thailand: "Palette",
    vietnam: "bảng màu"
  },

  CHOOSE_THIS_AS_RESULT: {
    singapore: "Choose this as result",
    indonesia: "Choose this as result",
    thailand: "Choose this as result",
    vietnam: "Chọn cái này làm kết quả"
  },

  RATE_THIS_RESULT: {
    singapore: "Rate this result",
    indonesia: "Rate this result",
    thailand: "Rate this result",
    vietnam: "Đánh giá kết quả này"
  },

  TOUCH_UP: {
    singapore: "Touch-up",
    indonesia: "Touch-up",
    thailand: "Touch-up",
    vietnam: "chỉnh sửa"
  },

  POOR:{
    singapore: "Poor",
    indonesia: "Poor",
    thailand: "Poor",
    vietnam: "Nghèo"
  },

  REASON_FOR_POOR_RATING:{
    singapore: "Reason for your poor rating",
    indonesia: "Reason for your poor rating",
    thailand: "Reason for your poor rating",
    vietnam: "Lý do đánh giá kém của bạn"
  },

  COLOUR_BLEED:{
    singapore: "Colour Bleed",
    indonesia: "Colour Bleed",
    thailand: "Colour Bleed",
    vietnam: "Chảy máu màu"
  },

  FILTER_LIKE_EFFECT:{
    singapore: "Filter like effect",
    indonesia: "Filter like effect",
    thailand: "Filter like effect",
    vietnam: "Bộ lọc giống như hiệu ứng"
  },

  APPLIED_WRONG_COLOUR:{
    singapore: "Applied wrong colour",
    indonesia: "Applied wrong colour",
    thailand: "Applied wrong colour",
    vietnam: "Áp dụng sai màu"
  },

  OTHER:{
    singapore: "Other",
    indonesia: "Other",
    thailand: "Other",
    vietnam: "Khác"
  },

  COMMENT:{
    singapore: "Comment",
    indonesia: "Comment",
    thailand: "Comment",
    vietnam: "Bình luận"
  },

  EDIT_COLOUR_PALETTE:{
    singapore: "Edit Colour Palette",
    indonesia: "Edit Colour Palette",
    thailand: "Edit Colour Palette",
    vietnam: "Khác"
  },

  MAKE_FINAL_CHANGES:{
    singapore: "Make final changes to the colour palette if required below by clicking on the colour to change. There will be a temporary white colour present if the image colour information is not enough, do change it if possible.",
    indonesia: "Make final changes to the colour palette if required below by clicking on the colour to change. There will be a temporary white colour present if the image colour information is not enough, do change it if possible.",
    thailand: "Make final changes to the colour palette if required below by clicking on the colour to change. There will be a temporary white colour present if the image colour information is not enough, do change it if possible.",
    vietnam: "Viet Make final changes to the colour palette if required below by clicking on the colour to change. There will be a temporary white colour present if the image colour information is not enough, do change it if possible."
  },

  COLOURS_USED_TO_EDIT:{
    singapore: "Colours used to edit image",
    indonesia: "Colours used to edit image",
    thailand: "Colours used to edit image",
    vietnam: "Viet Colours used to edit image"
  },

  EDITED_IMAGE:{
    singapore: "Edited Image",
    indonesia: "Edited Image",
    thailand: "Edited Image",
    vietnam: "Hình ảnh đã chỉnh sửa"
  },

  RESOLVE_COLOUR_BLEED:{
    singapore: "Resolve Color Bleed",
    indonesia: "Resolve Color Bleed",
    thailand: "Resolve Color Bleed",
    vietnam: "Viet Resolve Color Bleed"
  },

  UPLOAD_EDITED_IMAGE:{
    singapore: "Upload edited image",
    indonesia: "Upload edited image",
    thailand: "Upload edited image",
    vietnam: "Tải lên hình ảnh đã chỉnh sửa"
  },

  REFERENCE_IMAGE:{
    singapore: "Reference Image",
    indonesia: "Reference Image",
    thailand: "Reference Image",
    vietnam: "Hình ảnh tham chiếu"
  },

  EDITABLE_IMAGE:{
    singapore: "Editable Image",
    indonesia: "Editable Image",
    thailand: "Editable Image",
    vietnam: "Hình ảnh có thể chỉnh sửa"
  },

  RESULT_IMAGE:{
    singapore: "Result Image",
    indonesia: "Result Image",
    thailand: "Result Image",
    vietnam: "hình ảnh kết quả"
  },

  COLOR:{
    singapore: "color",
    indonesia: "color",
    thailand: "color",
    vietnam: "màu sắc"
  },

  HARD_LIGHT:{
    singapore: "hard-light",
    indonesia: "hard-light",
    thailand: "hard-light",
    vietnam: "đèn rất sáng"
  },

  OVERLAY:{
    singapore: "overlay",
    indonesia: "overlay",
    thailand: "overlay",
    vietnam: "lớp phủ"
  },


  NORMAL:{
    singapore: "normal",
    indonesia: "normal",
    thailand: "normal",
    vietnam: "thông thường"
  },

  SELECT_COLOUR:{
    singapore: "Select Colour",
    indonesia: "Select Colour",
    thailand: "Select Colour",
    vietnam: "Chọn màu"
  },

  CHOOSE_A_COLOUR_FROM:{
    singapore: "Choose a colour from the palette or given colors.",
    indonesia: "Choose a colour from the palette or given colors.",
    thailand: "Choose a colour from the palette or given colors.",
    vietnam: "Viet Choose a colour from the palette or given colors."
  },

  COLOUR_PALETTE :{
    singapore: "Colour Palette",
    indonesia: "Colour Palette",
    thailand: "Colour Palette",
    vietnam: "Bảng màu"
  },

  SEARCH_COLOUR:{
    singapore: "Search colour by name or code",
    indonesia: "Search colour by name or code",
    thailand: "Search colour by name or code",
    vietnam: "Viet Search colour by name or code"
  },

  NO_PALETTE_GIVEN:{
    singapore: "No palette is given as a reference image is being used.",
    indonesia: "No palette is given as a reference image is being used.",
    thailand: "No palette is given as a reference image is being used.",
    vietnam: "Viet No palette is given as a reference image is being used."
  },


  CONSUMER_ADDRESS:{
    singapore: "Consumer Address: ",
    indonesia: "Consumer Address: ",
    thailand: "Consumer Address: ",
    vietnam: "Địa chỉ người tiêu dùng: "
  },


  CONSUMER_CITY:{
    singapore: "Consumer City: ",
    indonesia: "Consumer City: ",
    thailand: "Consumer City: ",
    vietnam: "Thành phố tiêu dùng: "
  },


  CHOOSE_PRODUCT_STORE_PAINTER:{
    singapore: "Choose product, store and painter recommendation for this customer and their needs.",
    indonesia: "Choose product, store and painter recommendation for this customer and their needs.",
    thailand: "Choose product, store and painter recommendation for this customer and their needs.",
    vietnam: "Viet Choose product,store and painter recommendation for this customer and their needs."
  },


  SELECT:{
    singapore: "Select",
    indonesia: "Select",
    thailand: "Select",
    vietnam: "Lựa chọn"
  },


  CONFIRM_AND_SEND:{
    singapore: "Confirm & Send",
    indonesia: "Confirm & Send",
    thailand: "Confirm & Send",
    vietnam: "Xác nhận & Gửi"
  },

  DATE:{
    singapore: "Date",
    indonesia: "Date",
    thailand: "Date",
    vietnam: "Ngày"
  },

  ASSIGNED_TO:{
    singapore: "Assigned to",
    indonesia: "Assigned to",
    thailand: "Assigned to",
    vietnam: "Phân công"
  },

  FEEDBACK:{
    singapore: "Feedback",
    indonesia: "Feedback",
    thailand: "Feedback",
    vietnam: "Nhận xét"
  },

  REASSIGN:{
    singapore: "Reassign",
    indonesia: "Reassign",
    thailand: "Reassign",
    vietnam: "chỉ định lại"
  },

  ARCHIVE_REQUEST:{
    singapore: "Archive request",
    indonesia: "Archive request",
    thailand: "Archive request",
    vietnam: "yêu cầu lưu trữ"
  },

  ARCHIVED:{
    singapore: "Archived",
    indonesia: "Archived",
    thailand: "Archived",
    vietnam: "lưu trữ"
  },

  TOTAL_REQUESTS:{
    singapore: "Total Requests",
    indonesia: "Total Requests",
    thailand: "Total Requests",
    vietnam: "Tổng số yêu cầu"
  },

  CUSTOMERS:{
    singapore: "Customers",
    indonesia: "Customers",
    thailand: "Customers",
    vietnam: "khách hàng"
  },

  PENDING_REQUESTS:{
    singapore: "Pending Requests",
    indonesia: "Pending Requests",
    thailand: "Pending Requests",
    vietnam: "Yêu Câu Đang Đợi Giải Quyêt"
  },

  PREVIEW_TEAM:{
    singapore: "Preview Team",
    indonesia: "Preview Team",
    thailand: "Preview Team",
    vietnam: "Nhóm xem trước"
  },

  MY_CUSTOMERS:{
    singapore: "My Customers",
    indonesia: "My Customers",
    thailand: "My Customers",
    vietnam: "khách hàng của tôi"
  },

  MY_TEAM:{
    singapore: "My Team",
    indonesia: "My Team",
    thailand: "My Team",
    vietnam: "Đội của tôi"
  },

  TURN_AROUND_TIME:{
    singapore: "Turn-around Time",
    indonesia: "Turn-around Time",
    thailand: "Turn-around Time",
    vietnam: "Thời gian quay vòng"
  },

  MONITOR_YOUR_TEAM:{
    singapore: "Monitor your team's performance and efforts",
    indonesia: "Monitor your team's performance and efforts",
    thailand: "Monitor your team's performance and efforts",
    vietnam: "Viet Monitor your team's performance and efforts"
  },

  RESOLVED_REQUESTS:{
    singapore: "Resolved Requests",
    indonesia: "Resolved Requests",
    thailand: "Resolved Requests",
    vietnam: "Yêu cầu đã giải quyết"
  },

  ADD_MEMBER:{
    singapore: "Add Member",
    indonesia: "Add Member",
    thailand: "Add Member",
    vietnam: "Thêm thành viên"
  },

  NO_OF_RESOLVED_REQUESTS:{
    singapore: "No. of resolved requests",
    indonesia: "No. of resolved requests",
    thailand: "No. of resolved requests",
    vietnam: "Số yêu cầu đã giải quyết"
  },

  ROLE:{
    singapore: "Role",
    indonesia: "Role",
    thailand: "Role",
    vietnam: "Vai diễn"
  },

  PREVIEW_STAFF:{
    singapore: "Preview Staff",
    indonesia: "Preview Staff",
    thailand: "Preview Staff",
    vietnam: "Nhân viên xem trước"
  },

  PREVIEW_SUPERVISOR:{
    singapore: "Preview Supervisor",
    indonesia: "Preview Supervisor",
    thailand: "Preview Supervisor",
    vietnam: "Người giám sát xem trước"
  },

  PREVIEW_STAFF_ROLE:{
    singapore: "Their primary role is to resolve requests sent in to them",
    indonesia: "Their primary role is to resolve requests sent in to them",
    thailand: "Their primary role is to resolve requests sent in to them",
    vietnam: "Viet Their primary role is to resolve requests sent in to them"
  },

  PREVIEW_SUPERVISOR_ROLE:{
    singapore: "They are the managers who monitor all the requests coming infor their country. They can re-assign requests to different staff members and even solve requests on their own.",
    indonesia: "They are the managers who monitor all the requests coming infor their country. They can re-assign requests to different staff members and even solve requests on their own.",
    thailand: "They are the managers who monitor all the requests coming infor their country. They can re-assign requests to different staff members and even solve requests on their own.",
    vietnam: "Viet They are the managers who monitor all the requests coming infor their country. They can re-assign requests to different staff members and even solve requests on their own."
  },

  SEARCH_REQUESTS:{
    singapore: "Search requests",
    indonesia: "Search requests",
    thailand: "Search requests",
    vietnam: "yêu cầu tìm kiếm"
  },

  BY_DATE:{
    singapore: "By Date",
    indonesia: "By Date",
    thailand: "By Date",
    vietnam: "Theo ngày"
  },

  FROM:{
    singapore: "From",
    indonesia: "From",
    thailand: "From",
    vietnam: "Từ"
  },

  TO:{
    singapore: "To",
    indonesia: "To",
    thailand: "To",
    vietnam: "Đến"
  },

  BY_RECOLOUR_TYPE:{
    singapore: "By Re-Colour Type",
    indonesia: "By Re-Colour Type",
    thailand: "By Re-Colour Type",
    vietnam: "Theo loại màu lại"
  },

  RESET_FILTER:{
    singapore: "Reset Filter",
    indonesia: "Reset Filter",
    thailand: "Reset Filter",
    vietnam: "Đặt lại bộ lọc"
  },

  BY_COUNTRY:{
    singapore: "By Country",
    indonesia: "By Country",
    thailand: "By Country",
    vietnam: "Theo quốc gia"
  },

  REASSIGN_REQUEST:{
    singapore: "Reassign Request",
    indonesia: "Reassign Request",
    thailand: "Reassign Request",
    vietnam: "Yêu cầu chỉ định lại"
  },

  SAVE:{
    singapore: "Save",
    indonesia: "Save",
    thailand: "Save",
    vietnam: "Tiết kiệm"
  },

  SERVICE_HIGHLIGHTS:{
    singapore: "Service Highlights",
    indonesia: "Service Highlights",
    thailand: "Service Highlights",
    vietnam: "Dịch vụ nổi bật"
  },


  REQUESTS:{
    singapore: "Requests",
    indonesia: "Requests",
    thailand: "Requests",
    vietnam: "yêu cầu"
  },

  DASHBOARD:{
    singapore: "Dashboard",
    indonesia: "Dashboard",
    thailand: "Dashboard",
    vietnam: "bảng điều khiển"
  },

  ANALYTICS:{
    singapore: "Analytics",
    indonesia: "Analytics",
    thailand: "Analytics",
    vietnam: "phân tích"
  },

  FILTER_ANALYTICS:{
    singapore: "Filter Analytics",
    indonesia: "Filter Analytics",
    thailand: "Filter Analytics",
    vietnam: "Lọc phân tích"
  },


  BILLINGS:{
    singapore: "Billings",
    indonesia: "Billings",
    thailand: "Billings",
    vietnam: "hóa đơn"
  },

  DOWNLOAD_INVOICE:{
    singapore: "Download Invoice",
    indonesia: "Download Invoice",
    thailand: "Download Invoice",
    vietnam: "Tải xuống hóa đơn"
  },


  TOTAL_AMOUNT:{
    singapore: "Total Amount",
    indonesia: "Total Amount",
    thailand: "Total Amount",
    vietnam: "Tổng cộng"
  },

  SMALL_REQUESTS:{
    singapore: " requests",
    indonesia: " requests",
    thailand: " requests",
    vietnam: " yêu cầu"
  },

  GLOBAL_PREVIEW_TEAM:{
    singapore: "Global Preview Team",
    indonesia: "Global Preview Team",
    thailand: "Global Preview Team",
    vietnam: "Nhóm xem trước toàn cầu"
  },

  POSITION:{
    singapore: "Position",
    indonesia: "Position",
    thailand: "Position",
    vietnam: "Chức vụ"
  },

  COUNTRY:{
    singapore: "Country",
    indonesia: "Country",
    thailand: "Country",
    vietnam: "Quốc gia"
  },

  OPEN_REQUESTS:{
    singapore: "Open Requests",
    indonesia: "Open Requests",
    thailand: "Open Requests",
    vietnam: "Yêu cầu mở"
  },

  ACTIONS:{
    singapore: "Actions",
    indonesia: "Actions",
    thailand: "Actions",
    vietnam: "hành động"
  },

  MANAGE_USER:{
    singapore: "Manage User",
    indonesia: "Manage User",
    thailand: "Manage User",
    vietnam: "Quản lý người dùng"
  },

  REMOVE_USER:{
    singapore: "Remove User",
    indonesia: "Remove User",
    thailand: "Remove User",
    vietnam: "Xóa người dùng"
  },

  DETAILS:{
    singapore: "Details",
    indonesia: "Details",
    thailand: "Details",
    vietnam: "Thông tin chi tiết"
  },


  TIME_TO_DELIVER:{
    singapore: "Time to deliver",
    indonesia: "Time to deliver",
    thailand: "Time to deliver",
    vietnam: "Thời gian giao hàng"
  },

  BY_TIME:{
    singapore: "By Time",
    indonesia: "By Time",
    thailand: "By Time",
    vietnam: "Theo thời gian"
  },

  CUSTOM:{
    singapore: "Custom",
    indonesia: "Custom",
    thailand: "Custom",
    vietnam: "Phong tục"
  },

  TODAY:{
    singapore: "Today",
    indonesia: "Today",
    thailand: "Today",
    vietnam: "Hôm nay"
  },

  LAST_7_DAYS:{
    singapore: "Last 7 days",
    indonesia: "Last 7 days",
    thailand: "Last 7 days",
    vietnam: "7 ngày qua"
  },

  LAST_30_DAYS:{
    singapore: "Last 30 days",
    indonesia: "Last 30 days",
    thailand: "Last 30 days",
    vietnam: "30 ngày trước"
  },

  LAST_MONTH:{
    singapore: "Last month",
    indonesia: "Last month",
    thailand: "Last month",
    vietnam: "Tháng trước"
  },

  LAST_YEAR:{
    singapore: "Last year",
    indonesia: "Last year",
    thailand: "Last year",
    vietnam: "Năm ngoái"
  },

  SEARCH_CUSTOMERS:{
    singapore: "Search Customers",
    indonesia: "Search Customers",
    thailand: "Search Customers",
    vietnam: "Tìm kiếm khách hàng"
  },

  BY_POSITION:{
    singapore: "By Position",
    indonesia: "By Position",
    thailand: "By Position",
    vietnam: "theo vị trí"
  },

  SEARCH_TEAM:{
    singapore: "Search Team",
    indonesia: "Search Team",
    thailand: "Search Team",
    vietnam: "Nhóm tìm kiếm"
  },

  CUSTOMER_FEEDBACK:{
    singapore: "Customer Feedback ",
    indonesia: "Customer Feedback ",
    thailand: "Customer Feedback ",
    vietnam: "Phản hồi của khách hàng "
  },

  CUSTOMER_HAS_NOT_FILLED_FEEDBACK:{
    singapore: "Customer has not filled up feedback",
    indonesia: "Customer has not filled up feedback",
    thailand: "Customer has not filled up feedback",
    vietnam: "Khách hàng chưa điền thông tin phản hồi"
  },

  TIME:{
    singapore: "Time",
    indonesia: "Time",
    thailand: "Time",
    vietnam: "Thời gian"
  },

  RATING:{
    singapore: "Rating",
    indonesia: "Rating",
    thailand: "Rating",
    vietnam: "Xếp hạng"
  },
};
