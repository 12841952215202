/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  RemarksSideBar,
  CancelImage,
  RemarksSideBarHeaderDiv,
  RemarksSideBearHeaderImage,
  RemarksSideBarHeaderText,
  ErrorMessage,
  RemarksModalDiv,
} from "../../styles/PreviewSupervisorDashboardStyles";
import styled from "styled-components";
import "../../styles/floatingLabelInput.css";
import Select from "react-select";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardRemarksSideBar = ({
  openRemarksMenu,
  toggleOpenRemarksMenu,
  reassignRequest,
  selectedItem,
  state,
  getRequestsList,
  previewSupervisorTeam,
  previewSupervisorDetailsContext,
  decodedToken,
  width,
}) => {
  const countryValue = JSON.parse(localStorage.getItem("country"));

//   const [users, setUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState();
//   const [errorMessage, setErrorMessage] = useState(null);

//   const reassignRequestFailureCallback = (msg) => {
//     setErrorMessage(msg);
//   };

//   const reassignRequestSuccessCallback = () => {
//     setErrorMessage(null);
//     toggleOpenReAssignRequestMenu();
//     getRequestsList();
//   };

//   const previewSupervisorTeamSuccessCallback = (data) => {
//     data.users.map((user) => {
//       return setUsers((oldArray) => [
//         ...oldArray,
//         { value: user.email, label: user.email },
//       ]);
//     });
//   };


//   useEffect(() => {
//     previewSupervisorTeam({
//       country:
//         previewSupervisorDetailsContext.previewSupervisorDetails?.country ||
//         decodedToken?.country,
//       previewSupervisorTeamSuccessCallback,
//     });
//   }, []);

  if (width < 1100) {
    return (
      <RemarksModalDiv openSideMenu={openRemarksMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleOpenRemarksMenu}
          alt="Cancel"
          style={{ marginRight: "20px" }}
        />
        <RemarksSideBarHeaderDiv>
          <RemarksSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REMARKS_DETAILS"}
            /></RemarksSideBarHeaderText>
        </RemarksSideBarHeaderDiv>
        <TitleSubTitleDiv>
          <TitleText>{selectedItem?.remarks}</TitleText>
          <SubTitleText>{selectedItem?.remarks}</SubTitleText>
        </TitleSubTitleDiv>
      </RemarksModalDiv>
    );
  } else {
  }

  return (
    <RemarksSideBar openSideMenu={openRemarksMenu}>
      <CancelImage
        src={CANCEL_IMAGE}
        onClick={toggleOpenRemarksMenu}
        alt="Cancel"
      />
      <RemarksSideBarHeaderDiv>
          <RemarksSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REMARKS_DETAILS"}
            /></RemarksSideBarHeaderText>
        </RemarksSideBarHeaderDiv>
        <TitleSubTitleDiv>
          <TitleText>{selectedItem?.remarks}</TitleText>
          {/* <SubTitleText>{selectedItem?.remarks}</SubTitleText> */}
        </TitleSubTitleDiv>
      </RemarksSideBar>
  );
};

const TitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #545850;
  margin: 30px 0 0 0;
`;

const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0 0 30px 0;
`;

const TitleSubTitleDiv = styled.div`
  margin: 20px 40px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  padding: 6px 30px;
  color: #545850;
  outline: none;
`;

const SaveButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 6px 32px;
  outline: none;
  border: none;
  margin: 0px 20px;
`;

export default PreviewSupervisorDashboardRemarksSideBar;
