/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import {
  DeluxePreviewServiceImage,
  PreviewSupervisorSelectorContainer,
  PreviewSupervisorSelectorHeader,
  LogoutButton,
  HeaderHorizontalRuler,
  PreviewSupervisorSelectorBodyContainer,
  VerticalDivider,
  ButtonBar,
  BackButton,
  NextButton,
  ChosseProductText,
  BodyDiv,
  ConsumerDetailsText,
  ConsumerDetailsDiv,
} from "../../styles/PreviewSupervisorSelectorStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  HOME_PAGE_ROUTE,
  PREVIEW_SUPERVISOR_CONSUMER_RESULT_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import SelectorSteps from "../../components/selector/SelectorSteps";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { default as Back } from "../../components/BackButton";
import PreviewSupervisorSelectorRecommendedProducts from "../../components/selector/PreviewSupervisorSelectorRecommendedProducts";
import PreviewSupervisorSelectorStores from "../../components/selector/PreviewSupervisorSelectorStores";
import PreviewSupervisorSelectorPainters from "../../components/selector/PreviewSupervisorSelectorPainters";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminPSSelector = ({ history, location }) => {
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedPainters, setSelectedPainters] = useState([]);
  const [productList, setProductList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [painterList, setPainterList] = useState([]);
  const [consumerLocation, setConsumerLocation] = useState(null);
  const [productId, setProductId] = useState(0);
  const [shopId, setShopId] = useState(0);

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const previewSupervisorDetailsContext = useContext(
    PreviewSupervisorDetailsContext
  );

  const { previewSupervisorSelector, getConsumerLocationInfo } = useContext(
    PreviewSupervisorContext
  );

  const goBackHandler = () => {
    history.goBack();
  };

  const incrementProduct = () => {
    setProductId(productId+10)
  };

  const decrementProduct = () => {
    if(productId!=0){
      setProductId(productId-10)
    }
  };

  const incrementShop = () => {
    setShopId(shopId+5)
  };

  const decrementShop = () => {
    if(shopId!=0){
      setShopId(shopId-5)
    }
  };

  const nextHandler = () => {
    history.push(PREVIEW_SUPERVISOR_CONSUMER_RESULT_ROUTE, {
      selectedProducts,
      selectedStores,
      selectedPainters,
      productList,
      shopList,
      painterList,
    });
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailsContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const { width } = useWindowDimensions();

  const previewSupervisorSelectorCallback = (
    tempProductArray,
    tempShopArray,
    tempPainterArray
  ) => {
    setProductList(tempProductArray);
    setShopList(tempShopArray);
    setPainterList(tempPainterArray);
    if (tempProductArray[0] !== undefined) {
      setSelectedProducts((oldArray) => [
        ...oldArray,
        tempProductArray[0].product_id,
      ]);
    }

    if (tempProductArray[1] !== undefined) {
      setSelectedProducts((oldArray) => [
        ...oldArray,
        tempProductArray[1].product_id,
      ]);
    }

    if (tempProductArray[2] !== undefined) {
      setSelectedProducts((oldArray) => [
        ...oldArray,
        tempProductArray[2].product_id,
      ]);
    }

    if (tempShopArray[0] !== undefined) {
      setSelectedStores((oldArray) => [...oldArray, tempShopArray[0].shop_id]);
    }

    if (tempShopArray[1] !== undefined) {
      setSelectedStores((oldArray) => [...oldArray, tempShopArray[1].shop_id]);
    }

    if (tempShopArray[2] !== undefined) {
      setSelectedStores((oldArray) => [...oldArray, tempShopArray[2].shop_id]);
    }

    if (tempPainterArray[0] !== undefined) {
      setSelectedPainters((oldArray) => [
        ...oldArray,
        tempPainterArray[0].painter_id,
      ]);
    }

    if (tempPainterArray[1] !== undefined) {
      setSelectedPainters((oldArray) => [
        ...oldArray,
        tempPainterArray[1].painter_id,
      ]);
    }

    if (tempPainterArray[2] !== undefined) {
      setSelectedPainters((oldArray) => [
        ...oldArray,
        tempPainterArray[2].painter_id,
      ]);
    }
    document.body.style.cursor = "default"
  };

  const previewSupervisorSelectorFailureCallback = (msg) => {
    alert(msg);
    document.body.style.cursor = "default"
  };

  const getConsumerLocationInfoSuccessCallback = (data) => {
    setConsumerLocation(data);
  };

  const getConsumerLocationInfoFailureCallback = (msg) => {};

  useEffect(() => {
    document.body.style.cursor = "wait"
    getConsumerLocationInfo({
      email: location.state.email,
      getConsumerLocationInfoSuccessCallback,
      getConsumerLocationInfoFailureCallback,
    });
  }, []);

  useEffect(() => {
    console.log(consumerLocation)
    if(consumerLocation){
      previewSupervisorSelector({
        shop_id: shopId,
        product_id : productId,
        country: consumerLocation.country,
        previewSupervisorSelectorCallback,
        previewSupervisorSelectorFailureCallback,
      });
    }
  }, [consumerLocation, productId, shopId]);

  return (
    <>
      <ConsumerLogoutDrawer
      selectedCountry={
        decodedToken
          ? {
              value: decodedToken?.country,
              label: decodedToken?.country,
            }
          : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <PreviewSupervisorSelectorContainer applyFilter={openLogoutMenu}>
        <PreviewSupervisorSelectorHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              alt="Deluxe Preview"
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
            />
          </Link>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LOGOUT"}
            /></LogoutButton>
        </PreviewSupervisorSelectorHeader>
        <HeaderHorizontalRuler />

        <PreviewSupervisorSelectorBodyContainer>
          {width > 1100 && <SelectorSteps />}
          {width > 1100 && <VerticalDivider />}

          <div>
            <ConsumerDetailsDiv>
              {consumerLocation && (
                <>
                  <ConsumerDetailsText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CONSUMER_ADDRESS"}
            />{" "}
                    {consumerLocation?.address === null ||
                    consumerLocation?.address === "NULL"
                      ? "Not provided"
                      : consumerLocation.address}
                  </ConsumerDetailsText>
                  <ConsumerDetailsText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CONSUMER_CITY"}
            />{" "}
                    {consumerLocation?.city === null ||
                    consumerLocation?.city === "NULL"
                      ? "Not provided"
                      : consumerLocation.city}
                  </ConsumerDetailsText>
                </>
              )}
            </ConsumerDetailsDiv>
            <HeaderHorizontalRuler />
            <div style={{ display: "flex", alignItems: "center" }}>
              {width > 1100 && <Back goBackHandler={goBackHandler} />}
              <ChosseProductText>
              <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_PRODUCT_STORE_PAINTER"}
            />
              </ChosseProductText>
            </div>

            <BodyDiv>
              <PreviewSupervisorSelectorRecommendedProducts
                setSelectedProducts={setSelectedProducts}
                selectedProducts={selectedProducts}
                productList={productList}
                incrementProduct={incrementProduct}
                decrementProduct={decrementProduct}
              />
              <PreviewSupervisorSelectorStores
                selectedStores={selectedStores}
                setSelectedStores={setSelectedStores}
                shopList={shopList}
                incrementShop={incrementShop}
                decrementShop={decrementShop}
              />

              <div style={{ margin: "0px 0 0 20px", flex: "1.1" }}>
                {/* <PreviewSupervisorSelectorPainters
                  selectedPainters={selectedPainters}
                  setSelectedPainters={setSelectedPainters}
                  painterList={painterList}
                /> */}
                <ButtonBar>
                  <BackButton onClick={goBackHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
                  <NextButton onClick={nextHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEXT"}
            /></NextButton>
                </ButtonBar>
              </div>
            </BodyDiv>
          </div>
        </PreviewSupervisorSelectorBodyContainer>
      </PreviewSupervisorSelectorContainer>
    </>
  );
};

export default AdminPSSelector;
