import React from "react";
import {
  PaginationDiv,
  PaginationNumberDiv,
  PaginationLeftImage,
  PaginationRightImage,
  PaginationArrowContainer,
} from "../../styles/AdminUserStyles";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";

// Note: This determines behavior of bottom buttons in Preview Staff/Supervisor page.

const AdminUsersPaginationList = ({
  paginationList,
  selectedPage,
  setSelectedPage,
}) => {
  const rightIncrementHandler = () => {
    if (selectedPage <= paginationList.length) {
      selectedPage++;
      setSelectedPage(selectedPage);
    }
  };

  const leftDecrementHandler = () => {
    if (selectedPage > 0) {
      selectedPage--;
      setSelectedPage(selectedPage);
    }
  };

  const LeftArrow = () => {
      return (
        <PaginationArrowContainer>
          <PaginationLeftImage
          onClick={leftDecrementHandler}
          src={BLACK_ARROW_IMAGE}
          alt="Pagination left"
          />
        </PaginationArrowContainer>
      );
  }

  const RightArrow = () => {
    return (
      <PaginationArrowContainer>
        <PaginationRightImage
        onClick={rightIncrementHandler}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination right"
        />
      </PaginationArrowContainer>
    );
  }

  const ShowLeftArrow = () => {
    if (selectedPage > 1) {
      return <LeftArrow/>;
    }

    return <PaginationArrowContainer/>
  }

  const ShowRightArrow = () => {
    if (selectedPage < paginationList.length) {
      return <RightArrow/>;
    }

    return <PaginationArrowContainer/>
  }

  return (
    <PaginationDiv>
      <ShowLeftArrow/>
      <PaginationNumber
        key={ selectedPage }
        selectedPage={ selectedPage } 
        paginationList={paginationList}
        setSelectedPage={
          setSelectedPage
        }
      />
      <ShowRightArrow/>
    </PaginationDiv>
  );
};

const PaginationNumber = ({
  paginationList,
  setSelectedPage,
  selectedPage,
}) => {
  return (
    <>
    <div style={{flexDirection: "row", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
      {paginationList.map((item, index) => {
        return (
          <PaginationNumberDiv
            key={`${index}`}
            onClick={() => setSelectedPage(index + 1)}
            isSelected={selectedPage === index + 1}
          >
            {index + 1}
          </PaginationNumberDiv>
        );
      })}
      </div>
    </>
  );
};

export default AdminUsersPaginationList;


















