import styled from "styled-components";

export const AdminRequestContainer = styled.div`
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PendingResolvedContainerDiv = styled.div`
  display: flex;
  padding: 30px 30px 0 30px;
`;

export const SelectedCountryCheck = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const ArrowImage = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const PendingButton = styled.button`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  background-color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${(props) =>
    props.tabSelected === 0 ? "3px solid #2FC38C" : "none"};
  font-weight: ${(props) => (props.tabSelected === 0 ? "600" : "normal")};
`;

export const MyTeamSideBarHeaderDiv = styled.div`
  display: flex;
  padding: 0px 25px;
  align-items: center;
`;

export const MyTeamSideBarHeaderText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
  margin: 0 10px;
`;

export const MyTeamSideBearHeaderImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const CancelImage = styled.img`
  width: 20px;
  height: 20px;
  align-self: flex-end;
  margin: 3vh 2vw;
`;

export const ReassignRequestModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const ReassignRequestSideBar = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "30vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
`;

export const AdminResolvedModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const AdminResolvedFeedbackSideBar = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "30vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
`;

export const ResolvedButton = styled.button`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  background-color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${(props) =>
    props.tabSelected === 1 ? "3px solid #2FC38C" : "none"};
  font-weight: ${(props) =>
    props.tabSelected === 1 ? "600" : "normal"};
`;

export const ArchivedButton = styled.button`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  background-color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${(props) =>
    props.tabSelected === 2 ? "3px solid #2FC38C" : "none"};
  font-weight: ${(props) =>
    props.tabSelected === 2 ? "600" : "normal"};
`;

export const PendingRequestTable = styled.table`
  border-collapse: collapse;
  margin: 10px 30px;
  width: 80vw;
  @media (max-width: 1100px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const PendingRequestRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const PendingRequestTableHeader = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  padding: 8px;
  text-align: center;
`;

export const PendingRequestTableData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  align-self: center;
  margin: auto;
  padding: 8px;
  text-align: center;
  color: ${(props) => (props.isOverDue === true ? "red" : "#000000")};
`;

export const ResolvedRequestTable = styled.table`
  border-collapse: collapse;
  margin: 10px 30px;
  width: 80vw;
  @media (max-width: 1100px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const ResolvedRequestRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const ResolvedRequestTableHeader = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  padding: 8px;
  text-align: center;
`;

export const ResolvedRequestTableData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  align-self: center;
  margin: auto;
  padding: 8px;
  text-align: center;
`;

export const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
  margin: 20px auto;
  width: 80vw;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const PaginationArrowContainer = styled.div`
  width : 40px;
  height : 20px;
  color : #ffffff;
`;

export const PaginationNumberContainer = styled.tr`
  align-item : center;
`;

export const PaginationLeftImage = styled.img`
  padding: 0 10px;
  display: 'none';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  :hover {
    cursor: pointer;
  }
`;

export const PaginationRightImage = styled.img`
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const PaginationNumberDiv = styled.button`
  background-color: #f1f1f1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  color: ${(props) => (props.isSelected === true ? "#2FC38C" : "#012169")};
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  :hover {
    cursor: pointer;
  }
`;

export const AdminSearchRequestModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const AdminSearchRequestSideBarContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

export const AdminSearchRequestHeader = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
`;

export const SearchRequestsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const ByCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
  margin: 20px 0 8px 0;
`;

export const AdminRequestSearchCountryDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  :hover {
    cursor: pointer;
  }
`;

export const AdminCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0px 10px;
`;

export const ButtonBar = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

export const ResetFilterButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 10px;
  outline: none;
  margin: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  background: #2fc38c;
  border-radius: 5px;
  padding: 5px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const DateRow = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const FeedbackTitleRow = styled.div`
  display: flex;
  margin: 50px 20px 30px 20px;
  align-items: center;
`;

export const FeedbackImage = styled.img``;

export const FeedbackText = styled.p`
  font-family: "Colour-Sans-New";
  margin: 0px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
`;

export const FeedbackRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

export const FeedbackCircularDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #f1f1f1;
`;

export const FeedbackColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 10px;
`;

export const FeedbackRowImage = styled.img``;

export const FeedbackRowTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
`;

export const FeedbackRowSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  margin: 0;
`;

export const FeedbackDataRow = styled.div`
  display: flex;
  margin: 10px 20px;
  justify-content: space-between;
  align-items: center;
`;

export const FeedbackDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
  width: 30%;
`;

export const FeedbackDataTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 2px 0;
  color: #828282;
`;

export const FeedbackDataSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
`;

export const ViewResultButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 20px;
  padding: 4px 10px;
  :hover {
    cursor: pointer;
  }
`;

export const FeedbackButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
