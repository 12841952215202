import createDataContext from "./createDataContext";
import amarisServer from "../api/amarisServer";
import {
  IP_CONFIG_API,
  SOMETHING_WENT_WRONG,
  TOKEN_NAME,
  USER_CREATE_API,
  VERIFY_EMAIL_API,
  VERIFY_EMAIL_OTP_API,
} from "../utils/Constants";
import Cookies from "js-cookie";

const authReducer = (state, action) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        ...{ loading: false },
        ...{ errorMessage: action.payload },
      };
    case "verifyEmail":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "verifyEmailOtp":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "userCreate":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "inProgress":
      return { ...state, ...{ loading: true }, ...{ errorMessage: "" } };
    default:
      return state;
  }
};

const userCreate = (dispatch) => {
  return async ({ email, userCreateSuccessCallback }) => {
    dispatch({ type: "inProgress" });
    amarisServer
      .post(USER_CREATE_API, { email })
      .then((response) => {
        console.log("success")
        userCreateSuccessCallback(email);
        console.log("success2")
      })
      .catch((error) => {
        console.log("unsuccessful")
        userCreateSuccessCallback(email);

      });
  };
};

const verifyEmail = (dispatch) => {
  return async ({
    email,
    verifyEmailSuccessCallback,
    verifyEmailFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    amarisServer
      .post(VERIFY_EMAIL_API, {
        email,
      })
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "verifyEmail",
          });
          verifyEmailSuccessCallback();
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          verifyEmailFailureCallback(
            response?.data?.detail?.message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        verifyEmailFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const verifyAdminEmailOtp = (dispatch) => {
  return async ({
    email,
    otp,
    verifyOtpSuccessCallback,
    verifyOtpFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });

    amarisServer
      .post(VERIFY_EMAIL_OTP_API, {
        email,
        otp,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "verifyEmailOTP",
            payload: response.data,
          });
          verifyOtpSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          verifyOtpFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        verifyOtpFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const verifyEmailOTP = (dispatch) => {
  return async ({
    email,
    otp,
    verifyOtpSuccessCallback,
    verifyOtpFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });

    amarisServer
      .post(VERIFY_EMAIL_OTP_API, {
        email,
        otp,
      })
      .then((response) => {
        if (response.status === 200) {
          Cookies.set(TOKEN_NAME, response.data.token, { expires: 30 });
          dispatch({
            type: "verifyEmailOTP",
            payload: response.data,
          });
          verifyOtpSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          verifyOtpFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        verifyOtpFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const getIp = (dispatch) => {
  return async ({ getIpSuccessCallback, getIpFailureCallback }) => {
    amarisServer
      .get(IP_CONFIG_API)
      .then((response) => {
        getIpSuccessCallback(response.data);
      })
      .catch((error) => {
        getIpFailureCallback("Fail to get ip");
      });
  };
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { verifyEmail, verifyEmailOTP, userCreate, verifyAdminEmailOtp, getIp },
  []
);
