/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  ConsumerLoginSideBarDiv,
  ConsumerLoginModalBarDiv,
  SendOTP,
} from "../../styles/ConsumerUploadImageStyles";
import Select from "react-select";
import {
  HOME_PAGE_ROUTE,
  INDIA_HOME_PAGE_ROUTE,
  INDONESIA_HOME_PAGE_ROUTE,
  MALAYSIA_HOME_PAGE_ROUTE,
  PAKISTAN_HOME_PAGE_ROUTE,
  THAILAND_HOME_PAGE_ROUTE,
  VIETNAM_HOME_PAGE_ROUTE,
} from "../../utils/Constants";

const HomeCountrySideBar = ({
  width,
  countrySideBar,
  toggleCountrySideBar,
  history,
}) => {
  const options = [
    { value: "Malaysia", label: "Malaysia" },
    { value: "Thailand", label: "Thailand" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "India", label: "India" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSubmit = () => {
    if (selectedOption !== null) {
      toggleCountrySideBar();
      if (selectedOption.value === "India") {
        history.push(INDIA_HOME_PAGE_ROUTE);
      } else if (selectedOption.value === "Malaysia") {
        history.push(MALAYSIA_HOME_PAGE_ROUTE);
      } else if (selectedOption.value === "Thailand") {
        history.push(THAILAND_HOME_PAGE_ROUTE);
      } else if (selectedOption.value === "Vietnam") {
        history.push(VIETNAM_HOME_PAGE_ROUTE);
      } else if (selectedOption.value === "Pakistan") {
        history.push(PAKISTAN_HOME_PAGE_ROUTE);
      } else if (selectedOption.value === "Indonesia") {
        history.push(INDONESIA_HOME_PAGE_ROUTE);
      } else {
        history.push(HOME_PAGE_ROUTE);
      }
    }
  };

  if (width < 1100) {
    return (
      <ConsumerLoginModalBarDiv
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
        openSideMenu={countrySideBar}
      >
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
          styles={{
            singleValue: (styles) => ({
              color: "#012169",
            }),
            control: (styles) => ({
              ...styles,
              width: "200px",
            }),
            option: (styles) => ({
              ...styles,
            }),
          }}
        />
        <SendOTP onClick={handleSubmit}>Done</SendOTP>
      </ConsumerLoginModalBarDiv>
    );
  } else {
    return (
      <ConsumerLoginSideBarDiv
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
        openSideMenu={countrySideBar}
      >
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
          styles={{
            singleValue: (styles) => ({
              color: "#012169",
            }),
            control: (styles) => ({
              ...styles,
              width: "200px",
            }),
            option: (styles) => ({
              ...styles,
            }),
          }}
        />
        <SendOTP onClick={handleSubmit}>Done</SendOTP>
      </ConsumerLoginSideBarDiv>
    );
  }
};

export default HomeCountrySideBar;
