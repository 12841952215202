/* eslint-disable react-hooks/exhaustive-deps */
// import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import {
  OneTimeOtpText,
  ConsumerOtpInputDiv,
  // ConsumerResultOtpInput,
  ResendOtpButton,
  VerifyEmailButton,
  ConsumResultNewOtpInput,
  ErrorMessage,
} from "../../styles/ConsumerUploadImageStyles";
import {
  ADMIN_DASHBOARD_ROUTE,
  CONSUMER_HOME_ROUTE,
  PREVIEW_STAFF_DASHBOARD_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
} from "../../utils/Constants";
// import { otpValidationSchema } from "../../validations/Validations";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerLoginOTP = ({
  toggleSideMenu,
  verifyEmailOTP,
  state,
  email,
  history,
  colourizationContext,
  previewSupervisorDetailContext,
  verifyEmail,
  selectedCountry,
}) => {
  const [otp, setOtp] = useState("");
  const [progress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [resendOtpSuccessMsg, setResendOtpSuccessMsg] = useState(null);
  const [canResendOtp, setCanResendOtp] = useState(true);
  const [countDown, setCountDown] = useState(60);

  const toggleProgress = () => setProgress((previousState) => !previousState);

  // const handleKeyUp = (event) => {
  //   console.log(event.keyCode);
  //   const form = event.target.form;
  //   const index = Array.prototype.indexOf.call(form, event.target);
  //   if (event.keyCode === 8 || event.keyCode === 37) {
  //     if (index > 0) {
  //       form.elements[index - 1].focus();
  //       event.preventDefault();
  //     }
  //   } else if (
  //     (event.keyCode >= 48 && event.keyCode <= 57) ||
  //     (event.keyCode >= 65 && event.keyCode <= 90) ||
  //     (event.keyCode >= 96 && event.keyCode <= 105) ||
  //     event.keyCode === 39
  //   ) {
  //     if (index < 3) {
  //       form.elements[index + 1].focus();
  //       event.preventDefault();
  //     } else {
  //       form.elements[index].blur();
  //     }
  //   }
  // };

  useEffect(() => {
    if (!canResendOtp) {
      const interval = setInterval(() => {
        if (countDown > 0) {
          setCountDown(countDown - 1);
        } else {
          setCanResendOtp(true);
          setCountDown(60);
          return () => clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [canResendOtp, countDown]);

  const verifyEmailSuccessCallback = () => {
    toggleProgress();
    setResendOtpSuccessMsg("Otp send successfully");
  };

  const verifyEmailFailureCallback = (data) => {
    toggleProgress();
    setErrorMsg(data);
    setResendOtpSuccessMsg("");
  };

  const verifyOtpSuccessCallback = (data) => {
    toggleSideMenu();

    if (data.user_type === "Consumer") {
      const values = colourizationContext.colourizationDetails;
      colourizationContext.setColourizationDetails({
        raw_image: values.raw_image !== undefined ? values.raw_image : "",
        colourization_type: "",
        reference: "",
        theme: "",
        lead_colour: "",
        email: data.email,
        name: data.name,
        city: "",
        country: "",
      });
      history.push(CONSUMER_HOME_ROUTE);
    } else if (data.user_type === "Preview Supervisor") {
      previewSupervisorDetailContext.setPreviewSupervisorDetails({
        email: data.email,
        country: data.country,
        isPreviewStaff: false,
      });
      history.push(PREVIEW_SUPERVISOR_DASHBOARD_ROUTE);
    } else if (data.user_type === "Super Admin") {
      history.push(ADMIN_DASHBOARD_ROUTE);
    } else if (data.user_type === "Preview Staff") {
      previewSupervisorDetailContext.setPreviewSupervisorDetails({
        email: data.email,
        country: data.country,
        isPreviewStaff: true,
      });
      history.push(PREVIEW_STAFF_DASHBOARD_ROUTE);
    } else {
      setErrorMsg(`UI not done yet for ${data.user_type}`);
    }
  };

  const verifyOtpFailureCallback = (data) => {
    setErrorMsg(data);
  };

  const resendOtpHandler = () => {
    if (!progress && canResendOtp) {
      toggleProgress();
      setCanResendOtp(false);
      verifyEmail({
        email,
        verifyEmailSuccessCallback,
        verifyEmailFailureCallback,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    verifyEmailOTP({
      email,
      otp,
      verifyOtpSuccessCallback,
      verifyOtpFailureCallback,
    });
  };

  return (
    <>
      <OneTimeOtpText>
        <LanguageSelector
          selectedCountry={selectedCountry}
          text={"WE_HAVE_SENT_A_4_DIGIT"}
        />
        {email}
      </OneTimeOtpText>
      <OneTimeOtpText>
        <LanguageSelector
          selectedCountry={selectedCountry}
          text={"CHECK_JUNK_MAIL"}
        />
      </OneTimeOtpText>
      <ConsumerOtpInputDiv>
        <ConsumResultNewOtpInput
          type="text"
          pattern="\d*"
          maxLength={4}
          value={otp}
          onChange={(event) => setOtp(event.target.value)}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
      </ConsumerOtpInputDiv>
      {errorMsg !== null && <ErrorMessage>{errorMsg}</ErrorMessage>}
      {resendOtpSuccessMsg != null && (
        <OneTimeOtpText style={{ color: "green" }}>
          {resendOtpSuccessMsg}
        </OneTimeOtpText>
      )}
      {countDown !== 60 && (
        <ResendOtpButton>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"RESEND_OTP_IN"}
          />
          {countDown}s
        </ResendOtpButton>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ResendOtpButton onClick={resendOtpHandler}>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"RESEND_OTP"}
          />
        </ResendOtpButton>
      </div>
      <VerifyEmailButton
        onClick={submitHandler}
        disabled={otp.length < 4}
        isDisabled={otp.length < 4}
      >
        <LanguageSelector
          selectedCountry={selectedCountry}
          text={"VERIFY_EMAIL"}
        />
      </VerifyEmailButton>
      {/* <Formik
        initialValues={{ otpOne: "", otpTwo: "", otpThree: "", otpFour: "" }}
        onSubmit={(values, actions) => {
          const { otpOne, otpTwo, otpThree, otpFour } = values;
          verifyEmailOTP({
            email,
            otp: `${otpOne}${otpTwo}${otpThree}${otpFour}`,
            verifyOtpSuccessCallback,
            verifyOtpFailureCallback,
          });
        }}
        validationSchema={otpValidationSchema}
      >
        {(formikProps) => {
          return (
            <>
              <ConsumerOtpInputDiv>
                <ConsumerResultOtpInput
                  required
                  type="text"
                  pattern="\d*"
                  maxLength={1}
                  onKeyUp={handleKeyUp}
                  value={formikProps.values.otpOne}
                  onChange={formikProps.handleChange("otpOne")}
                  onBlur={formikProps.handleBlur("otpOne")}
                />
                <ConsumerResultOtpInput
                  required
                  type="text"
                  pattern="\d*"
                  maxLength={1}
                  onKeyUp={handleKeyUp}
                  value={formikProps.values.otpTwo}
                  onChange={formikProps.handleChange("otpTwo")}
                  onBlur={formikProps.handleBlur("otpTwo")}
                />
                <ConsumerResultOtpInput
                  required
                  type="text"
                  pattern="\d*"
                  maxLength={1}
                  onKeyUp={handleKeyUp}
                  value={formikProps.values.otpThree}
                  onChange={formikProps.handleChange("otpThree")}
                  onBlur={formikProps.handleBlur("otpThree")}
                />
                <ConsumerResultOtpInput
                  required
                  type="text"
                  pattern="\d*"
                  maxLength={1}
                  onKeyUp={handleKeyUp}
                  value={formikProps.values.otpFour}
                  onChange={formikProps.handleChange("otpFour")}
                  onBlur={formikProps.handleBlur("otpFour")}
                />
              </ConsumerOtpInputDiv>
              <ResendOtpButton
                onClick={() => {
                  verifyEmail({
                    email,
                    verifyEmailSuccessCallback,
                    verifyEmailFailureCallback,
                  });
                }}
              >
                Resend OTP
              </ResendOtpButton>
              <VerifyEmailButton
                type="submit"
                onClick={formikProps.handleSubmit}
                disabled={
                  !(formikProps.isValid && formikProps.dirty) || state.loading
                }
                isDisabled={!(formikProps.isValid && formikProps.dirty)}
              >
                Verify Email
              </VerifyEmailButton>
            </>
          );
        }}
      </Formik>
    */}
    </>
  );
};

export default ConsumerLoginOTP;
