/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import PreviewSupervisorDashboardHeaderRequest from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardHeaderRequest";
import PreviewSupervisorDashboardMyTeamSideBar from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardMyTeamSideBar";
import PreviewSupervisorDashboardPagination from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardPagination";
import PreviewSupervisorDashboardPendingRequests from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardPendingRequests";
import PreviewSupervisorDashboardPendingResolvedContainer from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardPendingResolvedContainer";
import PreviewSupervisorDashboardReassignRequestSideBar from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardReassignRequestSideBar";
import PreviewSupervisorDashboardRemarksSideBar from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardRemarksSideBar";
import PreviewSupervisorDashboardResolvedRequests from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardResolvedRequests";
import {
  PreviewSupervisorDashboardContainer,
  PreviewSupervisorDashboardHeader,
  DeluxePreviewServiceImage,
  LogoutButton,
  HeaderHorizontalRuler,
  WelcomeText,
  WelcomeNameText,
  ErrorMessage,
  SearchBarDiv,
  DownloadButton,
} from "../../styles/PreviewSupervisorDashboardStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  HOME_PAGE_ROUTE,
  PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE,
  PENDING_REQUEST_THEME_DETAIL_ROUTE,
  PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE,
  PREVIEW_SUPERVISOR_RESULT_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import dayjs from "dayjs";

import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import { Link } from "react-router-dom";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import PreviewSupervisorAddMember from "../../components/preview-supervisor-dashboard/PreviewSupervisorAddMember";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PreviewSupervisorRequestSearchBody from "../../components/preview-supervisor-dashboard/PreviewSupervisorRequestSearchBody";
import PreviewSupervisorSearchRequests from "../../components/preview-supervisor-dashboard/PreviewSupervisorSearchRequests";
import PreviewSupervisorArchivedRequests from "../../components/preview-supervisor-dashboard/PreviewSupervisorArchivedRequests";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboard = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const dropDownList = ["Latest First", "Oldest First"];
  var date = new Date();

  const [tabSelected, setTabSelected] = useState(0);
  const [selectedPageForPending, setSelectedPageForPending] = useState(1);
  const [selectedPageForResolved, setSelectedPageForResolved] = useState(1);
  const [selectedPageForArchived, setSelectedPageForArchived] = useState(1);
  const [paginationList, setPaginationList] = useState({
    selectedPageForPending: selectedPageForPending,
    selectedPageForResolved: selectedPageForResolved,
    tabSelected: tabSelected,
  });
  const [openMyTeamMenu, setOpenMyTeamMenu] = useState(false);
  const [openReAssignRequestMenu, setOpenReAssignRequestMenu] = useState(false);
  const [openRemarksMenu, setOpenRemarksMenu] = useState(false);
  const [requestList, setRquestList] = useState([]);
  const [tempRequestList, setTempRequestList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [textFilterName, setTextFilterName] = useState("");
  const [searchRequest, setSearchRequest] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(date.setDate(date.getDate() + 1));
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    dropDownList[0]
  );
  const [dashboardData, setDashboardData] = useState(null);
  const [archivedFlag, setArchivedFlag] = useState(false);

  const { width } = useWindowDimensions();

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleOpenMyTeamMenu = () =>
    setOpenMyTeamMenu((previousState) => !previousState);
  const toggleOpenReAssignRequestMenu = () =>
    setOpenReAssignRequestMenu((previousState) => !previousState);
  const toggleOpenRemarksMenu = () =>
    setOpenRemarksMenu((previousState) => !previousState);
  const toggleOpenAddMember = () =>
    setOpenAddMember((previousState) => !previousState);
  const toggleSearchRequest = () =>
    setSearchRequest((previousState) => !previousState);
  const toggleFilterDropDown = () =>
    setFilterDropDown((previousState) => !previousState);
  const toggleArchivedDone = () =>
    setArchivedFlag((previousState) => !previousState);
  const togglePending = () => setTabSelected(0);
  const toggleResolved = () => setTabSelected(1);
  const toggleArchived = () => setTabSelected(2);

  const previewSupervisorDetailsContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const {
    state,
    previewSupervisorRequestList,
    reassignRequest,
    previewSupervisorTeam,
    previewSupervisorAddUser,
    previewSupervisorDashboardSummary,
    previewSupervisorMyTeam,
    previewArchiveRequest,
    previewRequestsExcelDownload,
  } = useContext(PreviewSupervisorContext);

  const requestListFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const requestListSuccessCallback = (data) => {
    setRquestList(data.requests);
    setTempRequestList(data.requests);
  };

  const getRequestsList = () => {
    previewSupervisorRequestList({
      country:
        previewSupervisorDetailsContext.previewSupervisorDetails?.country ||
        decodedToken?.country,
      requestListFailureCallback,
      requestListSuccessCallback,
    });
  };

  const previewSupervisorDashboardSuccessCallback = (data) => {
    setDashboardData(data);
  };

  const previewSupervisorDashboardFailureCallback = (msg) => {
    // alert(msg);
  };

  useEffect(() => {
    previewSupervisorDashboardSummary({
      previewSupervisorDashboardSuccessCallback,
      previewSupervisorDashboardFailureCallback,
    });
    getRequestsList();
  }, [archivedFlag]);

  const setPreviewDashboardDetails = () => {
    const values = previewSupervisorDetailsContext.previewSupervisorDetails;

    previewSupervisorDetailsContext.setPreviewSupervisorDetails({
      email: values.email || decodedToken.email,
      country: values.country || decodedToken.country,
      isPreviewStaff: false,
      isValid: true,
    });
  };

  const previewSupervisorResultHandler = (item) => {
    setPreviewDashboardDetails();
    history.push(PREVIEW_SUPERVISOR_RESULT_ROUTE, {
      item: item,
    });
  };

  const resolveRequestHandler = (item) => {
    setPreviewDashboardDetails();
    const data = {
      selectedPageForPending: selectedPageForPending,
      selectedPageForResolved: selectedPageForResolved,
      tabSelected: tabSelected,
      requestList: requestList,
    };
    if (item.colourization_type === "Reference") {
      history.push(PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE, { item, data });
    } else if (item.colourization_type === "Theme") {
      history.push(PENDING_REQUEST_THEME_DETAIL_ROUTE, {
        item, data
      });
    } else {
      history.push(PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE, {
        item, data
      });
    }
  };

  const previewArchiveRequestSuccessCallback = (msg) => {
    toggleArchivedDone();
  };

  const previewArchiveRequestFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const archiveHandler = (request_id) => {
    previewArchiveRequest({
      request_id: request_id,
      previewArchiveRequestSuccessCallback,
      previewArchiveRequestFailureCallback,
    });
  };

  const previewRequestsExcelSuccessCallback = (data) => {
    var blob = data;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "report.xlsx");
    } else {
      var downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      downloadLink.download = "report.xlsx";
      document.body.appendChild(downloadLink);
      window.open(downloadLink, "_blank");
    }
  };

  const previewRequestsExcelFailureCallback = (msg) => {
    alert(msg);
  };

  const downloadReport = () => {
    previewRequestsExcelDownload({
      country:
        previewSupervisorDetailsContext.previewSupervisorDetails?.country ||
        decodedToken?.country,
      previewRequestsExcelSuccessCallback,
      previewRequestsExcelFailureCallback,
    });
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailsContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const initialFilter = () => {
    console.log("inside initial filter");
    let newList = [],
      tempNewList = [],
      filteredList = requestList;
    console.log(requestList);

    if (fromDate !== "" && toDate !== dayjs().format("DD/MM/YYYY")) {
      tempNewList = [];
      const unixFromDate = dayjs(fromDate).unix();
      const unixToDate = dayjs(toDate).unix();
      for (let i = 0; i < requestList.length; i++) {
        const serverUnixDate = dayjs(requestList[i].timestamp).unix();
        if (serverUnixDate <= unixToDate && serverUnixDate >= unixFromDate) {
          tempNewList.push(filteredList[i]);
        }
      }
      console.log("date");
      filteredList = tempNewList;
      console.log(filteredList);
    }

    newList = filteredList;

    setTempRequestList(newList);
    updatePagination(
      tabSelected === 0
        ? newList.filter((data) => data.status === "Pending")
        : tabSelected === 1
        ? newList.filter((data) => data.status === "Resolved")
        : newList.filter((data) => data.status === "Archived")
    );
  };

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    initialFilter();
    console.log("initial filter");
    console.log(tempRequestList);
  }, [fromDate, toDate, requestList]);

  return (
    <>
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      {openMyTeamMenu && (
        <PreviewSupervisorDashboardMyTeamSideBar
          openMyTeamMenu={openMyTeamMenu}
          toggleOpenMyTeamMenu={toggleOpenMyTeamMenu}
          toggleOpenAddMember={toggleOpenAddMember}
          width={width}
          dashboardData={dashboardData}
          previewSupervisorMyTeam={previewSupervisorMyTeam}
        />
      )}

      {openAddMember && (
        <PreviewSupervisorAddMember
          toggleOpenAddMember={toggleOpenAddMember}
          openAddMember={openAddMember}
          country={decodedToken.country}
          previewSupervisorAddUser={previewSupervisorAddUser}
          width={width}
        />
      )}
      {searchRequest && (
        <PreviewSupervisorSearchRequests
          openSideMenu={searchRequest}
          toggleSearchRequest={toggleSearchRequest}
          setTempRequestList={setTempRequestList}
          setSelectedPageForPending={setSelectedPageForPending}
          setSelectedPageForResolved={setSelectedPageForResolved}
          setPaginationList={setPaginationList}
          tabSelected={tabSelected}
          requestList={requestList}
          width={width}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      )}
      <PreviewSupervisorDashboardReassignRequestSideBar
        openReAssignRequestMenu={openReAssignRequestMenu}
        toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
        reassignRequest={reassignRequest}
        state={state}
        getRequestsList={getRequestsList}
        selectedItem={selectedItem}
        previewSupervisorDetailsContext={previewSupervisorDetailsContext}
        previewSupervisorTeam={previewSupervisorTeam}
        decodedToken={decodedToken}
        width={width}
      />
      <PreviewSupervisorDashboardRemarksSideBar
        openRemarksMenu={openRemarksMenu}
        toggleOpenRemarksMenu={toggleOpenRemarksMenu}
        reassignRequest={reassignRequest}
        state={state}
        getRequestsList={getRequestsList}
        selectedItem={selectedItem}
        previewSupervisorDetailsContext={previewSupervisorDetailsContext}
        previewSupervisorTeam={previewSupervisorTeam}
        decodedToken={decodedToken}
        width={width}
      />

      <PreviewSupervisorDashboardContainer
        applyFilter={
          openMyTeamMenu ||
          openReAssignRequestMenu ||
          openRemarksMenu ||
          openLogoutMenu ||
          openAddMember ||
          searchRequest
        }
      >
        <PreviewSupervisorDashboardHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          <WelcomeText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HI"}
            /> <WelcomeNameText>{decodedToken.name}</WelcomeNameText>, <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"WELCOME_DULUX"}
          />
          </WelcomeText>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LOGOUT"}
            /></LogoutButton>
        </PreviewSupervisorDashboardHeader>
        <HeaderHorizontalRuler />
        <PreviewSupervisorDashboardHeaderRequest
          toggleOpenMyTeamMenu={toggleOpenMyTeamMenu}
          requestList={requestList}
          dashboardData={dashboardData}
        />
        <SearchBarDiv>
          <PreviewSupervisorDashboardPendingResolvedContainer
            tabSelected={tabSelected}
            togglePending={togglePending}
            toggleResolved={toggleResolved}
            toggleArchived={toggleArchived}
            setTabSelected={setTabSelected}
            pendingListLength={
              tempRequestList.filter((data) => data.status === "Pending").length
            }
            resolvedListLength={
              tempRequestList.filter((data) => data.status === "Resolved")
                .length
            }
            archivedListLength={
              tempRequestList.filter((data) => data.status === "Archived")
                .length
            }
          />
          <PreviewSupervisorRequestSearchBody
            textFilterName={textFilterName}
            setTextFilterName={setTextFilterName}
            toggleSearchRequest={toggleSearchRequest}
            toggleFilterDropDown={toggleFilterDropDown}
            selectedFilterOption={selectedFilterOption}
            filterDropDown={filterDropDown}
            dropDownList={dropDownList}
            setSelectedFilterOption={setSelectedFilterOption}
          />
        </SearchBarDiv>

        {errorMessage !== null && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {tempRequestList.length > 0 && tabSelected === 0 ? (
          <PreviewSupervisorDashboardPendingRequests
            list={tempRequestList.filter((data) => data.status === "Pending")}
            selectedPageForPending={selectedPageForPending}
            toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
            toggleOpenRemarksMenu={toggleOpenRemarksMenu}
            resolveRequestHandler={resolveRequestHandler}
            setSelectedItem={setSelectedItem}
            textFilterName={textFilterName}
            selectedFilterOption={selectedFilterOption}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Pending"
            )}
            archiveHandler={archiveHandler}
          />
        ) : tabSelected === 1 ? (
          <PreviewSupervisorDashboardResolvedRequests
            list={tempRequestList.filter((data) => data.status === "Resolved")}
            selectedPageForResolved={selectedPageForResolved}
            previewSupervisorResultHandler={previewSupervisorResultHandler}
            selectedFilterOption={selectedFilterOption}
            textFilterName={textFilterName}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Resolved"
            )}
          />
        ) : (
          <PreviewSupervisorArchivedRequests
            list={tempRequestList.filter((data) => data.status === "Archived")}
            selectedPageForArchived={selectedPageForArchived}
            toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
            resolveRequestHandler={resolveRequestHandler}
            setSelectedItem={setSelectedItem}
            textFilterName={textFilterName}
            selectedFilterOption={selectedFilterOption}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Archived"
            )}
          />
        )}
        {tempRequestList.length > 0 && (
          <>
            <PreviewSupervisorDashboardPagination
              paginationList={paginationList}
              selectedPageForResolved={selectedPageForResolved}
              setSelectedPageForResolved={setSelectedPageForResolved}
              selectedPageForPending={selectedPageForPending}
              setSelectedPageForPending={setSelectedPageForPending}
              selectedPageForArchived={selectedPageForArchived}
              setSelectedPageForArchived={setSelectedPageForArchived}
              tabSelected={tabSelected}
            />
            <div
              style={{
                float: "right",
                marginRight: "200px",
                marginBottom: "70px",
              }}
            >
              <DownloadButton onClick={downloadReport}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            /></DownloadButton>
            </div>
          </>
        )}
      </PreviewSupervisorDashboardContainer>
    </>
  );
};

export default PreviewSupervisorDashboard;
