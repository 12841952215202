import React from "react";
import { loginEmailValidationSchema, loginEmailValidationSchemaLoggedin } from "../../validations/Validations";
import { Formik } from "formik";
import "../../styles/floatingLabelInput.css";
import { SubmitRequestButton, DuluxPreviewText } from "../../styles/ConsumerDuluxPreviewStyles";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerDuluxPreviewForm = ({ 
  handler, 
  selectedCountry,
  token,
  decodedToken
}) => {
  return (
    <Formik
      initialValues={token === undefined ? 
        { email: "",  floor_number: "",  floor_area: "",  room_number: "", remarks:"" }
        : {floor_number: "",  floor_area: "",  room_number: "", remarks:"" }
      }
      onSubmit={(values, actions) => {
        console.log("$$$$$$$$$$$$$$$$", values, values.remarks)
        {token === undefined ? 
          handler(values.email, values.floor_number, values.floor_area, values.room_number, values.remarks)
          : handler(values.floor_number, values.floor_area, values.room_number, values.remarks)
        }
      }}
      validationSchema= {token === undefined ? 
        loginEmailValidationSchema
        : loginEmailValidationSchemaLoggedin
      }
    >
      {(formikProps) => {
        return (
          <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            {token === undefined ? 
              <div className="form-control">
                <input
                  required
                  type="email"
                  name="input"
                  value={formikProps.values.email}
                  onChange={formikProps.handleChange("email")}
                  onBlur={formikProps.handleBlur("email")}
                />
                <label>E-mail</label>
                <div className="border-around"></div>
              </div>: null}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            {
              <div className="form-control">
                <input
                  required
                  type="text"
                  name="input"
                  defaultValue={formikProps.values.remarks}
                  onChange={formikProps.handleChange("remarks")}
                  onBlur={formikProps.handleBlur("remarks")}
                />
                <label><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"REMARKS_DETAILS"} /></label>
                <div className="border-around"></div>
              </div>}
          </div>
          
            {/* DISABLED INDIA CHANGE */}
            {/* {selectedCountry == "India"?
            <>
            <DuluxPreviewText>
              <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"ADDITIONAL_REQUEST_INFO"} />
            </DuluxPreviewText>
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
            >
            <div className="form-control">
              <input
                required
                name="input"
                value={formikProps.values.floor_number}
                onChange={formikProps.handleChange("floor_number")}
                onBlur={formikProps.handleBlur("floor_number")}
              />
              <label>Number of floors (Optional)</label>
              <div className="border-around"></div>
            </div>
            <div className="form-control">
              <input
                required
                name="input"
                value={formikProps.values.floor_area}
                onChange={formikProps.handleChange("floor_area")}
                onBlur={formikProps.handleBlur("floor_area")}
              />
              <label><span>
                  Floor area m<sup>2</sup> (Optional)
              </span></label>
              <div className="border-around"></div>
            </div>
            <div className="form-control">
              <input
                required
                name="input"
                value={formikProps.values.room_number}
                onChange={formikProps.handleChange("room_number")}
                onBlur={formikProps.handleBlur("room_number")}
              />
              <label>Number of rooms (Optional)</label>
              <div className="border-around"></div>
            </div>
            </div></> : null} */}
            {(decodedToken.customer_type === "Wall Owner" || decodedToken === null || decodedToken === undefined || decodedToken === "") &&
            <SubmitRequestButton
              type="submit"
              onClick={formikProps.handleSubmit}
              // DISABLED INDIA CHANGE
              // disabled={(token === undefined)|| (selectedCountry === "India")? (!(formikProps.isValid)) : false}
              // isDisabled={(token === undefined)|| (selectedCountry === "India")? (!(formikProps.isValid)) : false}
              disabled={(token === undefined)? (!(formikProps.isValid)) : false}
              isDisabled={(token === undefined)? (!(formikProps.isValid)) : false}
            >
              <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"SUBMIT_REQUEST"} />
            </SubmitRequestButton>}
            </>
        );
      }}
    </Formik>
  );
};

export default ConsumerDuluxPreviewForm;
