import React from "react";
import {
  PrivacyPolicyContainer,
  DeluxePreviewServiceImage,
  PrivacyPolicyHeader,
} from "../../styles/PrivacyPolicyStyles";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";

const PrivacyPolicyHeader1 = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyHeader>
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage src={DELUXE_PREVIEW_SERVICE_IMAGE} />
        </Link>
      </PrivacyPolicyHeader>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyHeader1;
