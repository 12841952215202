import React, { useState } from "react";
import ConsumerUploadImageHeader from "../../components/consumer-upload-image/ConsumerUploadImageHeader";
import {
  ConsumerContainer,
  AppropriateTextStyle,
  SituationDiv,
  ContactUsText,
  ContactUsHeader,
  ContactUsDiv,
} from "../../styles/ConsumerUploadImageStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import BackButton from "../../components/BackButton";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";
import ConsumerSituationContainer from "../../components/consumer-image-options/ConsumerSituationContainer";
import EXTERIOR_IMAGE from "../../images/exterior_icon.png";
import INTERIOR_IMAGE from "../../images/interior_icon.png";

const ConsumerChooseImageType = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const { width } = useWindowDimensions();

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [exteriorOption, setExteriorOption] = useState(false);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const exteriorOptionHandler = () => {
    setExteriorOption(true);
  };

  const interiorOptionHandler = () => {
    history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    if (exteriorOption) {
      setExteriorOption(false);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? { value: decodedToken?.country, label: decodedToken?.country }
            : countryValue
        }
      />
      <ConsumerContainer applyFilter={openLogoutMenu}>
        <ConsumerUploadImageHeader
          width={width}
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          goBackHandler={goBackHandler}
          selectedCountry={
            decodedToken
              ? { value: decodedToken?.country, label: decodedToken?.country }
              : countryValue
          }
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        {exteriorOption ? (
          <div style={{ marginTop: "150px" }}>
            <ContactUsHeader>
              <LanguageSelector
                selectedCountry={
                  decodedToken.country || countryValue.value || "Singapore"
                }
                text={"EXTRIOR_CONTACT_US"}
              />
            </ContactUsHeader>
            <ContactUsText>
              <LanguageSelector
                selectedCountry={
                  decodedToken.country || countryValue.value || "Singapore"
                }
                text={"EXTRIOR_CONTACT"}
              />
            </ContactUsText>
            <ContactUsHeader>
              <LanguageSelector
                selectedCountry={
                  decodedToken.country || countryValue.value || "Singapore"
                }
                text={"EXTRIOR_WRITE_TO_US"}
              />
            </ContactUsHeader>
            <div style={{ marginLeft: "-90px" }}>
              <ContactUsText>
                <LanguageSelector
                  selectedCountry={
                    decodedToken.country || countryValue.value || "Singapore"
                  }
                  text={"EXTRIOR_ADDRESS"}
                />
              </ContactUsText>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <AppropriateTextStyle>
              <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"CHOOSE_THE_IMAGE_TYPE"}
              />
            </AppropriateTextStyle>
            <SituationDiv>
              <ConsumerSituationContainer
                isImageTypeOption={true}
                image={INTERIOR_IMAGE}
                text={
                  <>
                    <LanguageSelector
                      selectedCountry={
                        decodedToken
                          ? {
                              value: decodedToken?.country,
                              label: decodedToken?.country,
                            }
                          : countryValue
                      }
                      text={"INTERIOR"}
                    />
                  </>
                }
                isComingSoon={false}
                clickHandler={interiorOptionHandler}
              />
              <ConsumerSituationContainer
                isImageTypeOption={true}
                image={EXTERIOR_IMAGE}
                text={
                  <>
                    <LanguageSelector
                      selectedCountry={
                        decodedToken
                          ? {
                              value: decodedToken?.country,
                              label: decodedToken?.country,
                            }
                          : countryValue
                      }
                      text={"EXTERIOR"}
                    />
                  </>
                }
                isComingSoon={false}
                clickHandler={exteriorOptionHandler}
              />
            </SituationDiv>
          </React.Fragment>
        )}
      </ConsumerContainer>
    </>
  );
};

export default ConsumerChooseImageType;
