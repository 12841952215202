import styled from "styled-components";

export const CookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

export const CookieHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  /* @media (max-width: 1500px) {
    visibility: hidden;
    padding: 0px;
  } */
  :hover {
    cursor: pointer;
  }
`;

export const CookieBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 120px;
`;

export const CookiePolicyHeaderText = styled.p`
  font-size: 42px;
  line-height: 50px;
  font-family: "Colour-Sans";
  margin: 0px;
`;

export const CookiePolicyText = styled.p`
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin: 20px 0;
`;

export const InformationText = styled.p`
  font-size: 32px;
  font-family: "Colour-Sans";
  margin: 0;
  color: #333333;
  font-weight: normal;
  margin: 10px 0;
`;

export const BodyText = styled.p`
  color: #666;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

export const BoldBodyText = styled.p`
  color: #666;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

export const BoldBodySpan = styled.span`
  color: #666;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

export const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`;

export const TableData = styled.td`
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid black;
`;

export const TableHeader = styled.th`
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid black;
`;

export const TableDataLink = styled.a`
  font-size: 14px;
  line-height: 1.42857143;
  color: #2fc48d;
`;
