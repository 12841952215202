import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";import {
  NearByStoresText,
  NearByStoresDiv,
  NearByStoresContainer,
  NearByStoresImage,
  NearByTitleDiv,
  NearByTitle,
  NearBySubTitle,
  SelectDiv,
  SelectCheckBox,
  SelectCheckBoxText,
  PaginationLeftImage,
  PaginationRightImage,
  PaginationArrowContainer
} from "../../styles/PreviewSupervisorSelectorStyles";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import SHOP_IMAGE from "../../images/shop_image.png";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorSelectorStores = ({
  setSelectedStores,
  selectedStores,
  shopList,
  incrementShop,
  decrementShop
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <div style={{ margin: "0px 0 0 20px", flex: "1.1" }}>
      <NearByStoresText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEARBY_STORES"}
            /></NearByStoresText>
      <NearByStoresDiv>
        {shopList.map((item, index) => {
          return (
            <NearByStoresContainer key={`${index}`}>
              <NearByStoresImage alt="Near By" src={SHOP_IMAGE} />

              <NearByTitleDiv>
                <NearByTitle>{item.shop_name}</NearByTitle>
                <NearBySubTitle>{item.city}, {item.address} </NearBySubTitle>
                <SelectDiv>
                  <SelectCheckBox
                    alt="CheckBox"
                    src={
                      selectedStores.includes(item.shop_id)
                        ? GREEN_CHECKBOX_CHECKED_IMAGE
                        : GREEN_CHECKBOX_UNCHECKED_IMAGE
                    }
                    onClick={() => {
                      if (selectedStores.includes(item.shop_id)) {
                        setSelectedStores(
                          selectedStores.filter(
                            (filteredItem) => filteredItem !== item.shop_id
                          )
                        );
                      } else {
                        setSelectedStores((oldArray) => [
                          ...oldArray,
                          item.shop_id,
                        ]);
                      }
                    }}
                  />
                  <SelectCheckBoxText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SELECT"}
            /></SelectCheckBoxText>
                </SelectDiv>
              </NearByTitleDiv>
            </NearByStoresContainer>
          );
        })}
      </NearByStoresDiv>
      <div style={{display:"flex", flexDirection:"row"}}>
      <PaginationArrowContainer>
        <PaginationLeftImage
        onClick={decrementShop}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination left"
        />
      </PaginationArrowContainer>
      <PaginationArrowContainer>
        <PaginationRightImage
        onClick={incrementShop}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination right"
        />
      </PaginationArrowContainer>
      </div>
    </div>
  );
};

export default PreviewSupervisorSelectorStores;
