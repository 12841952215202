import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  LeadColourSideBarDiv,
  LeadColourDiv,
  LeadColor,
  CancelDrawerImage,
  ColorBodyDiv,
  ColorDiv,
  ToolTip,
  TouchUpText,
  PaletteSideBarHeaderDiv,
  PaletteSideBarHeaderText,
  PaletteSideBearHeaderImage,
  SubTitleText,
  PaletteOptionDiv,
  PaletteOptionButtonDiv,
  PaletteOptionButton,
  SearchBarMainDiv,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import {
  ModalPaleeteDiv,
} from "../../styles/PreviewSupervisorPendingRequestThemeDetailStyles";
import {
  SearchColorColumnDiv,
  SearchShadeText,
  SearchColorRowDiv,
  SearchColorInputDiv,
  SearchButton,
  SuggestedPaletteSubTitle
} from "../../styles/ConsumerChooseColorStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
import PALETTE_IMAGE from "../../images/color-palette.png";
import PreviewSupervisorTouchUpLeadColourDrawer from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpLeadColourDrawer";
import PreviewSupervisorTouchUpThemeDrawer from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpThemeDrawer";
import LanguageSelector from "../../language/LanguageSelector"


const PreviewSupervisorTouchUpSideDrawer = ({
  openSideMenu,
  toggleSideMenu,
  colourList,
  familyColour,
  setSelectedColourFromDrawer,
  selectedColourFromDrawer,
  setColor,
  location,
  colorCollection,
  setColorCollection,
  selectedPath,
  setSearchText,
  searchText,
  errorText,
  searchColourHandler,
  color,
  usePalette,
  setUsePalette,
  paletteColorList,
  setPaletteColorList
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const selectedCountry= decodedToken
  ? {
      value: decodedToken?.country,
      label: decodedToken?.country,
    }
  : countryValue
  const searchColour = selectedCountry.value == "Vietnam" ? "Viet Search colour by name or code" : "Search colour by name or code";

  //Options are either palette or lead
  const [colorOption, setColorOption] = useState("lead");
  const [colorSuggestionList, setColorSuggestionList] = useState([]);
  console.log(paletteColorList);

  const familyColourSuccessCallback = (colourList) => {
    setColorSuggestionList(colourList);
  };

  const familyColorFailureCallback = (msg) => {
    alert(msg);
  };

  const colorPickerHandler = (name) => {
    familyColour({
      colour_family: name,
      familyColourSuccessCallback,
      familyColorFailureCallback,
    });
    // setSelectedColour(name);
  };

  const selectedColorHandler = (data) => {
    setColor(`rgb(${data.red_value},${data.green_value},${data.blue_value})`);
    var colorCollectionTemp = colorCollection;
    colorCollectionTemp[selectedPath] = `rgb(${data.red_value},${data.green_value},${data.blue_value})`;
    setColorCollection(colorCollectionTemp);
    selectedColourFromDrawer = data
    setSelectedColourFromDrawer(data);
    if(usePalette!== null){
      var paletteColorListTemp = paletteColorList;
      paletteColorListTemp[usePalette] = {
        red_value: data.red_value,
        green_value: data.green_value,
        blue_value: data.blue_value,
        colour_code: data.colour_code,
        colour_family: data.colour_family,
        colour_name: data.colour_name,
        colour_sensation: data.colour_sensation
      };
      setPaletteColorList(paletteColorListTemp);
      setUsePalette(null)
    }
    toggleSideMenu();
  };

  const optionSelectionHandler = (e) => {
    setColorOption(e.target.id);
  };

  return (
    <>
    <LeadColourSideBarDiv openSideMenu={openSideMenu}>
      <CancelDrawerImage src={CANCEL_IMAGE} onClick={toggleSideMenu} />
      <PaletteSideBarHeaderDiv>
          <PaletteSideBearHeaderImage src={PALETTE_IMAGE} alt="My Team" />
          <PaletteSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SELECT_COLOUR"}
            /></PaletteSideBarHeaderText>
          <SubTitleText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_A_COLOUR_FROM"}
            />
          </SubTitleText>
      </PaletteSideBarHeaderDiv>
      <PaletteOptionDiv 
       style={{
        marginTop: "30px",
        marginBottom: "30px"
        }}>
          <PaletteOptionButtonDiv>
            <PaletteOptionButton 
              style={{backgroundColor: colorOption === "lead" ? "#D3D3D3" : "white"}}
              id="lead" 
              onClick={optionSelectionHandler}
              >
               <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            /> 
            </PaletteOptionButton>
            <PaletteOptionButton style={{backgroundColor: colorOption === "palette" ? "#D3D3D3" : "white"}}
              id="palette" 
              onClick={optionSelectionHandler}
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COLOUR_PALETTE"}
            />
            </PaletteOptionButton>
          </PaletteOptionButtonDiv>
      </PaletteOptionDiv>

      <div 
      style={{
        marginTop: "30px",
        marginBottom: "30px"
        }}>

      {colorOption === "lead" ?
      <>
      <SearchBarMainDiv>
      <SearchColorColumnDiv>
        <SearchColorRowDiv>
          <SearchColorInputDiv
            value={searchText}
            placeholder={searchColour}
            onChange={(event) => setSearchText(event.target.value)}
            type="text"
            style={{width:"250px"}}
          />
          <SearchButton onClick={searchColourHandler} style={{width:"100px"}}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SEARCH"}
            /></SearchButton>
        </SearchColorRowDiv>
        {errorText ?
          <SuggestedPaletteSubTitle style={{color:"maroon"}}>
            <LanguageSelector selectedCountry={"Singapore"} text={"COLOUR_SEARCH_ERROR"} />
        </SuggestedPaletteSubTitle> : null}
      </SearchColorColumnDiv>
      </SearchBarMainDiv>
      <LeadColourDiv>
        {colourList.map((data, index) => {
          return (
            <LeadColor
              backgroundColour={data.color}
              key={`${index}`}
              onClick={() => {
                colorPickerHandler(data.name);
              }}
            />
          );
        })}
      </LeadColourDiv>
      <ColorBodyDiv>
        {colorSuggestionList.map((data, index) => {
          return (
            <ColorDiv
              key={`${index}`}
              style={{
                backgroundColor: `rgb(${data.red_value},${data.green_value},${data.blue_value})`,
                border:
                  data === selectedColourFromDrawer
                    ? "4px solid black"
                    : "none",
              }}
              onClick={() => {
                selectedColorHandler(data);
              }}
            >
              <ToolTip>{data.colour_name}</ToolTip>
            </ColorDiv>
          );
        })}
      </ColorBodyDiv>
      </>: 
        <ColorBodyDiv>
        {location.state.state.data.result?.request_detail.colourization_type === "Lead Colour" && 
        <>
        <PreviewSupervisorTouchUpLeadColourDrawer 
        location={location}
        setColor={setColor}
        setSelectedColourFromDrawer={setSelectedColourFromDrawer}
        selectedColourFromDrawer={selectedColourFromDrawer}
        toggleSideMenu={toggleSideMenu}
        setColorCollection={setColorCollection}
        colorCollection={colorCollection}
        selectedPath={selectedPath}
        />
        </>}
        {location.state.state.data.result?.request_detail.colourization_type === "Theme" && 
        <>
        <PreviewSupervisorTouchUpThemeDrawer 
        location={location}
        setColor={setColor}
        setSelectedColourFromDrawer={setSelectedColourFromDrawer}
        selectedColourFromDrawer={selectedColourFromDrawer}
        toggleSideMenu={toggleSideMenu}
        setColorCollection={setColorCollection}
        colorCollection={colorCollection}
        selectedPath={selectedPath}
        />
        </>}
        {location.state.state.data.request_detail?.colourization_type === "Reference" && 
        <>
        <SubTitleText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NO_PALETTE_GIVEN"}
            />
          </SubTitleText>
        </>}
      </ColorBodyDiv>}
      </div>
      </LeadColourSideBarDiv> 
    </>
  );
};

export default PreviewSupervisorTouchUpSideDrawer;
