import React from "react";
import {
  PreviewSupervisorRequestContainer,
  PreviewSupervisorRequestText,
  PreviewSupervisorRequestNumberContainer,
  PreviewSupervisorRequestNumberText,
  PreviewSupervisorRequestImage,
  RightArrowImage,
  PreviewSupervisorRequestContainerClock,
} from "../../styles/PreviewSupervisorDashboardStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";

const PreviewSupervisorDashboardRequest = ({
  image,
  number,
  title,
  showRightArrow,
  onClickHandler,
  hover,
}) => {
  return (
    <>
    {title == "Turn-around Time" ?
    <PreviewSupervisorRequestContainerClock onClick={onClickHandler} hover={hover}>
    {showRightArrow && (
      <RightArrowImage src={GREEN_ARROW_IMAGE} alt="Right Arrow" />
    )}
    <PreviewSupervisorRequestNumberContainer>
      <PreviewSupervisorRequestImage src={image} alt="Request" />
      <PreviewSupervisorRequestNumberText>
        {number}
      </PreviewSupervisorRequestNumberText>
    </PreviewSupervisorRequestNumberContainer>
    <PreviewSupervisorRequestText>{title}</PreviewSupervisorRequestText>
  </PreviewSupervisorRequestContainerClock> :
    <PreviewSupervisorRequestContainer onClick={onClickHandler} hover={hover}>
      {showRightArrow && (
        <RightArrowImage src={GREEN_ARROW_IMAGE} alt="Right Arrow" />
      )}
      <PreviewSupervisorRequestNumberContainer>
        <PreviewSupervisorRequestImage src={image} alt="Request" />
        <PreviewSupervisorRequestNumberText>
          {number}
        </PreviewSupervisorRequestNumberText>
      </PreviewSupervisorRequestNumberContainer>
      <PreviewSupervisorRequestText>{title}</PreviewSupervisorRequestText>
    </PreviewSupervisorRequestContainer>
    }
    </>
  );
};

export default PreviewSupervisorDashboardRequest;
