import React, {useState} from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ResolveRequestSideBar,
  CancelImage,
  MyTeamSideBarHeaderDiv,
  MyTeamSideBearHeaderImage,
  MyTeamSideBarHeaderText,
  ResolveRequestModalDiv,
} from "../../styles/PreviewSupervisorDashboardStyles";
import styled from "styled-components";
import { Formik } from "formik";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import LanguageSelector from "../../language/LanguageSelector";


const PreviewSupervisorDashboardResolveRequestFeedbackSidebar = ({
  rateResultFeedback,
  toggleRateResultFeedback,
  toggleResolveRequestMenu,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [colourBleed, setColourBleed] = useState(false)
  const [filterEffect, setFilterEffect] = useState(false)
  const [wrongColour, setWrongColour] = useState(false)
  const [otherOption, setOtherOption] = useState(false)
  const [progress, setProgress] = useState(false);

  
  
  const doneClickHandler = () => {
    toggleRateResultFeedback();
  };
  const cancelClickHandler =() => {
    toggleRateResultFeedback();
    toggleResolveRequestMenu();
  }
  const toggleColourBleed = () => {
    setColourBleed((previousState) => !previousState);
  };
  const toggleFilterEffect = () => {
    setFilterEffect((previousState) => !previousState);
  };
  const toggleWrongColour = () => {
    setWrongColour((previousState) => !previousState);
  };
  const toggleOtherOption = () => {
    setOtherOption((previousState) => !previousState);
  };
  const toggleProgress = () => setProgress((previousState) => !previousState);


  if (width < 1100) {
    return (
      <ResolveRequestModalDiv openSideMenu={rateResultFeedback}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={cancelClickHandler}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <SubTitleText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_FROM_AI_GENERATED"}
            />
        </SubTitleText>

        
        <Footer>
          <ButtonBar>

            <SubmitButton onClick={doneClickHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></SubmitButton>
          </ButtonBar>
        </Footer>
      </ResolveRequestModalDiv>
    );
  } else {
    return (
      <ResolveRequestSideBar openSideMenu={rateResultFeedback}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={cancelClickHandler}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <SubTitleText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_FROM_AI_GENERATED"}
            />
        </SubTitleText>
        <FeedbackOptionContainer>
          <FeedbackTitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RATE_THIS_RESULT"}
            /></FeedbackTitleText>
          <RangeSliderLabelContainer>
            <RangeSliderLabel>          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"POOR"}
            /></RangeSliderLabel>
            <RangeSliderLabel>          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EXCELLENT"}
            /></RangeSliderLabel>
          </RangeSliderLabelContainer>
          <RangeSlider></RangeSlider>
        </FeedbackOptionContainer>
        <FeedbackOptionContainer>
        <FeedbackTitleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REASON_FOR_POOR_RATING"}
            /></FeedbackTitleText>
        <ResultDiv>
        <img
              key={colourBleed}
              src={colourBleed
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={toggleColourBleed}
            />
            <FeedbackOptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COLOUR_BLEED"}
            /></FeedbackOptionText>
        </ResultDiv>
        <ResultDiv>
        <img
              key={filterEffect}
              src={filterEffect
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={toggleFilterEffect}
            />
            <FeedbackOptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FILTER_LIKE_EFFECT"}
            /></FeedbackOptionText>
        </ResultDiv>
        <ResultDiv>
        <img
              key={wrongColour}
              src={wrongColour
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={toggleWrongColour}
            />
            <FeedbackOptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"APPLIED_WRONG_COLOUR"}
            /></FeedbackOptionText>
        </ResultDiv>
        <ResultDiv>
        <img
              key={otherOption}
              src={otherOption
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={toggleOtherOption}
            />
            <FeedbackOptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OTHER"}
            /></FeedbackOptionText>
        </ResultDiv>
        <Formik
          initialValues={{ comment: " " }}
          onSubmit={(values, actions) => {
            if (!progress) {
              toggleProgress();
            }
          }}
        >
          {(formikProps) => {
            return (
              <>
                <div
                  className="form-control"
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    margin: "46px 0 0 0",
                    height: "100px",
                    width: "25vw"
                    
                  }}
                >
                  <input
                    required
                    name="input"
                    value={formikProps.values.comment}
                    style={{
                      alignSelf:"flex-start"                    
                    }}
                  />
                  <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COMMENT"}
            /></label>
                  <div className="border-around"></div>
                </div>
              </>
            );
          }}
        </Formik>
        <ButtonBar>
          <CancelButton onClick={doneClickHandler}>Cancel</CancelButton>
          <SubmitButton>Submit</SubmitButton>
        </ButtonBar>

        </FeedbackOptionContainer>
        <Footer>
        </Footer>
      </ResolveRequestSideBar>
    );
  }
};


const FeedbackOptionContainer = styled.div`
  margin: 20px 25px;
  width: 80%
`;

//Add range progress for other browsers too
const RangeSlider = styled.input.attrs(props => ({
  type: 'range',
  min: '0',
  max: '5',
  step: '1',
  onChange: props.onChange
}))`
  -webkit-appearance: none;
  border-radius: 7px;
  padding: 0px;
  background: #F1F1F1;
  position: absolute;
  width: 80%;
  height: 8px;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 7px;
      background: #2FC38C;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 7px;
      background: #2FC38C;
      cursor: pointer;
    }

    &::-moz-range-track {
      padding: 0 10px;
      height: 8px;
      background: repeating-linear-gradient(to right, 
        #F1F1F1, 
        #F1F1F1 19.6%, 
        #C4C4C4 19.7%, 
        #C4C4C4 20.1%);
    }
    
    &::-moz-range-progress {
      border-radius: 7px;
      background-color: #012169; 
      height: 8px;
    }
  
  }
`;



const RangeSliderLabel = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
`;


const RangeSliderLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


const FeedbackTitleText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
`;


const FeedbackOptionText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #012169;
  margin: 10px
`;


const ResultDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;


const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 40px;

  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

//@media (max-width: 1100px) {} -> add mobile view
const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 4px 30px;
  :hover {
    cursor: pointer;
  }
`;


const CancelButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin: 0 10px 0 0;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;


const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 25px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;


const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  margin: 4px 25px;
`;

export const PaginationButton = styled.button`
  background: #f1f1f1;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  color: ${(props) => (props.isSelected === true ? "#012169" : "#2FC38C")};
  margin: 0px 4px;
  border-radius: 50%;
`;

export default PreviewSupervisorDashboardResolveRequestFeedbackSidebar;
