/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PersonalizedHome from "../../components/home/PersonalizedHome";
import HomeHeader from "../../components/home/HomeHeader";
import HomeDuluxPreview from "../../components/home/HomeDuluxPreview";
import HomeHowItWorks from "../../components/home/HomeHowItWorks";
import HomeColourTrends from "../../components/home/HomeColourTrends";
import { HomeContainer } from "../../styles/HomeStyles";  
import HomeLivingSpaces from "../../components/home/HomeLivingSpaces";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import HomeSocialMedia from "../../components/home/HomeSocialMedia";
import HomeSubFooter from "../../components/home/HomeSubFooter";
import HomeFooter from "../../components/home/HomeFooter";
import {
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  // MALAYSIA_HOME_PAGE_ROUTE,
  // SINGAPORE_HOME_PAGE_ROUTE,
} from "../../utils/Constants";
import ConsumerLoginSideBar from "../../components/consumer-login/ConsumerLoginSideBar";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import { ColourizationContext } from "../../context/ColourizationContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import HomeCountrySideBar from "../../components/home/HomeCountrySideBar";
import ErrorMessage from "../../styles/ConsumerDuluxPreviewStyles";

const Home = ({ history }) => {
  const { width } = useWindowDimensions();
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [countrySideBar, setCountrySideBar] = useState(false);
  const [geoFlag, setGeoFlag] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({
    value: "Singapore",
    label: "Singapore",
  });

  const countries = [
    "Singapore",
    "Malaysia",
    "Indonesia",
    "Pakistan",
    "Sri Lanka",
    "Thailand",
    "Vietnam",
  ];

  const { state, verifyEmail, verifyEmailOTP } = useContext(AuthContext);
  const colourizationContext = useContext(ColourizationContext);
  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const { getGeocodingDetails } = useContext(ConsumerContext);

  const toggleSideMenu = () => {
    setOpenSideMenu((previousState) => !previousState);
    setGeoFlag(true);
  };
  const toggleCountrySideBar = () => {
    setCountrySideBar((previousState) => !previousState);
    setGeoFlag(true);
  };
  const goToConsumerClickHandler = () => {
    history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
  };

  console.log(history.location.pathname);

  useEffect(() => {
    // Update the document title using the browser API

    if (history.location.pathname === "/my") {
      setSelectedCountry({ value: "Malaysia", label: "Malaysia" });
    } else if (history.location.pathname === "/sg") {
      setSelectedCountry({ value: "Singapore", label: "Singapore" });
    } else if (history.location.pathname === "/id") {
      setSelectedCountry({ value: "Indonesia", label: "Indonesia" });
    } else if (history.location.pathname === "/in") {
      setSelectedCountry({ value: "India", label: "India" });
    } else if (history.location.pathname === "/pk") {
      setSelectedCountry({ value: "Pakistan", label: "Pakistan" });
    } else if (history.location.pathname === "/th") {
      setSelectedCountry({ value: "Thailand", label: "Thailand" });
    } else if (history.location.pathname === "/vn") {
      setSelectedCountry({ value: "Vietnam", label: "Vietnam" });
    } else if (history.location.pathname === "/sl") {
      setSelectedCountry({ value: "SriLanka", label: "SriLanka" });
    } else {
      if (geoFlag === false) {
        getLocation();
        console.log("geolocation is set");
      }
      if (navigator.cookieEnabled) {
        console.log("Set local");
        localStorage.setItem(
          "country",
          JSON.stringify({
            value: "Singapore",
            label: "Singapore",
          })
        );
      } else {
        alert("Enable 3rd party cookies to use this site.");
      }
    }
    console.log("selected dropdown is set");
  }, []);

  const getGeocodingSuccessCallback = (data) => {
    for (var res_index = 0; res_index < data.results.length; res_index++) {
      data.results[res_index].address_components.map((addr) => {
        if (
          geoFlag === false &&
          addr.types.includes("country") &&
          addr.types.includes("political")
        ) {
          var result = countries.filter(
            (x) => x.toLowerCase() === addr.long_name.toLowerCase()
          );
          if (result.length > 0) {
            setSelectedCountry({
              value: addr.long_name,
              label: addr.long_name,
            });
          }
        }
        return true;
      });
    }
    console.log("end of geolocation");
  };

  const getGeocodingFailureCallback = () => {
    setSelectedCountry({
      value: "Singapore",
      label: "Singapore",
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        getGeocodingDetails({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          getGeocodingSuccessCallback,
          getGeocodingFailureCallback,
        });
      });
    } else {
      setSelectedCountry({
        value: "Singapore",
        label: "Singapore",
      });
    }
    setGeoFlag(true);
  };

  // useEffect(() => {
  //   if (geoFlag === false) {
  //     getLocation();
  //   }
  //   if (navigator.cookieEnabled) {
  //     console.log("Set local");
  //     localStorage.setItem(
  //       "country",
  //       JSON.stringify({
  //         value: "Singapore",
  //         label: "Singapore",
  //       })
  //     );
  //   } else {
  //     alert("Enable 3rd party cookies to use this site.");
  //   }
  // }, []);

  return (
    <>
      <ConsumerLoginSideBar
        width={width}
        openSideMenu={openSideMenu}
        toggleSideMenu={toggleSideMenu}
        state={state}
        verifyEmail={verifyEmail}
        verifyEmailOTP={verifyEmailOTP}
        email={email}
        setEmail={setEmail}
        history={history}
        colourizationContext={colourizationContext}
        previewSupervisorDetailContext={previewSupervisorDetailContext}
        selectedCountry={selectedCountry}
      />
      <HomeCountrySideBar
        width={width}
        countrySideBar={countrySideBar}
        toggleCountrySideBar={toggleCountrySideBar}
        history={history}
      />
      <HomeContainer applyFilter={openSideMenu || countrySideBar}>
        <HomeHeader
          toggleSideMenu={toggleSideMenu}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          showLogin={true}
          width={width}
          history={history}
        />
        <HomeDuluxPreview
          goToConsumerClickHandler={goToConsumerClickHandler}
          selectedCountry={selectedCountry}
          width={width}
        />
        <PersonalizedHome
          goToConsumerClickHandler={goToConsumerClickHandler}
          selectedCountry={selectedCountry}
          width={width}
          history={history}
        />
        <HomeHowItWorks width={width} selectedCountry={selectedCountry} />
        <HomeColourTrends
          goToConsumerClickHandler={goToConsumerClickHandler}
          selectedCountry={selectedCountry}
          width={width}
        />
        <HomeLivingSpaces
          goToConsumerClickHandler={goToConsumerClickHandler}
          selectedCountry={selectedCountry}
          width={width}
        />
        <HomeSocialMedia selectedCountry={selectedCountry} />
        <HomeSubFooter selectedCountry={selectedCountry} width={width} />
        <HomeFooter selectedCountry={selectedCountry} history={history} />
      </HomeContainer>
    </>
  );
};

export default Home;
