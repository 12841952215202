import React from "react";
import { HowItWorksSubTitle, HowItWorksTitle } from "../../styles/HomeStyles";

const HomeWorkComponent = ({ image, title, subTitle, width }) => {
  if (width < 1100) {
    return (
      <div
        style={{
          margin: "20px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={image}
          alt="Get Started"
          style={{ width: "50px", height: "50px" }}
        />
        <HowItWorksTitle>{title}</HowItWorksTitle>
        <HowItWorksSubTitle>{subTitle}</HowItWorksSubTitle>
      </div>
    );
  } else {
    return (
      <div style={{ margin: "20px 0px" }}>
        <img
          src={image}
          alt="Get Started"
          // style={{ width: "5vw", height: "10vh" }}
        />
        <HowItWorksTitle>{title}</HowItWorksTitle>
        <HowItWorksSubTitle>{subTitle}</HowItWorksSubTitle>
      </div>
    );
  }
};

export default HomeWorkComponent;
