import React from "react";
import { Redirect, Route } from "react-router-dom";
// import { ColourizationContext } from "../context/ColourizationContext";
// import { PreviewSupervisorDetailsContext } from "../context/PreviewSupervisorDetailsContext";
import {
  ADMIN_DASHBOARD_ROUTE,
  CONSUMER_HOME_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
  PREVIEW_STAFF_DASHBOARD_ROUTE,
} from "./Constants";

const DynamicRoute = (props) => {
  // const colourizationContext = useContext(ColourizationContext);
  // const previewSupervisorDetailContext = useContext(
  //   PreviewSupervisorDetailsContext
  // );

  if (
    props.authenticated &&
    props.decodedToken &&
    props.decodedToken?.user_type === "Consumer"
  ) {
    // console.log("123" + props.path);
    // colourizationContext.setColourizationDetails({
    //   raw_image: "",
    //   colourization_type: "",
    //   reference: "",
    //   theme: "",
    //   lead_colour: "",
    //   email: props.decodedToken.email,
    //   name: props.decodedToken.name,
    //   city: "",
    //   country: "",
    // });
    return <Redirect to={CONSUMER_HOME_ROUTE} />;
  } else if (
    props.authenticated &&
    props.decodedToken &&
    props.decodedToken?.user_type === "Preview Supervisor"
  ) {
    // previewSupervisorDetailContext.setPreviewSupervisorDetails({
    //   email: props.decodedToken?.email,
    //   country: props.decodedToken?.country,
    //   isPreviewStaff: false,
    // });
    return <Redirect to={PREVIEW_SUPERVISOR_DASHBOARD_ROUTE} />;
  } else if (
    props.authenticated &&
    props.decodedToken &&
    props.decodedToken?.user_type === "Super Admin"
  ) {
    return <Redirect to={ADMIN_DASHBOARD_ROUTE} />;
  } else if (
    props.authenticated &&
    props.decodedToken &&
    props.decodedToken?.user_type === "Preview Staff"
  ) {
    // previewSupervisorDetailContext.setPreviewSupervisorDetails({
    //   email: props.decodedToken?.email,
    //   country: props.decodedToken?.country,
    //   isPreviewStaff: true,
    // });
    return <Redirect to={PREVIEW_STAFF_DASHBOARD_ROUTE} />;
  } else {
    return <Route component={props.component} {...props} />;
  }
};

export default DynamicRoute;
