import React from "react";
import {
  ColorText,
  ModalPaleeteDiv,
  ToolTip,
  OriginalImageBodyText,
  ReferenceImageBodyContainer,
  ImageComparisonContainer,
  OriginalImageBodyContainer,
  ReferenceImageBodyText,
  OriginalBodyImage,
  PaletteListDiv,
  PaletteBodyText
} from "../../styles/PreviewSupervisorPendingRequestThemeDetailStyles";

const PreviewSupervisorTouchUpThemeDrawer = ({
  location,
  setColor,
  setSelectedColourFromDrawer,
  toggleSideMenu,
  setColorCollection,
  colorCollection,
  selectedPath,
 }) => {
  const state = location.state.state;
  const selectedColorHandler = (e) => {
    const backgroundcolor = window.getComputedStyle(e.target, null).getPropertyValue("background-color");
    var colorCollectionTemp = colorCollection;
    colorCollectionTemp[selectedPath] = backgroundcolor;
    setColorCollection(colorCollectionTemp);
    setColor(backgroundcolor);
    toggleSideMenu();
  };

  return (
    <ImageComparisonContainer>
      <ReferenceImageBodyContainer>
        {/* <ReferenceImageBodyText>
          {`Theme Image: ${
            state.data && state.data?.result?.request_detail?.theme_name
          }`}
        </ReferenceImageBodyText> */}
        {state.data?.palette?.palette_1_colours.length > 0 && (
          <>
          <PaletteBodyText>
                {`Palette 1:`}
          </PaletteBodyText>
            <PaletteListDiv>
              {state.data?.palette?.palette_1_colours.map(
                (paletteItem, index) => {
                  return (
                    <div key={`${index}`}>
                      <ModalPaleeteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        onClick={selectedColorHandler}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{paletteItem?.colour_code}</ColorText>
                    </div>
                  );
                }
              )}
              </PaletteListDiv>
              <PaletteBodyText>
                {`Palette 2:`}
          </PaletteBodyText>
              <PaletteListDiv>
              {state.data?.palette?.palette_2_colours.map(
                (paletteItem, index) => {
                  return (
                    <div key={`${index}`}>
                      <ModalPaleeteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        onClick={selectedColorHandler}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{paletteItem?.colour_code}</ColorText>
                    </div>
                  );
                }
              )}
              </PaletteListDiv>
              <PaletteBodyText>
                {`Palette 3:`}
          </PaletteBodyText>
              <PaletteListDiv>
              {state.data?.palette?.palette_3_colours.map(
                (paletteItem, index) => {
                  return (
                    <div key={`${index}`}>
                      <ModalPaleeteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        onClick={selectedColorHandler}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{paletteItem?.colour_code}</ColorText>
                    </div>
                  );
                }
              )}
            </PaletteListDiv>
          </>
        )}
      </ReferenceImageBodyContainer>
      {/* <ThemePaletteDiv>
          <ThemePaletteText>Theme Palette</ThemePaletteText>
         
        </ThemePaletteDiv> */}
    </ImageComparisonContainer>
  );
};

export default PreviewSupervisorTouchUpThemeDrawer;
