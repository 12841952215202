import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminCustomerSearchRequestSideBarContainer,
  AdminCustomerSearchRequestHeader,
  AdminCustomerRequestsText,
  ByCountryText,
  AdminCustomerSearchRequestCountryDiv,
  SelectedCountryCheck,
  AdminCountryText,
  // RangeProgressBar,
  // RangerInputSlider,
  ResetFilterButton,
  // RangeNumber,
  DoneButton,
  // RangeNumberDiv,
  ButtonBar,
  AdminCustomerSearchRequestModalBarDiv,
} from "../../styles/AdminCustomersStyles";
import SEARCH_FILTER from "../../images/search_filter.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminCustomerSearchRequests = ({
  openSideMenu,
  toggleCustomerSearchRequest,
  requestList,
  setTempRequestList,
  setPaginationList,
  setSelectedPage,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const countries = [
    "Singapore",
    "India",
    "Indonesia",
    "Pakistan",
    "Vietnam",
    "Thailand",
    "Malaysia",
    "Sri Lanka"
  ];

  const [selectedCountries, setSelectedCountries] = useState([]);
  // const [slider, setSlider] = useState(2);

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  const resetFilterClickHandler = () => {
    setSelectedCountries([]);
    setTempRequestList(requestList);
    setSelectedPage(1);
    updatePagination(requestList);
    toggleCustomerSearchRequest();
  };

  const countrySelectedHandler = (item) => {
    if (item === "All") {
    } else {
      if (selectedCountries.includes(item)) {
        setSelectedCountries(
          selectedCountries.filter((filteredItem) => filteredItem !== item)
        );
      } else {
        setSelectedCountries((oldArray) => [...oldArray, item]);
      }
    }
  };

  const applyFilterHandler = () => {
    toggleCustomerSearchRequest();
    let newList = [],
      filteredList = [];
    if (selectedCountries.length > 0) {
      for (let i = 0; i < selectedCountries.length; i++) {
        filteredList = requestList.filter(
          (data) => data.country === selectedCountries[i]
        );
        newList.push(...filteredList);
      }
      setSelectedPage(1);
      setTempRequestList(newList);
      updatePagination(newList);
    } else {
      resetFilterClickHandler();
    }
  };

  if (width < 1100) {
    return (
      <AdminCustomerSearchRequestModalBarDiv openSideMenu={openSideMenu}>
        <AdminCustomerSearchRequestHeader>
          <img src={SEARCH_FILTER} alt="Search" />
          <AdminCustomerRequestsText>
            <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_CUSTOMERS"}
        />
          </AdminCustomerRequestsText>
        </AdminCustomerSearchRequestHeader>
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
        {countries.map((value, index) => {
          return (
            <AdminCustomerSearchRequestCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedCountries.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => countrySelectedHandler(value)}
              />
              <AdminCountryText isSelected={true}>{value}</AdminCountryText>
            </AdminCustomerSearchRequestCountryDiv>
          );
        })}
        {/* <ByCountryText>By Rating</ByCountryText> */}
        {/* <div style={{ position: "relative", width: "90%", marginBottom: "20px" }}>
          <RangerInputSlider
            type="range"
            min="0"
            max={4}
            value={slider}
            onChange={(event) => {
              setSlider(event.target.value);
            }}
          />
          <RangeProgressBar
            sliderValue={slider <= 1 ? slider * 27 : slider * 25}
          />
          <RangeNumberDiv>
            <RangeNumber>1</RangeNumber>
            <RangeNumber>2</RangeNumber>
            <RangeNumber>3</RangeNumber>
            <RangeNumber>4</RangeNumber>
            <RangeNumber>5</RangeNumber>
          </RangeNumberDiv>
        </div> */}
        <ButtonBar>
          <ResetFilterButton onClick={resetFilterClickHandler}>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
          </ResetFilterButton>
          <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
        </ButtonBar>
      </AdminCustomerSearchRequestModalBarDiv>
    );
  } else {
    return (
      <AdminCustomerSearchRequestSideBarContainer openSideMenu={openSideMenu}>
        <AdminCustomerSearchRequestHeader>
          <img src={SEARCH_FILTER} alt="Search" />
          <AdminCustomerRequestsText>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_CUSTOMERS"}
        />
          </AdminCustomerRequestsText>
        </AdminCustomerSearchRequestHeader>
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
        {countries.map((value, index) => {
          return (
            <AdminCustomerSearchRequestCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedCountries.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => countrySelectedHandler(value)}
              />
              <AdminCountryText isSelected={true}>{value}</AdminCountryText>
            </AdminCustomerSearchRequestCountryDiv>
          );
        })}
        {/* <ByCountryText>By Rating</ByCountryText> */}
        {/* <div style={{ position: "relative", width: "90%", marginBottom: "20px" }}>
          <RangerInputSlider
            type="range"
            min="0"
            max={4}
            value={slider}
            onChange={(event) => {
              setSlider(event.target.value);
            }}
          />
          <RangeProgressBar
            sliderValue={slider <= 1 ? slider * 27 : slider * 25}
          />
          <RangeNumberDiv>
            <RangeNumber>1</RangeNumber>
            <RangeNumber>2</RangeNumber>
            <RangeNumber>3</RangeNumber>
            <RangeNumber>4</RangeNumber>
            <RangeNumber>5</RangeNumber>
          </RangeNumberDiv>
        </div> */}
        <ButtonBar>
          <ResetFilterButton onClick={resetFilterClickHandler}>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
          </ResetFilterButton>
          <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
        </ButtonBar>
      </AdminCustomerSearchRequestSideBarContainer>
    );
  }
};

export default AdminCustomerSearchRequests;
