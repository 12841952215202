import styled from "styled-components";

export const HomeContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const Header = styled.header`
  flex-direction: row;
  display: flex;
  width: 100vw;
  height: 100px;
  position: relative;
  z-index: 11;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
`;

export const HamburgerHeader = styled.div`
  padding: 0 10px;
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  /* height: 12vh; */
  align-self: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    padding: 0;
  }
`;

export const AkzoNobelContainer = styled.div`
  background-image: linear-gradient(
    270deg,
    #eaeaea 0%,
    rgba(196, 196, 196, 0) 83.15%
  );
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 4px 9vw 14px 10px;
  align-items: center;
`;

export const AkzonobelImage = styled.img`
  align-self: flex-end;
  margin-left: 10px;
`;

export const HeaderOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const HeaderOptionsText = styled.p`
  font-weight: 600;
  line-height: 160%;
  font-size: 16px;
  font-style: normal;
  color: #012169;
  :hover {
    cursor: pointer;
  }
`;

export const HeaderLoginButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 20vw;
  padding: 0px 10vw;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  border: none;
  outline: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    padding: 0 20px;
    width: 60px;
  }
`;

export const HeaderLoginText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #012169;
  margin: 0px 2px;
`;

export const CountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #4a4a4a;
  margin: 0 10px 0 0;
`;

export const PersonalizedContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  justify-content: center;
  margin: 4vh 0;
`;

export const PersonalizedColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 20px;
`;

export const PersonalizedColumnImage = styled.img`
  width: 100%;
  align-self: center;
`;

export const PersonalizedTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  justify-content: center;
  /* width: 50vw; */
`;

export const HomePersonalizedTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  width: 30vw;
  @media (max-width: 1100px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const PersonalizedDesignText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  margin: 0;
  @media (max-width: 1100px) {
    margin-top: 10px;
    font-size: 27px;
  }
`;

export const PersonalizedColorRecommendationsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #333333;
  margin: 4px 0px;
`;

export const GetStartedButton = styled.button`
  background-color: #2fc38c;
  border-radius: 5px;
  border: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding: 4px 10px;
  width: 10vw;
  margin: 20px 0px;
  outline: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 100%;
    padding: 10px;
  }
`;

export const HowItWorksText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 150%;
  color: #000000;
  margin: 0px;
  @media (max-width: 1100px) {
    font-size: 27px;
    margin-top: 10px;
  }
`;

export const HowItWorksSubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #333333;
  margin: 0 0 2vh 0;
  @media (max-width: 1100px) {
    font-size: 16px;
  }
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 2vw 0px 2vw;
  margin: 0;
`;

export const HowItWorksContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HowItWorksTitle = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  margin: 2vh 0 1vh 0;
  color: #333333;
`;

export const HowItWorksSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #666666;
  margin: 0;
`;

export const HomeColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
  margin: 4vh 0;
`;

export const HomeColorColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  margin: 20px;
  @media (max-width: 1100px) {
    margin: 40px 20px 20px 20px;
  }
`;

export const HomeColourTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 2vw;
  justify-content: center;
  @media (max-width: 1100px) {
    margin-top: 20px;
  }
`;

export const HomeColorDesignText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 27px;
  }
`;

export const HomeColorRecommendationsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #333333;
  margin: 4px 0px;
`;

export const KnowMoreButton = styled.button`
  background-color: #2fc38c;
  border-radius: 5px;
  border: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding: 4px 10px;
  width: 10vw;
  margin: 10px 0px;
  outline: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 100%;
    padding: 10px;
  }
`;

export const CustomizeLivingSpaceText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 130%;
  color: #ffffff;
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 27px;
    color: black;
    margin-top: 20px;
  }
`;

export const CustomizeLivingSpaceSubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
  @media (max-width: 1100px) {
    color: black;
  }
`;

export const HomeSociaMediaContainer = styled.div`
  background-color: #fff;
  padding: 10vh 0;
  @media (max-width: 1100px) {
    background: #f9f9f9;
  }
`;

export const AddSomeColorToFeedText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

export const PersonalizeImage = styled.img`
  width: 50vw;
`;

export const HomeFooterImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5vh 0px 2vh 0px;
`;

export const HomeFooterLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HomeFooterLinksText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #666666;
  margin: 0px 10px;
  :hover {
    cursor: pointer;
  }
`;

export const HomeFooterEndDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 30px 0px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const HomeFooterEndText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #666666;
  margin-right: 10px;
`;

export const FacebookLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 3vw;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 25px;
    height: 25px;
  }
`;

export const YoutubeLogo = styled.img`
  width: 40px;
  height: 28px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 25px;
    height: 25px;
  }
`;

export const MenuItem = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 200%;
  color: #ffffff;
  margin: 0;
  :hover {
    cursor: pointer;
  }
`;
