import React from "react";
import {
  OptionTextStyle,
  ViewDetailsButton,
  ResultImageDiv,
  ToolTip,
  ResultImage,
  ResultColumnDiv,
  PaletteDiv,
  PaletteWrapDiv,
} from "../../styles/ConsumerDetailReportStyles";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportResult = ({
  state,
  toggleModal,
  viewDetailHandler,
  setSelectedModalIndex,
  selectedCountry,
}) => {
  return (
    <ResultImageDiv>
      {state.data &&
        state.data?.result?.report_details?.results?.length > 0 &&
        state.data?.result?.report_details?.results.map((item, index) => {
          return (
            <ResultColumnDiv key={`${index}`}>
              <OptionTextStyle><LanguageSelector selectedCountry={selectedCountry} text={"OPTION"} />{index + 1}</OptionTextStyle>
              <ResultImage
                onClick={() => {
                  setSelectedModalIndex(index);
                  toggleModal();
                }}
                src={item.result_image}
              />
              <PaletteWrapDiv>
                {item.colours.map((item2, index2) => {
                  return (
                    <PaletteDiv
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                      key={`${index}${index2}`}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </PaletteDiv>
                  );
                })}
              </PaletteWrapDiv>
              <ViewDetailsButton onClick={() => viewDetailHandler(index)}>
                <LanguageSelector selectedCountry={selectedCountry} text={"VIEW_DETAILS"} />
              </ViewDetailsButton>
            </ResultColumnDiv>
          );
        })}
    </ResultImageDiv>
  );
};

export default ConsumerDetailReportResult;
