import styled from "styled-components";

export const ConsumerChooseColourContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  @media (max-width: 1100px) {
    height: auto;
  }
`;

export const ConsumerChooseColourModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const LeadColorParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 4vw;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
  }
`;

export const OrText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
  align-self: flex-start;
`;

export const SearchColorColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SearchColorRowDiv = styled.div`
  display: flex;
`;

export const SearchColorInputDiv = styled.input`
  background: #ffffff;
  border: 1px solid #d7d4cb;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-align: center;
  color: #545850;
  height: 30px;
`;

export const SearchButton = styled.button`
  background: #ffffff;
  font-family: Open Sans;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const SearchShadeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
`;

export const SelectColorText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
  margin: 10px 18px;
`;

export const SelectColourFamilyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const SelectColourFamilyCard = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 10px;
  margin: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const LeadColourDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const CheckImage = styled.img`
  visibility: hidden;
`;

export const LeadColor = styled.div`
  background-color: ${(props) => `${props.backgroundColour}`};
  width: 60px;
  height: 40px;
  transition: transform 0.2s;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:hover ${CheckImage} {
    visibility: visible;
  }
`;

export const ConsumerChooseColourDrawerDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
`;

export const SelectColourFamilyTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-align: center;
  color: #000000;
  margin: 8px 10px 4px 10px;
`;

export const ShadesText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #545850;
  margin: 4px 10px;
`;

export const ShadesBottomDiv = styled.div`
  background: #d1432a;
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
`;

export const DrawerTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  width: 90%;
`;

export const TitleColour = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const CloseModalImage = styled.img`
  width: 30px;
  height: 30px;
  :hover {
    cursor: pointer;
  }
`;

export const ClosePaletteModalImage = styled.img`
  position: absolute;
  right: 0;
  margin-right: 4px;
  top: 10;
  :hover {
    cursor: pointer;
  }
`;

export const ColorBodyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
`;

export const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const ColorDiv = styled.div`
  border-radius: 3px;
  width: 80px;
  height: 80px;
  margin: 10px;
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  align-self: center;
  width: 100px;
  outline: none;
  border: none;
  padding: 4px;
  :hover {
    cursor: pointer;
  }
`;

export const SuggestedPalettetext = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 40px auto 10px auto;
`;

export const SuggestedPaletteSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #545850;
  margin: 0px;
`;

export const NextButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 100px;
  margin: 10px auto;
  :hover {
    cursor: pointer;
  }
`;

export const SuggestPaletteParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const SuggestedPaletteBodyDiv = styled.div`
  border: 1px dashed #e5e5e5;
  border-radius: 6px;
  margin: 10px;
  padding: 10px;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PaleeteTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  margin: 10px auto;
`;

export const PaleetColorBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaletteColorDiv = styled.div`
  border-radius: 5px;
  width: 112px;
  height: 61px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;
`;

export const PaletteColorText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 0 auto 4px auto;
  align-self: flex-end;
`;

export const SelectedShadeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
`;

export const SelectedShadeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 10vw;
  @media (max-width: 1100px) {
    margin: 0px 10px;
  }
`;

export const ColourShadeDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5vh;
`;

export const ColourShade = styled.div`
  border-radius: 5px;
  width: 112px;
  height: 61px;
`;

export const SelectedColorShade = styled.div`
  border-radius: 5px;
  width: 224px;
  height: 122px;
  @media (max-width: 1100px) {
    width: 112px;
    height: 61px;
  }
`;

export const ColourShadeTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 10px;
  margin-left: 10px;
  @media (max-width: 1100px) {
    margin-left: 5px;
  }
`;

export const ColourName = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 143%;
  text-align: center;
  margin: 0;
  color: #000000;
  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;

export const ColourCode = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;

export const TabDiv = styled.div`
  width: 92%;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
  // -moz-box-shadow: 0 0 5px rgba(0,0,0,0.3);
`;

export const ButtonDiv = styled.div`
  height: 50px;
`;

export const TabDivButton = styled.button`
  width: 150px;
  height: 100%;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  padding 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  &:hover {
    background-color: #DCDCDC;
  }
`;

export const SelectedColorDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
  // -moz-box-shadow: 0 0 5px rgba(0,0,0,0.3);
`;
