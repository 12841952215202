/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminResolvedFeedbackSideBar,
  FeedbackTitleRow,
  FeedbackText,
  FeedbackImage,
  FeedbackRow,
  FeedbackCircularDiv,
  FeedbackColumn,
  FeedbackRowSubTitle,
  FeedbackRowImage,
  FeedbackRowTitle,
  FeedbackDataColumn,
  FeedbackDataSubTitle,
  FeedbackDataTitle,
  FeedbackDataRow,
  DoneButton,
  FeedbackButtonBar,
  AdminResolvedModalBarDiv,
} from "../../styles/AdminRequestStyles";
import SMILEY_IMAGE from "../../images/smiley.svg";
import REQUEST_ID_IMAGE from "../../images/request_id.svg";
import ASSIGNED_TO_IMAGE from "../../images/assigned_to.svg";
import CUSTOMER_STAR_IMAGE from "../../images/customer_star.svg";
import EASE_OF_USE_IMAGE from "../../images/ease_of_use.svg";
import GALLERY_IMAGE from "../../images/gallery.svg";
import CLOCK_BLUE_IMAGE from "../../images/clock_blue.svg";
import RATING_IMAGE from "../../images/rating.svg";
import LanguageSelector from "../../language/LanguageSelector";


const AdminResolvedRequestFeedback = ({
  openFeedbackMenu,
  toggleFeedbackMenu,
  feedbackResult,
  selectedRequestIdForFilter,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));


  const [data, setData] = useState(null);
  const [noFeedback, setNoFeedback] = useState(false);
  console.log(selectedRequestIdForFilter.request_id)
  const feedbackResultSuccessCallback = (response) => {
    setData(response);
  };

  const feedbackResultFailureCallback = (msg) => {
    setData({recommend: "-", ease_of_use: "-", recommend:"-", time_to_deliver:"-", detailing:"-"})
    setNoFeedback(true)
  };

  useEffect(() => {
    feedbackResult({
      request_id: selectedRequestIdForFilter.request_id,
      feedbackResultSuccessCallback,
      feedbackResultFailureCallback,
    });
  }, []);

  if (width < 1100) {
    return (
      <AdminResolvedModalBarDiv openSideMenu={openFeedbackMenu}>
        <FeedbackTitleRow>
          <FeedbackImage src={SMILEY_IMAGE} alt="Smiley" />
          <FeedbackText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FEEDBACK"}
            /></FeedbackText>
        </FeedbackTitleRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={REQUEST_ID_IMAGE} alt="Request Id" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.request_id}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={ASSIGNED_TO_IMAGE} alt="Assigned to" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASSIGNED_TO"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.assigned}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={CUSTOMER_STAR_IMAGE} alt="Customer star" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOMER"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.email}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          {noFeedback && (
            <FeedbackRowSubTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"CUSTOMER_HAS_NOT_FILLED_FEEDBACK"}
          /></FeedbackRowSubTitle>
          )}
        </FeedbackRow>
        
        <FeedbackDataColumn style={{ margin: "0 20px", width: "27%" }}>
          <img src={RATING_IMAGE} alt="Rating" />
          <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"RATING"}
          /></FeedbackDataTitle>
          {data !== null && (
            <FeedbackDataSubTitle>{data.recommend}/5</FeedbackDataSubTitle>
          )}
        </FeedbackDataColumn>

        <FeedbackDataRow>
          <FeedbackDataColumn>
            <img src={CLOCK_BLUE_IMAGE} alt="Clock" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"TIME"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>
                {data.time_to_deliver}
              </FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>

          <FeedbackDataColumn>
            <img src={GALLERY_IMAGE} alt="Gallery" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"DETAILS"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>{data.detailing}/10</FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>

          <FeedbackDataColumn>
            <img src={EASE_OF_USE_IMAGE} alt="Ease of use" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"EASE_OF_USE"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>{data.ease_of_use}/10</FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>
        </FeedbackDataRow>
        <FeedbackButtonBar>
          {/* <ViewResultButton>View Result</ViewResultButton> */}
          <DoneButton onClick={toggleFeedbackMenu}><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"DONE"}
          /></DoneButton>
        </FeedbackButtonBar>
      </AdminResolvedModalBarDiv>
    );
  } else {
    return (
      <AdminResolvedFeedbackSideBar openSideMenu={openFeedbackMenu}>
        <FeedbackTitleRow>
          <FeedbackImage src={SMILEY_IMAGE} alt="Smiley" />
          <FeedbackText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FEEDBACK"}
            /></FeedbackText>
        </FeedbackTitleRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={REQUEST_ID_IMAGE} alt="Request Id" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.request_id}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={ASSIGNED_TO_IMAGE} alt="Assigned to" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASSIGNED_TO"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.assigned}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          <FeedbackCircularDiv>
            <FeedbackRowImage src={CUSTOMER_STAR_IMAGE} alt="Customer star" />
          </FeedbackCircularDiv>
          <FeedbackColumn>
            <FeedbackRowTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOMER"}
            /></FeedbackRowTitle>
              <FeedbackRowSubTitle>{selectedRequestIdForFilter.email}</FeedbackRowSubTitle>
          </FeedbackColumn>
        </FeedbackRow>

        <FeedbackRow>
          {noFeedback && (
            <FeedbackRowSubTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"CUSTOMER_HAS_NOT_FILLED_FEEDBACK"}
          /></FeedbackRowSubTitle>
          )}
        </FeedbackRow>

        <FeedbackDataColumn style={{ margin: "0 20px", width: "27%" }}>
          <img src={RATING_IMAGE} alt="Rating" />
          <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"RATING"}
          /></FeedbackDataTitle>
          {data !== null && (
            <FeedbackDataSubTitle>{data.recommend}/5</FeedbackDataSubTitle>
          )}
        </FeedbackDataColumn>

        <FeedbackDataRow>
          <FeedbackDataColumn>
            <img src={CLOCK_BLUE_IMAGE} alt="Clock" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"TIME"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>
                {data.time_to_deliver}
              </FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>

          <FeedbackDataColumn>
            <img src={GALLERY_IMAGE} alt="Gallery" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"DETAILS"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>{data.detailing}/10</FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>

          <FeedbackDataColumn>
            <img src={EASE_OF_USE_IMAGE} alt="Ease of use" />
            <FeedbackDataTitle><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"EASE_OF_USE"}
          /></FeedbackDataTitle>
            {data !== null && (
              <FeedbackDataSubTitle>{data.ease_of_use}/10</FeedbackDataSubTitle>
            )}
          </FeedbackDataColumn>
        </FeedbackDataRow>
        <FeedbackButtonBar>
          {/* <ViewResultButton>View Result</ViewResultButton> */}
          <DoneButton onClick={toggleFeedbackMenu}><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"DONE"}
          /></DoneButton>
        </FeedbackButtonBar>
      </AdminResolvedFeedbackSideBar>
    );
  }
};

export default AdminResolvedRequestFeedback;
