import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import AdminDashboardStats from "./AdminDashboardStats";
import { AdminDashboardStatsRow } from "../../styles/AdminDashboardStyles";
import MY_TEAM_IMAGE from "../../images/my_team.svg";
import PENDING_REQUEST_IMAGE from "../../images/pending_request.svg";
import STAR_IMAGE from "../../images/star.svg";
import TOTAL_REQUEST_IMAGE from "../../images/total_request.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardServiceHighlightStats = ({ serviceHighlights }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  
  return (
    <AdminDashboardStatsRow>
      <AdminDashboardStats
        image={TOTAL_REQUEST_IMAGE}
        number={`${serviceHighlights?.total_request ?? "0"}`}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TOTAL_REQUESTS"}
        />}
      />
      <AdminDashboardStats
        image={PENDING_REQUEST_IMAGE}
        number={`${serviceHighlights?.pending_request ?? "0"}`}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PENDING_REQUESTS"}
        />}
      />
      <AdminDashboardStats
        image={STAR_IMAGE}
        number={`${serviceHighlights?.total_customer ?? "0"}`}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMERS"}
        />}
      />
      <AdminDashboardStats
        image={MY_TEAM_IMAGE}
        number={`${serviceHighlights?.global_teams ?? "0"}`}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_TEAM"}
        />}
      />
      <AdminDashboardStats
        image={TOTAL_REQUEST_IMAGE}
        number={`${serviceHighlights?.avg_turnaround_time ?? "0"}`}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TURN_AROUND_TIME"}
        />}
      />
    </AdminDashboardStatsRow>
  );
};

export default AdminDashboardServiceHighlightStats;
