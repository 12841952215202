/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  PendingRequestRow,
  PendingRequestTableData,
  PendingRequestTable,
  PendingRequestTableHeader,
  ArrowImage,
} from "../../styles/PreviewSupervisorDashboardStyles";
import dayjs from "dayjs";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardPendingRequests = ({
  list,
  toggleOpenReAssignRequestMenu,
  setSelectedItem,
  resolveRequestHandler,
  textFilterName,
  selectedFilterOption,
  setPaginationList,
  allRequests,
  selectedPageForPending,
  archiveHandler,
  toggleOpenRemarksMenu
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;

  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [requestList, setRequestList] = useState([]);

  const reassginHandler = (data) => {
    setSelectedItem(data);
    toggleOpenReAssignRequestMenu();
  };

  const remarksHandler = (data) => {
    setSelectedItem(data);
    toggleOpenRemarksMenu();
  };

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      setRequestList(
        list
          ?.filter(
            (value) =>
              value.assigned.includes(textFilterName) ||
              value.email.includes(textFilterName)
          )
          .slice(
            selectedPageForPending === 1
              ? 0
              : 15 * (selectedPageForPending - 1),
            15 * selectedPageForPending
          )
      );
    } else {
      if (selectedFilterOption === "Latest First") {
        setRequestList(
          list
            .reverse()
            .slice(
              selectedPageForPending === 1
                ? 0
                : 15 * (selectedPageForPending - 1),
              15 * selectedPageForPending
            )
        );
      } else {
        setRequestList(
          list.slice(
            selectedPageForPending === 1
              ? 0
              : 15 * (selectedPageForPending - 1),
            15 * selectedPageForPending
          )
        );
      }
    }
  }, [textFilterName, list, selectedFilterOption]);

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      const filteredList = list?.filter(
        (value) =>
          value.assigned.includes(textFilterName) ||
          value.email.includes(textFilterName)
      );
      updatePagination(filteredList);
    } else {
      updatePagination(allRequests);
    }
  }, [textFilterName]);

  return (
    <PendingRequestTable>
      <tbody>
        <PendingRequestRow>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DATE"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASSIGNED_TO"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTER_EMAIL"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTER_TYPE"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOLOUR_TYPE"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"STATUS"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REASSIGN"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_REQUEST"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ARCHIVE_REQUEST"}
            /></PendingRequestTableHeader>
          <PendingRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REMARKS_DETAILS"}
            /></PendingRequestTableHeader>
        </PendingRequestRow>
        {requestList.map((item, index) => {
          // let isOverdue =
          //   dayjs().diff(dayjs(item.timestamp), "hour", true) > 24;
          //console.log(item, item.remarks, typeof(item.remarks))
          return (
            <PendingRequestRow
              key={`${index}`}
              isEven={index % 2 === 0 ? true : false}
            >
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
                {item.request_id}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
                {dayjs(item.timestamp).format("DD/MM/YYYY")}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
                {item.assigned}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
                {item.email}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
              {item.customer_type === null ? "None" : item.customer_type === "Wall Owner" ? <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"WALL_OWNER"}
              /> :item.customer_type}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
              {item.colourization_type === "Lead Colour"? <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            /> : 
                item.colourization_type === "Reference"? <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"REFERENCE"}
              /> : <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            />}
              </PendingRequestTableData>
              <PendingRequestTableData isOverDue={item.overdue} noRemarks={true}>
              {item.overdue ? <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OVERDUE"}
            /> : <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"UNDER_24_HOURS"}
          />}
              </PendingRequestTableData>
              <PendingRequestTableData onClick={() => reassginHandler(item)} noRemarks={true}>
                <ArrowImage src={BLACK_ARROW_IMAGE} alt="Reassign" />
              </PendingRequestTableData>
              <PendingRequestTableData
                onClick={() => resolveRequestHandler(item)} noRemarks={true}
              >
                <ArrowImage src={GREEN_ARROW_IMAGE} alt="Resolve request" />
              </PendingRequestTableData>
              <PendingRequestTableData
                onClick={() => archiveHandler(item.request_id)} noRemarks={true}
              >
                <ArrowImage src={BLACK_ARROW_IMAGE} alt="Archive" />
              </PendingRequestTableData>
              <PendingRequestTableData noRemarks={item.remarks}>
                {<ArrowImage src={BLACK_ARROW_IMAGE} alt="Remarks" onClick={() => remarksHandler(item)}/>}
              </PendingRequestTableData>
            </PendingRequestRow>
          );
        })}
      </tbody>
    </PendingRequestTable>
  );
};

export default PreviewSupervisorDashboardPendingRequests;
