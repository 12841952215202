import React from "react";
import {
  CustomizeLivingSpaceSubTitleText,
  CustomizeLivingSpaceText,
  GetStartedButton,
} from "../../styles/HomeStyles";
import LIVING_SPACES_IMAGE from "../../images/living_spaces.png";
import LanguageSelector from "../../language/LanguageSelector"

const HomeLivingSpaces = ({ goToConsumerClickHandler, width, selectedCountry }) => {
  if (width < 1100) {
    return (
      <div
        style={{
          margin: "40px 20px 20px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={LIVING_SPACES_IMAGE}
          alt="Living Spaces"
          style={{ width: "100%" }}
        />
        <CustomizeLivingSpaceText>
          <LanguageSelector selectedCountry={selectedCountry} text={"CUSTOMISE_LIVING_SPACES"}/>
        </CustomizeLivingSpaceText>
        <CustomizeLivingSpaceSubTitleText>
          <LanguageSelector selectedCountry={selectedCountry} text={"GET_YOUR_HOUSE_AND_OFFICE_small"}/>
        </CustomizeLivingSpaceSubTitleText>
        <GetStartedButton onClick={goToConsumerClickHandler}>
          <LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>
        </GetStartedButton>
      </div>
    );
  } else {
    return (
      <div
        style={{
          backgroundImage: "url(" + LIVING_SPACES_IMAGE + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "85vh",
          display: "flex",
          paddingTop: "10vh",
        }}
      >
        <div style={{ margin: "0 auto" }}>
          <CustomizeLivingSpaceText>
            <LanguageSelector selectedCountry={selectedCountry} text={"CUSTOMISE_LIVING_SPACES"}/>
          </CustomizeLivingSpaceText>
          <CustomizeLivingSpaceSubTitleText>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_YOUR_HOUSE_AND_OFFICE"}/>
          </CustomizeLivingSpaceSubTitleText>
          <GetStartedButton onClick={goToConsumerClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>
          </GetStartedButton>
        </div>
      </div>
    );
  }
};

export default HomeLivingSpaces;
