import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ReferenceBodyColumn,
  PaletteListDiv,
  ModalPaleeteDiv,
  ColorText,
  OriginalReferenceImageText,
  OriginalRefernceImageDiv,
  OriginalBodyColumn,
  OriginalReferenceImage,
  PaletteBodyText,
  ToolTip,
  PaletteDiv,
} from "../../styles/PreviewSupervisorConsumerResultStyles";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerResultOriginal = ({ values }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <OriginalRefernceImageDiv>
      <OriginalBodyColumn>
        <OriginalReferenceImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ORIGINAL"}
            /></OriginalReferenceImageText>
        <OriginalReferenceImage src={values.originalImage} />
      </OriginalBodyColumn>

      <ReferenceBodyColumn>
        <OriginalReferenceImageText>
          {values.colourization_type === "image" ? (
            <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"REFERENCE_IMAGE"}
          />
          ) : (
            <>
              {values.colourization_type === "theme" ?  <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"THEME"}
          /> :  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"LEAD_COLOUR"}
        />}
            </>
          )}
        </OriginalReferenceImageText>
        {values.colourization_type === "image" ? (
          <OriginalReferenceImage src={values.referenceImage} />
        ) : (
          <>
            {values.colourization_type === "leadcolour" ? (
              <>
                <PaletteListDiv>
                  <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEUTRAL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>
                  <div key="netural_conbinations_1">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.lead_colour.red_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.green_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .lead_colour?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_2">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.neutral_colour1.red_value},
                          ${values.referenceImage.colour_suggestion_details.neutral_colour1.green_value},
                          ${values.referenceImage.colour_suggestion_details.neutral_colour1.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.neutral_colour1.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .neutral_colour1?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_3">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.neutral_colour2.red_value},
                          ${values.referenceImage.colour_suggestion_details.neutral_colour2.green_value},
                          ${values.referenceImage.colour_suggestion_details.neutral_colour2.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.neutral_colour2.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .neutral_colour2?.colour_code
                      }
                    </ColorText>
                  </div>
                </PaletteListDiv>
                <PaletteListDiv>
                  <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TONAL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>
                  <div key="netural_conbinations_1">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.lead_colour.red_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.green_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .lead_colour?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_2">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.tonal_colour1.red_value},
                          ${values.referenceImage.colour_suggestion_details.tonal_colour1.green_value},
                          ${values.referenceImage.colour_suggestion_details.tonal_colour1.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.tonal_colour1.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .tonal_colour1?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_3">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.tonal_colour2.red_value},
                          ${values.referenceImage.colour_suggestion_details.tonal_colour2.green_value},
                          ${values.referenceImage.colour_suggestion_details.tonal_colour2.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.tonal_colour2.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .tonal_colour2?.colour_code
                      }
                    </ColorText>
                  </div>
                </PaletteListDiv>
                <PaletteListDiv>
                  <PaletteBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DESIGNERL_COMBINATIONS"}
            />{`: `}</PaletteBodyText>
                  <div key="netural_conbinations_1">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.lead_colour.red_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.green_value},
                          ${values.referenceImage.colour_suggestion_details.lead_colour.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.lead_colour.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .lead_colour?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_2">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.designer_colour1.red_value},
                          ${values.referenceImage.colour_suggestion_details.designer_colour1.green_value},
                          ${values.referenceImage.colour_suggestion_details.designer_colour1.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.designer_colour1.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .designer_colour1?.colour_code
                      }
                    </ColorText>
                  </div>
                  <div key="netural_conbinations_3">
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(
                          ${values.referenceImage.colour_suggestion_details.designer_colour2.red_value},
                          ${values.referenceImage.colour_suggestion_details.designer_colour2.green_value},
                          ${values.referenceImage.colour_suggestion_details.designer_colour2.blue_value}
                        )`,
                      }}
                    >
                      <ToolTip>{`${values.referenceImage.colour_suggestion_details.designer_colour2.colour_name}`}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>
                      {
                        values.referenceImage.colour_suggestion_details
                          .designer_colour2?.colour_code
                      }
                    </ColorText>
                  </div>
                </PaletteListDiv>
              </>
            ) : (
              <>
                <PaletteListDiv>
                  {console.log(values.referenceImage)}
                  {values.referenceImage?.palette_1_colours.map(
                    (paletteItem, index) => {
                      return (
                        <div key={`${index}`}>
                          <ModalPaleeteDiv
                            style={{
                              backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                            }}
                          >
                            <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                          </ModalPaleeteDiv>
                          <ColorText>{paletteItem?.colour_code}</ColorText>
                        </div>
                      );
                    }
                  )}
                </PaletteListDiv>
                <PaletteListDiv>
                  {values.referenceImage?.palette_2_colours.map(
                    (paletteItem, index) => {
                      return (
                        <div key={`${index}`}>
                          <ModalPaleeteDiv
                            style={{
                              backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                            }}
                          >
                            <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                          </ModalPaleeteDiv>
                          <ColorText>{paletteItem?.colour_code}</ColorText>
                        </div>
                      );
                    }
                  )}
                </PaletteListDiv>
                <PaletteListDiv>
                  {values.referenceImage?.palette_3_colours.map(
                    (paletteItem, index) => {
                      return (
                        <div key={`${index}`}>
                          <ModalPaleeteDiv
                            style={{
                              backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                            }}
                          >
                            <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                          </ModalPaleeteDiv>
                          <ColorText>{paletteItem?.colour_code}</ColorText>
                        </div>
                      );
                    }
                  )}
                </PaletteListDiv>
              </>
            )}
          </>
        )}
      </ReferenceBodyColumn>
    </OriginalRefernceImageDiv>
  );
};

export default ConsumerResultOriginal;
