/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminConsumResultNewOtpInput,
  ErrorMessage,
  ResendOtpButton,
  VerifyEmailButton,
  AdminConsumerOtpSideBarDiv,
  OneTimeOtpText,
  AdminConsumerOtpInputDiv,
  AdminConsumerOTPModalBarDiv,
} from "../../styles/AdminCustomersStyles";
import LanguageSelector from "../../language/LanguageSelector";

const AdminConsumerOtpScreen = ({
  email,
  showOtpSideBar,
  setShowOtpSideBar,
  verifyEmail,
  verifyAdminEmailOtp,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [otp, setOtp] = useState("");
  const [progress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [resendOtpSuccessMsg, setResendOtpSuccessMsg] = useState(null);
  const [canResendOtp, setCanResendOtp] = useState(true);
  const [countDown, setCountDown] = useState(60);

  const toggleProgress = () => setProgress((previousState) => !previousState);

  const verifyEmailSuccessCallback = () => {
    setProgress(false);
    setResendOtpSuccessMsg("Otp send successfully");
  };

  const verifyEmailFailureCallback = (data) => {
    setProgress(false);
    setErrorMsg(data);
    setResendOtpSuccessMsg("");
  };

  const verifyOtpSuccessCallback = () => {
    setShowOtpSideBar(false);
  };

  const verifyOtpFailureCallback = (data) => {
    setErrorMsg(data);
  };

  useEffect(() => {
    verifyEmail({
      email,
      verifyEmailSuccessCallback,
      verifyEmailFailureCallback,
    });
  }, []);

  useEffect(() => {
    if (!canResendOtp) {
      const interval = setInterval(() => {
        if (countDown > 0) {
          setCountDown(countDown - 1);
        } else {
          setCanResendOtp(true);
          setCountDown(60);
          return () => clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [canResendOtp, countDown]);

  const submitHandler = (e) => {
    e.preventDefault();
    verifyAdminEmailOtp({
      email,
      otp,
      verifyOtpSuccessCallback,
      verifyOtpFailureCallback,
    });
  };

  const resendOtpHandler = () => {
    if (!progress && canResendOtp) {
      toggleProgress();
      setCanResendOtp(false);
      verifyEmail({
        email,
        verifyEmailSuccessCallback,
        verifyEmailFailureCallback,
      });
    }
  };

  if (width < 1100) {
    return (
      <AdminConsumerOTPModalBarDiv openSideMenu={showOtpSideBar}>
        <OneTimeOtpText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"WE_HAVE_SENT_A_4_DIGIT"}
            />
          <br />
          {email}
        </OneTimeOtpText>
        <AdminConsumerOtpInputDiv>
          <AdminConsumResultNewOtpInput
            type="text"
            pattern="\d*"
            maxLength={4}
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </AdminConsumerOtpInputDiv>
        {errorMsg !== null && <ErrorMessage>{errorMsg}</ErrorMessage>}
        {resendOtpSuccessMsg != null && (
          <OneTimeOtpText style={{ color: "green" }}>
            {resendOtpSuccessMsg}
          </OneTimeOtpText>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ResendOtpButton onClick={resendOtpHandler}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESEND_OTP"}
            />
          </ResendOtpButton>
          {countDown !== 60 && (
            <ResendOtpButton><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"RESEND_OTP_IN"}
          /> {countDown}s</ResendOtpButton>
          )}
        </div>
        <VerifyEmailButton
          onClick={submitHandler}
          disabled={otp.length < 4}
          isDisabled={otp.length < 4}
        >
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"VERIFY_EMAIL"}
            />
        </VerifyEmailButton>
      </AdminConsumerOTPModalBarDiv>
    );
  } else {
    return (
      <AdminConsumerOtpSideBarDiv openSideMenu={showOtpSideBar}>
        <OneTimeOtpText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"WE_HAVE_SENT_A_4_DIGIT"}
            />
          <br />
          {email}
        </OneTimeOtpText>
        <AdminConsumerOtpInputDiv>
          <AdminConsumResultNewOtpInput
            type="text"
            pattern="\d*"
            maxLength={4}
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </AdminConsumerOtpInputDiv>
        {errorMsg !== null && <ErrorMessage>{errorMsg}</ErrorMessage>}
        {resendOtpSuccessMsg != null && (
          <OneTimeOtpText style={{ color: "green" }}>
            {resendOtpSuccessMsg}
          </OneTimeOtpText>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ResendOtpButton onClick={resendOtpHandler}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESEND_OTP"}
            />
          </ResendOtpButton>
          {countDown !== 60 && (
            <ResendOtpButton><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"RESEND_OTP_IN"}
          /> {countDown}s</ResendOtpButton>
          )}
        </div>
        <VerifyEmailButton
          onClick={submitHandler}
          disabled={otp.length < 4}
          isDisabled={otp.length < 4}
        >
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"VERIFY_EMAIL"}
            />
        </VerifyEmailButton>
      </AdminConsumerOtpSideBarDiv>
    );
  }
};

export default AdminConsumerOtpScreen;
