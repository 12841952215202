import React from "react";
import {
  HomeFooterEndDiv,
  HomeFooterImageDiv,
  HomeFooterLinksDiv,
  HomeFooterLinksText,
  HomeFooterEndText,
} from "../../styles/HomeStyles";
import AKZONOBEL_IMAGE from "../../images/akzonobel.png";
import {
  COOKIE_ROUTE,
  LEGAL_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const HomeFooter = ({ selectedCountry, history }) => {
  const footerList = ["Cookies", "Privacy Policy", "Legal"];

  const clickHandler = (item) => {
    if (item === "Cookies") {
      history.push(COOKIE_ROUTE, { selectedCountry });
    } else if (item === "Privacy Policy") {
      history.push(PRIVACY_POLICY_ROUTE, { selectedCountry });
    } else if (item === "Legal") {
      history.push(LEGAL_ROUTE, { selectedCountry });
    }
  };

  console.log(selectedCountry.value);

  return (
    <footer>
      <HomeFooterImageDiv>
        {/* <img
          src={DULUX_IMAGE}
          alt="Dulux"
          style={{ height: "62px", width: "119px" }}
        />
        <img
          src={SADOLIN_IMAGE}PrivacyPolicySingapore
          alt="Sadolin"
          style={{ height: "45px", width: "45px" }}
        /> */}
      </HomeFooterImageDiv>
      <HomeFooterLinksDiv>
        {footerList.map((item) => {
          return (
            <HomeFooterLinksText onClick={() => clickHandler(item)} key={item}>
              {item === "Cookies"
                ? language["COOKIES"][selectedCountry.value.toLowerCase()]
                : item === "Legal"
                ? language["LEGAL"][selectedCountry.value.toLowerCase()]
                : item === "Privacy Policy"
                ? language["PRIVACY_POLICY"][
                    selectedCountry.value.toLowerCase()
                  ]
                : "No translations"}
            </HomeFooterLinksText>
          );
        })}
      </HomeFooterLinksDiv>
      <HomeFooterEndDiv>
        <HomeFooterEndText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"COPYRIGHT2020"}
          />
        </HomeFooterEndText>
        <img src={AKZONOBEL_IMAGE} alt="AkzoNobel" />
      </HomeFooterEndDiv>
    </footer>
  );
};

export default HomeFooter;
