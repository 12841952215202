import React, { useContext, useState } from "react";
import ConsumerImageOptionHeader from "../../components/consumer-image-options/ConsumerImageOptionHeader";
import ConsumerSituationContainer from "../../components/consumer-image-options/ConsumerSituationContainer";
import { ColourizationContext } from "../../context/ColourizationContext";
import {
  AppropriateTextStyle,
  SituationDiv,
  ConsumerHomeContainer,
} from "../../styles/ConsumerImageOptionStyles";
import {
  CONSUMER_CHOOSE_COLOUR_ROUTE,
  CONSUMER_DULUX_THEME_ROUTE,
  CONSUMER_REFERENCE_IMAGE_ROUTE,
  HOME_PAGE_ROUTE,
  LEAD_COLOUR,
  REFERENCE,
  THEME,
  TOKEN_NAME,
} from "../../utils/Constants";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
// import PAINT_BRUSH_IMAGE from "../../images/paint-brush.svg";
// import PICTURE_IMAGE from "../../images/picture.svg";
// import PAINT_IMAGE from "../../images/paint.svg";
import PAINT_IMAGE from "../../images/icon_dulux_select.png";
import PICTURE_IMAGE from "../../images/icon_gallery.jpg";
import PAINT_BRUSH_IMAGE from "../../images/icon_palette_select.png";
import BackButton from "../../components/BackButton";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerImageOptions = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const { width } = useWindowDimensions();

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const colourizationContext = useContext(ColourizationContext);

  const setColourizationContext = (data) => {
    const value = colourizationContext.colourizationDetails;
    colourizationContext.setColourizationDetails({
      raw_image: value?.raw_image,
      colourization_type: data,
      reference: "",
      theme: "",
      lead_colour: "",
      email: "",
      name: "",
      city: "",
      country: "",
    });
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const consumerBrowseColorHandler = () => {
    const value = colourizationContext.colourizationDetails;
    if (value.raw_image !== undefined) {
      setColourizationContext(THEME);
      history.push(CONSUMER_DULUX_THEME_ROUTE);
    } else {
      alert("Upload an image to proceed");
    }
  };

  const consumerChooseColourHandler = () => {
    const value = colourizationContext.colourizationDetails;
    if (value.raw_image !== undefined) {
      setColourizationContext(LEAD_COLOUR);
      history.push(CONSUMER_CHOOSE_COLOUR_ROUTE);
    } else {
      alert("Upload an image to proceed");
    }
  };

  const consumerReferenceImageHandler = () => {
    const value = colourizationContext.colourizationDetails;
    if (value.raw_image !== undefined) {
      setColourizationContext(REFERENCE);
      history.push(CONSUMER_REFERENCE_IMAGE_ROUTE);
    } else {
      alert("Upload an image to proceed");
    }
  };

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue
        }
      />
      <ConsumerHomeContainer applyFilter={openLogoutMenu}>
        <ConsumerImageOptionHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          goBackHandler={goBackHandler}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        <AppropriateTextStyle>
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"CHOOSE_THE_MOST_APPROPRIATE"}
          />
        </AppropriateTextStyle>
        <SituationDiv>
          {/* <ConsumerSituationContainer
          image={NO_CLUE_COLORS_IMAGE}
          text={
            <>
              I have no clue
              <br />
              about colours
            </>
          }
          isComingSoon={false}
        /> */}

          <ConsumerSituationContainer
            image={PICTURE_IMAGE}
            text={
              <>
                <LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"I_HAVE_AN_IMAGE_WITH_ME"}
                />
              </>
            }
            isComingSoon={false}
            clickHandler={consumerReferenceImageHandler}
          />
          <ConsumerSituationContainer
            image={PAINT_IMAGE}
            text={
              <>
                <LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"I_D_SIMPLY_LIKE_TO"}
                />
              </>
            }
            isComingSoon={false}
            clickHandler={consumerBrowseColorHandler}
          />
          <ConsumerSituationContainer
            image={PAINT_BRUSH_IMAGE}
            text={
              <>
                <LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"I_HAVE_A_COLOUR_IN_MIND"}
                />
              </>
            }
            isComingSoon={false}
            clickHandler={consumerChooseColourHandler}
          />
        </SituationDiv>
      </ConsumerHomeContainer>
    </>
  );
};

export default ConsumerImageOptions;
