/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import PreviewStaffDashboardPagination from "../../components/preview-staff-dashboard/PreviewStaffDashboardPagination";
import PreviewStaffDashboardPendingRequests from "../../components/preview-staff-dashboard/PreviewStaffDashboardPendingRequests";
import PreviewStaffDashboardPendingResolvedContainer from "../../components/preview-staff-dashboard/PreviewStaffDashboardPendingResolvedContainer";
import PreviewStaffDashboardResolvedRequests from "../../components/preview-staff-dashboard/PreviewStaffDashboardResolvedRequests";
import {
  PreviewStaffDashboardContainer,
  PreviewStaffDashboardHeader,
  HeaderHorizontalRuler,
  DeluxePreviewServiceImage,
  LogoutButton,
  WelcomeText,
  WelcomeNameText,
  ErrorMessage,
  SearchBarDiv,
} from "../../styles/PreviewStaffDashboardStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  HOME_PAGE_ROUTE,
  PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE,
  PENDING_REQUEST_THEME_DETAIL_ROUTE,
  PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE,
  PREVIEW_STAFF_RESULT_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import dayjs from "dayjs";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import { Link } from "react-router-dom";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PreviewStaffRequestSearchBody from "../../components/preview-staff-dashboard/PreviewStaffRequestSearchBody";
import PreviewStaffSearchRequests from "../../components/preview-staff-dashboard/PreviewStaffSearchRequests";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewStaffDashboard = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  var date = new Date();
  const dropDownList = ["Latest First", "Oldest First"];

  const [tabSelected, setTabSelected] = useState(0);
  const [pendingClicked, setPendingClicked] = useState(true);
  const [requestList, setRquestList] = useState([]);
  const [tempRequestList, setTempRequestList] = useState([]);
  const [selectedPageForPending, setSelectedPageForPending] = useState(1);
  const [selectedPageForResolved, setSelectedPageForResolved] = useState(1);
  const [paginationList, setPaginationList] = useState({
    selectedPageForPending: selectedPageForPending,
    selectedPageForResolved: selectedPageForResolved,
    tabSelected: tabSelected,
  });
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [textFilterName, setTextFilterName] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    dropDownList[0]
  );
  const [searchRequest, setSearchRequest] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(date.setDate(date.getDate() + 1));

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const togglePendingClicked = () =>
    setPendingClicked((previousState) => !previousState);
  const toggleSearchRequest = () =>
    setSearchRequest((previousState) => !previousState);
  const toggleFilterDropDown = () =>
    setFilterDropDown((previousState) => !previousState);
  const togglePending = () => setTabSelected(0);
  const toggleResolved = () => setTabSelected(1);

  const previewSupervisorDetailsContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const { previewStaffRequestList } = useContext(PreviewSupervisorContext);

  const requestListFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const { width } = useWindowDimensions();
  const initialFilter = () => {
    console.log("inside initial filter");
    let newList = [],
      tempNewList = [],
      filteredList = requestList;
    console.log(requestList);

    if (fromDate !== "" && toDate !== dayjs().format("DD/MM/YYYY")) {
      tempNewList = [];
      const unixFromDate = dayjs(fromDate).unix();
      const unixToDate = dayjs(toDate).unix();
      for (let i = 0; i < requestList.length; i++) {
        const serverUnixDate = dayjs(requestList[i].timestamp).unix();
        if (serverUnixDate <= unixToDate && serverUnixDate >= unixFromDate) {
          tempNewList.push(filteredList[i]);
        }
      }
      console.log("date");
      filteredList = tempNewList;
      console.log(filteredList);
    }

    newList = filteredList;

    setTempRequestList(newList);
    updatePagination(
      tabSelected === 0
        ? newList.filter((data) => data.status === "Pending")
        : tabSelected === 1
        ? newList.filter((data) => data.status === "Resolved")
        : null
    );
  };

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    initialFilter();
    console.log("initial filter");
    console.log(tempRequestList);
  }, [fromDate, toDate, requestList]);

  // const updatePagination = (tempRequests) => {
  //   setPaginationList([]);
  //   if (pendingClicked) {
  //     const division = Math.ceil(
  //       tempRequests.filter((value) => value.status === "Pending").length / 15
  //     );
  //     const upTo = division === 0 ? 1 : division;
  //     for (let i = 0; i < upTo; i++) {
  //       setPaginationList((oldArray) => [...oldArray, i]);
  //     }
  //   } else {
  //     const division = Math.ceil(
  //       tempRequests.filter((value) => value.status === "Resolved").length / 15
  //     );
  //     const upTo = division === 0 ? 1 : division;
  //     for (let i = 0; i < upTo; i++) {
  //       setPaginationList((oldArray) => [...oldArray, i]);
  //     }
  //   }
  // };

  const requestListSuccessCallback = (data) => {
    setRquestList(data.requests);
    setTempRequestList(data.requests);
    // updatePagination(data.requests);
  };

  // useEffect(() => {
  //   if (requestList.length > 0) {
  //     updatePagination(requestList);
  //   }
  // }, [pendingClicked]);

  const getRequestsList = () => {
    previewStaffRequestList({
      country:
        previewSupervisorDetailsContext.previewSupervisorDetails?.country ||
        decodedToken?.country,
      email:
        previewSupervisorDetailsContext.previewSupervisorDetails?.email ||
        decodedToken?.email,
      requestListFailureCallback,
      requestListSuccessCallback,
    });
  };

  useEffect(() => {
    getRequestsList();
  }, []);

  const setPreviewDashboardDetails = () => {
    const values = previewSupervisorDetailsContext.previewSupervisorDetails;

    previewSupervisorDetailsContext.setPreviewSupervisorDetails({
      email: values.email || decodedToken?.email,
      country: values.country || decodedToken?.country,
      isPreviewStaff: true,
      isValid: true,
    });
  };

  const previewSupervisorResultHandler = (item) => {
    setPreviewDashboardDetails();
    history.push(PREVIEW_STAFF_RESULT_ROUTE, {
      item: item,
    });
  };

  const resolveRequestHandler = (item) => {
    setPreviewDashboardDetails();
    const data = {
      selectedPageForPending: selectedPageForPending,
      selectedPageForResolved: selectedPageForResolved,
      tabSelected: tabSelected,
      requestList: requestList,
    };
    if (item.colourization_type === "Reference") {
      history.push(PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE, { item, data });
    } else if (item.colourization_type === "Theme") {
      history.push(PENDING_REQUEST_THEME_DETAIL_ROUTE, {
        item, data
      });
    } else {
      history.push(PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE, {
        item, data
      });
    }
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailsContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  return (
    <>
      {searchRequest && (
        <PreviewStaffSearchRequests
          openSideMenu={searchRequest}
          toggleSearchRequest={toggleSearchRequest}
          setTempRequestList={setTempRequestList}
          setSelectedPageForPending={setSelectedPageForPending}
          setSelectedPageForResolved={setSelectedPageForResolved}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          setPaginationList={setPaginationList}
          tabSelected={tabSelected}
          pendingClicked={pendingClicked}
          requestList={requestList}
          width={width}
        />
      )}
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <PreviewStaffDashboardContainer
        applyFilter={openLogoutMenu || searchRequest}
      >
        <PreviewStaffDashboardHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage src={DELUXE_PREVIEW_SERVICE_IMAGE} />
          </Link>
          <WelcomeText>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HI"}
            /> <WelcomeNameText>{decodedToken.name}</WelcomeNameText>, <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"WELCOME_DULUX"}
          />
          </WelcomeText>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"LOGOUT"}
          /></LogoutButton>
        </PreviewStaffDashboardHeader>
        <HeaderHorizontalRuler />
        {errorMessage !== null && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <SearchBarDiv>
          <PreviewStaffDashboardPendingResolvedContainer
            togglePendingClicked={togglePendingClicked}
            pendingClicked={pendingClicked}
            pendingListLength={
              tempRequestList.filter((data) => data.status === "Pending").length
            }
            resolvedListLength={
              tempRequestList.filter((data) => data.status === "Resolved")
                .length
            }
          />
          <PreviewStaffRequestSearchBody
            textFilterName={textFilterName}
            setTextFilterName={setTextFilterName}
            toggleSearchRequest={toggleSearchRequest}
            toggleFilterDropDown={toggleFilterDropDown}
            selectedFilterOption={selectedFilterOption}
            filterDropDown={filterDropDown}
            dropDownList={dropDownList}
            setSelectedFilterOption={setSelectedFilterOption}
          />
        </SearchBarDiv>

        {tempRequestList.length > 0 && pendingClicked ? (
          <PreviewStaffDashboardPendingRequests
            list={tempRequestList.filter((data) => data.status === "Pending")}
            selectedPageForPending={selectedPageForPending}
            resolveRequestHandler={resolveRequestHandler}
            textFilterName={textFilterName}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Pending"
            )}
            selectedFilterOption={selectedFilterOption}
          />
        ) : (
          <PreviewStaffDashboardResolvedRequests
            list={tempRequestList.filter((data) => data.status === "Resolved")}
            selectedPageForResolved={selectedPageForResolved}
            previewSupervisorResultHandler={previewSupervisorResultHandler}
            textFilterName={textFilterName}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Resolved"
            )}
            selectedFilterOption={selectedFilterOption}
          />
        )}
        {tempRequestList.length > 0 && (
          <PreviewStaffDashboardPagination
            paginationList={paginationList}
            selectedPageForResolved={selectedPageForResolved}
            setSelectedPageForResolved={setSelectedPageForResolved}
            selectedPageForPending={selectedPageForPending}
            setSelectedPageForPending={setSelectedPageForPending}
            pendingClicked={pendingClicked}
          />
        )}
      </PreviewStaffDashboardContainer>
    </>
  );
};

export default PreviewStaffDashboard;
