import React from "react";
import {
  ModalPaleeteDiv,
  RightArrowImage,
  ModalPalette,
  ColorText,
  LeftArrowImage,
  ModalImage,
  ModalImageDiv,
  ModalImageDownloadButton,
  CloseModalImage,
  ResultBodyModal,
  ResultModal,
  ToolTip,
} from "../../styles/PreviewSupervisorResultStyles";
import { ResultBottomModalDiv } from "../../styles/PreviewSupervisorConsumerResultStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";

const ConsumerResultModal = ({
  toggleModal,
  previousModalImageClickHandler,
  values,
  selectedModalIndex,
  downloadSingleImageHandler,
  nextModalImageClickHandler,
  width,
}) => {
  if (width < 1100) {
    return (
      <ResultBottomModalDiv>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "60px 0 0 0 ",
            }}
          >
            <LeftArrowImage
              src={GREEN_ARROW_IMAGE}
              onClick={previousModalImageClickHandler}
            />
            <ModalImage
              src={values?.selectedImage[selectedModalIndex].result_image}
            />
            <RightArrowImage
              src={GREEN_ARROW_IMAGE}
              onClick={nextModalImageClickHandler}
            />
          </div>

          <ModalImageDiv>
            <ModalPalette>
              {values?.selectedImage[selectedModalIndex].colours.map(
                (item2, index2) => {
                  return (
                    <div key={`${index2}`}>
                      <ModalPaleeteDiv
                        style={{
                          backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                        }}
                      >
                        <ToolTip>{item2.colour_name}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{item2?.colour_code}</ColorText>
                    </div>
                  );
                }
              )}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  values?.selectedImage[selectedModalIndex].result_image
                );
              }}
            >
              Download Image
            </ModalImageDownloadButton>
          </ModalImageDiv>
        </ResultBodyModal>
      </ResultBottomModalDiv>
    );
  } else {
    return (
      <ResultModal>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
          <LeftArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={previousModalImageClickHandler}
          />
          <ModalImageDiv>
            <ModalImage
              src={values?.selectedImage[selectedModalIndex].result_image}
            />
            <ModalPalette>
              {values?.selectedImage[selectedModalIndex].colours.map(
                (item2, index2) => {
                  return (
                    <div key={`${index2}`}>
                      <ModalPaleeteDiv
                        style={{
                          backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                        }}
                      >
                        <ToolTip>{item2.colour_name}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{item2?.colour_code}</ColorText>
                    </div>
                  );
                }
              )}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  values?.selectedImage[selectedModalIndex].result_image
                );
              }}
            >
              Download Image
            </ModalImageDownloadButton>
          </ModalImageDiv>
          <RightArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={nextModalImageClickHandler}
          />
        </ResultBodyModal>
      </ResultModal>
    );
  }
};

export default ConsumerResultModal;
