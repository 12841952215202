import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AddUserSideBar,
  AddUserCancelImage,
  AddUserDiv,
  AdminUserAddUseImage,
  AdminUserAddtext,
  SendInviteButton,
  RoleText,
  PreviewStaffDiv,
  PreviewSupervisorDiv,
  SubTitleText,
  PreviewStaffSupervisorOuterCheckBox,
  PreviewStaffSupervisorInnerCheckBox,
  PreviewStaffSupervisorText,
  ErrorMessage,
  AddUserModalBarDiv,
} from "../../styles/AdminUserStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import "../../styles/personDetailsInput.css";
import { Formik } from "formik";
import { addUserValidationSchema } from "../../validations/Validations";
import Select from "react-select";
import LanguageSelector from "../../language/LanguageSelector";

const AdminUsersAddUserSideBar = ({
  toggleAddUser,
  adminAddUser,
  adminUsersSuccessCallback,
  adminUsers,
  adminUserFailureCallback,
  errorMessage,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const options = [
    { value: "India", label: "India" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Srilanka", label: "Srilanka" },
    { value: "Singapore", label: "Singapore" },
    { value: "Thailand", label: "Thailand" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Vietnam", label: "Vietnam" },
  ];

  const [selectedCountry, setSelectedCountry] = useState({
    value: "Singapore",
    label: "Singapore",
  });
  const [isPreviewStaffSelected, setIsPreviewStaffSelected] = useState(false);
  const [addUserErrorMsg, setAddUserErrorMsg] = useState(null);

  const selectedCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const adminAddUserSuccessCallback = () => {
    setAddUserErrorMsg(null);
    adminUsers({ adminUsersSuccessCallback, adminUserFailureCallback });
    toggleAddUser();
  };

  const adminAddUserFailureCallback = (msg) => {
    setAddUserErrorMsg(msg);
  };

  if (width < 1100) {
    return (
      <AddUserModalBarDiv>
        <AddUserCancelImage src={CANCEL_IMAGE} onClick={toggleAddUser} />
        <AddUserDiv>
          <AdminUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <AdminUserAddtext><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ADD_MEMBER"}
        /></AdminUserAddtext>
        </AddUserDiv>
        <Formik
          initialValues={{ email: "", name: "", city: "" }}
          onSubmit={(values, actions) => {
            adminAddUser({
              email: values.email,
              name: values.name,
              city: values.city,
              country: selectedCountry.value,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              adminAddUserSuccessCallback,
              adminAddUserFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={"Select Country"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={selectedCountry}
                    onChange={selectedCountryChange}
                    options={options}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>
                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"EMAIL"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"NAME_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.city}
                    onChange={formikProps.handleChange("city")}
                    onBlur={formikProps.handleBlur("city")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CITY_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <RoleText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ROLE"}
        /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIIEW_STAFF_ROLE"}
        />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR_ROLE"}
        />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                {addUserErrorMsg != null && (
                  <ErrorMessage>{addUserErrorMsg}</ErrorMessage>
                )}
                <SendInviteButton
                  type="submit"
                  onClick={formikProps.handleSubmit}
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isDisabled={!(formikProps.isValid && formikProps.dirty)}
                >
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SUBMIT_BUTTON"}
        />
                </SendInviteButton>
              </>
            );
          }}
        </Formik>
      </AddUserModalBarDiv>
    );
  } else {
    return (
      <AddUserSideBar>
        <AddUserCancelImage src={CANCEL_IMAGE} onClick={toggleAddUser} />
        <AddUserDiv>
          <AdminUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <AdminUserAddtext><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ADD_MEMBER"}
        /></AdminUserAddtext>
        </AddUserDiv>
        <Formik
          initialValues={{ email: "", name: "", city: "" }}
          onSubmit={(values, actions) => {
            adminAddUser({
              email: values.email,
              name: values.name,
              city: values.city,
              country: selectedCountry.value,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              adminAddUserSuccessCallback,
              adminAddUserFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={"Select Country"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={selectedCountry}
                    onChange={selectedCountryChange}
                    options={options}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>
                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"EMAIL"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"NAME_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.city}
                    onChange={formikProps.handleChange("city")}
                    onBlur={formikProps.handleBlur("city")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CITY_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>

                <RoleText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ROLE"}
        /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF_ROLE"}
        />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR_ROLE"}
        />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                {addUserErrorMsg != null && (
                  <ErrorMessage>{addUserErrorMsg}</ErrorMessage>
                )}
                <SendInviteButton
                  type="submit"
                  onClick={formikProps.handleSubmit}
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isDisabled={!(formikProps.isValid && formikProps.dirty)}
                >
                   <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SUBMIT_BUTTON"}
        />
                </SendInviteButton>
              </>
            );
          }}
        </Formik>
      </AddUserSideBar>
    );
  }
};

export default AdminUsersAddUserSideBar;
