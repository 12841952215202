import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  NearByStoresDiv,
  BottomText,
  NearByStoreDiv,
  NearByImage,
  NearByStoreText,
  NearByStoreSubTitle,
} from "../../styles/PreviewSupervisorResultStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import SHOP_IMAGE from "../../images/shop_image.png";
import LanguageSelector from "../../language/LanguageSelector";

const ResultNearByStores = ({ list }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <NearByStoresDiv>
      <BottomText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEARBY_STORES"}
            /></BottomText>
      {list &&
        list.length > 0 &&
        list.map((data, index) => {
          return (
            <NearByStoreDiv key={`${index}`}>
              {data ? (
                <>
                  <NearByImage src={SHOP_IMAGE} />
                  <div style={{ margin: "0px 10px" }}>
                    <NearByStoreText>{data.shop_name}</NearByStoreText>
                    <NearByStoreSubTitle>{data.address}</NearByStoreSubTitle>
                    <img src={GREEN_ARROW_IMAGE} alt="Arrow" />
                  </div>
                </>
              ) : null}
            </NearByStoreDiv>
          );
        })}
    </NearByStoresDiv>
  );
};

export default ResultNearByStores;
