import React, { useState } from "react";
import {
  InteriorDiv,
  ThemeImagesContainer,
  ThemeItemsContainer,
  ThemeText,
  ThemeImage,
  PaleeteeDiv,
} from "../../styles/ConsumerDuluxThemeStyles";
import "../../styles/checkBox2.css";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerDuluxThemeImageContainer = ({
  themeList,
  selectedImage,
  selectImageHandler,
  themePalette,
  selectedCountry,
}) => {
  const [list, setList] = useState(null);

  const themePaletteSuccessCallback = (data) => {
    setList(data);
  };

  const themePaletteFailureCallback = (msg) => {
    setList(null);
    alert(msg);
  };

  return (
    <InteriorDiv>
    <h3><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"RECOMMENDED_COLOR_THEMES"} /></h3>
    <ThemeItemsContainer>
      {themeList.map((data, index) => {
        return (
          <ThemeImagesContainer
            key={`${index}`}
            onClick={() => {
              themePalette({
                themeName: data.imageName,
                themePaletteSuccessCallback,
                themePaletteFailureCallback,
              });
              selectImageHandler(data.imageName);
            }}
          >
            <div style={{ position: "relative", display: "flex" }}>
              {selectedImage === data.imageName && (
                <label className="container">
                  {""}
                  <input
                    type="checkbox"
                    checked="checked"
                    onChange={(e) => true}
                  />
                  <span className="checkmark"></span>
                </label>
              )}
              <ThemeImage
                selectedImage={selectedImage === data.imageName ? true : false}
                src={data.imageUrl}
                alt="Theme"
              />
            </div>
            <ThemeText>{data.imageName}</ThemeText>
            {selectedImage === data.imageName && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {list != null &&
                  list.temp_palette[0].map((item, index) => {
                    return (
                      <PaleeteeDiv
                        key={`${index}`}
                        style={{
                          backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                        }}
                      />
                    );
                  })}
              </div>
            )}
            {selectedImage === data.imageName && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {list != null &&
                  list.temp_palette[1].map((item, index) => {
                    return (
                      <PaleeteeDiv
                        key={`${index}`}
                        style={{
                          backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                        }}
                      />
                    );
                  })}
              </div>
            )}
            {selectedImage === data.imageName && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {list != null &&
                  list.temp_palette[2].map((item, index) => {
                    return (
                      <PaleeteeDiv
                        key={`${index}`}
                        style={{
                          backgroundColor: `rgb(${item.red_value},${item.green_value},${item.blue_value})`,
                        }}
                      />
                    );
                  })}
              </div>
            )}
          </ThemeImagesContainer>
        );
      })}
    </ThemeItemsContainer>
    </InteriorDiv>
  );
};

export default ConsumerDuluxThemeImageContainer;
