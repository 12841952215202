import React from "react";
import {
  CookieContainer,
  CookieHeader,
  DeluxePreviewServiceImage,
  CookiePolicyHeaderText,
  CookieBody,
  CookiePolicyText,
  BodyText,
  BoldBodyText,
  TableData,
  TableHeader,
  Table,
  TableDataLink,
} from "../../styles/CookieStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";

const CookieBodyIndonesia = () => {
  return (
    <CookieContainer>
      <CookieBody>
        <CookiePolicyHeaderText>Pernyataan Cookie</CookiePolicyHeaderText>
        <CookiePolicyText>Pernyataan Cookie</CookiePolicyText>
        <CookiePolicyHeaderText>Pernyataan Cookie</CookiePolicyHeaderText>
        <BodyText>
          Ini adalah halaman informasi Cookie untuk situs web ini ("Situs web").
          Untuk mempelajari lebih lanjut tentang cara kami memproses data
          pribadi Anda saat Anda mengunjungi Situs web kami atau berkomunikasi
          dengan AkzoNobel, lihat Pernyataan Privasi.
        </BodyText>
        <BoldBodyText>Apa yang dimaksud dengan Cookie?</BoldBodyText>
        <BodyText>
          Untuk menawarkan layanan yang lebih baik saat mengunjungi situs web,
          sebagian besar situs menggunakan cookie; teknologi praktis yang
          mengumpulkan dan menggunakan informasi, membuat situs web lebih mudah
          digunakan. Cookie adalah potongan teks yang ditempatkan pada hard
          drive komputer saat Anda mengunjungi Situs web. Selama kunjungan Anda,
          cookie akan mengirimkan data ke AkzoNobel.
        </BodyText>

        <BoldBodyText>Apakah fungsinya?</BoldBodyText>
        <BodyText>
          Kami menggunakan cookie untuk menginformasikan kami, misalnya, apakah
          Anda telah mengunjungi kami sebelumnya atau jika Anda adalah
          pengunjung baru. Ini juga membantu kami meningkatkan pengalaman online
          Anda saat Anda mengunjungi Situs web kami.
        </BodyText>

        <BoldBodyText>Menyetujui penggunaan cookie di situs kami</BoldBodyText>
        <BodyText>
          Saat Anda mengunjungi Situs web kami, jendela akan ditampilkan di
          layar Anda tentang penempatan cookie pada hard drive komputer Anda.
          Pesan yang muncul secara singkat menjelaskan tentang kebijakan cookie
          kami. Dengan mengklik "Saya mengerti", berarti Anda menerima cookie
          yang ditentukan dalam Kebijakan Cookie. Anda juga dapat mengklik "Ubah
          Preferensi" guna mengatur preferensi Anda untuk cookie. Jika Anda
          tidak ingin kami menempatkan cookie, sebaiknya segera tutup situs web.
        </BodyText>

        <BoldBodyText>
          Jenis cookie yang kami gunakan dan fungsinya
        </BoldBodyText>
        <BodyText>
          AkzoNobel menggunakan berbagai jenis cookie. Kami percaya penting bagi
          Anda untuk mengetahui data yang dikumpulkan AkzoNobel dengan cookie
          dan bahwa Anda tahu cookie yang digunakan teknologi kami serta
          fungsinya. Lihat ikhtisar jenis cookie yang digunakan di bawah.
        </BodyText>

        <Table>
          <thead>
            <tr>
              <TableHeader>Jenis cookie</TableHeader>
              <TableHeader>Deskripsi</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableData>Cookie fungsional</TableData>
              <TableData>
                Cookie ini sangat diperlukan untuk menawarkan fungsionalitas
                dasar Situs web, untuk memberikan layanan yang Anda minta di
                Situs web dan untuk mengingat pengaturan cookie Anda.
              </TableData>
            </tr>

            <tr>
              <TableData>Cookie analisis</TableData>
              <TableData>
                Cookie analisis yang digunakan AkzoNobel disebut cookie Google
                Analytics. Google Analytics adalah layanan analisis web yang
                ditawarkan oleh Google Inc. (Google). Google menempatkan cookie
                dan menggunakan informasi statistik teragregasi dari cookie ini
                untuk memberi AkzoNobel pemahaman tentang bagaimana pengunjung
                situs web menggunakan Situs Web. Data yang dikumpulkan di Google
                Analytics akan digunakan untuk menganalisis cara pengunjung kami
                menggunakan Situs Web dan cara kami dapat meningkatkan Situs
                Web. Kami telah menandatangani perjanjian pemroses data untuk
                memastikan bahwa Google hanya diizinkan untuk memberikan
                informasi ini kepada pihak ketiga jika Google memiliki kewajiban
                hukum untuk melakukannya atau selama pihak ketiga ini memproses
                informasi atas nama Google. Google tidak akan mengaitkan alamat
                IP Anda dengan data lain yang dipegang oleh Google. Anda dapat
                menemukan informasi lebih lanjut tentang Google Analytics [di
                sini].
              </TableData>
            </tr>

            <tr>
              <TableData>Cookie pelacakan</TableData>
              <TableData>
                Cookie yang menyesuaikan Situs web dan komunikasi kami sesuai
                preferensi Anda. Saat menjelajahi situs web AkzoNobel, Anda
                setuju bahwa AkzoNobel menempatkan cookie yang tidak sepenuhnya
                diperlukan untuk menyediakan fungsionalitas Situs web. Cookie
                ini digunakan untuk mengingat preferensi lokasi Anda atau untuk
                membuat Anda tetap log in pada bagian Situs web yang aman
                setelah browser ditutup. Cookie ini juga akan melacak konten
                yang diklik dan preferensi yang Anda miliki. Ini memungkinkan
                AkzoNobel untuk mendekati Anda dengan cara yang lebih relevan.
              </TableData>
            </tr>
          </tbody>
        </Table>
        <BoldBodyText>
          ara lainnya untuk mengontrol, menonaktifkan, dan menghapus cookie
        </BoldBodyText>
        <BodyText>
          Anda dapat mengubah pengaturan untuk memblokir cookie atau untuk
          memberi tahu Anda bila cookie dikirim ke perangkat. Klik salah satu
          link di bawah untuk langsung membuka manual browser Anda. Ingat bahwa
          Anda harus mengatur preferensi Anda kembali atau login kembali di
          situs web tertentu setelah menghapus cookie.{" "}
        </BodyText>
        <ul>
          <li>
            <TableDataLink
              target="_blank"
              href="https://support.microsoft.com/en-us/windows?ui=en-US&rs=en-US&ad=US"
            >
              Internet Explorer
            </TableDataLink>
          </li>
          <li>
            <TableDataLink
              target="_blank"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
            >
              Mozilla Firefox
            </TableDataLink>
          </li>
          <li>
            <TableDataLink
              target="_blank"
              href="https://support.google.com/chrome/answer/95647?hl=en"
            >
              Google Chrome
            </TableDataLink>
          </li>
          <li>
            <TableDataLink
              target="_blank"
              href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
            >
              Apple Safari
            </TableDataLink>
          </li>
        </ul>
        <BodyText>
          Jika Anda sama sekali tidak ingin menerima cookie (termasuk cookie
          yang diperlukan untuk menyediakan Situs web kepada Anda), Anda dapat
          mengatur browser untuk menonaktifkan seluruh cookie. Jika Anda
          menonaktifkan cookie yang kami gunakan, ini dapat memengaruhi
          pengalaman pengguna Anda secara keseluruhan saat berada di Situs web.
          Anda mungkin tidak dapat mengunjungi area tertentu dari situs web
          AkzoNobel atau Anda mungkin tidak menerima informasi yang
          dipersonalisasi saat mengunjungi situs web AkzoNobel. Selain itu,
          penyimpanan pengaturan khusus seperti informasi login mungkin akan
          dihentikan. Jika Anda menggunakan perangkat yang berbeda untuk melihat
          dan mengakses Situs web (mis. Komputer, smartphone, tablet, dsb.),
          Anda harus memastikan bahwa setiap browser di setiap perangkat
          disesuaikan dengan benar agar sesuai dengan preferensi cookie Anda.
        </BodyText>
        <TableDataLink
          target="_blank"
          href="https://www.dulux.co.id/content/dam/akzonobel-flourish/dulux/id/id/pdfs/cat_warna_interior_dan_eksterior_-_ide_dekorasi_-_dulux_-_pernyataan_cookie_-_2019-06-26.pdf"
        >
          PDF Version
        </TableDataLink>
      </CookieBody>
    </CookieContainer>
  );
};

export default CookieBodyIndonesia;
