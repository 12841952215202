import React, { useState } from "react";
import {
  ConsumerLoginSideBarDiv,
  LoginCancelDrawerImage,
  LoginDrawerFooterDiv,
  WelcomeBackContainer,
  ConsumerLoginModalBarDiv,
} from "../../styles/ConsumerUploadImageStyles";
import ConsumerLoginEmail from "./ConsumerLoginEmail";
import ConsumerLoginOTP from "./ConsumerLoginOTP";
import CANCEL_IMAGE from "../../images/cancel.svg";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerLoginSideBar = ({
  openSideMenu,
  toggleSideMenu,
  state,
  verifyEmail,
  verifyEmailOTP,
  email,
  history,
  setEmail,
  colourizationContext,
  previewSupervisorDetailContext,
  width,
  selectedCountry,
}) => {
  const [showOtp, setShowOtp] = useState(false);

  const toggleShowOTP = () => setShowOtp((previousState) => !previousState);

  if (width < 1100) {
    return (
      <ConsumerLoginModalBarDiv openSideMenu={openSideMenu}>
        <LoginCancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toggleSideMenu}
          alt="Cancel"
        />
        <WelcomeBackContainer>
          {showOtp ? (
            <ConsumerLoginOTP
              toggleSideMenu={toggleSideMenu}
              state={state}
              verifyEmailOTP={verifyEmailOTP}
              email={email}
              history={history}
              colourizationContext={colourizationContext}
              previewSupervisorDetailContext={previewSupervisorDetailContext}
              verifyEmail={verifyEmail}
              selectedCountry={selectedCountry}
            />
          ) : (
            <ConsumerLoginEmail
              toggleShowOTP={toggleShowOTP}
              verifyEmail={verifyEmail}
              state={state}
              setEmail={setEmail}
              selectedCountry={selectedCountry}
            />
          )}
        </WelcomeBackContainer>
        <LoginDrawerFooterDiv />
      </ConsumerLoginModalBarDiv>
    );
  } else {
    return (
      <ConsumerLoginSideBarDiv openSideMenu={openSideMenu}>
        <LoginCancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toggleSideMenu}
          alt="Cancel"
        />
        <WelcomeBackContainer>
          {showOtp ? (
            <ConsumerLoginOTP
              toggleSideMenu={toggleSideMenu}
              state={state}
              verifyEmailOTP={verifyEmailOTP}
              email={email}
              history={history}
              colourizationContext={colourizationContext}
              previewSupervisorDetailContext={previewSupervisorDetailContext}
              verifyEmail={verifyEmail}
              selectedCountry={selectedCountry}
            />
          ) : (
            <ConsumerLoginEmail
              toggleShowOTP={toggleShowOTP}
              verifyEmail={verifyEmail}
              state={state}
              setEmail={setEmail}
              selectedCountry={selectedCountry}
            />
          )}
        </WelcomeBackContainer>
        <LoginDrawerFooterDiv />
      </ConsumerLoginSideBarDiv>
    );
  }
};

export default ConsumerLoginSideBar;
