import React from "react";
import { Link } from "react-router-dom";
import {
  CookieContainer,
  CookieHeader,
  DeluxePreviewServiceImage,
} from "../../styles/CookieStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";

const CookieHeader1 = () => {
  return (
    <CookieContainer>
      <CookieHeader>
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage
            src={DELUXE_PREVIEW_SERVICE_IMAGE}
            // alt="Deluxe Preview"
          />
        </Link>
      </CookieHeader>
    </CookieContainer>
  );
};

export default CookieHeader1;
