import React from "react";
import {
  LegacyContainer,
  LegacyBody,
  LegacyHeaderText,
  LegacyText,
  BodyText,
} from "../../styles/LegalStyles";

const LegalBodyEnglish = () => {
  return (
    <LegacyContainer>
      <LegacyBody>
        <LegacyHeaderText>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
          SITE
        </LegacyHeaderText>
        <LegacyText>General Terms and Conditions of Use</LegacyText>
        <BodyText>
          These General Terms and Conditions of Use (together with the Privacy
          Policy) (“Terms”) are the terms on which you may make use the website
          located at the URL [**] (“Website”). Use of the Website includes
          accessing, browsing, or registering to use the Website. Please read
          these Terms carefully before you start to use the Website. We
          recommend that you print a copy of this for future reference. By using
          the Website, you confirm that you accept these Terms and that you
          agree to comply with them. If you do not agree to these Terms, you
          must not use the Website. If you do not agree with any amendments made
          by us to these Terms at any time in future, you must stop using the
          Website with immediate effect.
        </BodyText>
        <LegacyHeaderText>Object</LegacyHeaderText>
        <BodyText>
          This Website is a technology based platform whereby users can send
          request us to provide various colour schemes or options for their
          painting site for free. This facility is provided on a pro-bono basis
          in the interest of the User and it is strongly advised to User to
          exercise discretion (and not solely reply on this facility) and form
          their own independent views on the colour schemes before taking the
          final decision. The Website is operated by AKZO NOBEL PAINTS
          (SINGAPORE) PTE LTD (the “AkzoNobel”) incorporated with registered
          office at 3 Changi Business Park Vista #05-01 AkzoNobel House.
          SINGAPORE 486051. Co registration no: 199506268M Potential Customers,
          Consumers, Dealers and the Painters are jointly referred to as
          “Users”. Any reference to “you” or “your” or refers to the User or a
          visitor to the Website (as the case may be); and any reference to
          “we”, “our” and “us” shall refer to AkzoNobel.
        </BodyText>
        <LegacyHeaderText>Changes to these terms and platform</LegacyHeaderText>
        <BodyText>
          We may revise these Terms at any time by amending this page. Please
          check this page from time to time to take notice of any changes we
          made, as they are binding on you. We may, without prior notice, change
          the nature of the Platform; add or remove functionalities or features;
          or stop providing the Platform or features of the Platform, to you or
          to users generally.
        </BodyText>
        <LegacyHeaderText>Changes to the Website</LegacyHeaderText>
        <BodyText>
          We may update the Website from time to time and may change the Content
          at any time. In these Terms, the term (“Content”) means any design,
          layout, text, images, graphics, sound, video etc. or other
          information, material or files including, without limitation, names or
          likenesses (all whether concerning you or a third party) or other
          materials uploaded by the Users (as a contributor), third party
          service providers or by us. However, please note that any of the
          Content on the Website may be out of date at any given time and we are
          under no obligation to update it. We do not guarantee that the
          Website, or any Content on it, will be free from errors or omissions.
        </BodyText>
        <LegacyHeaderText>Accessing the Website</LegacyHeaderText>
        <BodyText>
          We do not guarantee that your use of the Website, or any Content on
          it, will always be available or be uninterrupted. Access to the
          Website is permitted on a temporary basis. We may suspend, withdraw,
          discontinue or change all or any part of the Website without notice.
          We will not be liable to you if for any reason the Website is
          unavailable at any time or for any period.
        </BodyText>
        <LegacyHeaderText>User(s) Agreement</LegacyHeaderText>
        <BodyText>
          INTELLECTUAL PROPERTY RIGHTS Other than where expressly stated we or
          our group companies own all intellectual property rights in the Site
          and in the material published on it (including but not limited to
          text, images, audio, video, html-code and buttons) (the “Content”).
          These rights include but are not limited to all copyrights, database
          rights, trade names and trademarks. You may only view, print out and
          use the Site and the Preview outputs for your own personal,
          non-commercial use. We and our group companies expressly reserve all
          intellectual property rights in and to the Site and the Content and
          your use of the Site and the Content is subject to the following
          restrictions. You must not: remove any copyright or other proprietary
          notices contained in the Content; use any Content from the Site in any
          manner that may infringe any copyright, intellectual property right or
          proprietary right of us or any third parties; or reproduce, modify,
          display, perform, publish, distribute, disseminate, broadcast,
          communicate to the public or circulate to any third party or exploit
          this Site and/or the Content in any way including for any commercial
          purpose, without our prior written consent. We expressly reserve all
          rights in and to the www.duluxpreviewservice.com domain name and all
          related domains and sub-domains, the name "AkzoNobel”, our logo and
          our service marks, trading names and/or trade marks. Other trade
          marks, products and company names mentioned on our Site may be
          trademarks of their respective owners or licensors and the rights in
          such marks are reserved to their respective owners or licensors.
          COLOUR ACCURACY We have made every effort to make the colours on
          screen as accurate as possible. Unfortunately, we cannot guarantee an
          exact colour match of the on screen colour to the colours of the
          actual products and the colour appearing on screen should not be
          relied on as being such. Colours on screen may vary depending on your
          screen settings and resolution. If you are unsure of a colour, please
          refer to Dulux Colour Card at the dealer outlet for more accurate
          colours. ACCURACY OF INFORMATION AND CONTENT The Content and
          information provided on our Site is free of charge and for
          informational purposes only and does not create a business or
          professional services relationship between you and us. We have made
          reasonable efforts to ensure that all information and Content provided
          on this Site is accurate at the time of inclusion, however there may
          be inadvertent and occasional errors. The Site and the information and
          the Content on the Site are provided “as is”. We do not guarantee, and
          make no representation or warranties of any kind whether express or
          implied about the information, and Content provided through our Site,
          including any items used either directly or indirectly from our Site,
          and we reserve the right to make changes and corrections at any time,
          without notice. Commentary and information posted on the Site is not
          intended to amount to advice on which reliance should be placed. We
          accept no liability for any inaccuracies or omissions in the Site and
          any decisions based on information and opinions contained in the Site
          are the sole responsibility of the user. UPLOADING OR POSTING MATERIAL
          TO OUR SITE Whenever you make use of a feature that allows you to
          upload content to our Site, you must comply with the content standards
          set out in our Acceptable Use Policy. You warrant that any such
          contribution does comply with those standards, and you will be liable
          to us and indemnify us for any breach of that warranty. This means
          that you will be responsible for any loss or damage we suffer as a
          result of your breach of warranty. You grant to us a non-exclusive,
          perpetual, irrevocable, transferable, royalty-free license (including
          full rights to sub-license) to use any content that you upload to or
          post to our Site (“Your Content”) (including, without limit, the right
          to adapt, alter, amend or change Your Content) in any media throughout
          the world without restriction, and you warrant that any material you
          submit is your own work or that you have obtained all necessary rights
          and permissions of the relevant owner and that you have all relevant
          rights in Your Content to enable you to grant the rights in this
          clause. You hereby unconditionally and irrevocably waive and agree not
          to assert any and all moral rights and any other similar rights and
          all right of publicity and privacy in any country in the world in
          connection with our exploitation of the rights in Your Content granted
          hereunder, to the maximum extent permissible by law. Publication of
          Your Content will be at our sole discretion and we are entitled to
          make additions or deletions to any such material prior to publication,
          after publication or to refuse publication. If Your Content contains
          any material that is not owned by or licensed to you and/or which is
          subject to third party rights, you are responsible for obtaining,
          prior to submission of Your Content, all releases, consents and/or
          licenses necessary to permit use and exploitation of Your Content by
          us in accordance with these Terms of Use without additional payment.
          Although we have no obligation to moderate content on our Site, we
          reserve the right to monitor any information transmitted or received
          through our Site. We reserve the right to reject, block, suspend or
          remove Your Content at any time and in our sole discretion. We in no
          way guarantee that Your Content or any part of it will be displayed on
          our Site. You represent that you have the lawful right to submit such
          information and agree that you will not submit any information unless
          you are legally entitled to do so. We have the right to use, copy,
          distribute and disclose to third parties any such content for any
          purpose. We also have the right to disclose your identity to any third
          party who is claiming that any material posted or uploaded by you to
          our Site constitutes a violation of their intellectual property
          rights, or of their right to privacy. We will not be responsible, or
          liable to any third party, for the content or accuracy of any
          materials posted by you or any other user of our Site. We have the
          right to remove any content or posting you make on our Site at our
          sole discretion. We do not accept unauthorised idea submissions
          outside of established business relationships. Please do not
          communicate unauthorised idea submissions to us through this Site. Any
          ideas disclosed to us outside a pre-existing and documented
          confidential business relationship are not confidential and we shall
          therefore be entitled to develop, use, copy and/or commercially
          exploit these or similar ideas to the fullest extent and without
          compensating you or accounting to you. By submitting an idea or other
          detailed submission to us through this Site, you agree to be bound by
          the terms of this stated policy.
        </BodyText>
        <LegacyHeaderText>1. Registration</LegacyHeaderText>
        <BodyText>
          The Website is available only to registered Users. Registration will
          be available to Customer who have the legal capacity to enter into a
          contract User(s) can become a registered User(s) by filling an on-line
          registration form on the web site by providing some basic information
          (name, contact information, residential or business address etc.).
          AkzoNobel will establish an account ("Account") for each User upon
          registration and assign a user alias ("User ID") and password
          ("Password") for log-in access to your Account. AkzoNobel will also
          assign Users upon registration a web-based email/message account with
          limited storage space to send or receive email/messages. All
          communications between Painters and Consumers may be initiated only by
          Consumers. Consumers will not be allowed to send messages to other
          Users. Users will be responsible for the content of all the messages
          communicated through their Account, as well as the consequences of any
          such message. You can review the personal information you provided us
          and make any desired changes to the information, or to the settings
          for your account on this Platform, at any time by logging in to your
          account and editing the information on your profile page. You the
          Consumer represents and warrants that if you are an individual, (i)
          you are over eighteen years of age or are over such age limit as
          legally allowed in the relevant country to enter into contracts, or
          (ii) that if you are registering on behalf of an entity, that you are
          authorized to enter into, and bind the entity to, these Terms and
          register at the Platform, in accordance with the laws of Singapore.
          Any person under the age of eighteen (18) years accessing the Website
          should do so only under parental guidance. If we change the
          eligibility criteria to be registered with the Website and you no
          longer comply with the new eligibility criteria, as determined by us
          in our sole discretion, you accept that we may close your Account
          without any liability to us. You are solely responsible for ensuring
          that these Terms are in compliance with all laws, rules and
          regulations applicable to you and the right to access the Platform is
          revoked where these Terms or use of the Platform is prohibited and, in
          such circumstances, you agree not to use or access the Website or
          Platform in any way. If you the Consumer register on behalf of a
          business entity, you represent that business entity and (a) you have
          the authority to bind the entity to terms and condition of use and/or
          this Agreement; (b) the address you use when registering is the
          principal place of business of such business entity; and (c) all other
          information submitted to AkzoNobel during the registration process is
          true, accurate, current and complete. By becoming a registered User,
          you consent to the inclusion of your personal data in our online
          database and authorize AkzoNobel to share such information with its
          partners and affiliates in compliance with our Privacy Policy. You
          agree that AkzoNobel may suspend or terminate your Account at any time
          without any prior notification or without giving any reason
        </BodyText>
        <LegacyHeaderText>2. Final provisions</LegacyHeaderText>
        <BodyText>
          The Users shall ensure they any Content published or uploaded on the
          Website must not:
          <ul>
            <li>be misleading in any way;</li>
            <li>
              contain video, photographs, or images of another person (without
              voluntary permission/consent of that person);
            </li>
            <li>contain any material which is defamatory of any person;</li>
            <li>
              contain any material which is obscene, offensive, hateful or
              inflammatory;
            </li>
            <li>
              be grossly harmful, harassing, blasphemous defamatory, obscene,
              pornographic, paedophilic, libellous, invasive of another's
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever;
            </li>
            <li>
              infringe any intellectual property right of any other person;
            </li>
            <li>be likely to harm minors in any way</li>
            <li>be likely to deceive any person;</li>
            <li>
              be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence;
            </li>
            <li>prevent investigation of any offence;</li>
            <li>be insulting to any other nation;</li>
            <li>
              be threatening, abuse or invade another’s privacy, or cause
              annoyance, inconvenience or needless anxiety;
            </li>
            <li>
              be likely to harass, humiliate, upset, embarrass, alarm or annoy
              any other person;
            </li>
            <li>
              be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person;
            </li>
            <li>
              give the impression that they emanate from AkzoNobel, if this is
              not the case;
            </li>
            <li>
              advocate, promote or assist any unlawful act such as (by way of
              example only) copyright infringement or computer misuse;
            </li>
            <li>
              refer to any website, app or URL that in our sole discretion,
              contains material that is inappropriate for the Website; or any
              other website that contains content that would be prohibited or
              violates the letter or spirit of these Terms of Use;
            </li>
            <li>
              interfere with another User's use and enjoyment of the Website or
              any other individual's user and enjoyment of similar services;
            </li>
            <li>
              solicit any activity which we, in our sole discretion, believe is
              or could be construed as being illegal;
            </li>
            <li>
              involve the transmission of "junk mail", "chain letters", or
              unsolicited mass mailing or "spamming";
            </li>
            <li>
              directly or indirectly, offer, attempt to offer, trade or attempt
              to trade in any item, the dealing of which is prohibited or
              restricted in any manner under the provisions of any applicable
              law, rule, regulation or guideline for the time being in force;
            </li>
            <li>
              break or circumvent our security measures or otherwise test the
              vulnerability of our systems or networks;
            </li>
            <li>try to reverse engineer any of Company’s software;</li>
            <li>
              collect or store personally identifiable information of any Users
              or visitors without their permission;
            </li>
            <li>
              threatens the unity, integrity, defence, security or sovereignty
              of Singapore;
            </li>
            <li>
              sell your username or otherwise transfer it for compensation; and
            </li>
            <li>
              encourage or help anyone do any of the things on this list.{" "}
            </li>
          </ul>
          AkzoNobel has made every reasonable effort to ensure that the
          application / website is virus-free. However, no declaration or
          warranty, technical or otherwise, can be given, express or implied, in
          the sense that this platform is free of bugs and / or viruses, and
          Users are responsible for ensuring the installation of appropriate
          antivirus software. The application / website must be used properly by
          Users, who must not introduce any viruses, trojans, worms, logic bombs
          or other malicious or technologically harmful materials into it. Users
          should not attempt to gain unauthorized access to the application /
          website, the server where it is stored or any server, computer or
          database connected to this platform. Violation of this item may
          constitute a crime. AkzoNobel will report any violation to the
          competent law enforcement authorities and cooperate with them by
          disclosing the Users' names. If any breach occurs, Users' access to
          the application / website will be immediately blocked. To the extent
          permitted by applicable law, Akzo Nobel disclaims complete
          responsibility or liability towards Users howsoever arising in
          contract, torts or otherwise including but not limited to any indirect
          or consequential damages or loss of profit/business. At all times, the
          User will hold AkzoNobel harmless and indemnify for any claim that may
          arise against Akzo Nobel arising out of or in connection with usage of
          the Platform/Website. These Terms are drafted in the English language
          and where required in the local language of the country. If this Terms
          has been translated into any language other than English, the English
          language text will prevail. All items in these General Terms and
          Conditions of Use are governed by the laws in force in Singapore For
          all matters relating to interpretation, compliance or any other
          question relating to these General Terms and Conditions of Use, the
          parties agree to submit to the exclusive jurisdiction of the courts of
          Singapore
        </BodyText>
      </LegacyBody>
    </LegacyContainer>
  );
};

export default LegalBodyEnglish;
