import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  SearchFilterDiv,
  FilterText,
  DropDownParentDiv,
  DropDownDiv,
  DropDownText,
  DropDownContent,
  DropDownContentText,
  SearchBarBodyCustomerDiv,
  SearchBarInputDiv,
  SearchBarInput,
} from "../../styles/AdminCustomersStyles";
import SEARCH_IMAGE from "../../images/search.svg";
import SEARCH_FILTER from "../../images/search_filter.svg";
import DROP_DOWN_MENU_ONE_IMAGE from "../../images/drop_down_menu_one.svg";
import DROP_DOWN_MENU_TWO_IMAGE from "../../images/drop_down_menu_two.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminCustomerSearchBody = ({
  textFilterName,
  setTextFilterName,
  toggleCustomerSearchRequest,
  toggleFilterDropDown,
  selectedFilterOption,
  filterDropDown,
  dropDownList,
  setSelectedFilterOption,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const selectedCountry= decodedToken
  ? {
      value: decodedToken?.country,
      label: decodedToken?.country,
    }
  : countryValue
const searchName = selectedCountry.value == "Vietnam" ? "Tim kiêm tên" : "Search name";
  return (
    <SearchBarBodyCustomerDiv>
      <SearchBarInputDiv>
        <img src={SEARCH_IMAGE} alt="Search" />
        <SearchBarInput
          placeholder={searchName}
          value={textFilterName}
          onChange={(event) => setTextFilterName(event.target.value)}
        />
      </SearchBarInputDiv>

      <SearchFilterDiv onClick={toggleCustomerSearchRequest}>
        <img src={SEARCH_FILTER} alt="" />
        <FilterText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"FILTERS"}
        /></FilterText>
      </SearchFilterDiv>

      <DropDownParentDiv>
        <DropDownDiv onClick={toggleFilterDropDown}>
          <img src={DROP_DOWN_MENU_ONE_IMAGE} alt="Drop Down" />
          {selectedFilterOption == "Latest First" && 
         (<DropDownText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"LATEST_FIRST"}
        /></DropDownText>)}

      {selectedFilterOption == "Oldest First" && 
         (<DropDownText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"OLDEST_FIRST"}
        /></DropDownText>)}
          <img src={DROP_DOWN_MENU_TWO_IMAGE} alt="Drop Down" />
        </DropDownDiv>
        <DropDownContent show={filterDropDown}>
          {dropDownList.map((item, index) => {
            return (
              <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleFilterDropDown();
                  setSelectedFilterOption(item);
                }}
              >
                {item == "Latest First" && 
              (<DropDownText><LanguageSelector
               selectedCountry={
                 decodedToken
                   ? {
                       value: decodedToken?.country,
                       label: decodedToken?.country,
                     }
                   : countryValue
               }
               text={"LATEST_FIRST"}
             /></DropDownText>)}
     
           {item == "Oldest First" && 
              (<DropDownText><LanguageSelector
               selectedCountry={
                 decodedToken
                   ? {
                       value: decodedToken?.country,
                       label: decodedToken?.country,
                     }
                   : countryValue
               }
               text={"OLDEST_FIRST"}
             /></DropDownText>)}
              </DropDownContentText>
            );
          })}
        </DropDownContent>
      </DropDownParentDiv>
    </SearchBarBodyCustomerDiv>
  );
};

export default AdminCustomerSearchBody;
