import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  PendingButton,
  PendingResolvedContainerDiv,
  ResolvedButton,
} from "../../styles/PreviewStaffDashboardStyles";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewStaffDashboardPendingResolvedContainer = ({
  pendingClicked,
  togglePendingClicked,
  pendingListLength,
  resolvedListLength,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <PendingResolvedContainerDiv>
      <PendingButton
        pendingClicked={pendingClicked}
        onClick={togglePendingClicked}
      >
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PENDING"}
            />  ({pendingListLength})
      </PendingButton>
      <ResolvedButton
        pendingClicked={pendingClicked}
        onClick={togglePendingClicked}
      >
       <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVED"}
            />  ({resolvedListLength})
      </ResolvedButton>
    </PendingResolvedContainerDiv>
  );
};

export default PreviewStaffDashboardPendingResolvedContainer;
