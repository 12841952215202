import styled from "styled-components";
import COSTAL_VILLA_IMAGE from "../images/costal-villa.png";

export const ConsumerDuluxThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const HeaderText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #545850;
  margin: 4vh 0;
`;

export const ThemeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px auto;
`;

export const ThemeButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.theme === true ? "600" : "normal")};
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0px 10px;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${(props) =>
    props.theme === true ? "2px solid #2fc38c" : "none"};
  background-color: #fff;
  :hover {
    cursor: pointer;
  }
`;

export const ThemeItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0 20px;
  }
`;

export const ThemeImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background-image: ${(props) => `url(${props.image})`}; */
  /* background-image: url(${COSTAL_VILLA_IMAGE});
  width: 100px;
  height: 100px; */
  :hover {
    cursor: pointer;
  }
`;

export const ThemeImage = styled.img`
  border: ${(props) =>
    props.selectedImage === true ? " 2px solid #EFBF42" : "none"};
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  margin: 0;
  padding: 0;
  @media (max-width: 1100px) {
    width: 160px;
    height: 160px;
  }
`;

export const InteriorThemeImage = styled.img`
  border: ${(props) =>
    props.selectedImage === true ? " 2px solid #EFBF42" : "none"};
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  margin: 0;
  padding: 0;
  margin: 10px;
  width: 200px;
  height: 200px;
  @media (max-width: 1100px) {
    width: 160px;
    height: 160px;
  }
`;

export const ThemeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 0;
`;

export const NextButton = styled.button`
  background-color: #2fc38c;
  border-radius: 5px;
  border: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  padding: 4px 10px;
  width: 10vw;
  margin: 40px 14vw 20px 0px;
  outline: none;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 180px;
    margin: 20px auto;
    width: 100px;
  }
`;

export const InteriorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaleeteeDiv = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 5px;
  margin: 1px;
`;
