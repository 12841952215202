import styled from "styled-components";

export const LegacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

export const LegacyHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  /* @media (max-width: 1500px) {
    visibility: hidden;
    padding: 0px;
  } */
  :hover {
    cursor: pointer;
  }
`;

export const LegacyBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 120px;
`;

export const LegacyHeaderText = styled.p`
  font-size: 42px;
  line-height: 50px;
  font-family: "Colour-Sans";
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const LegacyText = styled.p`
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin: 20px 0;
`;

export const BodyText = styled.p`
  color: #666;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
