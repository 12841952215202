import React from "react";
import LanguageSelector from "../../language/LanguageSelector";
import {
  SelectedShadeText,
  ColourShadeTextDiv,
  ColourCode,
  SelectedShadeDiv,
  ColourShadeDiv,
  SelectedColorShade,
  ColourName,
  SuggestedPalettetext,
} from "../../styles/ConsumerChooseColorStyles";

const ConsumerChooseColourFamily = ({
  selectedColourFromDrawer,
  selectedCountry,
}) => {
  return (
    <SelectedShadeDiv>
      <div style={{ marginBottom: "20px" }}>
        <SuggestedPalettetext style={{ marginBottom: "10px" }}>
          {" "}
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"SELECTED_SHADE"}
          />
        </SuggestedPalettetext>
      </div>
      <ColourShadeDiv>
        <SelectedColorShade
          style={{
            backgroundColor: `rgb(${selectedColourFromDrawer.red_value},${selectedColourFromDrawer.green_value},
        ${selectedColourFromDrawer.blue_value})`,
          }}
        />
        <ColourShadeTextDiv>
          <ColourName>{selectedColourFromDrawer.colour_name}</ColourName>
          <ColourCode>{selectedColourFromDrawer.colour_code}</ColourCode>
        </ColourShadeTextDiv>
      </ColourShadeDiv>
    </SelectedShadeDiv>
  );
};

export default ConsumerChooseColourFamily;
