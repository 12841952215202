import React from "react";
import IMAGE_UPLOAD_CLEAR from "../../images/image_upload_clear.svg";
import IMAGE_INTERIOR_EXTERIOR from "../../images/interior_exterior_image.svg";
import IMAGE_REDUCE_SIZE from "../../images/reduce_image_size.svg";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { TOKEN_NAME } from "../../utils/Constants";
import {
  ImageInstructionsFooter,
  ImageInstructionsBodyDiv,
  ImageInstructionsBackgroundDiv,
  ImageInstructionsBodyColumnDiv,
  ImageInstructionsText,
  ReduceImageSize,
  Gallery,
  GalleryRow,
  GalleryGridItem,
  DoGalleryColumn,
  DontGalleryColumn,
  ImageGalleryText,
  DosAndDontsTitle,
  GridItemFigureImg,
  GridItemFigCaption,
  GalleryDiv,
} from "../../styles/ConsumerReferenceImageStyles";
import IMAGES_DONTS from "../../images/ImagesDonts";
import IMAGES_DOS from "../../images/ImagesDos";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerReferenceUploadInstructions = ({ width, selectedCountry }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  //console.log("HELLO" + decodedToken.country);
  console.log(countryValue);
  return (
    <>
      <ImageInstructionsFooter>
        <ImageInstructionsBodyDiv>
          <ImageInstructionsBackgroundDiv>
            <img src={IMAGE_REDUCE_SIZE} alt="Reduce " />
          </ImageInstructionsBackgroundDiv>
          <ImageInstructionsBodyColumnDiv>
            <ImageInstructionsText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"YOUR_IMAGE_MUST_BE_5_MB"}
              />
            </ImageInstructionsText>
            <ReduceImageSize href="https://compressjpeg.com/" target="_blank">
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CLICK_HERE_TO_REDUCE_THE_SIZE"}
              />
            </ReduceImageSize>
          </ImageInstructionsBodyColumnDiv>
        </ImageInstructionsBodyDiv>
        <ImageInstructionsBodyDiv>
          <ImageInstructionsBackgroundDiv>
            <img src={IMAGE_UPLOAD_CLEAR} alt="Clear " />
          </ImageInstructionsBackgroundDiv>
          <ImageInstructionsBodyColumnDiv>
            <ImageInstructionsText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"MAKE_SURE_THE_IMAGE_UPLOADED"}
              />
            </ImageInstructionsText>
          </ImageInstructionsBodyColumnDiv>
        </ImageInstructionsBodyDiv>
        <ImageInstructionsBodyDiv>
          <ImageInstructionsBackgroundDiv>
            <img src={IMAGE_INTERIOR_EXTERIOR} alt="Interior Exterior" />
          </ImageInstructionsBackgroundDiv>
          <ImageInstructionsBodyColumnDiv>
            <ImageInstructionsText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"PLEASE_UPLOAD_IMAGES_OF_YOUR_INTERIOR"}
              />
            </ImageInstructionsText>
          </ImageInstructionsBodyColumnDiv>
        </ImageInstructionsBodyDiv>
      </ImageInstructionsFooter>
      {decodedToken.country === "Vietnam" ||
      selectedCountry.value === "Vietnam" ? (
        <>
          <ImageInstructionsFooter>
            <ImageInstructionsBodyDiv>
              <ImageInstructionsBackgroundDiv>
                <img src={IMAGE_INTERIOR_EXTERIOR} alt="Interior Exterior" />
              </ImageInstructionsBackgroundDiv>
              <ImageInstructionsBodyColumnDiv>
                <ImageInstructionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"FINISHED_PUTTY_APPLICATION"}
                  />
                </ImageInstructionsText>
              </ImageInstructionsBodyColumnDiv>
            </ImageInstructionsBodyDiv>
            <ImageInstructionsBodyDiv>
              <ImageInstructionsBackgroundDiv>
                <img src={IMAGE_INTERIOR_EXTERIOR} alt="Interior Exterior" />
              </ImageInstructionsBackgroundDiv>
              <ImageInstructionsBodyColumnDiv>
                <ImageInstructionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"MINIMIZED_OBSTACLE_BLOCKING_CONSTRUCTION"}
                  />
                </ImageInstructionsText>
              </ImageInstructionsBodyColumnDiv>
            </ImageInstructionsBodyDiv>
            <ImageInstructionsBodyDiv>
              <ImageInstructionsBackgroundDiv>
                <img src={IMAGE_INTERIOR_EXTERIOR} alt="Interior Exterior" />
              </ImageInstructionsBackgroundDiv>
              <ImageInstructionsBodyColumnDiv>
                <ImageInstructionsText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"HI_RESOLUTION_FILE"}
                  />
                </ImageInstructionsText>
              </ImageInstructionsBodyColumnDiv>
            </ImageInstructionsBodyDiv>
          </ImageInstructionsFooter>
          <GalleryDiv>
            <DoGalleryColumn>
              <DosAndDontsTitle>Làm</DosAndDontsTitle>
            </DoGalleryColumn>
            <DontGalleryColumn>
              <DosAndDontsTitle>Đừng</DosAndDontsTitle>
            </DontGalleryColumn>
          </GalleryDiv>
          <GalleryDiv>
            <DoGalleryColumn>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DOS.BIET_THU} alt="Biet Thu" />
                <GridItemFigCaption>Biệt Thự</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DOS.NHA_PHO} alt="Nha Pho" />
                <GridItemFigCaption>Nhà Phố</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DOS.NOI_THAT_1} alt="Noi That" />
                <GridItemFigCaption>Nội Thất</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DOS.NOI_THAT} alt="Noi That" />
                <GridItemFigCaption>Nội Thất</GridItemFigCaption>
              </Gallery>
            </DoGalleryColumn>

            <DontGalleryColumn>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DONTS.BAT_CHE} alt="Bat Che" />
                <GridItemFigCaption>Bạt Che</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.BI_CHOI_SANG}
                  alt="Bi Choi Sang"
                />
                <GridItemFigCaption>Bị Chói Sáng</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DONTS.CAY_CHE} alt="Cay Che" />
                <GridItemFigCaption>Cây che</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.CHUA_TO_HO}
                  alt="Chưa tô hồ"
                />
                <GridItemFigCaption>Chưa tô hồ</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.CHUP_LAI_MAN_HINH_1}
                  alt="Chụp lại màn hình"
                />
                <GridItemFigCaption>Chụp lại màn hình </GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.CHUP_LAI_MAN_HINH}
                  alt="Chụp lại màn hình"
                />
                <GridItemFigCaption>Chụp lại màn hình </GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.CHUP_XA_CONG_TRINH}
                  alt="Chup Xa Cong Trinch"
                />
                <GridItemFigCaption>Chụp Xa Công Trình</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.GHI_CHU_QUA_NHIEU}
                  alt="Ghi Chu Qua Nhieu"
                />
                <GridItemFigCaption>Ghi Chú Quá Nhiều</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DONTS.LUOI_CHE} alt="Luoi Che" />
                <GridItemFigCaption>Lưới Che</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DONTS.MAT_BANG} alt="Mat Bang" />
                <GridItemFigCaption>Mặt Bằng</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg src={IMAGES_DONTS.MAT_CAT} alt="Mat Cat" />
                <GridItemFigCaption>Mặt Cắt</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.NGUOC_SANG}
                  alt="Nguoc Sang"
                />
                <GridItemFigCaption>Ngược Sáng</GridItemFigCaption>
              </Gallery>
              <Gallery>
                <GridItemFigureImg
                  src={IMAGES_DONTS.PHOI_CANH_KEM_CHAT_LUONG}
                  alt="Phoi Canh Kem Chat Luong"
                />
                <GridItemFigCaption>
                  Phối Cảnh Kém Chất Lượng
                </GridItemFigCaption>
              </Gallery>
            </DontGalleryColumn>
          </GalleryDiv>
        </>
      ) : null}
    </>
  );
};

export default ConsumerReferenceUploadInstructions;
