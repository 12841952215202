import React from "react";
import {
  PrivacyPolicyContainer,
  PrivacyPolicyBody,
  PrivacyPolicyHeaderText,
  PrivacyPolicyText,
  BodyText,
  BoldBodyText,
  CookieBodySpacer,
} from "../../styles/PrivacyPolicyStyles";

const PrivacyPolicyBodyEnglish = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyBody>
        <PrivacyPolicyHeaderText>Privacy Policy</PrivacyPolicyHeaderText>
        <PrivacyPolicyText>Privacy Policy </PrivacyPolicyText>
        <BodyText>
          We recognize that your privacy is very important and take it
          seriously. This Privacy Policy (“Privacy Policy”) describes our
          policies and procedures on the collection, use and disclosure of your
          information when you use the Website. We will not use or share your
          information with anyone except as described in this Privacy Policy.
          This Privacy Policy is a part of and incorporated within and is to be
          read along with the Terms of Use (“Terms”). The capitalized terms used
          in this Privacy Policy, but not defined herein, shall have the meaning
          given to such terms in the Terms. When does this Privacy Statement
          apply? This Privacy Policy applies to (a) registered Users, and (b)
          all persons (including individuals) other than registered Users,
          directly or indirectly, accessing the website. For what purpose do we
          process your personal data? We use your personal data for various
          purposes. Below you will find a summary of the types of personal data
          and the purposes for which we use them. We process technical data,
          such as your IP address, the browser you use, the pages visited, the
          sites visited before / after and the duration of a visit in order to
          improve the functionality of the Website and allow you to find the
          information you seek simply and quickly. Such data is also used to
          solve technical difficulties or to improve the accessibility of
          certain parts of the Website. For this purpose, we also use cookies.
          When choosing to register with us, it is necessary to provide personal
          data such as your name, a valid email address, your residential or
          business address and other related information so that we can create
          an Account for you. Your personal data allows us to manage your
          account, guarantee confidentiality and maintain security access. We
          also process data such as your display name, your role and your access
          data. We can, for example, change your password for you. However, we
          are unable to view your password. We use aggregated personal data to
          analyse visitor behaviour and adjust our offering accordingly to
          ensure that they are relevant to our customers. This means that we
          analyse how often you visit our app / website. Based on this
          information, we can adjust our offers and newsletters. We may also
          conduct market trends surveys through statistical analysis to evaluate
          and adapt our products and advertisements according to new
          developments, but the results of the survey are only disclosed in
          aggregate. We may purchase supplementary data from public sources to
          supplement our database for the purposes described above. We can send
          emails with promotions, news and invitations to participate in events.
          If you choose to participate in any of these activities, we need your
          personal data to be able to advertise and organize such events. For
          this, we process your name, address, e-mail address and your
          participation in the activity. We may call and/or SMS you to gather
          details of your usage or experience of the Platform/website including
          the details of the products used for the painting services. You agree
          and consent that we may collect, handle, process, use, store,
          transfer, exchange, share, disseminate, destruct, or otherwise make
          use of your personal information. Notwithstanding anything to the
          contrary, you further unequivocally agree and consent that we may use
          your personal information for the purposes provided above and share
          such personal information without limitation to a third party in the
          manner provided below.
        </BodyText>
        <br />
        <BoldBodyText>Who has access to your data?</BoldBodyText>
        <br />
        <BodyText>
          We treat your data with care and confidentiality; we do not share them
          with third parties that are not explicitly stated in this Privacy
          Policy. With other entities within the AkzoNobel group: We may share
          information about our consumers in a pseudonymous, anonymous or
          aggregated form with other members of the AkzoNobel Group so that they
          can be used, for example, for trend analysis. Please note that the
          data may get transferred and/or hosted out of Singapore to multiple
          locations. With service providers: We use several companies that
          process data on our behalf, such as hosting companies, delivery
          services. These companies use your information on our behalf and are
          subject to strict rules that need to be followed when processing your
          information and similar standards of data protection as maintained by
          us. We do not allow such companies to use the data in any other way
          that is not in accordance with our instructions. Other third parties:
          We may be required to disclose personal information at the request of
          government agencies or to maintain public order. We may also share
          your data with our channel partners including distributors, dealers
          and/or retailers. We will only share your information with other
          companies or individuals if we have your consent to do so. How to deal
          with links to other sites and social media?
        </BodyText>
        <br />
        <CookieBodySpacer>
          <BoldBodyText>
            External sites
            <br />
          </BoldBodyText>
          <BodyText>
            In the Website you may find several links to other sites. Although
            these sites are selected with care, we are not responsible for the
            processing of your personal data through these other sites.
            Therefore, the Terms and this Privacy Policy are not applicable to
            the use of such sites.
          </BodyText>
          <BoldBodyText>
            Social media
            <br />
          </BoldBodyText>
          <BodyText>
            The Website may provide social plug-ins from various social
            networks. If you choose to interact with a social network (by
            registering an account, for example), your activity on the Website
            will also be available for social networks such as Facebook and
            Twitter. If you are connected to one of these social networks during
            your visit to the Website, the social network can add such
            information to its respective profile on that platform. If you are
            interacting with one of the social plug-ins, that information will
            be transferred to the social network. If you do not want such data
            transfer to occur, disconnect from your social network before
            entering our Website.
            <br />
          </BodyText>
          <BoldBodyText>
            How do we protect data?
            <br />
          </BoldBodyText>
          <BodyText>
            Your personal data is treated with strict confidentiality and we
            adopt comprehensive and reasonable technical and organizational
            security measures against the loss or unlawful processing of such
            data, in compliance with the provisions of the Information
            Technology Act, 2000. For this purpose, we employ several security
            techniques, including secure servers, firewalls and encryption, in
            addition to the physical protection of the locations where the data
            is stored.
            <br />
          </BodyText>
          <BoldBodyText>
            Retention of data
            <br />
          </BoldBodyText>
          <BodyText>
            We will not retain your personal data for a period longer than
            necessary to achieve the purpose for which it was collected or for
            the maximum period determined by law. If you are a registered user,
            you have the following rights under this Privacy Policy: Personal
            Data Rights: You will have the right, upon request, to access and
            review any "sensitive personal data or information" provided by you
            and held by us. Upon discovering that the "sensitive personal data
            or information" provided is inaccurate or deficient in any manner,
            you will have the right to request suitable changes or amendments to
            such data. If it is feasible to do, we may make appropriate changes
            in its database in order to reflect such requested amendments.
            Account Rights: You can control your account settings and manage
            what communications you receive from us and how you receive them.
            You may also modify your password or close your account if you
            choose to do so. Consent Rights: You will also have the option of
            withdrawing consent previously provided to us. In such cases, we may
            no longer be obliged to continue providing the Platform/Website.
          </BodyText>
        </CookieBodySpacer>{" "}
      </PrivacyPolicyBody>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyBodyEnglish;
