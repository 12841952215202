import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  BodyImageOne,
  ImageText,
  ImageCanvas,
  PaletteSideBarHeaderText,
  DoneButton,
  ZoomToolIcons,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import styled from "styled-components";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import RESET_ZOOM_ICON from "../../images/reset_zoom_icon.png";
import ZOOM_IN_ICON from "../../images/zoom_in_icon.png";
import ZOOM_OUT_ICON from "../../images/zoom_out_icon.png";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorTouchUpEditable = ({
  useOptionOne,
  useOptionTwo,
  canvasRefColor,
  drawHandler,
  finishDrawingHandler,
  startDrawingHandler,
  selectedColorId,
  colorList,
  setColorList,
  setSelectedColorImage,
  selectedColorImage,
  setPointScale,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const currentOptionList = colorList;
  const [selectedIdRefImg, setSelectedIdRefImg]= useState(null);
  
  useEffect(() => {
    if(selectedIdRefImg !== null && selectedColorImage !== null){
      console.log("picker works")
      var changedArr = colorList;
      changedArr[selectedColorId] = selectedColorImage;
      currentOptionList[selectedIdRefImg] = changedArr[selectedColorId];
      setColorList(changedArr);
      setSelectedColorImage(null);
      setSelectedIdRefImg(null);
    }
  }, [selectedIdRefImg, selectedColorImage])


    
  useEffect(() => {
    if(document.getElementById("editablecanvas")){
      document.getElementById("editablecanvas").addEventListener("keydown", keyDownHandler)
    }
  }, [])

  const selectPaletteHandler = (e) => {
    console.log("selector works")
    const selected_index = parseInt(e.currentTarget.id);
    setSelectedIdRefImg(selected_index);
    setSelectedColorImage(null);
  }
  
  const rescalePointsHandler = (e) => {
    setPointScale(e.scale)
  }
    
  const keyDownHandler = (e) => {
    console.log(e)
  }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        position: "relative",
        margin: "0 50px",
      }}
    >
      {useOptionOne? null:
      <>
      <ImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE_IMAGE"}
            /></ImageText>
      <ImageCanvas
        ref={canvasRefColor}
        onMouseMove={drawHandler}
        onMouseUp={finishDrawingHandler}
        onMouseDown={startDrawingHandler}
      />
      <div
        style={{
          display: "inline-block",
          flexDirection: "column",
          alignItems: "start",
          position: "relative",
          margin: "20px 0px",
        }}>
        {/* <OptionText>Palette</OptionText> */}
          <PaletteContainer>
            {/* {currentOptionList &&
              currentOptionList[selectedPaginationNumber]?.colours?.map(
                (item, index) => {
                  return (
                    <div key={`${index}`}>
                      <PaletteDiv
                        id={`${index}`}
                        onClick={selectColorHandler}
                        style={{
                          backgroundColor: `rgb(${item?.red_value},${item.green_value},${item?.blue_value})`,
                        }}
                      >
                        <ToolTip>{item?.colour_name}</ToolTip>
                      </PaletteDiv>
                      <ColorText>{item?.colour_code}</ColorText>
                    </div>
                  );
                }
              )} */}
              {currentOptionList &&
              currentOptionList.map(
                (item, index) => { 
                  if(index>0){
                  return (
                    <div key={`${index}`}>
                      <PaletteDiv
                        id={`${index}`}
                        onClick={selectPaletteHandler}
                        style={{
                          backgroundColor: `rgb(${item[0]},${item[1]},${item[2]})`,
                        }}
                      >
                      {/* <ToolTip>{item?.colour_name}</ToolTip> */}
                      </PaletteDiv>
                      {/* <ColorText>{item?.colour_code}</ColorText> */}
                    </div>
                    );}
                    }
                  )}
          </PaletteContainer>
        </div>
        </>}
      {useOptionTwo? null:
      <>
      <ImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EDITABLE_IMAGE"}
            /></ImageText>
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
        onWheelStop={rescalePointsHandler}
        id="editablecanvas"
        panning={{activationKeys: ["alt"]}}
      >
        {({ scale, zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <TransformComponent>
              <ImageCanvas
                ref={canvasRefColor}
                onMouseMove={drawHandler}
                onMouseUp={finishDrawingHandler}
                onMouseDown={startDrawingHandler}
              />
            </TransformComponent>
              <div 
                className="tools"
                style={{margin: "10px"}}>
                <ZoomToolIcons 
                  src={ZOOM_IN_ICON}
                  onClick={zoomIn}
                />
                <ZoomToolIcons 
                  src={ZOOM_OUT_ICON}
                  onClick={zoomOut}
                />
                {/* <ZoomToolIcons 
                  src={RESET_ZOOM_ICON}
                  onClick={resetTransform}
                /> */}
              </div>
          </React.Fragment>
        )}
      </TransformWrapper>
      </>}
    </div>
  );
};

const PaletteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3px 25px;
  width: 500px;
`;

const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const ColorText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin: 2px auto;
  text-align: center;
`;

const PaletteDiv = styled.div`
  border-radius: 3px;
  width: 55px;
  height: 55px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  margin: 2px 12px;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

const OptionText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 10px 25px 4px 25px;
`;

export default PreviewSupervisorTouchUpEditable;
