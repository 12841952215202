import React from "react";
import {
  LegacyContainer,
  LegacyBody,
  LegacyHeaderText,
  LegacyText,
  BodyText,
} from "../../styles/LegalStyles";

const LegalBodyThailand = () => {
  return (
    <LegacyContainer>
      <LegacyBody>
        <LegacyHeaderText>
          โปรดอ่านข้อกำหนดและเงื่อนไขฉบับนี้อย่างละเอียดก่อนใช้งานเว็บไซต์นี้ข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งาน
        </LegacyHeaderText>
        <LegacyText>ข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งาน</LegacyText>
        <BodyText>
          ข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งานฉบับนี้
          (รวมถึงนโยบายความเป็นส่วนตัว) (“ข้อกำหนด”)
          เป็นข้อกำหนดว่าคุณสามารถใช้งานเว็บไซต์ซึ่งอยู่ที่ URL [**]
          (“เว็บไซต์”) ได้ การใช้งานเว็บไซต์ประกอบด้วยการเข้าถึง การเรียกดู
          หรือการลงทะเบียนใช้งานเว็บไซต์
          โปรดอ่านข้อกำหนดฉบับนี้อย่างละเอียดก่อนเริ่มใช้งานเว็บไซต์
          เราขอแนะนำให้คุณพิมพ์สำเนาข้อกำหนดนี้ไว้สำหรับการอ้างอิงในอนาคต
          Bจากการใช้งานเว็บไซต์
          คุณได้ยืนยันว่ายอมรับข้อกำหนดฉบับนี้และตกลงที่จะปฏิบัติตามข้อกำหนด
          หากคุณไม่ยอมรับข้อกำหนดฉบับนี้ คุณต้องไม่ใช้งานเว็บไซต์นี้
          หากคุณไม่ยอมรับการแก้ไขใดๆ
          ที่เราดำเนินการต่อข้อกำหนดฉบับนี้เมื่อใดก็ตามในอนาคต
          คุณต้องหยุดใช้งานเว็บไซต์โดยมีผลทันที
        </BodyText>
        <LegacyHeaderText>วัตถุประสงค์</LegacyHeaderText>
        <BodyText>
          เว็บไซต์นี้เป็นแพลตฟอร์มเทคโนโลยี
          ซึ่งผู้ใช้สามารถส่งคำขอให้เราจัดหาชุดสีหรือตัวเลือกสีสำหรับสถานที่ทาสีของผู้ใช้ได้ฟรี
          บริการนี้เป็นงานที่ปราศจากค่าตอบแทนเพื่อประโยชน์ของผู้ใช้
          และเราขอแนะนำให้ผู้ใช้ใช้วิจารณญาณ
          (และไม่พึ่งพาบริการนี้แต่เพียงอย่างเดียว)
          และพิจารณามุมมองที่เป็นอิสระของตนเองเกี่ยวกับชุดสีก่อนตัดสินใจ
          เว็บไซต์นี้ดำเนินการโดย บริษัท อั๊คโซ่ โนเบล เพ้นท์ส (ประเทศไทย) จำกัด
          (“อั๊คโซ่ โนเบล”) ซึ่งก่อตั้ง ภายใต้ประมวลกฎหมายแพ่งและพาณิชย์
          โดยมีสำนักงานที่จดทะเบียนตั้งอยู่ที่ 34/5 Moo1, Chaengwattana Road,
          Klongklue, Pakkred, Nonthaburi 11120 ผู้ที่อาจเป็นลูกค้า ผู้บริโภค
          ตัวแทนจำหน่าย และช่างทาสี รวมเรียกว่า “ผู้ใช้” การอ้างอิงใดๆ ถึง “คุณ”
          หรือ “ของคุณ” เป็นการอ้างอิงถึงผู้ใช้หรือผู้เข้าชมเว็บไซต์
          (แล้วแต่กรณี) และการอ้างอิงใดๆ ถึง “เรา” และ “ของเรา”
          จะอ้างอิงถึงอั๊คโซ่ โนเบล
        </BodyText>
        <LegacyHeaderText>
          การเปลี่ยนแปลงข้อกำหนดฉบับนี้และแพลตฟอร์ม
        </LegacyHeaderText>
        <BodyText>
          เราอาจแก้ไขข้อกำหนดฉบับนี้เมื่อใดก็ได้ด้วยการเปลี่ยนแปลงหน้านี้
          โปรดตรวจสอบหน้านี้เป็นระยะๆ เพื่อตรวจดูการเปลี่ยนแปลงใดๆ
          ที่เราได้ดำเนินการไป เนื่องจากมีผลผูกพันกับคุณ
          เราอาจเปลี่ยนแปลงลักษณะของแพลตฟอร์ม เพิ่มหรือลบฟังก์ชันหรือฟีเจอร์
          หรือหยุดให้บริการแพลตฟอร์มหรือฟีเจอร์ของแพลตฟอร์มแก่คุณหรือผู้ใช้ทั่วไปโดยไม่แจ้งให้ทราบล่วงหน้า
        </BodyText>
        <LegacyHeaderText>การเปลี่ยนแปลงของเว็บไซต์</LegacyHeaderText>
        <BodyText>
          เราอาจอัปเดตเว็บไซต์เป็นระยะๆ และอาจเปลี่ยนแปลงเนื้อหาเมื่อใดก็ได้
          ในข้อกำหนดฉบับนี้ คำว่า (“เนื้อหา”) หมายถึงการออกแบบ แผนผัง ข้อความ
          รูปภาพ กราฟิก เสียง วิดีโอ ฯลฯ หรือข้อมูล เนื้อหา หรือไฟล์อื่นๆ
          รวมถึงแต่ไม่จำกัดเฉพาะชื่อหรือข้อมูลที่คล้ายกัน
          (ทั้งหมดไม่ว่าจะเกี่ยวข้องกับคุณหรือบุคคลภายนอก) หรือเนื้อหาอื่นๆ
          ที่อัปโหลดโดยผู้ใช้ (ในฐานะผู้ให้ข้อมูล)
          ผู้ให้บริการที่เป็นบุคคลภายนอก หรือโดยเรา อย่างไรก็ตาม
          โปรดทราบว่าเนื้อหาใดๆ
          บนเว็บไซต์อาจไม่เป็นปัจจุบันเมื่อใดก็ได้และเราไม่มีหน้าที่ต้องอัปเดตเนื้อหานี้
          เราไม่รับประกันว่าเว็บไซต์หรือเนื้อหาใดๆ
          บนเว็บไซต์จะปราศจากข้อผิดพลาดหรือการละเว้น
        </BodyText>
        <LegacyHeaderText>การเข้าถึงเว็บไซต์</LegacyHeaderText>
        <BodyText>
          เราไม่รับประกันว่าการใช้งานเว็บไซต์ของคุณหรือเนื้อหาใดๆ
          บนเว็บไซต์จะพร้อมให้บริการหรือไม่ติดขัดเสมอ
          อนุญาตให้เข้าถึงเว็บไซต์ได้โดยชั่วคราว เราอาจระงับ ถอน หยุด
          หรือเปลี่ยนแปลงเว็บไซต์ส่วนใดส่วนหนึ่งหรือทั้งหมดโดยไม่มีการแจ้งให้ทราบ
          เราจะไม่รับผิดชอบต่อคุณหากไม่ว่าด้วยสาเหตุใดก็ตาม
          เว็บไซต์ไม่พร้อมให้บริการเมื่อใดก็ตามหรือเป็นระยะเวลาเท่าใดก็ตาม
        </BodyText>
        <LegacyHeaderText>ข้อตกลงของผู้ใช้</LegacyHeaderText>
        <BodyText>
          สิทธิในทรัพย์สินทางปัญญา นอกเหนือจากที่ระบุไว้อย่างชัดแจ้ง
          เราหรือกลุ่มบริษัทของเราเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาทั้งหมดในเว็บไซต์และในเนื้อหาที่เผยแพร่บนเว็บไซต์
          (รวมถึงแต่ไม่จำกัดเฉพาะข้อความ รูปภาพ เสียง วิดีโอ รหัส HTML และปุ่ม)
          (“เนื้อหา”) สิทธิเหล่านี้รวมถึงแต่ไม่จำกัดเฉพาะลิขสิทธิ์
          สิทธิในฐานข้อมูล ชื่อทางการค้า และเครื่องหมายการค้าทั้งหมด คุณสามารถดู
          สั่งพิมพ์
          และใช้เว็บไซต์และแบบสีเพื่อการใช้งานส่วนบุคคลเท่านั้นและไม่ใช่เพื่อการค้า
          เราและกลุ่มบริษัทของเราขอสงวนสิทธิ์โดยชัดแจ้งในทรัพย์สินทางปัญญาทั้งหมดในและต่อเว็บไซต์และเนื้อหา
          และการใช้งานเว็บไซต์และเนื้อหาของคุณจะอยู่ภายใต้ข้อจำกัดต่อไปนี้
          คุณต้องไม่: ลบเอกสารบอกกล่าวที่ระบุลิขสิทธิ์ใดๆ หรือกรรมสิทธิ์อื่นๆ
          ในเนื้อหา ใช้เนื้อหาใดๆ จากเว็บไซต์ในลักษณะใดๆ ที่อาจละเมิดลิขสิทธิ์
          สิทธิในทรัพย์สินทางปัญญา หรือสิทธิในการเป็นเจ้าของทรัพย์สินใดๆ
          ของเราหรือบุคคลภายนอกใดๆ หรือ ทำซ้ำ แก้ไข แสดง กระทำ ตีพิมพ์ แจกจ่าย
          เผยแพร่ ออกอากาศ สื่อสารกับสาธารณะหรือส่งต่อกับบุคคลภายนอกใดๆ
          หรือแสวงหาประโยชน์จากเว็บไซต์นี้และ/หรือเนื้อหาในลักษณะใดๆ
          รวมถึงเพื่อวัตถุประสงค์ทางการค้า
          โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากเรา
          เราขอสงวนสิทธิ์ทั้งหมดโดยชัดแจ้งในและต่อชื่อโดเมน
          www.duluxpreviewservice.com และโดเมนที่เกี่ยวข้องและโดเมนย่อยทั้งหมด
          ชื่อ "อั๊คโซ่ โนเบล” โลโก้ของเรา และเครื่องหมายบริการ ชื่อทางการค้า
          และ/หรือเครื่องหมายการค้าของเรา เครื่องหมายการค้า ผลิตภัณฑ์
          และชื่อบริษัทอื่นๆ
          ที่กล่าวถึงบนเว็บไซต์ของเราอาจเป็นเครื่องหมายการค้าของบริษัทหรือผู้ออกใบอนุญาตที่เกี่ยวข้อง
          และสิทธิในเครื่องหมายดังกล่าวเป็นของเจ้าของหรือผู้ออกใบอนุญาตที่เกี่ยวข้อง
          ความแม่นยำของสี
          เราได้พยายามทุกวิถีทางในการทำให้สีบนหน้าจอถูกต้องแม่นยำมากที่สุดเท่าที่จะเป็นไปได้
          อย่างไรก็ดี
          เราไม่สามารถรับประกันได้ว่าสีบนหน้าจอจะเหมือนกับสีของผลิตภัณฑ์จริงอย่างถูกต้องแม่นยำ
          และไม่ควรคาดหวังว่าสีที่แสดงผลบนหน้าจอจะมีความเที่ยงตรง
          สีบนหน้าจออาจแตกต่างกันไปขึ้นอยู่กับการตั้งค่าและความละเอียดของหน้าจอคุณ
          หากคุณไม่แน่ใจเกี่ยวกับสี โปรดอ้างอิง Dulux Colour Card
          ที่ร้านตัวแทนจำหน่ายเพื่อสีที่แม่นยำมากขึ้น
          ความถูกต้องของข้อมูลและเนื้อหา
          เนื้อหาและข้อมูลบนเว็บไซต์จัดให้บนเว็บไซต์ของเราโดยไม่มีค่าใช้จ่ายและมีวัตถุประสงค์เพื่อให้ข้อมูลเท่านั้นและไม่ได้สร้างความสัมพันธ์ในการให้บริการทางธุรกิจหรือวิชาชีพระหว่างคุณกับเรา
          เราได้ใช้ความพยายามตามสมควรในการตรวจสอบให้มั่นใจว่าข้อมูลและเนื้อหาทั้งหมดที่อยู่บนเว็บไซต์นี้มีความถูกต้อง
          ณ เวลาที่เพิ่มข้อมูล อย่างไรก็ดี
          อาจมีข้อผิดพลาดโดยไม่ได้ตั้งใจและเป็นครั้งคราว
          เว็บไซต์และข้อมูลและเนื้อหาบนเว็บไซต์ได้จัดให้ “ตามที่เป็นอยู่”
          เราไม่รับประกันและไม่ให้คำรับรองหรือคำรับประกันในทุกรูปแบบไม่ว่าจะโดยชัดแจ้งหรือโดยนัยก็ตามเกี่ยวกับข้อมูลและเนื้อหาที่จัดให้ผ่านเว็บไซต์ของเรา
          รวมถึงรายการใดๆ ที่ใช้งานไม่ว่าจะทางตรงหรือทางอ้อมจากเว็บไซต์ของเรา
          และเราขอสงวนสิทธิ์ในการเปลี่ยนแปลงและแก้ไขเมื่อใดก็ได้โดยไม่มีการแจ้งให้ทราบ
          ข้อคิดเห็นและข้อมูลที่โพสต์บนเว็บไซต์ไม่ได้มีเจตนาให้เปรียบเสมือนคำแนะนำที่ควรเชื่อถือ
          เราไม่รับผิดชอบต่อความไม่ถูกต้องหรือการละเว้นใดๆ
          ในเว็บไซต์และการตัดสินใจใดๆ
          จากข้อมูลและความคิดเห็นที่อยู่ในเว็บไซต์เป็นความรับผิดชอบของผู้ใช้แต่เพียงผู้เดียว
          การอัปโหลดหรือโพสต์เนื้อหาบนเว็บไซต์ของเรา
          เมื่อใดก็ตามที่คุณใช้งานฟีเจอร์ที่อนุญาตให้คุณอัปโหลดเนื้อหาบนเว็บไซต์ของเรา
          คุณต้องปฏิบัติตามมาตรฐานของเนื้อหาที่กำหนดไว้ในนโยบายการใช้งานที่ยอมรับได้ของเรา
          เรารับประกันว่าการให้ข้อมูลใดๆ ดังกล่าวปฏิบัติตามมาตรฐานเหล่านั้น
          และคุณจะต้องรับผิดชอบต่อเราและชดเชยค่าเสียหายให้กับเราสำหรับการละเมิดการรับประกันดังกล่าว
          ซึ่งหมายความว่าคุณจะต้องรับผิดชอบต่อความสูญเสียหรือความเสียหายใดๆ
          ที่เราได้รับอันเป็นผลมาจากการฝ่าฝืนการรับประกันของคุณ
          คุณได้ให้สิทธิการใช้งานแบบไม่ผูกขาด ต่อเนื่อง ไม่สามารถเพิกถอนได้
          สามารถถ่ายโอนให้ผู้อื่นได้ และไม่มีค่าลิขสิทธิ์
          (รวมถึงสิทธิอย่างเต็มที่ในการอนุญาตให้ใช้สิทธิช่วง)
          แก่เราในการใช้เนื้อหาใดๆ ที่คุณอัปโหลดหรือโพสต์บนเว็บไซต์ของเรา
          (“เนื้อหาของคุณ”) (รวมถึงแต่ไม่จำกัดเฉพาะสิทธิในการดัดแปลง ปรับเปลี่ยน
          แก้ไข หรือเปลี่ยนแปลงเนื้อหาของคุณ) ในสื่อใดๆ ทั่วโลกโดยไม่จำกัด
          และคุณรับประกันว่าเนื้อหาใดๆ
          ที่คุณส่งเป็นผลงานของคุณเองหรือคุณได้รับสิทธิและการอนุญาตที่จำเป็นทั้งหมดจากเจ้าของที่เกี่ยวข้อง
          และคุณมีสิทธิที่เกี่ยวข้องทั้งหมดในเนื้อหาของคุณในการทำให้คุณสามารถให้สิทธิในข้อนี้ได้
          คุณได้สละสิทธิโดยไม่มีเงื่อนไขและไม่สามารถเพิกถอนได้และยอมรับที่จะไม่อ้างสิทธิทางศีลธรรมใดๆ
          ทั้งหมดและสิทธิที่คล้ายคลึงอื่นใดและสิทธิสาธารณะและความเป็นส่วนตัวทั้งหมดในประเทศใดๆ
          ในโลกที่เกี่ยวข้องกับการแสวงหาประโยชน์ของเราจากสิทธิในเนื้อหาของคุณที่ได้ให้ไว้ภายใต้ข้อกำหนดนี้ในขอบเขตสูงสุดเท่าที่กฎหมายอนุญาต
          การเผยแพร่เนื้อหาของคุณจะขึ้นอยู่กับดุลยพินิจของเราแต่เพียงผู้เดียวและเรามีสิทธิที่จะเพิ่มหรือลบเนื้อหาใดๆ
          ดังกล่าวก่อนการเผยแพร่ หลังการเผยแพร่ หรือปฏิเสธการเผยแพร่
          หากเนื้อหาของคุณมีข้อความใดๆ ที่คุณไม่ได้เป็นเจ้าของหรือมีสิทธิใช้งาน
          และ/หรืออยู่ภายใต้สิทธิของบุคคลภายนอก
          คุณต้องรับผิดชอบต่อการได้รับการสละสิทธิ์ ความยินยอม
          และ/หรือสิทธิการใช้งานที่จำเป็นทั้งหมดก่อนการส่งเนื้อหาของคุณ
          ในการอนุญาตให้เราใช้งานและแสวงหาประโยชน์จากเนื้อหาของคุณตามข้อกำหนดการใช้งานเหล่านี้โดยไม่มีค่าใช้จ่ายเพิ่มเติม
          แม้ว่าเราจะไม่ได้มีหน้าที่ควบคุมเนื้อหาบนเว็บไซต์ของเรา
          เราขอสงวนสิทธิ์ในการกำกับดูแลข้อมูลใดๆ ที่ส่งหรือรับผ่านเว็บไซต์ของเรา
          เราขอสงวนสิทธิ์ในการปฏิเสธ ปิดกั้น ระงับ
          หรือลบเนื้อหาของคุณเมื่อใดก็ได้และด้วยดุลยพินิจของเราแต่เพียงผู้เดียว
          เราไม่รับประกันอย่างเด็ดขาดว่าเนื้อหาของคุณหรือส่วนใดๆ
          ของเนื้อหาจะแสดงอยู่บนเว็บไซต์ของเรา
          คุณรับรองว่าคุณมีสิทธิชอบด้วยกฎหมายที่จะส่งข้อมูลดังกล่าวและยอมรับว่าคุณจะไม่ส่งข้อมูลใดๆ
          เว้นแต่คุณมีสิทธิตามกฎหมายให้ดำเนินการได้ เรามีสิทธิที่จะใช้ คัดลอก
          แจกจ่าย และเปิดเผยเนื้อหาใดๆ
          ดังกล่าวต่อบุคคลภายนอกด้วยวัตถุประสงค์ใดก็ตาม
          และเรายังมีสิทธิที่จะเปิดเผยตัวตนของคุณต่อบุคคลภายนอกที่อ้างว่าข้อความใดๆ
          ที่คุณโพสต์หรืออัปโหลดบนเว็บไซต์ของเราเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญาหรือสิทธิความเป็นส่วนตัวของบุคคลดังกล่าว
          เราจะไม่รับผิดชอบหรือรับผิดต่อบุคคลภายนอก
          สำหรับเนื้อหาหรือความถูกต้องของข้อความใดๆ
          ที่โพสต์โดยคุณหรือผู้ใช้อื่นใดของเว็บไซต์เรา
          เรามีสิทธิที่จะลบเนื้อหาหรือการโพสต์ใดๆ
          ที่คุณได้ดำเนินการบนเว็บไซต์ของเราโดยขึ้นอยู่กับดุลยพินิจของเราแต่เพียงผู้เดียว
          เราไม่ยอมรับการส่งแนวคิดที่ไม่ได้รับอนุญาตนอกเหนือจากความสัมพันธ์ทางธุรกิจที่มีีอยู่
          โปรดอย่าสื่อสารเกี่ยวกับการส่งแนวคิดที่ไม่ได้รับอนุญาตให้กับเราผ่านเว็บไซต์นี้
          แนวคิดใดๆ
          ที่เปิดเผยกับเรานอกเหนือจากความสัมพันธ์ทางธุรกิจที่มีอยู่ก่อนหน้านี้และบันทึกไว้เป็นความลับจะไม่เป็นข้อมูลลับและเราจึงมีสิทธิที่จะพัฒนา
          ใช้ คัดลอก
          และ/หรือแสวงหาประโยชน์ทางการค้าจากแนวคิดเหล่านี้หรือที่คล้ายคลึงกันนี้ภายใต้ขอบเขตสูงสุดและไม่ชดเชยให้คุณหรือรับผิดชอบต่อคุณ
          จากการส่งแนวคิดหรือการส่งรายละเอียดอื่นๆ ให้กับเราผ่านเว็บไซต์นี้
          คุณได้ยอมรับที่จะมีผลผูกพันตามข้อกำหนดของนโยบายที่ระบุไว้นี้
        </BodyText>
        <LegacyHeaderText>1. การลงทะเบียน</LegacyHeaderText>
        <BodyText>
          เว็บไซต์นี้สามารถใช้งานได้เฉพาะผู้ใช้ที่ลงทะเบียนแล้ว
          การลงทะเบียนจะสามารถดำเนินการได้กับลูกค้าที่มีความสามารถทางกฏหมายในการทำสัญญา
          ผู้ใช้สามารถเป็นผู้ใช้ที่ลงทะเบียนแล้วได้ด้วยการกรอกแบบฟอร์มการลงทะเบียนออนไลน์บนเว็บไซต์
          โดยให้ข้อมูลพื้นฐาน (ชื่อ ข้อมูลติดต่อ ที่อยู่บ้านหรือสำนักงาน ฯลฯ)
          อั๊คโซ่ โนเบลจะสร้างบัญชี (“บัญชี”)
          สำหรับผู้ใช้แต่ละรายเมื่อลงทะเบียนและกำหนดนามแฝงของผู้ใช้
          (“รหัสผู้ใช้”) และรหัสผ่าน (“รหัสผ่าน”)
          สำหรับการเข้าสู่ระบบบัญชีของคุณ นอกจากนี้ อั๊คโซ่
          โนเบลจะมอบบัญชีอีเมล/ข้อความบนเว็บให้กับผู้ใช้เมื่อลงทะเบียน
          พร้อมพื้นที่จัดเก็บข้อมูลที่จำกัดสำหรับการส่งหรือรับอีเมล/ข้อความ
          การสื่อสารทั้งหมดระหว่างช่างทาสีกับผู้บริโภคสามารถเริ่มต้นโดยผู้บริโภคเท่านั้น
          ผู้บริโภคไม่ได้รับอนุญาตให้ส่งข้อความให้กับผู้ใช้อื่นๆ
          ผู้ใช้จะต้องรับผิดชอบต่อเนื้อหาของข้อความทั้งหมดที่สื่อสารผ่านบัญชีของตน
          เช่นเดียวกับผลสืบเนื่องของข้อความใดๆ ดังกล่าว
          คุณสามารถทบทวนข้อมูลส่วนบุคคลที่คุณได้มอบให้เราและดำเนินการเปลี่ยนแปลงใดๆ
          ที่ต้องการต่อข้อมูลหรือต่อการตั้งค่าบัญชีของคุณบนแพลตฟอร์มนี้เมื่อใดก็ได้โดยการเข้าสู่ระบบบัญชีของคุณและแก้ไขข้อมูลบนหน้าโปรไฟล์ของคุณ
          คุณซึ่งเป็นผู้บริโภครับรองและรับประกันว่าหากคุณเป็นบุคคลธรรมดา (1)
          คุณมีอายุเกินสิบแปดปีหรือเกินข้อจำกัดทางอายุที่กฎหมายในประเทศที่เกี่ยวข้องอนุญาตให้ทำสัญญาได้
          หรือ (2) หากคุณกำลังลงทะเบียนในนามของนิติบุคคล
          คุณได้รับอนุญาตให้เข้าทำและทำให้นิติบุคคลมีผลผูกพันตามข้อกำหนดฉบับนี้และลงทะเบียนในแพลตฟอร์มตามกฎหมายไทย
          บุคคลใดที่อายุต่ำกว่าสิบแปด (18)
          ปีที่เข้าถึงเว็บไซต์ควรดำเนินการภายใต้คำแนะนำของผู้ปกครองเท่านั้น
          หากเราเปลี่ยนแปลงเกณฑ์คุณสมบัติในการลงทะเบียนเว็บไซต์และคุณไม่ได้ปฏิบัติตามเกณฑ์คุณสมบัติใหม่นี้อีกต่อไปตามที่เราพิจารณาด้วยดุลยพินิจของเราแต่เพียงผู้เดียว
          คุณยอมรับว่าเราอาจปิดบัญชีของคุณโดยที่เราไม่ต้องรับผิดชอบใดๆ
          คุณมีหน้าที่รับผิดชอบแต่เพียงผู้เดียวในการตรวจสอบให้แน่ใจว่าข้อกำหนดฉบับนี้เป็นไปตามกฎหมาย
          กฎเกณฑ์ และข้อบังคับที่มีผลบังคับใช้กับคุณทั้งหมด
          และสิทธิในการเข้าถึงแพลตฟอร์มจะถูกยกเลิกเมื่อข้อกำหนดฉบับนี้หรือการใช้งานแพลตฟอร์มไม่ได้รับอนุญาต
          และในสถานการณ์ดังกล่าวคุณยอมรับว่าจะไม่ใช้งานหรือเข้าถึงเว็บไซต์หรือแพลตฟอร์มด้วยวิธีใดก็ตาม
          หากคุณซึ่งเป็นผู้บริโภคลงทะเบียนในนามขององค์กรธุรกิจ
          คุณรับรองว่าองค์กรธุรกิจและ (ก)
          คุณมีอำนาจที่จะทำให้องค์กรมีผลผูกพันตามข้อกำหนดและเงื่อนไขการใช้งานและ/หรือข้อตกลงนี้
          (ข)
          ที่อยู่ที่คุณใช้เมื่อลงทะเบียนคือสถานที่ประกอบการขององค์กรธุรกิจดังกล่าว
          และ (ค) ข้อมูลอื่นๆ ทั้งหมดที่ส่งให้กับอั๊คโซ่
          โนเบลระหว่างกระบวนการลงทะเบียนเป็นความจริง ถูกต้อง เป็นปัจจุบัน
          และสมบูรณ์ เมื่อเป็นผู้ใช้ที่ลงทะเบียนแล้ว
          คุณได้ยินยอมให้รวมข้อมูลส่วนบุคคลของคุณในฐานข้อมูลออนไลน์ของเราและมอบอำนาจให้อั๊คโซ่
          โนเบลแบ่งปันข้อมูลดังกล่าวกับพันธมิตรและบริษัทในเครือตามนโยบายความเป็นส่วนตัวของเรา
          คุณยอมรับว่าอั๊คโซ่
          โนเบลอาจระงับหรือยุติบัญชีของคุณเมื่อใดก็ได้โดยไม่แจ้งให้ทราบล่วงหน้าหรือโดยไม่ให้เหตุผลใดๆ
        </BodyText>
        <LegacyHeaderText>2. ข้อกำหนดสุดท้าย</LegacyHeaderText>
        <BodyText>
          ผู้ใช้จะตรวจสอบให้แน่ใจว่าเนื้อหาใดๆ
          ที่เผยแพร่หรืออัปโหลดบนเว็บไซต์ต้องไม่:
          <ul>
            <li>ทำให้เข้าใจผิดไม่ว่าทางใดก็ตาม;</li>
            <li>
              มีวิดีโอ ภาพถ่าย หรือภาพของบุคคลอื่น
              (โดยไม่ได้รับอนุญาต/ความยินยอมโดยสมัครใจของบุคคลนั้น);
            </li>
            <li>มีเนื้อหาใดๆ ซึ่งเป็นการหมิ่นประมาทบุคคลใดก็ตาม;</li>
            <li>
              มีเนื้อหาใดๆ ที่หยาบโลน ไม่ให้เกียรติ แสดงความเกลียดชัง
              หรือยั่วยุ;
            </li>
            <li>
              เป็นอันตราย ล่วงละเมิด ดูหมิ่นเหยียดหยาม หมิ่นประมาท หยาบโลน
              ลามกอนาจาร ใคร่เด็ก กล่าวร้าย ล่วงล้ำความเป็นส่วนตัวของผู้อื่น
              แสดงความเกลียดชังอย่างน่ารังเกียจ
              หรือก่อให้เกิดความบาดหมางทางเชื้อชาติและชาติพันธุ์ ดูหมิ่น
              เกี่ยวข้องหรือส่งเสริมการฟอกเงินหรือการพนัน
              หรือไม่ชอบด้วยกฎหมายไม่ว่าในลักษณะใดก็ตาม;
            </li>
            <li>ละเมิดสิทธิในทรัพย์สินทางปัญญาใดๆ ของบุคคลอื่นใด;</li>
            <li>มีแนวโน้มที่จะเป็นอันตรายต่อผู้เยาว์ไม่ว่าทางใดก็ตาม</li>
            <li>มีแนวโน้มที่จะหลอกลวงบุคคลใดๆ;</li>
            <li>
              จัดทำขึ้นโดยละเมิดหน้าที่ทางกฎหมายใดๆ ของบุคคลภายนอก เช่น
              หน้าที่ตามสัญญาหรือหน้าที่รักษาความไว้วางใจ;
            </li>
            <li>ขัดขวางไม่ให้มีการสอบสวนความผิดใดๆ;</li>
            <li>ดูถูกประเทศอื่นใด;</li>
            <li>
              คุกคาม ใช้ในทางที่ผิด หรือล่วงล้ำความเป็นส่วนตัวของผู้อื่น
              หรือก่อความรำคาญ ความไม่สะดวก หรือความวิตกกังวลโดยไม่จำเป็น;
            </li>
            <li>
              มีแนวโน้มที่จะล่วงละเมิด ทำให้เสียเกียรติ กลัดกลุ้ม อับอาย
              ตื่นตระหนก หรือก่อกวนบุคคลอื่นใด;
            </li>
            <li>
              ถูกนำมาใช้เพื่อปลอมเป็นบุคคลใดๆ
              หรือบิดเบือนตัวตนของคุณหรือความเกี่ยวเนื่องกับบุคคลใดๆ;
            </li>
            <li>
              ทำให้เข้าใจว่าเนื้อหามาจากอั๊คโซ่ โนเบล หากไม่ได้เป็นเช่นนั้น;
            </li>
            <li>
              สนับสนุน ส่งเสริม หรือช่วยเหลือการกระทำที่ไม่ชอบด้วยกฎหมาย เช่น
              (เพื่อแสดงเป็นตัวอย่างเท่านั้น)
              การละเมิดลิขสิทธิ์หรือการใช้คอมพิวเตอร์ในทางมิชอบ;
            </li>
            <li>
              อ้างอิงถึงเว็บไซต์ แอป หรือ URL ใดๆ
              ซึ่งภายใต้ดุลยพินิจของเราแต่เพียงผู้เดียว
              มีเนื้อหาที่ไม่เหมาะสมกับเว็บไซต์
              หรือเว็บไซต์อื่นใดที่มีเนื้อหาต้องห้ามหรือละเมิดลายลักษณ์อักษรหรือเจตนารมณ์ของข้อกำหนดการใช้งานเหล่านี้;
            </li>
            <li>
              รบกวนการใช้งานของผู้ใช้อื่นและการใช้งานของเว็บไซต์
              หรือผู้ใช้อื่นใดและการใช้บริการที่คล้ายคลึงกัน;
            </li>
            <li>
              ปลุกปั่นให้เกิดกิจกรรมใดๆ
              ซึ่งภายใต้ดุลยพินิจของเราแต่เพียงผู้เดียวเชื่อว่าผิดกฎหมายหรืออาจตีความได้ว่าผิดกฎหมาย;
            </li>
            <li>
              เกี่ยวข้องกับการส่ง “อีเมลขยะ” “จดหมายลูกโซ่”
              หรือการส่งอีเมลจำนวนมากที่ไม่ได้ร้องขอหรือ “สแปม”;
            </li>
            <li>
              เสนอ พยายามที่จะเสนอ ทำการค้าหรือพยายามทำการค้าสิ่งของใดๆ
              โดยตรงหรือโดยอ้อม
              ซึ่งเป็นการค้าขายต้องห้ามหรือจำกัดในลักษณะใดก็ตามภายใต้ข้อกำหนดของกฎหมาย
              กฎเกณฑ์ ข้อบังคับ หรือแนวปฏิบัติใดๆ ที่มีผลบังคับใช้ขณะนี้;
            </li>
            <li>
              ฝ่าฝืนหรือหลีกเลี่ยงมาตรการรักษาความปลอดภัยของเราหรือทดสอบช่องโหว่ด้านความปลอดภัยของระบบหรือเครือข่ายของเรา;
            </li>
            <li>ทดลองทำวิศวกรรมย้อนกลับกับซอฟต์แวร์ใดๆ ของบริษัท;</li>
            <li>
              รวบรวมหรือจัดเก็บข้อมูลที่สามารถระบุตัวตนของผู้ใช้หรือผู้เข้าชมใดๆ
              โดยไม่ได้รับอนุญาต;
            </li>
            <li>
              คุกคามความเป็นเอกภาพ บูรณภาพ ความมั่นคง ความปลอดภัย
              หรืออำนาจอธิปไตยของประเทศไทย;
            </li>
            <li>จำหน่ายชื่อผู้ใช้หรือถ่ายโอนเพื่อรับค่าตอบแทน; และ</li>
            <li>สนับสนุนหรือช่วยเหลือผู้อื่นกระทำสิ่งใดๆ ในรายการนี้ </li>
          </ul>
          อั๊คโซ่โนเบลได้ใช้ความพยายามตามสมควรในการตรวจสอบให้มั่นใจว่าแอปพลิเคชัน/เว็บไซต์ปราศจากไวรัส
          อย่างไรก็ตาม
          ไม่สามารถยืนยันหรือรับประกันทางเทคนิคหรือทางอื่นโดยชัดแจ้งหรือโดยนัยในแง่ที่ว่าแพลตฟอร์มนี้ปราศจากบั๊กและ/หรือไวรัส
          และผู้ใช้มีหน้าที่ตรวจสอบให้มั่นใจว่าได้ติดตั้งซอฟต์แวร์ป้องกันไวรัสที่เหมาะสม
          ผู้ใช้ต้องใช้แอปพลิเคชัน/เว็บไซต์อย่างเหมาะสมโดยผู้ใช้ต้องไม่นำไวรัส
          โทรจัน หนอนไวรัส ลอจิกบอมบ์
          หรือเนื้อหาที่เป็นอันตรายหรือเป็นภัยทางเทคโนโลยีใดๆ
          มาสู่แอปพลิเคชัน/เว็บไซต์
          ผู้ใช้ไม่ควรพยายามที่จะได้รับการเข้าถึงแอปพลิเคชัน/เว็บไซต์
          เซิร์ฟเวอร์ที่จัดเก็บแอปพลิเคชัน/เว็บไซต์หรือเซิร์ฟเวอร์ใดๆ
          คอมพิวเตอร์ หรือฐานข้อมูลใดๆ
          ที่เชื่อมต่อกับแพลตฟอร์มนี้โดยไม่ได้รับอนุญาต
          การละเมิดสิ่งนี้อาจเป็นความผิดทางอาญา อั๊คโซ่
          โนเบลจะรายงานการละเมิดใดๆ
          ต่อหน่วยงานบังคับใช้กฎหมายและร่วมมือกันโดยเปิดเผยชื่อของผู้ใช้
          หากเกิดการละเมิดใดๆ
          การเข้าถึงแอปพลิเคชัน/เว็บไซต์ของผู้ใช้จะถูกปิดกั้นทันที
          ภายใต้ขอบเขตที่กฎหมายอนุญาต อั๊คโซ่
          โนเบลปฏิเสธความรับผิดชอบหรือความรับผิดทั้งหมดต่อผู้ใช้ไม่ว่าลักษณะใดก็ตามที่เกิดขึ้นในสัญญา
          จากการละเมิดหรือรวมถึงแต่ไม่จำกัดเฉพาะความเสียหายหรือการสูญเสียกำไร/ธุรกิจโดยตรงหรือที่เป็นผลสืบเนื่องใดๆ
          ไม่ว่าเมื่อใดก็ตาม ผู้ใช้จะไม่ให้อั๊คโซ่
          โนเบลรับผิดชอบและชดเชยค่าเสียหายจากการเรียกร้องใดๆ
          ที่อาจเกิดขึ้นกับอั๊คโซ่
          โนเบลซึ่งเกิดขึ้นจากหรือเกี่ยวข้องกับการใช้งานแพลตฟอร์ม/เว็บไซต์
          ข้อกำหนดฉบับนี้ร่างเป็นภาษาอังกฤษและเป็นภาษาไทยตามที่จำเป็น
          หากข้อกำหนดฉบับนี้ได้รับการแปลเป็นภาษาใดๆ นอกเหนือจากภาษาอังกฤษ
          ให้ยึดข้อความภาษาอังกฤษเป็นหลัก
          ทุกข้อในข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งานฉบับนี้ควบคุมโดยกฎหมายที่มีผลบังคับใช้ในประเทศไทย
          สำหรับประเด็นทั้งหมดที่เกี่ยวข้องกับการแปล
          การปฏิบัติตามระเบียบข้อบังคับ
          หรือคำถามอื่นใดเกี่ยวกับข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งานเหล่านี้
          คู่สัญญายอมรับการตัดสินของศาลของสถานที่/เมืองที่อั๊คโซ่
          โนเบลมีสำนักงานที่จดทะเบียนตั้งอยู่ [จบ]
        </BodyText>
      </LegacyBody>
    </LegacyContainer>
  );
};

export default LegalBodyThailand;
