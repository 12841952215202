import styled from "styled-components";

export const AdminDashboardContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const DrawerVerticalDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;
