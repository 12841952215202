import styled from "styled-components";

export const ConsumerResultContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerOTPModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConsumerPersonlaDetailsModalBarDiv = styled.div`
  display: ${(props) => (props.personalDetails === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ConsumerResultHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100px;
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const RelaxImage = styled.img`
  margin: 0 auto;
  display: flex;
  width: 20vw;
  height: 20vw;
  @media (max-width: 1100px) {
    width: 200px;
    height: 200px;
  }
`;

export const SitBackText = styled.p`
  margin: 10px 0 0 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
`;

export const RelaxText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0;
  justify-content: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RecolourAgainButton = styled.button`
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
  margin: 0 20px;
  @media (max-width: 1100px) {
    margin: 20px;
  }
`;

export const BackToHomeButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const RecolorReferenceImageText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #4f4f4f;
  margin: 40px 0 0 0;
`;

export const TryThemeButton = styled.button`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  border: none;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

export const NextButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 4px 30px;
  display: flex;
  margin: 0 auto;
`;

export const ConsumerResultSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CancelImage = styled.img`
  width: 25px;
  height: 25px;
  align-self: flex-end;
  margin: 20px 20px 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const SideBarFooter = styled.div`
  background: #012169;
  height: 10px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const OneTimeOtpText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const ConsumResultNewOtpInput = styled.input`
  height: 50px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #000;
  letter-spacing: 3px;
  /* background-image: url(BG_IMAGE);
  background-repeat: repeat-x; */
  width: 256px;
  margin: 0 2px;
  &:focus {
    outline-color: #2fc38c;
  }
  align-self: center;
  margin: 10px 0;
`;

export const ResendOtpButton = styled.button`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #c4c4c4;
  outline: none;
  border: none;
  background-color: #fff;

  :hover {
    cursor: pointer;
  }
`;

export const OtpDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerifyEmailButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  display: flex;
  margin: 20px auto;
  padding: 2px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ConsumerOtpInputDiv = styled.form`
  display: flex;
  flex-direction: row;
  margin: 20px 0 4px 0;
  align-self: center;
`;

export const ConsumerResultOtpInput = styled.input`
  width: 15px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #000;
  margin: 0 2px;
  &:focus {
    outline-color: #2fc38c;
  }
`;

export const ConsumerResultPersonalSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.personalDetails === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PersonalDetailsDiv = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const EmailVerificationText = styled.h4`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const MoreDetailsText = styled.p`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #767676;
  margin: 0;
`;

export const LoginButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  width: 100px;
  padding: 4px 0;
  display: flex;
  margin: 20px auto;
  align-self: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const LocateMeButton = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  margin: auto 0px auto 40px;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    font-size: 6px;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
