/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import {
  PreviewSupervisorConsumerResultContainer,
  PreviewSupervisorConsumerResultHeader,
  DeluxePreviewServiceImage,
  LogoutButton,
  FooterDiv,
  HeaderHorizontalRuler,
  ConsumerResultBodyContainer,
  ConsumerResultContainer,
  BottomRow,
  HorizontalRuler,
  ConfirmSendButton,
  ConsumerResultText,
  DownloadButton,
  BackButton,
  VerticalDivider,
  ErrorMessage,
} from "../../styles/PreviewSupervisorConsumerResultStyles";
import Cookies from "js-cookie";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import {
  ACCESS_KEY_ID,
  BUCKET_NAME,
  HOME_PAGE_ROUTE,
  PREVIEW_STAFF_DASHBOARD_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
  SECRET_ACCESS_KEY,
  TOKEN_NAME,
  ADMIN_DASHBOARD_ROUTE,
} from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import AdminResultSideBar from "../../components/admin/AdminResultSideBar";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import ConsumerResultSteps from "../../components/ps-admin-consumer-result/ConsumerResultSteps";
import ConsumerResultImage from "../../components/ps-admin-consumer-result/ConsumerResultImage";
import ConsumerResultOriginal from "../../components/ps-admin-consumer-result/ConsumerResultOriginal";
import ConsumerResultModal from "../../components/ps-admin-consumer-result/ConsumerResultModal";
import AWS from "aws-sdk";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { default as Back } from "../../components/BackButton";
import ResultRecommendedProducts from "../../components/result/ResultRecommendedProducts";
import ResultNearByStores from "../../components/result/ResultNearByStores";
import ResultPainters from "../../components/result/ResultPainters";
import LanguageSelector from "../../language/LanguageSelector";

const AdminPSConsumerResult = ({ history, location }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [showModal, setShowModal] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(0);
  const [openResultMenu, setOpenResultMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedShopList, setSelectedShopList] = useState([]);
  const [selectedPainterList, setSelectedPainterList] = useState([]);

  useEffect(() => {
    for (let i = 0; i < location.state.selectedProducts.length; i++) {
      const filterTemp = location.state.productList.filter(
        (item) => item.product_id === location.state.selectedProducts[i]
      );
      if (filterTemp.length > 0) {
        setSelectedProducts((oldArray) => [...oldArray, filterTemp[0]]);
      }
    }

    for (let i = 0; i < location.state.selectedStores.length; i++) {
      const filterTemp = location.state.shopList.filter(
        (item) => item.shop_id === location.state.selectedStores[i]
      );
      if (filterTemp.length > 0) {
        setSelectedShopList((oldArray) => [...oldArray, filterTemp[0]]);
      }
    }

    for (let i = 0; i < location.state.selectedPainters.length; i++) {
      const filterTemp = location.state.painterList.filter(
        (item) => item.painter_id === location.state.selectedPainters[i]
      );
      if (filterTemp.length > 0) {
        setSelectedPainterList((oldArray) => [...oldArray, filterTemp[0]]);
      }
    }
  }, []);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleResultMenuDrawer = () =>
    setOpenResultMenu((previousState) => !previousState);
  const toggleModal = () => setShowModal((previousState) => !previousState);

  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const { resolveRequest } = useContext(PreviewSupervisorContext);

  const values = previewSupervisorDetailContext.previewSupervisorDetails;

  const nextModalImageClickHandler = () => {
    if (selectedModalIndex < values?.selectedImage.length - 1) {
      setSelectedModalIndex(selectedModalIndex + 1);
    }
  };

  const previousModalImageClickHandler = () => {
    if (selectedModalIndex > 0) {
      setSelectedModalIndex(selectedModalIndex - 1);
    }
  };

  const resolveRequestSuccessCallback = () => {
    setErrorMessage(null);
    if (decodedToken.user_type === "Preview Staff") {
      history.push(PREVIEW_STAFF_DASHBOARD_ROUTE);
    } else if (decodedToken.user_type === "Preview Supervisor") {
      history.push(PREVIEW_SUPERVISOR_DASHBOARD_ROUTE);
    } else if (decodedToken.user_type === "Super Admin") {
      history.push(ADMIN_DASHBOARD_ROUTE);
    } else {
      setErrorMessage(
        "Looks like you are not a preview supervisor, preview staff or super admin"
      );
    }
  };

  const resolveRequestFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const confirmClickHandler = () => {
    resolveRequest({
      request_id: values.request_id,
      results: values.selectedImage,
      shop_id: location.state.selectedStores,
      product_id: location.state.selectedProducts,
      painter_id: location.state.selectedPainters,
      resolveRequestSuccessCallback,
      resolveRequestFailureCallback,
    });
  };

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const downloadImage = (fileName) => {
    s3.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: fileName,
      },
      function (error, data) {
        if (error != null) {
          alert(`${error.name} ${error.originalError}  ${error.message}`);
        } else {
          let blob = new Blob([data.Body], { type: data.ContentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "file.jpeg";
          link.click();
        }
      }
    );
  };

  const downloadClickHandler = () => {
    for (var i = 0; i < values?.selectedImage.length; i++) {
      const fileName = values?.selectedImage[i].result_image
        .split("amazonaws.com/")[1]
        .split("?")[0];
      downloadImage(fileName);
      // amarisServer
      //   .get(values?.selectedImage[i].result_image, {
      //     responseType: "blob",
      //   })
      //   .then((response) => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.jpeg"); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch((err) => {
      //     alert(err);
      //   });
    }
  };

  const downloadSingleImageHandler = (imageUrl) => {
    window.open(imageUrl);
    // amarisServer
    //   .get(imageUrl, {
    //     responseType: "blob",
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "file.jpeg"); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };

  const viewDetailHandler = (index) => {
    setSelectedItem(index);
    toggleResultMenuDrawer();
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBack = () => {
    history.goBack();
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <AdminResultSideBar
        openResultMenu={openResultMenu}
        toggleResultMenuDrawer={toggleResultMenuDrawer}
        results={values?.selectedImage}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        downloadSingleImageHandler={downloadSingleImageHandler}
        width={width}
      />
      <PreviewSupervisorConsumerResultContainer
        applyFilter={openResultMenu || openLogoutMenu || showModal}
      >
        <PreviewSupervisorConsumerResultHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LOGOUT"}
            /></LogoutButton>
        </PreviewSupervisorConsumerResultHeader>
        <HeaderHorizontalRuler />
        <ConsumerResultBodyContainer>
          {width > 1100 && <ConsumerResultSteps />}
          {width > 1100 && <VerticalDivider />}
          {width > 1100 && <Back goBackHandler={goBack} />}
          <ConsumerResultContainer>
            <ConsumerResultText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULTS"}
            /></ConsumerResultText>

            <ConsumerResultImage
              values={values}
              toggleModal={toggleModal}
              viewDetailHandler={viewDetailHandler}
              setSelectedModalIndex={setSelectedModalIndex}
            />

            <ConsumerResultOriginal values={values} />
            <HorizontalRuler />
            <BottomRow>
              <ResultRecommendedProducts list={selectedProducts} />
              <ResultNearByStores list={selectedShopList} />
              <ResultPainters list={selectedPainterList} />
            </BottomRow>
            <FooterDiv>
              <BackButton onClick={goBack}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
              <DownloadButton onClick={downloadClickHandler}>
              <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            />
              </DownloadButton>
              <ConfirmSendButton onClick={confirmClickHandler}>
                {" "}
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CONFIRM_AND_SEND"}
            /> 
              </ConfirmSendButton>
            </FooterDiv>
            {errorMessage != null && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </ConsumerResultContainer>
        </ConsumerResultBodyContainer>
      </PreviewSupervisorConsumerResultContainer>
      {showModal && (
        <ConsumerResultModal
          toggleModal={toggleModal}
          previousModalImageClickHandler={previousModalImageClickHandler}
          values={values}
          selectedModalIndex={selectedModalIndex}
          downloadSingleImageHandler={downloadSingleImageHandler}
          nextModalImageClickHandler={nextModalImageClickHandler}
          width={width}
        />
      )}
    </>
  );
};

export default AdminPSConsumerResult;
