import React from "react";
import { Link } from "react-router-dom";
import {
  CookieContainer,
  CookieHeader,
  DeluxePreviewServiceImage,
  CookiePolicyHeaderText,
  CookieBody,
  CookiePolicyText,
  InformationText,
  BodyText,
  BoldBodyText,
  BoldBodySpan,
  TableData,
  TableHeader,
  Table,
  TableDataLink,
} from "../../styles/CookieStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";

const CookieBodyEnglish = () => {
  return (
    <CookieContainer>
      <CookieBody>
        <CookiePolicyHeaderText>Cookie Policy</CookiePolicyHeaderText>
        <CookiePolicyText>Cookie Policy</CookiePolicyText>
        <InformationText>Information about our use of cookies</InformationText>
        <BodyText>
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and also allows us to improve our site. By
          continuing to browse the site, you are agreeing to our use of cookies.
          <br />
          <br />A cookie is a small file of letters and numbers that we store on
          your browser or the hard drive of your computer if you agree. Cookies
          contain information that is transferred to your computer's hard drive.
          <br />
          <br />
          We use the following cookies:
        </BodyText>
        <ul>
          <li>
            <BoldBodyText>Strictly necessary cookies. </BoldBodyText>
            <BoldBodySpan>
              These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to log
              into secure areas of our website, use a shopping cart or make use
              of e-billing services.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Analytical/performance cookies. </BoldBodyText>
            <BoldBodySpan>
              They allow us to recognise and count the number of visitors and to
              see how visitors move around our website when they are using it.
              This helps us to improve the way our website works, for example,
              by ensuring that users are finding what they are looking for
              easily.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Functionality cookies. </BoldBodyText>
            <BoldBodySpan>
              These are used to recognise you when you return to our website.
              This enables us to personalise our content for you, greet you by
              name and remember your preferences (for example, your choice of
              language or region).
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Targeting cookies. </BoldBodyText>
            <BoldBodySpan>
              These cookies record your visit to our website, the pages you have
              visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on
              it more relevant to your interests. We may also share this
              information with third parties for this purpose.
            </BoldBodySpan>
          </li>
        </ul>
        <BodyText>
          You can find more information about the individual cookies we use and
          the purposes for which we use them in the table below:
        </BodyText>

        <Table>
          <thead>
            <tr>
              <TableHeader>Cookie</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Purpose</TableHeader>
              <TableHeader>More Information</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableData>__utma</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                Used to distinguish users and sessions. The cookie is created
                when the javascript library executes and no existing __utma
                cookies exists. The cookie is updated every time data is sent to
                Google Analytics.
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  Google Analytics Cookie Usage on Websites
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  Google Analytics Privacy Overview
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmb</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                Used to determine new sessions/visits. The cookie is created
                when the javascript library executes and no existing __utmb
                cookies exists. The cookie is updated every time data is sent to
                Google Analytics.
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  Google Analytics Cookie Usage on Websites
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  Google Analytics Privacy Overview
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmc</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                Not used in ga.js. Set for interoperability with urchin.js.
                Historically, this cookie operated in conjunction with the
                __utmb cookie to determine whether the user was in a new
                session/visit.
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  Google Analytics Cookie Usage on Websites
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  Google Analytics Privacy Overview
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmz</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                Stores the traffic source or campaign that explains how the user
                reached your site. The cookie is created when the javascript
                library executes and is updated every time data is sent to
                Google Analytics.
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  Google Analytics Cookie Usage on Websites
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  Google Analytics Privacy Overview
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmv</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                Used to store visitor-level custom variable data. This cookie is
                created when a developer uses the _setCustomVar method with a
                visitor level custom variable. This cookie was also used for the
                deprecated _setVar method. The cookie is updated every time data
                is sent to Google Analytics.
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  Google Analytics Cookie Usage on Websites
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  Google Analytics Privacy Overview
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>message</TableData>
              <TableData>Facebook</TableData>
              <TableData>
                This cookie is used to identify a user as logged in or not. it
                contains a token that can be used to validating the state of the
                user.
              </TableData>
              <TableData></TableData>
            </tr>

            <tr>
              <TableData>akzo-dulux</TableData>
              <TableData>Website</TableData>
              <TableData>
                The cookie is used to hold the state of the users scrap book
                while they are logged out.
              </TableData>
              <TableData></TableData>
            </tr>

            <tr>
              <TableData>cc_cookie_accept</TableData>
              <TableData>Cookie Acceptance</TableData>
              <TableData>
                This cookie is used to store the state of whether the user
                accept the use of cookies.
              </TableData>
              <TableData></TableData>
            </tr>
          </tbody>
        </Table>

        <BodyText>
          Please note that third parties (including, for example, advertising
          networks and providers of external services like web traffic analysis
          services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting
          cookies.
          <br />
          <br />
          You block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          site.
          <br />
          <br />
          Except for essential cookies, all cookies will expire after 2 years.
        </BodyText>
      </CookieBody>
    </CookieContainer>
  );
};

export default CookieBodyEnglish;
