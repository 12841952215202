import React, { useState, useContext } from "react";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_DULUX_PREVIEW_ROUTE,
  HOME_PAGE_ROUTE,
  LEAD_COLOUR,
  TOKEN_NAME,
} from "../../utils/Constants";
import { ColourizationContext } from "../../context/ColourizationContext";
import {
  ConsumerChooseColourContainer,
  NextButton,
  TabDiv,
  ButtonDiv,
  TabDivButton,
  SelectedColorDiv,
  SuggestedPaletteSubTitle,
} from "../../styles/ConsumerChooseColorStyles";
import ConsumerImageOptionHeader from "../../components/consumer-image-options/ConsumerImageOptionHeader";
import BackButton from "../../components/BackButton";
import ConsumerChooseColourFamily from "../../components/consumer-choose-colour/ConsumerChooseColourFamily";
import ConsumerChooseColourDrawer from "../../components/consumer-choose-colour/ConsumerChooseColourDrawer";
import ConsumerChooseColourLeadColour from "../../components/consumer-choose-colour/ConsumerChooseColourLeadColour";
import ConsumerChooseColourPalette from "../../components/consumer-choose-colour/ConsumerChooseColourPalette";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const ConsumerChooseColour = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  let lowercase_country = decodedToken
  ? decodedToken?.country.charAt(0).toLowerCase() + decodedToken?.country.slice(1) : countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [openColourDrawer, setOpenColourDrawer] = useState(false);
  const [selectedColour, setSelectedColour] = useState(null);
  const [selectedColourFromDrawer, setSelectedColourFromDrawer] =
    useState(null);
  const [colorSuggestionList, setColorSuggestionList] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isFromSearch, setIsFromSearch] = useState(false);
  const [pakistanColor, setPakistanColor] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleColourDrawer = () =>
    setOpenColourDrawer((previousState) => !previousState);
  const usePakistanColor = () => setPakistanColor(true);
  const useNormalColor = () => setPakistanColor(false);

  const colourizationContext = useContext(ColourizationContext);
  const {
    // state,
    familyColour,
    familyColourSuggestion,
    searchColour,
  } = useContext(ConsumerContext);

  const { width } = useWindowDimensions();

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  const familyColourSuggestionSuccessCallback = (tempData) => {
    setColorSuggestionList(tempData);
  };

  const familyColourSuggestionFailureCallback = () => {};

  const getSuggesstionsHandler = (name) => {
    familyColourSuggestion({
      colour_name: name,
      familyColourSuggestionSuccessCallback,
      familyColourSuggestionFailureCallback,
    });
  };

  const searchColourSuccessCallback = (data) => {
    setSelectedColourFromDrawer(data);
    getSuggesstionsHandler(data.colour_name);
    setErrorText(false);
  };

  const searchColourFailureCallback = (msg) => {
    setErrorText(true);
    // alert(msg);
  };

  const searchColourHandler = () => {
    setIsFromSearch(true);
    searchColour({
      colour_name: searchText,
      searchColourSuccessCallback,
      searchColourFailureCallback,
    });
  };


  const colourList = [
    { name: "Warm Neutral", label: language["WARM_NEUTRAL"][lowercase_country], color: "rgb(191, 178, 143)" },
    { name: "White", label: language["WHITE"][lowercase_country], color: "rgb(219, 219, 205)" },
    { name: "Red", label: language["RED"][lowercase_country], color: "rgb(210, 61, 57)" },
    { name: "Orange", label: language["ORANGE"][lowercase_country], color: "rgb(229, 146, 58)" },
    { name: "Gold", label: language["GOLD"][lowercase_country], color: "rgb(247, 206, 72)" },
    { name: "Yellow", label: language["YELLOW"][lowercase_country], color: "rgb(252, 236, 84)" },
    { name: "Lime", label: language["LIME"][lowercase_country], color: "rgb(187, 204, 71)" },
    { name: "Green", label: language["GREEN"][lowercase_country], color: "rgb(88, 151, 75)" },
    { name: "Teal", label: language["TEAL"][lowercase_country], color: "rgb(89, 173, 159)" },
    { name: "Blue", label: language["BLUE"][lowercase_country], color: "rgb(79, 117, 159)" },
    { name: "Violet", label: language["VIOLET"][lowercase_country], color: "rgb(111, 83, 129)" },
    { name: "Cool Neutral", label: language["COOL_NEUTRAL"][lowercase_country], color: "rgb(143, 146, 147)" },
  ];

  const nextHandler = () => {
    const value = colourizationContext.colourizationDetails;
    if (
      value.raw_image === undefined ||
      value.colourization_type === undefined
    ) {
      alert("Upload image and choose a type");
    } else {
      colourizationContext.setColourizationDetails({
        raw_image: value.raw_image,
        colourization_type: LEAD_COLOUR,
        reference: "",
        theme: "",
        lead_colour:
          !isFromSearch && selectedColourFromDrawer !== null
            ? selectedColourFromDrawer.colour_name
            : searchText,
        email: value?.email || decodedToken?.email,
        name: "",
        city: "",
        country: "",
      });
      history.push(CONSUMER_DULUX_PREVIEW_ROUTE);
    }
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
      />
      {openColourDrawer && (
        <ConsumerChooseColourDrawer
          openSideMenu={openColourDrawer}
          toggleModal={toggleColourDrawer}
          width={width}
          familyColour={familyColour}
          selectedColour={selectedColour}
          setSelectedColourFromDrawer={setSelectedColourFromDrawer}
          getSuggesstionsHandler={getSuggesstionsHandler}
          selectedColourFromDrawer={selectedColourFromDrawer}
          setIsFromSearch={setIsFromSearch}
          colourList={colourList}
        />
      )}
      <ConsumerChooseColourContainer
        applyFilter={openLogoutMenu || openColourDrawer}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue
        }
      >
        <ConsumerImageOptionHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          goBackHandler={goBackHandler}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        {countryValue.value === "Pakistan" ? (
          <TabDiv>
            <ButtonDiv>
              <TabDivButton
                style={{
                  borderBottom: pakistanColor ? "white" : "1px solid #D3D3D3",
                }}
                onClick={useNormalColor}
              >
                CP5 Colours
              </TabDivButton>
              <TabDivButton
                style={{
                  borderBottom: pakistanColor ? "1px solid #D3D3D3" : "white",
                }}
                onClick={usePakistanColor}
              >
                Ready Mix Colours
              </TabDivButton>
            </ButtonDiv>
          </TabDiv>
        ) : null}
        <ConsumerChooseColourLeadColour
          colourList={colourList}
          toggleColourDrawer={toggleColourDrawer}
          setSearchText={setSearchText}
          setSelectedColour={setSelectedColour}
          searchText={searchText}
          searchColourHandler={searchColourHandler}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          pakistanColor={pakistanColor}
          setSelectedColourFromDrawer={setSelectedColourFromDrawer}
          familyColour={familyColour}
          selectedColourFromDrawer={selectedColourFromDrawer}
          getSuggesstionsHandler={getSuggesstionsHandler}
          errorText={errorText}
        />
        <SelectedColorDiv
          style={{
            marginTop: pakistanColor ? "10px" : "120px",
          }}
        >
          <div>
            {selectedColourFromDrawer !== null && (
              <ConsumerChooseColourFamily
                selectedColourFromDrawer={selectedColourFromDrawer}
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
              />
            )}
          </div>
          <div>
            {selectedColourFromDrawer !== null && colorSuggestionList != null && (
              <ConsumerChooseColourPalette
                colorSuggestionList={colorSuggestionList}
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
              />
            )}
          </div>
        </SelectedColorDiv>
        {selectedColourFromDrawer !== null && (
          <NextButton onClick={nextHandler}>
            {" "}
            <LanguageSelector selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          } text={"NEXT"} />
          </NextButton>
        )}
      </ConsumerChooseColourContainer>
    </>
  );
};

export default ConsumerChooseColour;
