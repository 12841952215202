import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  DashboardTitle,
  // DropDownDiv,
  // DropDownParentDiv,
  // DropDownText,
  ServiceHighlightsText,
  ServiceHightLightsImage,
  ServiceHightligtsDiv,
  // DropDownContent,
  // DropDownContentText,
} from "../../styles/AdminDashboardStyles";
// import DROP_DOWN_MENU_ONE_IMAGE from "../../images/drop_down_menu_one.svg";
// import DROP_DOWN_MENU_TWO_IMAGE from "../../images/drop_down_menu_two.svg";
import ANALYTICS_IMAGE from "../../images/analytics.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardAnalyticsHeader = () => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  return (
    <DashboardTitle>
      <ServiceHightligtsDiv>
        <ServiceHightLightsImage src={ANALYTICS_IMAGE} />
        <ServiceHighlightsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ANALYTICS"}
        /></ServiceHighlightsText>
      </ServiceHightligtsDiv>
      {/* <DropDownParentDiv>
        <DropDownDiv onClick={toggleDropDown}>
          <img src={DROP_DOWN_MENU_ONE_IMAGE} alt="Drop Down" />
          <DropDownText>{selectedTime}</DropDownText>
          <img src={DROP_DOWN_MENU_TWO_IMAGE} alt="Drop Down" />
        </DropDownDiv>
        <DropDownContent show={showDropDown}>
          {options.map((item, index) => {
            return (
              <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleDropDown();
                  setSelectedTime(item);
                }}
              >
                {item}
              </DropDownContentText>
            );
          })}
        </DropDownContent>
      </DropDownParentDiv>
     */}
    </DashboardTitle>
  );
};

export default AdminDashboardAnalyticsHeader;
