/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  ConsumerSideBarDiv,
  SideMenuHeaderDiv,
  SideMenuTitleDiv,
  CurratedInsprationsText,
  ImageGalleryText,
  DoneButton,
  ThemeImage,
  SelectedThemeContainer,
  ThemeText,
  SelectedChildThemeContainer,
  ConsumerSideBarModalBarDiv,
} from "../../styles/ConsumerReferenceImageStyles";
import "../../styles/checkBox.css";
import ConsumerReferenceImageTheme from "./ConsumerReferenceImageTheme";
import AWS from "aws-sdk";
import {
  ACCESS_KEY_ID,
  BUCKET_NAME,
  SECRET_ACCESS_KEY,
} from "../../utils/Constants";
import { language } from "../../language/language";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerReferenceImageSideBarDiv = ({
  openSideMenu,
  toggleSideMenu,
  interiorThemeHandler,
  selectedTheme,
  exteriorThemeHandler,
  toggleFilterApplied,
  filterApplied,
  exteriorFilterList,
  interiorFilterList,
  selectedFilter,
  setSelectedFilter,
  selectedImage,
  setSelectedImage,
  galleryImages,
  imageGallery,
  state,
  colourizationContext,
  setImage,
  setGalleryImages,
  width,
  countryValue,
  decodedToken,
  selectedCountry
}) => {
  // console.log(selectedCountry.value.charAt(0).toLowerCase())
  let lowercase_country = countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
  const imageDescription = {
    'Calm':language["CALM"][lowercase_country],
    'Bold':language["BOLD"][lowercase_country],
    'Playful':language["PLAYFUL"][lowercase_country],
    'Restful':language["RESTFUL"][lowercase_country],
    'Bedroom':language["BEDROOM"][lowercase_country],
    'Living Room':language["LIVING_ROOM"][lowercase_country],
    'Study Room':language["STUDY_ROOM"][lowercase_country]
  };

  const description = ['Calm', 'Bold', 'Playful', 'Restful']

  const imageGallerySuccessCallback = (data) => {
    console.log(lowercase_country)
    for (let item in data.images){
      data.images[item].image_name = data.images[item].image_name.substring(data.images[item].image_name.indexOf(" "), data.images[item].image_name.lastIndexOf(" ")) + " " + data.images[item].image_name.substring(0, data.images[item].image_name.indexOf(" ")) + " "
      data.images[item].image_name = data.images[item].image_name.substring(0, data.images[item].image_name.lastIndexOf(" ")).replace(/Calm|Bold|Playful|Restful|Bedroom|Study Room|Living Room/g, m => imageDescription[m]) + " "
    }
    console.log(data)
    setGalleryImages(data.images);
  };
  const imageGalleryFailureCallback = () => {};

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const selectedImageHandler = (item) => {
    const fileName = item.image_content
      .split("amazonaws.com/")[1]
      .split("?")[0];
    s3.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: fileName,
      },
      function (error, data) {
        if (error != null) {
          alert(`${error.name} ${error.originalError}  ${error.message}`);
        } else {
          console.log("image name", item.image_name, "image id", item.image_id)
          setSelectedImage({
            theme: selectedTheme,
            filter: selectedFilter,
            imageName: item.image_name,
            image_id: item.image_id
          });
          setImage([
            {
              imageUrl: item.image_content,
              // imageUrl: item.s3_url,
              imageName: item.image_name,
              imageSize: 1 + " mb",
              image_id: item.image_id
            },
          ]);

          const value = colourizationContext.colourizationDetails;
          colourizationContext.setColourizationDetails({
            raw_image: value.raw_image,
            colourization_type: value.colourization_type,
            // reference: item.image_content,
            reference: `data:image/jpeg;base64,${data.Body.toString("base64")}`,
            theme: "",
            lead_colour: "",
            email: "",
            name: "",
            city: "",
            country: "",
          });
        }
      }
    );
  };

  useEffect(() => {
    console.log("!!!!!!!!", selectedFilter, selectedTheme)
    imageGallery({
      category: selectedTheme,
      filter:
        selectedFilter.filterName === "" || selectedFilter.filterName === language["RESET"][lowercase_country] ? null : 
        selectedFilter.filterName === language["CALM"][lowercase_country] ? language["CALM"]["singapore"] : 
        selectedFilter.filterName === language["PLAYFUL"][lowercase_country] ? language["PLAYFUL"]["singapore"] : 
        selectedFilter.filterName === language["BOLD"][lowercase_country] ? language["BOLD"]["singapore"] : 
        selectedFilter.filterName === language["RESTFUL"][lowercase_country] ? language["RESTFUL"]["singapore"] : 
        selectedFilter.filterName
        ,
      imageGallerySuccessCallback,
      imageGalleryFailureCallback,
    });
    console.log("~~~~~~~~~~~~``", selectedFilter, selectedTheme)
  }, [selectedTheme, selectedFilter]);

  if (width < 1100) {
    return (
      <ConsumerSideBarModalBarDiv openSideMenu={openSideMenu}>
        <SideMenuHeaderDiv>
          <SideMenuTitleDiv>
            <ImageGalleryText>              
              <LanguageSelector
                selectedCountry={
                  selectedCountry
                }
                text={"IMAGE_GALLERY"}
              /></ImageGalleryText>
            <CurratedInsprationsText>
            <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CHOOSE_FROM_A_RANGE_OF"}
              />
            </CurratedInsprationsText>
          </SideMenuTitleDiv>
          <DoneButton onClick={toggleSideMenu}>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"DONE"}
          />
          </DoneButton>
        </SideMenuHeaderDiv>
        <ConsumerReferenceImageTheme
          interiorThemeHandler={interiorThemeHandler}
          selectedTheme={selectedTheme}
          exteriorThemeHandler={exteriorThemeHandler}
          toggleFilterApplied={toggleFilterApplied}
          filterApplied={filterApplied}
          exteriorFilterList={exteriorFilterList}
          interiorFilterList={interiorFilterList}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedCountry={selectedCountry}
        />
        <SelectedThemeContainer>
          {galleryImages?.length > 0 &&
            galleryImages.map((item, index) => {
              return (
                <SelectedChildThemeContainer
                  onClick={() => {
                    selectedImageHandler(item);
                  }}
                  key={`${index}`}
                >
                  <div style={{ position: "relative" }}>
                    <ThemeImage
                      selectedImage={
                        selectedImage.theme === selectedTheme &&
                        selectedImage.filter === selectedFilter &&
                        selectedImage.imageName === item.image_name
                        && selectedImage.image_id == item.image_id
                      }
                      src={item.image_content}
                      // src={item.s3_url}
                      alt={item.image_name}
                    />
                    {selectedImage.theme === selectedTheme &&
                      selectedImage.filter === selectedFilter &&
                      selectedImage.imageName === item.image_name && 
                      selectedImage.image_id == item.image_id && 
                      (
                        <label
                          className="container"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {""}
                          <input
                            type="checkbox"
                            checked="checked"
                            onChange={(e) => true}
                          />
                          <span className="checkmark"></span>
                        </label>
                      )}
                  </div>
                  <ThemeText>{selectedTheme === "Interior" ? item.image_name + " " + (index+1) : index+1}</ThemeText>
                </SelectedChildThemeContainer>
              );
            })}
        </SelectedThemeContainer>
      </ConsumerSideBarModalBarDiv>
    );
  } else {
    return (
      <ConsumerSideBarDiv
        //   className="w3-sidebar w3-bar-block w3-card w3-animate-right"
        //   id="rightMenu"
        openSideMenu={openSideMenu}
      >
        <SideMenuHeaderDiv>
          <SideMenuTitleDiv>
            <ImageGalleryText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"IMAGE_GALLERY"}
              />
          </ImageGalleryText>
            <CurratedInsprationsText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"CHOOSE_FROM_A_RANGE_OF"}
              />
            </CurratedInsprationsText>
          </SideMenuTitleDiv>
          <DoneButton onClick={toggleSideMenu}>
            <LanguageSelector
            selectedCountry={selectedCountry}
            text={"DONE"}
          />
            </DoneButton>
        </SideMenuHeaderDiv>
        <ConsumerReferenceImageTheme
          interiorThemeHandler={interiorThemeHandler}
          selectedTheme={selectedTheme}
          exteriorThemeHandler={exteriorThemeHandler}
          toggleFilterApplied={toggleFilterApplied}
          filterApplied={filterApplied}
          exteriorFilterList={exteriorFilterList}
          interiorFilterList={interiorFilterList}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedCountry={selectedCountry}
          imageDescription={imageDescription}
        />
        <SelectedThemeContainer>
          {galleryImages?.length > 0 &&
            galleryImages.map((item, index) => {
              return (
                <SelectedChildThemeContainer
                  onClick={() => {
                    selectedImageHandler(item);
                  }}
                  key={`${index}`}
                >
                  <div style={{ position: "relative" }}>
                    <ThemeImage
                      selectedImage={
                        selectedImage.theme === selectedTheme &&
                        selectedImage.filter === selectedFilter &&
                        selectedImage.imageName === item.image_name  
                        && selectedImage.image_id == item.image_id
                      }
                      src={item.image_content}
                      // src={item.s3_url}
                      alt={item.image_name}
                    />
                    {selectedImage.theme === selectedTheme &&
                      selectedImage.filter === selectedFilter &&
                      selectedImage.imageName === item.image_name && 
                      selectedImage.image_id == item.image_id && 
                      (
                        <label
                          className="container"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {""}
                          <input
                            type="checkbox"
                            checked="checked"
                            onChange={(e) => true}
                          />
                          <span className="checkmark"></span>
                        </label>
                      )}
                  </div>
                  <ThemeText>{selectedTheme === "Interior" ? item.image_name.substring(0, item.image_name.lastIndexOf(" "))+ " " + (index+1) : index+1}</ThemeText>
                </SelectedChildThemeContainer>
              );
            })}
        </SelectedThemeContainer>
      </ConsumerSideBarDiv>
    );
  }
};

export default ConsumerReferenceImageSideBarDiv;
