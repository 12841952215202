import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  DashboardTitle,
  ServiceHighlightsText,
  ServiceHightLightsImage,
  ServiceHightligtsDiv,
  FilterDiv,
  FilterText,
} from "../../styles/AdminDashboardStyles";
import DROP_DOWN_MENU_ONE_IMAGE from "../../images/drop_down_menu_one.svg";
import SERVICE_HIGHLIGHTS_IMAGE from "../../images/service_highlights.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardServiceHighlightsHeader = ({ toggleShowFilter }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  return (
    <DashboardTitle>
      <ServiceHightligtsDiv>
        <ServiceHightLightsImage src={SERVICE_HIGHLIGHTS_IMAGE} />
        <ServiceHighlightsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SERVICE_HIGHLIGHTS"}
        /></ServiceHighlightsText>
      </ServiceHightligtsDiv>
      <FilterDiv onClick={toggleShowFilter}>
        <img src={DROP_DOWN_MENU_ONE_IMAGE} alt="Filter" />
        <FilterText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"FILTERS"}
        /></FilterText>
      </FilterDiv>
    </DashboardTitle>
  );
};

export default AdminDashboardServiceHighlightsHeader;
