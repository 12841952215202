import React, { useRef, useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ImageText,
  BodyImageTwo,
  ResultCanvas,
  DoneButton,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import styled from "styled-components";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorTouchUpResultImage = ({
  setImageToSave,
  imageToSave,
  tempImageToSave,
  setTempImageToSave,
  canvasRef,
  resultImage,
  useOptionOne,
  useOptionTwo,
  canvasRefPolyResult,
  imageSize,
  pathCollection,
  colorCollection,
  color,
  setSelectedPath,
  location,
  originalImage,
  toggleSideMenu,
  selectedColorId,
  setSelectedColorId,
  selectedColourFromDrawer,
  setSelectedColourFromDrawer,
  changeList,
  setChangeList,
  colorSwapChangeHandler,
  mixBlendMode,
  canvasOpacity,
  selectedFilterOption,
  opacityCollection,
  selectedPath,
  starPoint,
  clickPoints,
  bufferRef
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const myImgRef = new Image();
  myImgRef.src = originalImage;

  const resultRef = new Image();
  resultRef.src = originalImage;

  const svgRef = new Image();

  const compositeCanvasRef = useRef(null);

  var svgpath1 = "";
  var svgCall = [];
  var svgColorCall = [];
  var svgCallTemp = [];
  var svgColorCallTemp = [];
  var svgimg = "";

  if (pathCollection.length === 0) {
    // console.log("empty");
  }

  const polyClick = (e) => {
    const id = parseInt(e.currentTarget.id);
    setSelectedPath(id);
  };

  var currentOptionList = changeList;

  useEffect(() => {
    if (selectedColourFromDrawer !== null && selectedColorId !== null) {
      var changedArr = changeList;
      changedArr[selectedColorId] = [
        selectedColourFromDrawer.red_value,
        selectedColourFromDrawer.green_value,
        selectedColourFromDrawer.blue_value,
      ];
      // currentOptionList[selectedPaginationNumber].colours[selectedColorId] = selectedColourFromDrawer;
      currentOptionList[selectedColorId] = changedArr[selectedColorId];
      setChangeList(changedArr);
      setSelectedColourFromDrawer(selectedColourFromDrawer);
      setSelectedColorId(selectedColorId);
    }
  }, [selectedColourFromDrawer, selectedColorId]);

  const selectColorHandler = (e) => {
    const selected_index = parseInt(e.currentTarget.id);
    setSelectedColorId(selected_index);
    toggleSideMenu();
  };

  const Canvas = (props) => {
    const draw = async (ctx, compositeCtx) => {
      //LOOP and DRAW CANVAS
      if (svgimg !== (null || undefined || "") && pathCollection.length > 0) {
        // for (var index = 0; index < pathCollection.length; index++) {
        const onLoadLoop = (image, index) => {
        resultRef.onload = function () {
          ctx.globalAlpha = 1;
          ctx.drawImage(resultRef, 0, 0, imageSize[0], imageSize[1]);
        };
        resultRef.src = image
        var xml = new XMLSerializer().serializeToString(document.getElementById("svg"+index));
        var svg64 = btoa(xml);
        var b64Start = 'data:image/svg+xml;base64,';
        var image64 = b64Start + svg64;
        ctx.globalAlpha = svgColorCall[index]["opacity"];
        // ctx.beginPath();
        // ctx.globalAlpha = svgColorCall[index]["opacity"];
        // ctx.globalCompositeOperation =  mixBlendMode[index];
        // ctx.moveTo(pathCollection[index][0], pathCollection[index][1]);
        // if (pathCollection[index].length > 0) {
        //   for (var i = 0; i < pathCollection[index].length; i++) {
        //     ctx.lineTo(
        //       pathCollection[index][i][0],
        //       pathCollection[index][i][1]
        //     );
        //   }
        //   ctx.lineTo(pathCollection[index][0], pathCollection[index][1]);
        // }
        // ctx.closePath();
        // ctx.fillStyle = svgColorCall[index]["color"];
        // ctx.fill();
        // var jpegImg = canvasRef.current.toDataURL("image/jpeg");
        // var jpgImg = jpegImg.split("/")[0] + "/jpg;" + jpegImg.split(";")[1];
        // setImageToSave(jpgImg);
        
        svgRef.onload = () => {
            compositeCtx.drawImage(resultRef, 0, 0, imageSize[0], imageSize[1]);
            compositeCtx.globalCompositeOperation = 'destination-in';
            compositeCtx.drawImage(svgRef, 0,0)
            if( mixBlendMode[index] === "normal"){
              compositeCtx.globalCompositeOperation = "copy";
            }else{
              compositeCtx.globalCompositeOperation = mixBlendMode[index];
            }
            compositeCtx.drawImage(svgRef, 0,0)
            ctx.drawImage(compositeCanvasRef.current, 0,0)
            console.log(ctx.globalCompositeOperation)
            var jpegImg = canvasRef.current.toDataURL("image/jpeg");
            // var jpgImg = jpegImg.split("/")[0] + "/jpg;" + jpegImg.split(";")[1];
            if (index+1 === pathCollection.length) return image
            else{
              onLoadLoop(jpegImg , ++index)
            }
          }
          svgRef.src = image64;
        }

        const result = await onLoadLoop(originalImage, 0)
      }
    };

    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const compositeCanvas = compositeCanvasRef.current;
      const compositeContext = compositeCanvas.getContext("2d");
      draw(context, compositeContext);
    }, [draw]);

    return (
      <>
      <canvas
        style={{ display: "none" }}
        ref={canvasRef}
        width={imageSize[0]}
        height={imageSize[1]}
        {...props}
      />
      <canvas
      style={{ display: "none" }}
      ref={compositeCanvasRef}
      width={imageSize[0]}
      height={imageSize[1]}
      {...props}
    />
    </>
    );
  };

  const Svg = () => {
    for (var i = 0; i < pathCollection.length; i++) {
      if (pathCollection[i].length > 0) {
        svgpath1 +=
          "M" +
          pathCollection[i][0][0].toString() +
          " " +
          pathCollection[i][0][1].toString() +
          " ";
        for (var j = 1; j < pathCollection[i].length; j++) {
          svgpath1 +=
            "L" +
            pathCollection[i][j][0].toString() +
            " " +
            pathCollection[i][j][1].toString();
        }
        svgpath1 +=
          "L" +
          pathCollection[i][0][0].toString() +
          " " +
          pathCollection[i][0][1].toString();
      }
      svgCall.push(svgpath1);
      svgColorCall.push({
        opacity: opacityCollection[i],
        path: svgpath1,
        color: colorCollection[i],
      });
      svgpath1 = "";
    }
    svgimg = "";
    svgimg += '<svg width="400px" height="400px">';

    // const style = {
    //   filter: `url(#${app || 'default'})`
    // }

    return (
      <>
        {svgColorCall.map((spath1, index1) => (
          <div style={{marginTop: "50px", display: "inline-block",
          zIndex: 1,
          position: "absolute", mixBlendMode: mixBlendMode[index1]}}>
          <svg
            id={"svg" + index1}
            width={imageSize[0]}
            height={imageSize[1]}
            style={{
              display: "inline-block",
              zIndex: 1,
              position: "absolute",
              mixBlendMode: mixBlendMode[index1],
            }}
            mlns="http://www.w3.org/2000/svg"
          >
            {/* {svgCall.map(spath => <a key={key++}> {colorCollection.map(colourset => <path key={svgCall.indexOf(spath)} id={svgCall.indexOf(spath)} d={spath} onClick={polyClick}  fill={colourset} cursor="pointer"/>)}</a>)} */}
            {svgColorCall.map((spath2, index2) => (
              <path
                style={{ mixBlendMode: mixBlendMode[index1] }}
                key={svgColorCall.indexOf(spath2) + "color"}
                onClick={polyClick}
                id={svgColorCall.indexOf(spath2)}
                d={spath2.path}
                fillOpacity={index1 === index2 ? spath2.opacity : 0}
                fill={spath2.color}
                cursor="pointer"
              />
            ))}
          </svg>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {useOptionOne
        ? null
        : resultImage !== null && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <ImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULT_IMAGE"}
            /></ImageText>
              <BodyImageTwo
                width={imageSize[0]}
                height={imageSize[1]}
                src={resultImage}
              ></BodyImageTwo>
              <div
                style={{
                  display: "inline-block",
                  flexDirection: "column",
                  alignItems: "start",
                  position: "relative",
                  margin: "20px -20px",
                }}
              >
                {/* <OptionText>Palette</OptionText> */}
                <PaletteContainer>
                  {/* {currentOptionList &&
              currentOptionList[selectedPaginationNumber]?.colours?.map(
                (item, index) => {
                  return (
                    <div key={`${index}`}>
                      <PaletteDiv
                        id={`${index}`}
                        onClick={selectColorHandler}
                        style={{
                          backgroundColor: `rgb(${item?.red_value},${item.green_value},${item?.blue_value})`,
                        }}
                      >
                        <ToolTip>{item?.colour_name}</ToolTip>
                      </PaletteDiv>
                      <ColorText>{item?.colour_code}</ColorText>
                    </div>
                  );
                }
              )} */}
                  {currentOptionList &&
                    currentOptionList.map((item, index) => {
                      if (index > 0) {
                        return (
                          <div key={`${index}`}>
                            <PaletteDiv
                              id={`${index}`}
                              onClick={selectColorHandler}
                              style={{
                                backgroundColor: `rgb(${item[0]},${item[1]},${item[2]})`,
                              }}
                            ></PaletteDiv>
                          </div>
                        );
                      }
                    })}
                </PaletteContainer>
                <DoneButton onClick={colorSwapChangeHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SUBMIT"}
            /></DoneButton>
              </div>
            </div>
          )}

      {useOptionTwo ? null : (
        <div
          id="test"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "50px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              zIndex: -1,
              position: "relative",
            }}
          >
            <ImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULT_IMAGE"}
            /></ImageText>
            <ResultCanvas ref={canvasRefPolyResult} />
          </div>
          {/* <div style={{
              display: "inline-block",
              zIndex: 1,
              position: "absolute",
              marginTop: "50px",
              // mixBlendMode: mixBlendMode, willChange: 'opacity',
            }}> */}
          <Svg />
          {/* </div> */}
          <div
            style={{
              display: "inline-block",
              zIndex: 1,
              position: "absolute",
              marginTop: "500px",
            }}
          >
            <Canvas />
          </div>
        </div>
      )}
    </>
  );
};

const PaletteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3px 25px;
  width: 500px;
`;

const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const ColorText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin: 2px auto;
  text-align: center;
`;

const PaletteDiv = styled.div`
  border-radius: 3px;
  width: 55px;
  height: 55px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  margin: 2px 12px;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

const OptionText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 10px 25px 4px 25px;
`;

export default PreviewSupervisorTouchUpResultImage;
