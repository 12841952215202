import React, { useState } from "react";
import { Formik } from "formik";
import { loginEmailValidationSchema } from "../../validations/Validations";
import {
  EnterEmailText,
  SendOTP,
  WelcomeBackText,
  ErrorMessage,
} from "../..//styles/ConsumerUploadImageStyles";
import "../../styles/floatingLabelInput.css";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerLoginEmail = ({
  toggleShowOTP,
  verifyEmail,
  state,
  setEmail,
  selectedCountry,
}) => {
  const [progress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const toggleProgress = () => setProgress((previousState) => !previousState);

  const verifyEmailSuccessCallback = () => {
    toggleShowOTP(true);
  };

  const verifyEmailFailureCallback = (msg) => {
    setErrorMsg(msg);
    toggleProgress();
  };

  return (
    <>
      <WelcomeBackText>
        <LanguageSelector selectedCountry={selectedCountry} text={"WELCOME_BACK"} />
      </WelcomeBackText>
      <EnterEmailText>
        <LanguageSelector selectedCountry={selectedCountry} text={"ENTER_THE_EMAIL"} />
      </EnterEmailText>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values, actions) => {
          if (!progress) {
            toggleProgress();
            setEmail(values.email);
            verifyEmail({
              email: values.email,
              verifyEmailSuccessCallback,
              verifyEmailFailureCallback,
            });
          }
        }}
        validationSchema={loginEmailValidationSchema}
      >
        {(formikProps) => {
          return (
            <>
              <div
                className="form-control"
                style={{
                  display: "flex",
                  alignSelf: "center",
                  margin: "10px 0 0 0",
                }}
              >
                <input
                  required
                  type="email"
                  name="input"
                  value={formikProps.values.email}
                  onChange={formikProps.handleChange("email")}
                  onBlur={formikProps.handleBlur("email")}
                />
                <label>
                  <LanguageSelector selectedCountry={selectedCountry} text={"EMAIL"} />
                </label>
                <div className="border-around"></div>
              </div>
              {errorMsg != null && <ErrorMessage>{errorMsg}</ErrorMessage>}
              <SendOTP
                type="submit"
                onClick={formikProps.handleSubmit}
                disabled={!(formikProps.isValid && formikProps.dirty)}
                isDisabled={!(formikProps.isValid && formikProps.dirty)}
              >
                <LanguageSelector selectedCountry={selectedCountry} text={"SEND_OTP"} />
              </SendOTP>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ConsumerLoginEmail;
