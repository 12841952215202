/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  PreviewSupervisorResultContainer,
  PreviewSupervisorResultHeader,
  DeluxePreviewServiceImage,
  LogoutButton,
  HorizontalRuler,
  BottomRow,
  HeaderHorizontalRuler,
  ButtonBar,
  DownloadButton,
  BackButton,
  PreviewSupervisorResultBody,
  ResultsText,
  ErrorMessage,
} from "../../styles/PreviewSupervisorResultStyles";
import AWS from "aws-sdk";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import {
  ACCESS_KEY_ID,
  BUCKET_NAME,
  HOME_PAGE_ROUTE,
  SECRET_ACCESS_KEY,
  TOKEN_NAME,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
  PREVIEW_STAFF_DASHBOARD_ROUTE
} from "../../utils/Constants";
import { Link } from "react-router-dom";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import ResultSideBar from "../../components/result/ResultSideBar";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import ResultComp from "../../components/result/ResultComp";
import ResultOriginal from "../../components/result/ResultOriginal";
import AdminPSResultModal from "../../components/result/AdminPSResultModal";
import ResultRecommendedProducts from "../../components/result/ResultRecommendedProducts";
import ResultNearByStores from "../../components/result/ResultNearByStores";
import ResultPainters from "../../components/result/ResultPainters";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { default as Back } from "../../components/BackButton";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorResult = ({ history, location }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const [selectedModalIndex, setSelectedModalIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [openResultMenu, setOpenResultMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleResultMenuDrawer = () =>
    setOpenResultMenu((previousState) => !previousState);
  const toggleModal = () => setShowModal((previousState) => !previousState);

  const previewSupervisorDetailsContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const { state, requestReport, adminReportDownload } = useContext(
    PreviewSupervisorContext
  );

  const goBackHandler = () => {
    console.log("USER TYPEEEE", decodedToken.user_type)
    if (decodedToken.user_type === "Preview Staff") {
      history.push(PREVIEW_STAFF_DASHBOARD_ROUTE, {
        menu: "Requests",
        tabSelected: location.state?.tabSelected,
        selectedPageForResolved: location.state?.selectedPageForResolved,
        selectedPageForPending: location.state?.selectedPageForPending,
        selectedPageForArchived: location.state?.selectedPageForArchived,
        requestList: location.state?.requestList,
      });}
    else if (decodedToken.user_type === "Preview Supervisor"){
      history.push(PREVIEW_SUPERVISOR_DASHBOARD_ROUTE, {
        menu: "Requests",
        tabSelected: location.state?.tabSelected,
        selectedPageForResolved: location.state?.selectedPageForResolved,
        selectedPageForPending: location.state?.selectedPageForPending,
        selectedPageForArchived: location.state?.selectedPageForArchived,
        requestList: location.state?.requestList,
      });
  }};

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailsContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const { width } = useWindowDimensions();

  const nextModalImageClickHandler = () => {
    if (
      selectedModalIndex <
      state.data?.result?.report_details?.results.length - 1
    ) {
      setSelectedModalIndex(selectedModalIndex + 1);
    }
  };

  const previousModalImageClickHandler = () => {
    if (selectedModalIndex > 0) {
      setSelectedModalIndex(selectedModalIndex - 1);
    }
  };

  const requestReportSuccessCallback = (data) => {};

  const requestReportFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  useEffect(() => {
    requestReport({
      request_id: location.state?.item.request_id,
      requestReportSuccessCallback,
      requestReportFailureCallback,
    });
  }, []);

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const downloadImage = (fileName) => {
    s3.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: fileName,
      },
      function (error, data) {
        if (error != null) {
          alert(`${error.name} ${error.originalError}  ${error.message}`);
        } else {
          let blob = new Blob([data.Body], { type: data.ContentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "file.jpeg";
          link.click();
        }
      }
    );
  };
  const adminReportDownloadSuccessCallback = (data) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.pdf";
    document.body.appendChild(link);
    window.open(link, "_blank")
  };

  const adminReportDownloadFailureCallback = (msg) => {
    console.log(msg);
    alert("unable to download report");
  };

  const downloadClickHandler = () => {
    adminReportDownload({
      request_id: location.state?.item.request_id,
      adminReportDownloadSuccessCallback,
      adminReportDownloadFailureCallback,
    });
  };

  const downloadSingleImageHandler = (imageUrl) => {
    window.open(imageUrl);
    // amarisServer
    //   .get(imageUrl, {
    //     responseType: "blob",
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "file.jpeg"); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };

  const viewDetailHandler = (index) => {
    setSelectedItem(index);
    toggleResultMenuDrawer();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <ResultSideBar
        openResultMenu={openResultMenu}
        toggleResultMenuDrawer={toggleResultMenuDrawer}
        results={state.data?.result?.report_details?.results}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        downloadSingleImageHandler={downloadSingleImageHandler}
        width={width}
      />
      <PreviewSupervisorResultContainer
        applyFilter={openResultMenu || openLogoutMenu || showModal}
      >
        <PreviewSupervisorResultHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              alt="Deluxe Preview"
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
            />
          </Link>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LOGOUT"}
            /></LogoutButton>
        </PreviewSupervisorResultHeader>
        <HeaderHorizontalRuler />
        {width > 1100 && <Back goBackHandler={goBackHandler} />}
        <PreviewSupervisorResultBody>
          <ResultsText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULTS"}
            /></ResultsText>
          {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ResultComp
            state={state}
            toggleModal={toggleModal}
            viewDetailHandler={viewDetailHandler}
            setSelectedModalIndex={setSelectedModalIndex}
          />
          <ResultOriginal state={state} />
          <HorizontalRuler />
          <BottomRow>
            <ResultRecommendedProducts
              list={state.data?.result?.report_details?.product_details}
            />
            <ResultNearByStores
              list={state.data?.result?.report_details?.shop_details}
            />
            <ResultPainters
              list={state.data?.result?.report_details?.painter_details}
            />
          </BottomRow>
          <ButtonBar>
            <DownloadButton onClick={downloadClickHandler}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            />
            </DownloadButton>
            <BackButton onClick={goBackHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
          </ButtonBar>
        </PreviewSupervisorResultBody>
      </PreviewSupervisorResultContainer>
      {showModal && (
        <AdminPSResultModal
          toggleModal={toggleModal}
          previousModalImageClickHandler={previousModalImageClickHandler}
          state={state}
          selectedModalIndex={selectedModalIndex}
          downloadSingleImageHandler={downloadSingleImageHandler}
          nextModalImageClickHandler={nextModalImageClickHandler}
          width={width}
        />
      )}
    </>
  );
};

export default PreviewSupervisorResult;
