import React, { useContext, useState } from "react";
import {
  ConsumerResultContainer,
  ConsumerResultHeader,
  DeluxePreviewServiceImage,
  RecolorReferenceImageText,
  RecolourAgainButton,
  RelaxImage,
  RelaxText,
  TryThemeButton,
  BackToHomeButton,
  ButtonBar,
  LogoutButton,
  SitBackText,
} from "../../styles/ConsumerResultStyles";
import {
  CONSUMER_HOME_ROUTE,
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  HOME_PAGE_ROUTE,
  REFERENCE,
  THEME,
  TOKEN_NAME,
} from "../../utils/Constants";

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import "../../styles/floatingLabelInput.css";
import { Link } from "react-router-dom";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import RELAX_IMAGE from "../../images/relax.svg";
import { ColourizationContext } from "../../context/ColourizationContext";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerResult = ({ history, location }) => {
  console.log("!#!#!#!#!",location, "!!!",location?.state?.type)
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const { width } = useWindowDimensions();

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const colourizationContext = useContext(ColourizationContext);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goToImageUploadOptions = () => {
    history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue
        }
      />
      <ConsumerResultContainer applyFilter={openLogoutMenu}>
        <ConsumerResultHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          {decodedToken && (
            <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"LOGOUT"}
          /></LogoutButton>
          )}
        </ConsumerResultHeader>
        <RelaxText style={{ marginTop: "20px" }}>
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"THANK_YOU_FOR_SENDING"}
          />
        </RelaxText>
        <RelaxImage src={RELAX_IMAGE} alt="Relax" />

        <SitBackText>
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"SIT_BACK_AND_RELAX_ONE"}
          />
          <br />
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"SIT_BACK_AND_RELAX_TWO"}
          />
        </SitBackText>
        <ButtonBar>
          <Link to={CONSUMER_HOME_ROUTE}>
            <BackToHomeButton>
              <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"BACK_TO_HOME"}
              />
            </BackToHomeButton>
          </Link>
          <Link to={CONSUMER_UPLOAD_IMAGE_ROUTE}>
            {" "}
            <RecolourAgainButton>
              <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"SUBMIT_ANOTHER_REQUEST"}
              />
            </RecolourAgainButton>
          </Link>
        </ButtonBar>

        <RecolorReferenceImageText>
          {location?.state?.type === THEME ? (
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"YOU_CHOSE_THEME"}
            />
          ) : location?.state?.type === REFERENCE ? (
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"YOU_CHOSE_REF"}
            />
          ): (
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"YOU_CHOSE_LEAD"}
            />
          )}
        </RecolorReferenceImageText>
        <TryThemeButton onClick={goToImageUploadOptions}>
          {location?.state?.type === THEME ? (
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HOW_ABOUT_TRY_SOME_LEAD"}
            />
          ) : location?.state?.type === REFERENCE ? (
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HOW_ABOUT_TRY_SOME_THEMES"}
            />
          ):(<LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"HOW_ABOUT_TRY_SOME_REF"}
          />)}
        </TryThemeButton>
      </ConsumerResultContainer>
    </>
  );
};

export default ConsumerResult;
