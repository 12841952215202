import React from "react";
import {
  CancelImage,
  ChangeButton,
  SelectedImage,
  SelectedImageDetailsContainer,
  SelectedImageDiv,
  SelectedImageSizeText,
  SelectedImageText,
} from "../../styles/ConsumerUploadImageStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerUploadSelectedImage = ({
  image,
  uploadImageClickHandler,
  cancelUploadedImageHandler,
  selectedCountry,
  width,
}) => {
  if (width < 1100) {
    return (
      <SelectedImageDiv>
        <div style={{ position: "relative", display: "flex" }}>
          <SelectedImage src={image[0].imageUrl} alt={image[0].imageName} />
          <CancelImage
            src={CANCEL_IMAGE}
            onClick={cancelUploadedImageHandler}
            alt="Cancel"
          />
        </div>
        <SelectedImageDetailsContainer>
          <SelectedImageText>
            {image[0].imageName.length > 10
              ? `${image[0].imageName.substring(0, 10)}...`
              : image[0].imageName}
          </SelectedImageText>
          <SelectedImageSizeText>{image[0].imageSize}</SelectedImageSizeText>
          <ChangeButton onClick={uploadImageClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"CHANGE"} />
          </ChangeButton>
        </SelectedImageDetailsContainer>
      </SelectedImageDiv>
    );
  } else {
    return (
      <SelectedImageDiv>
        <div style={{ position: "relative", display: "flex" }}>
          <SelectedImage src={image[0].imageUrl} alt={image[0].imageName} />
          <CancelImage
            src={CANCEL_IMAGE}
            onClick={cancelUploadedImageHandler}
            alt="Cancel"
          />
        </div>
        <SelectedImageDetailsContainer>
          <SelectedImageText>
            {image[0].imageName.length > 10
              ? `${image[0].imageName.substring(0, 10)}...`
              : image[0].imageName}
          </SelectedImageText>
          <SelectedImageSizeText>{image[0].imageSize}</SelectedImageSizeText>
          <ChangeButton onClick={uploadImageClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"CHANGE"} />
          </ChangeButton>
        </SelectedImageDetailsContainer>
      </SelectedImageDiv>
    );
  }
};

export default ConsumerUploadSelectedImage;
