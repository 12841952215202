import styled from "styled-components";

export const ConsumerHomeContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerHomeHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0;
  }
`;

export const ConsumerHomeBodyContainer = styled.div`
  display: flex;
  margin: 0;
  width: 100vw;
  height: auto;
`;

export const ConsumerHomeUploadedDiv = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

export const ConsumerHomeBodyImage = styled.img`
  /* align-self: flex-end; */
  object-fit: none;
  height: 85vh;
  margin: 0;
  width: 25vw;
`;

export const ConsumerHomeBodyImageVietnamWallOwner = styled.img`
  /* align-self: flex-end; */
  object-fit: none;
  filter: hue-rotate(100deg);
  height: 85vh;
  margin: 0;
  width: 25vw;
`;

export const ConsumerHomeBodySideDiv = styled.div`
  position: relative;
  text-align: center;
`;

export const ConsumerHomeBodyImageVietnamRetailer = styled.img`
  /* align-self: flex-end; */
  object-fit: none;
  filter: hue-rotate(200deg);
  height: 85vh;
  margin: 0;
  width: 25vw;
`;

export const ConsumerHomeBodyImageVietnamEmployee = styled.img`
  /* align-self: flex-end; */
  object-fit: none;
  filter: hue-rotate(300deg);
  height: 85vh;
  margin: 0;
  width: 25vw;
`;

export const ConsumerHomeNameVietnam = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: 3rem;
  position: absolute;
  top: 0.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 1.9vw;
  text-shadow: 5px 10px 10px #000000;
  color: #ffffff;
`;

export const ConsumerHomeBodyImageVietnamPainter = styled.img`
  /* align-self: flex-end; */
  object-fit: none;
  filter: hue-rotate(170deg);
  height: 85vh;
  margin: 0;
  width: 25vw;
`;

export const ConsumerNameHeaderDiv = styled.div`
  display: flex;
  padding: 0px 30px;
`;

export const ConsumerNameDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 2;
`;

export const ConsumerHomeName = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 20px 0px 10px -8px;
  align-self: flex-start;
`;

export const WelcomeBackText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #4f4f4f;
  margin: 0 0 0 -8px;
`;

export const RecolorImageButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  align-self: flex-end;
  margin: auto 0;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

export const ConsumerHomeUserCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ConsumerHomeUserCards = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  margin: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ConsumerHomeUploadedImage = styled.img`
  width: 200px;
  height: 200px;
  margin: 0 10px;
  object-fit: fill;
  border-radius: 10px;
  @media (max-width: 1100px) {
    width: 170px;
    height: 150px;
  }
`;

export const RequestIdText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin: 0;
  padding: 0px 10px;
`;

export const RecolourByText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0 0 10px 0;
  padding: 0px 10px;
`;

export const NoOfImagesText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
  color: #545850;
  padding: 0px 10px;
`;

export const RecolourButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #2fc38c;
  outline: none;
  border: none;
  background-color: #fff;
  width: 115px;
  margin: 0px auto;
`;

export const ViewResult = styled.button`
  outline: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100px;
  margin: 4px auto;
  :hover {
    cursor: pointer;
  }
`;

export const RequestReceivedButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 160%;
  text-align: center;
  color: #f1f1f1;
  background: #333333;
  border-radius: 0px 3px 3px 0px;
  outline: none;
  border: none;
  padding: 5px;
  align-self: flex-start;
  margin: 12px 0px 4px 0;
`;

export const DetailArrow = styled.div`
  background: #f1f1f1;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const SubmitAnotherRequestDiv = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border: 1px dashed #d7d4cb;
  width: 220px;
  height: 300px;
  margin: 10px 10px 10px 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 170px;
    height: 280px;
  }
`;

export const SubmitRequestButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  border: none;
  background: transparent;
  margin: 10px 0;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
