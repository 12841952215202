import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  BillingBodyDiv,
  BillingAmountDiv,
  BillingAmountText,
  TotalAmountText,
  CountryDiv,
  CountryDetailDiv,
  CountryImage,
  CountryNameText,
  CountryBillingAmountText,
  BillingHeaderDiv,
  BillingDiv,
  BillingImage,
  BillingsText,
  DownloadInvoice,
} from "../../styles/AdminDashboardStyles";
import BILLING_IMAGE from "../../images/billing.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardBillingBody = ({
  countries,
  billingData,
  toggleDownloadInvoiceDrawer,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <>
      <BillingHeaderDiv>
        <BillingDiv>
          <BillingImage src={BILLING_IMAGE} />
          <BillingsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BILLINGS"}
        /></BillingsText>
        </BillingDiv>
        <DownloadInvoice onClick={toggleDownloadInvoiceDrawer}>
        <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DOWNLOAD_INVOICE"}
        />
        </DownloadInvoice>
      </BillingHeaderDiv>
      <BillingBodyDiv>
        <BillingAmountDiv>
          {billingData !== null && billingData?.total_amount !== undefined && (
            <BillingAmountText>$ {billingData.total_amount}</BillingAmountText>
          )}
          <TotalAmountText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TOTAL_AMOUNT"}
        /></TotalAmountText>
        </BillingAmountDiv>
        <CountryDiv>
          {countries.map((item, index) => {
            return (
              <CountryDetailDiv key={`${index}`}>
                <CountryImage src={item.image} />
                <CountryNameText>{item.name}</CountryNameText>
                {billingData !== null && (
                  <CountryBillingAmountText>
                     {billingData[`${item.name.toLowerCase()}`]} <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SMALL_REQUESTS"}
        />
                  </CountryBillingAmountText>
                )}
              </CountryDetailDiv>
            );
          })}
        </CountryDiv>
      </BillingBodyDiv>
    </>
  );
};

export default AdminDashboardBillingBody;
