import styled from "styled-components";

export const ConsumerDuluxPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100px;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const StepContainer = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 1vh 20vw 0 0;
  @media (max-width: 1100px) {
    margin: 10px;
    width: 100%;
  }
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const DuluxPreviewImage = styled.img`
  width: 12vw;
  height: 12vw;
  align-self: center;
  margin: 20px 0;
  @media (max-width: 1100px) {
    width: 150px;
    height: 150px;
  }
`;

export const DuluxPreviewText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #000000;
`;

export const SubmitRequestButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  border: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  width: 10vw;
  padding: 2px 0;
  margin: 20px auto;
  outline: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 150px;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
