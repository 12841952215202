import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import { PreviewSupervisorDashboardHeaderContainer } from "../../styles/PreviewSupervisorDashboardStyles";
import TOTAL_REQUEST_IMAGE from "../../images/total_request.svg";
import PENDING_REQUEST_IMAGE from "../../images/pending_request.svg";
import STAR_IMAGE from "../../images/star.svg";
import MY_TEAM_IMAGE from "../../images/my_team.svg";
import CLOCK_IMAGE from "../../images/clock.svg";
import PreviewSupervisorDashboardRequest from "./PreviewSupervisorDashboardRequest";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardHeaderRequest = ({
  toggleOpenMyTeamMenu,
  requestList,
  dashboardData,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  return (
    <PreviewSupervisorDashboardHeaderContainer>
      <PreviewSupervisorDashboardRequest
        image={TOTAL_REQUEST_IMAGE}
        number={dashboardData !== null ? dashboardData.total_request : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TOTAL_REQUESTS"}
        />}
        showRightArrow={false}
        hover={false}
      />
      <PreviewSupervisorDashboardRequest
        image={PENDING_REQUEST_IMAGE}
        number={dashboardData !== null ? dashboardData.pending_request : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PENDING_REQUESTS"}
        />}
        showRightArrow={false}
        hover={false}
      />
      <PreviewSupervisorDashboardRequest
        image={STAR_IMAGE}
        number={dashboardData !== null ? dashboardData.customer : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MY_CUSTOMERS"}
        />}
        showRightArrow={false}
        hover={false}
      />
      <PreviewSupervisorDashboardRequest
        image={MY_TEAM_IMAGE}
        number={dashboardData !== null ? dashboardData.team : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MY_TEAM"}
        />}
        showRightArrow={true}
        onClickHandler={toggleOpenMyTeamMenu}
        hover={true}
      />
      <PreviewSupervisorDashboardRequest
        image={CLOCK_IMAGE}
        number={dashboardData !== null ? dashboardData.avg_turnaround_time : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TURN_AROUND_TIME"}
        />}
        showRightArrow={false}
        hover={false}
      />
    </PreviewSupervisorDashboardHeaderContainer>
  );
};

export default PreviewSupervisorDashboardHeaderRequest;
