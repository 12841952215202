import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  NearByPainters,
  BottomText,
  NearByPaintBodyDiv,
  NearByPainterImage,
  NearByPainterTitleText,
  NearByPainterSubTitleText,
} from "../../styles/PreviewSupervisorResultStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import PAINTER_IMAGE from "../../images/painter_image.png";
import LanguageSelector from "../../language/LanguageSelector";

const ResultPainters = ({ list }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <NearByPainters>
      <BottomText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEARBY_PAINTERS"}
            /></BottomText>
      <NearByPainterTitleText style={{ marginTop: "10px" }}>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COMING_SOON"}
            />
      </NearByPainterTitleText>
      {list &&
        list.length > 0 &&
        list.map((data, index) => {
          return (
            <>
              {/* <NearByPaintBodyDiv key={`${index}`}>
              <NearByPainterImage src={PAINTER_IMAGE} />
              <div style={{ margin: "0 10px" }}>
                <NearByPainterTitleText>
                  {data.shop_name}
                </NearByPainterTitleText>
                <NearByPainterSubTitleText>
                  {data.phone}
                </NearByPainterSubTitleText>
                <img src={GREEN_ARROW_IMAGE} alt="Arrow" />
              </div>
            </NearByPaintBodyDiv> */}
            </>
          );
        })}
    </NearByPainters>
  );
};

export default ResultPainters;
