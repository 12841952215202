import * as yup from "yup";

export const loginEmailValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required").label("Email"),
  floor_number: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else {
        return true
      }
    }
  ),
  floor_area: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else{
        return true
      }
    }
  ),
  room_number: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else{
        return true
      }
    }
  ),
});

export const loginEmailValidationSchemaLoggedin = yup.object().shape({
  floor_number: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else {
        return true
      }
    }
  ),
  floor_area: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else{
        return true
      }
    }
  ),
  room_number: yup.string().test(
    'Is a number?', 
    'Enter a valid number', 
    (value) => {
      if (value || value === 0){
        if(value.match(/^[0-9]+$/)){
          return true
        } else{
          return false
        }
      }
      else{
        return true
      }
    }
  ),
});

export const personalDetailsValidationSchema = yup.object().shape({
  name: yup.string().min(2).label("Name").required("Name is required"),
  city: yup.string().label("City").required("City is required"),
});

export const otpValidationSchema = yup.object().shape({
  otpOne: yup
    .number()
    .test("len", "Max", (val) => {
      if (!val && val !== 0) {
        return false;
      } else if (val.toString().length === 1) {
        return true;
      } else {
        return false;
      }
    })
    .required("")
    .label(""),
  otpTwo: yup
    .number()
    .test("len", "Max", (val) => {
      if (!val && val !== 0) {
        return false;
      } else if (val.toString().length === 1) {
        return true;
      } else {
        return false;
      }
    })
    .required("")
    .label(""),
  otpThree: yup
    .number()
    .test("len", "Max", (val) => {
      if (!val && val !== 0) {
        return false;
      } else if (val.toString().length === 1) {
        return true;
      } else {
        return false;
      }
    })
    .required("")
    .label(""),
  otpFour: yup
    .number()
    .test("len", "Max", (val) => {
      if (!val && val !== 0) {
        return false;
      } else if (val.toString().length === 1) {
        return true;
      } else {
        return false;
      }
    })
    .required("")
    .label(""),
});

export const addUserValidationSchema = yup.object().shape({
  email: yup.string().min(2).label("Email").required("Email is required"),
  name: yup.string().label("Name").required("Name is required"),
});
