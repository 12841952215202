import styled from "styled-components";

export const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const DashboardGraphBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const AddUserCancelImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 20px 0;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 20px;
  }
`;

export const FilterDateModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const DashboardTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const ServiceHightligtsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ServiceHightLightsImage = styled.img``;

export const ServiceHighlightsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const DropDownDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
`;

export const DropDownText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #545850;
  margin: 0 10px;
`;

export const DropDownParentDiv = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
`;

export const DropDownContent = styled.div`
  display: ${(props) => (props.show === true ? "flex" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  :hover {
    cursor: pointer;
  }
`;

export const DropDownContentText = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #545850;
  margin: 4px 10px;
  outline: none;
  border: none;
  background: transparent;
`;

export const AdminDashboardRequestContainer = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 20px 0px;
  margin: 0px 10px;
  outline: none;
  @media (max-width: 1100px) {
    padding: 4px;
    margin: 10px;
  }
`;

export const AdminDashboardRequestNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AdminDashboardRequestImage = styled.img`
  width: 25px;
  height: 25px;
  margin: 0px 5px 0 0;
`;

export const AdminDashboardRequestNumberText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 25px;
  }
`;

export const AdminDashboardRequestText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #828282;
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 16px;
  }
`;

export const AdminDashboardStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  margin: 0 10px 20px 10px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    margin: 10px;
  }
`;

export const HoirzontalStatsDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;

export const AnalyticsCountryTitleDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 60vw;
  margin: 0px 20px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    max-width: 100vw;
  }
`;

export const CountryNameButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.isSelected === true ? "600" : "normal")};
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-bottom: ${(props) =>
    props.isSelected === true ? "3px solid #2FC38C" : "none"};
`;

export const CountryNameHorizontalDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0 150px 0px 20px;
`;

export const RequestsCustomersDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  /* justify-content: space-evenly; */
  /* width: 100%; */
`;

export const RequestDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin: 10px 10px 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const CustomerDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex: 0.47;
  /* flex: 0.45; */
`;

export const RequestCustomerText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin: 0 0 10px 0px;
`;

export const RequestGraph = styled.canvas`
  /* width: 80%;
  height: 50%; */
`;

export const CustomerGraph = styled.canvas`
  /* width: "100%";
  height: "100%"; */
`;

export const AnalyticsStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  margin: 0 10px 20px 10px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    margin: 10px;
  }
`;

export const AnalyticsRequestContainer = styled.button`
  background: #ffffff;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  margin: 0px 30px;
  outline: none;
  @media (max-width: 1100px) {
    margin: 10px;
    padding: 10px;
  }
`;

export const AnalyticsRequestNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AnalyticsRequestImage = styled.img`
  width: 25px;
  height: 25px;
  margin: 0px 5px 0 0;
`;

export const AnalyticsRequestNumberText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
  margin: 0;
`;

export const AnalyticsRequestText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #828282;
  margin: 0;
`;

export const BillingDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BillingImage = styled.img``;

export const BillingsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const BillingBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 20px 100px 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const BillingAmountDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 4px 10px;
  align-items: center;
  @media (max-width: 1100px) {
    margin: 10px 0px 20px 0px;
  }
`;

export const BillingAmountText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const TotalAmountText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #828282;
`;

export const CountryDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  margin: 0 30px;
  align-items: center;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const CountryDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const CountryImage = styled.img``;

export const CountryNameText = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #828282;
`;

export const CountryBillingAmountText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const FilterDiv = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 0 10px 0 40px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px 0;
    padding: 10px;
    width: 100px;
  }
`;

export const FilterText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #545850;
  margin: 0 10px;
`;

export const FilterDateSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const DownloadInvoiceModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
`;

export const DownloadInvoiceSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;

export const BillingHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const DownloadInvoice = styled.button`
  outline: none;
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 130px;
    margin: 10px 0;
  }
`;

export const FilterDateDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterDateAddUseImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 10px 0 0;
`;

export const FilterDateAddtext = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const ByTime = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
`;

export const AdminFilterDateSearchDateDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  :hover {
    cursor: pointer;
  }
`;

export const SelectedDateCheck = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const SelectedDateText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0px 10px;
`;

export const DoneButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  width: 80px;
  text-align: center;
  background: #2fc38c;
  border-radius: 5px;
  margin: 20px auto;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const DateRow = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
