/* eslint-disable react-hooks/exhaustive-deps */
// import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  CancelImage,
  // ConsumerOtpInputDiv,
  // ConsumerResultOtpInput,
  ConsumerResultSideBarDiv,
  OneTimeOtpText,
  OtpDiv,
  ResendOtpButton,
  SideBarFooter,
  VerifyEmailButton,
  ConsumerOTPModalBarDiv,
  ConsumResultNewOtpInput,
  ErrorMessage,
} from "../../styles/ConsumerResultStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";
import LanguageSelector from "../../language/LanguageSelector";
// import { otpValidationSchema } from "../../validations/Validations";

const ConsumerResultSideBar = ({
  openSideMenu,
  toggleSideMenu,
  togglePersonalDetailsMenu,
  verifyEmailOTP,
  email,
  verifyEmail,
  width,
  selectedCountry
}) => {
  const [otp, setOtp] = useState("");
  const [progress, setProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verfiyEmailSuccess, setVerifyEmailSuccess] = useState(null);
  const [countDown, setCountDown] = useState(60);
  const [canResendOtp, setCanResendOtp] = useState(true);

  const toggleProgress = () => setProgress((previousState) => !previousState);

  const verifyEmailSuccessCallback = () => {
    toggleProgress();
    setVerifyEmailSuccess("Resend otp success");
  };

  const verifyEmailFailureCallback = (msg) => {
    toggleProgress();
    setErrorMessage(msg);
    setVerifyEmailSuccess("");
  };

  // const handleKeyUp = (event) => {
  //   const form = event.target.form;
  //   const index = Array.prototype.indexOf.call(form, event.target);
  //   if (event.keyCode === 8 || event.keyCode === 37) {
  //     if (index > 0) {
  //       form.elements[index - 1].focus();
  //       event.preventDefault();
  //     }
  //   } else if (
  //     (event.keyCode >= 48 && event.keyCode <= 57) ||
  //     (event.keyCode >= 65 && event.keyCode <= 90) ||
  //     (event.keyCode >= 96 && event.keyCode <= 105) ||
  //     event.keyCode === 39
  //   ) {
  //     if (index < 3) {
  //       form.elements[index + 1].focus();
  //       event.preventDefault();
  //     } else {
  //       form.elements[index].blur();
  //     }
  //   }
  // };

  useEffect(() => {
    if (!canResendOtp) {
      const interval = setInterval(() => {
        if (countDown > 0) {
          setCountDown(countDown - 1);
        } else {
          setCanResendOtp(true);
          setCountDown(60);
          return () => clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [canResendOtp, countDown]);

  const verifyOtpSuccessCallback = () => {
    toggleProgress();
    toggleSideMenu();
    togglePersonalDetailsMenu();
  };

  const verifyOtpFailureCallback = (msg) => {
    toggleProgress();
    setErrorMessage(msg);
  };

  const otpClickHandler = () => {
    if (!progress && canResendOtp) {
      toggleProgress();
      setCanResendOtp(false);
      verifyEmail({
        email,
        verifyEmailSuccessCallback,
        verifyEmailFailureCallback,
      });
    }
  };

  if (width < 1100) {
    return (
      <ConsumerOTPModalBarDiv openSideMenu={openSideMenu}>
        <CancelImage src={CANCEL_IMAGE} onClick={toggleSideMenu} alt="Cancel" />
        <OtpDiv>
          <OneTimeOtpText>
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"WE_HAVE_SENT_A_4_DIGIT"} />
            <br />
            {email}
          </OneTimeOtpText>
          <ConsumResultNewOtpInput
            type="text"
            pattern="\d*"
            maxLength={4}
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {verfiyEmailSuccess != null && (
            <OneTimeOtpText style={{ color: "green" }}>
              {verfiyEmailSuccess}
            </OneTimeOtpText>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ResendOtpButton onClick={otpClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"RESEND_OTP"} />
            </ResendOtpButton>
            {countDown !== 60 && (
              <ResendOtpButton><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"RESEND_OTP_IN"} />{countDown}s</ResendOtpButton>
            )}
          </div>

          <VerifyEmailButton
            type="submit"
            onClick={() => {
              if (!progress) {
                toggleProgress();
                verifyEmailOTP({
                  email,
                  otp,
                  verifyOtpSuccessCallback,
                  verifyOtpFailureCallback,
                });
              }
            }}
            disabled={otp.length < 4}
            isDisabled={otp.length < 4}
          >
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"VERIFY_EMAIL"} />
          </VerifyEmailButton>
          {/* <Formik
            initialValues={{
              otpOne: "",
              otpTwo: "",
              otpThree: "",
              otpFour: "",
            }}
            onSubmit={(values, actions) => {
              const { otpOne, otpTwo, otpThree, otpFour } = values;

              verifyEmailOTP({
                email,
                otp: `${otpOne}${otpTwo}${otpThree}${otpFour}`,
                verifyOtpSuccessCallback,
                verifyOtpFailureCallback,
              });
            }}
            validationSchema={otpValidationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <ConsumerOtpInputDiv autoComplete="off">
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpOne}
                      onChange={formikProps.handleChange("otpOne")}
                      onBlur={formikProps.handleBlur("otpOne")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpTwo}
                      onChange={formikProps.handleChange("otpTwo")}
                      onBlur={formikProps.handleBlur("otpTwo")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpThree}
                      onChange={formikProps.handleChange("otpThree")}
                      onBlur={formikProps.handleBlur("otpThree")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpFour}
                      onChange={formikProps.handleChange("otpFour")}
                      onBlur={formikProps.handleBlur("otpFour")}
                    />
                  </ConsumerOtpInputDiv>
                  <ResendOtpButton
                    onClick={() => {
                      verifyEmail({
                        email,
                        verifyEmailSuccessCallback,
                        verifyEmailFailureCallback,
                      });
                    }}
                  >
                    Resend OTP
                  </ResendOtpButton>
                  <VerifyEmailButton
                    type="submit"
                    onClick={formikProps.handleSubmit}
                    disabled={!(formikProps.isValid && formikProps.dirty)}
                    isDisabled={!(formikProps.isValid && formikProps.dirty)}
                  >
                    Verify Email
                  </VerifyEmailButton>
                </>
              );
            }}
          </Formik> */}
        </OtpDiv>
        <SideBarFooter />
      </ConsumerOTPModalBarDiv>
    );
  } else {
    return (
      <ConsumerResultSideBarDiv openSideMenu={openSideMenu}>
        <CancelImage src={CANCEL_IMAGE} onClick={toggleSideMenu} alt="Cancel" />
        <OtpDiv>
          <OneTimeOtpText>
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"WE_HAVE_SENT_A_4_DIGIT"} />
            <br />
            {email}
          </OneTimeOtpText>
          <ConsumResultNewOtpInput
            type="text"
            pattern="\d*"
            maxLength={4}
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {verfiyEmailSuccess != null && (
            <OneTimeOtpText style={{ color: "green" }}>
              {verfiyEmailSuccess}
            </OneTimeOtpText>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ResendOtpButton onClick={otpClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"RESEND_OTP"} />
            </ResendOtpButton>
            {countDown !== 60 && (
              <ResendOtpButton><LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"RESEND_OTP_IN"} /> {countDown}s</ResendOtpButton>
            )}
          </div>

          <VerifyEmailButton
            type="submit"
            onClick={() => {
              if (!progress) {
                toggleProgress();
                verifyEmailOTP({
                  email,
                  otp,
                  verifyOtpSuccessCallback,
                  verifyOtpFailureCallback,
                });
              }
            }}
            disabled={otp.length < 4}
            isDisabled={otp.length < 4}
          >
            <LanguageSelector selectedCountry={selectedCountry || "Singapore"} text={"VERIFY_EMAIL"} />
          </VerifyEmailButton>
          {/* <Formik
            initialValues={{
              otpOne: "",
              otpTwo: "",
              otpThree: "",
              otpFour: "",
            }}
            onSubmit={(values, actions) => {
              const { otpOne, otpTwo, otpThree, otpFour } = values;

              verifyEmailOTP({
                email,
                otp: `${otpOne}${otpTwo}${otpThree}${otpFour}`,
                verifyOtpSuccessCallback,
                verifyOtpFailureCallback,
              });
            }}
            validationSchema={otpValidationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <ConsumerOtpInputDiv autoComplete="off">
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpOne}
                      onChange={formikProps.handleChange("otpOne")}
                      onBlur={formikProps.handleBlur("otpOne")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpTwo}
                      onChange={formikProps.handleChange("otpTwo")}
                      onBlur={formikProps.handleBlur("otpTwo")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpThree}
                      onChange={formikProps.handleChange("otpThree")}
                      onBlur={formikProps.handleBlur("otpThree")}
                    />
                    <ConsumerResultOtpInput
                      type="text"
                      pattern="\d*"
                      maxLength={1}
                      onKeyUp={handleKeyUp}
                      value={formikProps.values.otpFour}
                      onChange={formikProps.handleChange("otpFour")}
                      onBlur={formikProps.handleBlur("otpFour")}
                    />
                  </ConsumerOtpInputDiv>
                  <ResendOtpButton
                    onClick={() => {
                      verifyEmail({
                        email,
                        verifyEmailSuccessCallback,
                        verifyEmailFailureCallback,
                      });
                    }}
                  >
                    Resend OTP
                  </ResendOtpButton>
                  <VerifyEmailButton
                    type="submit"
                    onClick={formikProps.handleSubmit}
                    disabled={!(formikProps.isValid && formikProps.dirty)}
                    isDisabled={!(formikProps.isValid && formikProps.dirty)}
                  >
                    Verify Email
                  </VerifyEmailButton>
                </>
              );
            }}
          </Formik> */}
        </OtpDiv>
        <SideBarFooter />
      </ConsumerResultSideBarDiv>
    );
  }
};

export default ConsumerResultSideBar;
