import styled from "styled-components";

//splits screen in half
export const PreviewSupervisorColorPickerColumn = styled.div`
  float: left;
  width: 50%;
  padding: 30px;
`;

export const PreviewSupervisorTouchUpContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PreviewSupervisorDashboardHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  /* @media (max-width: 1500px) {
    visibility: hidden;
    padding: 0px;
  } */
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const TouchUpText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const ColourPickerTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
`;

export const PreviewSupervisorBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
`;

export const PreviewSupervisorBodyOptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ShadesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 4vw;
`;

export const BodyImageOne = styled.img`
  height: 400px;
  width: 400px;
  border-radius: 20px;
  object-fit: cover;
`;

export const BodyImageTwo = styled.img``;

export const OptionText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin: 10px 0 0 0;
`;

export const ColorPaletteText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 10px 0 4px 0;
`;

export const ColorPaletteDivContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectColorPaletteText = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  margin: 20px 0 0 0;
`;

export const ButtonBar = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 100px;
`;

export const BackButton = styled.button`
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  outline: none;
  padding: 4px 30px;
  margin: 0 10px;
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const InputButton = styled.input`
  display: none;
`;

export const InputLabel = styled.label`
  display: flex;
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  outline: none;
  padding: 5px 60px;
  margin: 0 10px;
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ResetButton = styled.button`
  background: #ffffff;
  outline: none;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  margin: 0 10px;
  padding: 4px 18px;
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  outline: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: 0 10px;
  padding: 4px 30px;
  margin-bottom: 10px;
  border: 1px solid #2fc38c;
  :hover {
    cursor: pointer;
  }
`;

export const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const ColorPaletteDiv = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 3px;
  margin: 4px;
  position: relative;
  display: inline-block;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const SelectColourButton = styled.button`
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #2fc38c;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  height: 40px;
  width: 140px;
  :hover {
    cursor: pointer;
  }
`;

export const LeadColourSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
`;

export const LeadColourDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const LeadColor = styled.div`
  background-color: ${(props) => `${props.backgroundColour}`};
  width: 60px;
  height: 40px;
  transition: transform 0.2s;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const CancelDrawerImage = styled.img`
  align-self: flex-end;
  margin: 20px 30px;
  :hover {
    cursor: pointer;
  }
`;

export const SearchBarMainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ColorBodyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ColorDiv = styled.div`
  border-radius: 3px;
  width: 80px;
  height: 80px;
  margin: 10px;
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const ImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const ImageCanvas = styled.canvas`
  dislay: flex;
  position: relative;
  left: 0;
  right: 0;
`;

export const ResultCanvas = styled.canvas`
  position: relative;
`;

export const Svg = styled.canvas`
  :hover {
    cursor: pointer;
  }
`;

export const TabDiv = styled.div`
  width: 92%;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 50px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  background-color: white;
  // -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
  // -moz-box-shadow: 0 0 5px rgba(0,0,0,0.3);
`;

export const ButtonDiv = styled.div`
  height: 50px;
  border-bottom: 1px solid #d3d3d3;
`;

export const TabDivButton = styled.button`
  width: 50%;
  height: 100%;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  padding 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background-color: white;
  &:hover {
    background-color: #DCDCDC;
  }
`;

export const PaletteSideBarHeaderDiv = styled.div`
  display: flex;
  padding: 0px 25px;
  align-items: center;
`;

export const PaletteSideBarHeaderText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
  margin: 0 10px;
`;

export const PaletteSideBearHeaderImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  margin: 4px 0;
  color: #767676;
  @media (max-width: 1100px) {
    margin: 4px 10px;
  }
`;

export const PaletteOptionDiv = styled.div`
  width: 92%;
  height: 50px;
  margin: 0 auto;
`;

export const PaletteOptionButtonDiv = styled.div`
  height: 50px;
`;

export const PaletteOptionButton = styled.button`
  width: 50%;
  height: 100%;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  padding 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background-color: white;
`;

export const TouchupOptionsIconImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const ColorIconOptionDiv = styled.img`
  margin-right: 20px;
`;

export const HiddenDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const ZoomToolIcons = styled.img`
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
  width: 30px;
  height: 30px;
`;

export const FilterPath = styled.div`
  mix-blend-mode: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;
