import React from "react";
import {
  NearByPainters,
  BottomText,
  BodyDiv,
  NearByPainterImage,
  NearByPainterTitleText,
  NearByPainterSubTitleText,
} from "../../styles/ConsumerDetailReportStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import PAINTER_IMAGE from "../../images/painter_image.png";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportPainter = ({ list, selectedCountry }) => {
  return (
    <NearByPainters>
      <BottomText>
      <LanguageSelector selectedCountry={selectedCountry} text={"NEARBY_PAINTERS"} />
      </BottomText>
      <NearByPainterTitleText style={{marginTop: "10px"}}><div style={{display:"flex"}}><LanguageSelector selectedCountry={selectedCountry} text={"COMING_SOON"} /></div></NearByPainterTitleText>
      {list !== undefined &&
        list.length > 0 &&
        list.map((data, index) => {
          return (
            <>
            </>
            // <NearByPaintBodyDiv style={{ margin: "10px 10px" }} key={`${index}`}>
            //   <NearByPainterImage src={PAINTER_IMAGE} />
            //   <div style={{ margin: "10px 10px" }}>
            //     <NearByPainterTitleText>
            //       {data.shop_name}
            //     </NearByPainterTitleText>
            //     {/* <NearByPainterSubTitleText>
            //       {data.phone}
            //     </NearByPainterSubTitleText> */}
            //   </div>
            // </NearByPaintBodyDiv>
          );
        })}
    </NearByPainters>
  );
};

export default ConsumerDetailReportPainter;
