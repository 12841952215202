import styled from "styled-components";

export const AdminLeftNavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-self: flex-start;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const DeluxePreviewImage = styled.img`
  object-fit: contain;
  :hover {
    cursor: pointer;
  }
`;

export const DrawerMenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const DrawerMenuButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-size: 18px;
  line-height: 22px;
  color: #012169;
  margin: 5px 0;
  outline: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: ${(props) =>
    props.isSelected === true ? "3px solid #012169" : "none"};
  background: ${(props) =>
    props.isSelected === true ? "#f1f1f1" : "transparent"};
  padding: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 5px 0;
  outline: none;
  border: none;
  background: none;
  padding: 10px;
  :hover {
    cursor: pointer;
  }
`;
