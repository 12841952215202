import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminSearchRequestSideBarContainer,
  AdminSearchRequestHeader,
  SearchRequestsText,
  ByCountryText,
  AdminRequestSearchCountryDiv,
  AdminCountryText,
  ButtonBar,
  ResetFilterButton,
  DateRow,
  SelectedCountryCheck,
  AdminSearchRequestModalBarDiv,
  DoneButton,
} from "../../styles/AdminRequestStyles";
import dayjs from "dayjs";
import SEARCH_FILTER from "../../images/search_filter.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import "../../styles/floatingLabelInput.css";
import { LEAD_COLOUR, REFERENCE, THEME } from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector";

const AdminSearchRequests = ({
  openSideMenu,
  toggleSearchRequest,
  setTempRequestList,
  setSelectedPageForPending,
  setSelectedPageForResolved,
  setPaginationList,
  tabSelected,
  requestList,
  width,
  fromDate,
  toDate,
  selectedCountries,
  selectedReColourType,
  setFromDate,
  setToDate,
  setSelectedCountries,
  setSelectedRecolourType,
  initialFilter
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  
  const countries = [
    "Singapore",
    "India",
    "Indonesia",
    "Pakistan",
    "Vietnam",
    "Thailand",
    "Malaysia",
    "Sri Lanka",
  ];
  const reColorType = [LEAD_COLOUR, REFERENCE, THEME];

  const countrySelectedHandler = (item) => {
    if (item === "All") {
    } else {
      if (selectedCountries.includes(item)) {
        setSelectedCountries(
          selectedCountries.filter((filteredItem) => filteredItem !== item)
        );
      } else {
        setSelectedCountries((oldArray) => [...oldArray, item]);
      }
    }
  };

  const reColorTypeSelectedHandler = (item) => {
    if (selectedReColourType.includes(item)) {
      setSelectedRecolourType(
        selectedReColourType.filter((filteredItem) => filteredItem !== item)
      );
    } else {
      setSelectedRecolourType((oldArray) => [...oldArray, item]);
    }
  };

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  const resetFilterClickHandler = () => {
    setFromDate("")
    setToDate("")
    setSelectedCountries([]);
    setSelectedRecolourType([]);
    setTempRequestList(requestList);
    setSelectedPageForPending(1);
    setSelectedPageForResolved(1);
    initialFilter();
    toggleSearchRequest();
  };

  const applyFilterHandler = () => {
    let newList = [],
      tempNewList = [],
      filteredList = requestList;
    console.log(filteredList);
    if (
      selectedCountries.length > 0 ||
      selectedReColourType.length > 0 ||
      (fromDate !== "" && toDate !== "")
    ) {
      if (
        fromDate !== "" &&
        toDate !== dayjs().format("DD/MM/YYYY")
      ) {
        tempNewList = [];
        const unixFromDate = dayjs(fromDate).unix();
        const unixToDate = dayjs(toDate).unix();
        for (let i = 0; i < requestList.length; i++) {
          const serverUnixDate = dayjs(requestList[i].timestamp).unix();
          if (serverUnixDate <= unixToDate && serverUnixDate >= unixFromDate) {
            tempNewList.push(filteredList[i]);
          }
        }
        console.log("date");
        filteredList = tempNewList;
        console.log(filteredList);
      }

      if (selectedCountries.length > 0) {
        // tempNewList = [];
        // for (let i = 0; i < selectedCountries.length; i++) {
        //   console.log(selectedCountries[i]);
        //   tempNewList.push(
        //     ...filteredList.filter(
        //       (data) => data.country === selectedCountries[i]
        //     )
        //   );
        // }
        // console.log("countries");
        // filteredList = tempNewList;
        // console.log(filteredList);

        if (selectedCountries.length > 0) {
          for (let i = 0; i < selectedCountries.length; i++) {
            filteredList = requestList.filter(
              (data) => data.country === selectedCountries[i]
            );
            newList.push(...filteredList);
          }


      }
      console.log("countries");
      filteredList = newList;
      console.log("filtered list", filteredList);
      }

      if (selectedReColourType.length > 0) {
        // tempNewList = [];
        // for (let i = 0; i < selectedReColourType.length; i++) {
        //   tempNewList.push(
        //     ...filteredList.filter(
        //       (data) => data.colourization_type === selectedReColourType[i]
        //     )
        //   );
        // }
        // console.log("type");
        // filteredList = tempNewList;
        // console.log(filteredList);

        for (let i = 0; i < selectedReColourType.length; i++) {
          filteredList = requestList.filter(
            (data) => data.colourization_type === selectedReColourType[i]
          );
          newList.push(...filteredList);
        }
        console.log("type");
        filteredList = newList;
        console.log(filteredList);
      }
      newList = filteredList;

      setSelectedPageForPending(1);
      setSelectedPageForResolved(1);
      setTempRequestList(newList);
      updatePagination(
        tabSelected === 0
          ? newList.filter((data) => data.status === "Pending")
          : tabSelected === 1
          ? newList.filter((data) => data.status === "Resolved")
          : newList.filter((data) => data.status === "Archived")
      );
      toggleSearchRequest();
    } else {
      resetFilterClickHandler();
    }
  };

  if (width < 1100) {
    return (
      <AdminSearchRequestModalBarDiv openSideMenu={openSideMenu}>
        <AdminSearchRequestHeader>
          <img src={SEARCH_FILTER} alt="Search" />
          <SearchRequestsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_REQUESTS"}
        /></SearchRequestsText>
        </AdminSearchRequestHeader>
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
        {countries.map((value, index) => {
          return (
            <AdminRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedCountries.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => countrySelectedHandler(value)}
              />
              <AdminCountryText isSelected={true}>{value}</AdminCountryText>
            </AdminRequestSearchCountryDiv>
          );
        })}
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_DATE"}
        /></ByCountryText>
        <DateRow>
          <div
            className="form-control"
            style={{ display: "flex", alignSelf: "center" }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
            />
            <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"FROM"}
        /></label>
            <div className="border-around"></div>
          </div>
          <div style={{ width: "30px" }} />
          <div
            className="form-control"
            style={{
              display: "flex",
              alignSelf: "center",
              margin: "0 20px",
            }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
            />
            <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TO"}
        /></label>
            <div className="border-around"></div>
          </div>
        </DateRow>

        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_RECOLOUR_TYPE"}
        /></ByCountryText>
        {reColorType.map((value, index) => {
          return (
            <AdminRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedReColourType.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check box"
                onClick={() => reColorTypeSelectedHandler(value)}
              />
            {value == "Lead Colour" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            /> </AdminCountryText>
            )}

            {value == "Reference" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE"}
            /> </AdminCountryText>
            )}
          
            {value == "Theme" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            /> </AdminCountryText>
            )}
            </AdminRequestSearchCountryDiv>
          );
        })}
        <ButtonBar>
          <ResetFilterButton onClick={resetFilterClickHandler}>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
          </ResetFilterButton>
          <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
        </ButtonBar>
      </AdminSearchRequestModalBarDiv>
    );
  } else {
    return (
      <AdminSearchRequestSideBarContainer openSideMenu={openSideMenu}>
        <AdminSearchRequestHeader>
          <img src={SEARCH_FILTER} alt="Search" />
          <SearchRequestsText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SEARCH_REQUESTS"}
        /></SearchRequestsText>
        </AdminSearchRequestHeader>
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_COUNTRY"}
        /></ByCountryText>
        {countries.map((value, index) => {
          return (
            <AdminRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedCountries.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check"
                onClick={() => countrySelectedHandler(value)}
              />
              <AdminCountryText isSelected={true}>{value}</AdminCountryText>
            </AdminRequestSearchCountryDiv>
          );
        })}
        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_DATE"}
        /></ByCountryText>
        <DateRow>
          <div
            className="form-control"
            style={{ display: "flex", alignSelf: "center" }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
            />
            <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"FROM"}
        /></label>
            <div className="border-around"></div>
          </div>
          <div style={{ width: "30px" }} />
          <div
            className="form-control"
            style={{
              display: "flex",
              alignSelf: "center",
              margin: "0 20px",
            }}
          >
            <input
              required
              type="date"
              name="input"
              style={{ height: "40px" }}
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
            />
            <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TO"}
        /></label>
            <div className="border-around"></div>
          </div>
        </DateRow>

        <ByCountryText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"BY_RECOLOUR_TYPE"}
        /></ByCountryText>
        {reColorType.map((value, index) => {
          return (
            <AdminRequestSearchCountryDiv key={`${index}`}>
              <SelectedCountryCheck
                src={
                  selectedReColourType.includes(value)
                    ? GREEN_CHECKBOX_CHECKED_IMAGE
                    : GREEN_CHECKBOX_UNCHECKED_IMAGE
                }
                alt="Check box"
                onClick={() => reColorTypeSelectedHandler(value)}
              />
              {value == "Lead Colour" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            /> </AdminCountryText>
            )}

            {value == "Reference" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE"}
            /> </AdminCountryText>
            )}
          
            {value == "Theme" && (
              <AdminCountryText isSelected={true}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            /> </AdminCountryText>
            )}
            </AdminRequestSearchCountryDiv>
          );
        })}
        <ButtonBar>
          <ResetFilterButton onClick={resetFilterClickHandler}>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"RESET_FILTER"}
        />
          </ResetFilterButton>
          <DoneButton onClick={applyFilterHandler}><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DONE"}
        /></DoneButton>
        </ButtonBar>
      </AdminSearchRequestSideBarContainer>
    );
  }
};

export default AdminSearchRequests;
