import React from "react";
import {
  LegacyContainer,
  LegacyBody,
  LegacyHeaderText,
  LegacyText,
  BodyText,
} from "../../styles/LegalStyles";

const LegalBodyIndonesia = () => {
  return (
    <LegacyContainer>
      <LegacyBody>
        <LegacyHeaderText>
          SILAKAN MEMBACA SYARAT DAN KETENTUAN INI DENGAN SAKSAMA SEBELUM
          MENGGUNAKAN SITUS INI
        </LegacyHeaderText>
        <LegacyText>Syarat dan Ketentuan Umum Penggunaan</LegacyText>
        <BodyText>
          Syarat dan Ketentuan Umum Penggunaan ini (bersama dengan Kebijakan
          Privasi) (“Persyaratan”) adalah persyaratan yang membolehkan Anda
          menggunakan situs web yang terletak pada URL [**] (“Situs Web”).
          Penggunaan Situs Web termasuk mengakses, meramban, atau mendaftar
          untuk menggunakan Situs Web. Silakan membaca Persyaratan ini secara
          saksama sebelum Anda mulai menggunakan Situs Web. Kami menyarankan
          agar Anda mencetak salinan ini untuk referensi mendatang. Dengan
          menggunakan Situs Web, Anda mengkonfirmasi bahwa Anda menerima
          Persyaratan ini dan Anda setuju untuk mematuhinya. Jika Anda tidak
          setuju dengan Persyaratan ini, Anda tidak boleh menggunakan Situs Web.
          Jika Anda tidak setuju dengan amandemen apapun yang kami buat pada
          Persyaratan ini kapan pun di kemudian hari, Anda harus segera berhenti
          menggunakan Situs Web.
        </BodyText>
        <LegacyHeaderText>Objek</LegacyHeaderText>
        <BodyText>
          Situs Web ini adalah platform berbasis teknologi dan para penggunanya
          dapat mengirimkan permintaan kepada kami untuk menyediakan berbagai
          skema warna, atau opsi warna untuk proyek pengecatan milik mereka
          secara gratis. Fasilitas ini disediakan berdasarkan pro-bono demi
          kepentingan Pengguna. Pengguna sangat disarankan untuk bertindak
          secara bijaksana (serta tidak hanya membalas atau menjawab fasilitas
          ini) dan dapat menyimpulkan pandangan independen mereka mengenai skema
          warna sebelum mengambil keputusan akhir. Situs Web ini dioperasikan
          oleh PT ICI Paints Indonesia (“AkzoNobel”) yang didirikan berdasarkan
          Undang Undang Perusahaan Indonesia, 2013 dengan kantor yang terdaftar
          di [∙] bertuliskan CIN [∙]. Pelanggan, Konsumen, Dealer dan Pengecat
          potensial secara bersama-sama dirujuk sebagai “Pengguna”. Rujukan apa
          pun kepada “Anda” atau “milik Anda” atau merujuk ke Pengguna atau
          pengunjung ke Situs Web (tergantung keadaannya); dan rujukan apa pun
          kepada “kami”, “milik kami” dan “oleh kami”, akan merujuk ke
          AkzoNobel.
        </BodyText>
        <LegacyHeaderText>Perubahan persyaratan dan platform</LegacyHeaderText>
        <BodyText>
          Kami dapat merevisi Persyaratan ini sewaktu-waktu dengan mengubah
          halaman ini. Silakan cek halaman ini dari waktu ke waktu untuk
          mengetahui segala perubahan yang kami buat, karena perubahan tersebut
          mengikat Anda. Kami dapat, tanpa pemberitahuan sebelumnya, mengubah
          sifat Platform; menambah atau menghapus berbagai fungsionalitas atau
          fitur; atau menghentikan penyediaan Platform atau fitur Platform, bagi
          Anda atau untuk para pengguna secara umum.
        </BodyText>
        <LegacyHeaderText>Perubahan pada Situs Web</LegacyHeaderText>
        <BodyText>
          Kami dapat memperbarui Situs Web dari waktu ke waktu dan mengubah
          Konten situs sewaktu-waktu. Dari segi Persyaratan, istilah (“Konten”)
          berarti desain atau rancangan, tata letak, teks, gambar, grafik,
          suara, video apapun, dsb, atau informasi, materi/bahan atau file
          lainnya, termasuk, tanpa terbatas pada, nama atau sejenisnya
          (semuanya, baik mengenai Anda atau pihak ketiga) atau materi/bahan
          lainnya yang diunggah oleh Pengguna (sebagai kontributor), penyedia
          layanan pihak ketiga atau oleh kami. Namun demikian, harap
          diperhatikan bahwa segala Konten pada Situs Web mungkin sudah
          ketinggalan zaman pada waktu tertentu, dan kami tidak berkewajiban
          untuk memperbaharuinya. Kami tidak menjamin bahwa Situs Web, atau
          Konten apapun pada situs tersebut akan bebas dari kesalahan atau
          kelalaian.
        </BodyText>
        <LegacyHeaderText>Mengakses Situs Web</LegacyHeaderText>
        <BodyText>
          Kami tidak menjamin, bahwa penggunaan Anda atas Situs Web, atau Konten
          apapun pada situs tersebut, akan selalu tersedia atau tidak terganggu.
          Akses ke Situs Web diizinkan berdasarkan waktu temporer. Kami dapat
          menangguhkan, menarik kembali, tidak melanjutkan atau mengubah semua
          atau sebagian Situs Web tanpa pemberitahuan. Kami tidak bertanggung
          jawab terhadap Anda, jika, karena alasan apapun, Situs Web ini tidak
          tersedia sewaktu-waktu atau untuk jangka waktu kapan pun.
        </BodyText>
        <LegacyHeaderText>Perjanjian Pengguna</LegacyHeaderText>
        <BodyText>
          HAK KEKAYAAN INTELEKTUAL Selain dari hal yang secara tegas dinyatakan,
          kami atau perusahaan grup kami memiliki semua hak kekayaan intelektual
          di Situs dan dalam materi yang dipublikasikan di situs (termasuk
          tetapi tidak terbatas pada teks, gambar, audio, video, kode html, dan
          tombol) ("Konten" ). Semua hak, termasuk tetapi tidak terbatas pada
          semua hak cipta, hak database, nama dagang dan merek dagang. Anda
          hanya boleh melihat, mencetak dan menggunakan Situs serta hasil
          Pratinjau untuk penggunaan Anda secara pribadi, dan tidak
          diperjual-belikan (non-commercial). Kami dan perusahaan grup kami
          secara tegas berhak atas semua hak kekayaan intelektual dalam dan pada
          Situs serta Konten dan penggunaan Anda atas Situs serta Konten
          tersebut tunduk pada pembatasan berikut ini. Anda tidak diperbolehkan
          untuk: Menghapus hak cipta apa pun atau pemberitahuan kepemilikan
          apapun yang dimuat dalam Konten; penggunaan Konten apapun dari Situs
          dalam cara apapun yang dapat melanggar segala hak cipta, hak kekayaan
          intelektual atau hak kepemilikan kami atau pihak ketiga mana pun; atau
          mereproduksi, memodifikasi, menampilkan, melakukan, menerbitkan,
          mendistribusikan, menyebarkan, menyiarkan, menyampaikan kepada publik
          atau mengedarkan kepada pihak ketiga mana pun, atau mengeksploitasi
          Situs dan/atau Konten dengan cara apapun, termasuk untuk tujuan
          komersial, tanpa izin tertulis dari kami sebelumnya. Kami secara tegas
          berhak dalam dan atas nama ranah www.duluxpreviewservice.com serta
          semua ranah dan sub-ranah terkait, nama "AkzoNobel”, logo kami dan
          merek layanan, nama dagang dan/atau merek dagang. Merek dagang, produk
          dan nama perusahaan lainnya yang disebutkan pada Situs kami, mungkin
          merupakan merek dagang dari pemilik atau pemberi lisensinya
          masing-masing dan hak atas merek tersebut dilindungi oleh
          undang-undang untuk masing-masing pemilik atau pemberi lisensinya.
          AKURASI WARNA Kami telah melakukan segala upaya untuk membuat warna
          pada layar seakurat mungkin. Namun sayang, kami tidak dapat menjamin
          kesesuaian warna secara tepat pada warna layar seperti warna produk
          yang sesungguhnya, dan harap jangan mengandalkan warna yang tampak
          pada layar sebagaimana adanya. Warna pada layar dapat bervariasi,
          tergantung pada pengaturan dan resolusi layar Anda. Jika Anda tidak
          yakin mengenai warnanya, harap merujuk ke Dulux Colour Card (Kartu
          Warna Dulux) dan Dulux Fandeck di gerai dealer untuk mengetahui warna
          yang lebih akurat. AKURASI INFORMASI DAN KONTEN Konten dan informasi
          yang tersedia pada Situs kami adalah gratis dan hanya untuk tujuan
          informasi serta tidak membentuk suatu hubungan layanan bisnis atau
          profesional antara Anda dan kami. Kami telah melakukan segala upaya
          yang wajar untuk memastikan bahwa semua informasi dan Konten yang
          tersedia pada Situs ini adalah akurat pada waktu penyertaannya, namun
          demikian, mungkin ada kesalahan yang tidak disengaja dan sesekali
          terjadi. Situs dan informasi serta Konten pada Situs, tersedia “apa
          adanya”. Kami tidak menjamin, dan tidak membuat pernyataan atau
          jaminan dalam bentuk apapun, baik tersurat maupun tersirat tentang
          informasi, dan Konten yang disediakan melalui Situs kami, termasuk
          item apa pun yang digunakan, baik secara langsung maupun tidak
          langsung dari Situs kami, dan kami berhak untuk sewaktu-waktu membuat
          perubahan serta mengoreksi, tanpa pemberitahuan. Komentar dan
          informasi yang ditampilkan pada Situs ini tidak dimaksudkan sebagai
          saran yang dapat diandalkan. Kami tidak bertanggung jawab atas segala
          hal yang tidak akurat atau terabaikan di Situs, dan keputusan apapun
          berdasarkan informasi dan opini yang dimuat dalam Situs, adalah
          tanggung jawab pengguna semata-mata. MENGUNGGAH KE, ATAU MENAMPILKAN
          MATERI PADA SITUS KAMI Bilamana Anda memanfaatkan suatu fitur yang
          memperbolehkan Anda untuk mengunggah konten ke Situs kami, Anda harus
          mematuhi standar konten yang diuraikan dalam Kebijakan Penggunaan yang
          Dapat Diterima. Anda menjamin, bahwa setiap kontribusi tersebut
          mematuhi standar yang dimaksudkan, dan Anda akan bertanggung jawab
          terhadap kami dan memberikan ganti-rugi kepada kami atas setiap
          pelanggaran dari jaminan itu. Hal ini berarti, bahwa Anda akan
          bertanggung jawab atas segala kerugian atau kerusakan yang kami terima
          akibat pelanggaran Anda terhadap jaminan tersebut. Anda memberikan
          kepada kami, lisensi yang bersifat non-eksklusif, abadi, tidak dapat
          dicabut kembali, dapat dipindahtangankan, bebas royalti (termasuk hak
          penuh untuk sub-lisensi) untuk menggunakan konten apapun yang Anda
          unggah ke, atau dikirimkan ke Situs kami ("Konten Anda") (termasuk,
          tanpa terbatas pada, hak untuk mengadaptasi, mengganti, memperbaiki
          atau mengubah Konten Anda) di media mana pun di seluruh dunia tanpa
          batasan, dan Anda menjamin bahwa materi apa pun yang Anda kirimkan
          adalah karya Anda sendiri atau bahwa Anda telah memperoleh semua hak
          dan izin yang diperlukan dari pemilik yang bersangkutan, dan bahwa
          Anda memiliki semua hak yang relevan dalam Konten Anda untuk
          memungkinkan Anda memberikan hak dalam klausul ini. Anda dengan ini
          dan tanpa syarat serta tidak dapat dicabut kembali, mengesampingkan,
          dan setuju untuk tidak menuntut agar setiap dan semua hak moral serta
          hak serupa lainnya, dan semua hak publisitas serta privasi di negara
          manapun di dunia, sehubungan dengan penyalahgunaan kami atas hak-hak
          dalam Konten Anda yang diberikan di bawah ini, hingga sebatas maksimal
          yang diizinkan oleh hukum. Publikasi Konten Anda akan sesuai dengan
          kebijaksanaan kami semata-mata, dan kami berhak untuk membuat tambahan
          atau penghapusan atas setiap materi tersebut sebelum dipublikasikan,
          setelah publikasi atau menolak untuk memublikasikan. Jika Konten Anda
          memuat materi apa pun yang tidak dimiliki oleh, atau dilisensikan
          kepada Anda dan/atau yang tunduk pada hak pihak ketiga, Anda
          bertanggung jawab untuk mendapatkan, sebelum pengiriman Konten Anda,
          semua rilis, persetujuan dan/atau lisensi yang diperlukan untuk
          mengizinkan penggunaan dan pemanfaatan Konten Anda oleh kami, sesuai
          dengan Ketentuan Penggunaan ini tanpa pembayaran tambahan. Walaupun
          kami tidak berkewajiban untuk memoderasi konten pada Situs kami, namun
          kami berhak untuk memantau informasi apa pun yang ditransmisikan atau
          diterima melalui Situs kami. Kami berhak untuk menolak, memblokir,
          menangguhkan atau menghapus Konten Anda sewaktu-waktu, dan sesuai
          dengan kebijaksanaan kami semata-mata. Kami tidak menjamin dalam cara
          apapun, bahwa Konten Anda atau bagian apa pun dari Konten tersebut,
          akan ditampilkan pada Situs kami. Anda menyatakan, bahwa Anda memiliki
          hak sesuai hukum untuk mengirimkan informasi tersebut dan menyetujui
          bahwa Anda tidak akan mengirimkan informasi apapun, kecuali Anda
          secara hukum berhak untuk melakukannya. Kami berhak untuk menggunakan,
          menyalin, mendistribusikan dan mengungkapkan kepada pihak ketiga,
          setiap konten tersebut untuk tujuan apa pun. Kami juga berhak untuk
          mengungkapkan identitas Anda kepada pihak ketiga mana pun, yang
          mengklaim bahwa materi apa pun yang dikirimkan atau diunggah oleh Anda
          ke Situs kami, merupakan pelanggaran hak kekayaan intelektual, atau
          hak mereka terhadap privasi. Kami tidak akan bertanggung jawab, atau
          mempertanggungjawabkan kepada pihak ketiga mana pun, atas konten atau
          akurasi materi apa pun yang dikirimkan oleh Anda, atau pengguna lain,
          siapapun, atas Situs kami. Kami berhak untuk menghapus konten apa pun
          atau posting yang Anda buat pada Situs kami, atas kebijaksanaan kami
          semata-mata. Kami tidak menerima pengiriman gagasan yang tidak
          diotorisasi di luar hubungan bisnis yang sudah terjalin. Harap tidak
          menyampaikan pengiriman gagasan yang tidak diotorisasi kepada kami
          melalui Situs ini. Gagasan apa pun yang diungkapkan kepada kami di
          luar hubungan bisnis rahasia yang sudah ada sebelumnya dan
          didokumentasikan, menjadi tidak bersifat rahasia, dan oleh karena itu
          kami berhak untuk mengembangkan, menggunakan, menyalin dan/atau
          memanfaatkan secara komersial semua gagasan ini atau yang serupa
          hingga sepenuh-penuhnya, dan tanpa memberikan kompensasi kepada Anda
          atau bertanggung jawab kepada Anda. Dengan mengirimkan gagasan atau
          pengajuan lainnya secara terperinci kepada kami melalui Situs ini,
          Anda setuju untuk terikat oleh persyaratan kebijakan yang dinyatakan
          ini.
        </BodyText>
        <LegacyHeaderText>1. Pendaftaran</LegacyHeaderText>
        <BodyText>
          Situs Web hanya tersedia bagi Pengguna yang terdaftar. Pendaftaran
          akan tersedia bagi Pelanggan yang memiliki kapasitas hukum untuk
          membuat kontrak. Pengguna dapat menjadi Pengguna terdaftar dengan
          mengisi formulir pendaftaran online pada situs web, dengan memberikan
          sebagian informasi dasar (nama, informasi kontak, alamat tempat
          tinggal atau bisnis, dll.). AkzoNobel akan membuat akun ("Akun") untuk
          masing-masing Pengguna setelah pendaftaran, dan menetapkan suatu alias
          pengguna ("ID Pengguna") dan kata sandi ("Kata Sandi") untuk akses
          log-in ke Akun Anda. AkzoNobel juga akan menetapkan Pengguna setelah
          pendaftaran, satu akun email/pesan berbasis web dengan ruang
          penyimpanan terbatas untuk mengirim atau menerima email/pesan. Semua
          komunikasi antara Pengecat dan Konsumen hanya dapat diprakarsai oleh
          Konsumen. Konsumen tidak akan diperbolehkan mengirim pesan kepada
          Pengguna lainnya. Pengguna akan bertanggung jawab atas konten semua
          pesan yang dikomunikasikan melalui Akun mereka, serta konsekuensi dari
          pesan tersebut. Anda dapat meninjau informasi pribadi yang Anda
          berikan kepada kami, dan membuat perubahan apa pun yang diinginkan
          pada informasi, atau pada pengaturan untuk akun Anda pada Platform ini
          sewaktu-waktu, dengan log in ke akun Anda dan menyunting informasi
          pada halaman profil Anda. Anda sebagai Konsumen menyatakan dan
          menjamin, bahwa jika Anda adalah seorang individu, (1) Anda berusia di
          atas delapan belas tahun atau melampaui batas usia tersebut
          sebagaimana diperbolehkan secara hukum di negara yang bersangkutan
          untuk mengadakan kontrak, atau (ii) bahwa jika Anda mendaftar atas
          nama suatu entitas yang mewenangkan Anda untuk mengadakan, dan
          mengikat entitas tersebut dengan Persyaratan, dan mendaftar di
          Platform, sesuai dengan undang-undang di Indonesia. Siapa pun yang
          berusia di bawah delapan belas (18) tahun, yang mengakses Situs Web
          ini, hanya boleh melakukannya di bawah panduan orangtua. Jika kami
          mengubah kriteria kelayakan untuk terdaftar pada Situs Web, dan Anda
          tidak lagi mematuhi kriteria kelayakan baru, sebagaimana ditentukan
          oleh kami, sesuai dengan kebijaksanaan kami semata-mata, Anda menerima
          bahwa kami dapat menutup Akun Anda tanpa pertanggungjawaban apa pun
          pada kami. Anda bertanggung jawab sepenuhnya untuk memastikan, bahwa
          semua Persyaratan ini mematuhi semua undang-undang, aturan dan
          peraturan yang berlaku bagi Anda, dan hak untuk mengakses Platform
          dicabut bilamana Persyaratan atau penggunaan Platform ini dilarang
          dan, dalam keadaan seperti itu, Anda setuju untuk tidak untuk
          menggunakan atau mengakses Situs Web atau Platform dengan cara apa
          pun. Jika Anda adalah Konsumen yang mendaftar atas nama entitas
          bisnis, Anda menyatakan, bahwa entitas bisnis dan (a) Anda berwenang
          untuk mengikat entitas dengan syarat dan ketentuan penggunaan dan/atau
          Perjanjian ini; (b) alamat yang Anda gunakan ketika mendaftar, adalah
          tempat utama bisnis dari entitas bisnis tersebut; dan (c) semua
          informasi lainnya yang dikirimkan ke AkzoNobel selama proses
          pendaftaran adalah benar, akurat, terbaru dan lengkap. Dengan menjadi
          Pengguna terdaftar, Anda menyetujui penyertaan data pribadi Anda dalam
          database online kami dan mewenangkan AkzoNobel untuk berbagi informasi
          tersebut dengan para mitra dan afiliasinya yang mematuhi Kebijakan
          Privasi kami. Anda setuju, bahwa AkzoNobel dapat menangguhkan atau
          mengakhiri Akun Anda sewaktu-waktu tanpa pemberitahuan sebelumnya,
          atau tanpa memberikan alasan apa pun.
        </BodyText>
        <LegacyHeaderText>2. Ketetapan akhir</LegacyHeaderText>
        <BodyText>
          Pengguna akan memastikan, bahwa Konten apa pun yang mereka
          publikasikan atau unggah pada Situs Web, tidak akan:
        </BodyText>
        <ul>
          <li>menyesatkan dengan cara apa pun;</li>
          <li>
            memuat video, foto, atau gambar orang lain (tanpa izin/persetujuan
            secara sukarela dari orang tersebut);{" "}
          </li>
          <li>memuat materi apa pun yang merendahkan siapa pun;</li>
          <li>
            memuat materi apa pun yang bersifat mesum, tidak sopan, penuh
            kebencian atau menghasut;
          </li>
          <li>
            sangat berbahaya, melecehkan, menghujat, memfitnah, mesum,
            pornografi, pedofilik, memfitnah, mengganggu privasi orang lain,
            penuh kebencian, atau rasialis, secara etnis tidak dapat diterima,
            meremehkan, menghubungkan atau mendorong pencucian uang atau
            perjudian, atau melanggar hukum dengan cara apa pun;
          </li>
          <li>melanggar hak kekayaan intelektual milik siapa pun;</li>
          <li>kemungkinan mencederai golongan minoritas dengan cara apa pun</li>
          <li>kemungkinan menipu siapa pun;</li>
          <li>
            dibuat dengan melanggar kewajiban hukum kepada pihak ketiga, seperti
            kewajiban kontrak atau kewajiban kerahasiaan;
          </li>
          <li>mencegah penyelidikan terhadap pelanggaran apa pun;</li>
          <li>menghina bangsa lain mana pun;</li>
          <li>
            mengancam, menyalahgunakan atau menyerang privasi orang lain, atau
            menyebabkan gangguan, ketidaknyamanan, atau kecemasan yang tidak
            perlu;
          </li>
          <li>
            kemungkinan melecehkan, menghina, mengecewakan, mempermalukan,
            meresahkan atau mengganggu orang lain;
          </li>
          <li>
            digunakan untuk menyamar sebagai orang lain siapa pun, atau
            menyesatkan identitas atau afiliasi Anda dengan siapa pun;
          </li>
          <li>
            memberikan kesan, bahwa informasi tersebut berasal dari AkzoNobel,
            dan jika bukan ini masalahnya;
          </li>
          <li>
            mendukung, mempromosikan, atau membantu tindakan yang melanggar
            hukum seperti (hanya dengan cara mencontohkan) pelanggaran hak cipta
            atau penyalahgunaan komputer;
          </li>
          <li>
            merujuk ke situs web, aplikasi atau URL apa pun yang merupakan
            kebijaksanaan kami semata-mata, memuat materi yang tidak sesuai
            untuk Situs Web; atau situs web lain apa pun yang memuat konten yang
            akan dilarang atau melanggar semangat Persyaratan Penggunaan ini.
          </li>
          <li>
            mengganggu penggunaan dan kesenangan Pengguna lain Situs Web ini,
            atau pengguna individual lain dan kesenangan menikmati layanan
            serupa;
          </li>
          <li>
            meminta aktivitas apapun yang kami, dalam kebijaksanaan kami
            semata-mata, yakini merupakan atau bisa dianggap sebagai menyalahi
            hukum;
          </li>
          <li>
            terlibat dalam transmisi “surat sampah”, “surat berantai”, atau
            pengiriman massal yang tidak diminta, atau “spamming”;
          </li>
          <li>
            secara langsung maupun tidak langsung, menawarkan, berupaya
            menawarkan, berdagang atau berupaya mendagangkan barang apa pun,
            membuat kesepakatan atau mengurus hal yang dilarang atau dibatasi
            dengan cara apapun berdasarkan ketetapan undang-undang, aturan,
            peraturan atau panduan apapun yang berlaku untuk ditegakkan saat
            ini;
          </li>
          <li>
            melanggar atau menyiasati tindakan keamanan kami, atau menguji
            kerentanan sistem atau jaringan kami;
          </li>
          <li>
            mencoba untuk membalikkan rekayasa perangkat lunak apapun milik
            Perusahaan;
          </li>
          <li>
            mengumpulkan atau menyimpan informasi yang dapat mengenali jati diri
            seseorang dari Pengguna atau pengunjung siapa pun, tanpa izin
            mereka;
          </li>
          <li>
            mengancam kesatuan, integritas, pertahanan, keamanan kedaulatan
            Indonesia;
          </li>
          <li>
            menjual nama pengguna Anda, atau memindahtangankannya untuk
            mendapatkan bayaran; dan
          </li>
          <li>
            mendorong atau membantu siapa pun melakukan apa pun yang tercantum
            pada daftar ini.
          </li>
        </ul>
        <BodyText>
          AkzoNobel sudah melakukan segala upaya yang wajar untuk memastikan
          bahwa aplikasi / situs web tidak mengandung virus. Namun demikian,
          tidak ada pernyataan atau jaminan, baik secara teknis atau lainnya,
          yang dapat diberikan, secara tersurat atau tersirat, dalam artian
          bahwa platform ini tidak mengandung ‘bug’ dan / atau virus, dan
          Pengguna bertanggung jawab untuk memastikan penginstalan perangkat
          lunak antivirus yang sesuai. Aplikasi / situs web harus digunakan
          secara layak oleh Pengguna, yang tidak boleh memperkenalkan virus,
          trojan, worm, logic bomb apa pun, atau memasukkan material lainnya
          yang memiliki sifat jahat atau secara teknologi membahayakan. Pengguna
          tidak boleh berupaya mendapatkan akses yang tidak berwenang ke
          aplikasi / situs web, server, bilamana ini disimpan atau mengakses
          server, komputer atau database apa pun yang terhubung ke platform ini.
          Pelanggan dari item ini dapat dianggap sebagai kejahatan pidana.
          AkzoNobel akan melaporkan pelanggaran apapun kepada pihak penegak
          hukum yang berwenang, dan bekerja sama dengan mereka dengan
          mengungkapkan nama-nama Pengguna. Jika terjadi pelanggaran apapun,
          akses Pengguna ke aplikasi / situs web akan segera diblokir. Hingga
          batas yang diizinkan oleh undang-undang yang berlaku, Akzo Nobel
          menafikan tanggung jawab atau pertanggungjawaban sepenuhnya terhadap
          Pengguna, bagaimanapun kemunculannya dalam kontrak, kesalahan atau
          yang termasuk tetapi tidak terbatas pada kerusakan atau kerugian tidak
          langsung atau konsekuensial apa pun dari profit/bisnis. Setiap saat,
          Pengguna akan membebaskan AkzoNobel dan mengganti kerugian atas klaim
          yang mungkin timbul terhadap AkzoNobel yang timbul dari atau
          sehubungan dengan penggunaan Platform/Situs Web. Semua Persyaratan ini
          dikonsep dalam bahasa Inggris, dan bilamana diwajibkan, dalam bahasa
          setempat negara yang bersangkutan. Jika Persyaratan ini telah
          diterjemahkan ke dalam bahasa apa pun selain bahasa Inggris, maka,
          teks dalam bahasa Inggris yang akan berlaku. Semua item dalam Syarat
          dan Ketentuan Umum Penggunaan ini diatur oleh undang-undang yang
          berlaku di Indonesia Untuk semua masalah yang berkaitan dengan
          interpretasi, kepatuhan atau pertanyaan lain apa pun yang berkaitan
          dengan Syarat dan Ketentuan Umum Penggunaan, para pihak setuju untuk
          mengajukan ke yurisdiksi eksklusif dari pengadilan di tempat/kota, di
          mana AkzoNobel memiliki kantor yang terdaftar.
        </BodyText>
        {/* <LegacyHeaderText></LegacyHeaderText>
        <BodyText>
          
        </BodyText>
        <LegacyHeaderText></LegacyHeaderText>
        <BodyText>
          
        </BodyText>
        <LegacyHeaderText></LegacyHeaderText>
        <BodyText>
          
        </BodyText> */}
      </LegacyBody>
    </LegacyContainer>
  );
};

export default LegalBodyIndonesia;
