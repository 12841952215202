import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ToolTip,
  OriginalImageBodyText,
  ReferenceImageBodyContainer,
  ImageComparisonContainer,
  OriginalImageBodyContainer,
  ReferenceImageBodyText,
  OriginalBodyImage,
  PaletteListDiv,
  PaletteDiv,
} from "../../styles/PreviewSupervisorPendingRequestThemeDetailStyles";
import LanguageSelector from "../../language/LanguageSelector";

// Taken from admin

const PendingRequestThemeDetailOriginal = ({ state }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <ImageComparisonContainer>
      <OriginalImageBodyContainer>
        <OriginalImageBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ORIGINAL_IMAGE"}
            /></OriginalImageBodyText>
        <OriginalBodyImage
          alt="Original"
          src={state.data && state.data?.result?.request_detail?.raw_image}
        />
      </OriginalImageBodyContainer>
      <ReferenceImageBodyContainer>
        <ReferenceImageBodyText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            />{`: ${
            state.data && state.data?.result?.request_detail?.theme_name
          }`}
        </ReferenceImageBodyText>
        {state.data?.palette?.palette_1_colours.length !== undefined &&
          state.data?.palette?.palette_1_colours.length > 0 && (
            <>
              <PaletteListDiv>
                {state.data?.palette?.palette_1_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
              </PaletteListDiv>
              <PaletteListDiv>
                {state.data?.palette?.palette_2_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
              </PaletteListDiv>
              <PaletteListDiv>
                {state.data?.palette?.palette_3_colours.map(
                  (paletteItem, index) => {
                    return (
                      <PaletteDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaletteDiv>
                    );
                  }
                )}
              </PaletteListDiv>
            </>
          )}
      </ReferenceImageBodyContainer>

    </ImageComparisonContainer>
  );
};

export default PendingRequestThemeDetailOriginal;
