import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import { Link } from "react-router-dom";
import {
  AdminLeftNavBarContainer,
  DeluxePreviewImage,
  DrawerMenuButton,
  DrawerMenuDiv,
  LogoutButton,
} from "../../styles/AdminLeftNavBarStyles";
import {
  Header,
  DeluxePreviewServiceImage,
  HeaderLoginButton,
  HamburgerHeader,
  HeaderOptionsText,
} from "../../styles/HomeStyles";
import { HOME_PAGE_ROUTE, ADMIN_DASHBOARD_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import HAMBURGER_IMAGE from "../../images/hamburger_menu.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminLeftNavBar = ({
  history,
  drawerMenuList,
  selectedMenu,
  setSelectedMenu,
  applyFilter,
  toggleLogoutMenu,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  
  const [showMenu, setShowMenu] = useState(true);

  const toggleShowMenu = () => setShowMenu((previousState) => !previousState);

  const setMenuHandlerForSmallWidth = (item) => {
    setMenuHandler(item);
    toggleShowMenu();
  };

  const setMenuHandler = (item) => {
    history.push(ADMIN_DASHBOARD_ROUTE, { menu: item });
    setSelectedMenu(item);
  };

  if (width < 1100) {
    return (
      <>
        <Header>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview Service "
            />
          </Link>
          <HeaderLoginButton onClick={toggleShowMenu}>
            <img
              src={HAMBURGER_IMAGE}
              alt="Menu"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                objectFit: "fill",
              }}
            />
          </HeaderLoginButton>
        </Header>
        {showMenu && (
          <HamburgerHeader>
            <HeaderOptionsText
              onClick={() => setMenuHandlerForSmallWidth("Dashoard")}
            ></HeaderOptionsText>
            <HeaderOptionsText
              onClick={() => setMenuHandlerForSmallWidth("Requests")}
            >
              <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"REQUESTS"}
        />
            </HeaderOptionsText>
            <HeaderOptionsText
              onClick={() => setMenuHandlerForSmallWidth("Preview Team")}
            >
             <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_TEAM"}
        />
            </HeaderOptionsText>
            <HeaderOptionsText
              onClick={() => setMenuHandlerForSmallWidth("Customers")}
            >
              <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMERS"}
        />
            </HeaderOptionsText>
            <HeaderOptionsText onClick={toggleLogoutMenu}>
            <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"LOGOUT"}
        />
            </HeaderOptionsText>
          </HamburgerHeader>
        )}
      </>
    );
  } else {
    return (
      <AdminLeftNavBarContainer applyFilter={applyFilter}>
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewImage src={DELUXE_PREVIEW_SERVICE_IMAGE} />
        </Link>
        <DrawerMenuDiv>
          {drawerMenuList.map((item, index) => {
            if (item == "Dashboard") {
              return (
                <DrawerMenuButton
                  isSelected={selectedMenu === item}
                  key={`${index}`}
                  onClick={() => setMenuHandler(item)}
                >
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"DASHBOARD"}
        />
                </DrawerMenuButton>
              );
            } 
            

            if (item == "Requests") {
              return (
                <DrawerMenuButton
                  isSelected={selectedMenu === item}
                  key={`${index}`}
                  onClick={() => setMenuHandler(item)}
                >
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"REQUESTS"}
        />
                </DrawerMenuButton>
              );
            } 


            if (item == "Preview Team") {
              return (
                <DrawerMenuButton
                  isSelected={selectedMenu === item}
                  key={`${index}`}
                  onClick={() => setMenuHandler(item)}
                >
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_TEAM"}
        />
                </DrawerMenuButton>
              );
            } 


            if (item == "Customers") {
              return (
                <DrawerMenuButton
                  isSelected={selectedMenu === item}
                  key={`${index}`}
                  onClick={() => setMenuHandler(item)}
                >
                 <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMERS"}
        />
                </DrawerMenuButton>
              );
            } 

            
            if (item == "Logout") {
              return (
                <LogoutButton key={`${index}`} onClick={toggleLogoutMenu}>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"LOGOUT"}
        />
                </LogoutButton>
              );
            }
          })}
        </DrawerMenuDiv>
      </AdminLeftNavBarContainer>
    );
  }
};

export default AdminLeftNavBar;
