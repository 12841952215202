import React from "react";
import {
  AnalyticsCountryTitleDiv,
  CountryNameButton,
  CountryNameHorizontalDivider,
} from "../../styles/AdminDashboardStyles";

const AdminDashboardCountryName = ({
  countryList,
  selectedCountry,
  setSelectedCountry,
}) => {
  return (
    <div style={{ display: "block" }}>
      <AnalyticsCountryTitleDiv>
        {countryList.map((item, index) => {
          return (
            <CountryNameButton
              isSelected={selectedCountry === item}
              key={`${index}`}
              onClick={() => {
                setSelectedCountry(item);
              }}
            >
              {item}
            </CountryNameButton>
          );
        })}
      </AnalyticsCountryTitleDiv>
      <CountryNameHorizontalDivider />
    </div>
  );
};

export default AdminDashboardCountryName;
