/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {
  Switch,
  Route,
  withRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import {
  CONSUMER_BROWSE_COLOUR_ROUTE,
  CONSUMER_DULUX_PREVIEW_ROUTE,
  CONSUMER_DULUX_THEME_ROUTE,
  CONSUMER_IMAGE_OPTIONS_ROUTE,
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  CONSUMER_CHOOSE_IMAGE_TYPE_ROUTE,
  HOME_PAGE_ROUTE,
  CONSUMER_REFERENCE_IMAGE_ROUTE,
  CONSUMER_RESULT_ROUTE,
  CONSUMER_HOME_ROUTE,
  PREVIEW_SUPERVISOR_COLOUR_PICKER_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
  PREVIEW_SUPERVISOR_TOUCH_UP_ROUTE,
  PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE,
  PENDING_REQUEST_THEME_DETAIL_ROUTE,
  PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE,
  ADMIN_RESULT_ROUTE,
  PREVIEW_SUPERVISOR_RESULT_ROUTE,
  PREVIEW_SUPERVISOR_CONSUMER_RESULT_ROUTE,
  PREVIEW_SUPERVISOR_SELECTOR,
  CONSUMER_DETAIL_ROUTE,
  CONSUMER_WRITE_REVIEW,
  PREVIEW_STAFF_DASHBOARD_ROUTE,
  PREVIEW_STAFF_RESULT_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  TOKEN_NAME,
  COOKIE_ROUTE,
  LEGAL_ROUTE,
  PRIVACY_POLICY_ROUTE,
  INTERNAL_LOGIN_ROUTE,
  CONSUMER_CHOOSE_COLOUR_ROUTE,
  USER_LOGIN_ROUTE,
} from "./utils/Constants";
import ConsumerUploadImage from "./screens/consumer/ConsumerUploadImage";
import ConsumerChooseImageType from "./screens/consumer/ConsumerChooseImageType";
import ConsumerImageOptions from "./screens/consumer/ConsumerImageOptions";
import ConsumerBrowseColour from "./screens/consumer/ConsumerBrowseColour";
import ConsumerDuluxTheme from "./screens/consumer/ConsumerDuluxTheme";
import ConsumerDuluxPreview from "./screens/consumer/ConsumerDuluxPreview";
import ConsumerReferenceImage from "./screens/consumer/ConsumerReferenceImage";
import ConsumerResult from "./screens/consumer/ConsumerResult";
import ConsumerHome from "./screens/consumer/ConsumerHome";
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as ConsumerProvider } from "./context/ConsumerContext";
import { Provider as PreviewSupervisorProvider } from "./context/PreviewSupervisorContext";
import { Provider as AdminProvider } from "./context/AdminContext";
import { ColourizationProvider } from "./context/ColourizationContext";
import { PreviewSupervisorDetailsProvider } from "./context/PreviewSupervisorDetailsContext";
import { AdminDetailsProvider } from "./context/AdminDetailsContext";
import PreviewSupervisorDashboard from "./screens/preview-supervisor/PreviewSupervisorDashboard";
import PreviewSupervisorTouchUp from "./screens/preview-supervisor/PreviewSupervisorTouchUp";
import PreviewSupervisorColourPicker from "./screens/preview-supervisor/PreviewSupervisorColourPicker";
import PendingRequestReferenceImageDetail from "./screens/admin-and-preview-supervisor/PendingRequestReferenceImageDetail";
import PendingRequestThemeDetail from "./screens/admin-and-preview-supervisor/PendingRequestThemeDetail";
import PendingRequestLeadColourDetail from "./screens/admin-and-preview-supervisor/PendingRequestLeadColourDetail";
import PreviewSupervisorResult from "./screens/preview-supervisor/PreviewSupervisorResult";
import AdminResult from "./screens/admin/AdminResult";
import AdminPSSelector from "./screens/admin-and-preview-supervisor/AdminPSSelector";
import ConsumerDetailReport from "./screens/consumer/ConsumerDetailReport";
import ConsumerWriteReview from "./screens/consumer/ConsumerWriteReview";
import PreviewStaffDashboard from "./screens/preview-staff/PreviewStaffDashboard";
import Admin from "./screens/admin/Admin";
import DynamicRoute from "./utils/DynamicRoute";
import ConsumerRoute from "./utils/ConsumerRoute";
import PreviewSupervisorRoute from "./utils/PreviewSupervisorRoute";
import AdminRoute from "./utils/AdminRoute";
import AdminPSConsumerResult from "./screens/admin-and-preview-supervisor/AdminPSConsumerResult";
import PrivacyPolicy from "./screens/privacy-policy/PrivacyPolicy";
import Legal from "./screens/legal/Legal";
import Cookie from "./screens/cookies/Cookie";
import InternalLogin from "./screens/login/InternalLogin";
import UserLogin from "./screens/login/UserLogin";
import Home from "./screens/home/Home";
import ConsumerChooseColour from "./screens/consumer/ConsumerChooseColour";

const Root = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const currentTime = dayjs().unix();

  useEffect(() => {
    // Cookies.set(
    //   TOKEN_NAME,
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2huQGFtYXJpcy5haSIsImlhdCI6MTYwNDM5MDA0NywibmJmIjoxNjA0MzkwMDQ3LCJqdGkiOiJhY2I3NjM5OC1kOTRjLTQyNzQtOTEyZC1iNjA3ZDNlOWE0MGEiLCJleHAiOjE2MDQ0ODQwMDMsInR5cGUiOiJhY2Nlc3MiLCJmcmVzaCI6ZmFsc2UsInVzZXJfdHlwZSI6IlN1cGVyIEFkbWluIiwiZW1haWwiOiJzYXdhbnRwcml0aXNoQGdtYWlsLmNvbSIsIm5hbWUiOiJOaWxlc2ggSyIsImNvdW50cnkiOiJTaW5nYXBvcmUifQ.fMvO0bfcbfprHDWX9jPETRjkbE6BZ2Uq77qLRU1GFgQ",
    //   { expires: 30 }
    // );

    if (decodedToken) {
      if (currentTime > decodedToken.exp) {
        Cookies.remove(TOKEN_NAME);
      }
    }
  }, []);

  return (
    <Switch>
      <Route path={CONSUMER_HOME_ROUTE} component={ConsumerHome} />
      <Route
        path={CONSUMER_UPLOAD_IMAGE_ROUTE}
        component={ConsumerUploadImage}
      />
      <Route
        path={CONSUMER_CHOOSE_IMAGE_TYPE_ROUTE}
        component={ConsumerChooseImageType}
      />
      <ConsumerRoute
        path={CONSUMER_IMAGE_OPTIONS_ROUTE}
        component={ConsumerImageOptions}
      />
      <ConsumerRoute
        path={CONSUMER_BROWSE_COLOUR_ROUTE}
        component={ConsumerBrowseColour}
      />
      <ConsumerRoute
        path={CONSUMER_DULUX_THEME_ROUTE}
        component={ConsumerDuluxTheme}
      />
      <ConsumerRoute
        path={CONSUMER_DULUX_PREVIEW_ROUTE}
        component={ConsumerDuluxPreview}
      />
      <ConsumerRoute
        path={CONSUMER_REFERENCE_IMAGE_ROUTE}
        component={ConsumerReferenceImage}
      />
      <ConsumerRoute
        path={CONSUMER_CHOOSE_COLOUR_ROUTE}
        component={ConsumerChooseColour}
      />
      <Route path={CONSUMER_RESULT_ROUTE} component={ConsumerResult} />
      <Route
        path={PREVIEW_SUPERVISOR_DASHBOARD_ROUTE}
        component={PreviewSupervisorDashboard}
      />
      <Route
        path={PREVIEW_SUPERVISOR_TOUCH_UP_ROUTE}
        component={PreviewSupervisorTouchUp}
      />
      <Route
        path={PREVIEW_SUPERVISOR_COLOUR_PICKER_ROUTE}
        component={PreviewSupervisorColourPicker}
      />
      <Route
        path={PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE}
        component={PendingRequestReferenceImageDetail}
        decodedToken={decodedToken}
      />
      <Route
        path={PENDING_REQUEST_THEME_DETAIL_ROUTE}
        component={PendingRequestThemeDetail}
        decodedToken={decodedToken}
      />
      <Route
        path={PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE}
        component={PendingRequestLeadColourDetail}
      />
      <Route
        path={PREVIEW_SUPERVISOR_RESULT_ROUTE}
        component={PreviewSupervisorResult}
        decodedToken={decodedToken}
      />

      <Route
        path={PREVIEW_STAFF_RESULT_ROUTE}
        component={PreviewSupervisorResult}
        decodedToken={decodedToken}
      />


      <Route
        path={ADMIN_RESULT_ROUTE}
        component={AdminResult}
        decodedToken={decodedToken}
      />

      <Route
        path={PREVIEW_SUPERVISOR_CONSUMER_RESULT_ROUTE}
        component={AdminPSConsumerResult}
        decodedToken={decodedToken}
      />
      <Route
        path={PREVIEW_SUPERVISOR_SELECTOR}
        component={AdminPSSelector}
        decodedToken={decodedToken}
      />
      <Route path={CONSUMER_DETAIL_ROUTE} component={ConsumerDetailReport} />
      <Route path={CONSUMER_WRITE_REVIEW} component={ConsumerWriteReview} />
      <Route
        path={PREVIEW_STAFF_DASHBOARD_ROUTE}
        component={PreviewStaffDashboard}
      />
      <AdminRoute
        path={ADMIN_DASHBOARD_ROUTE}
        component={Admin}
        decodedToken={decodedToken}
      />

      <Route path={COOKIE_ROUTE} component={withRouter(Cookie)} />

      <Route path={LEGAL_ROUTE} component={Legal} />

      <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />

      <DynamicRoute
        path={INTERNAL_LOGIN_ROUTE}
        component={InternalLogin}
        decodedToken={decodedToken}
        authenticated={decodedToken && currentTime < decodedToken.exp}
      />
      <DynamicRoute
        path={USER_LOGIN_ROUTE}
        component={UserLogin}
        decodedToken={decodedToken}
        authenticated={decodedToken && currentTime < decodedToken.exp}
      />
      <DynamicRoute
        exact
        path={HOME_PAGE_ROUTE}
        component={Home}
        decodedToken={decodedToken}
        authenticated={decodedToken && currentTime < decodedToken.exp}
      />
      <Route
        exact
        path={HOME_PAGE_ROUTE + ":country"}
        component={withRouter(Home)}
        decodedToken={decodedToken}
        authenticated={decodedToken && currentTime < decodedToken.exp}
      />
    </Switch>
  );
};

const RootWithAuth = withRouter(Root);

ReactDOM.render(
  <AdminDetailsProvider>
    <AdminProvider>
      <PreviewSupervisorProvider>
        <ConsumerProvider>
          <AuthProvider>
            <PreviewSupervisorDetailsProvider>
              <ColourizationProvider>
                <Router>
                  <RootWithAuth />
                </Router>
              </ColourizationProvider>
            </PreviewSupervisorDetailsProvider>
          </AuthProvider>
        </ConsumerProvider>
      </PreviewSupervisorProvider>
    </AdminProvider>
  </AdminDetailsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
