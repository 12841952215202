import React from "react";
import {
  PaginationDiv,
  PaginationLeftImage,
  PaginationNumberDiv,
  PaginationRightImage,
  PaginationArrowContainer,
} from "../../styles/PreviewSupervisorDashboardStyles";
// import PAGINATION_LEFT_IMAGE from "../../images/pagination_left.svg";
// import PAGINATION_RIGHT_IMAGE from "../../images/pagination_right.svg";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";

const PreviewSupervisorDashboardPagination = ({
  paginationList,
  selectedPageForResolved,
  selectedPageForPending,
  selectedPageForArchived,
  setSelectedPageForPending,
  setSelectedPageForResolved,
  setSelectedPageForArchived,
  tabSelected,
}) => {
  const rightIncrementHandler = () => {
    if (tabSelected === 0) {
      if (selectedPageForPending <= paginationList.length) {
        selectedPageForPending++;
        setSelectedPageForPending(selectedPageForPending);
      }
    } 
    else if (tabSelected === 1 && selectedPageForResolved <= paginationList.length) {
        selectedPageForResolved++;
        setSelectedPageForResolved(selectedPageForResolved);
    }
    else if (tabSelected === 2 && selectedPageForArchived <= paginationList.length) {
        selectedPageForArchived++;
        setSelectedPageForArchived(selectedPageForArchived);
    }
  };

  const leftDecrementHandler = () => {
    if (tabSelected === 0) {
      if (selectedPageForPending > 0) {
        selectedPageForPending--;
        setSelectedPageForPending(selectedPageForPending);
      }
    } 
    else if (tabSelected === 1 && selectedPageForResolved > 0) {
        selectedPageForResolved--;
        setSelectedPageForResolved(selectedPageForResolved);
    }
    else if (tabSelected === 2 && selectedPageForArchived > 0) {
      selectedPageForArchived--;
      setSelectedPageForArchived(selectedPageForArchived);
    }
  };

  const LeftArrow = () => {
      return (
        <PaginationArrowContainer>
          <PaginationLeftImage
          onClick={leftDecrementHandler}
          src={BLACK_ARROW_IMAGE}
          alt="Pagination left"
          />
        </PaginationArrowContainer>
      );
  }

  const RightArrow = () => {
    return (
      <PaginationArrowContainer>
        <PaginationRightImage
        onClick={rightIncrementHandler}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination right"
        />
      </PaginationArrowContainer>
    );
  }

  const ShowLeftArrow = () => {
    if (tabSelected === 0 && selectedPageForPending > 1) {
        return <LeftArrow/>;
    } 
    else if (tabSelected === 1 && selectedPageForResolved > 1) {
        return <LeftArrow/>;
    } 
    else if (tabSelected === 2 && selectedPageForArchived > 1) {
      return <LeftArrow/>;
    } 
    return <PaginationArrowContainer/>
  }

  const ShowRightArrow = () => {
    if (tabSelected === 0 && selectedPageForPending < paginationList.length) {
        return <RightArrow/>;
    }
    else if (tabSelected === 1 && selectedPageForResolved < paginationList.length) {
      return <RightArrow/>;
    } 
    else if (tabSelected === 2 && selectedPageForArchived < paginationList.length) {
        return <RightArrow/>;
    } 
    return <PaginationArrowContainer/>
  }

  return (
    <PaginationDiv>
      <ShowLeftArrow/>
      <PaginationNumber
        key={ tabSelected === 0 ? selectedPageForPending 
            : (tabSelected === 1 ? selectedPageForResolved : selectedPageForArchived)}
        selectedPage={ tabSelected === 0 ? selectedPageForPending 
          : (tabSelected === 1 ? selectedPageForResolved : selectedPageForArchived)} 
        paginationList={paginationList}
        setSelectedPage={ tabSelected === 0 ? setSelectedPageForPending
          : (tabSelected === 1 ? setSelectedPageForResolved : setSelectedPageForArchived)}
      />
      <ShowRightArrow/>
    </PaginationDiv>
  );
};

const PaginationNumber = ({
  paginationList,
  setSelectedPage,
  selectedPage,
}) => {
  return (
    <>
    <div style={{flexDirection: "row", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
      {paginationList.map((item, index) => {
        return (
          <PaginationNumberDiv
            key={`${index}`}
            onClick={() => setSelectedPage(index + 1)}
            isSelected={selectedPage === index + 1}
          >
            {index + 1}
          </PaginationNumberDiv>
        );
      })}
      </div>
    </>
  );
};

export default PreviewSupervisorDashboardPagination;
