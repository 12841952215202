/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import PreviewSupervisorDashboardResolveRequestSidebar from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardResolveRequestSidebar";
import PreviewSupervisorDashboardResolveRequestFeedbackSidebar from "../../components/preview-supervisor-dashboard/PreviewSupervisorDashboardResolveRequestFeedbackSidebar";
import {
  PreviewSupervisorPendingRequestDetailContainer,
  PreviewSupervisorPendingRequestDetailHeader,
  DeluxePreviewServiceImage,
  LogoutButton,
  HeaderHorizontalRuler,
  PreviewSupervisorPendingRequestBodyContainer,
  VerticalDivider,
  MainBodyContainer,
  RequestDetailsContainer,
  RequestIdText,
  ReferenceImageContainer,
  ReferenceImage,
  ReferenceImageText,
  CustomerImageContainer,
  CustomerImage,
  CustomerEmailText,
  RequestDetailsDivider,
  RequestMadeText,
  ResultGeneratedText,
  ButtonBar,
  BackButton,
  NextButton,
  ErrorMessage,
  DownloadImage,
} from "../../styles/PreviewSupervisorPendingRequestReferenceImageDetailStyles";
import {
  HOME_PAGE_ROUTE,
  PREVIEW_SUPERVISOR_SELECTOR,
  PREVIEW_SUPERVISOR_TOUCH_UP_ROUTE,
  TOKEN_NAME,
  ACCESS_KEY_ID,
  BUCKET_NAME,
  SECRET_ACCESS_KEY,
  ADMIN_DASHBOARD_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
  PREVIEW_STAFF_DASHBOARD_ROUTE
} from "../../utils/Constants";
import AWS from "aws-sdk";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import CUSTOMER_IMAGE from "../../images/customer.svg";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import REFERENCE_IMAGE from "../../images/reference.svg";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import { Link } from "react-router-dom";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import PendingRequestReferenceImageSteps from "../../components/pending-request-reference-image/PendingRequestReferenceImageSteps";
import PendingRequestReferenceImageOriginal from "../../components/pending-request-reference-image/PendingRequestReferenceImageOriginal";
import PendingRequestReferenceImageResults from "../../components/pending-request-reference-image/PendingRequestReferenceImageResults";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { default as Back } from "../../components/BackButton";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import LanguageSelector from "../../language/LanguageSelector";

const PendingRequestReferenceImageDetail = ({ history, location }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  console.log(location);

  const [resolveRequestMenu, setResolveRequestMenu] = useState(false);
  const [rateResultFeedback, setRateResultFeedback] = useState(false);
  const [selectedPaginationNumber, setSelectedPaginationNumber] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [requestMadeTime, setRequestMadeTime] = useState("");

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleResolveRequestMenu = () =>
    setResolveRequestMenu((previousState) => !previousState);
  const toggleRateResultFeedback = () =>
    setRateResultFeedback((previousState) => !previousState);

  const { state, resultRequest } = useContext(PreviewSupervisorContext);
  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );

  const touchUphandler = (imageSource, result_id) => {
    history.push(PREVIEW_SUPERVISOR_TOUCH_UP_ROUTE, {
      imageSource,
      result_id,
      request_id: location.state?.item.request_id,
      state: state,
      optionList: state.data && state.data?.results,
      selectedPaginationNumber,
    });
  };

  const backHandler = () => {
    if (decodedToken.user_type === "Preview Staff") {
      history.push(PREVIEW_STAFF_DASHBOARD_ROUTE, {
        menu: "Requests",
      tabSelected: location.state?.data.tabSelected,
      selectedPageForResolved: location.state?.data.selectedPageForResolved,
      selectedPageForPending: location.state?.data.selectedPageForPending,
      selectedPageForArchived: location.state?.data.selectedPageForArchived,
      requestList: location.state?.data.requestList,
    });};
    if (decodedToken.user_type === "Super Admin") {
        history.push(ADMIN_DASHBOARD_ROUTE, {
      menu: "Requests",
      tabSelected: location.state?.data.tabSelected,
      selectedPageForResolved: location.state?.data.selectedPageForResolved,
      selectedPageForPending: location.state?.data.selectedPageForPending,
      selectedPageForArchived: location.state?.data.selectedPageForArchived,
      requestList: location.state?.data.requestList,
    });
  };
  if (decodedToken.user_type === "Preview Supervisor") {
    history.push(PREVIEW_SUPERVISOR_DASHBOARD_ROUTE, {
  menu: "Requests",
  tabSelected: location.state?.data.tabSelected,
  selectedPageForResolved: location.state?.data.selectedPageForResolved,
  selectedPageForPending: location.state?.data.selectedPageForPending,
  selectedPageForArchived: location.state?.data.selectedPageForArchived,
  requestList: location.state?.data.requestList,
});
};
  }
  const resultRequestSuccessCallback = () => {
    setErrorMessage(null);
  };

  const resultRequestFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const nextHandler = () => {
    if (selectedOptions.length > 0) {
      const values = previewSupervisorDetailContext.previewSupervisorDetails;

      previewSupervisorDetailContext.setPreviewSupervisorDetails({
        email: values?.email || decodedToken?.email,
        selectedImage: selectedOptions,
        originalImage: state.data?.request_detail?.raw_image,
        referenceImage: state.data?.request_detail?.reference_image,
        request_id: state.data?.request_detail?.request_id,
        colourization_type: "image",
        isValid: values.isValid,
      });
      history.push(PREVIEW_SUPERVISOR_SELECTOR, {
        email: state.data?.request_detail?.email,
      });
    } else {
      setErrorMessage("Select image to continue");
    }
  };

  const { width } = useWindowDimensions();

  useEffect(() => {
    resultRequest({
      request_id: location.state?.item.request_id,
      resultRequestSuccessCallback,
      resultRequestFailureCallback,
      isTheme: false,
    });
  }, []);

  useEffect(() => {
    console.log("convert timezone initial");
    if (location.state?.item.timestamp && decodedToken?.country) {
      console.log("convert timezone");
      var timeZone = "";
      var timeExt = "";
      dayjs.extend(dayjsPluginUTC);
      dayjs.extend(require("dayjs-timezone-iana-plugin"));
      if (decodedToken?.country === "Vietnam") {
        timeZone = "Asia/Ho_Chi_Minh";
        timeExt = "ICT";
      } else if (decodedToken?.country === "Sri Lanka") {
        timeZone = "Asia/Colombo";
        timeExt = "IST";
      } else if (decodedToken?.country === "Indonesia") {
        timeZone = "Asia/Jakarta";
        timeExt = "WIB";
      } else if (decodedToken?.country === "Thailand") {
        timeZone = "Asia/Bangkok";
        timeExt = "THA";
      } else if (decodedToken?.country === "Pakistan") {
        timeZone = "Asia/Karachi";
        timeExt = "PKT";
      } else if (decodedToken?.country === "Malaysia") {
        timeZone = "Asia/Kuala_Lumpur";
        timeExt = "MYT";
      } else if (decodedToken?.country === "India") {
        timeZone = "Asia/Calcutta";
        timeExt = "IST";
      } else {
        timeZone = "Asia/Singapore";
        timeExt = "SGT";
      }
      console.log(location.state?.item.timestamp + ".00");
      console.log(
        dayjs(location.state?.item.timestamp + ".00")
          .tz(timeZone)
          .format("DD/MM/YYYY hh:mm:ss A ") + timeExt
      );
      setRequestMadeTime(
        dayjs(location.state?.item.timestamp + ".00")
          .tz(timeZone)
          .format("DD/MM/YYYY hh:mm:ss A ") + timeExt
      );
    }
  }, [location.state?.item.timestamp]);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    previewSupervisorDetailContext.setPreviewSupervisorDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const downloadSingleImageHandler = (imageUrl) => {
    console.log(imageUrl);
    window.open(imageUrl);
  };

  return (
    <>
      <ConsumerLogoutDrawer
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <PreviewSupervisorDashboardResolveRequestSidebar
        resolveRequestMenu={resolveRequestMenu}
        toggleResolveRequestMenu={toggleResolveRequestMenu}
        touchUphandler={touchUphandler}
        selectedPaginationNumber={selectedPaginationNumber}
        setSelectedPaginationNumber={setSelectedPaginationNumber}
        optionList={state.data && state.data?.results}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        toggleRateResultFeedback={toggleRateResultFeedback}
        width={width}
      />

      <PreviewSupervisorDashboardResolveRequestFeedbackSidebar
        rateResultFeedback={rateResultFeedback}
        toggleRateResultFeedback={toggleRateResultFeedback}
        toggleResolveRequestMenu={toggleResolveRequestMenu}
        width={width}
      />

      <PreviewSupervisorPendingRequestDetailContainer
        applyFilter={resolveRequestMenu || openLogoutMenu}
      >
        <PreviewSupervisorPendingRequestDetailHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              alt="Deluxe Preview"
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
            />
          </Link>
          <LogoutButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LOGOUT"}
            /></LogoutButton>
        </PreviewSupervisorPendingRequestDetailHeader>
        <HeaderHorizontalRuler />
        <PreviewSupervisorPendingRequestBodyContainer>
          {width > 1100 && <PendingRequestReferenceImageSteps />}
          {width > 1100 && <VerticalDivider />}
          {width > 1100 && <Back goBackHandler={backHandler} />}
          <MainBodyContainer>
            <RequestDetailsContainer>
              <RequestIdText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            />{`: ${
                state.data && state.data?.request_detail?.request_id
              }`}</RequestIdText>
              <ReferenceImageContainer>
                <ReferenceImage src={REFERENCE_IMAGE} alt="Reference" />
                <ReferenceImageText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE_IMAGE"}
            /></ReferenceImageText>
              </ReferenceImageContainer>
              <CustomerImageContainer>
                <CustomerImage src={CUSTOMER_IMAGE} alt="Customer" />
                <CustomerEmailText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CUSTOMER"}
            />{`:  ${
                  state.data && state.data?.request_detail?.name
                }`}</CustomerEmailText>
              </CustomerImageContainer>
            </RequestDetailsContainer>
            <RequestDetailsDivider />
            {errorMessage != null && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}

            <PendingRequestReferenceImageOriginal state={state} />
            {state.data && state.data?.request_detail?.raw_image ? (
              <DownloadImage
                onClick={(e) =>
                  downloadSingleImageHandler(
                    state.data?.request_detail?.raw_image
                  )
                }
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD_IMAGE"}
            />
              </DownloadImage>
            ) : null}
            <PendingRequestReferenceImageResults
              state={state}
              setSelectedPaginationNumber={setSelectedPaginationNumber}
              toggleResolveRequestMenu={toggleResolveRequestMenu}
              rateResultFeedback={rateResultFeedback}
              toggleRateResultFeedback={toggleRateResultFeedback}
              selectedOptions={selectedOptions}
            />
            {selectedOptions.length > 0 && (
              <ButtonBar>
                <BackButton onClick={backHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
                <NextButton onClick={nextHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEXT"}
            /></NextButton>
              </ButtonBar>
            )}
            <RequestMadeText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_MADE"}
            />{requestMadeTime}</RequestMadeText>
            <ResultGeneratedText>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESULTS_GENERATED"}
            /> {requestMadeTime}
            </ResultGeneratedText>
          </MainBodyContainer>
        </PreviewSupervisorPendingRequestBodyContainer>
      </PreviewSupervisorPendingRequestDetailContainer>
    </>
  );
};

export default PendingRequestReferenceImageDetail;
