import React, { useContext, useState } from "react";
import ConsumerDuluxThemeImageContainer from "../../components/consumer-dulux-theme/ConsumerDuluxThemeImageContainer";
import ConsumerImageOptionHeader from "../../components/consumer-image-options/ConsumerImageOptionHeader";
import { ColourizationContext } from "../../context/ColourizationContext";
import {
  ConsumerDuluxThemeContainer,
  HeaderText,
  NextButton,
  ThemeButton,
  ThemeContainer,
} from "../../styles/ConsumerDuluxThemeStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_DULUX_PREVIEW_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import CLASSIC_ELEGANCE_IMAGE from "../../images/classic_elegance.png";
// import AFRICA_IMAGE from "../../images/africa.png";
import COSTAL_VILLA_IMAGE from "../../images/costal-villa.png";
// import FRANCE_IMAGE from "../../images/france.png";
// import ITALY_IMAGE from "../../images/italy.png";
import MODERN_TWIST_IMAGE from "../../images/modern_contemporary.png";
import MODEN_CONTEMPORARY_IMAGE from "../../images/modern_twist.png";
import MEDITERRANEAN_CHARM_IMAGE from "../../images/mediterranean_charm.png";
// import THAILAND_IMAGE from "../../images/thailand_theme.png";
import TROPICAL_IMAGE from "../../images/tropical.png";
// import SCANDINAVIA_IMAGE from "../../images/scandinavia.png";
import BackButton from "../../components/BackButton";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import TRUST_IMAGE from "../../images/trust.jpg";
import TOUCHING_COLORS from "../../images/touching_colors.png";
import TIMELESS_IMAGE from "../../images/timeless.jpg";
import EXPRESSIVE_IMAGE from "../../images/expressive.jpg";
import ESSENTIAL_IMAGE from "../../images/essential.jpg";
import EATH_IMAGE from "../../images/earth.jpg";
import ATMOSPHERIC_IMAGE from "../../images/atmospheric.jpg";
import ConsumerDuluxInteriorList from "../../components/consumer-dulux-theme/ConsumerDuluxInteriorList";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerDuluxTheme = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const { width } = useWindowDimensions();

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const [theme, setTheme] = useState("Interior");
  const [selectedImage, setSelectedImage] = useState("");

  const colourizationContext = useContext(ColourizationContext);
  const { themePalette } = useContext(ConsumerContext);
  const interiorList = [
    {
      imageName: "Atmospheric Colors",
      imageUrl: ATMOSPHERIC_IMAGE,
    },
    {
      imageName: "Touching Colors",
      imageUrl: TOUCHING_COLORS,
    },
    {
      imageName: "Essentials Colors",
      imageUrl: ESSENTIAL_IMAGE,
    },
    // {
    //   imageName: "Scandinavia",
    //   imageUrl: SCANDINAVIA_IMAGE,
    // },
    // {
    //   imageName: "Africa",
    //   imageUrl: AFRICA_IMAGE,
    // },
    // {
    //   imageName: "Italy",
    //   imageUrl: ITALY_IMAGE,
    // },
    // {
    //   imageName: "France",
    //   imageUrl: FRANCE_IMAGE,
    // },
    // {
    //   imageName: "Thailand",
    //   imageUrl: THAILAND_IMAGE,
    // },
  ];

  const colorOfYearList = [
    {
      imageName: "Expressive Colors",
      imageUrl: EXPRESSIVE_IMAGE,
    },
    {
      imageName: "Trust Colors",
      imageUrl: TRUST_IMAGE,
    },
    {
      imageName: "Timeless Colors",
      imageUrl: TIMELESS_IMAGE,
    },
    {
      imageName: "Earth Colors",
      imageUrl: EATH_IMAGE,
    },
  ];

  const exteriorList = [
    {
      imageName: "Coastal Villa",
      imageUrl: COSTAL_VILLA_IMAGE,
    },
    {
      imageName: "Tropical Retreat",
      imageUrl: TROPICAL_IMAGE,
    },
    {
      imageName: "Mediterranean Charm",
      imageUrl: MEDITERRANEAN_CHARM_IMAGE,
    },
    {
      imageName: "Modern Contemporary",
      imageUrl: MODEN_CONTEMPORARY_IMAGE,
    },
    {
      imageName: "Modern Twist",
      imageUrl: MODERN_TWIST_IMAGE,
    },
    {
      imageName: "Classic Elegance",
      imageUrl: CLASSIC_ELEGANCE_IMAGE,
    },
  ];

  const interiorHandler = () => {
    setTheme("Interior");
  };

  const exteriorHandler = () => {
    setTheme("Exterior");
  };

  const selectImageHandler = (imageName) => {
    if (selectedImage === imageName) {
      setSelectedImage("");
    } else {
      setSelectedImage(imageName);
      const value = colourizationContext.colourizationDetails;
      if (
        value.raw_image === undefined ||
        value.colourization_type === undefined
      ) {
        alert("Upload image and choose a type");
      } else {
        colourizationContext.setColourizationDetails({
          raw_image: value.raw_image,
          colourization_type: value.colourization_type,
          reference: "",
          theme: imageName,
          lead_colour: "",
          email: value?.email || decodedToken?.email,
          name: "",
          city: "",
          country: "",
        });
      }
    }
  };

  const goToConsumerDuluxHandler = () => {
    history.push(CONSUMER_DULUX_PREVIEW_ROUTE);
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
      />
      <ConsumerDuluxThemeContainer applyFilter={openLogoutMenu}>
        <ConsumerImageOptionHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          goBackHandler={goBackHandler}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        <HeaderText>
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"DULUX_HAS_A_RANGE_OF_THEMES"}
          />
          <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"CHOOSE_THE_ENVIROMENT_AND"}
          />
        </HeaderText>
        <ThemeContainer>
          <ThemeButton
            theme={theme === "Interior" ? true : false}
            onClick={interiorHandler}
          >
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"INTERIOR"}
            />
          </ThemeButton>
          {/* DISABLED INDIA CHANGE */}
          {/* {(decodedToken.country || countryValue.value) !== "India" ? */}
          <ThemeButton
            theme={theme === "Exterior" ? true : false}
            onClick={exteriorHandler}
          >
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EXTERIOR"}
            />
          </ThemeButton>
          {/* : null } */}
        </ThemeContainer>
        {theme === "Interior" ? (
          <ConsumerDuluxInteriorList
            themeList={interiorList}
            colorOfYearList={colorOfYearList}
            selectImageHandler={selectImageHandler}
            selectedImage={selectedImage}
            themePalette={themePalette}
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
          />
        ) : (
          <ConsumerDuluxThemeImageContainer
            themeList={exteriorList}
            selectImageHandler={selectImageHandler}
            selectedImage={selectedImage}
            themePalette={themePalette}
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
          />
        )}
        {selectedImage !== "" && (
          <NextButton onClick={goToConsumerDuluxHandler}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEXT"}
            />
          </NextButton>
        )}
      </ConsumerDuluxThemeContainer>
    </>
  );
};

export default ConsumerDuluxTheme;
