import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ManageUserSideBar,
  ManageUserCancelImage,
  ManageUserDiv,
  ManageUserAddtext,
  ManageUserAddUseImage,
  RoleText,
  SubTitleText,
  PreviewStaffSupervisorText,
  PreviewStaffSupervisorInnerCheckBox,
  PreviewSupervisorDiv,
  PreviewStaffSupervisorOuterCheckBox,
  PreviewStaffDiv,
  SaveButton,
  RemoveUser,
  ButtonBar,
  ErrorMessage,
  ManageUserModalBarDiv,
} from "../../styles/AdminUserStyles";
import { Formik } from "formik";
import "../../styles/personDetailsInput.css";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import { addUserValidationSchema } from "../../validations/Validations";
import Select from "react-select";
import LanguageSelector from "../../language/LanguageSelector";


const AdminUserManageUser = ({
  toggleManageUser,
  adminManageUser,
  adminDeleteUser,
  user,
  adminUsers,
  adminUsersSuccessCallback,
  adminUserFailureCallback,
  errorMessage,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const options = [
    { value: "India", label: "India" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Srilanka", label: "Srilanka" },
    { value: "Singapore", label: "Singapore" },
    { value: "Thailand", label: "Thailand" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Vietnam", label: "Vietnam" },
  ];

  const [selectedCountry, setSelectedCountry] = useState({
    value: user?.country || "India",
    label: user?.country || "India",
  });
  const [isPreviewStaffSelected, setIsPreviewStaffSelected] = useState(
    user?.user_type === "Preview Staff" ? true : false
  );
  const [manageUserErrorMsg, setManageUserErrorMsg] = useState(null);

  const selectedCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const adminManageuserSuccessCallback = () => {
    setManageUserErrorMsg(null);
    adminUsers({ adminUsersSuccessCallback, adminUserFailureCallback });
    toggleManageUser(null);
  };

  const adminManageUserFailureCallback = (msg) => {
    setManageUserErrorMsg(msg);
  };

  const adminDeleteUserCallback = () => {
    setManageUserErrorMsg(null);
    adminUsers({ adminUsersSuccessCallback, adminUserFailureCallback });
    toggleManageUser(null);
  };

  const adminDeleteFailureCallback = (msg) => {
    setManageUserErrorMsg(msg);
  };

  const removeUser = (email) => {
    adminDeleteUser({
      email,
      adminDeleteUserCallback,
      adminDeleteFailureCallback,
    });
  };

  if (width < 1100) {
    return (
      <ManageUserModalBarDiv>
        <ManageUserCancelImage src={CANCEL_IMAGE} onClick={toggleManageUser} />
        <ManageUserDiv>
          <ManageUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <ManageUserAddtext><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MANAGE_USER"}
        /></ManageUserAddtext>
        </ManageUserDiv>
        <Formik
          initialValues={{ email: user?.email, name: user?.name }}
          onSubmit={(values, actions) => {
            adminManageUser({
              email: values.email,
              name: values.name,
              country: selectedCountry.value,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              adminManageuserSuccessCallback,
              adminManageUserFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={"India"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={selectedCountry}
                    onChange={selectedCountryChange}
                    options={options}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>
                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    disabled
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label style={{ fontSize: "12px" }}>
                    {formikProps.values.email}
                  </label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"NAME_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>
                <RoleText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ROLE"}
        /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF_ROLE"}
        />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                {manageUserErrorMsg != null && (
                  <ErrorMessage>{manageUserErrorMsg}</ErrorMessage>
                )}
                <ButtonBar>
                  <RemoveUser
                    onClick={() => {
                      removeUser(formikProps.values.email);
                    }}
                  >
                    <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"REMOVE_USER"}
        />
                  </RemoveUser>
                  <SaveButton
                    type="submit"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                    isDisabled={!formikProps.isValid}
                  >
                    <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SUBMIT_BUTTON"}
        />
                  </SaveButton>
                </ButtonBar>
              </>
            );
          }}
        </Formik>
      </ManageUserModalBarDiv>
    );
  } else {
    return (
      <ManageUserSideBar>
        <ManageUserCancelImage src={CANCEL_IMAGE} onClick={toggleManageUser} />
        <ManageUserDiv>
          <ManageUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <ManageUserAddtext><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MANAGE_USER"}
        /></ManageUserAddtext>
        </ManageUserDiv>
        <Formik
          initialValues={{ email: user?.email, name: user?.name }}
          onSubmit={(values, actions) => {
            adminManageUser({
              email: values.email,
              name: values.name,
              country: selectedCountry.value,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              adminManageuserSuccessCallback,
              adminManageUserFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={"India"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={selectedCountry}
                    onChange={selectedCountryChange}
                    options={options}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>
                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    disabled
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label style={{ fontSize: "12px" }}>
                    {formikProps.values.email}
                  </label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"NAME_DETAILS"}
        /></label>
                  <div className="border-around"></div>
                </div>
                <RoleText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ROLE"}
        /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_STAFF_ROLE"}
        />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_SUPERVISOR"}
        />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                {manageUserErrorMsg != null && (
                  <ErrorMessage>{manageUserErrorMsg}</ErrorMessage>
                )}
                <ButtonBar>
                  <RemoveUser
                    onClick={() => {
                      removeUser(formikProps.values.email);
                    }}
                  >
                    <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"REMOVE_USER"}
        />
                  </RemoveUser>
                  <SaveButton
                    type="submit"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                    isDisabled={!formikProps.isValid}
                  >
                   <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"SUBMIT_BUTTON"}
        />
                  </SaveButton>
                </ButtonBar>
              </>
            );
          }}
        </Formik>
      </ManageUserSideBar>
    );
  }
};

export default AdminUserManageUser;
