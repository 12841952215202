import React from "react";
import {
  ConsumerHomeName,
  ConsumerHomeUploadedDiv,
  WelcomeBackText,
  ConsumerNameHeaderDiv,
  ViewResult,
  ConsumerNameDiv,
  SubmitRequestButton,
  ConsumerHomeUserCards,
  ConsumerHomeUploadedImage,
  ConsumerHomeUserCardsContainer,
  RequestIdText,
  RecolourByText,
  RecolourButton,
  RequestReceivedButton,
  DetailArrow,
  SubmitAnotherRequestDiv,
  ErrorMessage,
} from "../../styles/ConsumerHomeStyles";

import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import PAPER_PLANE_IMAGE from "../../images/paper_plane.svg";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerHomeCard = ({
  value,
  decodedToken,
  state,
  reColourAgainHandler,
  imageUploadOptions,
  goToReportDetail,
  errorMessage,
  selectedCountry,
}) => {
  return (
    <ConsumerHomeUploadedDiv>
      <ConsumerNameHeaderDiv>
        <ConsumerNameDiv>
        {decodedToken?.name != "NULL" && (
          <ConsumerHomeName>
          <LanguageSelector
              selectedCountry={selectedCountry}
              text={"HI"}
            />{" "}
            {decodedToken?.name}
            {"!"}
          </ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == " " && (
          <ConsumerHomeName>{"!"}</ConsumerHomeName>)}
          {decodedToken?.name == "NULL" && (
          <ConsumerHomeName>
          <LanguageSelector
              selectedCountry={selectedCountry}
              text={"HI"}
            />{" "}
            {state.data?.requests[0]?.name }
            {"!"}
          </ConsumerHomeName>)}
          <WelcomeBackText>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"WELCOME_YOU_HAVE_MADE"}
            />{" "}
            {state.data?.requests?.length || 0}
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"PREVIEW_REQUESTS_SO_FAR"}
            />
          </WelcomeBackText>
        </ConsumerNameDiv>
        {/* <RecolorImageButton onClick={reColourAgainHandler}>
              Re-colour Image
            </RecolorImageButton> */}
      </ConsumerNameHeaderDiv>
      {errorMessage != null && (
        <ErrorMessage
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {errorMessage}
        </ErrorMessage>
      )}
      <ConsumerHomeUserCardsContainer>
        <SubmitAnotherRequestDiv>
          <img src={PAPER_PLANE_IMAGE} alt="Paper Plane" />
          <SubmitRequestButton onClick={reColourAgainHandler}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"CLICK_HERE_TO_SUBMIT"}
            />
          </SubmitRequestButton>
        </SubmitAnotherRequestDiv>
        {state.data &&
          state.data?.requests?.map((item, index) => {
            return (
              <ConsumerHomeUserCards key={`${index}`}>
                <ConsumerHomeUploadedImage
                  src={item?.raw_image}
                  alt={item.request_id}
                />
                {item?.status === "Resolved" && (
                  <DetailArrow>
                    <img src={GREEN_ARROW_IMAGE} alt="Resolve request" />
                  </DetailArrow>
                )}
                <RequestIdText>
                  <LanguageSelector
                    selectedCountry={selectedCountry}
                    text={"REQUEST_ID"}
                  />{" "}
                  : {item?.request_id}
                </RequestIdText>
                <RecolourByText>
                  {item?.colourization_type === "Theme" && (
                    <LanguageSelector
                      selectedCountry={selectedCountry}
                      text={"RE_COLOUR_BY_THEME"}
                    />
                  )}
                  {item?.colourization_type === "Lead Colour" && (
                    <LanguageSelector
                      selectedCountry={selectedCountry}
                      text={"RE_COLOUR_BY_LEAD_COLOUR"}
                    />
                  )}

                  {item?.colourization_type === "Reference" && (
                    <LanguageSelector
                      selectedCountry={selectedCountry}
                      text={"RE_COLOUR_BY_REFERENCE"}
                    />
                  )}
                </RecolourByText>
                {item?.status === "Resolved" && (
                  <>
                    <RecolourButton onClick={imageUploadOptions}>
                      <LanguageSelector
                        selectedCountry={selectedCountry}
                        text={"RE_COLOUR_AGAIN"}
                      />
                    </RecolourButton>
                    <ViewResult
                      onClick={() => {
                        if (item?.status === "Resolved") {
                          goToReportDetail(item);
                        }
                      }}
                    >
                      <LanguageSelector
                        selectedCountry={selectedCountry}
                        text={"VIEW_RESULT"}
                      />
                    </ViewResult>
                  </>
                )}
                {item?.status === "Pending" && (
                  <RequestReceivedButton>
                    <LanguageSelector
                      selectedCountry={selectedCountry}
                      text={"REQUEST_RECIEVED"}
                    />
                  </RequestReceivedButton>
                )}
              </ConsumerHomeUserCards>
            );
          })}
      </ConsumerHomeUserCardsContainer>
    </ConsumerHomeUploadedDiv>
  );
};

export default ConsumerHomeCard;
