import React from "react";
import { Redirect, Route } from "react-router-dom";
import { CONSUMER_UPLOAD_IMAGE_ROUTE } from "./Constants";

const AdminRoute = (props) => {
  const { decodedToken } = props;
  if (
    decodedToken === false ||
    decodedToken === null ||
    decodedToken === undefined
  ) {
    return <Redirect to={CONSUMER_UPLOAD_IMAGE_ROUTE} />;
  } else {
    if (decodedToken?.user_type === "Super Admin") {
      return <Route component={props.component} {...props} />;
    } else {
      return <Redirect to={CONSUMER_UPLOAD_IMAGE_ROUTE} />;
    }
  }
};

export default AdminRoute;
