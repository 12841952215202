/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ConsumerChooseColourDrawerDiv,
  TitleColour,
  DrawerTitleDiv,
  CloseModalImage,
  ColorBodyDiv,
  ColorDiv,
  DoneButton,
  ToolTip,
  ConsumerChooseColourModalBarDiv,
} from "../../styles/ConsumerChooseColorStyles";
import CANCEL_IMAGE from "../../images/cancel.svg";

const ConsumerChooseColourDrawer = ({
  openSideMenu,
  toggleModal,
  width,
  familyColour,
  selectedColour,
  setSelectedColourFromDrawer,
  getSuggesstionsHandler,
  selectedColourFromDrawer,
  setIsFromSearch,
  colourList
}) => {
  const [list, setList] = useState([]);

  const familyColourSuccessCallback = (colourList) => {
    setList(colourList);
  };

  const familyColorFailureCallback = () => {};

  const selectedColorHandler = (data) => {
    setSelectedColourFromDrawer(data);
    setIsFromSearch(false);
    getSuggesstionsHandler(data.colour_name);
    toggleModal();
  };

  const doneClickHandler = () => {
    setIsFromSearch(false);
    getSuggesstionsHandler(selectedColourFromDrawer.colour_name);
    toggleModal();
  };

  useEffect(() => {
    if (selectedColour !== null) {
      console.log(selectedColour)
      familyColour({
        colour_family: selectedColour,
        familyColourSuccessCallback,
        familyColorFailureCallback,
      });
    }
  }, []);

  if (width < 1100) {
    return (
      <ConsumerChooseColourModalBarDiv openSideMenu={openSideMenu}>
        <DrawerTitleDiv>
          <TitleColour>{colourList[colourList.map(e => e.name).indexOf(selectedColour)].label}</TitleColour>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
        </DrawerTitleDiv>
        <ColorBodyDiv>
          {list.map((data, index) => {
            return (
              <ColorDiv
                key={`${index}`}
                style={{
                  backgroundColor: `rgb(${data.red_value},${data.green_value},${data.blue_value})`,
                  border:
                    data === selectedColourFromDrawer
                      ? "4px solid black"
                      : "none",
                }}
                onClick={() => {
                  selectedColorHandler(data);
                }}
              >
                <ToolTip>{data.colour_name}</ToolTip>
              </ColorDiv>
            );
          })}
        </ColorBodyDiv>
        <DoneButton onClick={doneClickHandler}>Done</DoneButton>
      </ConsumerChooseColourModalBarDiv>
    );
  } else {
    return (
      <ConsumerChooseColourDrawerDiv openSideMenu={openSideMenu}>
        <DrawerTitleDiv>
          <TitleColour>{colourList[colourList.map(e => e.name).indexOf(selectedColour)].label}</TitleColour>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
        </DrawerTitleDiv>
        <ColorBodyDiv>
          {list.map((data, index) => {
            return (
              <ColorDiv
                key={`${index}`}
                style={{
                  backgroundColor: `rgb(${data.red_value},${data.green_value},${data.blue_value})`,
                  border:
                    data === selectedColourFromDrawer
                      ? "4px solid black"
                      : "none",
                }}
                onClick={() => {
                  selectedColorHandler(data);
                }}
              >
                <ToolTip>{data.colour_name}</ToolTip>
              </ColorDiv>
            );
          })}
        </ColorBodyDiv>
        <DoneButton onClick={doneClickHandler}>Done</DoneButton>
      </ConsumerChooseColourDrawerDiv>
    );
  }
};

export default ConsumerChooseColourDrawer;
