/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  CancelImage,
  SideBar,
  MyTeamSideBarHeaderDiv,
  MyTeamSideBarHeaderText,
  MyTeamSideBearHeaderImage,
  SidebarModalDiv,
} from "../../styles/PreviewSupervisorDashboardStyles";
import Chart from "chart.js";
import styled from "styled-components";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import MY_TEAM_IMAGE from "../../images/my_team.svg";
import PENDING_REQUEST_IMAGE from "../../images/pending_request.svg";
import RESOLVE_REQUEST_IMAGE from "../../images/resolve_request.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardMyTeamSideBar = ({
  openMyTeamMenu,
  toggleOpenMyTeamMenu,
  toggleOpenAddMember,
  width,
  dashboardData,
  previewSupervisorMyTeam,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const selectedCountry = decodedToken
      ? {
          value: decodedToken?.country,
          label: decodedToken?.country,
        }
      : countryValue
  
  const noOfResolvedRequests = selectedCountry.value == "Vietnam" ? "Số yêu cầu đã giải quyết" : "No. of resolved requests";

  const [userNameList, setUserNameList] = useState([]);
  const [userDataList, setUserDataList] = useState([]);

  const previewSupervisorMyTeamSuccessCallback = (data) => {
    console.log("@@@@@@@@@@",data, data.users[0].name)
    setUserNameList([]);
    setUserDataList([]);
    for (let i = 0; i < data.users.length; i++) {
      setUserNameList((oldArray) => [...oldArray, data.users[i].name]);
      setUserDataList((oldArray) => [
        ...oldArray,
        data.users[i].resolved_requests,
      ]);
    }
  };

  const previewSupervisorMyTeamFailureCallback = (msg) => {};

  useEffect(() => {
    previewSupervisorMyTeam({
      previewSupervisorMyTeamSuccessCallback,
      previewSupervisorMyTeamFailureCallback,
    });
  }, []);

  const chatRef = useCallback(
    (node) => {
      if (node !== null) {
        //console.log("user name n data list", userNameList, userDataList)
        // node = chatRef.current
        new Chart(node, {
          type: "bar",
          data: {
            labels: userNameList,

            datasets: [
              {
                data: userDataList,
                backgroundColor: "#EFBF42",
                borderWidth: 0,
                maxBarThickness: 10,
              },
            ],
          },
          options: {
            // tooltips: { enabled: false },
            hover: false,
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: noOfResolvedRequests,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    color: "rgba(0,0,0,0)",
                  },
                },
              ],
            },
          },
        });
      }
    },
    [userNameList]
  );

  if (width < 1100) {
    return (
      <SidebarModalDiv openSideMenu={openMyTeamMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleOpenMyTeamMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MY_TEAM"}
        /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <MonitorTeamPerformanceText>
        <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MONITOR_YOUR_TEAM"}
        />
        </MonitorTeamPerformanceText>
        {userNameList.length > 0 && userDataList.length > 0 && (
          <CharbarDiv>
            <canvas id="myChart" height="200" ref={chatRef}></canvas>
          </CharbarDiv>
        )}
        <SideBarMyTeamFooter>
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={MY_TEAM_IMAGE}
            text={dashboardData !== null ? dashboardData.team : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"MY_TEAM"}
            />}
          />
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={PENDING_REQUEST_IMAGE}
            text={dashboardData !== null ? dashboardData.pending_request : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PENDING_REQUESTS"}
            />}
          />
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={RESOLVE_REQUEST_IMAGE}
            text={dashboardData !== null ? dashboardData.resolved_request : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVED_REQUESTS"}
            />}
          />
        </SideBarMyTeamFooter>
        <AddMemberButton
          onClick={() => {
            toggleOpenMyTeamMenu();
            toggleOpenAddMember();
          }}
        >
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ADD_MEMBER"}
            />
        </AddMemberButton>
      </SidebarModalDiv>
    );
  } else {
    return (
      <SideBar openSideMenu={openMyTeamMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleOpenMyTeamMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MY_TEAM"}
        /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <MonitorTeamPerformanceText>
        <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"MONITOR_YOUR_TEAM"}
        />
        </MonitorTeamPerformanceText>
        {userNameList.length > 0 && userDataList.length > 0 && (
          <CharbarDiv>
            <canvas id="myChart" height="200" ref={chatRef}></canvas>
          </CharbarDiv>
        )}
        <SideBarMyTeamFooter>
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={MY_TEAM_IMAGE}
            text={dashboardData !== null ? dashboardData.team : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"MY_TEAM"}
            />}
          />
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={PENDING_REQUEST_IMAGE}
            text={dashboardData !== null ? dashboardData.pending_request : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PENDING_REQUESTS"}
            />}
          />
          <PreviewSupervisorDashboardMyTeamSideBarFooter
            image={RESOLVE_REQUEST_IMAGE}
            text={dashboardData !== null ? dashboardData.resolved_request : 0}
            subtitle={<LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVED_REQUESTS"}
            />}
          />
        </SideBarMyTeamFooter>
        <AddMemberButton
          onClick={() => {
            toggleOpenMyTeamMenu();
            toggleOpenAddMember();
          }}
        >
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ADD_MEMBER"}
            />
        </AddMemberButton>
      </SideBar>
    );
  }
};

const PreviewSupervisorDashboardMyTeamSideBarFooter = ({
  image,
  text,
  subtitle,
}) => {
  return (
    <SideBarMyTeam>
      <SideBarMyTeamTopDiv>
        <SideBarMyTeamImage src={image} alt="My Team" />
        <SideBarMyTeamNumberText>{text}</SideBarMyTeamNumberText>
      </SideBarMyTeamTopDiv>
      <SidebarMyTeamSubTitle>{subtitle}</SidebarMyTeamSubTitle>
    </SideBarMyTeam>
  );
};

const AddMemberButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 100px;
  margin: 10px 20px 30px 30px;
  :hover {
    cursor: pointer;
  }
`;

const SideBarMyTeamFooter = styled.div`
  display: flex;
  padding: 20px 25px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
  }
`;

const SideBarMyTeam = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 10px 20px;
  margin: 10px;

  @media (max-width: 1100px) {
    width: 40vw;
    padding: 10px;
    margin: 10px;
  }
`;

const SideBarMyTeamTopDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SideBarMyTeamNumberText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #000000;
  margin: 0 10px;
`;

const SidebarMyTeamSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #828282;
  margin: 0;
`;

const SideBarMyTeamImage = styled.img`
  width: 30px;
  height: 30px;
`;

const CharbarDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin: 10px 20px;
  padding: 20px 0px;
  pointer-events: none;
`;

const MonitorTeamPerformanceText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 10px 25px;
`;

export default PreviewSupervisorDashboardMyTeamSideBar;
