import createDataContext from "./createDataContext";
import amarisServer from "../api/amarisServer";
import jwt_decode from "jwt-decode";
import {
  SOMETHING_WENT_WRONG,
  ADMIN_ADD_USER_API,
  ADMIN_MANAGE_USER_API,
  ADMIN_DELETE_USER_API,
  ADMIN_SERVICE_HIGHLIGHTS_API,
  ADMIN_REQUEST_API,
  ADMIN_CONSUMER_LIST_API,
  ADMIN_USERS_LIST_API,
  TOKEN_NAME,
  PREVIEW_SUPERVISOR_REASSIGN_REQUEST_API,
  TEAM_LIST_API,
  PREVIEW_SUPERVISOR_REPORT_API,
  COLOUR_THEME_PALETTE_API,
  PREVIEW_SUPETRVISOR_RESULT_API,
  PREVIEW_SUPERVISOR_RESOLVE_REQUEST_API,
  FAMILY_COLOUR_SUGGESTION_API,
  PS_PRODUCT_LIST,
  PS_SHOP_LIST,
  PS_PAINTER_LIST,
  ADMIN_CONSUMER_LIST_DOWNLOAD,
  ADMIN_REQUEST_LIST_DOWNLOAD,
  ADMIN_USER_LIST_DOWNLOAD,
  ADMIN_FEEDBACK_DETAILS_API,
  ADMIN_BILLING_API,
  ADMIN_ANALYTICS_API,
  ADMIN_INVOICE_DOWNLOAD,
  USER_LOCATION_INFO_API,
  ADMIN_ARCHIVE_API
} from "../utils/Constants";
import Cookies from "js-cookie";

const adminReducer = (state, action) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        ...{ loading: false },
        ...{ errorMessage: action.payload },
      };
    case "resolveRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminAddUser":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminManageUser":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminDeleteUser":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminServiceHighlights":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminRequestLists":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminCustomersList":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminUsers":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "reassignRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminTeam":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "requestReport":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "resultRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminSelector":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "userLocationInfo":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "adminArchiveRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "inProgress":
      return { ...state, ...{ loading: true }, ...{ errorMessage: "" } };
    default:
      return state;
  }
};

const resolveRequest = (dispatch) => {
  return async ({
    request_id,
    results,
    shop_id,
    product_id,
    painter_id,
    resolveRequestSuccessCallback,
    resolveRequestFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    // let resultIdArray = [];

    // console.log(resultIdArray[0]);

    // for (var i = 0; i < results.length; i++) {
    //   resultIdArray.push(results[i].result_id);
    // }
    // const payload = resultIdArray.reduce(
    //   (acc, el, index) => ({ ...acc, [`result_id${index + 1}`]: el }),
    //   {}
    // );
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_RESOLVE_REQUEST_API,
        {
          request_id,
          result_id1: results[0].result_id,
          result_id2: results[1] !== undefined ? results[1].result_id : null,
          result_id3: results[2] !== undefined ? results[2].result_id : null,
          shop_id,
          product_id,
          painter_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({ type: "resolveRequest", payload: response.data });
          resolveRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          resolveRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        resolveRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const resultRequest = (dispatch) => {
  return async ({
    request_id,
    resultRequestSuccessCallback,
    resultRequestFailureCallback,
    isTheme,
    isLead
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPETRVISOR_RESULT_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          if (isTheme) {
            amarisServer
              .post(
                COLOUR_THEME_PALETTE_API,
                {
                  theme_name: response.data.request_detail.theme_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourPaletteResponse) => {
                if (colourPaletteResponse.status === 200) {
                  resultRequestSuccessCallback(colourPaletteResponse.data);
                  dispatch({
                    type: "resultRequest",
                    payload: {
                      result: response.data,
                      palette: colourPaletteResponse.data,
                    },
                  });
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });
                  resultRequestFailureCallback(
                    colourPaletteResponse?.data?.detail?.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourPaletteError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                resultRequestFailureCallback(
                  colourPaletteError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } if (isLead) {
            amarisServer
              .post(
                FAMILY_COLOUR_SUGGESTION_API,
                {
                  colour_name: response.data.request_detail.colour_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourSuggestionResponse) => {
                if (colourSuggestionResponse.status === 200) {
                  resultRequestSuccessCallback(colourSuggestionResponse.data);
                  dispatch({
                    type: "resultRequest",
                    payload: {
                      result: response.data,
                      colourSuggestion: colourSuggestionResponse.data,
                    },
                  });
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });

                  resultRequestFailureCallback(
                    colourSuggestionResponse?.data?.detail?.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourSuggestionError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });

                resultRequestFailureCallback(
                  colourSuggestionError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } else {
            dispatch({ type: "resultRequest", payload: response.data });
            resultRequestSuccessCallback();
          }
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          resultRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        resultRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const requestReport = (dispatch) => {
  return async ({
    request_id,
    requestReportSuccessCallback,
    requestReportFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REPORT_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          if (response.data.request_details?.colourization_type === "Theme") {
            amarisServer
              .post(
                COLOUR_THEME_PALETTE_API,
                {
                  theme_name: response.data.request_details?.theme_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourPaletteResponse) => {
                if (colourPaletteResponse.status === 200) {
                  dispatch({
                    type: "requestReport",
                    payload: {
                      result: response.data,
                      palette: colourPaletteResponse.data,
                    },
                  });
                  requestReportSuccessCallback();
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });
                  requestReportFailureCallback(
                    colourPaletteResponse.data.detail.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourPaletteError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                requestReportFailureCallback(
                  colourPaletteError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } else {
            dispatch({
              type: "requestReport",
              payload: { result: response.data },
            });
            requestReportSuccessCallback();
          }
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          requestReportFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        requestReportFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const reassignRequest = (dispatch) => {
  return async ({
    request_id,
    email,
    reassignRequestFailureCallback,
    reassignRequestSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REASSIGN_REQUEST_API,
        {
          request_id,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({ type: "reassignRequest", payload: response.data });
          reassignRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          reassignRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        reassignRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminTeam = (dispatch) => {
  return async ({ adminTeamSuccessCallback }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        TEAM_LIST_API,
        {
          country: decodedToken.country,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          adminTeamSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          // alert(response?.data?.detail?.status_message || SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        // alert(error.response.data.detail.message || SOMETHING_WENT_WRONG);
      });
  };
};

const adminUsers = (dispatch) => {
  return async ({ adminUsersSuccessCallback, adminUserFailureCallback }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .get(ADMIN_USERS_LIST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminUsers",
            payload: response.data,
          });
          adminUsersSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminUserFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminUserFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminAddUser = (dispatch) => {
  return async ({
    email,
    name,
    city,
    country,
    user_type,
    adminAddUserSuccessCallback,
    adminAddUserFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_ADD_USER_API,
        {
          email,
          name,
          city,
          country,
          user_type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminAddUser",
            payload: response.data,
          });
          adminAddUserSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminAddUserFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminAddUserFailureCallback(
          error?.response?.data?.detail?.status_code === 1201 ? "User already exists" : SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminManageUser = (dispatch) => {
  return async ({
    email,
    name,
    country,
    user_type,
    adminManageuserSuccessCallback,
    adminManageUserFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_MANAGE_USER_API,
        {
          email,
          name,
          country,
          user_type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminManageUser",
            payload: response.data,
          });
          adminManageuserSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminManageUserFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminManageUserFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
        // alert(error.response.data.detail.message || SOMETHING_WENT_WRONG);
      });
  };
};

const adminServiceHighlights = (dispatch) => {
  return async ({ from, to, adminServiceSuccessCallback }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_SERVICE_HIGHLIGHTS_API,
        {
          from,
          to,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminServiceHighlights",
            payload: response.data,
          });
          adminServiceSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          alert(response?.data?.detail?.status_message || SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        alert(error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG);
      });
  };
};

const adminCustomersList = (dispatch) => {
  return async ({
    adminCustomersSuccessCallback,
    adminCustomerFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .get(ADMIN_CONSUMER_LIST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminCustomersList",
            payload: response.data,
          });
          adminCustomersSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminCustomerFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminCustomerFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminRequestLists = (dispatch) => {
  return async ({
    // month,
    adminRequestsSuccessCallback,
    adminRequestsFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_REQUEST_API,
        {
          // month:"October",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminRequestLists",
            payload: response.data,
          });
          adminRequestsSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminRequestsFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminRequestsFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminDeleteUser = (dispatch) => {
  return async ({
    email,
    adminDeleteUserCallback,
    adminDeleteFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_DELETE_USER_API,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminDeleteUser",
            payload: response.data,
          });
          adminDeleteUserCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminDeleteFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminDeleteFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminSelector = (dispatch) => {
  return async ({ country, adminCallback, adminFailureCallback }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        PS_PRODUCT_LIST,
        {
          country: country,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((productListResponse) => {
        if (productListResponse.data.status_code === 1100) {
          amarisServer
            .post(
              PS_SHOP_LIST,
              {
                country: country,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((shopListResponse) => {
              if (shopListResponse.data.status_code === 1100) {
                amarisServer
                  .post(
                    PS_PAINTER_LIST,
                    {
                      country: country,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((painterListResponse) => {
                    if (painterListResponse.data.status_code === 1100) {
                      dispatch({
                        type: "adminSelector",
                      });
                      adminCallback(
                        productListResponse.data.products,
                        shopListResponse.data.shops,
                        painterListResponse.data.painters
                      );
                    } else {
                      dispatch({
                        type: "error",
                        payload: SOMETHING_WENT_WRONG,
                      });
                      adminFailureCallback(
                        painterListResponse?.data?.detail?.status_message ||
                        SOMETHING_WENT_WRONG
                      );
                    }
                  })
                  .catch((error) => {
                    dispatch({
                      type: "error",
                      payload: SOMETHING_WENT_WRONG,
                    });
                    adminFailureCallback(
                      error?.response?.data?.detail?.message ||
                      SOMETHING_WENT_WRONG
                    );
                  });
              } else {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                adminFailureCallback(
                  shopListResponse?.data?.detail?.status_message ||
                  SOMETHING_WENT_WRONG
                );
              }
            })
            .catch((error) => {
              dispatch({
                type: "error",
                payload: SOMETHING_WENT_WRONG,
              });
              adminFailureCallback(
                error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
              );
            });
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminFailureCallback(
            productListResponse?.data?.detail?.status_message ||
            SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminConsumerExcelDownload = (dispatch) => {
  return async ({
    adminConsumerExcelSuccessCallback,
    adminConsumerFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .get(ADMIN_CONSUMER_LIST_DOWNLOAD, {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        adminConsumerExcelSuccessCallback(response.data);
      })
      .catch((error) => {
        adminConsumerFailureCallback(JSON.stringify(error));
      });
  };
};

const adminUserExcelDownload = (dispatch) => {
  return async ({
    adminUserExcelSuccessCallback,
    adminUserExcelFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .get(ADMIN_USER_LIST_DOWNLOAD, {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        adminUserExcelSuccessCallback(response.data);
      })
      .catch((error) => {
        adminUserExcelFailureCallback(JSON.stringify(error));
      });
  };
};

const adminRequestsExcelDownload = (dispatch) => {
  return async ({
    fromDate,
    toDate,
    selectedCountries,
    selectedReColourType,
    adminRequestExcelSuccessCallback,
    adminRequestExcelFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(ADMIN_REQUEST_LIST_DOWNLOAD, 
        {
          fromDate: fromDate,
          toDate: toDate,
          selectedCountries: selectedCountries,
          selectedReColourType: selectedReColourType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        responseType: "arraybuffer",
      })
      .then((response) => {
        adminRequestExcelSuccessCallback(response.data);
      })
      .catch((error) => {
        adminRequestExcelFailureCallback(JSON.stringify(error));
      });
  };
};

const adminInvoiceDownload = (dispatch) => {
  return async ({
    tempFromDateYear,
    tempFromMonth,
    tempToDateYear,
    tempToMonth,
    adminInvoiceSuccessCallback,
    adminInvoiceFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    console.log("posting to billing api*****************")
    amarisServer
      .post(ADMIN_INVOICE_DOWNLOAD,
        {
          tempFromDateYear:tempFromDateYear,
          tempFromMonth:tempFromMonth,
          tempToDateYear:tempToDateYear,
          tempToMonth:tempToMonth
        },
        {
          headers: {
            // "Content-Disposition": "attachment; filename=invoice.zip",
            // "Content-Type":
            //   "application/zip, application/octet-stream",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        })
      .then((response) => {
        adminInvoiceSuccessCallback(response.data);
      })
      .catch((error) => {
        console.log(error,"!!!!!!!!!!!!!!!!")
        adminInvoiceFailureCallback(JSON.stringify(error));
      });
  };
};

const feedbackResult = (dispatch) => {
  return async ({
    request_id,
    feedbackResultSuccessCallback,
    feedbackResultFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_FEEDBACK_DETAILS_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status_code === 1100) {
          feedbackResultSuccessCallback(response.data);
        } else {
          feedbackResultFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        feedbackResultFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const billing = (dispatch) => {
  return async ({
    _from,
    to,
    adminBillingSuccessCallback,
    adminBillingFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_BILLING_API,
        {
          _from,
          to,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((billingResponse) => {
        if (billingResponse.data.status_code === 1100) {
          adminBillingSuccessCallback(billingResponse.data);
        } else {
          adminBillingFailureCallback(
            billingResponse?.data?.detail?.status_message ||
            SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        adminBillingFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const analytics = (dispatch) => {
  return async ({
    _from,
    to,
    country,
    analyticsSuccessCallback,
    analyticsFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_ANALYTICS_API,
        { _from, to, country },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((analyticsResponse) => {
        if (analyticsResponse.data.status_code === 1100) {
          analyticsSuccessCallback(analyticsResponse.data);
        } else {
          analyticsFailureCallback(
            analyticsResponse?.data?.detail?.status_message ||
            SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        analyticsFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const getConsumerLocationInfo = (dispatch) => {
  return async ({
    email,
    getConsumerLocationInfoSuccessCallback,
    getConsumerLocationInfoFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        USER_LOCATION_INFO_API,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status_code === 1100) {
          getConsumerLocationInfoSuccessCallback(response.data);
        } else {
          getConsumerLocationInfoFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        getConsumerLocationInfoFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminArchiveRequest = (dispatch) => {
  return async ({
    request_id,
    adminArchiveRequestSuccessCallback,
    adminArchiveRequestFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_ARCHIVE_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "adminArchiveRequest",
            payload: response.data,
          });
          adminArchiveRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          adminArchiveRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        adminArchiveRequestFailureCallback(
          error?.response?.data?.detail?.status_code === 1201 ? "Error with data" : SOMETHING_WENT_WRONG
        );
      });
  };
};

export const { Provider, Context } = createDataContext(
  adminReducer,
  {
    adminAddUser,
    adminManageUser,
    adminDeleteUser,
    adminServiceHighlights,
    adminRequestLists,
    adminCustomersList,
    adminUsers,
    reassignRequest,
    adminTeam,
    requestReport,
    resultRequest,
    resolveRequest,
    adminSelector,
    adminConsumerExcelDownload,
    adminUserExcelDownload,
    adminRequestsExcelDownload,
    feedbackResult,
    billing,
    analytics,
    adminInvoiceDownload,
    getConsumerLocationInfo,
    adminArchiveRequest
  },
  []
);
