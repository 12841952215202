import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  PaletteDiv,
  OptionTextStyle,
  ResultImage,
  ToolTip,
  ResultImageDiv,
  ResultColumnDiv,
  PaletteWrapDiv,
  ViewDetailsButton,
} from "../../styles/PreviewSupervisorResultStyles";
import LanguageSelector from "../../language/LanguageSelector";

const ResultComp = ({
  state,
  toggleModal,
  viewDetailHandler,
  setSelectedModalIndex,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <ResultImageDiv>
      {state.data &&
        state.data?.result?.report_details?.results?.length > 0 &&
        state.data?.result?.report_details?.results.map((item, index) => {
          return (
            <ResultColumnDiv key={`${index}`}>
              <OptionTextStyle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            /> {index + 1}</OptionTextStyle>
              <ResultImage
                src={item.result_image}
                onClick={() => {
                  setSelectedModalIndex(index);
                  toggleModal();
                }}
              />
              <PaletteWrapDiv>
                {item.colours.map((item2, index2) => {
                  return (
                    <PaletteDiv
                      key={`${index}${index2}`}
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </PaletteDiv>
                  );
                })}
              </PaletteWrapDiv>
              <ViewDetailsButton onClick={() => viewDetailHandler(index)}>
              <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"VIEW_DETAILS"}
            />
              </ViewDetailsButton>
            </ResultColumnDiv>
          );
        })}
    </ResultImageDiv>
  );
};

export default ResultComp;
