/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  ConsumerResultPersonalSideBarDiv,
  PersonalDetailsDiv,
  EmailVerificationText,
  MoreDetailsText,
  LoginButton,
  LocateMeButton,
  ConsumerPersonlaDetailsModalBarDiv,
  ErrorMessage,
} from "../../styles/ConsumerResultStyles";
import Cookies, { set } from "js-cookie";
import jwt_decode from "jwt-decode";
import { CONSUMER_RESULT_ROUTE, TOKEN_NAME } from "../../utils/Constants";
import "../../styles/personDetailsInput.css";
import Select from "react-select";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const ConsumerResultPersonalDetails = ({
  personalDetails,
  togglePersonalDetailsMenu,
  history,
  colourizationContext,
  colourizationRequest,
  width,
  getGeocodingDetails,
  floorArea,
  floorNumber,
  roomNumber,
  wallOwnerContact,
  setWallOwnerContact,
  wallOwnerAddress,
  setWallOwnerAddress,
  wallOwnerName,
  setWallOwnerName,
  customerCode,
  setCustomerCode,
  customerType,
  setCustomerType,
}) => {
  const options = [
    { value: "India", label: "India" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Singapore", label: "Singapore" },
    { value: "Thailand", label: "Thailand" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Vietnam", label: "Vietnam" },
  ];

  // const customerTypeOptions = [
  //   {
  //     value: "Wall Owner",
  //     label: (
  //       <LanguageSelector
  //         selectedCountry={selectedCountry}
  //         text={"WALL_OWNER"}
  //       />
  //     ),
  //   },
  //   { value: "Painter", label: "Painter" },
  //   { value: "Retailer", label: "Retailer" },
  //   { value: "Employee", label: "Employee" },
  // ];

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : "";
  const geoFlag = [false, false];

  const [selectedCountry, setSelectedCountry] = useState({
    value: "Singapore",
    label: "Singapore",
  });

  const countryValue = JSON.parse(localStorage.getItem("country"));

  let lowercase_country = countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);

  const [name, setName] = useState(
    decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.name !== null &&
      decodedToken.name !== "NULL"
      ? decodedToken?.name
      : ""
  );
  const [city, setCity] = useState(
    decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.city !== null &&
      decodedToken.city !== "NULL"
      ? decodedToken?.city
      : ""
  );
  const [contact, setContact] = useState(
    decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.phone !== null &&
      decodedToken.phone !== "NULL"
      ? decodedToken?.phone
      : ""
  );
  const [address, setAddress] = useState(
    decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.address !== null &&
      decodedToken.address !== "NULL"
      ? decodedToken?.address
      : ""
  );
  const [remarks, setRemarks] = useState(
    decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.address !== null &&
      decodedToken.address !== "NULL"
      ? decodedToken?.address
      : ""
  );
  const [allowToSubmit, setAllowToSubmit] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [enablePartTwo, setEnablePartTwo] = useState(false);

  const selectedCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const toggleAllowToSubmit = () =>
    setAllowToSubmit((previousState) => !previousState);

  const showError = (error) => {
    let lowercase_country =
      countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
    console.log(language["USER_GEOLOCATION_DENIED"][lowercase_country]);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setErrorMessage(language["USER_GEOLOCATION_DENIED"][lowercase_country]);
        break;
      case error.POSITION_UNAVAILABLE:
        setErrorMessage(language["LOCATION_INFO_UNAVAIL"][lowercase_country]);
        break;
      case error.TIMEOUT:
        setErrorMessage(
          language["USER_LOCATION_REQ_TIMEOUT"][lowercase_country]
        );
        break;
      case error.UNKNOWN_ERROR:
        setErrorMessage(
          language["UNKNOWN_ERROR_WENT_WRONG"][lowercase_country]
        );
        break;
      default:
        setErrorMessage(language["SOMETHING_WENT_WRONG"][lowercase_country]);
        break;
    }
  };

  const getGeocodingSuccessCallback = (data) => {
    lowercase_country =
      countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
    for (var res_index = 0; res_index < data.results.length; res_index++) {
      data.results[res_index].address_components.map((addr) => {
        if (
          addr.types.includes("country") &&
          addr.types.includes("political")
        ) {
          var result = options.filter(
            (x) => x.value.toLowerCase() === addr.long_name.toLowerCase()
          );
          if (result.length > 0) {
            setSelectedCountry({
              value: addr.long_name,
              label: addr.long_name,
            });
            geoFlag[1] = true;
          }
        } else if (
          addr.types.includes("locality") &&
          addr.types.includes("political")
        ) {
          setCity(addr.long_name);
          geoFlag[0] = true;
        } else if (
          addr.types.includes("administrative_area_level_1") &&
          addr.types.includes("political")
        ) {
          setCity(addr.long_name);
          geoFlag[0] = true;
        }
        return true;
      });
    }

    if (geoFlag[0] === false) {
      setCity("");
      setErrorMessage(language["SET_CITY_MANUALLY"][lowercase_country]);
    }
  };

  const getGeocodingFailureCallback = () => {
    lowercase_country =
      countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
    setErrorMessage(language["LOCATION_INFO_FAILED"][lowercase_country]);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        getGeocodingDetails({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          getGeocodingSuccessCallback,
          getGeocodingFailureCallback,
        });
      }, showError);
    } else {
      setErrorMessage(language["GEOLOCATION_NOT_SUPPORTED"][lowercase_country]);
    }
  };

  const colourizationRequestSuccessCallback = (colourization_type) => {
    // if (data.limit_reached === true) {
    //   alert(language["REQUEST_LIMIT_ALERT"][lowercase_country]);
    // }
    // togglePersonalDetailsMenu();
    // if (countryValue.value === "India") {
    // }
    // const value = colourizationContext.colourizationDetails;
    const type = colourization_type;
    // colourizationContext.setColourizationDetails({
    //   raw_image: "",
    //   colourization_type: "",
    //   reference: "",
    //   theme: "",
    //   lead_colour: "",
    //   email: value.email || decodedToken?.email,
    //   name: "",
    //   city: "",
    //   phone: "",
    //   address: "",
    //   country: "",
    //   remarks: "",
    //   floor_area: "",
    //   floors_number: "",
    //   rooms_number: "",
    //   customer_code: "",
    //   customer_type: "",
    //   wall_owner_name: "",
    //   user_email: "",
    //   wall_owner_address: "",
    //   mobile: "",
    // });
    history.push(CONSUMER_RESULT_ROUTE, {
      type,
    });
    return;
  };

  const colourizationRequestFailureCallback = (msg) => {
    let lowercase_country =
      countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);
    toggleAllowToSubmit();
    if (countryValue.value === "Vietnam") {
      setErrorMessage(language["COLOR_REQUEST_FAIL_ALERT"][lowercase_country]);
    } else {
      setErrorMessage(msg);
    }
  };

  const colourizationRequestCountryHandler = (country) => {
    if (country === "SriLanka" || country === "Srilanka") {
      return "Sri Lanka";
    } else return country;
  };

  const submitPersonDetails = () => {
    const value = colourizationContext.colourizationDetails;
    console.log("********************", value, value.remarks)
    if (
      value.raw_image === undefined ||
      value.colourization_type === undefined
    ) {
      setErrorMessage(language["UPLOAD_IMAGE_TYPE"][lowercase_country]);
    } else {
      const {
        raw_image,
        colourization_type,
        reference,
        theme,
        email,
        lead_colour,
      } = value;

      colourizationContext.setColourizationDetails({
        raw_image: raw_image,
        colourization_type: colourization_type,
        reference: reference,
        theme: theme,
        lead_colour: lead_colour,
        email: email || decodedToken?.email,
        name,
        city,
        phone: contact,
        address,
        country: colourizationRequestCountryHandler(selectedCountry.value),
        remarks: value.remarks,
        floor_area: floorArea,
        floors_number: floorNumber,
        rooms_number: roomNumber,
        customer_code: decodedToken?.customer_code || customerCode,
        customer_type: decodedToken?.customer_type || customerType.value,
        wall_owner_name: wallOwnerName,
        user_email: email || decodedToken?.email,
        wall_owner_address: wallOwnerAddress,
        mobile: wallOwnerContact,
      });
      colourizationRequest({
        raw_image: raw_image,
        colourization_type: colourization_type,
        reference: reference,
        theme: theme,
        lead_colour: lead_colour,
        email: email || decodedToken?.email,
        name,
        city,
        phone: contact,
        address,
        country: colourizationRequestCountryHandler(selectedCountry.value),
        remarks: value.remarks,
        floor_area: floorArea,
        floors_number: floorNumber,
        rooms_number: roomNumber,
        customer_code: decodedToken?.customer_code || customerCode,
        customer_type: decodedToken?.customer_type || customerType.value,
        wall_owner_name: wallOwnerName,
        user_email: email || decodedToken?.email,
        wall_owner_address: wallOwnerAddress,
        mobile: wallOwnerContact,
        colourizationRequestSuccessCallback,
        colourizationRequestFailureCallback,
      });
    }
  };

  const nextHandler = () => {
    setEnablePartTwo(true);
  };

  const submitHandler = () => {
    if (
      name !== null &&
      name !== undefined &&
      (city !== null) & (city !== undefined) &&
      name !== "" &&
      city !== undefined &&
      address !== null &&
      address !== "" &&
      address !== undefined &&
      contact !== null &&
      contact !== "" &&
      contact !== undefined
    ) {
      if (contact.match(/^[+]?[0-9]+$/) !== null) {
        if (allowToSubmit) {
          submitPersonDetails();
          toggleAllowToSubmit();
          //window.lbReload('form-submit', '', '', '')
        }
      } else {
        setErrorMessage(language["ENTER_VALID_CONTACT"][lowercase_country]);
      }
    } else {
      setErrorMessage(language["ENTER_DETAILS"][lowercase_country]);
    }
  };

  const customerTypeOptions = [
    {
      value: "Wall Owner",
      label: (
        <LanguageSelector
          selectedCountry={selectedCountry}
          text={"WALL_OWNER"}
        />
      ),
    },
    {
      value: "Painter",
      label: (
        <LanguageSelector selectedCountry={selectedCountry} text={"PAINTER"} />
      ),
    },
    {
      value: "Retailer",
      label: (
        <LanguageSelector selectedCountry={selectedCountry} text={"RETAILER"} />
      ),
    },
    {
      value: "Employee",
      label: (
        <LanguageSelector selectedCountry={selectedCountry} text={"EMPLOYEE"} />
      ),
    },
  ];

  useEffect(() => {
    if (countryValue.value) {
      setSelectedCountry(countryValue);
    }
    if (
      decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken?.country === "Vietnam" &&
      decodedToken?.customer_type !== null &&
      decodedToken.customer_type !== "NULL"
    ) {
      nextHandler();
      console.log(decodedToken);
    }
  }, []);

  useEffect(() => {
    if (personalDetails) {
      getLocation();
    }
  }, [personalDetails]);

  if (width < 1100) {
    return (
      <ConsumerPersonlaDetailsModalBarDiv personalDetails={personalDetails}>
        {/* <CancelImage
          src={CANCEL_IMAGE}
          onClick={togglePersonalDetailsMenu}
          alt="Cancel"
        /> */}
        <PersonalDetailsDiv>
          <EmailVerificationText>
            <LanguageSelector
              selectedCountry={countryValue.value}
              text={"CONGRATS_EMAIL_VERIFICATION_SUCCESSFUL"}
            />
          </EmailVerificationText>
          <MoreDetailsText>
            <LanguageSelector
              selectedCountry={countryValue.value}
              text={"WE_JUST_NEED"}
            />
          </MoreDetailsText>

          <div className="form-control">
            <input
              required
              type="text"
              name="input"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <label>
              <LanguageSelector
                selectedCountry={countryValue.value}
                text={"NAME_DETAILS"}
              />
            </label>
            <div className="border-around"></div>
          </div>

          <div className="form-control">
            <input
              required
              type="tel"
              name="input"
              value={contact}
              onChange={(event) => setContact(event.target.value)}
            />
            <label>
              <LanguageSelector
                selectedCountry={countryValue.value}
                text={"CONTACT_DETAILS"}
              />
            </label>
            <div className="border-around"></div>
          </div>

          <div className="form-control">
            <input
              required
              type="text"
              name="input"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
            <label>
              <LanguageSelector
                selectedCountry={countryValue.value}
                text={"ADDRESS_DETAILS"}
              />
            </label>
            <div className="border-around"></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="form-control">
              <input
                required
                type="text"
                name="input"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <label>
                <LanguageSelector
                  selectedCountry={countryValue.value}
                  text={"CITY_DETAILS"}
                />
              </label>
              <div className="border-around"></div>
            </div>
            <LocateMeButton onClick={getLocation}>
              <LanguageSelector
                selectedCountry={countryValue.value}
                text={"LOCATE_ME"}
              />
            </LocateMeButton>
          </div>

          <Select
            placeholder={
              <LanguageSelector
                selectedCountry={countryValue.value}
                text={"COUNTRY_DETAILS"}
              />
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            value={countryValue.value}
            onChange={selectedCountryChange}
            options={options}
            styles={{
              singleValue: (styles) => ({
                color: "#545850",
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "150%",
                color: "#545850",
                width: "280px",
                marginTop: "20px",
              }),
              option: (styles) => ({
                ...styles,
                backgroundColor: "white",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "150%",
                color: "#545850",
                width: "280px",
              }),
            }}
          />

          <script>lbReload('form-submit', '', '', '');</script>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Default checkbox
            </label>
          </div>

          <LoginButton type="submit" onClick={submitHandler} isDisabled={false}>
            <LanguageSelector
              selectedCountry={countryValue.value}
              text={"SUBMIT_BUTTON"}
            />
          </LoginButton>

          {/* <Formik
            initialValues={{
              name:
                decodedToken !== null && decodedToken !== undefined
                  ? decodedToken?.name
                  : "",
              city: "",
            }}
            onSubmit={(values, actions) => {
              if (allowToSubmit) {
               
              }
            }}
            validationSchema={personalDetailsValidationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <div className="form-control">
                    <input
                      required
                      type="text"
                      name="input"
                      value={formikProps.values.name}
                      onChange={formikProps.handleChange("name")}
                      onBlur={formikProps.handleBlur("name")}
                    />
                    <label>Name</label>
                    <div className="border-around"></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="form-control">
                      <input
                        required
                        type="text"
                        name="input"
                        value={formikProps.values.city}
                        onChange={formikProps.handleChange("city")}
                        onBlur={formikProps.handleBlur("city")}
                      />
                      <label>City</label>
                      <div className="border-around"></div>
                    </div>
                    <LocateMeButton onClick={getLocation}>
                      Locate me
                    </LocateMeButton>
                  </div>
                  <Select
                    placeholder={"India"}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={countryValue.value}
                    onChange={selectedCountryChange}
                    options={options}
                    styles={{
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                  <LoginButton
                    type="submit"
                    onClick={formikProps.handleSubmit}
                    disabled={!(formikProps.isValid && formikProps.dirty)}
                    isDisabled={!(formikProps.isValid && formikProps.dirty)}
                  >
                    Submit
                  </LoginButton>
                </>
              );
            }}
          </Formik>
         */}
        </PersonalDetailsDiv>
        {errorMessage != null && (
          <ErrorMessage style={{ marginTop: "10px" }}>
            {errorMessage}
          </ErrorMessage>
        )}
        {/* <SideBarFooter /> */}
      </ConsumerPersonlaDetailsModalBarDiv>
    );
  } else {
    return (
      <ConsumerResultPersonalSideBarDiv personalDetails={personalDetails}>
        {/* <CancelImage
          src={CANCEL_IMAGE}
          onClick={togglePersonalDetailsMenu}
          alt="Cancel"
        /> */}
        <PersonalDetailsDiv>
          <EmailVerificationText>
            <LanguageSelector
              selectedCountry={countryValue.value}
              text={"CONGRATS_EMAIL_VERIFICATION_SUCCESSFUL"}
            />
          </EmailVerificationText>
          <MoreDetailsText>
            <LanguageSelector
              selectedCountry={countryValue.value}
              text={"WE_JUST_NEED"}
            />
          </MoreDetailsText>
          {enablePartTwo === false ? (
            <>
              <div className="form-control">
                <input
                  required
                  type="text"
                  name="input"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <label>
                  <LanguageSelector
                    selectedCountry={countryValue.value}
                    text={"NAME_DETAILS"}
                  />
                </label>
                <div className="border-around"></div>
              </div>

              <div className="form-control">
                <input
                  required
                  type="tel"
                  name="input"
                  value={contact}
                  onChange={(event) => setContact(event.target.value)}
                />
                <label>
                  <LanguageSelector
                    selectedCountry={countryValue.value}
                    text={"CONTACT_DETAILS"}
                  />
                </label>
                <div className="border-around"></div>
              </div>

              <div className="form-control">
                <input
                  required
                  type="text"
                  name="input"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                />
                <label>
                  <LanguageSelector
                    selectedCountry={countryValue.value}
                    text={"ADDRESS_DETAILS"}
                  />
                </label>
                <div className="border-around"></div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  />
                  <label>
                    <LanguageSelector
                      selectedCountry={countryValue.value}
                      text={"CITY_DETAILS"}
                    />
                  </label>
                  <div className="border-around"></div>
                </div>
                <LocateMeButton onClick={getLocation}>
                  <LanguageSelector
                    selectedCountry={countryValue.value}
                    text={"LOCATE_ME"}
                  />
                </LocateMeButton>
              </div>

              <Select
                placeholder={"Select Country"}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={selectedCountry}
                onChange={selectedCountryChange}
                options={options}
                styles={{
                  singleValue: (styles) => ({
                    color: "#545850",
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#545850",
                    width: "280px",
                    marginTop: "20px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#545850",
                    width: "280px",
                  }),
                }}
              />

              {/* <div class="form-check" style={{marginTop:"40px", marginBottom:"20px"}}>
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{width:"20px"}}/>
            <label class="form-check-label" for="flexCheckDefault">
              I have a country code
            </label>
          </div> */}

              {(selectedCountry.value || decodedToken?.country) ===
              "Vietnam" ? (
                <Select
                  placeholder={"Chọn loại khách hàng"}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={setCustomerType}
                  options={customerTypeOptions}
                  styles={{
                    singleValue: (styles) => ({
                      color: "#545850",
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#545850",
                      width: "280px",
                      marginTop: "20px",
                    }),
                    option: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "150%",
                      color: "#545850",
                      width: "280px",
                    }),
                  }}
                ></Select>
              ) : null}

              {customerType.value !== "Wall Owner" ? (
                <>
                  <div className="form-control" style={{ marginTop: "20px" }}>
                    <input
                      required
                      type="text"
                      name="input"
                      value={customerCode}
                      onChange={(event) => setCustomerCode(event.target.value)}
                    />
                    <label>Customer Code</label>
                    <div className="border-around"></div>
                  </div>
                  <LoginButton onClick={nextHandler} isDisabled={false}>
                    <LanguageSelector
                      selectedCountry={countryValue.value}
                      text={"NEXT"}
                    />
                  </LoginButton>
                </>
              ) : (
                <LoginButton
                  type="submit"
                  onClick={submitHandler}
                  isDisabled={false}
                >
                  <LanguageSelector
                    selectedCountry={countryValue.value}
                    text={"SUBMIT_BUTTON"}
                  />
                </LoginButton>
              )}
            </>
          ) : (
            <>
              <MoreDetailsText>
                <LanguageSelector
                  selectedCountry={countryValue.value}
                  text={"INPUT_WALL_OWNER_DETAILS"}
                />
              </MoreDetailsText>
              <div className="form-control">
                <input
                  required
                  type="text"
                  name="input"
                  value={wallOwnerName}
                  onChange={(event) => setWallOwnerName(event.target.value)}
                />
                <label>Tên chủ nhà</label>
                <div className="border-around"></div>
              </div>

              {/* <div className="form-control">
            <input
              required
              type="email"
              name="input"
              value={wallOwnerEmail}
              onChange={(event) => setWallOwnerEmail(event.target.value)}
            />
            <label>Wall Owner Email</label>
            <div className="border-around"></div>
          </div> */}

              <div className="form-control">
                <input
                  required
                  type="tel"
                  name="input"
                  value={wallOwnerContact}
                  onChange={(event) => setWallOwnerContact(event.target.value)}
                />
                <label>Số điện thoại</label>
                <div className="border-around"></div>
              </div>

              <div className="form-control">
                <input
                  required
                  type="text"
                  name="input"
                  value={wallOwnerAddress}
                  onChange={(event) => setWallOwnerAddress(event.target.value)}
                />
                <label>Địa chỉ</label>
                <div className="border-around"></div>
              </div>
              <LoginButton
                type="submit"
                onClick={submitHandler}
                isDisabled={false}
              >
                <LanguageSelector
                  selectedCountry={countryValue.value}
                  text={"SUBMIT_BUTTON"}
                />
              </LoginButton>
            </>
          )}
        </PersonalDetailsDiv>
        {errorMessage != null && (
          <ErrorMessage
            style={{
              display: "flex",
              marginTop: "10px",
              alignSelf: "start",
            }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
        {/* <SideBarFooter /> */}
      </ConsumerResultPersonalSideBarDiv>
    );
  }
};

export default ConsumerResultPersonalDetails;
