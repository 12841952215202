import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import { AnalyticsStatsRow } from "../../styles/AdminDashboardStyles";
import MY_TEAM_IMAGE from "../../images/my_team.svg";
import PENDING_REQUEST_IMAGE from "../../images/pending_request.svg";
import STAR_IMAGE from "../../images/star.svg";
import TOTAL_REQUEST_IMAGE from "../../images/total_request.svg";
import AdminDashboardAnalyticsStats from "./AdminDashboardAnalyticsStats";
import LanguageSelector from "../../language/LanguageSelector";

//
const AdminDashboardAnalyticsStatsContainer = ({ analyticsData }) => {
  console.log(analyticsData,"****************")
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  return (
    <AnalyticsStatsRow>
      <AdminDashboardAnalyticsStats
        image={TOTAL_REQUEST_IMAGE}
        number={
          analyticsData !== null ? analyticsData.overview.total_requests : 0
        }
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TOTAL_REQUESTS"}
        />}
      />
      <AdminDashboardAnalyticsStats
        image={STAR_IMAGE}
        number={analyticsData !== null ? analyticsData.overview.customers : 0}
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMERS"}
        />}
      />
      <AdminDashboardAnalyticsStats
        image={PENDING_REQUEST_IMAGE}
        number={
          analyticsData !== null ? analyticsData.overview.pending_requests : 0
        }
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PENDING_REQUESTS"}
        />}
      />
      <AdminDashboardAnalyticsStats
        image={MY_TEAM_IMAGE}
        number={
          analyticsData !== null ? analyticsData.overview.preview_team : 0
        }
        title={<LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"PREVIEW_TEAM"}
        />}
      />
    </AnalyticsStatsRow>
  );
};

export default AdminDashboardAnalyticsStatsContainer;
