import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  NearByStoresText,
  NearByStoresDiv,
  NearByStoresContainer,
  NearByStoresImage,
  NearByTitleDiv,
  NearByTitle,
  NearBySubTitle,
  SelectDiv,
  SelectCheckBox,
  SelectCheckBoxText,
} from "../../styles/PreviewSupervisorSelectorStyles";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import PAINTER_IMAGE from "../../images/painter_image.png";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorSelectorPainters = ({
  selectedPainters,
  setSelectedPainters,
  painterList,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <>
      <NearByStoresText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEARBY_PAINTERS"}
            /> <LanguageSelector
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
            text={"COMING_SOON"}
          /></NearByStoresText>
      <NearByStoresDiv>
        {painterList.map((item, index) => {
          return (
            <NearByStoresContainer key={`${index}`}>
              <NearByStoresImage alt="Near By" src={PAINTER_IMAGE} />

              <NearByTitleDiv>
                <NearByTitle>Dulux paint store</NearByTitle>
                <NearBySubTitle>
                  AL QuozAL Quoz ndustrial Area 3 - Dubai
                </NearBySubTitle>
                <SelectDiv>
                  <SelectCheckBox
                    alt="CheckBox"

                    src={
                      selectedPainters.includes(item.painter_id)
                        ? GREEN_CHECKBOX_CHECKED_IMAGE
                        : GREEN_CHECKBOX_UNCHECKED_IMAGE
                    }
                    // onClick={() => {
                    //   if (selectedPainters.includes(item.painter_id)) {
                    //     setSelectedPainters(
                    //       selectedPainters.filter(
                    //         (filteredItem) => filteredItem !== item.painter_id
                    //       )
                    //     );
                    //   } else {
                    //     setSelectedPainters((oldArray) => [
                    //       ...oldArray,
                    //       item.painter_id,
                    //     ]);
                    //   }
                    // }}
                  />
                  <SelectCheckBoxText>Select</SelectCheckBoxText>
                </SelectDiv>
              </NearByTitleDiv>
            </NearByStoresContainer>
          );
        })}
      </NearByStoresDiv>
    </>
  );
};

export default PreviewSupervisorSelectorPainters;
