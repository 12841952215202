import React from "react";
import {
  PersonalizedContainer,
  PersonalizedDesignText,
  PersonalizedTextDiv,
  PersonalizedColorRecommendationsText,
  GetStartedButton,
  PersonalizedColumnContainer,
  PersonalizedColumnImage,
} from "../../styles/HomeStyles";
import {
  LEGAL_ROUTE,
  LEGAL_MALYASIA_ROUTE,
  LEGAL_SINGAPORE_ROUTE,
  LEGAL_INDONESIA_ROUTE,
  LEGAL_PAKISTAN_ROUTE,
  LEGAL_SRILANKA_ROUTE,
  LEGAL_THAILAND_ROUTE,
  LEGAL_VIETNAM_ROUTE,
} from "../../utils/Constants";
import PERSONALIZED_IMAGE from "../../images/personalized_design.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PersonalizedHome = ({
  goToConsumerClickHandler,
  width,
  selectedCountry,
  history,
}) => {
  const goToLegalClickHandler = () => {
    history.push(LEGAL_ROUTE, { selectedCountry });
    // console.log(selectedCountry.value)
    // if (selectedCountry.value === "Singapore") {
    //   history.push(LEGAL_SINGAPORE_ROUTE);
    // } else if (selectedCountry.value === "Indonesia") {
    //   console.log("works")
    //   history.push(LEGAL_INDONESIA_ROUTE);
    // } else if (selectedCountry.value === "Malaysia") {
    //   history.push(LEGAL_MALYASIA_ROUTE);
    // } else if (selectedCountry.value === "Thailand") {
    //   history.push(LEGAL_THAILAND_ROUTE);
    // } else if (selectedCountry.value === "SriLanka") {
    //   history.push(LEGAL_SRILANKA_ROUTE);
    // } else if (selectedCountry.value === "Pakistan") {
    //   history.push(LEGAL_PAKISTAN_ROUTE);
    // } else if (selectedCountry.value === "Vietnam") {
    //   history.push(LEGAL_VIETNAM_ROUTE);
    // } else {
    //   history.push(LEGAL_SINGAPORE_ROUTE);
    // }
  };

  if (width < 1100) {
    return (
      <PersonalizedColumnContainer>
        <PersonalizedColumnImage src={PERSONALIZED_IMAGE} />
        <PersonalizedDesignText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"PERSONALISED_DESIGNS_small"}
          />
        </PersonalizedDesignText>
        <PersonalizedColorRecommendationsText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"GET_FREE_AND_PERSONALISED_small"}
          />
        </PersonalizedColorRecommendationsText>
        <GetStartedButton onClick={goToConsumerClickHandler}>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"GET_STARTED"}
          />
        </GetStartedButton>
        <PersonalizedColorRecommendationsText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"PERSONALISED_TERMS_AND_CONDITIONS_ONE_small"}
          />
          <div
            onClick={goToLegalClickHandler}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"PERSONALISED_TERMS_AND_CONDITIONS_TWO_small"}
            />
          </div>
        </PersonalizedColorRecommendationsText>
      </PersonalizedColumnContainer>
    );
  } else {
    return (
      <PersonalizedContainer>
        <PersonalizedTextDiv>
          <PersonalizedDesignText>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"PERSONALISED_DESIGNS"}
            />
          </PersonalizedDesignText>
          <PersonalizedColorRecommendationsText>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"GET_FREE_AND_PERSONALISED"}
            />
          </PersonalizedColorRecommendationsText>
          <GetStartedButton onClick={goToConsumerClickHandler}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"GET_STARTED"}
            />
          </GetStartedButton>
          <PersonalizedColorRecommendationsText
            style={{ display: "inline-flex" }}
          >
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"PERSONALISED_TERMS_AND_CONDITIONS_ONE"}
            />
            <div
              onClick={goToLegalClickHandler}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"PERSONALISED_TERMS_AND_CONDITIONS_TWO"}
              />
            </div>
          </PersonalizedColorRecommendationsText>
        </PersonalizedTextDiv>
        <img
          src={PERSONALIZED_IMAGE}
          alt="Personalised design within 24 hours"
          style={{ width: "50vw", objectFit: "stretch" }}
        />
      </PersonalizedContainer>
    );
  }
};

export default PersonalizedHome;
