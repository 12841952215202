import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import { PREVIEW_SUPERVISOR_COLOUR_PICKER_ROUTE } from "../../utils/Constants";
import {
  PreviewSupervisorColorPickerColumn,
  PreviewSupervisorTouchUpContainer,
  TouchUpText,
  ColourPickerTitle,
  PreviewSupervisorBodyDiv,
  PreviewSupervisorBodyOptionDiv,
  ButtonBar,
  BackButton,
  ResetButton,
  DoneButton,
  HeaderHorizontalRuler,
  TabDiv,
  TabDivButton,
  ButtonDiv,
  InputButton,
  InputLabel,
  ColorPaletteDiv,
  ColorPaletteDivContainer,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import {
  ModalPaleeteDiv,
  ColorText,
  ModalPalette,
  ToolTip,
} from "../../styles/PreviewSupervisorConsumerResultStyles";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import { default as Back } from "../../components/BackButton";
import PreviewSupervisorTouchUpSideDrawer from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpSideDrawer";
import PreviewSupervisorTouchUpHeader from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpHeader";
import PreviewSupervisorTouchUpShades from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpShades";
import PreviewSupervisorTouchUpEditable from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpEditable";
import PreviewSupervisorTouchUpResultImage from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpResultImage";
import PreviewSupervisorTouchUpPolygon from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpPolygon";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorColourPicker = ({ history }) => {
  //location.state.result_id
  //location.state.request_id
  //location.state.imageSource
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [selectedColourFromDrawer, setSelectedColourFromDrawer] =
    useState(null);
  const [reset, setReset] = useState(false);
  const [resultImage, setResultImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [imageToSave, setImageToSave] = useState(null);

  const [color, setColor] = useState("red");
  const [colorCollection, setColorCollection] = useState([]);

  const [clickPoints, setClickPoints] = useState([]);
  const [selectedPath, setSelectedPath] = useState(0);

  const [colorList, setColorList] = useState([]);
  const [changeList, setChangeList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [errorText, setErrorText] = useState(false);

  const [useOptionOne, setUseOptionOne] = useState(false);
  const [useOptionTwo, setUseOptionTwo] = useState(true);
  const [usePalette, setUsePalette] = useState(null);
  const [paletteColorList, setPaletteColorList] = useState([]);

  const [mixBlendMode, setMixBlendMode] = useState("color");
  const [canvasOpacity, setCanvasOpacity] = useState(1);
  const dropDownList = ["color", "hard-light", "overlay", "normal"];
  const [selectedFilterOption, setSelectedFilterOption] = useState([
    dropDownList[0],
  ]);

  const {
    familyColour,
    getRawImage,
    uploadEditedImage,
    searchColour,
    editColourPalette,
  } = useContext(PreviewSupervisorContext);

  const colourList = [
    { name: "Warm Neutral", color: "rgb(191, 178, 143)" },
    { name: "White", color: "rgb(219, 219, 205)" },
    { name: "Red", color: "rgb(210, 61, 57)" },
    { name: "Orange", color: "rgb(229, 146, 58)" },
    { name: "Gold", color: "rgb(247, 206, 72)" },
    { name: "Yellow", color: "rgb(252, 236, 84)" },
    { name: "Lime", color: "rgb(187, 204, 71)" },
    { name: "Green", color: "rgb(88, 151, 75)" },
    { name: "Teal", color: "rgb(89, 173, 159)" },
    { name: "Blue", color: "rgb(79, 117, 159)" },
    { name: "Violet", color: "rgb(111, 83, 129)" },
    { name: "Cool Neutral", color: "rgb(143, 146, 147)" },
  ];

  const toggleSideMenu = () =>
    setOpenSideMenu((previousState) => !previousState);

 
  const toggleReset = () => {
    setReset((previousState) => !previousState);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  const getRawImageSuccessCallback = (image) => {
    setOriginalImage(image);
    setResultImage(image);
  };

  const getRawImageFailureCallback = (msg) => {
    alert(msg);
  };

  const getRawImageHandler = () => {
    getRawImage({
      getRawImageSuccessCallback,
      getRawImageFailureCallback,
    });
  };

  const uploadEditedImageSuccessCallback = () => {
    console.log("done");
  };

  const uploadEditedImageFailureCallback = (msg) => {
    alert(msg);
  };

  const uploadEditedImageHandler = (resultimage) => {
    uploadEditedImage({
      result_image: resultimage,

      uploadEditedImageSuccessCallback,
      uploadEditedImageFailureCallback,
    });
  };

  const editColourPaletteSuccessCallback = () => {
    console.log("done");
  };

  const editColourPaletteFailureCallback = (msg) => {
    alert(msg);
  };

  const editColourPaletteHandler = (resultimage) => {
    editColourPalette({
      colour_code1: paletteColorList[0].colour_code,
      colour_code2: paletteColorList[1].colour_code,
      colour_code3: paletteColorList[2].colour_code,
      colour_code4: paletteColorList[3].colour_code,
      colour_code5: paletteColorList[4].colour_code,
      editColourPaletteSuccessCallback,
      editColourPaletteFailureCallback,
    });
  };


  const colorSwapChangeSuccessCallback = (image) => {
    setResultImage(image);
  };

  const colorSwapChangeFailureCallback = (msg) => {
    alert(msg);
  };

  const searchColourSuccessCallback = (data) => {
    console.log(data);
    setErrorText(false);
    let rgbcolour =
      "rgb(" +
      data.red_value.toString() +
      "," +
      data.green_value.toString() +
      "," +
      data.blue_value.toString() +
      ")";
    setColor(rgbcolour);
    toggleSideMenu();
  };

  const searchColourFailureCallback = (msg) => {
    setErrorText(true);
    // alert(msg);
  };

  const searchColourHandler = () => {
    searchColour({
      colour_name: searchText,
      searchColourSuccessCallback,
      searchColourFailureCallback,
    });
  };

  let contextRef = useRef(null);
  let contextRefResult = useRef(null);

  const myImgRef = new Image();
  myImgRef.src = originalImage;
  const redImgRef = new Image();

  var new_width = 0;
  var new_height = 0;
  var clicks = [];

  const resetHandler = () => {
    toggleReset();
    if (!useOptionOne) {
      contextRef.current.clearRect(0, 0, new_width, new_height);
      setResultImage(originalImage);
      setColorList([]);
      setChangeList([]);
    }
    if (!useOptionTwo) {
      setSelectedPath(0);
      setClickPoints((arr) => []);
      contextRef.current.clearRect(0, 0, new_width, new_height);
      clicks = [];
    }
    getRawImageHandler();
  };

  const doneHandler = () => {
    let paletteTemp = JSON.stringify();
    if (!useOptionOne) {
      uploadEditedImageHandler(resultImage);
    }
    if (!useOptionTwo) {
      uploadEditedImageHandler(imageToSave);
    }
    if (paletteTemp !== JSON.stringify(paletteColorList)) {
      console.log("Palette Changed");
      editColourPaletteHandler();
    }
    setTimeout(function () {}, 1000);
    // const downloadLink = document.createElement("a");
    // downloadLink.href = imageToSave;
    // downloadLink.download = "EditedImage.png";
    // downloadLink.click();
  };

  useEffect(() => {
    if (selectedColourFromDrawer !== null && contextRef !== null) {
      contextRef.current.strokeStyle = `rgb(${selectedColourFromDrawer.red_value},${selectedColourFromDrawer.green_value},${selectedColourFromDrawer.blue_value})`;
    }
    if (changeList.length === 0 && colorList?.length > 0) {
      var onelist = colorList.slice(0);
      setChangeList(onelist);
    }
  }, [selectedColourFromDrawer, colorList]);

  useEffect(() => {
    setPaletteColorList();
  }, []);

  useEffect(() => {
    setPaletteColorList();
  }, [originalImage]);

  const editPalette = (e) => {
    setUsePalette(parseInt(e.target.id));
    console.log(parseInt(e.target.id));
    toggleSideMenu();
  };

  return (
    <>
      <PreviewSupervisorTouchUpSideDrawer
        openSideMenu={openSideMenu}
        toggleSideMenu={toggleSideMenu}
        colourList={colourList}
        familyColour={familyColour}
        selectedColourFromDrawer={selectedColourFromDrawer}
        setSelectedColourFromDrawer={setSelectedColourFromDrawer}
        setColor={setColor}
        colorCollection={colorCollection}
        setColorCollection={setColorCollection}
        selectedPath={selectedPath}
        setSearchText={setSearchText}
        searchText={searchText}
        errorText={errorText}
        searchColourHandler={searchColourHandler}
        color={color}
        usePalette={usePalette}
        setUsePalette={setUsePalette}
        paletteColorList={paletteColorList}
        setPaletteColorList={setPaletteColorList}
      />

      <PreviewSupervisorTouchUpContainer applyFilter={openSideMenu}>
        <PreviewSupervisorTouchUpHeader />
        <HeaderHorizontalRuler />
        <div style={{ margin: "20px 4vw 0 4vw" }}>
          <Back goBackHandler={goBackHandler} />
          <TouchUpText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TOUCH_UP"}
            /></TouchUpText>
        </div>

        <PreviewSupervisorBodyDiv>
          <PreviewSupervisorColorPickerColumn>
            <div style={{ margin: "-50px 4vw 0 3vw" }}>
              <ColourPickerTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EDIT_COLOUR_PALETTE"}
            /></ColourPickerTitle>

              <p style={{ fontSize: 15 }}>
              <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"MAKE_FINAL_CHANGES"}
            />
              </p>
            </div>
            <ModalPalette style={{ marginTop: "15px" }}>
              {paletteColorList &&
                paletteColorList.map((item2, index2) => {
                  return (
                    <div key={`1`}>
                      <ModalPaleeteDiv
                        id={`1`}
                        style={{
                          backgroundColor: `rgb(220,20,60)`,
                        }}
                        onClick={editPalette}
                      >
                        <ToolTip>{item2.colour_name}</ToolTip>
                      </ModalPaleeteDiv>
                      <ColorText>{item2?.colour_code}</ColorText>
                    </div>
                  );
                })}
            </ModalPalette>

            <div style={{ margin: "170px 4vw 0 3vw" }}>
              <ColourPickerTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"MAKE_FINAL_CHANGES"}
            /></ColourPickerTitle>
              <ModalPalette style={{ marginTop: "15px" }}>
                {paletteColorList &&
                  paletteColorList.map((item2, index2) => {
                    return (
                      <div key={`1`}>
                        <ModalPaleeteDiv
                          id={`1`}
                          style={{
                            backgroundColor: `rgb(220,20,60)`,
                          }}
                          onClick={editPalette}
                        >
                          <ToolTip>{item2.colour_name}</ToolTip>
                        </ModalPaleeteDiv>
                        <ColorText>{item2?.colour_code}</ColorText>
                      </div>
                    );
                  })}
              </ModalPalette>
            </div>
          </PreviewSupervisorColorPickerColumn>
          <PreviewSupervisorColorPickerColumn>
            <ColourPickerTitle>
              <p style={{ fontSize: 20 }}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EDITED_IMAGE"}
            /></p>
              <img
                src="https://www.interiorleo.com/wp-content/uploads/2020/07/room-color-orang-e1601360278420.jpg"
                alt="Editted Image"
              />
            </ColourPickerTitle>
          </PreviewSupervisorColorPickerColumn>
        </PreviewSupervisorBodyDiv>
        <ButtonBar
          style={{
            marginRight: "150px",
          }}
        >
          <div style={{ margin: "-100px 4vw 0 -30vw" }}>
            <BackButton onClick={goBackHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
            <ResetButton onClick={resetHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESET"}
            /></ResetButton>
            <DoneButton onClick={doneHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
          </div>
        </ButtonBar>
      </PreviewSupervisorTouchUpContainer>
    </>
  );
};

export default PreviewSupervisorColourPicker;
