import React from "react";
import {
  AdminDashboardRequestContainer,
  AdminDashboardRequestText,
  AdminDashboardRequestNumberContainer,
  AdminDashboardRequestNumberText,
  AdminDashboardRequestImage,
} from "../../styles/AdminDashboardStyles";

const AdminDashboardStats = ({ image, number, title }) => {
  return (
    <AdminDashboardRequestContainer>
      <AdminDashboardRequestNumberContainer>
        <AdminDashboardRequestImage src={image} alt="Request" />
        <AdminDashboardRequestNumberText>
          {number}
        </AdminDashboardRequestNumberText>
      </AdminDashboardRequestNumberContainer>
      <AdminDashboardRequestText>{title}</AdminDashboardRequestText>
    </AdminDashboardRequestContainer>
  );
};

export default AdminDashboardStats;
