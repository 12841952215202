import React from "react";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import {
  LegacyContainer,
  LegacyHeader,
  DeluxePreviewServiceImage,
} from "../../styles/LegalStyles";

const LegalHeader = () => {
  return (
    <LegacyContainer>
      <LegacyHeader>
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage src={DELUXE_PREVIEW_SERVICE_IMAGE} />
        </Link>
      </LegacyHeader>
    </LegacyContainer>
  );
};

export default LegalHeader;
