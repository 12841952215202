import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  PreviewSupervisorColorPickerColumn,
  ColourPickerTitle,
  PreviewSupervisorTouchUpContainer,
  TouchUpText,
  PreviewSupervisorBodyDiv,
  PreviewSupervisorBodyOptionDiv,
  ButtonBar,
  BackButton,
  ResetButton,
  DoneButton,
  HeaderHorizontalRuler,
  TabDiv,
  TabDivButton,
  ButtonDiv,
  InputButton,
  InputLabel,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import {
  ModalPaleeteDiv,
  ColorText,
  ModalPalette,
  ToolTip,
  ModalPaletteDivAutoColor
} from "../../styles/PreviewSupervisorConsumerResultStyles";
import { Context as PreviewSupervisorContext } from "../../context/PreviewSupervisorContext";
import { default as Back } from "../../components/BackButton";
import PreviewSupervisorTouchUpSideDrawer from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpSideDrawer";
import PreviewSupervisorTouchUpHeader from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpHeader";
import PreviewSupervisorTouchUpShades from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpShades";
import PreviewSupervisorTouchUpEditable from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpEditable";
import PreviewSupervisorTouchUpResultImage from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpResultImage";
import PreviewSupervisorTouchUpPolygon from "../../components/preview-supervisor-touch-up/PreviewSupervisorTouchUpPolygon";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorTouchUp = ({ history, location }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  console.log("RENDER TOUCHUP");
  //location.state.result_id
  //location.state.request_id
  //location.state.imageSource

  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [selectedColourFromDrawer, setSelectedColourFromDrawer] =
    useState(null);
  const [isDrwaing, setIsDrawing] = useState(false);
  const [reset, setReset] = useState(false);
  const [resultImage, setResultImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [imageToSave, setImageToSave] = useState(null);
  const [colorSwapImage, setColorSwapImage] = useState(null);
  const [imageSize, setImageSize] = useState([0, 0]);
  const [useColorPicker, setUseColorPicker] = useState(false);

  const [color, setColor] = useState("rgb(255, 0, 0)");
  const [colorCollection, setColorCollection] = useState([]);
  const [pathCollection, setPathCollection] = useState([]);
  const [opacityCollection, setOpacityCollection] = useState([]);

  const [starPoint, setStartPoint] = useState([]);
  const [clickPoints, setClickPoints] = useState([]);
  const [pathIdCollection, setPathIdCollection] = useState([]);
  const [selectedPath, setSelectedPath] = useState(0);
  const [orgImgSize, setOrgImgSize] = useState([0, 0]);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedColorImage, setSelectedColorImage] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [changeList, setChangeList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [errorText, setErrorText] = useState(false);

  const [useOptionOne, setUseOptionOne] = useState(true);
  const [useOptionTwo, setUseOptionTwo] = useState(false);
  const [usePalette, setUsePalette] = useState(null);
  const [paletteColorList, setPaletteColorList] = useState([]);
  const [tempImageToSave, setTempImageToSave] = useState([]);
  const [pointScale, setPointScale] = useState(1);
  const [currentPoints, setCurrentPoints] = useState([]);

  const [mixBlendMode, setBlendMode] = useState(["color"]);
  const [canvasOpacity, setCanvasOpacity] = useState(1);
  const dropDownList = ["color", "hard-light", "overlay", "normal"];
  const [selectedFilterOption, setSelectedFilterOption] = useState([
    dropDownList[0],
  ]);

  const [useAutoColorPicker, setUseAutoColorPicker] = useState(false);
  const [colorStackDetails, setColorStackDetails] = useState([]);
  const [colorStack, setColorStack] = useState([]);

  const [keyPressed, setKeyPressed] = useState(false);
  window.onkeyup = function(e) {
    if(e.key == "a"){
      setKeyPressed(false)
    }
  }
  window.onkeydown = function(e) {
    if(e.key == "a"){
      setKeyPressed(true)
    }
  }

  const {
    familyColour,
    getRawImage,
    uploadEditedImage,
    /*   colorSwapExtract,
    colorSwapChange, */
    searchColour,
    editColourPalette,
    autoColorPicker,
  } = useContext(PreviewSupervisorContext);

  const colourList = [
    { name: "Warm Neutral", color: "rgb(191, 178, 143)" },
    { name: "White", color: "rgb(219, 219, 205)" },
    { name: "Red", color: "rgb(210, 61, 57)" },
    { name: "Orange", color: "rgb(229, 146, 58)" },
    { name: "Gold", color: "rgb(247, 206, 72)" },
    { name: "Yellow", color: "rgb(252, 236, 84)" },
    { name: "Lime", color: "rgb(187, 204, 71)" },
    { name: "Green", color: "rgb(88, 151, 75)" },
    { name: "Teal", color: "rgb(89, 173, 159)" },
    { name: "Blue", color: "rgb(79, 117, 159)" },
    { name: "Violet", color: "rgb(111, 83, 129)" },
    { name: "Cool Neutral", color: "rgb(143, 146, 147)" },
  ];

  const selectionPage = location.state?.selectedPaginationNumber;
  const colours =
    location.state?.optionList[selectionPage]?.colours ||
    location.state?.result?.results[selectionPage]?.colours;

  const toggleSideMenu = () =>
    setOpenSideMenu((previousState) => !previousState);

  const toggleOptionOne = () => {
    setUseOptionOne((previousState) => !previousState);
  };

  const toggleOptionTwo = () => {
    setUseOptionTwo((previousState) => !previousState);
  };

  const toggleColorPicker = () => {
    setUseColorPicker((previousState) => !previousState);
  };

  const selectOptionOne = (e) => {
    setUseOptionOne(false);
    setUseOptionTwo(true);
    e.target.classList.add("active");
  };

  const selectOptionTwo = (e) => {
    setUseOptionOne(true);
    setUseOptionTwo(false);
  };

  const toggleReset = () => {
    setReset((previousState) => !previousState);
  };

  const goBackHandler = () => {
    if (useAutoColorPicker === true) {
      setUseAutoColorPicker(false);
    } else {
      history.goBack();
    }
  };

  const addColorstack = (data) => {
    setColorStack((arr) => [...arr, data]);
  };

  const addClickPoints = (x, y) => {
    var points = [x, y];
    setClickPoints((arr) => [...arr, points]);
  };

  const setMixBlendMode = (value) => {
    var tempMixBlendMode = mixBlendMode;
    tempMixBlendMode[selectedPath] = value;
    setBlendMode(tempMixBlendMode);
  };

  const addPathId = (idToAdd) => {
    setPathIdCollection((arr) => [...arr, idToAdd]);
    return idToAdd;
  };

  const addColorAndPath = (newColor, pointSet, opacitySet) => {
    console.log(newColor,"new colour********")
    if (selectedColourFromDrawer === null) {
      addColorstack({
        red_value: newColor.split(",")[0].split("(")[1],
        green_value: newColor.split(",")[1],
        blue_value: newColor.split(",")[2].split(")")[0],
        colour_code: " ",
        colour_family: " ",
        colour_name: "NA",
        colour_sensation: " ",
      });
    } else {
      setColorStack((arr) => [...arr, selectedColourFromDrawer]);
    }
    setColorCollection((arr) => [...arr, newColor]);
    setPathCollection((arr) => [...arr, pointSet]);
    setOpacityCollection((arr) => [...arr, opacitySet]);
    setClickPoints((arr) => []);
    setBlendMode((arr) => [...arr, "color"]);
  };

  const setNewColor = (setcolor, id) => {
    var array = colorCollection;
    array[id] = setcolor;
    setColor((color) => setcolor);
    setColorCollection((arr) => array);
  };

  const getRawImageSuccessCallback = (image) => {
    setOriginalImage(image);
    setResultImage(image);
    setImageToSave(image);
  };

  const getRawImageFailureCallback = (msg) => {
    alert(msg);
  };

  const getRawImageHandler = () => {
    getRawImage({
      result_id: location.state.result_id,
      getRawImageSuccessCallback,
      getRawImageFailureCallback,
    });
  };

  const autoColorPickerSuccessCallback = (data) => {
    let tempColorCollection = [];
    let tempColorFillValue = 5 - data.rgb_values.length;
    for (let i = 0; i < data.rgb_values.length; i++) {
      tempColorCollection.push({
        red_value: data.rgb_values[i][0],
        green_value: data.rgb_values[i][1],
        blue_value: data.rgb_values[i][2],
        colour_code: data.dulux_color[i].dulux_color_code,
        colour_family: " ",
        colour_name: data.dulux_color[i].dulux_color_name,
        colour_sensation: " ",
      });
    }
    for (let i = 0; i < tempColorFillValue; i++) {
      tempColorCollection.push({
        red_value: 255,
        green_value: 255,
        blue_value: 255,
        colour_code: "1951",
        colour_family: " ",
        colour_name: "White",
        colour_sensation: " ",
      });
    }
    setPaletteColorList(tempColorCollection);
  };

  const autoColorPickerFailureCallback = (msg) => {
    console.log(msg);
  };

  const uploadEditedImageSuccessCallback = () => {
    console.log("done");
  };

  const uploadEditedImageFailureCallback = (msg) => {
    alert(msg);
  };

  const uploadEditedImageHandler = (resultimage) => {
    uploadEditedImage({
      result_image: resultimage,
      result_id: location.state.result_id,
      request_id: location.state.request_id,
      uploadEditedImageSuccessCallback,
      uploadEditedImageFailureCallback,
    });
  };

  const editColourPaletteSuccessCallback = () => {
    console.log("done");
  };

  const editColourPaletteFailureCallback = (msg) => {
    alert(msg);
  };

  const editColourPaletteHandler = (resultimage) => {
    editColourPalette({
      result_id: location.state.result_id,
      colour_code1: paletteColorList[0].colour_code,
      colour_code2: paletteColorList[1].colour_code,
      colour_code3: paletteColorList[2].colour_code,
      colour_code4: paletteColorList[3].colour_code,
      colour_code5: paletteColorList[4].colour_code,
      editColourPaletteSuccessCallback,
      editColourPaletteFailureCallback,
    });
  };

  /* const colorSwapExtractSuccessCallback = (colours) => {
    setColorList(colours.slice(0));
    setChangeList(colours.slice(0));
  };

  const colorSwapExtractFailureCallback = (msg) => {
    alert(msg);
  };

  const colorSwapChangeHandler = () => {
    colorSwapChange({
      image: colorSwapImage.split(",")[1],
      imageType: "png",
      height: orgImgSize[1],
      width: orgImgSize[0],
      colorFrom: colorList,
      colorTo: changeList,
      colorSwapChangeSuccessCallback,
      colorSwapChangeFailureCallback,
    });
  }; */

  /* const colorSwapChangeSuccessCallback = (image) => {
    setResultImage(image);
  };

  const colorSwapChangeFailureCallback = (msg) => {
    alert(msg);
  }; */

  const searchColourSuccessCallback = (data) => {
    if(!useAutoColorPicker){
          console.log(data)
    setErrorText(false);
    let rgbcolour =
      "rgb(" +
      data.red_value.toString() +
      "," +
      data.green_value.toString() +
      "," +
      data.blue_value.toString() +
      ")";
    setColor(rgbcolour);
    toggleSideMenu();
    }else{
      console.log(data)
      let tempColorCollection = []
      console.log("success")
      setErrorText(false);
      tempColorCollection = paletteColorList
      console.log(paletteColorList)
      tempColorCollection[usePalette] = data
      setPaletteColorList(tempColorCollection)
      toggleSideMenu();
    }
  };

  const searchColourFailureCallback = (msg) => {
    setErrorText(true);
    // alert(msg);
  };

  const searchColourHandler = () => {
    searchColour({
      colour_name: searchText,
      searchColourSuccessCallback,
      searchColourFailureCallback,
    });
  };

  let contextRef = useRef(null);
  let contextRefResult = useRef(null);

  const myImgRef = new Image();
  myImgRef.src = originalImage;
  const redImgRef = new Image();
  const bufferRef = new Image();
  bufferRef.src = originalImage;
  const canvasRef = useRef(null);

  var new_width = 0;
  var new_height = 0;
  var clicks = [];

  const loadImage = (maxlen, width, height) => {
    new_width = width;
    new_height = height;
    if (width > maxlen) {
      new_width = maxlen;
      new_height = (height * maxlen) / width;
    } else if (height > maxlen) {
      new_width = (width * maxlen) / height;
      new_height = maxlen;
    }
    setImageSize([new_width, new_height]);
    setOrgImgSize([width, height]);
  };

  const canvasRefColor = useCallback(
    (node) => {
      console.log("triggered")
      if (node != null) {
        const context = node.getContext("2d");
        myImgRef.onload = function () {
          loadImage(500, this.width, this.height);
          node.width = new_width;
          node.height = new_height;
          node.style.width = new_width;
          node.style.height = new_height;
          context.drawImage(myImgRef, 0, 0, new_width, new_height);
          //createColorList();
          var test = colorList;
          // if(colorList.length === 0){
          var pngImg = node.toDataURL("image/png");
          setColorSwapImage(pngImg);
          /* colorSwapExtract({
            image: pngImg.split(",")[1],
            imageType: "png",
            numK: 5,
            height: this.height,
            width: this.width,
            colorSwapExtractSuccessCallback,
            colorSwapExtractFailureCallback,
          }); */
          // }
        };
        myImgRef.src = originalImage;
        if (originalImage == null) {
          getRawImageHandler();
        }
        //myImgRef.src = location.state.imageSource;
        context.lineWidth = 2;
        context.lineCap = "round";
        context.strokeStyle = "black";
        contextRef.current = context;
        setClickPoints([]);
        setPathCollection([]);
        setOpacityCollection([]);
        setColorCollection([]);
        setPathIdCollection([]);
        setStartPoint([]);
      }
    },
    [useOptionOne, reset, originalImage]
  );

  const canvasRefPolyResult = useCallback(
    (node) => {
      if (node != null) {
        const context = node.getContext("2d");
        redImgRef.onload = function () {
          node.width = new_width;
          node.height = new_height;
          node.style.width = new_width;
          node.style.height = new_height;
          context.drawImage(redImgRef, 0, 0, new_width, new_height);
        };

        redImgRef.src = originalImage;
        context.drawImage(redImgRef, 0, 0, new_width, new_height);
        context.lineWidth = 2;
        context.lineCap = "round";
        context.strokeStyle = "black";
        contextRefResult.current = context;
      }
    },
    [originalImage]
  );

  const resetHandler = () => {
    toggleReset();
    if (!useOptionOne) {
      contextRef.current.clearRect(0, 0, new_width, new_height);
      setResultImage(originalImage);
      setColorList([]);
      setChangeList([]);
    }
    if (!useOptionTwo) {
      setSelectedPath(0);
      console.log("reset triggered")
      setClickPoints((arr) => []);
      contextRef.current.clearRect(0, 0, new_width, new_height);
      clicks = [];
      setCurrentPoints([])
      setPathCollection([]);
      setImageToSave(originalImage);
    }
    setColorStack([]);
    getRawImageHandler();
  };

  const doneHandler = () => {
    let paletteTemp = JSON.stringify(
      location.state?.optionList[selectionPage].colours.slice(0)
    );
    if (!useOptionOne) {
      uploadEditedImageHandler(resultImage);
    }
    if (!useOptionTwo) {
      uploadEditedImageHandler(imageToSave);
    }
    if (paletteTemp !== JSON.stringify(paletteColorList)) {
      editColourPaletteHandler();
    }
    setTimeout(function () {
      history.goBack();
    }, 1000);
    // const downloadLink = document.createElement("a");
    // downloadLink.href = imageToSave;
    // downloadLink.download = "EditedImage.png";
    // downloadLink.click();
  };

  const nextHandler = () => {
    console.log(resultImage)
    console.log(imageToSave)
    console.log(canvasRef.current.toDataURL("image/jpeg"));
    if (pathCollection.length > 0) {
      setImageToSave(canvasRef.current.toDataURL("image/jpeg"));
      autoColorPicker({
        image: canvasRef.current.toDataURL("image/jpeg"),
        autoColorPickerSuccessCallback,
        autoColorPickerFailureCallback,
      });
    } else {
      setImageToSave(resultImage);
      autoColorPicker({
        image: resultImage,
        autoColorPickerSuccessCallback,
        autoColorPickerFailureCallback,
      });
    }
    setPathCollection([]);
    setUseAutoColorPicker(true);
    // if(imageToSave){
    //   autoColorPicker({
    //     image: imageToSave,
    //     autoColorPickerSuccessCallback,
    //     autoColorPickerFailureCallback,
    //   });
    // }
    // else{
    //   autoColorPicker({
    //     image: resultImage,
    //     autoColorPickerSuccessCallback,
    //     autoColorPickerFailureCallback,
    //   });
    // }
  };

  const startDrawingHandler = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    if(keyPressed){
    if (!useOptionOne) {
      var pointrgb = Array.from(
        contextRef.current.getImageData(offsetX, offsetY, 1, 1).data
      );
      pointrgb.pop();
      setSelectedColorImage(pointrgb);
    }
    if (!useOptionTwo) {
      if (useColorPicker === true) {
        var pointrgb = Array.from(
          contextRef.current.getImageData(offsetX, offsetY, 1, 1).data
        );
        pointrgb.pop();
        setColor(`rgb(
          ${pointrgb[0]},
          ${pointrgb[1]},
          ${pointrgb[2]}
        )`);
        var colorCollectionTemp = colorCollection;
        colorCollectionTemp[selectedPath] = `rgb(
          ${pointrgb[0]},
          ${pointrgb[1]},
          ${pointrgb[2]}
        )`;
        setColorCollection(colorCollectionTemp);
        setUseColorPicker(false);
      } else {
        if (clickPoints.length < 1) {
          setStartPoint((arr) => [offsetX, offsetY]);
        }
        addClickPoints(offsetX, offsetY);
        clicks.push({
          x: offsetX,
          y: offsetY,
        });
        setTimeout(function () {}, 3000);
        redrawpoly(offsetX, offsetY);
      }
      if (starPoint.length > 0) {
        if (checkRange(offsetX, offsetY)) {
          addColorAndPath(color, clickPoints, 1);
          setStartPoint([]);
          if (colorCollection.length > 0) {
            setSelectedPath(colorCollection.length);
          }
        }
      }
    }
  }
  };

  const finishDrawingHandler = (event) => {
    if (!useOptionOne) {
      contextRef.current.closePath();
      setIsDrawing(false);
      setTimeout(function () {}, 3000);
    }
    if (!useOptionTwo) {
      setTimeout(function () {}, 3000);
    }
  };

  const drawHandler = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    if (!useOptionTwo) {
      if (starPoint.length > 0) {
        if (checkRange(offsetX, offsetY)) {
          document.body.style.cursor = "pointer";
        } else {
          document.body.style.cursor = "default";
        }
      } else {
        document.body.style.cursor = "default";
      }
    }
  };

  const redrawpoly = (offsetX, offsetY) => {
    drawPolygon(offsetX, offsetY);
    drawPolyPoints(offsetX, offsetY);
  };

  useEffect(() => {
    //redraw canvas
    if (myImgRef.getAttribute('src') !== "null") {
      console.log(clickPoints)
      console.log(myImgRef)
      console.log(contextRef.current)
      contextRef.current.drawImage(myImgRef, 0, 0, imageSize[0], imageSize[1]);
    }
    console.log(pointScale)

    redrawPolygon()
    redrawPolyPoints()
    console.log(clickPoints)
    console.log(pathCollection)
  }, [pointScale]);

  const drawPolygon = (offsetX, offsetY) => {
    contextRef.current.strokeStyle = "#df4b26";
    contextRef.current.lineWidth = 1/pointScale;
    contextRef.current.beginPath();

    if (clickPoints.length > 0) {
      contextRef.current.moveTo(
        clickPoints[clickPoints.length - 1][0],
        clickPoints[clickPoints.length - 1][1]
      );
      console.log(clickPoints[clickPoints.length - 1][0],
        clickPoints[clickPoints.length - 1][1])
      console.log(offsetX, offsetY)
      contextRef.current.lineTo(offsetX, offsetY);
    }
    contextRef.current.closePath();
    contextRef.current.stroke();
  };
  const drawPolyPoints = (offsetX, offsetY) => {
    contextRef.current.strokeStyle = "#df4b26";
    contextRef.current.lineJoin = "round";
    if(offsetX && offsetY) {
      contextRef.current.beginPath();
      console.log(pointScale)
      if (clickPoints.length == 0) {
        contextRef.current.lineWidth = 6/pointScale;
      } else {
        contextRef.current.lineWidth = 3/pointScale;
      }
      contextRef.current.arc(
        offsetX, offsetY,
        3/pointScale,
        0,
        (2/pointScale) * Math.PI,
        false
      );
      contextRef.current.fillStyle = "#ffffff";
      contextRef.current.fill();
      contextRef.current.stroke();
      contextRef.current.closePath();
    }
  };

  const redrawPolygon = () => {
    contextRef.current.strokeStyle = "#df4b26";
    contextRef.current.lineWidth = 1/pointScale;
    //Draw all old lines
    console.log("REDRAWING LINES")
    for(let i=0; i<pathCollection.length; i++){
      for(let j=0; j<pathCollection[i].length-1; j++){
        console.log("REDRAWING INDV LINES")
        contextRef.current.beginPath();
        contextRef.current.moveTo(
            pathCollection[i][j][0],
            pathCollection[i][j][1]
          );
        contextRef.current.lineTo(
            pathCollection[i][j+1][0],
            pathCollection[i][j+1][1]
          );
        contextRef.current.closePath();
        contextRef.current.stroke();

      }
      console.log("REDRAWING LAST LINE")
        contextRef.current.beginPath();
        contextRef.current.moveTo(
            pathCollection[i][pathCollection[i].length-1][0],
            pathCollection[i][pathCollection[i].length-1][1]
          );
        contextRef.current.lineTo(
            pathCollection[i][0][0],
            pathCollection[i][0][1]
          );
        contextRef.current.closePath();
        contextRef.current.stroke();
    }
    //Draw all current lines 
    for(let j=0; j<clickPoints.length-1; j++){
      console.log("REDRAWING INDV LINES")
      contextRef.current.beginPath();
      contextRef.current.moveTo(
          clickPoints[j][0],
          clickPoints[j][1]
        );
      contextRef.current.lineTo(
          clickPoints[j+1][0],
          clickPoints[j+1][1]
        );
      contextRef.current.closePath();
      contextRef.current.stroke();

    }
    console.log(clickPoints)
  };


  const redrawPolyPoints = (offsetX, offsetY) => {
    contextRef.current.strokeStyle = "#df4b26";
    contextRef.current.lineJoin = "round";

    //Draw all old points 
    for(let i=0; i<pathCollection.length; i++){
      for(let j=0; j<pathCollection[i].length-1; j++){
        console.log("REDRAWING POINTS")
        contextRef.current.beginPath();
        if (j == 0) {
          contextRef.current.lineWidth = 6/pointScale;
        } else {
          contextRef.current.lineWidth = 3/pointScale;
        }
        contextRef.current.arc(
          pathCollection[i][j][0],
          pathCollection[i][j][1],
          3/pointScale,
          0,
          2/pointScale * Math.PI,
          false
        );
        contextRef.current.fillStyle = "#ffffff";
        contextRef.current.fill();
        contextRef.current.stroke();
        contextRef.current.closePath();

      }
    }
    //Draw all current points 
    for(let j=0; j<clickPoints.length-1; j++){
      console.log("REDRAWING POINTS")
      contextRef.current.beginPath();
      if (j == 0) {
        contextRef.current.lineWidth = 6/pointScale;
      } else {
        contextRef.current.lineWidth = 3/pointScale;
      }
      contextRef.current.arc(
        clickPoints[j][0],
        clickPoints[j][1],
        3/pointScale,
        0,
        2/pointScale * Math.PI,
        false
      );
      contextRef.current.fillStyle = "#ffffff";
      contextRef.current.fill();
      contextRef.current.stroke();
      contextRef.current.closePath()
    }
    console.log(clickPoints)
  };

  const drawPolyFill = () => {
    contextRef.current.beginPath();
    contextRef.current.moveTo(starPoint[0], starPoint[1]);
    if (clickPoints.length > 0) {
      for (var i = 0; i < clickPoints.length; i++) {
        contextRef.current.lineTo(clickPoints[i][0], clickPoints[i][1]);
      }
      contextRef.current.lineTo(starPoint[0], starPoint[1]);
    }
    contextRef.current.closePath();
    contextRef.current.fillStyle = "rgba(100,100,100,0.5)";
    contextRef.current.fill();
  };

  const checkRange = (x, y) => {
    if (x < starPoint[0] + 4 && x > starPoint[0] - 4) {
      if (y < starPoint[1] + 4 && y > starPoint[1] - 4) {
        return true;
      }
    } else return false;
  };

  useEffect(() => {
    selectOptionTwo();
  }, []);

  useEffect(() => {
    if (selectedColourFromDrawer !== null && contextRef !== null) {
      contextRef.current.strokeStyle = `rgb(${selectedColourFromDrawer.red_value},${selectedColourFromDrawer.green_value},${selectedColourFromDrawer.blue_value})`;
    }
    if (changeList.length === 0 && colorList?.length > 0) {
      var onelist = colorList.slice(0);
      setChangeList(onelist);
    }
  }, [selectedColourFromDrawer, colorList]);

  useEffect(() => {
    setPaletteColorList(
      location.state?.optionList[selectionPage].colours.slice(0)
    );
  }, []);

  useEffect(() => {
    setPaletteColorList(
      location.state?.optionList[selectionPage].colours.slice(0)
    );
  }, [originalImage]);

  const editPalette = (e) => {
    setUsePalette(parseInt(e.target.id));
    toggleSideMenu();
  };

  const resetPaletteHandler = () => {
    setPaletteColorList(
      location.state?.optionList[selectionPage].colours.slice(0)
    );
  };

  const showFile = async (e) => {
    let fileTypes = ["jpg;", "jpeg", "png;"];
    let image = "";
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      if (fileTypes.includes(e.target.result.substring(11, 15))) {
        image = e.target.result;
        setOriginalImage(image);
        setResultImage(image);
      } else {
        alert("Please upload png or jpeg images.");
      }
      // setAnnotationData(myObject)
      // setAnnotationData(myObject.shapes)
      // setAnnotationImage({image: myObject.imageData, width: myObject.imageWidth, height: myObject.imageHeight})
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <PreviewSupervisorTouchUpSideDrawer
        openSideMenu={openSideMenu}
        toggleSideMenu={toggleSideMenu}
        colourList={colourList}
        familyColour={familyColour}
        selectedColourFromDrawer={selectedColourFromDrawer}
        setSelectedColourFromDrawer={setSelectedColourFromDrawer}
        setColor={setColor}
        colorCollection={colorCollection}
        setColorCollection={setColorCollection}
        location={location}
        selectedPath={selectedPath}
        setSearchText={setSearchText}
        searchText={searchText}
        errorText={errorText}
        searchColourHandler={searchColourHandler}
        color={color}
        usePalette={usePalette}
        setUsePalette={setUsePalette}
        paletteColorList={paletteColorList}
        setPaletteColorList={setPaletteColorList}
        setColorStackDetails={setColorStackDetails}
      />
      {useAutoColorPicker ? (
        <PreviewSupervisorTouchUpContainer applyFilter={openSideMenu}>
          <PreviewSupervisorTouchUpHeader />
          <HeaderHorizontalRuler />
          <div style={{ margin: "20px 4vw 0 4vw" }}>
            <Back goBackHandler={goBackHandler} />
            <TouchUpText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TOUCH_UP"}
            /></TouchUpText>
          </div>

          <PreviewSupervisorBodyDiv>
            <PreviewSupervisorColorPickerColumn>
              <div style={{ margin: "-50px 4vw 50px 3vw", marginTop: "15px" }}>
                <ColourPickerTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EDIT_COLOUR_PALETTE"}
            /></ColourPickerTitle>

                <p style={{ fontSize: 15 }}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"MAKE_FINAL_CHANGES"}
            />
            </p>
                <ModalPalette style={{ marginTop: "15px" }}>
                  {paletteColorList &&
                    paletteColorList.map((item2, index2) => {
                      return (
                        <div key={`${index2}`}>
                          <ModalPaletteDivAutoColor
                            id={`${index2}`}
                            style={{
                              backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                            }}
                            onClick={editPalette}
                          >
                            <ToolTip>{item2.colour_name}</ToolTip>
                          </ModalPaletteDivAutoColor>
                          <ColorText>{item2?.colour_code}</ColorText>
                        </div>
                      );
                    })}
                </ModalPalette>
              </div>
              <div style={{ paddingRight: "40px", paddingLeft: "40px" }}>
                <HeaderHorizontalRuler />
              </div>

              <div style={{ margin: "40px 4vw 0 3vw" }}>
                <ColourPickerTitle>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COLOURS_USED_TO_EDIT"}
            />
                </ColourPickerTitle>
                <ModalPalette style={{ marginTop: "15px" }}>
                  {colorStack &&
                    colorStack.map((item3, index3) => {
                      return (
                        <div key={`${index3}`}>
                          <ModalPaleeteDiv
                            id={`${index3}`}
                            style={{
                              backgroundColor: `rgb(${item3.red_value},${item3.green_value},${item3.blue_value})`,
                            }}
                          >
                            <ToolTip>{item3.colour_name}</ToolTip>
                          </ModalPaleeteDiv>
                          <ColorText>{item3?.colour_code}</ColorText>
                        </div>
                      );
                    })}
                </ModalPalette>
              </div>
            </PreviewSupervisorColorPickerColumn>
            <PreviewSupervisorColorPickerColumn>
              <ColourPickerTitle>
                <p style={{ fontSize: 20 }}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EDITED_IMAGE"}
            /></p>
                <img
                  src={imageToSave || resultImage}
                  alt="Editted Image"
                  width="70%"
                  height="70%"
                />
              </ColourPickerTitle>
            </PreviewSupervisorColorPickerColumn>
          </PreviewSupervisorBodyDiv>
          <ButtonBar
            style={{
              marginRight: "150px",
            }}
          >
            <div style={{ margin: "-100px 4vw 0 -30vw" }}>
              <BackButton onClick={goBackHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
              <BackButton onClick={resetPaletteHandler}> <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESET"}
            /> </BackButton>
              <DoneButton onClick={doneHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
            </div>
          </ButtonBar>
        </PreviewSupervisorTouchUpContainer>
      ) : (
        <PreviewSupervisorTouchUpContainer applyFilter={openSideMenu}>
          <PreviewSupervisorTouchUpHeader />
          <HeaderHorizontalRuler />
          <div style={{ margin: "20px 4vw 0 4vw" }}>
            <Back goBackHandler={goBackHandler} />
            <TouchUpText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TOUCH_UP"}
            /></TouchUpText>
          </div>
          {/* <PreviewSupervisorBodyDiv>
          <div style={{ flexDirection: "column" }}>
            <TouchUpText style={{ fontFamily: "sans-serif" }}>
              Edit Color Palette :{" "}
            </TouchUpText>
            <p style={{ fontSize: 13 }}>(Change the color palette here)</p>
          </div>
        </PreviewSupervisorBodyDiv> */}
          <TabDiv><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_COLOUR_BLEED"}
            /></TabDiv>
          {/* <TabDiv style={{ marginTop: "20px" }}>
          <ButtonDiv> */}
          {/* <TabDivButton
              style={{
                backgroundColor: useOptionOne === false ? "#D3D3D3" : "white",
              }}
              onClick={selectOptionOne}
            >
              Color Swap
            </TabDivButton> */}
          {/* <TabDivButton
              style={{
                backgroundColor: useOptionTwo === true ? "#D3D3D3" : "white",
              }}
              onClick={selectOptionTwo}
            >
              Resolve Color Bleed
            </TabDivButton>
          </ButtonDiv>
        </TabDiv> */}

          <PreviewSupervisorBodyDiv>
            <PreviewSupervisorTouchUpEditable
              useOptionOne={useOptionOne}
              useOptionTwo={useOptionTwo}
              canvasRefColor={canvasRefColor}
              drawHandler={drawHandler}
              finishDrawingHandler={finishDrawingHandler}
              startDrawingHandler={startDrawingHandler}
              selectedColorId={selectedColorId}
              colorList={colorList}
              setColorList={setColorList}
              setSelectedColorImage={setSelectedColorImage}
              selectedColorImage={selectedColorImage}
              setPointScale={setPointScale}
            />
            <PreviewSupervisorTouchUpResultImage
              resultImage={resultImage}
              imageToSave={imageToSave}
              canvasRef={canvasRef}
              tempImageToSave={tempImageToSave}
              setTempImageToSave={tempImageToSave}
              bufferRef={bufferRef}
              setImageToSave={setImageToSave}
              useOptionOne={useOptionOne}
              useOptionTwo={useOptionTwo}
              canvasRefPolyResult={canvasRefPolyResult}
              imageSize={imageSize}
              clickPoints={clickPoints}
              pathCollection={pathCollection}
              colorCollection={colorCollection}
              color={color}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              addPathId={addPathId}
              location={location}
              originalImage={originalImage}
              toggleSideMenu={toggleSideMenu}
              selectedColorId={selectedColorId}
              setSelectedColorId={setSelectedColorId}
              selectedColourFromDrawer={selectedColourFromDrawer}
              setSelectedColourFromDrawer={setSelectedColourFromDrawer}
              changeList={changeList}
              colorList={colorList}
              setChangeList={setChangeList}
              /* colorSwapChangeHandler={colorSwapChangeHandler} */
              mixBlendMode={mixBlendMode}
              canvasOpacity={canvasOpacity}
              selectedFilterOption={selectedFilterOption}
              opacityCollection={opacityCollection}
              starPoint={starPoint}
            />
          </PreviewSupervisorBodyDiv>
          <ButtonBar
            style={{
              marginRight: "150px",
            }}
          >
            <PreviewSupervisorBodyOptionDiv>
              <PreviewSupervisorTouchUpShades
                selectedColourFromDrawer={selectedColourFromDrawer}
                setSelectedColourFromDrawer={setSelectedColourFromDrawer}
                useOptionOne={useOptionOne}
                toggleOptionOne={toggleOptionOne}
                toggleOptionTwo={toggleOptionTwo}
                toggleSideMenu={toggleSideMenu}
              />
              <PreviewSupervisorTouchUpPolygon
                useOptionTwo={useOptionTwo}
                toggleOptionTwo={toggleOptionTwo}
                toggleOptionOne={toggleOptionOne}
                color={color}
                colorCollection={colorCollection}
                setColorCollection={setColorCollection}
                selectedPath={selectedPath}
                setSelectedPath={setSelectedPath}
                setNewColor={setNewColor}
                toggleSideMenu={toggleSideMenu}
                selectedColourFromDrawer={selectedColourFromDrawer}
                setSelectedColourFromDrawer={setSelectedColourFromDrawer}
                setColor={setColor}
                toggleColorPicker={toggleColorPicker}
                searchColourHandler={searchColourHandler}
                setSearchText={setSearchText}
                searchText={searchText}
                errorText={errorText}
                mixBlendMode={mixBlendMode}
                setMixBlendMode={setMixBlendMode}
                canvasOpacity={canvasOpacity}
                setCanvasOpacity={setCanvasOpacity}
                selectedFilterOption={selectedFilterOption}
                setSelectedFilterOption={setSelectedFilterOption}
                setOpacityCollection={setOpacityCollection}
                opacityCollection={opacityCollection}
              />
            </PreviewSupervisorBodyOptionDiv>
            <div>
              <InputLabel style={{ height: "40px", marginTop: "25px" }}>
                <InputButton
                  type="file"
                  onChange={(e) => showFile(e)}
                ></InputButton>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"UPLOAD_EDITED_IMAGE"}
            />
              </InputLabel>
              <BackButton onClick={goBackHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"BACK"}
            /></BackButton>
              <ResetButton onClick={resetHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESET"}
            /></ResetButton>
              <DoneButton onClick={nextHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEXT"}
            /></DoneButton>
            </div>
          </ButtonBar>
        </PreviewSupervisorTouchUpContainer>
      )}
    </>
  );
};

export default PreviewSupervisorTouchUp;
