/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import { Context as AdminContext } from "../../context/AdminContext";
import dayjs from "dayjs";
import AdminRequestPendingResolvedButton from "../../components/admin-request/AdminRequestPendingResolvedButton";
import { AdminRequestContainer } from "../../styles/AdminRequestStyles";
import AdminPendingRequests from "../../components/admin-request/AdminPendingRequests";
import AdminResolvedRequests from "../../components/admin-request/AdminResolvedRequests";
import AdminArchivedRequests from "../../components/admin-request/AdminArchivedRequests";
import AdminRequestPagination from "../../components/admin-request/AdminRequestPagination";
import AdminDashboardReassingRequestSideBar from "../../components/admin-request/AdminDashboardReassingRequestSideBar";
import {
  PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE,
  PENDING_REQUEST_THEME_DETAIL_ROUTE,
  PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_RESULT_ROUTE,
} from "../../utils/Constants";
import {
  AdminCustomersImage,
  AdminCustomersText,
  AdminCustomerTitleDiv,
  HoirzontalStatsDivider,
  SearchBarDiv,
  ErrorMessage,
  FooterDiv,
  DownloadButton,
} from "../../styles/AdminCustomersStyles";
import BLACK_STAR_IMAGE from "../../images/black_star.svg";
import AdminRequestSearchBody from "../../components/admin-request/AdminRequestSearchBody";
import AdminResolvedRequestFeedback from "../../components/admin-request/AdminResolvedRequestFeedback";
import AdminSearchRequests from "../../components/admin-request/AdminSearchRequests";
import LanguageSelector from "../../language/LanguageSelector";

const AdminRequests = ({
  history,
  width,
  requestList,
  tempRequestList,
  setTempRequestList,
  getAdminRequests,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const dropDownList = ["Latest First", "Oldest First"];
  var date = new Date();
  const [tabSelected, setTabSelected] = useState(0);
  const [pagginationToggle, setPaginationToggle] = useState(false);
  const [selectedPageForPending, setSelectedPageForPending] = useState(1);
  const [selectedPageForResolved, setSelectedPageForResolved] = useState(1);
  const [selectedPageForArchived, setSelectedPageForArchived] = useState(1);
  const [selectedItem, setSelectedItem] = useState();
  const [openReAssignRequestMenu, setOpenReAssignRequestMenu] = useState(false);
  const [openFeedbackMenu, setOpenFeedbackMenu] = useState(false);
  const [paginationList, setPaginationList] = useState([]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(date.setDate(date.getDate() + 1));
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    dropDownList[0]
  );
  const [textFilterName, setTextFilterName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchRequest, setSearchRequest] = useState(false);
  const [selectedRequestIdForFilter, setSelectedRequestIdForFilter] =
    useState(null);
  const [archivedFlag, setArchivedFlag] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [selectedReColourType, setSelectedRecolourType] = useState([]);

  const {
    state,
    reassignRequest,
    adminTeam,
    adminRequestsExcelDownload,
    feedbackResult,
    adminArchiveRequest,
  } = useContext(AdminContext);

  const toggleOpenReAssignRequestMenu = () =>
    setOpenReAssignRequestMenu((previousState) => !previousState);
  const togglePagination = () =>
    setPaginationToggle((previousState) => !previousState);
  const toggleFilterDropDown = () =>
    setFilterDropDown((previousState) => !previousState);
  const toggleFeedbackMenu = () =>
    setOpenFeedbackMenu((previousState) => !previousState);
  const toggleSearchRequest = () =>
    setSearchRequest((previousState) => !previousState);
  const togglePending = () => setTabSelected(0);
  const toggleResolved = () => setTabSelected(1);
  const toggleArchived = () => setTabSelected(2);
  const toggleArchivedDone = () =>
    setArchivedFlag((previousState) => !previousState);

  const [pagination, setPagination] = useState({
    selectedPageForPending: selectedPageForPending,
    selectedPageForResolved: selectedPageForResolved,
    tabSelected: tabSelected,
  });

  const setHistoryForBackwardNavigation = () => {
    setPagination({
      selectedPageForPending: selectedPageForPending,
      selectedPageForResolved: selectedPageForResolved,
      tabSelected: tabSelected,
    });
    // _state.selectedPageForPending = selectedPageForPending
    // _state.selectedPageForResolved = selectedPageForResolved
    // _state.tabSelected = tabSelected
    // history.replace(ADMIN_ROUTE, _state)
  };

  const resolveRequestHandler = (item) => {
    const filter = {
      fromDate: fromDate,
      toDate: toDate,
      selectedCountries: selectedCountries,
      selectedReColourType: selectedReColourType,
    };
    const data = {
      selectedPageForPending: selectedPageForPending,
      selectedPageForResolved: selectedPageForResolved,
      selectedPageForArchived: selectedPageForArchived,
      tabSelected: tabSelected,
      requestList: requestList,
      filter: filter,
    };
    // setHistoryForBackwardNavigation()
    if (item.colourization_type === "Reference") {
      history.push(PENDING_REQUEST_REFERENCE_IMAGE_DETAIL_ROUTE, {
        item: item,
        data: data,
      });
    } else if (item.colourization_type === "Theme") {
      history.push(PENDING_REQUEST_THEME_DETAIL_ROUTE, {
        item: item,
        data: data,
      });
    } else {
      history.push(PENDING_REQUEST_LEAD_COLOUR_DETAIL_ROUTE, {
        item: item,
        data: data,
      });
    }
  };

  const adminArchiveRequestSuccessCallback = (msg) => {
    toggleArchivedDone();
  };

  const adminArchiveRequestFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const archiveHandler = (request_id) => {
    setHistoryForBackwardNavigation();
    adminArchiveRequest({
      request_id: request_id,
      adminArchiveRequestSuccessCallback,
      adminArchiveRequestFailureCallback,
    });
  };

  useEffect(() => {
    if (archivedFlag === true) {
      getAdminRequests();
      // console.log("archive flag")
      toggleArchivedDone();
    }
  }, [archivedFlag]);

  const applyFilterHandler = () => {
    let newList = [],
      tempNewList = [],
      filteredList = requestList;
    console.log(filteredList);
    if (
      selectedCountries.length > 0 ||
      selectedReColourType.length > 0 ||
      (fromDate !== "" && toDate !== "")
    ) {
      if (
        fromDate !== "" &&
        toDate !== dayjs().format("DD/MM/YYYY")
      ) {
        tempNewList = [];
        const unixFromDate = dayjs(fromDate).unix();
        const unixToDate = dayjs(toDate).unix();
        for (let i = 0; i < requestList.length; i++) {
          const serverUnixDate = dayjs(requestList[i].timestamp).unix();
          if (serverUnixDate <= unixToDate && serverUnixDate >= unixFromDate) {
            tempNewList.push(filteredList[i]);
          }
        }
        console.log("date");
        filteredList = tempNewList;
        console.log("filtered list", filteredList);
      }

      if (selectedCountries.length > 0) {
            for (let i = 0; i < selectedCountries.length; i++) {
              filteredList = requestList.filter(
                (data) => data.country === selectedCountries[i]
              );
              newList.push(...filteredList);
            }

        console.log("countries");
        filteredList = newList;
        console.log("filtered list", filteredList);
      }

      if (selectedReColourType.length > 0) {
        // tempNewList = [];
        // for (let i = 0; i < selectedReColourType.length; i++) {
        //   tempNewList.push(
        //     ...filteredList.filter(
        //       (data) => data.colourization_type === selectedReColourType[i]
        //     )
        //   );
        // }

        for (let i = 0; i < selectedReColourType.length; i++) {
          filteredList = requestList.filter(
            (data) => data.colourization_type === selectedReColourType[i]
          );
          newList.push(...filteredList);
        }
        console.log("type");
        filteredList = newList;
        console.log(filteredList);
      }
      newList = filteredList;

      setSelectedPageForPending(1);
      setSelectedPageForResolved(1);
      setTempRequestList(newList);
      updatePagination(
        tabSelected === 0
          ? newList.filter((data) => data.status === "Pending")
          : tabSelected === 1
          ? newList.filter((data) => data.status === "Resolved")
          : newList.filter((data) => data.status === "Archived")
      );
    }
  };

  const initialFilter = () => {
    console.log("inside initial filter");
    console.log(fromDate, toDate,"^^^^^")
    let newList = [],
      tempNewList = [],
      filteredList = requestList;
    console.log(requestList);

    if (fromDate !== "" && toDate !== dayjs().format("DD/MM/YYYY")) {
      tempNewList = [];
      const unixFromDate = dayjs(fromDate).unix();
      const unixToDate = dayjs(toDate).unix();
      for (let i = 0; i < requestList.length; i++) {
        const serverUnixDate = dayjs(requestList[i].timestamp).unix();
        if (serverUnixDate <= unixToDate && serverUnixDate >= unixFromDate) {
          tempNewList.push(filteredList[i]);
        }
      }
      console.log("date");
      filteredList = tempNewList;
      console.log(filteredList);
    }

    newList = filteredList;

    setTempRequestList(newList);
    updatePagination(
      tabSelected === 0
        ? newList.filter((data) => data.status === "Pending")
        : tabSelected === 1
        ? newList.filter((data) => data.status === "Resolved")
        : newList.filter((data) => data.status === "Archived")
    );
  };

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    // console.log("history values")
    console.log(history.location);
    console.log("Initial filter check");
    if (history.location.state?.tabSelected) {
      // console.log("setting tab selected")
      setTabSelected(history.location.state?.tabSelected);
    }
    if (history.location.state?.selectedPageForPending) {
      // console.log("setting page resolved")
      setSelectedPageForPending(history.location.state?.selectedPageForPending);
    }
    if (history.location.state?.selectedPageForResolved) {
      // console.log("setting page pending")
      setSelectedPageForResolved(
        history.location.state?.selectedPageForResolved
      );
    }
    if (history.location.state?.selectedPageForArchived) {
      // console.log("setting page archived")
      setSelectedPageForArchived(
        history.location.state?.selectedPageForArchived
      );
    }
    if (history.location.state?.filter) {
      // console.log("setting page archived")
      setFromDate(history.location.state?.filter.fromDate);
      setToDate(history.location.state?.filter.toDate);
      setSelectedCountries(history.location.state?.filter.selectedCountries);
      setSelectedRecolourType(
        history.location.state?.filter.selectedReColourType
      );
    }
  }, []);

  useEffect(() => {
    initialFilter();
    console.log("initial filter");
    console.log(tempRequestList);
  }, [fromDate, toDate, requestList]);

  useEffect(() => {
    applyFilterHandler();
  }, [selectedReColourType, selectedCountries]);

  const adminResultHandler = (item) => {
    history.push(ADMIN_RESULT_ROUTE, {
      item: item,
      selectedPageForPending: selectedPageForPending,
      selectedPageForResolved: selectedPageForResolved,
      selectedPageForArchived: selectedPageForArchived,
      tabSelected: tabSelected,
      requestList: requestList,
      filter: {
        fromDate: fromDate,
        toDate: toDate,
        selectedCountries: selectedCountries,
        selectedReColourType: selectedReColourType,
      },
    });
  };

  const adminRequestExcelSuccessCallback = (data) => {
    var blob = data;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "report.xlsx");
    } else {
      var downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      downloadLink.download = "report.xlsx";
      document.body.appendChild(downloadLink);
      window.open(downloadLink, "_blank");
    }
  };

  const adminRequestExcelFailureCallback = (msg) => {
    alert(msg);
  };

  const downloadReport = () => {
    adminRequestsExcelDownload({
      fromDate: dayjs(fromDate).format("DD/MM/YYYY"),
      toDate: dayjs(toDate).format("DD/MM/YYYY"),
      selectedCountries: selectedCountries,
      selectedReColourType: selectedReColourType,
      adminRequestExcelSuccessCallback,
      adminRequestExcelFailureCallback,
    });
  };

  return (
    <>
      {searchRequest && (
        <AdminSearchRequests
          openSideMenu={searchRequest}
          toggleSearchRequest={toggleSearchRequest}
          setTempRequestList={setTempRequestList}
          setSelectedPageForPending={setSelectedPageForPending}
          setSelectedPageForResolved={setSelectedPageForResolved}
          setPaginationList={setPaginationList}
          tabSelected={tabSelected}
          requestList={requestList}
          width={width}
          fromDate={fromDate}
          toDate={toDate}
          selectedCountries={selectedCountries}
          selectedReColourType={selectedReColourType}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setSelectedCountries={setSelectedCountries}
          setSelectedRecolourType={setSelectedRecolourType}
          tempRequestList={tempRequestList}
          initialFilter={initialFilter}
        />
      )}
      <AdminDashboardReassingRequestSideBar
        openReAssignRequestMenu={openReAssignRequestMenu}
        toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
        selectedItem={selectedItem}
        getAdminRequests={getAdminRequests}
        adminTeam={adminTeam}
        state={state}
        reassignRequest={reassignRequest}
        width={width}
      />
      {openFeedbackMenu && (
        <AdminResolvedRequestFeedback
          feedbackResult={feedbackResult}
          openFeedbackMenu={openFeedbackMenu}
          toggleFeedbackMenu={toggleFeedbackMenu}
          selectedRequestIdForFilter={selectedRequestIdForFilter}
          width={width}
        />
      )}
      <AdminRequestContainer
        applyFilter={
          searchRequest || openFeedbackMenu || openReAssignRequestMenu
        }
      >
        <AdminCustomerTitleDiv>
          <AdminCustomersImage src={BLACK_STAR_IMAGE} />
          <AdminCustomersText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTS"}
            /></AdminCustomersText>
        </AdminCustomerTitleDiv>
        <HoirzontalStatsDivider />

        <SearchBarDiv>
          <AdminRequestPendingResolvedButton
            tabSelected={tabSelected}
            togglePending={togglePending}
            toggleResolved={toggleResolved}
            toggleArchived={toggleArchived}
            setTabSelected={setTabSelected}
            pendingListLength={
              tempRequestList.filter((data) => data.status === "Pending").length
            }
            resolvedListLength={
              tempRequestList.filter((data) => data.status === "Resolved")
                .length
            }
            archivedListLength={
              tempRequestList.filter((data) => data.status === "Archived")
                .length
            }
          />
          <AdminRequestSearchBody
            textFilterName={textFilterName}
            setTextFilterName={setTextFilterName}
            toggleSearchRequest={toggleSearchRequest}
            toggleFilterDropDown={toggleFilterDropDown}
            selectedFilterOption={selectedFilterOption}
            filterDropDown={filterDropDown}
            dropDownList={dropDownList}
            setSelectedFilterOption={setSelectedFilterOption}
          />
        </SearchBarDiv>
        {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {tempRequestList.length > 0 && tabSelected === 0 ? (
          <AdminPendingRequests
            list={tempRequestList.filter((data) => data.status === "Pending")}
            selectedPageForPending={selectedPageForPending}
            toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
            setSelectedItem={setSelectedItem}
            resolveRequestHandler={resolveRequestHandler}
            textFilterName={textFilterName}
            selectedFilterOption={selectedFilterOption}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Pending"
            )}
            archiveHandler={archiveHandler}
          />
        ) : tabSelected === 1 ? (
          <AdminResolvedRequests
            selectedFilterOption={selectedFilterOption}
            list={tempRequestList.filter((data) => data.status === "Resolved")}
            selectedPageForResolved={selectedPageForResolved}
            adminResultHandler={adminResultHandler}
            textFilterName={textFilterName}
            toggleFeedbackMenu={toggleFeedbackMenu}
            setPaginationList={setPaginationList}
            setSelectedRequestIdForFilter={setSelectedRequestIdForFilter}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Resolved"
            )}
          />
        ) : (
          <AdminArchivedRequests
            list={tempRequestList.filter((data) => data.status === "Archived")}
            selectedPageForArchived={selectedPageForArchived}
            toggleOpenReAssignRequestMenu={toggleOpenReAssignRequestMenu}
            setSelectedItem={setSelectedItem}
            resolveRequestHandler={resolveRequestHandler}
            textFilterName={textFilterName}
            selectedFilterOption={selectedFilterOption}
            setPaginationList={setPaginationList}
            allRequests={tempRequestList.filter(
              (data) => data.status === "Archived"
            )}
          />
        )}
        {tempRequestList.length > 0 && (
          <FooterDiv>
            <AdminRequestPagination
              paginationList={paginationList}
              selectedPageForResolved={selectedPageForResolved}
              setSelectedPageForResolved={setSelectedPageForResolved}
              selectedPageForPending={selectedPageForPending}
              setSelectedPageForPending={setSelectedPageForPending}
              selectedPageForArchived={selectedPageForArchived}
              setSelectedPageForArchived={setSelectedPageForArchived}
              tabSelected={tabSelected}
              togglePagination={togglePagination}
            />
            <DownloadButton onClick={downloadReport}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            /></DownloadButton>
          </FooterDiv>
        )}
      </AdminRequestContainer>
    </>
  );
};

export default AdminRequests;
