import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  StepTwoContainer,
  StepThreeContainer,
  StepsContainer,
  StepOneDiv,
  StepText,
  StepOneContainer,
  StepTwoDiv,
  StepThreeDiv,
  StpeSubTitle,
} from "../../styles/PreviewSupervisorSelectorStyles";
import LanguageSelector from "../../language/LanguageSelector";

const SelectorSteps = () => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <StepsContainer>
      <StepOneContainer>
        <StepOneDiv>
          <StepText>1</StepText>
        </StepOneDiv>
        <StpeSubTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_IMAGE"}
            /></StpeSubTitle>
      </StepOneContainer>
      <StepTwoContainer>
        <StepTwoDiv>
          <StepText>2</StepText>
        </StepTwoDiv>
        <StpeSubTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOMMENDATIONS"}
            /></StpeSubTitle>
      </StepTwoContainer>
      <StepThreeContainer>
        <StepThreeDiv>
          <StepText>3</StepText>
        </StepThreeDiv>
        <StpeSubTitle><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CONFIRM_RESULTS"}
            /></StpeSubTitle>
      </StepThreeContainer>
    </StepsContainer>
  );
};

export default SelectorSteps;
