import React from "react";
import styled from "styled-components";
import BACK_IMAGE from "../images/back.svg";

const BackButton = ({ goBackHandler }) => {
  return (
    <BackBody onClick={goBackHandler}>
      <img src={BACK_IMAGE} alt="back" />
    </BackBody>
  );
};

const BackBody = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.14);
  padding: 4px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 10px 0 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export default BackButton;
