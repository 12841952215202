import React from "react";
import {
  NearByStoresDiv,
  BottomText,
  NearByStoreDiv,
  NearByImage,
  NearByStoreText,
  NearByStoreSubTitle,
} from "../../styles/ConsumerDetailReportStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import SHOP_IMAGE from "../../images/shop_image.png";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportNearByStores = ({ list, selectedCountry }) => {
  return (
    <NearByStoresDiv>
      <BottomText>
      <LanguageSelector selectedCountry={selectedCountry} text={"NEARBY_STORES"} />
      </BottomText>
      {list !== undefined &&
        list.length > 0 &&
        list.map((data, index) => {
          return (
            <NearByStoreDiv key={`${index}`}>
              {data?
              <>
                <NearByImage src={SHOP_IMAGE} />
                <div style={{ margin: "0px 10px" }}>
                  <NearByStoreText>{data.shop_name}</NearByStoreText>
                  <NearByStoreSubTitle>{data.address}</NearByStoreSubTitle>
                  <img src={GREEN_ARROW_IMAGE} alt="Arrow" />
                </div>
              </>
              :null}
            </NearByStoreDiv>
          );
        })}
    </NearByStoresDiv>
  );
};

export default ConsumerDetailReportNearByStores;
