import React, { useState, useEffect } from "react";
import {
  LeadColourDiv,
  LeadColor,
  LeadColorParentDiv,
  OrText,
  SearchColorColumnDiv,
  SearchShadeText,
  SearchColorRowDiv,
  SearchColorInputDiv,
  SearchButton,
  SelectColorText,
  ColorBodyDiv,
  ColorDiv,
  ToolTip,
  SuggestedPaletteSubTitle,
} from "../../styles/ConsumerChooseColorStyles";
// import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerChooseColourLeadColour = ({
  colourList,
  toggleColourDrawer,
  setSelectedColour,
  setSearchText,
  searchText,
  searchColourHandler,
  selectedCountry,
  pakistanColor,
  familyColour,
  selectedColour,
  setSelectedColourFromDrawer,
  selectedColourFromDrawer,
  getSuggesstionsHandler,
  errorText,
}) => {
  //Normal Colors
  const colorPickerHandler = (colourName) => {
    setSelectedColour(colourName);
    toggleColourDrawer();
  };

  //Pakistan Colors
  const [list, setList] = useState([]);

  const familyColourSuccessCallback = (colourList) => {
    setList(colourList);
  };

  const familyColorFailureCallback = () => {};

  const selectedColorHandler = (data) => {
    console.log(data);
    setSelectedColour(data);
    setSelectedColourFromDrawer(data);
    getSuggesstionsHandler(data.colour_name);
  };

  useEffect(() => {
    if (selectedColour !== null) {
      familyColour({
        colour_family: "Pakistan Ready Mix",
        familyColourSuccessCallback,
        familyColorFailureCallback,
      });
    }
  }, []);

  return (
    <LeadColorParentDiv>
      {pakistanColor ? (
        <React.Fragment>
          <ColorBodyDiv>
            {list !== null &&
              list.map((data, index) => {
                return (
                  <ColorDiv
                    key={`${index}`}
                    style={{
                      backgroundColor: `rgb(${data.red_value},${data.green_value},${data.blue_value})`,
                      border:
                        data === selectedColourFromDrawer
                          ? "4px solid black"
                          : "none",
                    }}
                    onClick={() => {
                      selectedColorHandler(data);
                    }}
                  >
                    <ToolTip>{data.colour_name}</ToolTip>
                  </ColorDiv>
                );
              })}
          </ColorBodyDiv>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              diaply: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <SelectColorText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"SELECT_A_LEAD_COLOUR"}
              />
            </SelectColorText>
            <LeadColourDiv>
              {colourList.map((data, index) => {
                return (
                  <LeadColor
                    backgroundColour={data.color}
                    key={`${index}`}
                    onClick={() => {
                      colorPickerHandler(data.name);
                    }}
                  >
                    {/* <CheckImage src={BLACK_ARROW_IMAGE}></CheckImage> */}
                  </LeadColor>
                );
              })}
            </LeadColourDiv>
          </div>
          <OrText>
            <LanguageSelector selectedCountry={selectedCountry} text={"OR"} />
          </OrText>
          <SearchColorColumnDiv>
            <SearchShadeText>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"SEARCH_SHADE"}
              />
            </SearchShadeText>
            <SearchColorRowDiv>
              <SearchColorInputDiv
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                type="text"
              />
              <SearchButton onClick={searchColourHandler}>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"SEARCH"}
                />
              </SearchButton>
            </SearchColorRowDiv>
            {errorText ? (
              <SuggestedPaletteSubTitle>
                <LanguageSelector
                  selectedCountry={selectedCountry}
                  text={"COLOUR_SEARCH_ERROR"}
                />
              </SuggestedPaletteSubTitle>
            ) : null}
          </SearchColorColumnDiv>
        </React.Fragment>
      )}
    </LeadColorParentDiv>
  );
};

export default ConsumerChooseColourLeadColour;
