/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import AdminDashboardServiceHighlightsHeader from "../../components/admin-dashboard/AdminDashboardServiceHighlightsHeader";
import {
  DashboardBody,
  DashboardGraphBody,
  HoirzontalStatsDivider,
} from "../../styles/AdminDashboardStyles";
import AdminDashboardServiceHighlightStats from "../../components/admin-dashboard/AdminDashboardServiceHighlightStats";
import AdminDashboardAnalyticsHeader from "../../components/admin-dashboard/AdminDashboardAnalyticsHeader";
import AdminDashboardCountryName from "../../components/admin-dashboard/AdminDashboardCountryName";
import AdminDashboardRequestCustomerGraph from "../../components/admin-dashboard/AdminDashboardRequestCustomerGraph";
import AdminDashboardAnalyticsStatsContainer from "../../components/admin-dashboard/AdminDashboardAnalyticsStatsContainer";
import SINGAPORE_IMAGE from "../../images/singapore.svg";
import INDONESIA_IMAGE from "../../images/indonesia.svg";
import THAILAND_COUNTRY_IMAGE from "../../images/thailand.svg";
import VIETNAM_IMAGE from "../../images/vietnam.svg";
import INDIA_IMAGE from "../../images/india.svg";
import MALAYASIA_IMAGE from "../../images/malayasia.svg";
import PAKISTAN_IMAGE from "../../images/pakistan.svg";
import SRILANKA_IMAGE from "../../images/sri_lanka.png";
import AdminDashboardBillingBody from "../../components/admin-dashboard/AdminDashboardBillingBody";
import dayjs from "dayjs";
import { Context as AdminContext } from "../../context/AdminContext";
import AdminDashboardFilterSideBar from "../../components/admin-dashboard/AdminDashboardFilterSideBar";
import AdminDashboardDownloadInvoiceDrawer from "../../components/admin-dashboard/AdminDashboardDownloadInvoiceDrawer";

const AdminDashboard = ({ width }) => {
  var date = new Date();
  const countryList = [
    "All",
    "Singapore",
    "Indonesia",
    "Thailand",
    "Vietnam",
    "India",
    "Pakistan",
    "Malaysia",
    "Sri Lanka",
  ];
  const countries = [
    { name: "Singapore", image: SINGAPORE_IMAGE },
    { name: "Malaysia", image: MALAYASIA_IMAGE },
    { name: "Indonesia", image: INDONESIA_IMAGE },
    { name: "Thailand", image: THAILAND_COUNTRY_IMAGE },
    { name: "Vietnam", image: VIETNAM_IMAGE },
    { name: "India", image: INDIA_IMAGE },
    { name: "Pakistan", image: PAKISTAN_IMAGE },
    { name: "Sri Lanka", image: SRILANKA_IMAGE },
  ];

  const [selectedCountry, setSelectedCountry] = useState(countryList[0]);
  const [serviceHighlights, setServiceHighlights] = useState();
  const [billingData, setBillingData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [downloadInvoiceDrawer, setDownloadInvoiceDrawer] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().subtract(30, "day").format("DD/MM/YYYY"));
  const [toDate, setToDate] = useState(dayjs().format("DD/MM/YYYY"));

  const toggleShowFilter = () =>
    setShowFilter((previousState) => !previousState);
  const toggleDownloadInvoiceDrawer = () =>
    setDownloadInvoiceDrawer((previousState) => !previousState);

  const { adminServiceHighlights, billing, analytics } =
    useContext(AdminContext);

  const adminServiceSuccessCallback = (data) => {
    setServiceHighlights(data);
  };

  const adminBillingSuccessCallback = (data) => {
    setBillingData(data);
  };

  const adminBillingFailureCallback = (msg) => {};

  const analyticsSuccessCallback = (data) => {
    setAnalyticsData(data);
  };

  const analyticsFailureCallback = (msg) => {};

  useEffect(() => {
    adminServiceHighlights({
      from: fromDate,
      to: toDate,
      adminServiceSuccessCallback,
    });
    billing({
      _from: fromDate,
      to: toDate,
      adminBillingSuccessCallback,
      adminBillingFailureCallback,
    });
    analytics({
      country: selectedCountry,
      _from: fromDate,
      to: toDate,
      analyticsSuccessCallback,
      analyticsFailureCallback,
    });
  }, [fromDate, toDate, selectedCountry]);

  return (
    <>
      {showFilter && (
        <AdminDashboardFilterSideBar
          showFilter={showFilter}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
          toggleShowFilter={toggleShowFilter}
          width={width}
        />
      )}
      {downloadInvoiceDrawer && (
        <AdminDashboardDownloadInvoiceDrawer
          toggleDownloadInvoiceDrawer={toggleDownloadInvoiceDrawer}
          downloadInvoiceDrawer={downloadInvoiceDrawer}
          width={width}
        />
      )}
      <DashboardBody applyFilter={showFilter || downloadInvoiceDrawer}>
        <AdminDashboardServiceHighlightsHeader
          toggleShowFilter={toggleShowFilter}
        />
        <AdminDashboardServiceHighlightStats
          serviceHighlights={serviceHighlights}
        />
        <HoirzontalStatsDivider />
        <AdminDashboardAnalyticsHeader />
        <AdminDashboardCountryName
          countryList={countryList}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <DashboardGraphBody applyFilter={showFilter || downloadInvoiceDrawer}>
          <AdminDashboardRequestCustomerGraph
            analyticsData={analyticsData}
            selectedCountry={selectedCountry}
          />
        </DashboardGraphBody>
        <AdminDashboardAnalyticsStatsContainer 
        analyticsData={analyticsData} 
        selectedCountry={selectedCountry}
        />
        <HoirzontalStatsDivider />
        <AdminDashboardBillingBody
          countries={countries}
          billingData={billingData}
          toggleDownloadInvoiceDrawer={toggleDownloadInvoiceDrawer}
        />
      </DashboardBody>
    </>
  );
};

export default AdminDashboard;
