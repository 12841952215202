import styled from "styled-components";

export const AdminUserBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const AdminUserHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const AdminUserTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  align-items: center;
`;

export const AddMemberButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  height: 30px;
  width: 130px;
  margin: 10px;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    align-self: flex-start;
  }
`;

export const AdminUserImage = styled.img``;

export const AdminUserText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 4px;
`;

export const HoirzontalStatsDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;

export const AdminCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const AddUserModalBarDiv = styled.div`
  display: "flex";
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const AddUserSideBar = styled.div`
  height: 100%;
  width: 30vw;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

export const AddUserCancelImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 20px 0;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
`;

export const AddUserDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminUserAddUseImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 10px 0 0;
`;

export const AdminUserAddtext = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const SendInviteButton = styled.button`
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 100px;
  padding: 8px 0;
  background: ${(props) => (props.isDisabled === true ? "grey" : "#2fc38c")};
  margin: 20px 0;
`;

export const RoleText = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
`;

export const PreviewStaffDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const PreviewSupervisorDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  margin: 4px 0;
  color: #767676;
`;

export const PreviewStaffSupervisorText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  margin: 0 4px;
`;

export const PreviewStaffSupervisorOuterCheckBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #2fc38c;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 4px 0 0;
`;

export const PreviewStaffSupervisorInnerCheckBox = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2fc38c;
`;

export const ManageUserModalBarDiv = styled.div`
  display: "flex";
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const ManageUserSideBar = styled.div`
  height: 100%;
  width: 30vw;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

export const ManageUserCancelImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 20px 0;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
`;

export const ManageUserDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const ManageUserAddUseImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 10px 0 0;
`;

export const ManageUserAddtext = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

export const SaveButton = styled.button`
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  width: 100px;
  padding: 8px 0;
  background: ${(props) => (props.isDisabled === true ? "grey" : "#2fc38c")};
  :hover {
    cursor: pointer;
  }
`;

export const RemoveUser = styled.button`
  outline: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100px;
  padding: 8px 0;
  margin: 0 20px 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const AdminUserTable = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  width: 94%;
  margin-top: 20px;
  @media (max-width: 1100px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const ManageuserIcon = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const AdminTableBody = styled.tbody``;

export const AdminTableRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const AdminTableHeaderData = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
  align-self: center;
`;

export const AdminTableBodyData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  padding: 10px 0px;
`;

export const SearchBarBodyCustomerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 40px;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchBarInputDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 180px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const SearchBarInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  width: 150px;
`;

export const SearchFilterDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 160px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  padding: 0px 4px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const FilterText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #545850;
  margin: 0 20px;
`;

export const DropDownParentDiv = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const DropDownDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
`;

export const DropDownText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #545850;
  margin: 0 10px;
`;

export const DropDownContent = styled.div`
  display: ${(props) => (props.show === true ? "flex" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropDownContentText = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #545850;
  margin: 4px 10px;
  outline: none;
  border: none;
  background: transparent;
`;

export const HoirzontalCustomerDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 10px 40px;
`;

export const FooterDiv = styled.div`
  display: flex;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
  /* padding: 0 14vw; */
  margin: 20px auto;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const PaginationArrowContainer = styled.div`
  width : 40px;
  height : 20px;
  color : #ffffff;
`;

export const PaginationLeftImage = styled.img`
  padding: 0 10px;
  display: 'none';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  :hover {
    cursor: pointer;
  }
`;

export const PaginationRightImage = styled.img`
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const PaginationNumberDiv = styled.button`
  background-color: #f1f1f1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  color: ${(props) => (props.isSelected === true ? "#2FC38C" : "#012169")};
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  :hover {
    cursor: pointer;
  }
`;

export const AdminUserSearchRequestModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const AdminUserSearchRequestSideBarContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

export const AdminUserSearchRequestHeader = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
`;

export const AdminUserSearchRequestsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const ByCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
  margin: 20px 0 8px 0;
`;

export const AdminUserRequestSearchCountryDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    align-items: flex-start;
  }
`;

export const SelectedCountryCheck = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const AdminUserCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0px 10px;
`;

export const AdminUsersButtonBar = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

export const ResetFilterButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 10px;
  outline: none;
  margin: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  background: #2fc38c;
  border-radius: 5px;
  padding: 5px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const DownloadButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  padding: 5px 20px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`;
