import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  OriginalImageBodyText,
  ReferenceImageBodyContainer,
  ImageComparisonContainer,
  OriginalImageBodyContainer,
  ReferenceImageBodyText,
  ReferenceBodyImage,
  OriginalBodyImage,
} from "../../styles/PreviewSupervisorPendingRequestReferenceImageDetailStyles";
import LanguageSelector from "../../language/LanguageSelector";

//only for preview supervisor

const PendingRequestReferenceImageOriginal = ({ state }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <ImageComparisonContainer>
      <OriginalImageBodyContainer>
        <OriginalImageBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ORIGINAL_IMAGE"}
            /></OriginalImageBodyText>
        <OriginalBodyImage
          alt="Original"
          src={state.data && state.data?.request_detail?.raw_image}
        />
      </OriginalImageBodyContainer>
      <ReferenceImageBodyContainer>
        <ReferenceImageBodyText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REFERENCE"}
            /></ReferenceImageBodyText>
        <ReferenceBodyImage
          alt="Reference"
          src={state.data && state.data?.request_detail?.reference_image}
        />
      </ReferenceImageBodyContainer>
    </ImageComparisonContainer>
  );
};

export default PendingRequestReferenceImageOriginal;
