import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { PreviewSupervisorDetailsContext } from "../context/PreviewSupervisorDetailsContext";
import {
  PREVIEW_STAFF_DASHBOARD_ROUTE,
  PREVIEW_SUPERVISOR_DASHBOARD_ROUTE,
} from "./Constants";

const PreviewSupervisorRoute = (props) => {
  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const value = previewSupervisorDetailContext.previewSupervisorDetails;
  const { decodedToken } = props;

  if (
    value.isValid === undefined ||
    value.isValid === false ||
    value.isValid === null
  ) {
    if (decodedToken?.user_type === "Preview Staff") {
      return <Redirect to={PREVIEW_STAFF_DASHBOARD_ROUTE} />;
    } else {
      return <Redirect to={PREVIEW_SUPERVISOR_DASHBOARD_ROUTE} />;
    }
  } else {
    return <Route component={props.component} {...props} />;
  }
};

export default PreviewSupervisorRoute;
