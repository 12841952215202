import React from "react";
import CANCEL_IMAGE from "../../images/cancel.svg";
import {
  ConsumerLoginSideBarDiv,
  LoginCancelDrawerImage,
  LoginDrawerFooterDiv,
  WelcomeBackContainer,
  ConsumerLoginModalBarDiv,
} from "../../styles/ConsumerUploadImageStyles";
import ConsumerLoginOTP from "../../components/consumer-login/ConsumerLoginOTP";

const InternalLoginOtpSideBar = ({
  showOTPMenu,
  toogleOTPMenu,
  state,
  verifyEmailOTP,
  email,
  history,
  colourizationContext,
  previewSupervisorDetailContext,
  verifyEmail,
  width,
  selectedCountry
}) => {
  if (width < 1100) {
    return (
      <ConsumerLoginModalBarDiv openSideMenu={showOTPMenu}>
        <LoginCancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toogleOTPMenu}
          alt="Cancel"
        />
        <WelcomeBackContainer>
          <ConsumerLoginOTP
            toggleSideMenu={toogleOTPMenu}
            state={state}
            verifyEmailOTP={verifyEmailOTP}
            email={email}
            history={history}
            colourizationContext={colourizationContext}
            previewSupervisorDetailContext={previewSupervisorDetailContext}
            verifyEmail={verifyEmail}
            selectedCountry={selectedCountry}
          />
        </WelcomeBackContainer>
        <LoginDrawerFooterDiv />
      </ConsumerLoginModalBarDiv>
    );
  } else {
    return (
      <ConsumerLoginSideBarDiv openSideMenu={showOTPMenu}>
        <LoginCancelDrawerImage
          src={CANCEL_IMAGE}
          onClick={toogleOTPMenu}
          alt="Cancel"
        />
        <WelcomeBackContainer>
          <ConsumerLoginOTP
            toggleSideMenu={toogleOTPMenu}
            state={state}
            verifyEmailOTP={verifyEmailOTP}
            email={email}
            history={history}
            colourizationContext={colourizationContext}
            previewSupervisorDetailContext={previewSupervisorDetailContext}
            verifyEmail={verifyEmail}
            selectedCountry={selectedCountry}
          />
        </WelcomeBackContainer>
        <LoginDrawerFooterDiv />
      </ConsumerLoginSideBarDiv>
    );
  }
};

export default InternalLoginOtpSideBar;
