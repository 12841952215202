import React from "react";
import {
  ResultBodyModal,
  CloseModalImage,
  ColorText,
  ModalImageDiv,
  ToolTip,
  ModalPaleeteDiv,
  ModalImageDownloadButton,
  ResultModal,
  LeftArrowImage,
  RightArrowImage,
  ModalImage,
  ModalPalette,
  ResultBottomModal,
} from "../../styles/ConsumerDetailReportStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import CANCEL_IMAGE from "../../images/cancel.svg";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportResultModal = ({
  toggleModal,
  previousModalImageClickHandler,
  state,
  selectedModalIndex,
  downloadSingleImageHandler,
  nextModalImageClickHandler,
  width,
  selectedCountry
}) => {
  if (width < 1100) {
    return (
      <ResultBottomModal>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />

          <ModalImageDiv>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <LeftArrowImage
                src={GREEN_ARROW_IMAGE}
                onClick={previousModalImageClickHandler}
              />
              <ModalImage
                src={
                  state.data?.result?.report_details?.results[
                    selectedModalIndex
                  ].result_image
                }
              />
              <RightArrowImage
                src={GREEN_ARROW_IMAGE}
                onClick={nextModalImageClickHandler}
              />
            </div>
            <ModalPalette>
              {state.data?.result?.report_details?.results[
                selectedModalIndex
              ].colours.map((item2, index2) => {
                return (
                  <div key={`${index2}`}>
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>{item2?.colour_code}</ColorText>
                  </div>
                );
              })}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  state.data?.result?.report_details?.results[
                    selectedModalIndex
                  ].result_image
                );
              }}
            >
            <LanguageSelector selectedCountry={selectedCountry} text={"DOWNLOAD_IMAGE"} />
            </ModalImageDownloadButton>
          </ModalImageDiv>
        </ResultBodyModal>
      </ResultBottomModal>
    );
  } else {
    return (
      <ResultModal>
        <ResultBodyModal>
          <CloseModalImage src={CANCEL_IMAGE} onClick={toggleModal} />
          <LeftArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={previousModalImageClickHandler}
          />
          <ModalImageDiv>
            <ModalImage
              src={
                state.data?.result?.report_details?.results[selectedModalIndex]
                  .result_image
              }
            />
            <ModalPalette>
              {state.data?.result?.report_details?.results[
                selectedModalIndex
              ].colours.map((item2, index2) => {
                return (
                  <div key={`${index2}`}>
                    <ModalPaleeteDiv
                      style={{
                        backgroundColor: `rgb(${item2.red_value},${item2.green_value},${item2.blue_value})`,
                      }}
                    >
                      <ToolTip>{item2.colour_name}</ToolTip>
                    </ModalPaleeteDiv>
                    <ColorText>{item2?.colour_code}</ColorText>
                  </div>
                );
              })}
            </ModalPalette>
            <ModalImageDownloadButton
              onClick={() => {
                downloadSingleImageHandler(
                  state.data?.result?.report_details?.results[
                    selectedModalIndex
                  ].result_image
                );
              }}
            >
            <LanguageSelector selectedCountry={selectedCountry} text={"DOWNLOAD_IMAGE"} />
            </ModalImageDownloadButton>
          </ModalImageDiv>
          <RightArrowImage
            src={GREEN_ARROW_IMAGE}
            onClick={nextModalImageClickHandler}
          />
        </ResultBodyModal>
      </ResultModal>
    );
  }
};

export default ConsumerDetailReportResultModal;
