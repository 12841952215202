/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  CustomersTable,
  CustomersTableRow,
  CustomersTableHeaderData,
  CustomerTableBody,
  CustomerTableBodyData,
} from "../../styles/AdminCustomersStyles";
import dayjs from "dayjs";
import LanguageSelector from "../../language/LanguageSelector";

const AdminCustomerTable = ({
  customers,
  textFilterName,
  selectedFilterOption,
  setPaginationList,
  allCustomers,
  selectedPage,
  setSelectedPage
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  
  console.log(selectedPage)
  // console.log(allCustomers)
  const [requestList, setRequestList] = useState([]);
  
  useEffect(() => {
    console.log("!!!!!!!! UPDATED")
    console.log("textFilterName", textFilterName)
    console.log("selectedFilterOption", selectedFilterOption)
    let allCustomersTemp = allCustomers;
    let listCustomers = [];
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      // const filteredCustomers = allCustomersTemp?.filter(
      //   (value) =>
      //     (value.name != null && value.name.includes(textFilterName)) ||
      //     (value.email != null && value.email.includes(textFilterName))
      // ).slice(
      //   selectedPage === 1
      //     ? 0
      //     : 15 * (selectedPage - 1),
      //   15 * selectedPage
      // );
      // listCustomers=filteredCustomers
      // updatePagination(listCustomers)
      setRequestList(allCustomersTemp?.filter(
        (value) =>
          (value.name != null && value.name.includes(textFilterName)) ||
          (value.email != null && value.email.includes(textFilterName))
      ).slice(
        selectedPage === 1
          ? 0
          : 15 * (selectedPage - 1),
        15 * selectedPage
      ))
      console.log("filtered customers", filteredCustomers)
    } else {
      if (selectedFilterOption === "Latest First") {
        console.log("latest chosen******")
        // listCustomers = allCustomersTemp.slice(
        //   selectedPage === 1
        //     ? 0
        //     : 15 * (selectedPage - 1),
        //   15 * selectedPage
        // )
        // updatePagination(listCustomers)
        setRequestList(allCustomersTemp.slice(
          selectedPage === 1
            ? 0
            : 15 * (selectedPage - 1),
          15 * selectedPage
        ));
        // console.log("latest first", listCustomers)

      } else {
        console.log("oldest chosen------")
        // listCustomers = allCustomersTemp.reverse().slice(
        //   selectedPage === 1
        //     ? 0
        //     : 15 * (selectedPage - 1),
        //   15 * selectedPage
        // )
        // updatePagination(listCustomers)
        setRequestList(allCustomersTemp.reverse().slice(
          selectedPage === 1
            ? 0
            : 15 * (selectedPage - 1),
          15 * selectedPage
        ));
        allCustomersTemp.reverse();
        // console.log("oldest first", listCustomers)

      }
    }

  }, [textFilterName,selectedFilterOption, allCustomers, selectedPage]);

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      const filteredCustomers = allCustomers?.filter(
        (value) =>
          (value.name != null && value.name.includes(textFilterName)) ||
          (value.email != null && value.email.includes(textFilterName))
      );
      updatePagination(filteredCustomers);
    } else {
      updatePagination(allCustomers);
    }
  }, [textFilterName]);

  return (
    <CustomersTable>
      <CustomerTableBody>
        <CustomersTableRow>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DATE"}
            /></CustomersTableHeaderData>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NAME_DETAILS"}
            /></CustomersTableHeaderData>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EMAIL"}
            /></CustomersTableHeaderData>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTS"}
            /></CustomersTableHeaderData>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COUNTRY"}
            /></CustomersTableHeaderData>
          <CustomersTableHeaderData><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FEEDBACK"}
            /></CustomersTableHeaderData>
        </CustomersTableRow>
        {requestList &&
          requestList?.length > 0 &&
          requestList?.map((item, index) => {
            return (
              <CustomersTableRow
                key={`${index}`}
                isEven={index % 2 === 0 ? true : false}
              >
                <CustomerTableBodyData>
                  {dayjs(item.date_created).format("DD/MM/YYYY")}
                </CustomerTableBodyData>
                <CustomerTableBodyData>{item.name}</CustomerTableBodyData>
                <CustomerTableBodyData>{item.email}</CustomerTableBodyData>
                <CustomerTableBodyData>
                  {item.request_count}
                </CustomerTableBodyData>
                <CustomerTableBodyData>{item.country}</CustomerTableBodyData>
                <CustomerTableBodyData>{item.feedback}</CustomerTableBodyData>
              </CustomersTableRow>
            );
          })}
      </CustomerTableBody>
    </CustomersTable>
  );
};

export default AdminCustomerTable;
