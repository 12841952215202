import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  RecommendedProductsColumnDiv,
  BottomText,
  RecommendedProductsDiv,
  RecommendedProductsImage,
  RecommendedProductsText,
  RecommendedProductAnchor,
} from "../../styles/PreviewSupervisorResultStyles";
import LanguageSelector from "../../language/LanguageSelector";


const ResultRecommendedProducts = ({ list }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <RecommendedProductsColumnDiv>
      <BottomText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOMMENDED_PRODUCTS"}
            /></BottomText>
      {list && list.length > 0 && (
        <RecommendedProductsDiv>
          {list.map((item, index) => {
            return (
              <RecommendedProductAnchor
                key={index}
                target="_blank"
                href={item?.product_url}
              >
                {item ? (
                  <>
                    <RecommendedProductsImage src={item.product_image} />
                    <RecommendedProductsText>
                      {item.product_name}
                    </RecommendedProductsText>
                  </>
                ) : null}
              </RecommendedProductAnchor>
            );
          })}
        </RecommendedProductsDiv>
      )}
    </RecommendedProductsColumnDiv>
  );
};

export default ResultRecommendedProducts;
