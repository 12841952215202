import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  OptionContainerBody,
  PaletteImage,
  ToolTip,
  PleaseChooseText,
  OptionBodyContainer,
  OptionContainer,
  OptionText,
  PaletteListDiv,
  PaleeteReferenceThemeDiv,
} from "../../styles/PreviewSupervisorPendingRequestThemeDetailStyles";
import "../../styles/checkBox.css";
import LanguageSelector from "../../language/LanguageSelector";

const PendingRequestLeadColourDetailResults = ({
  state,
  setSelectedPaginationNumber,
  toggleResolveRequestMenu,
  rateResultFeedback,
  toggleRateResultFeedback,
  selectedOptions,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <OptionContainerBody>
      <PleaseChooseText>
      <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_THE_FOLLOWING_OPTIONS"}
            />
      </PleaseChooseText>
      <OptionContainer>
        {state.data &&
          state.data?.result?.results?.map((item, index) => {
            return (
              <OptionBodyContainer
                key={`${index}`}
                onClick={() => {
                  setSelectedPaginationNumber(index);
                  toggleResolveRequestMenu();
                  if (rateResultFeedback) {
                    toggleRateResultFeedback();
                  }
                }}
              >
                <OptionText> <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            />{` ${index + 1}`}</OptionText>
                <PaletteImage
                  isSelected={
                    selectedOptions.filter(
                      (data) => data.result_id === item.result_id
                    ).length > 0
                  }
                  alt="Palette"
                  src={item.result_image}
                />
                <PaletteListDiv>
                  {item?.colours?.map((paletteItem, paletteIndex) => {
                    return (
                      <PaleeteReferenceThemeDiv
                        style={{
                          backgroundColor: `rgb(${paletteItem.red_value},${paletteItem.green_value},${paletteItem.blue_value})`,
                        }}
                        key={`${index}${paletteIndex}`}
                      >
                        <ToolTip>{`${paletteItem.colour_name}`}</ToolTip>
                      </PaleeteReferenceThemeDiv>
                    );
                  })}
                </PaletteListDiv>
                {selectedOptions.filter(
                  (data) => data.result_id === item.result_id
                ).length > 0 && (
                  <label
                    className="container"
                    style={{
                      backgroundColor: "transparent",
                      position: "absolute",
                      top: "28px",
                      right: "-24px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked="checked"
                      onChange={(e) => true}
                    />
                    <span className="checkmark"></span>
                  </label>
                )}
              </OptionBodyContainer>
            );
          })}
      </OptionContainer>
    </OptionContainerBody>
  );
};

export default PendingRequestLeadColourDetailResults;
