import React from "react";
import {
  SuggestedPalettetext,
  SuggestedPaletteBodyDiv,
  PaleeteTitle,
  PaleetColorBodyDiv,
  PaletteColorDiv,
  PaletteColorText,
  SuggestPaletteParentDiv,
} from "../../styles/ConsumerChooseColorStyles";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerChooseColourPalette = ({ colorSuggestionList, selectedCountry }) => {
  return (
    <>
      <SuggestedPalettetext>
      <LanguageSelector selectedCountry={selectedCountry} text={"SUGGESTED_PALETTES"} />
      </SuggestedPalettetext>
      <SuggestPaletteParentDiv>
        <SuggestedPalette
          title={<LanguageSelector selectedCountry={selectedCountry} text={"NEUTRAL_COMBINATIONS"} />}
          leadColour={colorSuggestionList.lead_colour}
          colour1={colorSuggestionList.neutral_colour1}
          colour2={colorSuggestionList.neutral_colour2}
        />
        <SuggestedPalette
          title={<LanguageSelector selectedCountry={selectedCountry} text={"TONAL_COMBINATIONS"} />}
          leadColour={colorSuggestionList.lead_colour}
          colour1={colorSuggestionList.tonal_colour1}
          colour2={colorSuggestionList.tonal_colour2}
        />
        <SuggestedPalette
          title={<LanguageSelector selectedCountry={selectedCountry} text={"DESIGNERL_COMBINATIONS"} />}
          leadColour={colorSuggestionList.lead_colour}
          colour1={colorSuggestionList.designer_colour1}
          colour2={colorSuggestionList.designer_colour2}
        />
      </SuggestPaletteParentDiv>
    </>
  );
};

const SuggestedPalette = ({ title, leadColour, colour1, colour2 }) => {
  return (
    <SuggestedPaletteBodyDiv>
      <PaleeteTitle>{title}</PaleeteTitle>
      <PaleetColorBodyDiv>
        <InnerPalette color={leadColour} />
        <InnerPalette color={colour1} />
        <InnerPalette color={colour2} />
      </PaleetColorBodyDiv>
    </SuggestedPaletteBodyDiv>
  );
};

const InnerPalette = ({ color }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PaletteColorDiv
        style={{
          backgroundColor: `rgb(${color.red_value},${color.green_value},${color.blue_value})`,
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PaletteColorText style={{ fontWeight: "bold" }}>
          {color.colour_name}
        </PaletteColorText>
        <PaletteColorText>{color.colour_code}</PaletteColorText>
      </div>
    </div>
  );
};

export default ConsumerChooseColourPalette;
