import LegalBodyEnglish from "../../components/legal/LegalBodyEnglish";
import LegalBodyVietnam from "../../components/legal/LegalBodyVietnam";
import LegalBodyThailand from "../../components/legal/LegalBodyThailand";
import LegalBodyIndonesia from "../../components/legal/LegalBodyIndonesia";
import LegalBodyMalaysia from "../../components/legal/LegalBodyMalaysia";
import LegalHeader from "../../components/legal/LegalHeader";

const Legal = ({ history }) => {
  var return_text;
  let selectedCountry = history.location.state.selectedCountry;
  console.log(history.location.state.selectedCountry);
  // var
  return (
    <>
      <LegalHeader />
      {(selectedCountry?.value === "Singapore" ||
        selectedCountry?.value === "Pakistan" ||
        selectedCountry?.value === "India" ||
        selectedCountry?.value === "SriLanka") && (
        <LegalBodyEnglish></LegalBodyEnglish>
      )}
      {selectedCountry?.value === "Malaysia" && (
        <LegalBodyMalaysia></LegalBodyMalaysia>
      )}
      {selectedCountry?.value === "Indonesia" && (
        <LegalBodyIndonesia></LegalBodyIndonesia>
      )}
      {selectedCountry?.value === "Vietnam" && (
        <LegalBodyVietnam></LegalBodyVietnam>
      )}
      {selectedCountry?.value === "Thailand" && (
        <LegalBodyThailand></LegalBodyThailand>
      )}
    </>
  );
};
export default Legal;
