import styled from "styled-components";

export const ConsumerContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  height: 100px;
  @media (max-width: 1100px) {
    justify-content: space-between;
    paddingf: 0 4px;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    padding: 0;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const StepContainer = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 1vh 20vw 0 0;
  @media (max-width: 1100px) {
    margin: 10px 0;
  }
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1100px) {
    padding: 0px 20px;
  }
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const DragDropImagesContainerDiv = styled.div`
  background: #ffffff;
  border: 1px dashed #2fc38c;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin: 10vh auto;
  padding: 3vh 0px;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 40vw;
  min-width: 40vw;
  max-width: 40vw;
  opacity: ${(props) => (props.inDropZone === true ? "0.3" : "1")};

  @media (max-width: 1200px) {
    margin: 5vh auto;
    min-width: 90vw;
    max-width: 90vw;
  }
  @media (max-width: 992px) {
    margin: 5vh auto;
    min-width: 80vw;
    max-width: 80vw;
    padding: 3vh 2vw;
  }
  @media (max-width: 768px) {
    margin: 5vh auto;
    min-width: 80vw;
    max-width: 80vw;
    padding: 3vh 2vw;
  }
  @media (max-width: 576px) {
    margin: 5vh auto;
    min-width: 80vw;
    max-width: 80vw;
    padding: 3vh 2vw;
  }
`;

export const DragAndDropImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  margin-left: 2vw;
`;

export const UploadImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20vw;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ImageReductionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    margin: 2vh 0px;
  }
`;

export const InstructionsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  margin: 10px 0 0 0;
`;

export const InstructionsSubTitle = styled.a`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: teal;
  margin: 0;
  text-decoration: none;
`;

export const UploadImageButton = styled.button`
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #fff;
  background: #2fc38c;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-self: center;
  outline: none;
  padding: 4px 10px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 100px auto;
  }
`;

export const LoginToUploadButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  border: none;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
`;

export const SelectedImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10vh 0;
  position: relative;
`;

export const SelectedImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.08));

  @media (max-width: 1100px) {
    width: 100px;
    height: 100px;
  }
`;

export const SelectedImageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1vw;
  align-items: flex-start;

  @media (max-width: 1100px) {
    margin: 0 0 0 20px;
  }
`;

export const SelectedImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  margin: 0;
`;

export const SelectedImageSizeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  margin: 4px 0px;
`;

export const ChangeButton = styled.button`
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  background-color: #fff;
  display: flex;
  outline: none;
  padding: 4px 10px;
  margin: 4px 0px;
  :hover {
    cursor: pointer;
  }
`;

export const CancelImage = styled.img`
  position: absolute;
  display: flex;
  /* align-self: flex-start; */
  top: 10px;
  right: 10px;
  /* margin: 20px 60px 0 0; */ /* top: 0; */ /* right:10px; */
  /* margin:0px -20px 0 0; */ /* margin: -140px 0px 0px 0px; */
  /* width: 3vw;
    height: 3vw; */ /* } */

  @media (max-width: 1100px) {
    /* margin: 100px auto; */
  }
`;

export const NextButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 4px 40px;
  margin: 40px auto;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

export const ConsumerLoginSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConsumerLoginModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const LoginCancelDrawerImage = styled.img`
  width: 25px;
  height: 25px;
  display: flex;
  align-self: flex-end;
  margin: 30px 30px 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const LoginDrawerFooterDiv = styled.div`
  height: 10px;
  background-color: #012169;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const WelcomeBackText = styled.h4`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const EnterEmailText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #767676;
  margin: 0;
`;

export const SendOTP = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 4px 30px;
  display: flex;
  margin: 10px auto;
  :hover {
    cursor: pointer;
  }
`;

export const WelcomeBackContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OneTimeOtpText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const ConsumerOtpInputDiv = styled.form`
  display: flex;
  flex-direction: row;
  margin: 20px 0 4px 0;
  align-self: center;
`;

export const ConsumerResultOtpInput = styled.input`
  width: 15px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #000;
  margin: 0 2px;
  &:focus {
    outline-color: #2fc38c;
  }
`;

export const ConsumResultNewOtpInput = styled.input`
  height: 50px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #000;
  letter-spacing: 3px;
  /* background-image: url(BG_IMAGE);
  background-repeat: repeat-x; */
  width: 256px;
  margin: 0 2px;
  &:focus {
    outline-color: #2fc38c;
  }
`;

export const ResendOtpButton = styled.button`
  font-family: "Colour-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #c4c4c4;
  outline: none;
  border: none;
  background-color: #fff;
  /* margin: 0 170px 0px 0; */
  :hover {
    cursor: pointer;
  }
`;

export const VerifyEmailButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  display: flex;
  margin: 20px auto;
  padding: 2px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const UploadImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
  text-align: center;
  margin: 4vh 0 0 0;
`;

export const AppropriateTextStyle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #545850;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 10vh 0;
`;

export const ContactUsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #545850;
  margin: 1vh 0;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const ContactUsHeader = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #545850;
  margin-top: 5vh;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const ContactUsDiv = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 10vh 0;
`;

export const SituationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0px 20vw;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    padding: 0 10px;
  }
`;
