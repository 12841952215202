import amarisServer from "../api/amarisServer";
import createDataContext from "./createDataContext";
import {
  COLOUR_THEME_PALETTE_API,
  FAMILY_COLOUR_API,
  PREVIEW_SUPERVISOR_ADD_USER_API,
  PREVIEW_SUPERVISOR_DASHBOARD_SUMMARY_API,
  PREVIEW_SUPERVISOR_MY_TEAM,
  PREVIEW_SUPERVISOR_REASSIGN_REQUEST_API,
  PREVIEW_SUPERVISOR_REPORT_API,
  PREVIEW_SUPERVISOR_REQUEST_LIST_API,
  PREVIEW_SUPERVISOR_RESOLVE_REQUEST_API,
  PREVIEW_SUPETRVISOR_RESULT_API,
  FAMILY_COLOUR_SUGGESTION_API,
  COLOURIZATION_REQUEST_REPORT_DOWNLOAD_API,
  PS_PAINTER_LIST,
  PS_PRODUCT_LIST,
  PS_SHOP_LIST,
  SOMETHING_WENT_WRONG,
  TEAM_LIST_API,
  TOKEN_NAME,
  PS_RAW_IMG,
  PS_UPLOAD_EDITED_IMG,
  COLOR_SWAP_EXTRACT,
  COLOR_SWAP_CHANGE,
  AUTO_COLOR_PICKER,
  USER_LOCATION_INFO_API,
  ADMIN_ARCHIVE_API,
  PS_CONSUMER_LIST_DOWNLOAD,
  SEARCH_COLOUR_API,
  PS_EDIT_PALETTE
} from "../utils/Constants";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const previewSupervisorReducer = (state, action) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        ...{ loading: false },
        ...{ errorMessage: action.payload },
      };
    case "previewSupervisorRequestList":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "reassignRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "resolveRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "resultRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "requestReport":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "previewStaffRequestList":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "previewSupervisorAddUser":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "previewSupervisorSelector":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "familyColour":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "rawimage":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "uploadEditedImage":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "userLocationInfo":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "previewArchiveRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "searchColour":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "autoColorPicker":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "inProgress":
      return { ...state, ...{ loading: true }, ...{ errorMessage: "" } };
    default:
      return state;
  }
};

const requestReport = (dispatch) => {
  return async ({
    request_id,
    requestReportSuccessCallback,
    requestReportFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REPORT_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          if (response.data.request_details?.colourization_type === "Theme") {
            amarisServer
              .post(
                COLOUR_THEME_PALETTE_API,
                {
                  theme_name: response.data.request_details?.theme_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourPaletteResponse) => {
                if (colourPaletteResponse.status === 200) {
                  dispatch({
                    type: "requestReport",
                    payload: {
                      result: response.data,
                      palette: colourPaletteResponse.data,
                    },
                  });
                  requestReportSuccessCallback();
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });
                  requestReportFailureCallback(
                    colourPaletteResponse.data.detail.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourPaletteError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                requestReportFailureCallback(
                  colourPaletteError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } else {
            dispatch({
              type: "requestReport",
              payload: { result: response.data },
            });
            requestReportSuccessCallback();
          }
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          requestReportFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        requestReportFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const resultRequest = (dispatch) => {
  return async ({
    request_id,
    resultRequestSuccessCallback,
    resultRequestFailureCallback,
    isTheme,
    isLead
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPETRVISOR_RESULT_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {

        if (response.status === 200 && response.data.status_code === 1100) {
          if (isTheme) {
            amarisServer
              .post(
                COLOUR_THEME_PALETTE_API,
                {
                  theme_name: response.data.request_detail.theme_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourPaletteResponse) => {
                if (colourPaletteResponse.status === 200) {
                  resultRequestSuccessCallback(colourPaletteResponse.data);
                  dispatch({
                    type: "resultRequest",
                    payload: {
                      result: response.data,
                      palette: colourPaletteResponse.data,
                    },
                  });
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });

                  resultRequestFailureCallback(
                    colourPaletteResponse?.data?.detail?.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourPaletteError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });

                resultRequestFailureCallback(
                  colourPaletteError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } if (isLead) {
            amarisServer
              .post(
                FAMILY_COLOUR_SUGGESTION_API,
                {
                  colour_name: response.data.request_detail.colour_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourSuggestionResponse) => {
                if (colourSuggestionResponse.status === 200) {
                  resultRequestSuccessCallback(colourSuggestionResponse.data);
                  dispatch({
                    type: "resultRequest",
                    payload: {
                      result: response.data,
                      colourSuggestion: colourSuggestionResponse.data,
                    },
                  });
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });

                  resultRequestFailureCallback(
                    colourSuggestionResponse?.data?.detail?.status_message ||
                    SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourSuggestionError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });

                resultRequestFailureCallback(
                  colourSuggestionError?.response?.data?.detail?.message ||
                  SOMETHING_WENT_WRONG
                );
              });
          } else {
            dispatch({ type: "resultRequest", payload: response.data });
            resultRequestSuccessCallback();
          }
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          resultRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        resultRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const resolveRequest = (dispatch) => {
  return async ({
    request_id,
    results,
    shop_id,
    product_id,
    painter_id,
    resolveRequestSuccessCallback,
    resolveRequestFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    // let resultIdArray = [];

    // console.log(resultIdArray[0]);

    // for (var i = 0; i < results.length; i++) {
    //   resultIdArray.push(results[i].result_id);
    // }
    // const payload = resultIdArray.reduce(
    //   (acc, el, index) => ({ ...acc, [`result_id${index + 1}`]: el }),
    //   {}
    // );
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_RESOLVE_REQUEST_API,
        {
          request_id,
          result_id1: results[0].result_id,
          result_id2: results[1] !== undefined ? results[1].result_id : null,
          result_id3: results[2] !== undefined ? results[2].result_id : null,
          shop_id,
          product_id,
          painter_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({ type: "resolveRequest", payload: response.data });
          resolveRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          resolveRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        resolveRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const reassignRequest = (dispatch) => {
  return async ({
    request_id,
    email,
    reassignRequestFailureCallback,
    reassignRequestSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REASSIGN_REQUEST_API,
        {
          request_id,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({ type: "reassignRequest", payload: response.data });
          reassignRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          reassignRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        reassignRequestFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewStaffRequestList = (dispatch) => {
  return async ({
    country,
    email,
    requestListFailureCallback,
    requestListSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REQUEST_LIST_API,
        {
          country,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "previewStaffRequestList",
            payload: response.data,
          });
          requestListSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          requestListFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        requestListFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewSupervisorRequestList = (dispatch) => {
  return async ({
    country,
    requestListFailureCallback,
    requestListSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_REQUEST_LIST_API,
        {
          country,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "previewSupervisorRequestList",
            payload: response.data,
          });
          requestListSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          requestListFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        requestListFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewSupervisorTeam = (dispatch) => {
  return async ({ country, previewSupervisorTeamSuccessCallback }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        TEAM_LIST_API,
        { country },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          previewSupervisorTeamSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          // alert(response?.data?.detail?.status_message || SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        // alert(error.response.data.detail.message || SOMETHING_WENT_WRONG);
      });
  };
};

const previewSupervisorAddUser = (dispatch) => {
  return async ({
    email,
    name,
    country,
    user_type,
    previewSupervisorSuccessCallback,
    previewSupervisorFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_ADD_USER_API,
        {
          email,
          name,
          country,
          user_type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "previewSupervisorAddUser",
            payload: response.data,
          });
          previewSupervisorSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          previewSupervisorFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        previewSupervisorFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const getConsumerLocationInfo = (dispatch) => {
  return async ({
    email,
    getConsumerLocationInfoSuccessCallback,
    getConsumerLocationInfoFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        USER_LOCATION_INFO_API,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status_code === 1100) {
          getConsumerLocationInfoSuccessCallback(response.data);
        } else {
          getConsumerLocationInfoFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        getConsumerLocationInfoFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewSupervisorDashboardSummary = (dispatch) => {
  return async ({
    previewSupervisorDashboardSuccessCallback,
    previewSupervisorDashboardFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_DASHBOARD_SUMMARY_API,
        {
          country: decodedToken?.country,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status_code === 1100) {
          previewSupervisorDashboardSuccessCallback(response.data);
        } else {
          previewSupervisorDashboardFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        previewSupervisorDashboardFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewSupervisorMyTeam = (dispatch) => {
  return async ({
    previewSupervisorMyTeamSuccessCallback,
    previewSupervisorMyTeamFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        PREVIEW_SUPERVISOR_MY_TEAM,
        {
          country: decodedToken?.country,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status_code === 1100) {
          console.log("%%%%%%%", response.data, response)
          previewSupervisorMyTeamSuccessCallback(response.data);
        } else {
          previewSupervisorMyTeamFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        previewSupervisorMyTeamFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewSupervisorSelector = (dispatch) => {
  return async ({
    country,
    shop_id,
    product_id ,
    previewSupervisorSelectorCallback,
    previewSupervisorSelectorFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    const decodedToken = token !== undefined ? jwt_decode(token) : false;
    amarisServer
      .post(
        PS_PRODUCT_LIST,
        {
          country: country,
          product_id : product_id ,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((productListResponse) => {
        if (productListResponse.data.status_code === 1100) {
          amarisServer
            .post(
              PS_SHOP_LIST,
              {
                country: country,
                shop_id: shop_id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((shopListResponse) => {
              if (shopListResponse.data.status_code === 1100) {
                amarisServer
                  .post(
                    PS_PAINTER_LIST,
                    {
                      country: country,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((painterListResponse) => {
                    if (painterListResponse.data.status_code === 1100) {
                      dispatch({
                        type: "previewSupervisorSelector",
                      });
                      previewSupervisorSelectorCallback(
                        productListResponse.data.products,
                        shopListResponse.data.shops,
                        painterListResponse.data.painters
                      );
                    } else {
                      dispatch({
                        type: "error",
                        payload: SOMETHING_WENT_WRONG,
                      });
                      previewSupervisorSelectorFailureCallback(
                        painterListResponse?.data?.detail?.status_message ||
                        SOMETHING_WENT_WRONG
                      );
                    }
                  })
                  .catch((error) => {
                    dispatch({
                      type: "error",
                      payload: SOMETHING_WENT_WRONG,
                    });
                    previewSupervisorSelectorFailureCallback(
                      error?.response?.data?.detail?.message ||
                      SOMETHING_WENT_WRONG
                    );
                  });
              } else {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                previewSupervisorSelectorFailureCallback(
                  shopListResponse?.data?.detail?.status_message ||
                  SOMETHING_WENT_WRONG
                );
              }
            })
            .catch((error) => {
              dispatch({
                type: "error",
                payload: SOMETHING_WENT_WRONG,
              });
              previewSupervisorSelectorFailureCallback(
                error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
              );
            });
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          previewSupervisorSelectorFailureCallback(
            productListResponse?.data?.detail?.status_message ||
            SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        previewSupervisorSelectorFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const familyColour = (dispatch) => {
  return async ({
    colour_family,
    familyColourSuccessCallback,
    familyColorFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        FAMILY_COLOUR_API,
        { colour_family },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "familyColour",
            payload: response.data,
          });
          familyColourSuccessCallback(response.data.colours);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          familyColorFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        familyColorFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const adminReportDownload = (dispatch) => {
  return async ({
    request_id,
    adminReportDownloadSuccessCallback,
    adminReportDownloadFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(COLOURIZATION_REQUEST_REPORT_DOWNLOAD_API,
        {
          request_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        })
      .then((response) => {
        console.log("response",response)
        adminReportDownloadSuccessCallback(response.data);
      })
      .catch((error) => {
        adminReportDownloadFailureCallback(JSON.stringify(error));
      });
  };
};

const getRawImage = (dispatch) => {
  return async ({
    result_id,
    getRawImageSuccessCallback,
    getRawImageFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PS_RAW_IMG,
        {
          result_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "getRawImage",
            payload: response.data,
          });
          getRawImageSuccessCallback(response.data.image);
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          getRawImageFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        getRawImageFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const uploadEditedImage = (dispatch) => {
  return async ({
    result_image,
    result_id,
    request_id,
    uploadEditedImageSuccessCallback,
    uploadEditedImageFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PS_UPLOAD_EDITED_IMG,
        {
          result_image,
          result_id,
          request_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "uploadEditedImage",
            payload: response.data,
          });
          uploadEditedImageSuccessCallback(response.data.image);
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          uploadEditedImageFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        uploadEditedImageFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const colorSwapExtract = (dispatch) => {
  return async ({
    image,
    imageType,
    numK,
    height,
    width,
    colorSwapExtractFailureCallback,
    colorSwapExtractSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        COLOR_SWAP_EXTRACT,
        {
          image,
          imageType,
          numK,
          height,
          width
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "colorSwapExtract", payload: response.data, });
          //console.log(response.data.colors);
          colorSwapExtractSuccessCallback(response.data.colors);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          colorSwapExtractFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        colorSwapExtractFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewArchiveRequest = (dispatch) => {
  return async ({
    request_id,
    previewArchiveRequestSuccessCallback,
    previewArchiveRequestFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        ADMIN_ARCHIVE_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "previewArchiveRequest",
            payload: response.data,
          });
          previewArchiveRequestSuccessCallback();
          // alert(response.data.message);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          previewArchiveRequestFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        previewArchiveRequestFailureCallback(
          error?.response?.data?.detail?.status_code === 1201 ? "Error with data" : SOMETHING_WENT_WRONG
        );
      });
  };
};


const colorSwapChange = (dispatch) => {
  return async ({
    image,
    imageType,
    height,
    width,
    colorFrom,
    colorTo,
    colorSwapChangeFailureCallback,
    colorSwapChangeSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        COLOR_SWAP_CHANGE,
        {
          image,
          imageType,
          height,
          width,
          colorFrom,
          colorTo
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "colorSwapChange", payload: response.data, });
          console.log(response.data);
          colorSwapChangeSuccessCallback(response.data.outputImage);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          colorSwapChangeFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        colorSwapChangeFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const autoColorPicker = (dispatch) => {
  return async ({
    image,
    autoColorPickerFailureCallback,
    autoColorPickerSuccessCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        AUTO_COLOR_PICKER,
        {
          image,
          is_url: false,
          num_colors: 5,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "autoColorPicker", payload: response.data, });
          console.log(response.data);
          autoColorPickerSuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });

          autoColorPickerFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });

        autoColorPickerFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const previewRequestsExcelDownload = (dispatch) => {
  return async ({
    country,
    previewRequestsExcelSuccessCallback,
    previewRequestsExcelFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(PS_CONSUMER_LIST_DOWNLOAD, 
        {
          country : country
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        responseType: "arraybuffer",
      })
      .then((response) => {
        previewRequestsExcelSuccessCallback(response.data);
      })
      .catch((error) => {
        previewRequestsExcelFailureCallback(JSON.stringify(error));
      });
  };
};

const searchColour = (dispatch) => {
  return async ({
    colour_name,
    searchColourSuccessCallback,
    searchColourFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        SEARCH_COLOUR_API,
        { colour_name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "searchColour",
            payload: response.data,
          });
          searchColourSuccessCallback(response.data.colour);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          searchColourFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        searchColourFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const editColourPalette = (dispatch) => {
  return async ({
    result_id,
    colour_code1,
    colour_code2,
    colour_code3,
    colour_code4,
    colour_code5,
    editColourPaletteSuccessCallback,
    editColourPaletteFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        PS_EDIT_PALETTE,
        {result_id,
        colour_code1,
        colour_code2,
        colour_code3,
        colour_code4,
        colour_code5},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "editColourPalette",
            payload: response.data,
          });
          editColourPaletteSuccessCallback(response.data.colour);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          editColourPaletteFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        editColourPaletteFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};


export const { Provider, Context } = createDataContext(
  previewSupervisorReducer,
  {
    previewSupervisorRequestList,
    reassignRequest,
    resolveRequest,
    resultRequest,
    previewStaffRequestList,
    requestReport,
    previewSupervisorTeam,
    previewSupervisorAddUser,
    previewSupervisorDashboardSummary,
    previewSupervisorMyTeam,
    getConsumerLocationInfo,
    previewSupervisorSelector,
    familyColour,
    adminReportDownload,
    getRawImage,
    uploadEditedImage,
    colorSwapExtract,
    colorSwapChange,
    previewArchiveRequest,
    previewRequestsExcelDownload,
    searchColour,
    editColourPalette,
    autoColorPicker
  },
  []
);
