import styled from "styled-components";

export const AdminCustomerBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const AdminCustomerTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  align-items: center;
`;

export const AdminCustomersImage = styled.img``;

export const AdminCustomersText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 4px;
`;

export const HoirzontalStatsDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 0;
`;

export const SearchBarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  margin: 0 20px 0 0;
`;

export const SearchBarBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
`;

export const SearchBarInputDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 180px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const SearchFilterDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 160px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  padding: 0px 4px;
  :hover {
    cursor: pointer;
  }
`;

export const FilterText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #545850;
  margin: 0 20px;
`;

export const SearchBarInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  width: 150px;
`;

export const FilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 40px 0 0;
  align-self: flex-end;
`;

export const DropDownParentDiv = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const DropDownDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
`;

export const DropDownText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0 10px;
`;

export const DropDownContent = styled.div`
  display: ${(props) => (props.show === true ? "flex" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropDownContentText = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 4px 10px;
  outline: none;
  border: none;
  background: transparent;
`;

export const HoirzontalCustomerDivider = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
  margin: 10px 40px;
`;

export const CustomersTable = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  width: 94%;
  @media (max-width: 1100px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const CustomersTableHead = styled.thead``;

export const CustomersTableRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const CustomersTableHeaderData = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
  align-self: center;
`;

export const CustomerTableBody = styled.tbody``;

export const CustomerTableBodyData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  padding: 10px 0px;
`;

export const PaginationNumberDiv = styled.button`
  background-color: #f1f1f1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  color: ${(props) => (props.isSelected === true ? "#2FC38C" : "#012169")};
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  :hover {
    cursor: pointer;
  }
`;

export const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
  /* padding: 0 14vw; */
  margin: 20px auto;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const PaginationArrowContainer = styled.div`
  width : 40px;
  height : 20px;
  color : #ffffff;
`;

export const PaginationLeftImage = styled.img`
  padding: 0 10px;
  display: 'none';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  :hover {
    cursor: pointer;
  }
`;

export const PaginationRightImage = styled.img`
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    margin: 0px;
  }
`;

export const DownloadButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  padding: 5px 20px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`;

export const AdminCustomerSearchRequestModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

export const AdminCustomerSearchRequestSideBarContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

export const AdminCustomerSearchRequestHeader = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
`;

export const AdminCustomerRequestsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const ByCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
  margin: 20px 0 8px 0;
`;

export const AdminCustomerSearchRequestCountryDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    align-items: flex-start;
    margin: 10px;
  }
`;

export const SelectedCountryCheck = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const AdminCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0px 10px;
`;

export const RangerInputSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  padding: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2fc38c;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2fc38c;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }
`;

export const RangeProgressBar = styled.div`
  width: ${(props) => `${props.sliderValue}%`};
  height: 10px;
  background: #012169;
  border-radius: 5px;
  position: absolute;
  top: 6px;
  left: 0px;
  padding: 0;
  margin: 0;
`;

export const RangeNumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RangeNumber = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
`;

export const ButtonBar = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

export const ResetFilterButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 10px;
  outline: none;
  margin: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  background: #2fc38c;
  border-radius: 5px;
  padding: 5px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const SearchBarBodyCustomerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 40px;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const AdminConsumerOtpSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OneTimeOtpText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
`;

export const AdminConsumerOtpInputDiv = styled.form`
  display: flex;
  flex-direction: row;
  margin: 20px 0 4px 0;
  align-self: center;
`;

export const AdminConsumResultNewOtpInput = styled.input`
  height: 50px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #000;
  letter-spacing: 3px;
  /* background-image: url(BG_IMAGE);
  background-repeat: repeat-x; */
  width: 256px;
  margin: 0 2px;
  &:focus {
    outline-color: #2fc38c;
  }
`;

export const ResendOtpButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #c4c4c4;
  outline: none;
  border: none;
  background-color: #fff;
  /* margin: 0 170px 0px 0; */
  :hover {
    cursor: pointer;
  }
`;

export const VerifyEmailButton = styled.button`
  background: ${(props) => (props.isDisabled ? "grey" : "#2fc38c")};
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  display: flex;
  margin: 20px auto;
  padding: 2px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const AdminConsumerOTPModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
