import styled from "styled-components";

export const ConsumerBrowseColourContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  @media (max-width: 1100px) {
    height: auto;
  }
`;

export const SituationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  margin: auto;
  align-self: center;
  @media (max-width: 1100px) {
    margin: 0 20px;
    width: 100%;
  }
`;

export const SituationContainer = styled.button`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  outline: none;
  border: none;
  align-items: center;
  padding-top: 5vh;
  height: ${(props) => (props.isComingSoon ? "30vh" : "31vh")};
  width: 12vw;
  padding-left: 0;
  padding-right: 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 100%;
    margin: 10px 10px;
  }
`;

export const BrowseSituationContainer = styled.button`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  outline: none;
  border: none;
  align-items: center;
  padding-top: 5vh;
  height: ${(props) => (props.isComingSoon ? "30vh" : (props.isImageTypeOption ? "35vh" : "31vh" ))};
  width: ${(props) => (props.isImageTypeOption ? "15vw" : "12vw")};
  padding-left: 0;
  padding-right: 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 100%;
    margin: 10px 10px;
    height: 30vh;
  }
`;

export const Spacer = styled.div`
  margin: 0px 20px;
`;

export const NextButton = styled.button`
  background-color: #2fc38c;
  border-radius: 5px;
  border: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding: 4px 10px;
  width: 10vw;
  margin: 20px auto;
  outline: none;
`;
