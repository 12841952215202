import PrivacyPolicyBodyEnglish from "../../components/privacy-policy/PrivacyPolicyBodyEnglish";
import PrivacyPolicyBodyVietnam from "../../components/privacy-policy/PrivacyPolicyBodyVietnam";
import PrivacyPolicyBodyThailand from "../../components/privacy-policy/PrivacyPolicyBodyThailand";
import PrivacyPolicyBodyMalaysia from "../../components/privacy-policy/PrivacyPolicyBodyMalaysia";
import PrivacyPolicyBodyIndonesia from "../../components/privacy-policy/PrivacyPolicyBodyIndonesia";
import PrivacyPolicyHeader1 from "../../components/privacy-policy/PrivacyPolicyHeader1";

const PrivacyPolicy = ({ history }) => {
  var return_text;
  let selectedCountry = history.location.state.selectedCountry;
  console.log(history.location.state.selectedCountry);

  return (
    <>
      <PrivacyPolicyHeader1 />
      {(selectedCountry?.value === "Singapore" ||
        selectedCountry?.value === "Pakistan" ||
        selectedCountry?.value === "India" ||
        selectedCountry?.value === "SriLanka") && (
        <PrivacyPolicyBodyEnglish></PrivacyPolicyBodyEnglish>
      )}
      {selectedCountry?.value === "Malaysia" && (
        <PrivacyPolicyBodyMalaysia></PrivacyPolicyBodyMalaysia>
      )}
      {selectedCountry?.value === "Indonesia" && (
        <PrivacyPolicyBodyIndonesia></PrivacyPolicyBodyIndonesia>
      )}
      {selectedCountry?.value === "Vietnam" && (
        <PrivacyPolicyBodyVietnam></PrivacyPolicyBodyVietnam>
      )}
      {selectedCountry?.value === "Thailand" && (
        <PrivacyPolicyBodyThailand></PrivacyPolicyBodyThailand>
      )}
    </>
  );
};

export default PrivacyPolicy;
