import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ResolveRequestSideBar,
  CancelImage,
  MyTeamSideBarHeaderDiv,
  MyTeamSideBearHeaderImage,
  MyTeamSideBarHeaderText,
  PaginationRightImage,
  PaginationLeftImage,
  ResolveRequestModalDiv,
} from "../../styles/PreviewSupervisorDashboardStyles";
import styled from "styled-components";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
// import PAGINATION_RIGHT_IMAGE from "../../images/pagination_right.svg";
// import PAGINATION_LEFT_IMAGE from "../../images/pagination_left.svg";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardResolveRequestSidebar = ({
  resolveRequestMenu,
  toggleResolveRequestMenu,
  touchUphandler,
  selectedPaginationNumber,
  setSelectedPaginationNumber,
  optionList,
  setSelectedOptions,
  selectedOptions,
  toggleRateResultFeedback,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const paginationButtonClickHandler = (data) => {
    setSelectedPaginationNumber(data);
  };

  const checkedItemHandler = (item) => {
    if (
      selectedOptions.filter((data) => data.result_id === item.result_id)
        .length > 0
    ) {
      setSelectedOptions(
        selectedOptions.filter((data) => data.result_id !== item.result_id)
      );
    } else {
      setSelectedOptions((oldArray) => [...oldArray, item]);
    }
  };

  const doneClickHandler = () => {
    toggleResolveRequestMenu();
  };

  const incrementPaginationCount = () => {
    if (selectedPaginationNumber < optionList.length - 1) {
      setSelectedPaginationNumber(selectedPaginationNumber + 1);
    }
  };

  const decrementPaginationCount = () => {
    if (selectedPaginationNumber > 0) {
      setSelectedPaginationNumber(selectedPaginationNumber - 1);
    }
  };

  const showfeedbacksidebar = false;

  if (width < 1100) {
    return (
      <ResolveRequestModalDiv openSideMenu={resolveRequestMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleResolveRequestMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <SubTitleText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_FROM_AI_GENERATED"}
            />
        </SubTitleText>
        <OptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            /> {selectedPaginationNumber + 1}</OptionText>
        <LandingImage
          src={optionList && optionList[selectedPaginationNumber]?.result_image}
          alt="Landing"
        />
        <PaginationDiv>
          <PaginationLeftImage
            src={BLACK_ARROW_IMAGE}
            alt="Previous"
            onClick={decrementPaginationCount}
          />
          {optionList &&
            optionList.map((item, index) => {
              return (
                <PaginationButton
                  key={`${index}${selectedPaginationNumber}`}
                  isSelected={selectedPaginationNumber === index}
                  onClick={() => paginationButtonClickHandler(index)}
                >
                  {index + 1}
                </PaginationButton>
              );
            })}
          <PaginationRightImage
            src={BLACK_ARROW_IMAGE}
            alt="Next"
            onClick={incrementPaginationCount}
          />
        </PaginationDiv>
        <OptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PALETTE"}
            /></OptionText>
        <PaletteContainer>
          {optionList &&
            optionList[selectedPaginationNumber]?.colours?.map(
              (item, index) => {
                return (
                  <div key={`${index}`}>
                    <PaletteDiv
                      style={{
                        backgroundColor: `rgb(${item?.red_value},${item.green_value},${item?.blue_value})`,
                      }}
                    >
                      <ToolTip>{item.colour_name}</ToolTip>
                    </PaletteDiv>
                    <ColorText>{item?.colour_code}</ColorText>
                  </div>
                );
              }
            )}
        </PaletteContainer>
        <Footer>
          <ResultDiv>
            <img
              key={selectedPaginationNumber}
              src={
                selectedOptions.filter(
                  (data) =>
                    data.result_id ===
                    optionList[selectedPaginationNumber]?.result_id
                ).length > 0
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={() => {
                checkedItemHandler(optionList[selectedPaginationNumber]);
              }}
            />
            <ChooseAsResultText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_THIS_AS_RESULT"}
            /></ChooseAsResultText>
          </ResultDiv>
          <ButtonBar>
            {/* <TouchUpButton disabled onClick={touchUphandler}>
              Touch-up
            </TouchUpButton> */}
            <DoneButton onClick={doneClickHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
          </ButtonBar>
        </Footer>
      </ResolveRequestModalDiv>
    );
  } else {
    return (
      <ResolveRequestSideBar openSideMenu={resolveRequestMenu}>
        <CancelImage
          src={CANCEL_IMAGE}
          onClick={toggleResolveRequestMenu}
          alt="Cancel"
        />
        <MyTeamSideBarHeaderDiv>
          <MyTeamSideBearHeaderImage src={MY_TEAM_BLACK_IMAGE} alt="My Team" />
          <MyTeamSideBarHeaderText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVE_REQUEST"}
            /></MyTeamSideBarHeaderText>
        </MyTeamSideBarHeaderDiv>
        <SubTitleText>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_FROM_AI_GENERATED"}
            />
        </SubTitleText>
        <OptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OPTION"}
            /> {selectedPaginationNumber + 1}</OptionText>
        <LandingImage
          src={optionList && optionList[selectedPaginationNumber]?.result_image}
          alt="Landing"
        />
        <PaginationDiv>
          <PaginationLeftImage
            src={BLACK_ARROW_IMAGE}
            alt="Previous"
            onClick={decrementPaginationCount}
          />
          {optionList &&
            optionList.map((item, index) => {
              return (
                <PaginationButton
                  key={`${index}${selectedPaginationNumber}`}
                  isSelected={selectedPaginationNumber === index}
                  onClick={() => paginationButtonClickHandler(index)}
                >
                  {index + 1}
                </PaginationButton>
              );
            })}
          <PaginationRightImage
            src={BLACK_ARROW_IMAGE}
            alt="Next"
            onClick={incrementPaginationCount}
          />
        </PaginationDiv>
        <OptionText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PALETTE"}
            /></OptionText>
        <PaletteContainer>
          {optionList &&
            optionList[selectedPaginationNumber]?.colours?.map(
              (item, index) => {
                return (
                  <div key={`${index}`}>
                    <PaletteDiv
                      style={{
                        backgroundColor: `rgb(${item?.red_value},${item.green_value},${item?.blue_value})`,
                      }}
                    >
                      <ToolTip>{item.colour_name}</ToolTip>
                    </PaletteDiv>
                    <ColorText>{item?.colour_code}</ColorText>
                  </div>
                );
              }
            )}
        </PaletteContainer>
        {showfeedbacksidebar == true && (
        <RateDiv>
          <RateResultButton onClick={toggleRateResultFeedback}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RATE_THIS_RESULT"}
            />
          </RateResultButton>
        </RateDiv>)}
        <Footer>
          <ResultDiv>
            <img
              key={selectedPaginationNumber}
              src={
                selectedOptions.filter(
                  (data) =>
                    data.result_id ===
                    optionList[selectedPaginationNumber]?.result_id
                ).length > 0
                  ? GREEN_CHECKBOX_CHECKED_IMAGE
                  : GREEN_CHECKBOX_UNCHECKED_IMAGE
              }
              alt="Checkbox"
              style={{ width: "20px", height: "20px" }}
              onClick={() => {
                checkedItemHandler(optionList[selectedPaginationNumber]);
              }}
            />
            <ChooseAsResultText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CHOOSE_THIS_AS_RESULT"}
            /></ChooseAsResultText>
          </ResultDiv>
          <ButtonBar>
            <TouchUpButton
              onClick={() => {
                touchUphandler(
                  optionList[selectedPaginationNumber]?.result_image,
                  optionList[selectedPaginationNumber]?.result_id,
                  selectedPaginationNumber
                );
              }}
            >
              <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TOUCH_UP"}
            />
            </TouchUpButton>
            <DoneButton onClick={doneClickHandler}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DONE"}
            /></DoneButton>
          </ButtonBar>
        </Footer>
      </ResolveRequestSideBar>
    );
  }
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 25px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;


const RateResultButton = styled.a`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #2FC38C;
  margin: 0 4px;
  cursor: pointer;
`;

const RateDiv = styled.div`
display: flex;
justify-content: space-between;
margin: 20px 25px;
@media (max-width: 1100px) {
  flex-direction: column;
}
`;


const ResultDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

const TouchUpButton = styled.button`
  outline: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 20px;
  @media (max-width: 1100px) {
    margin: 10px;
  }
  :hover {
    cursor: pointer;
  }
`;

const DoneButton = styled.button`
  outline: none;
  border: none;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  padding: 5px 40px;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

const ChooseAsResultText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0 4px;
`;

const PaletteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3px 25px;
`;

const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const ColorText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin: 2px auto;
  text-align: center;
`;

const PaletteDiv = styled.div`
  border-radius: 3px;
  width: 55px;
  height: 55px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  margin: 2px 12px;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

const SubTitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  margin: 4px 25px;
`;

const OptionText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 10px 25px 4px 25px;
`;

const LandingImage = styled.img`
  /* width: 300px;
  height: 300px; */
  align-self: center;
  margin: 10px 0;
  width: 90%;
  border-radius: 10px;
`;

const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
`;

export const PaginationButton = styled.button`
  background: #f1f1f1;
  outline: none;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  color: ${(props) => (props.isSelected === true ? "#012169" : "#2FC38C")};
  margin: 0px 4px;
  border-radius: 50%;
`;

export default PreviewSupervisorDashboardResolveRequestSidebar;
