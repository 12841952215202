import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  RecommendedProductsBodyDiv,
  RecommendedProductsText,
  RecommendedProductsDiv,
  RecommendedProductCard,
  RecommendedProductImage,
  RecommendedProductText,
  SelectDiv,
  SelectCheckBox,
  SelectCheckBoxText,
  PaginationLeftImage,
  PaginationRightImage,
  PaginationArrowContainer
} from "../../styles/PreviewSupervisorSelectorStyles";
import GREEN_CHECKBOX_UNCHECKED_IMAGE from "../../images/green_checkbox_unchecked.svg";
import GREEN_CHECKBOX_CHECKED_IMAGE from "../../images/green_checkbox_checked.svg";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorSelectorRecommendedProducts = ({
  setSelectedProducts,
  selectedProducts,
  productList,
  incrementProduct,
  decrementProduct
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  return (
    <RecommendedProductsBodyDiv>
      <RecommendedProductsText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOMMENDED_PRODUCTS"}
            /></RecommendedProductsText>
      <RecommendedProductsDiv>
        {productList.map((item, index) => {
          return (
            <RecommendedProductCard key={`${index}`}>
              <RecommendedProductImage
                alt="Recommended"
                src={item.product_image}
                onClick={() => {
                  window.open(item.product_url, "_blank");
                }}
              />
              <RecommendedProductText
                onClick={() => {
                  window.open(item.product_url, "_blank");
                }}
              >
                {item.product_name}
              </RecommendedProductText>
              <SelectDiv>
                <SelectCheckBox
                  alt="CheckBox"
                  src={
                    selectedProducts.includes(item.product_id)
                      ? GREEN_CHECKBOX_CHECKED_IMAGE
                      : GREEN_CHECKBOX_UNCHECKED_IMAGE
                  }
                  onClick={() => {
                    if (selectedProducts.includes(item.product_id)) {
                      setSelectedProducts(
                        selectedProducts.filter(
                          (filteredItem) => filteredItem !== item.product_id
                        )
                      );
                    } else {
                      setSelectedProducts((oldArray) => [
                        ...oldArray,
                        item.product_id,
                      ]);
                    }
                  }}
                />
                <SelectCheckBoxText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SELECT"}
            /></SelectCheckBoxText>
              </SelectDiv>
            </RecommendedProductCard>
          );
        })}
      </RecommendedProductsDiv>
      <div style={{display:"flex", flexDirection:"row"}}>
      <PaginationArrowContainer>
        <PaginationLeftImage
        onClick={decrementProduct}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination left"
        />
      </PaginationArrowContainer>
      <PaginationArrowContainer>
        <PaginationRightImage
        onClick={incrementProduct}
        src={BLACK_ARROW_IMAGE}
        alt="Pagination right"
        />
      </PaginationArrowContainer>
      </div>
    </RecommendedProductsBodyDiv>
  );
};

export default PreviewSupervisorSelectorRecommendedProducts;
