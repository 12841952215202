import React, { useContext, useState } from "react";
import ConsumerImageOptionHeader from "../../components/consumer-image-options/ConsumerImageOptionHeader";
import ConsumerSituationContainer from "../../components/consumer-image-options/ConsumerSituationContainer";
import {
  ConsumerBrowseColourContainer,
  SituationDiv,
  Spacer,
} from "../../styles/ConsumerBrowseColourStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_CHOOSE_COLOUR_ROUTE,
  CONSUMER_DULUX_THEME_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import LIVING_ROOM_IMAGE from "../../images/living-room.svg";
import PAINT_BUCKET_IMAGE from "../../images/paint-bucket.svg";
import { ColourizationContext } from "../../context/ColourizationContext";
import BackButton from "../../components/BackButton";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerBrowseColour = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const { width } = useWindowDimensions();

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const goToDuluxTheme = () => {
    history.push(CONSUMER_DULUX_THEME_ROUTE);
  };

  const colourizationContext = useContext(ColourizationContext);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const consumerChooseColourHandler = () => {
    history.push(CONSUMER_CHOOSE_COLOUR_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <ConsumerBrowseColourContainer applyFilter={openLogoutMenu}>
        <ConsumerImageOptionHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        <SituationDiv>
          <ConsumerSituationContainer
            image={LIVING_ROOM_IMAGE}
            text={
              <>
                Choose a colour
                <br />
                &nbsp;
              </>
            }
            isComingSoon={false}
            clickHandler={consumerChooseColourHandler}
          />
          {width > 1100 && <Spacer />}
          <ConsumerSituationContainer
            image={PAINT_BUCKET_IMAGE}
            text={
              <>
                Explore Dulux
                <br />
                Themes
              </>
            }
            isComingSoon={false}
            clickHandler={goToDuluxTheme}
          />
        </SituationDiv>
        <div></div>
      </ConsumerBrowseColourContainer>
    </>
  );
};

export default ConsumerBrowseColour;
