import React from "react";
import {
  AnalyticsRequestContainer,
  AnalyticsRequestNumberContainer,
  AnalyticsRequestImage,
  AnalyticsRequestNumberText,
  AnalyticsRequestText,
} from "../../styles/AdminDashboardStyles";

const AdminDashboardAnalyticsStats = ({ image, number, title }) => {
  return (
    <AnalyticsRequestContainer>
      <AnalyticsRequestNumberContainer>
        <AnalyticsRequestImage src={image} alt="Request" />
        <AnalyticsRequestNumberText>{number}</AnalyticsRequestNumberText>
      </AnalyticsRequestNumberContainer>
      <AnalyticsRequestText>{title}</AnalyticsRequestText>
    </AnalyticsRequestContainer>
  );
};

export default AdminDashboardAnalyticsStats;
