import React, { useState, useRef, useEffect } from "react";
import {
  HOME_PAGE_ROUTE,
  ID_CHOOSE_PRODUCT_URL,
  ID_EXPERT_HELP_URL,
  ID_FIND_COLOUR_URL,
  ID_FIND_STORE_URL,
  ID_IDEAS_URL,
  INDONESIA_HOME_PAGE_ROUTE,
  MALAYSIA_HOME_PAGE_ROUTE,
  ML_CHOOSE_PRODUCT_URL,
  ML_EXPERT_HELP_URL,
  ML_FIND_COLOUR_URL,
  ML_FIND_STORE_URL,
  ML_IDEAS_URL,
  SG_CHOOSE_PRODUCT_URL,
  SG_EXPERT_HELP_URL,
  SG_FIND_COLOUR_URL,
  SG_FIND_STORE_URL,
  SG_IDEAS_URL,
  SINGAPORE_HOME_PAGE_ROUTE,
} from "../../utils/Constants";
import Select from "react-select";
import {
  Header,
  DeluxePreviewServiceImage,
  AkzoNobelContainer,
  AkzonobelImage,
  HeaderOptions,
  HeaderOptionsText,
  HeaderLoginButton,
  HeaderLoginText,
  CountryText,
  HamburgerHeader,
} from "../../styles/HomeStyles";
import "../../styles/header.css";
import { Link } from "react-router-dom";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import AKZONOBEL_IMAGE from "../../images/akzonobel.png";
import DROP_DOWN_IMAGE from "../../images/drop_down.svg";
import LOGIN_IMAGE from "../../images/login.svg";
import HAMBURGER_IMAGE from "../../images/hamburger_menu.svg";

const InternalHomeHeader = ({
  toggleSideMenu,
  setSelectedCountry,
  selectedCountry,
  showLogin,
  width,
  history,
}) => {
  const options = [
    { value: "Singapore", label: "Singapore" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Indonesia", label: "Indonesia" },
  ];

  const selectedCountryChange = (selectedOption) => {
    if (selectedOption.value === "Singapore") {
      history.push(SINGAPORE_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Malaysia") {
      history.push(MALAYSIA_HOME_PAGE_ROUTE);
    } else if (selectedOption.value === "Indonesia") {
      history.push(INDONESIA_HOME_PAGE_ROUTE);
    }
    setSelectedCountry(selectedOption);
  };

  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => setShowMenu((previousState) => !previousState);

  const ref = useRef(null);
  // const handleScroll = () => {
  //   if (ref.current) {
  //     setSticky(ref.current.getBoundingClientRect().top <= 0);
  //   }
  // };

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  const findColourClickHandler = () => {
    if (selectedCountry.value === "Singapore") {
      window.open(SG_FIND_COLOUR_URL, "_blank");
    } else if (selectedCountry.value === "Malaysia") {
      window.open(ML_FIND_COLOUR_URL, "_blank");
    } else if (selectedCountry.value === "Indonesia") {
      window.open(ID_FIND_COLOUR_URL, "_blank");
    }
  };

  const chooseProductClickHandler = () => {
    if (selectedCountry.value === "Singapore") {
      window.open(SG_CHOOSE_PRODUCT_URL, "_blank");
    } else if (selectedCountry.value === "Malaysia") {
      window.open(ML_CHOOSE_PRODUCT_URL, "_blank");
    } else if (selectedCountry.value === "Indonesia") {
      window.open(ID_CHOOSE_PRODUCT_URL, "_blank");
    }
  };

  const ideasClickHandler = () => {
    if (selectedCountry.value === "Singapore") {
      window.open(SG_IDEAS_URL, "_blank");
    } else if (selectedCountry.value === "Malaysia") {
      window.open(ML_IDEAS_URL, "_blank");
    } else if (selectedCountry.value === "Indonesia") {
      window.open(ID_IDEAS_URL, "_blank");
    }
  };

  const expertHelpClickHandler = () => {
    if (selectedCountry.value === "Singapore") {
      window.open(SG_EXPERT_HELP_URL, "_blank");
    } else if (selectedCountry.value === "Malaysia") {
      window.open(ML_EXPERT_HELP_URL, "_blank");
    } else if (selectedCountry.value === "Indonesia") {
      window.open(ID_EXPERT_HELP_URL, "_blank");
    }
  };

  const findStoreClickHandler = () => {
    if (selectedCountry.value === "Singapore") {
      window.open(SG_FIND_STORE_URL, "_blank");
    } else if (selectedCountry.value === "Malaysia") {
      window.open(ML_FIND_STORE_URL, "_blank");
    } else if (selectedCountry.value === "Indonesia") {
      window.open(ID_FIND_STORE_URL, "_blank");
    }
  };

  if (width < 1100) {
    return (
      <div>
        <Header
          className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
          ref={ref}
        >
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview Service "
            />
          </Link>
          <div style={{ display: "flex" }}>
            <HeaderLoginButton onClick={toggleMenu}>
              <img
                src={HAMBURGER_IMAGE}
                alt="Login"
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  objectFit: "fill",
                }}
              />
            </HeaderLoginButton>
          </div>
        </Header>
        {showMenu && (
          <HamburgerHeader>
            <HeaderOptionsText onClick={findColourClickHandler}>
              Find a colour
            </HeaderOptionsText>
            <HeaderOptionsText onClick={chooseProductClickHandler}>
              Choose a product
            </HeaderOptionsText>
            <HeaderOptionsText onClick={ideasClickHandler}>
              Ideas
            </HeaderOptionsText>
            <HeaderOptionsText onClick={expertHelpClickHandler}>
              Expert Help
            </HeaderOptionsText>
            <HeaderOptionsText onClick={findStoreClickHandler}>
              Find a store
            </HeaderOptionsText>
          </HamburgerHeader>
        )}
      </div>
    );
  } else {
    return (
      // <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
      <Header
        className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
        ref={ref}
      >
        <Link to={HOME_PAGE_ROUTE}>
          <DeluxePreviewServiceImage
            src={DELUXE_PREVIEW_SERVICE_IMAGE}
            alt="Deluxe Preview Service "
          />
        </Link>
        <div style={{ flex: 1 }}>
          <AkzoNobelContainer>
            <CountryText>Country</CountryText>
            <div style={{ width: "8vw" }}>
              <Select
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => (
                    <img
                      src={DROP_DOWN_IMAGE}
                      alt="Country select"
                      style={{ paddingRight: "5px" }}
                    />
                  ),
                }}
                value={selectedCountry}
                onChange={selectedCountryChange}
                options={options}
                styles={{
                  singleValue: (styles) => ({
                    color: "#012169",
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#012169",
                    minHeight: 28,
                    height: 28,
                    cursor: "pointer",
                  }),
                  option: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#012169",
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <AkzonobelImage src={AKZONOBEL_IMAGE} alt="Akzonobel" />
          </AkzoNobelContainer>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <HeaderOptions>
              <HeaderOptionsText onClick={findColourClickHandler}>
                Find a colour
              </HeaderOptionsText>
              <HeaderOptionsText onClick={chooseProductClickHandler}>
                Choose a product
              </HeaderOptionsText>
              <HeaderOptionsText onClick={ideasClickHandler}>
                Ideas
              </HeaderOptionsText>
              <HeaderOptionsText onClick={expertHelpClickHandler}>
                Expert Help
              </HeaderOptionsText>
              <HeaderOptionsText onClick={findStoreClickHandler}>
                Find a store
              </HeaderOptionsText>
            </HeaderOptions>
            <div
              style={{
                flex: "0.55",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {showLogin && (
                <HeaderLoginButton onClick={toggleSideMenu}>
                  <img src={LOGIN_IMAGE} alt="Login" />
                  <HeaderLoginText>Login</HeaderLoginText>
                </HeaderLoginButton>
              )}
            </div>
          </div>
        </div>
      </Header>
      // </div>
    );
  }
};

export default InternalHomeHeader;
