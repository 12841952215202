import React from "react";
import { Link } from "react-router-dom";
import {
  CookieContainer,
  CookieHeader,
  DeluxePreviewServiceImage,
  CookiePolicyHeaderText,
  CookieBody,
  CookiePolicyText,
  InformationText,
  BodyText,
  BoldBodyText,
  BoldBodySpan,
  TableData,
  TableHeader,
  Table,
  TableDataLink,
} from "../../styles/CookieStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";

const CookieBodyThailand = () => {
  return (
    <CookieContainer>
      <CookieBody>
        <CookiePolicyHeaderText>นโยบายการใช้คุกกี้</CookiePolicyHeaderText>
        <CookiePolicyText>นโยบายการใช้คุกกี้</CookiePolicyText>
        <InformationText>ข้อมูลเกี่ยวกับการใช้คุกกี้ของเรา</InformationText>
        <BodyText>
          เว็บไซต์ของเราใช้คุกกี้เพื่อทำให้คุณพบกับความแตกต่างจากผู้ใช้อื่น ๆ
          ของเว็บไซต์ของเรา ทั้งนี้เพื่อช่วยให้เราสามารถส่งมอบประสบการณ์
          ที่ดีเมื่อคุณติดตามเนื้อหาในเว็บไซต์ของเราและยังช่วยให้เราในการปรับปรุงเว็บไซต์ของเราอย่างต่อเนื่อง
          เมื่อคุณใช้งานเว็บไซต์ของเรา ถือว่าคุณได้ยินยอมให้เราใช้คุกกี้
          <br />
          <br />
          คุกกี้เป็นไฟล์ขนาดเล็กประกอบด้วยตัวอักษรและตัวเลขที่เราติดตั้งไว้ในเบราว์เซอร์หรือฮาร์ดไดรฟ์ของเครื่องคอมพิวเตอร์ของคุณหากคุณยินยอม
          คุกกี้มีข้อมูลที่จะถูกถ่ายโอนไปยังฮาร์ดไดรฟ์ของคอมพิวเตอร์ของคุณ
          <br />
          <br />
          เราใช้คุกกี้ดังต่อไปนี้:
        </BodyText>
        <ul>
          <li>
            <BoldBodyText>
              คุกกี้ที่มีความจำเป็นอย่างสูงต่อการทำงานของเวปไซด์:{" "}
            </BoldBodyText>
            <BoldBodySpan>
              คุกกี้เหล่านี้เป็นคุกกี้ที่จำเป็นต่อการปฎิบัติการของเว็บไซต์
              ที่รวมถึง ตัวอย่างเช่น
              ซึ่งช่วยให้คุณสามารถเข้าสู่ระบบความปลอดภัยของเว็บไซต์, ใช้เมนู
              Shopping Cart หรือใช้บริการ E-Billing ได้.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>คุกกี้ที่ใช้ในการวิเคราะห์: </BoldBodyText>
            <BoldBodySpan>
              คุกกี้เหล่านี้ช่วยให้เราสามารถรับรู้จำนวนผู้เข้าชมและเข้าใจเส้นทางที่ผู้เข้าชมเลือกและใช้ฟังก์ชันใดบ้างระหว่างการเยี่ยมชมเว็บไซต์
              ซึ่งช่วยให้เราปรับปรุงวิธีการทำงานเว็บไซต์
              เพื่อให้มั่นใจว่าผู้เข้าชมเว็บไซต์สามารถค้นหาสิ่งที่ต้องการได้ง่าย
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>
              คุกกี้ที่รู้ถึงตัวตนของผู้ที่เข้าเยี่ยมชมเวปไซด์:{" "}
            </BoldBodyText>
            <BoldBodySpan>
              คุกกี้เหล่านี้จะช่วยให้เราทราบเมื่อคุณกลับมาที่เว็บไซต์
              ซึ่งช่วยให้เราสามารถปรับแต่งเนื้อหาสำหรับคุณ
              หรือต้อนรับคุณด้วยชื่อและการตั้งค่าเดิมของคุณ (เช่น
              การแนะนำตัวเลือกสำหรับภาษาหรือประเทศท้องถิ่น).
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>
              คุกกี้เพื่อการปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย:{" "}
            </BoldBodyText>
            <BoldBodySpan>
              คุกกี้เหล่านี้บันทึกการเข้าชมเว็บไซต์นี้ของคุณ,
              หน้าเว็บที่คุณได้เยี่ยมชมและลิงค์ที่พาคุณเข้ามา
              เราจะใช้ข้อมูลนี้เพื่อให้เว็บไซต์และโฆษณาของเราปรากฏอยู่บนเว็บเพจที่ตรงกับความสนใจของคุณมากขึ้น
              นอกจากนี้เรายังอาจแบ่งปันข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ดังกล่าว
            </BoldBodySpan>
          </li>
        </ul>
        <BodyText>
          คุณสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้และวัตถุประสงค์การใช้งานคุกกี้แต่ละรายการจากตารางด้านล่าง
        </BodyText>

        <Table>
          <thead>
            <tr>
              <TableHeader>ชื่อคุกกี้</TableHeader>
              <TableHeader>ชื่อแอพพลิเคชันที่เชื่อมต่อ</TableHeader>
              <TableHeader>วัตถุประสงค์</TableHeader>
              <TableHeader>ข้อมูลเพิ่มเติม</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableData>__utma</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                ใช้สำหรับการวิเคราะห์ส่วนของผู้ใช้งานกับส่วนต่างๆของเวปไซด์
                คุกกี้จะถูกสร้างเมื่อ จาวาสคริปต์ถูกใช้งานและไม่มีคุกกี้ _utma
                แสดงอยู่ คุกกี้นี้จะถูกเปลี่ยนให้เป็นคุกกี้ล่าสุด
                ทุกครั้งที่ถูกส่งไปยัง Google Analytics
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  ข้อมูลการใช้คุกกี้บนเวปไซต์ จาก Google Analytics
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  ข้อมูลความเป็นส่วนตัว จาก Google Analytics
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmb</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                ใช้สำหรับการวิเคราะห์การเข้าเยี่ยมชมเวปไซต์ครั้งใหม่
                คุกกี้จะถูกสร้างเมื่อ จาวาสคริปต์ถูกใช้งานและไม่มีคุกกี้ _utmb
                แสดงอยู่ คุกกี้นี้จะถูกเปลี่ยนให้เป็นคุกกี้ล่าสุด
                ทุกครั้งที่ถูกส่งไปยัง Google Analytics
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  ข้อมูลการใช้คุกกี้บนเวปไซต์ จาก Google Analytics
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  ข้อมูลความเป็นส่วนตัว จาก Google Analytics
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmc</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                ไม่ถูกใช้ใน ga.js. ถูกตั้งมาเพื่อใช้ทำงานร่วมกับurchin.js.
                เมื่อก่อน คุกกี้นี้ทำงานร่วมกับคุกกี้__utmb
                เพื่อตรวจว่าผู้ใช้งานเวปไซต์อยู่ใน session ใหม่
                หรือเป็นการเข้าเยี่ยมครั้งใหม่หรือไม่
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  ข้อมูลการใช้คุกกี้บนเวปไซต์ จาก Google Analytics
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  ข้อมูลความเป็นส่วนตัว จาก Google Analytics
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmz</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                จัดเก็บข้อมูลแหล่งหรือแคมเปญที่นำมาผู้ใช้งานมาสู่เวปไซต์
                คุกกี้จะถูกสร้างเมื่อ จาวาสคริปต์ถูกใช้งาน
                คุกกี้นี้จะถูกเปลี่ยนให้เป็นคุกกี้ล่าสุด ทุกครั้งที่ถูกส่งไปยัง
                Google Analytics
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  ข้อมูลการใช้คุกกี้บนเวปไซต์ จาก Google Analytics
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  ข้อมูลความเป็นส่วนตัว จาก Google Analytics
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>__utmv</TableData>
              <TableData>Google Analytics</TableData>
              <TableData>
                ใช้สำหรับจัดเก็บข้อมูลผู้เข้าเยี่ยมชมแบบแปรผัน
                คุกกี้จะถูกสร้างเมื่อ ผู้พัฒนาใช้ วิธี_setCustomVar
                จัดเก็บข้อมูลผู้เข้าเยี่ยมชมแบบแปรผัน คุกกี้นี้ถูกใช้ในวิธี
                _setVar (ระบบสนับสนุนแต่ไม่เป็นวิธีที่แนะนำ).
                คุกกี้นี้จะถูกเปลี่ยนให้เป็นคุกกี้ล่าสุด ทุกครั้งที่ถูกส่งไปยัง
                Google Analytics
              </TableData>
              <TableData>
                <TableDataLink
                  target="_blank"
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                >
                  ข้อมูลการใช้คุกกี้บนเวปไซต์ จาก Google Analytics
                </TableDataLink>
                <br /> <br />
                <TableDataLink
                  target="_blank"
                  href="https://marketingplatform.google.com/about/"
                >
                  ข้อมูลความเป็นส่วนตัว จาก Google Analytics
                </TableDataLink>
              </TableData>
            </tr>

            <tr>
              <TableData>message</TableData>
              <TableData>Facebook</TableData>
              <TableData>
                คุกกี้นี้จะถูกใช้เพื่อระบุว่าผู้เข้าชมเวปไซต์เป็นผู้ล็อกอินเข้ามาหรือไม่
                มันมี token ที่สามารถใช้รับรู้ถึงสถานะผู้เข้าชมเวปไซต์
              </TableData>
              <TableData></TableData>
            </tr>

            <tr>
              <TableData>akzo-dulux</TableData>
              <TableData>Website</TableData>
              <TableData>
                คุกกี้นี้จะถูกใช้ในการเก็บสถานะผู้เข้าชมเวปไซต์ในส่วนของ
                Scrapbook เมื่อไม่ได้ล็อกอิน
              </TableData>
              <TableData></TableData>
            </tr>

            <tr>
              <TableData>cc_cookie_accept</TableData>
              <TableData>Cookie Acceptance</TableData>
              <TableData>
                คุกกี้นี้ถูกใช้ในการเก็บสถานะว่าผู้เข้าชมเวปไซต์ยอมรับเงื่อนไขการใช้คุกกี้หรือไม่
              </TableData>
              <TableData></TableData>
            </tr>
          </tbody>
        </Table>

        <BodyText>
          โปรดทราบว่าบุคคลที่สาม (ได้แก่
          เครือข่ายผู้จัดทำโฆษณาและผู้ให้บริการภายนอก เช่น
          ผู้ให้บริการการวิเคราะห์การเข้าชมเว็บไซต์)
          อาจจะใช้คุกกี้รายการอื่นใดที่มิได้อยู่ในการควบคุมของเรา
          คุกกี้เหล่านี้มีแนวโน้มที่จะเป็นเชิงวิเคราะห์และเพื่อการปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย
          <br />
          <br />
          คุณสามารถบล็อคคุกกี้โดยการเปิดใช้การตั้งค่าเบราว์เซอร์ของคุณโดยคุณสามารถที่จะบล็อคการตั้งค่าของคุกกี้ทั้งหมดหรือบางรายการ
          แต่ถ้าคุณใช้การตั้งค่าเบราว์เซอร์ของคุณเพื่อบล็อคคุกกี้ทั้งหมด
          (รวมทั้งคุกกี้ที่จำเป็นอย่างสูงสำหรับการทำงานของเวปไซด์)
          คุณอาจจะไม่สามารถเข้าถึงเนื้อหาบางส่วนหรือทั้งหมดของเว็บไซต์ของเราได้
          <br />
          <br />
          คุกกี้จะหมดอายุภายใน 2 ปี
          ยกเว้นคุกกี้ที่จำเป็นอย่างสูงสำหรับการทำงานของเวปไซด์
        </BodyText>
      </CookieBody>
    </CookieContainer>
  );
};

export default CookieBodyThailand;
