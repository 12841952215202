/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  ConsumerHomeContainer,
  ConsumerHomeHeader,
  DeluxePreviewServiceImage,
  ConsumerHomeBodyContainer,
  ConsumerHomeBodyImage,
  ConsumerHomeBodySideDiv,
  ConsumerHomeBodyImageVietnamWallOwner,
  ConsumerHomeBodyImageVietnamPainter,
  ConsumerHomeBodyImageVietnamRetailer,
  ConsumerHomeBodyImageVietnamEmployee,
  ConsumerHomeNameVietnam,
  LogoutButton,
} from "../../styles/ConsumerHomeStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import {
  CONSUMER_IMAGE_OPTIONS_ROUTE,
  HOME_PAGE_ROUTE,
  CONSUMER_DETAIL_ROUTE,
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  TOKEN_NAME,
  CONSUMER_CHOOSE_IMAGE_TYPE_ROUTE,
} from "../../utils/Constants";
import CONSUMER_HOME_IMAGE from "../../images/consumer_home.svg";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { ColourizationContext } from "../../context/ColourizationContext";
import { Link } from "react-router-dom";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import ConsumerHomeCard from "../../components/consumer-home/ConsumerHomeCard";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import HomeSocialMedia from "../../components/home/HomeSocialMedia";
import HomeSubFooter from "../../components/home/HomeSubFooter";
import HomeFooter from "../../components/home/HomeFooter";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerHome = ({ history, selectedCountry }) => {
  // const userImagesList = ["1", "2", "3", "4", "5"];
  const { state, requestHistory } = useContext(ConsumerContext);
  const colourizationContext = useContext(ColourizationContext);
  const value = colourizationContext.colourizationDetails;
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { width } = useWindowDimensions();

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country")); // countryValue is an object
  console.log(decodedToken.country);
  const imageUploadOptions = () => {
    history.push(CONSUMER_IMAGE_OPTIONS_ROUTE);
  };

  const goToReportDetail = (data) => {
    history.push(CONSUMER_DETAIL_ROUTE, {
      item: data,
    });
    window.location.reload()
  };

  const reColourAgainHandler = () => {
    history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
    // DISABLED INDIA CHANGE
    // if (decodedToken.country === "India") {
    //   history.push(CONSUMER_CHOOSE_IMAGE_TYPE_ROUTE);
    // } else {
    //   history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
    // }
  };

  const requestHistorySuccessCallback = () => {};

  const requestHistoryFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  useEffect(() => {
    requestHistory({
      email: value?.email || decodedToken?.email,
      requestHistorySuccessCallback,
      requestHistoryFailureCallback,
    });
  }, []);

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue
        }
      />
      <ConsumerHomeContainer applyFilter={openLogoutMenu}>
        <ConsumerHomeHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          {decodedToken && (
            <LogoutButton onClick={toggleLogoutMenu}>
              <LanguageSelector
               selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
                text={"LOGOUT"}
              />{" "}
            </LogoutButton>
          )}
        </ConsumerHomeHeader>
        <ConsumerHomeBodyContainer>
          <ConsumerHomeCard
            value={value}
            decodedToken={decodedToken}
            state={state}
            reColourAgainHandler={reColourAgainHandler}
            imageUploadOptions={imageUploadOptions}
            goToReportDetail={goToReportDetail}
            errorMessage={errorMessage}
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
          />
          {decodedToken.customer_type === "Wall Owner" && width > 1100 ? (
            <>
              <ConsumerHomeBodySideDiv>
                <ConsumerHomeBodyImageVietnamWallOwner
                  src={CONSUMER_HOME_IMAGE}
                  alt="Home"
                />
                <ConsumerHomeNameVietnam>
                  <LanguageSelector
                    selectedCountry={
                      decodedToken
                        ? {
                            value: decodedToken?.country,
                            label: decodedToken?.country,
                          }
                        : countryValue
                    }
                    text={"WALL_OWNER"}
                  />
                </ConsumerHomeNameVietnam>
              </ConsumerHomeBodySideDiv>
            </>
          ) : decodedToken.customer_type === "Painter" && width > 1100 ? (
            <>
              <ConsumerHomeBodySideDiv>
                <ConsumerHomeBodyImageVietnamPainter
                  src={CONSUMER_HOME_IMAGE}
                  alt="Home"
                />
                <ConsumerHomeNameVietnam>
                  <LanguageSelector
                    selectedCountry={
                      decodedToken
                        ? {
                            value: decodedToken?.country,
                            label: decodedToken?.country,
                          }
                        : countryValue
                    }
                    text={"PAINTER"}
                  />
                </ConsumerHomeNameVietnam>
              </ConsumerHomeBodySideDiv>
            </>
          ) : decodedToken.customer_type === "Retailer" && width > 1100 ? (
            <>
              <ConsumerHomeBodySideDiv>
                <ConsumerHomeBodyImageVietnamRetailer
                  src={CONSUMER_HOME_IMAGE}
                  alt="Home"
                />
                <ConsumerHomeNameVietnam>
                  <LanguageSelector
                    selectedCountry={
                      decodedToken
                        ? {
                            value: decodedToken?.country,
                            label: decodedToken?.country,
                          }
                        : countryValue
                    }
                    text={"RETAILER"}
                  />
                </ConsumerHomeNameVietnam>
              </ConsumerHomeBodySideDiv>
            </>
          ) : decodedToken.customer_type === "Employee" && width > 1100 ? (
            <>
              <ConsumerHomeBodySideDiv>
                <ConsumerHomeBodyImageVietnamEmployee
                  src={CONSUMER_HOME_IMAGE}
                  alt="Home"
                />
                <ConsumerHomeNameVietnam>
                  <LanguageSelector
                    selectedCountry={
                      decodedToken
                        ? {
                            value: decodedToken?.country,
                            label: decodedToken?.country,
                          }
                        : countryValue
                    }
                    text={"EMPLOYEE"}
                  />
                </ConsumerHomeNameVietnam>
              </ConsumerHomeBodySideDiv>
            </>
          ) : width > 1100 ? (
            <ConsumerHomeBodyImage src={CONSUMER_HOME_IMAGE} alt="Home" />
          ) : null}
        </ConsumerHomeBodyContainer>

        <HomeSocialMedia
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
        />
        <HomeSubFooter
          width={width}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
        />
        <HomeFooter
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          history={history}
        />
      </ConsumerHomeContainer>
    </>
  );
};

export default ConsumerHome;
