import CookieBodyEnglish from "../../components/cookie/CookieBodyEnglish";
import CookieBodyVietnam from "../../components/cookie/CookieBodyVietnam";
import CookieBodyThailand from "../../components/cookie/CookieBodyThailand";
import CookieBodyIndonesia from "../../components/cookie/CookieBodyIndonesia";

import CookieHeader1 from "../../components/cookie/CookieHeader";

const Cookie = ({ history }) => {
  var return_text;
  let selectedCountry = history.location.state.selectedCountry;
  console.log(history.location.state.selectedCountry);
  // var
  return (
    <>
      <CookieHeader1 />
      {(selectedCountry?.value === "Singapore" ||
        selectedCountry?.value === "Pakistan" ||
        selectedCountry?.value === "India" ||
        selectedCountry?.value === "Malaysia" ||
        selectedCountry?.value === "SriLanka") && (
        <CookieBodyEnglish></CookieBodyEnglish>
      )}

      {selectedCountry?.value === "Indonesia" && (
        <CookieBodyIndonesia></CookieBodyIndonesia>
      )}
      {selectedCountry?.value === "Vietnam" && (
        <CookieBodyVietnam></CookieBodyVietnam>
      )}
      {selectedCountry?.value === "Thailand" && (
        <CookieBodyThailand></CookieBodyThailand>
      )}
    </>
  );
};

export default Cookie;
