import React from "react";
import { Link } from "react-router-dom";
import {
  CookieContainer,
  CookieHeader,
  DeluxePreviewServiceImage,
  CookiePolicyHeaderText,
  CookieBody,
  CookiePolicyText,
  InformationText,
  BodyText,
  BoldBodyText,
  BoldBodySpan,
  TableData,
  TableHeader,
  Table,
  TableDataLink,
} from "../../styles/CookieStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";

const CookieBodyVietnam = () => {
  return (
    <CookieContainer>
      <CookieBody>
        <CookiePolicyHeaderText>Chính sách về Cookie</CookiePolicyHeaderText>
        <CookiePolicyText>
          Đây là trang thông tin Cookie cho trang web này ("Trang web"). Để tìm
          hiểu thêm về cách chúng tôi xử lý dữ liệu cá nhân của bạn khi bạn truy
          cập Trang web của chúng tôi hoặc liên hệ với AkzoNobel, vui lòng tham
          khảo Tuyên bố bảo mật của chúng tôi.
        </CookiePolicyText>
        <InformationText>Cookie là gì?</InformationText>
        <BodyText>
          Để phục vụ bạn tốt hơn khi truy cập một trang web, hầu hết các trang
          web đều sử dụng cookie; các công nghệ thực tế thu thập và sử dụng
          thông tin, làm cho các trang web dễ sử dụng hơn. Cookie là các đoạn
          văn bản được đặt trong ổ cứng máy tính của bạn khi bạn truy cập Trang
          web. Trong khi bạn truy cập, cookie sẽ gửi dữ liệu đến AkzoNobel.
        </BodyText>
        <br />
        <InformationText>Cookie có tác dụng gì?</InformationText>
        <BodyText>
          Ví dụ: chúng tôi sử dụng cookie để xác định xem bạn đã từng ghé thăm
          chúng tôi trước đây chưa hay bạn là khách mới truy cập. Cookie cũng
          giúp chúng tôi nâng cao trải nghiệm trực tuyến của bạn khi truy cập
          Trang web của chúng tôi.
        </BodyText>
        <br />
        <InformationText>
          Đồng ý sử dụng cookie trên trang web của chúng tôi
        </InformationText>
        <BodyText>
          Khi bạn truy cập Trang web của chúng tôi, sẽ có một cửa sổ bật lên
          trên màn hình của bạn, thông báo về việc đặt cookie trong ổ cứng máy
          tính của bạn. Thông báo xuất hiện sẽ giải thích ngắn gọn cho bạn về
          chính sách cookie của chúng tôi. Bằng cách nhấp vào “Accept Cookies”
          (Chấp nhận Cookie), bạn chấp nhận các cookie được chỉ định trong Chính
          sách về cookie này. Bạn cũng có thể nhấp vào “Cookies Settings” (Cài
          đặt cookie) để thiết lập tùy chọn của bạn đối với cookie.
        </BodyText>
        <br />
        <InformationText>
          Các loại cookie chúng tôi có thể sử dụng và mục đích sử dụng chúng
        </InformationText>
        <BodyText>
          AkzoNobel sử dụng nhiều loại cookie. Chúng tôi tin rằng điều quan
          trọng là bạn cần phải biết AkzoNobel dùng cookie để thu thập dữ liệu
          gì, cũng như công nghệ của chúng tôi sử dụng cookie nào và chúng được
          sử dụng cho mục đích gì. Vui lòng xem thông tin tổng quan ở bên dưới
          để biết về loại cookie có thể được sử dụng.
        </BodyText>
        <br />
        <ul>
          <li>
            <BoldBodyText>Các cookie cực kỳ cần thiết</BoldBodyText>
            <BoldBodySpan>
              Những cookie này là vô cùng cần thiết để cung cấp các chức năng cơ
              bản của Trang web và không thể tắt được trong hệ thống của chúng
              tôi. Chúng cung cấp dịch vụ bạn yêu cầu trên Trang web và ghi nhớ
              các cài đặt cookie của bạn. Bạn có thể thiết đặt trình duyệt của
              mình để chặn hoặc thông báo cho bạn về các cookie này, nhưng như
              vậy thì một số phần của trang web sẽ không hoạt động được. Những
              cookie này không lưu trữ bất kỳ thông tin nhận dạng cá nhân nào.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Cookie hiệu suất</BoldBodyText>
            <BoldBodySpan>
              Chúng tôi sử dụng cookie hiệu suất để đếm lượt truy cập và nguồn
              lưu lượng truy cập nhằm đo lường và cải thiện hiệu suất trang web
              của mình. Cookie hiệu suất giúp chúng tôi hiểu được cách khách
              truy cập vào Trang web của mình, cách họ sử dụng Trang web như thế
              nào và trong bao lâu, cũng như cách chúng tôi có thể cải thiện
              trải nghiệm của họ. Tất cả thông tin mà các cookie này thu thập
              được đều được tổng hợp và ẩn danh. Chúng tôi sử dụng các cookie
              này để kiểm tra các thiết kế và tính năng khác nhau trên các Trang
              web, đồng thời chúng tôi cũng sử dụng chúng để theo dõi cách khách
              truy cập vào Trang web của chúng tôi. Nếu bạn không cho phép các
              cookie này, chúng tôi sẽ không biết thời điểm bạn truy cập Trang
              web và sẽ không thể theo dõi hoạt động của trang web.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Cookie chức năng </BoldBodyText>
            <BoldBodySpan>
              Những cookie này cho phép Trang web cung cấp khả năng cá nhân hóa
              và chức năng nâng cao. Chúng có thể được thiết đặt bởi chúng tôi
              hoặc bởi các nhà cung cấp bên thứ ba sở hữu dịch vụ mà chúng tôi
              đã thêm vào các trang của mình. Những cookie này giúp chúng tôi
              quảng cáo và thu thập thông tin về thói quen duyệt web của bạn để
              làm cho nội dung và quảng cáo của chúng tôi phù hợp với bạn và sở
              thích của bạn nhất có thể. Chúng tôi cũng sử dụng các cookie này
              để đo lường hiệu quả của các chiến dịch quảng cáo bằng cách theo
              dõi số lượng nhấp chuột. Chúng ghi nhớ các trang web bạn truy cập
              và sử dụng thông tin này để cung cấp cho bạn quyền truy cập vào
              nội dung phù hợp trên Trang web của chúng tôi, đồng thời hiển thị
              cho bạn các quảng cáo được điều chỉnh phù hợp với bạn hơn khi bạn
              truy cập các trang web khác. Nếu bạn không cho phép các cookie
              này, thì một số hoặc tất cả các dịch vụ trên Trang web này có thể
              không hoạt động bình thường.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Cookie nhắm đến đối tượng </BoldBodyText>
            <BoldBodySpan>
              Các cookie này có thể do các đối tác quảng cáo thiết đặt thông qua
              Trang web của chúng tôi. Các công ty đó có thể sử dụng chúng để
              tạo dựng hồ sơ về sở thích của bạn và hiển thị cho bạn các quảng
              cáo có liên quan trên các trang web khác. Chúng không lưu trữ trực
              tiếp thông tin cá nhân mà dựa trên việc nhận dạng duy nhất trình
              duyệt và thiết bị internet của bạn. Nếu bạn không cho phép các
              cookie này, bạn sẽ gặp ít quảng cáo được nhắm đối tượng hơn.
            </BoldBodySpan>
          </li>
          <li>
            <BoldBodyText>Cookie mạng xã hội</BoldBodyText>
            <BoldBodySpan>
              Các cookie này được thiết đặt bởi một loạt các dịch vụ truyền
              thông xã hội mà chúng tôi đã thêm vào Trang web để giúp bạn chia
              sẻ nội dung của chúng tôi với bạn bè và mạng lưới quan hệ của bạn.
              Các dịch vụ truyền thông xã hội này có thể sử dụng cookie để xem
              bạn đã đăng nhập hay chưa và cho phép bạn "thích" nội dung hoặc
              chuyển tiếp nội dung đó cho bạn bè của mình. Chúng có thể theo dõi
              trình duyệt của bạn trên các trang web khác và tạo hồ sơ sở thích
              của bạn. Tùy thuộc vào cài đặt của bạn, các bên thứ ba này có thể
              sử dụng thông tin của bạn vào các mục đích riêng của họ, chẳng hạn
              như quảng cáo. Điều này có thể ảnh hưởng đến nội dung và thông báo
              bạn thấy trên các trang web khác mà bạn truy cập. Nếu bạn muốn tìm
              hiểu thêm về cách các dịch vụ truyền thông xã hội này sử dụng dữ
              liệu, vui lòng tham khảo thông báo bảo mật của các nền tảng này.
              Nếu bạn không cho phép các cookie này, bạn có thể không sử dụng
              được hoặc không thấy được các công cụ chia sẻ này.
            </BoldBodySpan>
          </li>
        </ul>
        <InformationText>
          Các cách khác giúp kiểm soát, vô hiệu hóa và xóa cookie
        </InformationText>
        <BodyText>
          Bạn có thể thay đổi các cài đặt để chặn cookie hoặc để cảnh báo bạn
          khi cookie được gửi đến thiết bị của bạn. Nhấp vào một trong các liên
          kết bên dưới để trực tiếp truy cập vào hướng dẫn sử dụng trình duyệt
          của bạn. Hãy nhớ rằng bạn sẽ phải thiết lập lại tùy chọn của mình hoặc
          đăng nhập lại trên một số trang web sau khi xóa cookie.
          <ul>
            <li>Internet Explorer</li>
            <li>Mozilla Firefox</li>
            <li>Google Chrome</li>
            <li>Apple Safari</li>
          </ul>
          Nếu bạn không muốn nhận bất kỳ cookie nào (bao gồm cả những cookie cần
          thiết để giúp bạn truy cập Trang web), bạn có thể thiết lập trình
          duyệt của mình để tắt toàn bộ các cookie. Nếu bạn tắt cookie mà chúng
          tôi sử dụng, điều này có thể ảnh hưởng đến trải nghiệm người dùng tổng
          thể của bạn khi truy cập Trang web. Bạn có thể không truy cập được vào
          một số khu vực nhất định trên trang web AkzoNobel hoặc không thể nhận
          được thông tin phù hợp với riêng bạn khi truy cập trang web của
          AkzoNobel. Ngoài ra, các cài đặt tùy chỉnh như thông tin đăng nhập có
          thể không được lưu lại nữa. Nếu bạn sử dụng các thiết bị khác nhau để
          xem và truy cập Trang web (ví dụ: máy tính, điện thoại thông minh, máy
          tính bảng, v.v.), bạn sẽ cần đảm bảo rằng mỗi trình duyệt trên mỗi
          thiết bị đều được điều chỉnh cho phù hợp với tùy chọn cookie của bạn.
        </BodyText>
      </CookieBody>
    </CookieContainer>
  );
};

export default CookieBodyVietnam;
