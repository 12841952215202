import React from "react";
import {
  GetStartedButton,
  PersonalizedColorRecommendationsText,
  PersonalizedContainer,
  PersonalizedDesignText,
  HomePersonalizedTextDiv,
  PersonalizedColumnContainer,
} from "../../styles/HomeStyles";
import BeforeAfterReact from "before-after-react";
import BeforeImage from "../../images/before-min.jpg";
import BeforeImageThailand from "../../images/before_image_thailand.png";
import ReactCompareImage from "react-compare-image";
import AfterImage from "../../images/after-min.jpg";
import AfterImageThailand from "../../images/after_image_thailand.png";
import "../../styles/beforeAfter.css";
import LanguageSelector from "../../language/LanguageSelector"

const HomeDuluxPreview = ({ goToConsumerClickHandler, width, selectedCountry }) => {
  // const [clicked, setClicked] = useState(0);
  // const [slider, setSlider] = useState(document.createElement("DIV"));
  // var img, w, h;

  // const slideReady = (e) => {
  //   e.preventDefault();
  //   /*the slider is now clicked and ready to move:*/
  //   setClicked(1);
  //   /*execute a function when the slider is moved:*/
  //   window.addEventListener("mousemove", slideMove);
  //   window.addEventListener("touchmove", slideMove);
  // };

  // const slideFinish = (e) => {
  //   setClicked(0);
  // };

  // function slideMove(e) {
  //   var pos;
  //   /*if the slider is no longer clicked, exit this function:*/
  //   if (clicked === -0) return false;
  //   /*get the cursor's x position:*/
  //   pos = getCursorPos(e);
  //   /*prevent the slider from being positioned outside the image:*/
  //   if (pos < 0) pos = 0;
  //   if (pos > w) pos = w;
  //   /*execute a function that will resize the overlay image according to the cursor:*/
  //   slide(pos);
  // }
  // function getCursorPos(e) {
  //   var a,
  //     x = 0;
  //   e = e || window.event;
  //   /*get the x positions of the image:*/
  //   a = img.getBoundingClientRect();
  //   /*calculate the cursor's x coordinate, relative to the image:*/
  //   x = e.pageX - a.left;
  //   /*consider any page scrolling:*/
  //   x = x - window.pageXOffset;
  //   return x;
  // }
  // function slide(x) {
  //   /*resize the image:*/
  //   img.style.width = x + "px";
  //   /*position the slider:*/
  //   slider.style.left = img.offsetWidth - slider.offsetWidth / 2 + "px";
  // }

  // const overlayRef = useCallback((node) => {
  //   if (node != null) {
  //     w = node.offsetWidth;
  //     h = node.offsetHeight;
  //     node.style.width = w / 2 + "px";
  //     slider.setAttribute("class", "img-comp-slider");
  //     node.parentElement.insertBefore(slider, img);
  //     /*position the slider in the middle:*/
  //     slider.style.top = h / 2 - slider.offsetHeight / 2 + "px";
  //     slider.style.left = w / 2 - slider.offsetWidth / 2 + "px";
  //     slider.addEventListener("mousedown", slideReady);
  //     /*and another function when the mouse button is released:*/
  //     window.addEventListener("mouseup", slideFinish);
  //     /*or touched (for touch screens:*/
  //     slider.addEventListener("touchstart", slideReady);
  //     /*and released (for touch screens:*/
  //     window.addEventListener("touchend", slideFinish);
  //   }
  // }, []);

  if (width < 1100) {
    console.log(selectedCountry);
    return (
      <PersonalizedColumnContainer style={{ paddingTop: "30px" }}>
        {selectedCountry.value === "Thailand" ?
        <ReactCompareImage
          rightImage={BeforeImageThailand}
          leftImage={AfterImageThailand}
          cursor="col-resize"
          // containerClass="beforeAfter"
          aspectRatio="wider"
          hover={true}
        /> : 
        <ReactCompareImage
          rightImage={BeforeImage}
          leftImage={AfterImage}
          cursor="col-resize"
          // containerClass="beforeAfter"
          aspectRatio="wider"
          hover={true}
        />}

        <HomePersonalizedTextDiv>
          <PersonalizedDesignText>
            <LanguageSelector selectedCountry={selectedCountry} text={"DULUX_PREVIEW_SERVICE"}/>
          </PersonalizedDesignText>
          <PersonalizedColorRecommendationsText>
            <LanguageSelector selectedCountry={selectedCountry} text={"FIRST_OF_ITS_KIND"}/>
          </PersonalizedColorRecommendationsText>
          {/* The text below is only for Thailand as no other country has made this request */}
          {selectedCountry.value === "Thailand" ?
          <>
            <PersonalizedColorRecommendationsText>
            อีเมล :    <a style={{color: "#2fc38c"}} href={"mailto:icith.cdc@akzonobel.com"}>icith.cdc@akzonobel.com</a> (หรือ)
            </PersonalizedColorRecommendationsText>
            <PersonalizedColorRecommendationsText style={{ marginLeft : "40px"}}>
            <a style={{color: "#2fc38c"}} href={"mailto:dulux.thailand@akzonobel.com"}>dulux.thailand@akzonobel.com</a>
            </PersonalizedColorRecommendationsText>
            <PersonalizedColorRecommendationsText>
            LINE :  <a style={{color: "#2fc38c"}} href={"http://line.me/ti/p/~@duluxdesign"}>@Duluxdesign</a>
            </PersonalizedColorRecommendationsText>
          </>
         :null}
          <GetStartedButton onClick={goToConsumerClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>
          </GetStartedButton>
        </HomePersonalizedTextDiv>
      </PersonalizedColumnContainer>
    );
  } else {
    return (
      <PersonalizedContainer>
        {selectedCountry.value === "Thailand" ?
        <BeforeAfterReact
          firstImgSrc={BeforeImageThailand}
          secondImgSrc={AfterImageThailand}
          cursor="col-resize"
          containerClass="beforeAfter"
        /> : 
        <BeforeAfterReact
          firstImgSrc={BeforeImage}
          secondImgSrc={AfterImage}
          cursor="col-resize"
          containerClass="beforeAfter"
        />}
        {/* <div class="img-comp-container">
          <div class="img-comp-img">
            <img src={BeforeImage} width="300" height="200" alt="Before" />
          </div>
          <div class="img-comp-img img-comp-overlay" ref={overlayRef}>
            <img src={AfterImage} width="300" height="200" alt="After" />
          </div>
        </div> */}
        <HomePersonalizedTextDiv style={{margin:"5px"}}>
        <PersonalizedDesignText style={{whiteSpace: "nowrap"}}>
            <LanguageSelector selectedCountry={selectedCountry} text={"DULUX_PREVIEW_SERVICE"} style={{whiteSpace: "nowrap"}} />
          </PersonalizedDesignText>
          <PersonalizedColorRecommendationsText>
            <LanguageSelector selectedCountry={selectedCountry} text={"FIRST_OF_ITS_KIND"}/>
          </PersonalizedColorRecommendationsText>
          {/* The text below is only for Thailand as no other country has made this request */}
          {selectedCountry.value === "Thailand" ?
          <>
            <PersonalizedColorRecommendationsText>
            อีเมล :    <a style={{color: "#2fc38c"}} href={"mailto:icith.cdc@akzonobel.com"}>icith.cdc@akzonobel.com</a> (หรือ)
            </PersonalizedColorRecommendationsText>
            <PersonalizedColorRecommendationsText style={{ marginLeft : "40px"}}>
            <a style={{color: "#2fc38c"}} href={"mailto:dulux.thailand@akzonobel.com"}>dulux.thailand@akzonobel.com</a>
            </PersonalizedColorRecommendationsText>
            <PersonalizedColorRecommendationsText>
            LINE :  <a style={{color: "#2fc38c"}} href={"http://line.me/ti/p/~@duluxdesign"}>@Duluxdesign</a>
            </PersonalizedColorRecommendationsText>
            </>
          
          : null}
          <GetStartedButton onClick={goToConsumerClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_STARTED"}/>
          </GetStartedButton>
        </HomePersonalizedTextDiv>
      </PersonalizedContainer>
    );
  }
};

export default HomeDuluxPreview;
