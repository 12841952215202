/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  RequestCustomerText,
  RequestDiv,
  RequestsCustomersDiv,
  RequestGraph,
  CustomerDiv,
  CustomerGraph,
} from "../../styles/AdminDashboardStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import Chart from "chart.js";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardRequestCustomerGraph = ({
  analyticsData,
  selectedCountry,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const countryLabel= decodedToken
      ? {
          value: decodedToken?.country,
          label: decodedToken?.country,
        }
      : countryValue
  const easeOfUseLabel = countryLabel.value == "Vietnam" ? "Dễ sử dụng" : "Ease of use";
  const detailsLabel = countryLabel.value == "Vietnam" ? "Thông tin chi tiết" : "Details";
  const timeToDeliverLabel = countryLabel.value == "Vietnam" ? "Thời gian giao hàng" : "Time to deliver";

  const [datesArray, setDatesArray] = useState([]);
  const [requestsArray, setRequestsArray] = useState([]);

  useEffect(() => {
    if (analyticsData !== null) {
      let datesSet = new Set();
      setRequestsArray([]);
      console.log("=============", selectedCountry, analyticsData)
      if (selectedCountry === "All") {
        for (let i = 0; i < analyticsData.requests.length; i++) {
          datesSet.add(analyticsData.requests[i].timestamp);
        }
      } else {
        let tempFilter = analyticsData.requests.filter(
          (value) => value.country === selectedCountry
        );
        console.log(tempFilter,"+++++++++++++++++++")
        for (let i = 0; i < tempFilter.length; i++) {
          datesSet.add(tempFilter[i].timestamp);
        }
      }
      let tempDatesArray = Array.from(datesSet);
      setDatesArray(tempDatesArray.sort());
      for (let i = 0; i < tempDatesArray.length; i++) {
        const requests = analyticsData.requests.filter(
          (value) => value.timestamp === tempDatesArray[i]
        );

        setRequestsArray((oldArray) => [...oldArray, requests.length]);
      }
    }
  }, [selectedCountry, analyticsData]);

  const requestChartRef = useCallback(
    (node) => {
      if (node !== null) {
        // node = chatRef.current
        new Chart(node, {
          type: "line",
          data: {
            labels: datesArray,
            datasets: [
              {
                data: requestsArray,
                fill: false,
                borderWidth: 4,
                borderColor: "#439BD5",
                maxBarThickness: 10,
              },
            ],
          },
          options: {
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    color: "rgba(0,0,0,0)",
                  },
                },
              ],
            },
          },
        });
      }
    },
    [requestsArray, analyticsData]
  );

  const getRecommends = () => {
    let value = 0;
    if (analyticsData !== null) {
      for (let i = 0; i < analyticsData.feedbacks.length; i++) {
        value += analyticsData.feedbacks[i].recommend;
      }
      return value;
    } else {
      return value;
    }
  };

  const easeOfUse = () => {
    let value = 0;
    if (analyticsData !== null) {
      for (let i = 0; i < analyticsData.feedbacks.length; i++) {
        value += analyticsData.feedbacks[i].ease_of_use;
      }
      value = value/analyticsData.feedbacks.length
      return value;
    } else {
      return value;
    }
  };

  const details = () => {
    let value = 0;
    if (analyticsData !== null) {
      for (let i = 0; i < analyticsData.feedbacks.length; i++) {
        value += analyticsData.feedbacks[i].detailing;
      }
      value = value/analyticsData.feedbacks.length
      return value;
    } else {
      return value;
    }
  };

  const timeToDeliver = () => {
    let value = 0;
    if (analyticsData !== null) {
      for (let i = 0; i < analyticsData.feedbacks.length; i++) {
        value += analyticsData.feedbacks[i].time_to_deliver;
      }
      value = value/analyticsData.feedbacks.length
      return value;
    } else {
      return value;
    }
  };

  const customerChartRef = useCallback(
    (node) => {
      if (node !== null) {
        // node = chatRef.current
        new Chart(node, {
          type: "bar",
          data: {
            labels: [easeOfUseLabel, detailsLabel, timeToDeliverLabel],
            datasets: [
              {
                data: [easeOfUse(), details(), timeToDeliver()],
                borderWidth: 4,
                borderColor: "#DB7F30",
                maxBarThickness: 20,
                backgroundColor: "#DB7F30",
              },
            ],
          },
          options: {
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    color: "rgba(0,0,0,0)",
                  },
                },
              ],
            },
          },
        });
      }
    },
    [analyticsData]
  );

  return (
    <RequestsCustomersDiv>
      <RequestDiv>
        <RequestCustomerText><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"REQUESTS"}
        /></RequestCustomerText>
        <RequestGraph ref={requestChartRef}></RequestGraph>
      </RequestDiv>
      <CustomerDiv>
        <RequestCustomerText>
          <LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"CUSTOMER_FEEDBACK"}
        />{" "}
          {analyticsData !== null &&
          !isNaN(getRecommends()) &&
          !isNaN(analyticsData.feedbacks.length)
            ? `${getRecommends() / analyticsData.feedbacks.length} / 5`
            : ""}
        </RequestCustomerText>
        <CustomerGraph ref={customerChartRef}></CustomerGraph>
      </CustomerDiv>
    </RequestsCustomersDiv>
  );
};

export default AdminDashboardRequestCustomerGraph;
