import styled from "styled-components";

export const PreviewSupervisorConsumerResultContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PreviewSupervisorConsumerResultHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px;
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: start;
  margin: 30px 0;
  height: calc(100vh - 10px);
`;

export const StepOneContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 20px 0;
  /* padding: 0 20px; */
  @media (max-width: 1100px) {
    padding: 0px;
  }
`;

export const StepTwoContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 20px; */
  margin: 20px 20px 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepThreeContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0; */
  margin: 20px 20px 20px 0;
  /* @media (max-width: 1500px) {
    padding: 0px;
  } */
`;

export const StepOneDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media (max-width: 1500px) {
    width: 20px;
    height: 20px;
  } */
`;

export const StepTwoDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepThreeDiv = styled.div`
  width: 46px;
  height: 46px;
  background: #efbf42;
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StepText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
`;

export const StpeSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 5px 0px;
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const ConsumerResultBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 60px;
`;

export const ConsumerResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  width: 100%;
`;

export const ConsumerResultText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 0 25px;
  @media (max-width: 1100px) {
    margin: 0 4px;
  }
`;

export const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const VerticalDivider = styled.hr`
  opacity: 0.5;
  border: 1px dashed #d7d4cb;
  margin: 0;
`;

export const RecommendedProductsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const NearByStoresDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NearByPainters = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DownloadButton = styled.button`
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  margin: 0 20px 0 0;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const BackButton = styled.button`
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  margin: 0 20px 0 0;
  text-align: center;
  color: #545850;
  outline: none;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const ConfirmSendButton = styled.button`
  display: flex;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0 20px 0 0;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  width: 140px;
  border: none;
  padding: 5px 20px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const FollowingResultText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
`;

export const ResultImageDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 30px;
  @media (max-width: 1100px) {
    margin: 4px;
  }
`;

export const ResultColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  height: 300px;
  width: 300px;
  object-fit: cover;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    height: 200px;
    width: 200px;
  }
`;

export const PaletteListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;

export const PaletteWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PaletteDiv = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 3px;
  margin: 4px;
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const ColorCodeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin: 4px auto;
  text-align: center;
  color: #000000;
`;

export const ModalPaletteDiv = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 3px;
  margin: 4px 15px;
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const OriginalRefernceImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
    margin: 10px 4px;
  }
`;

export const OriginalBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 0;
`;

export const OriginalReferenceImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const PaletteBodyText = styled.p`
  display: flex;
  width: 150px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  margin: 0 0 0 20px;
`;

export const OriginalReferenceImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  width: 300px;
  height: 300px;
  object-fit: cover;

  @media (max-width: 1100px) {
    width: 200px;
    height: 200px;
  }
`;

export const ReferenceBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px dashed #d7d4cb;
  transform: rotate(180deg);
  margin: 10px 60px;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const RecommendedProductsColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BottomText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const RecommendedProductsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RecommendedProductsImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 4px 0;
`;

export const NearByStoreDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
`;

export const NearByImage = styled.img`
  border-radius: 4px;
  width: 50px;
  height: 50px;
`;

export const NearByStoreText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const NearByStoreSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const NearByPaintBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
`;

export const NearByPainterImage = styled.img`
  border-radius: 4px;
  width: 50px;
  height: 50px;
`;

export const NearByPainterTitleText = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const NearByPainterSubTitleText = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
`;

export const ModalPaleeteDiv = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 3px;
  margin: 0px 12px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  &:hover ${ToolTip} {
    visibility: visible;
  }

  @media (max-width: 1100px) {
    margin: 2 10px;
  }
`;

export const ModalPaletteDivAutoColor = styled.div`
  width: 55px;
  height: 55px;
  border-style: solid;
  border-radius: 3px;
  border-color: black;
  border-width: 1px;
  margin: 0px 12px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  &:hover ${ToolTip} {
    visibility: visible;
  }

  @media (max-width: 1100px) {
    margin: 2 10px;
  }
`;

export const ViewDetailsButton = styled.button`
  outline: none;
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  margin: 10px 0;
  width: 140px;
  :hover {
    cursor: pointer;
  }
`;

export const ColorText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 3px auto;
  text-align: center;
`;

export const FooterDiv = styled.div`
  margin: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const ResultModal = styled.div`
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.29);
  backdrop-filter: blur(24px);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

export const ResultBottomModalDiv = styled.div`
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const ResultBodyModal = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  position: fixed;
  background: #fff;
  border-radius: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const LeftArrowImage = styled.img`
  transform: rotate(180deg);
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const RightArrowImage = styled.img`
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ModalImage = styled.img`
  width: 80%;
  height: 80%;
  border-radius: 10px;
  object-fit: cover;
  margin: 20px auto;
  @media (max-width: 1100px) {
    width: 60%;
    height: 80%;
  }
`;

export const ModalPalette = styled.div`
  display: flex;
  height: auto;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const CloseModalImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 30px;
  :hover {
    cursor: pointer;
  }
`;

export const ModalImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

export const ModalImageDownloadButton = styled.button`
  text-decoration: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  width: 130px;
  outline: none;
  margin: 0 0 20px 0;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
