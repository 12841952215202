import React, { useContext } from "react";
import {
  CONSUMER_IMAGE_OPTIONS_ROUTE,
  CONSUMER_UPLOAD_IMAGE_ROUTE,
} from "./Constants";
import { ColourizationContext } from "../context/ColourizationContext";
import { Redirect, Route } from "react-router-dom";

const ConsumerRoute = (props) => {
  const colourizationContext = useContext(ColourizationContext);
  const value = colourizationContext.colourizationDetails;
  const { path } = props;

  if (path === CONSUMER_IMAGE_OPTIONS_ROUTE) {
    if (
      value.raw_image === "" ||
      value.raw_image === undefined ||
      value.raw_image === null
    ) {
      return <Redirect to={CONSUMER_UPLOAD_IMAGE_ROUTE} />;
    } else {
      return <Route component={props.component} {...props} />;
    }
  } else {
    if (
      value.raw_image === "" ||
      value.raw_image === undefined ||
      value.raw_image === null ||
      value.colourization_type === "" ||
      value.colourization_type === undefined ||
      value.colourization_type === null
    ) {
      return <Redirect to={CONSUMER_UPLOAD_IMAGE_ROUTE} />;
    } else {
      return <Route component={props.component} {...props} />;
    }
  }
};

export default ConsumerRoute;
