import React from "react";
import {
  PrivacyPolicyContainer,
  PrivacyPolicyHeader,
  DeluxePreviewServiceImage,
  PrivacyPolicyBody,
  PrivacyPolicyHeaderText,
  PrivacyPolicyText,
  BodyText,
  BoldBodyText,
  CookieBodySpacer,
} from "../../styles/PrivacyPolicyStyles";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import { Link } from "react-router-dom";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";

const PrivacyPolicyBodyVietnam = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyBody>
        <PrivacyPolicyHeaderText
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Chính sách bảo mật
        </PrivacyPolicyHeaderText>
        <BodyText>
          Chúng tôi thực sự tôn trọng quyền riêng tư của bạn. Chính sách bảo mật
          này (“Chính sách bảo mật”) mô tả các chính sách và thủ tục của chúng
          tôi đối với việc thu thập, sử dụng và tiết lộ thông tin của bạn khi
          bạn sử dụng Trang web. Chúng tôi sẽ không sử dụng hoặc chia sẻ thông
          tin của bạn với bất kỳ ai ngoại trừ như được mô tả trong Chính sách
          bảo mật này.
        </BodyText>
        <BodyText>
          Chính sách bảo mật này là một phần và được kết hợp chặt chẽ cùng với
          Điều khoản sử dụng (“Điều khoản”). Các điều khoản viết hoa được áp
          dụng trong Chính sách bảo mật này, nhưng không được định nghĩa ở đây,
          sẽ có ý nghĩa tương đương các điều khoản đó trong Điều khoản.
        </BodyText>
        <BodyText style={{ marginTop: "20px", marginBottom: "20px" }}>
          <BoldBodyText style={{ marginBottom: "10px" }}>
            Khi nào thì Chính sách bảo mật này được áp dụng?
          </BoldBodyText>
          <BodyText style={{ marginBottom: "20px" }}>
            Chính sách bảo mật này áp dụng cho (a) Người dùng đã đăng ký và (b)
            tất cả những người (kể cả cá nhân) không phải Người dùng đã đăng ký
            theo cách trực tiếp hoặc gián tiếp khi truy cập trang web.
          </BodyText>
          <BoldBodyText style={{ marginBottom: "10px" }}>
            Chúng tôi xử lý dữ liệu cá nhân của bạn cho mục đích gì?
          </BoldBodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Chúng tôi sử dụng dữ liệu cá nhân của bạn cho nhiều mục đích khác
            nhau. Sau đây là bản tóm tắt về các loại dữ liệu cá nhân và mục đích
            mà chúng tôi sử dụng chúng.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Chúng tôi xử lý dữ liệu về kỹ thuật, chẳng hạn như địa chỉ IP của
            bạn, trình duyệt bạn sử dụng, các trang đã truy cập, các trang đã
            truy cập trước/sau và thời lượng truy cập để cải thiện chức năng của
            Trang web và giúp bạn tìm được thông tin một cách đơn giản và nhanh
            chóng. Dữ liệu đó cũng được sử dụng để giải quyết các vấn đề về kỹ
            thuật hoặc cải thiện khả năng truy cập vào một số phần của Trang
            web. Vì mục đích này, chúng tôi cũng sử dụng cookie.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Khi chọn đăng ký với chúng tôi, bạn cần cung cấp dữ liệu cá nhân như
            tên, địa chỉ email hợp lệ, địa chỉ nơi cư trú hoặc doanh nghiệp của
            bạn và các thông tin liên quan khác để chúng tôi có thể tạo Tài
            khoản cho bạn. Dữ liệu cá nhân của bạn giúp chúng tôi quản lý tài
            khoản của bạn, đảm bảo tính bảo mật và duy trì quyền truy cập bảo
            mật. Chúng tôi cũng xử lý dữ liệu như tên hiển thị, vai trò và dữ
            liệu truy cập của bạn. Ví dụ, chúng tôi có thể thay đổi mật khẩu cho
            bạn. Tuy nhiên, chúng tôi không thể xem mật khẩu của bạn.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Chúng tôi sử dụng dữ liệu cá nhân tổng hợp để phân tích hành vi của
            khách truy cập và điều chỉnh tương ứng thông tin đăng tải của chúng
            tôi cho phù hợp với khách hàng. Điều này có nghĩa là chúng tôi phân
            tích tần suất bạn truy cập ứng dụng/trang web của chúng tôi. Dựa
            trên thông tin này, chúng tôi có thể điều chỉnh các thông tin đăng
            tải và bản tin của mình. Chúng tôi cũng có thể thực hiện các cuộc
            khảo sát về xu hướng thị trường thông qua phân tích thống kê để đánh
            giá và điều chỉnh các sản phẩm cùng quảng cáo của mình theo những
            hướng phát triển mới, nhưng kết quả của cuộc khảo sát chỉ được tiết
            lộ ở dạng tổng hợp. Chúng tôi có thể mua dữ liệu bổ trợ từ các nguồn
            công khai để bổ sung vào cơ sở dữ liệu của chúng tôi nhằm dùng cho
            các mục đích được mô tả ở trên.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Chúng tôi có thể gửi email bao gồm các chương trình khuyến mãi, tin
            tức và lời mời tham gia các sự kiện. Nếu bạn chọn tham gia vào bất
            kỳ hoạt động nào trong số các hoạt động này, chúng tôi cần dữ liệu
            cá nhân của bạn để có thể quảng cáo và tổ chức các sự kiện đó. Để
            thực hiện quá trình này, chúng tôi sẽ xử lý tên, địa chỉ, địa chỉ
            e-mail và khả năng tham gia của bạn vào hoạt động này. Chúng tôi có
            thể gọi điện và/hoặc nhắn tin SMS cho bạn để thu thập thông tin chi
            tiết về việc sử dụng hoặc trải nghiệm của bạn đối với Nền tảng/trang
            web, bao gồm thông tin chi tiết về các sản phẩm được sử dụng cho
            dịch vụ sơn.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            Bạn đồng ý và chấp thuận rằng chúng tôi có thể thu thập, xử lý, sử
            dụng, lưu trữ, chuyển giao, trao đổi, chia sẻ, phổ biến, hủy hoặc sử
            dụng theo cách khác thông tin cá nhân của bạn. Bất kể mọi ý kiến
            trái ngược nào, bạn hoàn toàn đồng ý và đồng thuận rằng chúng tôi có
            thể sử dụng thông tin cá nhân của bạn cho các mục đích được liệt kê
            ở trên. Chúng tôi cũng có thể tùy ý chia sẻ không giới hạn thông tin
            cá nhân đó cho bên thứ ba theo cách được nêu bên dưới.
          </BodyText>
        </BodyText>
        <BoldBodyText style={{ marginBottom: "10px" }}>
          Ai có quyền truy cập vào dữ liệu của bạn?
        </BoldBodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Chúng tôi xử lý dữ liệu của bạn một cách cẩn thận và bảo mật; chúng
          tôi không chia sẻ dữ liệu của bạn với các bên thứ ba không được nêu rõ
          ràng trong Chính sách bảo mật này.
        </BodyText>
        <BodyText
          style={{ marginLeft: "30px", marginTop: "5px", marginBottom: "5px" }}
        >
          <li>
            <u>Đối với các đơn vị khác trong Tập đoàn AkzoNobel:</u> Chúng tôi
            có thể chia sẻ thông tin về người tiêu dùng của mình dưới dạng bút
            danh, ẩn danh hoặc thông tin tổng hợp với các thành viên khác của
            Tập đoàn AkzoNobel để thông tin có thể được sử dụng, chẳng hạn như
            để phân tích xu hướng. Xin lưu ý rằng dữ liệu có thể được chuyển
            giao và/hoặc lưu trữ bên ngoài Việt Nam đến nhiều địa điểm.
          </li>
          <li>
            <u>Đối với các nhà cung cấp dịch vụ:</u> Chúng tôi để một số công ty
            xử lý dữ liệu cho chúng tôi, chẳng hạn như công ty lưu trữ, dịch vụ
            giao hàng. Các công ty này thay mặt chúng tôi sử dụng thông tin của
            bạn và tuân theo các quy tắc nghiêm ngặt cần thiết khi xử lý thông
            tin của bạn và các tiêu chuẩn về bảo vệ dữ liệu tương tự do chúng
            tôi duy trì. Chúng tôi không cho phép các công ty đó sử dụng dữ liệu
            theo bất kỳ cách nào khác mà chúng tôi không hướng dẫn.
          </li>
          <li>
            <u>Các bên thứ ba khác:</u> Chúng tôi có thể cần tiết lộ thông tin
            cá nhân theo yêu cầu của các cơ quan chính phủ hoặc để duy trì trật
            tự công cộng. Chúng tôi cũng có thể chia sẻ dữ liệu của bạn với các
            đối tác kênh của chúng tôi, bao gồm các nhà phân phối, đại lý
            và/hoặc nhà bán lẻ.
          </li>
        </BodyText>

        <BodyText style={{ marginBottom: "30px" }}>
          Chúng tôi sẽ chỉ chia sẻ thông tin của bạn với các công ty hoặc người
          khác nếu bạn đồng ý.
        </BodyText>

        <BoldBodyText style={{ marginBottom: "10px" }}>
          Cách xử lý các liên kết tới các trang web khác và phương tiện truyền
          thông xã hội?
        </BoldBodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          <u>Các trang web bên ngoài</u>
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Trên Trang web, bạn có thể tìm thấy một số liên kết đến các trang web
          khác. Mặc dù các trang web này đều được lựa chọn cẩn thận, chúng tôi
          không chịu trách nhiệm về việc xử lý dữ liệu cá nhân của bạn thông qua
          các trang web khác này. Do đó, các Điều khoản và Chính sách bảo mật
          này không áp dụng cho việc sử dụng các trang web đó.
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          <u>Truyền thông mạng xã hội</u>
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Trang web có thể thêm các phần bổ trợ xã hội từ nhiều mạng xã hội. Nếu
          bạn chọn tương tác trên mạng xã hội (ví dụ: bằng cách đăng ký tài
          khoản), hoạt động của bạn trên Trang web cũng sẽ xuất hiện trên các
          mạng xã hội như Facebook và Twitter.
        </BodyText>
        <BodyText style={{ marginBottom: "30px" }}>
          Nếu bạn được kết nối với một trong các mạng xã hội trong quá trình
          truy cập Trang web, mạng xã hội có thể thêm thông tin của bạn vào hồ
          sơ tương ứng trên nền tảng đó. Nếu bạn đang tương tác với một trong
          các phần bổ trợ xã hội, thông tin về hoạt động tương tác sẽ được
          chuyển đến mạng xã hội. Nếu bạn không muốn việc truyền dữ liệu như vậy
          xảy ra, hãy ngắt kết nối với mạng xã hội của bạn trước khi vào Trang
          web của chúng tôi.
        </BodyText>

        <BoldBodyText style={{ marginBottom: "10px" }}>
          Chúng tôi bảo vệ dữ liệu bằng cách nào?
        </BoldBodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Dữ liệu cá nhân của bạn được bảo mật nghiêm ngặt. Chúng tôi có áp dụng
          các biện pháp bảo mật mang tính kỹ thuật và tổ chức toàn diện, hợp lý
          để phòng tránh việc mất mát hoặc xử lý dữ liệu bất hợp pháp, căn cứ
          theo quy định của Đạo luật về công nghệ thông tin năm 2000. Vì mục
          đích này, chúng tôi có sử dụng một số phương pháp bảo mật, bao gồm máy
          chủ bảo mật, tường lửa và mã hóa, bên cạnh biện pháp bảo vệ vật lý tại
          các vị trí lưu trữ dữ liệu.
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          <u>Lưu giữ dữ liệu</u>
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Trang web có thể thêm các phần bổ trợ xã hội từ nhiều mạng xã hội. Nếu
          bạn chọn tương tác trên mạng xã hội (ví dụ: bằng cách đăng ký tài
          khoản), hoạt động của bạn trên Trang web cũng sẽ xuất hiện trên các
          mạng xã hội như Facebook và Twitter.
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Chúng tôi sẽ không lưu giữ dữ liệu cá nhân của bạn trong thời gian lâu
          hơn mức cần thiết nhằm đạt được mục đích khi thu thập dữ liệu hoặc
          trong khoảng thời gian tối đa theo quy định của pháp luật.
        </BodyText>
        <BodyText style={{ marginBottom: "5px" }}>
          Nếu bạn là người dùng đã đăng ký, bạn sẽ có các quyền sau theo Chính
          sách bảo mật này:
        </BodyText>
        <BodyText
          style={{ marginLeft: "30px", marginTop: "5px", marginBottom: "5px" }}
        >
          <BodyText style={{ marginBottom: "5px" }}>
            (a) Quyền đối với dữ liệu cá nhân: Tùy theo yêu cầu, bạn sẽ có quyền
            truy cập và xem xét bất kỳ "dữ liệu hoặc thông tin cá nhân nhạy cảm"
            nào do bạn cung cấp và chúng tôi nắm giữ. Khi phát hiện ra rằng "dữ
            liệu hoặc thông tin cá nhân nhạy cảm" được cung cấp là không chính
            xác hoặc bị thiếu theo bất kỳ cách nào, bạn sẽ có quyền yêu cầu thay
            đổi hoặc chỉnh sửa dữ liệu đó sao cho phù hợp. Nếu có thể, chúng tôi
            sẽ thực hiện các nội dung thay đổi phù hợp trong cơ sở dữ liệu để
            thể hiện các nội dung sửa đổi được yêu cầu.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            (b) Quyền đối với tài khoản: Bạn có thể kiểm soát các cài đặt tài
            khoản của mình và quản lý những thông tin liên lạc bạn nhận được từ
            chúng tôi và cách bạn nhận thông tin. Bạn cũng có thể sửa đổi mật
            khẩu hoặc đóng tài khoản của mình nếu muốn.
          </BodyText>
          <BodyText style={{ marginBottom: "5px" }}>
            (c) Quyền đồng ý: Bạn cũng có thể chọn rút lại sự đồng ý đã cung cấp
            cho chúng tôi trước đó. Trong những trường hợp như vậy, chúng tôi có
            thể không còn nghĩa vụ cần tiếp tục cung cấp Nền tảng/Trang web.
          </BodyText>
        </BodyText>
      </PrivacyPolicyBody>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyBodyVietnam;
