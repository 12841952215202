import React from "react";
import {
  HomeColorContainer,
  HomeColourTextDiv,
  HomeColorDesignText,
  HomeColorRecommendationsText,
  KnowMoreButton,
  HomeColorColumnContainer,
} from "../../styles/HomeStyles";
import COLOUR_TREND_IMAGE from "../../images/colour_trend.svg";
import LanguageSelector from "../../language/LanguageSelector"

const HomeColourTrends = ({ goToConsumerClickHandler, width, selectedCountry }) => {
  if (width < 1100) {
    return (
      <HomeColorColumnContainer>
        <img
          src={COLOUR_TREND_IMAGE}
          alt="Latest colour trends"
          style={{ width: "100%" }}
        />
        <HomeColourTextDiv>
          <HomeColorDesignText>
            <LanguageSelector selectedCountry={selectedCountry} text={"LATEST_COLOUR_TRENDS"}/>
          </HomeColorDesignText>
          <HomeColorRecommendationsText>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_EXPERT_RECOMMENDATIONS"}/>
          </HomeColorRecommendationsText>
          <KnowMoreButton onClick={goToConsumerClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"KNOW_MORE"}/>
          </KnowMoreButton>
        </HomeColourTextDiv>
      </HomeColorColumnContainer>
    );
  } else {
    return (
      <HomeColorContainer>
        <img
          src={COLOUR_TREND_IMAGE}
          alt="Latest colour trends"
          style={{ width: "50vw" }}
        />
        <HomeColourTextDiv>
          <HomeColorDesignText>
            <LanguageSelector selectedCountry={selectedCountry} text={"LATEST_COLOUR_TRENDS"}/>
          </HomeColorDesignText>
          <HomeColorRecommendationsText>
            <LanguageSelector selectedCountry={selectedCountry} text={"GET_EXPERT_RECOMMENDATIONS"}/>
          </HomeColorRecommendationsText>
          <KnowMoreButton onClick={goToConsumerClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"KNOW_MORE"}/>
          </KnowMoreButton>
        </HomeColourTextDiv>
      </HomeColorContainer>
    );
  }
};

export default HomeColourTrends;
