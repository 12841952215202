/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ConsumerDuluxPreviewHeader from "../../components/consumer-dulux-preview/ConsumerDuluxPreviewHeader";
import ConsumerResultSideBar from "../../components/consumer-result/ConsumerResultSideBar";
import { ColourizationContext } from "../../context/ColourizationContext";
import {
  ConsumerDuluxPreviewContainer,
  DuluxPreviewText,
  SubmitRequestButton,
  DuluxPreviewImage,
  ErrorMessage,
} from "../../styles/ConsumerDuluxPreviewStyles";
import "../../styles/floatingLabelInput.css";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import ConsumerResultPersonalDetails from "../../components/consumer-result/ConsumerResultPersonalDetails";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_RESULT_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import BackButton from "../../components/BackButton";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import ConsumerDuluxPreviewForm from "../../components/consumer-dulux-preview/ConsumerDuluxPreviewForm";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const ConsumerDuluxPreview = ({ history }) => {

  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [personalDetails, setPersonalDetails] = useState(false);
  const [email, setEmail] = useState(null);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [progress, setProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [requestLimitReached, setRequestLimitReached] = useState(false);
  const [floorArea, setfloorArea] = useState("");
  const [floorNumber, setfloorNumber] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [remarks, setRemarks] = useState("");
  const [wallOwnerContact, setWallOwnerContact] = useState("");
  const [wallOwnerAddress, setWallOwnerAddress] = useState("");
  const [wallOwnerName, setWallOwnerName] = useState("");
  const [customerCode, setCustomerCode] = useState();
  const [customerType, setCustomerType] = useState({
    value: "Wall Owner",
    label: "Wall Owner",
  });

  const { width } = useWindowDimensions();

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleProgress = () => setProgress((previousState) => !previousState);

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : "";
  const countryValue = JSON.parse(localStorage.getItem("country"));

  let lowercase_country =
    countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);

  const { verifyEmail, verifyEmailOTP, userCreate } = useContext(AuthContext);
  const { colourizationRequest, getGeocodingDetails } =
    useContext(ConsumerContext);
  const colourizationContext = useContext(ColourizationContext);

  const toggleSideMenu = () =>
    setOpenSideMenu((previousState) => !previousState);
  const togglePersonalDetailsMenu = () =>
    setPersonalDetails((previousState) => !previousState);

  const verifyEmailSuccessCallback = () => {
    toggleSideMenu();
  };

  const verifyEmailFailureCallback = (msg) => {
    toggleProgress();
    setErrorMessage(msg);
  };

  const userCreateSuccessCallback = (email) => {
    verifyEmail({
      email: email,
      verifyEmailSuccessCallback,
      verifyEmailFailureCallback,
    });
  };

  const goToConsumerResult = (email, floorArea, floorNumber, roomNumber, remarks) => {
    console.log("consumer result", remarks, email)
    setEmail(email);
    setRemarks(remarks);
    if (countryValue.value === "India") {
      setfloorArea(floorArea);
      setfloorNumber(floorNumber);
      setRoomNumber(roomNumber);
      
    }
    verifyEmailLimit(email, remarks);
  };

  const verifyEmailLimit = (email, remarks) => {
    console.log("@@@@@@@@@@@@@@@@@@", progress, token, decodedToken)
    if (!progress) {
      console.log("no progress")
      toggleProgress();
      const value = colourizationContext.colourizationDetails;
      console.log(value, remarks)
      if (
        value.raw_image === undefined ||
        value.colourization_type === undefined
      ) {
        setErrorMessage(language["UPLOAD_IMAGE_TYPE"][lowercase_country]);
      } else {
        colourizationContext.setColourizationDetails({
          raw_image: value.raw_image,
          colourization_type: value.colourization_type,
          reference: value.reference,
          theme: value.theme,
          lead_colour: value.lead_colour,
          email: email,
          name: "",
          city: "",
          country: decodedToken?.country || countryValue.value,
          floor_area: floorArea,
          floors_number: floorNumber,
          rooms_number: roomNumber,
          remarks: remarks,
          customer_code: decodedToken?.customer_code || customerCode,
          customer_type: decodedToken?.customer_type || customerType.value,
          wall_owner_name: wallOwnerName,
          user_email: email || decodedToken?.email,
          wall_owner_address: wallOwnerAddress,
          mobile: wallOwnerContact,
        });
        userCreate({
          email: email,
          userCreateSuccessCallback,
        });
        colourizationRequestSuccessCallback();
      }
    }
  };

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    console.log(decodedToken);
    if (
      decodedToken !== "" &&
      decodedToken !== null &&
      decodedToken !== undefined &&
      decodedToken.customer_type !== "Wall Owner"
    ) {
      togglePersonalDetailsMenu();
    }
  }, []);

  const colourizationRequestSuccessCallback = (data) => {
    setRequestLimitReached(data.limit_reached);
    if (data.limit_reached === true) {
      alert("Yêu cầu của quý khách đã được ghi nhận, tuy nhiên số lượng yêu cầu đã vượt mức giới hạn trong ngày và sẽ được xếp thời gian xử lý với thứ tự ưu tiên thấp hơn.");
    }
    const value = colourizationContext.colourizationDetails;
    console.log("%%%%%%%%%%%%%%%%%%%",value, remarks, value.remarks)
    const type = value.colourization_type;
    
    colourizationContext.setColourizationDetails({
      raw_image: "",
      colourization_type: "",
      reference: "",
      theme: "",
      lead_colour: "",
      email: value.email || decodedToken?.email || "",
      name: "",
      city: "",
      phone: "",
      address: "",
      country: decodedToken?.country || countryValue.value,
      floor_area: floorArea,
      floors_number: floorNumber,
      rooms_number: roomNumber,
      remarks: remarks,
      customer_code: decodedToken?.customer_code || customerCode,
      customer_type: decodedToken?.customer_type || customerType.value,
      wall_owner_name: wallOwnerName,
      user_email: email || decodedToken?.email,
      wall_owner_address: wallOwnerAddress,
      mobile: wallOwnerContact,
    });
    history.push(CONSUMER_RESULT_ROUTE, {
      type,
    });
  };

  const colourizationRequestFailureCallback = (msg) => {
    toggleProgress();
    setErrorMessage(msg);
  };

  const submitPersonDetails = (floorArea, floorNumber, roomNumber, remarks) => {
    if (!progress) {
      //DISABLED INDIA CHANGE
      // if(countryValue.value === "India"){
      //   setfloorArea(floorArea);
      //   setfloorNumber(floorNumber);
      //   setRoomNumber(roomNumber);
      // }
      toggleProgress();
      const value = colourizationContext.colourizationDetails;
      console.log("!!!!!!!!!!!!!!!!!!!!!!", value, remarks)
      console.log("decoded token", decodedToken)
      console.log("token", token)
      if (
        value.raw_image === undefined ||
        value.colourization_type === undefined
      ) {
        setErrorMessage(language["UPLOAD_IMAGE_TYPE"][lowercase_country]);
      } else {
        if (requestLimitReached === true) {
          setErrorMessage(
            "Yêu cầu của quý khách đã được ghi nhận, tuy nhiên số lượng yêu cầu đã vượt mức giới hạn trong ngày và sẽ được xếp thời gian xử lý với thứ tự ưu tiên thấp hơn."
          );
          alert(
            "Yêu cầu của quý khách đã được ghi nhận, tuy nhiên số lượng yêu cầu đã vượt mức giới hạn trong ngày và sẽ được xếp thời gian xử lý với thứ tự ưu tiên thấp hơn."
          );
        }
        colourizationContext.setColourizationDetails({
          raw_image: value.raw_image,
          colourization_type: value.colourization_type,
          reference:  value.reference,
          theme:  value.theme,
          lead_colour:  value.lead_colour,
          email: decodedToken?.email || "",
          name: decodedToken?.name || "",
          city: decodedToken?.city || "",
          phone: decodedToken?.phone || "",
          address: decodedToken?.city || "",
          country: decodedToken?.country || countryValue.value || "",
          floor_area: floorArea,
          floors_number: floorNumber,
          rooms_number: roomNumber,
          remarks: remarks,
          customer_code: decodedToken?.customer_code || customerCode,
          customer_type: decodedToken?.customer_type || customerType.value,
          wall_owner_name: wallOwnerName,
          user_email: decodedToken?.email,
          wall_owner_address: wallOwnerAddress,
          mobile: wallOwnerContact,
        });
        colourizationRequest({
          raw_image: value.raw_image,
          colourization_type: value.colourization_type,
          reference:  value.reference,
          theme:  value.theme,
          lead_colour:  value.lead_colour,
          email: decodedToken?.email || "",
          name: decodedToken?.name || "",
          city: decodedToken?.city || "",
          phone: decodedToken?.phone || "",
          address: decodedToken?.city || "",
          country: decodedToken?.country || countryValue.value || "",
          floor_area: floorArea,
          floors_number: floorNumber,
          rooms_number: roomNumber,
          remarks: remarks,
          customer_code: decodedToken?.customer_code || customerCode,
          customer_type: decodedToken?.customer_type || customerType.value,
          wall_owner_name: wallOwnerName,
          user_email: decodedToken?.email,
          wall_owner_address: wallOwnerAddress,
          mobile: wallOwnerContact,
          colourizationRequestSuccessCallback,
          colourizationRequestFailureCallback,
        });
      }
    }
  };

  return (
    <>
      <ConsumerLogoutDrawer
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue}
        openLogoutMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />

      <ConsumerResultSideBar
        openSideMenu={openSideMenu}
        toggleSideMenu={toggleSideMenu}
        verifyEmailOTP={verifyEmailOTP}
        togglePersonalDetailsMenu={togglePersonalDetailsMenu}
        email={email}
        verifyEmail={verifyEmail}
        width={width}
        selectedCountry={
          decodedToken?.country || countryValue.value || "Singapore"
        }
      />
      {personalDetails && (
        <ConsumerResultPersonalDetails
          personalDetails={personalDetails}
          togglePersonalDetailsMenu={togglePersonalDetailsMenu}
          history={history}
          colourizationContext={colourizationContext}
          colourizationRequest={colourizationRequest}
          decodedToken={decodedToken}
          width={width}
          getGeocodingDetails={getGeocodingDetails}
          countryValue={
            decodedToken?.country || countryValue.value || "Singapore"
          }
          floorArea={floorArea}
          floorNumber={floorNumber}
          roomNumber={roomNumber}
          wallOwnerContact={wallOwnerContact}
          setWallOwnerContact={setWallOwnerContact}
          wallOwnerAddress={wallOwnerAddress}
          setWallOwnerAddress={setWallOwnerAddress}
          wallOwnerName={wallOwnerName}
          setWallOwnerName={setWallOwnerName}
          customerCode={customerCode}
          setCustomerCode={setCustomerCode}
          customerType={customerType}
          setCustomerType={setCustomerType}
        />
      )}
      <ConsumerDuluxPreviewContainer
        applyFilter={openLogoutMenu || openSideMenu || personalDetails}
      >
        <ConsumerDuluxPreviewHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
          selectedCountry={
            decodedToken?.country || countryValue.value || "Singapore"
          }
          goBackHandler={goBackHandler}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        <DuluxPreviewImage
          src={colourizationContext.colourizationDetails.raw_image}
          alt="Deluxe Preview"
        />
        <DuluxPreviewText>
          <LanguageSelector
            selectedCountry={
              decodedToken?.country || countryValue.value || "Singapore"
            }
            text={"THANK_YOU_FOR_USING"}
          />
        </DuluxPreviewText>
        {token === undefined ? (
          <ConsumerDuluxPreviewForm
            token={token}
            handler={goToConsumerResult}
            selectedCountry={
              decodedToken?.country || countryValue.value || "Singapore"
            }
            decodedToken={decodedToken}
          />
        ) : (
          <ConsumerDuluxPreviewForm
            token={token}
            handler={submitPersonDetails}
            selectedCountry={
              decodedToken?.country || countryValue.value || "Singapore"
            }
            decodedToken={decodedToken}
          />
        )}
        {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}

      </ConsumerDuluxPreviewContainer>
    </>
  );
};

export default ConsumerDuluxPreview;
