import React from "react";
import {
  DragAndDropImageText,
  DragDropImagesContainerDiv,
} from "../../styles/ConsumerReferenceImageStyles";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerReferenceImageDragAndDrop = ({
  reader,
  createListItem,
  data,
  dispatch,
  selectedCountry,
}) => {
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      // const files = data.fileList.map((f) => f.name);
      // files = files.filter((f) => !existingFiles.includes(f.name));

      for (let i = 0; i < files.length; i++) {
        reader = new FileReader();

        reader.onloadend = (function (file) {
          return function (evt) {
            createListItem(evt, file);
          };
        })(files[i]);

        reader.readAsDataURL(files[i]);
      }

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    }
  };

  return (
    <DragDropImagesContainerDiv
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      inDropZone={data.inDropZone}
    >
      <DragAndDropImageText>
        <LanguageSelector
          selectedCountry={selectedCountry}
          text={"DRAG_AND_DROP_IMAGE_HERE"}
        />
      </DragAndDropImageText>
    </DragDropImagesContainerDiv>
  );
};

export default ConsumerReferenceImageDragAndDrop;
