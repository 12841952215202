import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ShadesContainer,
  OptionText,
  ColorPaletteText,
  ColorPaletteDiv,
  ColorPaletteDivContainer,
  SelectColourButton,
  TouchupOptionsIconImage
} from "../../styles/PreviewSupervisorTouchUpStyles";
import {
  DropDownDiv,
  DropDownText,
  FilterDiv,
  DropDownParentDiv,
  DropDownContent,
  DropDownContentText,
} from "../../styles/AdminCustomersStyles";
import {
  RangeSliderTextDiv,
  RangeSliderText,
  RangerInputSlider,
  RangeProgressBar,
  QuestionarrieText,
} from "../../styles/ConsumerWriteReviewStyles";
import PALETTE_IMAGE from "../../images/color-palette.png";
import COLOR_PICKER_IMAGE from "../../images/color-picker.png";
import LanguageSelector from "../../language/LanguageSelector";
import DROP_DOWN_MENU_TWO_IMAGE from "../../images/drop_down_menu_two.svg";


const PreviewSupervisorTouchUpPolygon = ({
  useOptionTwo,
  toggleOptionTwo,
  toggleOptionOne,
  color,
  selectedPath,
  setNewColor,
  toggleSideMenu,
  selectedColourFromDrawer,
  setColor,
  toggleColorPicker,
  searchColourHandler,
  setSearchText,
  searchText,
  errorText,
  setMixBlendMode,
  mixBlendMode,
  canvasOpacity,
  setCanvasOpacity,
  setSelectedFilterOption,
  selectedFilterOption,
  setOpacityCollection,
  opacityCollection
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
 
  const [filterDropDown, setFilterDropDown] = useState(false);
  const dropDownList = ["color", "hard-light", "overlay", "normal"];


  const toggleFilterDropDown = () =>
  setFilterDropDown((previousState) => !previousState);

  const opacityHandler = (item) =>{
    var opacityCollectionTemp = opacityCollection;
    opacityCollectionTemp[selectedPath] = item;
    setOpacityCollection(opacityCollectionTemp);
    setCanvasOpacity(item)
  };

  return (
    <ShadesContainer>
      {useOptionTwo ? null:
      <ColorPaletteDivContainer 
        style={{
          marginRight: "150px",
        }}>
        {/* <SelectColourButton>
        <input type="color" value={color} onChange={e => setNewColor(e.target.value,selectedPath)} />
        </SelectColourButton> */}
        <ColorPaletteDiv 
        style={{
          marginRight: "30px",
          marginLeft: "10px",
          backgroundColor: color,
        }}/>
        <TouchupOptionsIconImage 
        style={{
          marginRight: "30px",
          marginLeft: "10px",
          cursor : "pointer"
        }}
        onClick={toggleColorPicker} 
        src={COLOR_PICKER_IMAGE} alt="Color Picker Icon"/>
        <TouchupOptionsIconImage
        style={{
          marginRight: "5s0px",
          marginLeft: "10px",
          cursor : "pointer"
        }}
        onClick={toggleSideMenu} 
        src={PALETTE_IMAGE} alt="Palette Icon"/>
        <DropDownParentDiv style={{marginLeft: "10px"}}>
        <DropDownDiv onClick={toggleFilterDropDown}>
          {selectedFilterOption == "color" && <DropDownText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COLOR"}
            /></DropDownText>}
          {selectedFilterOption == "hard-light" && <DropDownText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HARD_LIGHT"}
            /></DropDownText>}
          {selectedFilterOption == "overlay" && <DropDownText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OVERLAY"}
            /></DropDownText>}
          {selectedFilterOption == "normal" && <DropDownText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NORMAL"}
            /></DropDownText>}
          <img src={DROP_DOWN_MENU_TWO_IMAGE} alt="Drop Down" />
        </DropDownDiv>
        <DropDownContent show={filterDropDown}>
          {dropDownList.map((item, index) => {
            return (
              <>
              {item == "color" && <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleFilterDropDown();
                  setSelectedFilterOption(item);
                  setMixBlendMode(item);
                }}
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"COLOR"}
            />
              </DropDownContentText>}

              {item == "hard-light" && <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleFilterDropDown();
                  setSelectedFilterOption(item);
                  setMixBlendMode(item);
                }}
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"HARD_LIGHT"}
            />
              </DropDownContentText>}

              {item == "overlay" && <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleFilterDropDown();
                  setSelectedFilterOption(item);
                  setMixBlendMode(item);
                }}
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"OVERLAY"}
            />
              </DropDownContentText>}


              {item == "normal" && <DropDownContentText
                key={`${index}`}
                onClick={() => {
                  toggleFilterDropDown();
                  setSelectedFilterOption(item);
                  setMixBlendMode(item);
                }}
              >
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NORMAL"}
            />
              </DropDownContentText>}

            </>);
          })}
        </DropDownContent>
      </DropDownParentDiv>
        <div style={{ position: "relative", marginRight: "30px", marginLeft: "10px",}}>
          <RangerInputSlider
            type="range"
            min="0"
            max="10"
            value={canvasOpacity * 10}
            onChange={(event) => {
              opacityHandler(event.target.value/10);
            }}
            style={{zIndex:1}}
          />
          <RangeProgressBar sliderValue={canvasOpacity*100} style={{zIndex:-1}}/>
        </div>
      </ColorPaletteDivContainer>}
    </ShadesContainer>
  );
};

export default PreviewSupervisorTouchUpPolygon;
