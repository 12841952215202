import styled from "styled-components";

export const PreviewStaffDashboardContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PaginationNumberDiv = styled.button`
  background-color: #f1f1f1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  color: ${(props) => (props.isSelected === true ? "#2FC38C" : "#012169")};
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  :hover {
    cursor: pointer;
  }
`;

export const WelcomeText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #767676;
  margin: 0px 60px 10px 0;
  align-self: flex-end;
  @media (max-width: 1100px) {
    margin: 0px 20px 20px 20px;
  }
`;

export const WelcomeNameText = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #767676;
`;

export const PreviewStaffDashboardHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  height: 100px;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const PendingResolvedContainerDiv = styled.div`
  display: flex;
  padding: 1vh 14vw 0 14vw;
  @media (max-width: 1100px) {
    padding: 10px 20px;
  }
`;

export const ArrowImage = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const PendingButton = styled.button`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  background-color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${(props) =>
    props.pendingClicked === true ? "3px solid #2FC38C" : "none"};
  font-weight: ${(props) => (props.pendingClicked === true ? "600" : "normal")};
`;

export const ResolvedButton = styled.button`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  background-color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${(props) =>
    props.pendingClicked === false ? "3px solid #2FC38C" : "none"};
  font-weight: ${(props) =>
    props.pendingClicked === false ? "600" : "normal"};
`;

export const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
  /* padding: 0 14vw; */
  margin: 20px auto;
  width: 100vw;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
`;

export const PaginationArrowContainer = styled.div`
  width : 40px;
  height : 20px;
  color : #ffffff;
`;

export const PaginationLeftImage = styled.img`
  padding: 0 10px;
  display: 'none';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  :hover {
    cursor: pointer;
  }
`;

export const PaginationRightImage = styled.img`
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ResolvedRequestTable = styled.table`
  border-collapse: collapse;
  width: calc(100% - 28vw);
  margin: 10px 14vw;
  @media (max-width: 1100px) {
    margin: 10px;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const ResolvedRequestRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const ResolvedRequestTableHeader = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  padding: 8px;
  text-align: center;
`;

export const ResolvedRequestTableData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  align-self: center;
  margin: auto;
  padding: 8px;
  text-align: center;
`;

export const PendingRequestTable = styled.table`
  border-collapse: collapse;
  width: calc(100% - 28vw);
  margin: 10px 14vw;
  @media (max-width: 1100px) {
    margin: 10px;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const PendingRequestRow = styled.tr`
  background-color: ${(props) => (props.isEven === true ? "#dddddd" : "#fff")};
`;

export const PendingRequestTableHeader = styled.th`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  padding: 8px;
  text-align: center;
`;

export const PendingRequestTableData = styled.td`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  align-self: center;
  margin: auto;
  padding: 8px;
  text-align: center;
  color: ${(props) => (props.isOverDue === true ? "red" : "#000000")};
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const SearchBarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const SearchBarBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 14vw;
  @media (max-width: 1100px) {
    margin: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchBarInputDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 180px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const SearchBarInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  width: 150px;
`;

export const SearchFilterDiv = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 160px;
  height: 30px;
  padding: 3px;
  align-items: center;
  display: flex;
  margin: 0 10px;
  padding: 0px 4px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const FilterText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "kern" off;
  color: #545850;
  margin: 0 20px;
`;

export const DropDownParentDiv = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin: 10px;
  }
`;

export const DropDownDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
`;

export const DropDownText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0 10px;
`;

export const DropDownContent = styled.div`
  display: ${(props) => (props.show === true ? "flex" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropDownContentText = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 4px 10px;
  outline: none;
  border: none;
  background: transparent;
`;

export const AdminSearchRequestSideBarContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

export const AdminSearchRequestHeader = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
`;

export const SearchRequestsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  margin: 0 10px;
`;

export const ByCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #333333;
  margin: 20px 0 8px 0;
`;

export const DateRow = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const AdminRequestSearchCountryDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    align-items: flex-start;
  }
`;

export const SelectedCountryCheck = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const AdminCountryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: ${(props) => (props.isSelected === true ? "bold" : "normal")};
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #545850;
  margin: 0px 10px;
`;

export const ButtonBar = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

export const ResetFilterButton = styled.button`
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 10px;
  outline: none;
  margin: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  background: #2fc38c;
  border-radius: 5px;
  padding: 5px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const AdminSearchRequestModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
