import React from "react";
import {
  LegacyContainer,
  LegacyBody,
  LegacyHeaderText,
  LegacyText,
  BodyText,
} from "../../styles/LegalStyles";

const LegalBodyVietnam = () => {
  return (
    <LegacyContainer>
      <LegacyBody>
        <LegacyHeaderText>
          VUI LÒNG ĐỌC KỸ CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN NÀY TRƯỚC KHI SỬ DỤNG
          TRANG WEB NÀY
        </LegacyHeaderText>
        <LegacyText>Điều khoản và điều kiện sử dụng chung</LegacyText>
        <BodyText>
          Các Điều khoản và điều kiện sử dụng chung này (cùng với Chính sách bảo
          mật) (“Điều khoản”) là các điều khoản mà bạn có thể áp dụng khi sử
          dụng trang web tại URL [**] (“Trang web”). Việc sử dụng Trang web bao
          gồm việc truy cập, duyệt tìm hoặc đăng ký sử dụng Trang web. Vui lòng
          đọc kỹ các Điều khoản này trước khi bạn bắt đầu sử dụng Trang web.
          Chúng tôi đề xuất bạn nên in một bản sao các Điều khoản này để tham
          khảo trong tương lai. Bằng việc sử dụng Trang web, bạn xác nhận rằng
          mình chấp nhận các Điều khoản này và đồng ý tuân thủ chúng. Nếu bạn
          không đồng ý với các Điều khoản này, bạn sẽ không được sử dụng Trang
          web. Nếu bạn không đồng ý với bất kỳ nội dung sửa đổi nào do chúng tôi
          thực hiện đối với các Điều khoản này vào bất kỳ lúc nào trong tương
          lai, bạn phải ngừng sử dụng Trang web ngay lập tức.
        </BodyText>
        <LegacyHeaderText>Mục tiêu</LegacyHeaderText>
        <BodyText>
          Trang web này là một nền tảng dựa trên công nghệ trong đó người dùng
          có thể gửi yêu cầu để chúng tôi cung cấp miễn phí các cách hoặc tùy
          chọn phối màu khác nhau cho khu vực cần sơn của họ. Phương tiện này
          được triển khai miễn phí vì lợi ích của Người dùng và chúng tôi khuyên
          Người dùng nên thận trọng (và không chỉ dựa vào phương tiện này), đồng
          thời có ý tưởng riêng về cách phối màu trước khi đưa ra quyết định
          cuối cùng . Trang web được điều hành bởi International Paint Singapore
          Pte Ltd (chi nhánh Việt Nam) (“AkzoNobel”) được thành lập Luật doanh
          nghiệp Việt Nam năm 1999 với văn phòng đăng ký tại [•] và mang mã CIN
          là [•]. Khách hàng tiềm năng, Người tiêu dùng, Đại lý và Thợ sơn được
          gọi chung là “Người dùng”. Bất kỳ tài liệu nào có nhắc đến “bạn” hoặc
          “của bạn” hoặc đề cập đến Người dùng hay khách truy cập vào Trang web
          (tùy từng trường hợp); và bất kỳ tài liệu nào có liên quan đến “chúng
          tôi” và “của chúng tôi” sẽ đề cập đến AkzoNobel.
        </BodyText>
        <LegacyHeaderText>
          Các nội dung thay đổi đối với các điều khoản và nền tảng này
        </LegacyHeaderText>
        <BodyText>
          Chúng tôi có thể sửa đổi các Điều khoản này bất kỳ lúc nào bằng cách
          sửa đổi trang này. Vui lòng kiểm tra trang này theo thời gian để cập
          nhật mọi nội dung thay đổi mà chúng tôi đã thực hiện, vì chúng có liên
          quan tới bạn. Chúng tôi có thể thay đổi bản chất của Nền tảng; thêm
          hoặc bớt các chức năng hoặc tính năng; hoặc ngừng cung cấp Nền tảng
          hoặc các tính năng của Nền tảng cho bạn hoặc cho người dùng nói chung
          mà không cần thông báo trước.
        </BodyText>
        <LegacyHeaderText>
          Các nội dung thay đổi đối với trang web
        </LegacyHeaderText>
        <BodyText>
          Chúng tôi có thể cập nhật Trang web theo thời gian và có thể thay đổi
          Nội dung bất kỳ lúc nào. Trong các Điều khoản này, thuật ngữ (“Nội
          dung”) có nghĩa là bất kỳ thiết kế, bố cục, văn bản, hình ảnh, đồ họa,
          âm thanh, video nào, v.v. hoặc thông tin, tài liệu hay tệp khác bao
          gồm nhưng không giới hạn ở tên hoặc nội dung tương tự (tất cả cho dù
          liên quan đến bạn hay bên thứ ba) hoặc các tài liệu khác được Người
          dùng (với tư cách là người đóng góp), nhà cung cấp dịch vụ bên thứ ba
          hoặc chúng tôi tải lên. Tuy nhiên, xin lưu ý rằng bất kỳ Nội dung nào
          trên Trang web này có thể đã lỗi thời vào bất kỳ thời điểm nào và
          chúng tôi không có nghĩa vụ phải cập nhật nội dung đó. Chúng tôi không
          đảm bảo rằng Trang web, hoặc bất kỳ Nội dung nào trên Trang web, sẽ
          không có lỗi hoặc thiếu sót.
        </BodyText>
        <LegacyHeaderText>Truy cập trang web</LegacyHeaderText>
        <BodyText>
          Chúng tôi không đảm bảo rằng bạn luôn có thể sử dụng Trang web, hoặc
          bất kỳ Nội dung nào trên đó, hoặc việc sử dụng của bạn sẽ không bị
          gián đoạn. Quyền được phép truy cập vào Trang web là tạm thời. Chúng
          tôi có thể đình chỉ, thu hồi, ngừng hiển thị hoặc thay đổi tất cả hay
          bất kỳ phần nào của Trang web mà không cần thông báo. Chúng tôi sẽ
          không chịu trách nhiệm với bạn nếu Trang web không thể truy cập được
          vào bất kỳ lúc nào hoặc trong bất kỳ khoảng thời gian nào vì bất kỳ lý
          do nào.
        </BodyText>
        <LegacyHeaderText>Thỏa thuận người dùng</LegacyHeaderText>
        <BodyText>
          QUYỀN SỞ HỮU TRÍ TUỆ Ngoài những nơi đã tuyên bố rõ ràng, chúng tôi
          hoặc các công ty thuộc tập đoàn của chúng tôi nắm giữ tất cả các quyền
          sở hữu trí tuệ đối với Trang web và tài liệu được công bố trên Trang
          web (bao gồm nhưng không giới hạn ở văn bản, hình ảnh, âm thanh,
          video, mã html và các nút) (“Nội dung”). Các quyền này bao gồm nhưng
          không giới hạn ở tất cả các bản quyền, quyền sử dụng cơ sở dữ liệu,
          tên thương mại và nhãn hiệu. Bạn chỉ có thể xem, in ra và sử dụng
          Trang web cùng kết quả Xem trước cho mục đích sử dụng cá nhân, phi
          thương mại của riêng bạn. Chúng tôi và các công ty trong tập đoàn của
          chúng tôi có tất cả các quyền sở hữu trí tuệ rõ ràng đối với Trang web
          và Nội dung. Khi sử dụng Trang web và Nội dung, bạn phải tuân theo các
          quy định hạn chế sau. Bạn không được: loại bỏ bất kỳ bản quyền hoặc
          thông báo về nội dung độc quyền khác có trong Nội dung; sử dụng bất kỳ
          Nội dung nào từ Trang web theo bất kỳ cách nào có thể vi phạm đến bản
          quyền, quyền sở hữu trí tuệ hoặc quyền sở hữu của chúng tôi hoặc bất
          kỳ bên thứ ba nào; hoặc sao chép, sửa đổi, hiển thị, thực hiện, xuất
          bản, phân phối, phổ biến, phát sóng, truyền thông cho công chúng hoặc
          lưu hành cho bất kỳ bên thứ ba nào hoặc khai thác Trang web và/hoặc
          Nội dung này theo bất kỳ cách nào, bao gồm cho bất kỳ mục đích thương
          mại nào mà không có văn bản đồng ý trước của chúng tôi. Chúng tôi có
          tất cả các quyền rõ ràng đối với tên miền www.duluxpreviewservice.com
          và tất cả các tên miền cùng miền phụ có liên quan, tên “AkzoNobel”,
          biểu tượng và nhãn hiệu dịch vụ, tên giao dịch và/hoặc nhãn hiệu của
          chúng tôi. Các nhãn hiệu, sản phẩm và tên công ty khác được đề cập
          trên Trang web của chúng tôi có thể là nhãn hiệu của chủ sở hữu hoặc
          người cấp phép tương ứng của họ và các quyền đối với nhãn hiệu đó là
          dành cho chủ sở hữu hoặc người cấp phép tương ứng của họ. ĐỘ CHÍNH XÁC
          VỀ MÀU SẮC Chúng tôi cố gắng hết sức để làm cho màu sắc minh họa trên
          màn hình chính xác nhất có thể. Rất tiếc, chúng tôi không thể đảm bảo
          màu hiển thị trên màn hình trùng khớp hoàn toàn với màu của sản phẩm
          thực tế. Chúng tôi khuyên bạn không nên phụ thuộc hoàn toàn vào màu
          xuất hiện trên màn hình. Màu sắc trên màn hình có thể khác nhau tùy
          thuộc vào chế độ cài đặt và độ phân giải màn hình của bạn. Nếu bạn
          không chắc chắn về màu sắc, vui lòng tham khảo Bảng màu Dulux tại cửa
          hàng đại lý để biết màu chính xác hơn. ĐỘ CHÍNH XÁC CỦA THÔNG TIN VÀ
          NỘI DUNG Nội dung và thông tin được cung cấp trên Trang web của chúng
          tôi là miễn phí và chỉ dành cho mục đích phổ biến thông tin mà không
          nhằm mục đích tạo mối quan hệ về kinh doanh hoặc dịch vụ chuyên nghiệp
          giữa bạn và chúng tôi. Chúng tôi đã nỗ lực hợp lý để đảm bảo rằng tất
          cả thông tin và Nội dung được cung cấp trên Trang web này là chính xác
          tại thời điểm công bố, tuy nhiên đôi khi có thể vô tình nảy sinh sai
          sót. Trang web và thông tin cùng Nội dung trên Trang web được đưa ra
          một cách “nguyên trạng”. Chúng tôi không chắc chắn và không đại diện
          hoặc bảo đảm dưới bất kỳ hình thức nào cho dù rõ ràng hay ngụ ý về
          thông tin và Nội dung được cung cấp thông qua Trang web của chúng tôi,
          bao gồm bất kỳ mục nào trên Trang web của chúng tôi được sử dụng trực
          tiếp hoặc gián tiếp; chúng tôi cũng có quyền thực hiện các thay đổi và
          sửa chữa bất cứ lúc nào mà không cần thông báo. Ý kiến bình luận và
          thông tin được đăng trên Trang web không nhằm mục đích đưa ra lời
          khuyên về căn cứ đáng tin cậy. Chúng tôi không chịu trách nhiệm pháp
          lý đối với bất kỳ sự không chính xác hoặc thiếu sót nào ở Trang web;
          người dùng cũng chịu trách nhiệm toàn bộ đối với bất kỳ quyết định nào
          dựa trên thông tin và ý kiến có trên Trang web. TẢI LÊN HOẶC ĐĂNG TÀI
          LIỆU VÀO TRANG WEB CỦA CHÚNG TÔI Bất cứ khi nào bạn sử dụng tính năng
          cho phép tải nội dung lên Trang web của chúng tôi, bạn phải tuân thủ
          các tiêu chuẩn về nội dung được nêu trong Chính sách sử dụng được chấp
          nhận của chúng tôi. Bạn đảm bảo rằng bất kỳ nội dung đóng góp nào như
          vậy đều phải tuân thủ các tiêu chuẩn đó và bạn sẽ phải chịu trách
          nhiệm và bồi thường cho chúng tôi nếu vi phạm điều khoản bảo đảm đó.
          Do đó, bạn sẽ chịu trách nhiệm cho bất kỳ mất mát hoặc thiệt hại nào
          mà chúng tôi phải chịu nếu bạn vi phạm điều khoản bảo đảm. Bạn cấp cho
          chúng tôi giấy phép không độc quyền, vĩnh viễn, không thể thu hồi, có
          thể chuyển nhượng, miễn phí bản quyền (bao gồm toàn quyền đối với giấy
          phép phụ) để sử dụng bất kỳ nội dung nào mà bạn tải lên hoặc đăng lên
          Trang web của chúng tôi (“Nội dung của bạn”) (bao gồm nhưng không giới
          hạn ở quyền điều chỉnh, thay thế, sửa đổi hoặc thay đổi Nội dung của
          bạn) trên bất kỳ phương tiện truyền thông nào trên toàn thế giới mà
          không có hạn chế. Ngoài ra, bạn đảm bảo rằng mọi tài liệu mà bạn gửi
          là tác phẩm của riêng bạn, hoặc bạn đã có được tất cả các quyền và sự
          cho phép cần thiết của chủ sở hữu có liên quan và bạn có tất cả các
          quyền liên quan trong Nội dung của bạn để có thể cấp các quyền trong
          điều khoản này cho chúng tôi. Theo đó, bạn từ bỏ vô điều kiện và không
          thể hủy ngang cũng như đồng ý không đòi bất kỳ và tất cả các quyền
          nhân thân và bất kỳ quyền tương tự nào khác, cùng tất cả các quyền
          công khai và riêng tư ở bất kỳ quốc gia nào trên thế giới liên quan
          đến việc chúng tôi khai thác các quyền trong Nội dung của bạn được cấp
          dưới đây, dựa trên mức cho phép tối đa của pháp luật. Việc xuất bản
          Nội dung của bạn sẽ do chúng tôi toàn quyền quyết định và chúng tôi có
          quyền bổ sung hoặc xóa bỏ bất kỳ tài liệu nào như vậy trước khi xuất
          bản, sau khi xuất bản hoặc từ chối xuất bản. Trước khi gửi Nội dung
          của bạn cho chúng tôi, nếu Nội dung của bạn chứa bất kỳ tài liệu nào
          không thuộc quyền sở hữu của hoặc được cấp phép cho bạn và/hoặc thuộc
          quyền của bên thứ ba, bạn có trách nhiệm có được quyền sở hữu đối với
          tất cả các bản phát hành, sự đồng ý và/hoặc giấy phép cần thiết để cho
          phép chúng tôi sử dụng và khai thác Nội dung của bạn theo các Điều
          khoản sử dụng này mà không phải trả thêm tiền. Mặc dù chúng tôi không
          có nghĩa vụ kiểm duyệt nội dung trên Trang web của mình, nhưng chúng
          tôi có quyền giám sát bất kỳ thông tin nào được truyền hoặc nhận qua
          Trang web của chúng tôi. Chúng tôi có quyền từ chối, chặn, tạm ngưng
          hoặc xóa Nội dung của bạn bất kỳ lúc nào và căn cứ vào quyết định
          riêng của chúng tôi. Chúng tôi không thể đảm bảo rằng Nội dung của bạn
          hoặc bất kỳ phần nào trong Nội dung đó sẽ được hiển thị trên Trang web
          của chúng tôi. Bạn tuyên bố rằng bạn có quyền hợp pháp gửi thông tin
          đó và đồng ý rằng bạn sẽ không gửi bất kỳ thông tin nào trừ khi bạn có
          quyền hợp pháp làm như vậy. Chúng tôi có quyền sử dụng, sao chép, phân
          phối và tiết lộ cho bên thứ ba bất kỳ nội dung nào như vậy để dùng vào
          bất kỳ mục đích nào. Chúng tôi cũng có quyền tiết lộ danh tính của bạn
          cho bất kỳ bên thứ ba nào tuyên bố rằng tài liệu nào đó do bạn đăng
          hoặc tải lên Trang web của chúng tôi là vi phạm quyền sở hữu trí tuệ
          hoặc quyền riêng tư của họ. Chúng tôi sẽ không chịu trách nhiệm hoặc
          có nghĩa vụ pháp lý với bất kỳ bên thứ ba nào về nội dung hoặc tính
          chính xác của bất kỳ tài liệu nào do bạn hoặc bất kỳ người dùng nào
          khác đăng trên Trang web của chúng tôi. Chúng tôi có quyền xóa bất kỳ
          nội dung hoặc bài đăng nào bạn đưa lên Trang web của chúng tôi, căn cứ
          vào quyết định riêng của chúng tôi. Chúng tôi không chấp nhận việc gửi
          ý tưởng trái phép khi không liên quan đến các mối quan hệ kinh doanh
          đã thiết lập. Vui lòng không truyền đạt về các ý tưởng được gửi trái
          phép cho chúng tôi thông qua Trang web này. Bất kỳ ý tưởng nào được
          tiết lộ cho chúng tôi mà không liên quan đến quan hệ kinh doanh bí mật
          đã có từ trước và được lập thành văn bản đều không phải là bí mật. Do
          đó, chúng tôi sẽ được quyền phát triển, sử dụng, sao chép và/hoặc khai
          thác những ý tưởng này hoặc những ý tưởng tương tự vì mục đích thương
          mại đến mức tối đa và không phải bồi thường hoặc tính công cho bạn.
          Bằng cách gửi ý tưởng hoặc nội dung gửi chi tiết khác cho chúng tôi
          thông qua Trang web này, bạn đồng ý ràng buộc với các điều khoản của
          chính sách đã nêu này.
        </BodyText>
        <LegacyHeaderText>1. Đăng ký</LegacyHeaderText>
        <BodyText>
          Chỉ Người dùng đã đăng ký mới sử dụng được Trang web. Khách hàng có
          năng lực pháp lý để ký kết hợp đồng có thể đăng ký (Những) người dùng
          có thể trở thành (những) Người dùng đã đăng ký bằng cách điền vào biểu
          mẫu đăng ký trực tuyến trên trang web thông qua việc cung cấp một số
          thông tin cơ bản (tên, thông tin liên hệ, địa chỉ cư trú hoặc địa chỉ
          doanh nghiệp, v.v.). AkzoNobel sẽ thiết lập tài khoản ("Tài khoản")
          cho mỗi Người dùng khi đăng ký và chỉ định bí danh người dùng ("ID
          người dùng") và mật khẩu ("Mật khẩu") để đăng nhập vào Tài khoản.
          AkzoNobel cũng sẽ chỉ định Người dùng khi đăng ký tài khoản email/tin
          nhắn trên web với dung lượng lưu trữ hạn chế để gửi hoặc nhận
          email/tin nhắn. Mọi cuộc trao đổi giữa Thợ sơn và Người tiêu dùng chỉ
          có thể do Người tiêu dùng chủ động bắt đầu. Người tiêu dùng sẽ không
          được phép gửi thông báo cho Người dùng khác. Người dùng sẽ chịu trách
          nhiệm về nội dung của tất cả các thông báo được gửi qua Tài khoản của
          họ, cũng như hậu quả của bất kỳ thông báo nào như vậy. Bạn có thể xem
          lại thông tin cá nhân mà bạn đã cung cấp cho chúng tôi và thực hiện
          bất kỳ thay đổi nào nếu muốn đối với thông tin đó, hoặc tùy chọn cài
          đặt cho tài khoản của bạn trên Nền tảng này vào bất kỳ lúc nào, bằng
          cách đăng nhập vào tài khoản và chỉnh sửa thông tin trên trang hồ sơ
          của bạn. Với tư cách là Người tiêu dùng, bạn đại diện và đảm bảo rằng
          bạn là một cá nhân, (i) bạn trên mười tám tuổi hoặc ở trên giới hạn độ
          tuổi được pháp luật cho phép ở quốc gia liên quan để ký kết hợp đồng,
          hoặc (ii) bạn đang đăng ký thay mặt cho một tổ chức mà bạn được phép
          ký kết và ràng buộc tổ chức đó với các Điều khoản này và đăng ký tại
          Nền tảng này, theo luật của Việt Nam. Bất kỳ ai dưới mười tám (18)
          tuổi khi truy cập Trang web chỉ nên hoạt động khi có sự hướng dẫn của
          cha mẹ. Nếu chúng tôi thay đổi tiêu chí đủ điều kiện để được đăng ký
          với Trang web và bạn không còn phù hợp với các tiêu chí đủ điều kiện
          mới căn cứ theo quyết định riêng của chúng tôi, bạn chấp nhận rằng
          chúng tôi có thể đóng Tài khoản của bạn mà không phải chịu bất kỳ
          trách nhiệm pháp lý nào. Bạn hoàn toàn chịu trách nhiệm đảm bảo rằng
          các Điều khoản này tuân thủ tất cả các luật, quy tắc và quy định được
          áp dụng cho bạn và quyền truy cập vào Nền tảng sẽ bị thu hồi khi các
          Điều khoản này hoặc việc sử dụng Nền tảng bị cấm. Trong trường hợp đó,
          bạn đồng ý không sử dụng hoặc truy cập Trang web hay Nền tảng theo bất
          kỳ cách nào. Nếu bạn là Người tiêu dùng đăng ký thay mặt cho một đơn
          vị kinh doanh, bạn sẽ đại diện cho đơn vị kinh doanh đó và (a) bạn có
          quyền ràng buộc đơn vị đó với các điều khoản và điều kiện sử dụng
          và/hoặc Thỏa thuận này; (b) địa chỉ bạn sử dụng khi đăng ký là địa
          điểm hoạt động chính của đơn vị kinh doanh đó; và (c) tất cả các thông
          tin khác được gửi cho AkzoNobel trong quá trình đăng ký là trung thực,
          chính xác, mới nhất và đầy đủ. Bằng cách trở thành Người dùng đã đăng
          ký, bạn đồng ý đưa dữ liệu cá nhân của mình vào cơ sở dữ liệu trực
          tuyến của chúng tôi và cho phép AkzoNobel chia sẻ thông tin đó với các
          đối tác và chi nhánh của mình theo Chính sách bảo mật của chúng tôi.
          Bạn đồng ý rằng AkzoNobel có thể tạm ngưng hoặc chấm dứt Tài khoản của
          bạn bất kỳ lúc nào mà không cần thông báo trước hoặc không cần đưa ra
          bất kỳ lý do nào.
        </BodyText>
        <LegacyHeaderText>2. Điều khoản cuối</LegacyHeaderText>
        <BodyText>
          Người dùng phải đảm bảo rằng mọi Nội dung được xuất bản hoặc tải lên
          trên Trang web không được:
          <ul>
            <li>gây hiểu lầm theo bất kỳ cách nào;</li>
            <li>
              chứa video, ảnh hoặc hình ảnh của người khác (mà không có sự cho
              phép/đồng ý tự nguyện của người đó);
            </li>
            <li>chứa bất kỳ tài liệu nào có tính chất phỉ báng bất kỳ ai;</li>
            <li>
              chứa bất kỳ tài liệu nào có nội dung khiêu dâm, xúc phạm, thù địch
              hoặc gây kích động;
            </li>
            <li>
              có nội dung cực kỳ gây hại, quấy rối, nói xấu báng bổ, tục tĩu,
              khiêu dâm, kích động, bôi nhọ, xâm phạm quyền riêng tư của người
              khác, gây thù hận hoặc phân biệt chủng tộc, phản đối dân tộc, chê
              bai, có liên quan hoặc khuyến khích rửa tiền hay cờ bạc, hoặc có
              nội dung bất hợp pháp dưới bất kỳ hình thức nào;
            </li>
            <li>xâm phạm quyền sở hữu trí tuệ của bất kỳ người nào khác;</li>
            <li>
              có khả năng gây hại cho trẻ vị thành niên theo bất kỳ cách nào
            </li>
            <li>có khả năng lừa dối bất kỳ người nào;</li>
            <li>
              vi phạm bất kỳ nghĩa vụ pháp lý nào đối với bên thứ ba, chẳng hạn
              như nghĩa vụ hợp đồng hoặc nghĩa vụ tín nhiệm;
            </li>
            <li>cản trở việc điều tra bất kỳ hành vi phạm tội nào;</li>
            <li>xúc phạm bất kỳ quốc gia nào khác;</li>
            <li>
              đe dọa, lạm dụng hoặc xâm phạm quyền riêng tư của người khác, hoặc
              gây phiền toái, bất tiện hoặc hoang mang vô cớ;
            </li>
            <li>
              có khả năng quấy rối, làm nhục, gây khó chịu, lăng mạ, gây hoang
              mang hoặc làm phiền bất kỳ người nào khác
            </li>
            <li>
              được sử dụng để mạo danh bất kỳ người nào, hoặc xuyên tạc danh
              tính hay mối quan hệ của bạn với bất kỳ người nào;
            </li>
            <li>
              tạo ấn tượng rằng nội dung là do AkzoNobel công bố, trong trường
              hợp đó không phải sự thật;
            </li>
            <li>
              ủng hộ, thúc đẩy hoặc hỗ trợ bất kỳ hành động bất hợp pháp nào như
              (chỉ bằng ví dụ) vi phạm bản quyền hoặc lạm dụng máy tính;
            </li>
            <li>
              đề cập đến bất kỳ trang web, ứng dụng hoặc URL nào mà theo quy
              định riêng của chúng tôi là có chứa tài liệu không phù hợp với
              Trang web; hoặc bất kỳ trang web nào khác có chứa nội dung bị cấm
              hoặc vi phạm câu chữ hay ý nghĩa của Điều khoản sử dụng này;
            </li>
            <li>
              can thiệp vào việc sử dụng và trải nghiệm Trang web của Người dùng
              khác hoặc việc sử dụng và trải nghiệm các dịch vụ tương tự của bất
              kỳ cá nhân nào khác;
            </li>
            <li>
              lôi kéo bất kỳ hoạt động nào mà chúng tôi cho rằng chính là hoặc
              có thể được hiểu là bất hợp pháp dựa trên quyết định riêng của
              chúng tôi;
            </li>
            <li>
              liên quan đến việc truyền "thư rác", "thư dây chuyền", hoặc gửi
              thư hàng loạt không theo yêu cầu hoặc "gửi thư rác";
            </li>
            <li>
              trực tiếp hoặc gián tiếp chào bán, cố gắng chào bán, giao dịch
              hoặc cố gắng giao dịch bất kỳ mặt hàng nào mà việc kinh doanh mặt
              hàng đó bị cấm hoặc hạn chế theo mọi hình thức theo quy định của
              bất kỳ bộ luật, quy tắc, quy định hoặc hướng dẫn hiện hành nào
              trong thời gian có hiệu lực;
            </li>
            <li>
              phá vỡ hoặc xâm hại các biện pháp bảo mật của chúng tôi hoặc gây
              ảnh hưởng không tốt đến hệ thống hoặc mạng lưới của chúng tôi;
            </li>
            <li>cố làm nhái bất kỳ phần mềm nào của Công ty;</li>
            <li>
              thu thập hoặc lưu trữ thông tin nhận dạng cá nhân của bất kỳ Người
              dùng hoặc khách truy cập nào mà không có sự cho phép của họ;
            </li>
            <li>
              đe dọa sự thống nhất, toàn vẹn, quốc phòng, an ninh hoặc chủ quyền
              của Việt Nam;
            </li>
            <li>
              bán tên người dùng của bạn hoặc lợi dụng tên người dùng để trục
              lợi từ việc đền bù; và
            </li>
            <li>
              khuyến khích hoặc hỗ trợ bất kỳ ai thực hiện bất kỳ điều nào trong
              danh sách này{" "}
            </li>
          </ul>
          AkzoNobel đã thực hiện mọi nỗ lực có thể để đảm bảo rằng ứng
          dụng/trang web không có vi-rút. Tuy nhiên, Công ty không đưa ra dù rõ
          ràng hay ngụ ý về bất kỳ tuyên bố hoặc sự bảo đảm nào về mặt kỹ thuật
          hoặc mặt khác nhằm khẳng định rằng nền tảng này không có lỗi và/hoặc
          vi rút. Người dùng có trách nhiệm đảm bảo cài đặt phần mềm diệt vi-rút
          thích hợp. Người dùng phải dùng ứng dụng/trang web đúng cách, không
          được đưa bất kỳ loại vi-rút, chương trình gây hại, sâu máy tính, mã
          độc ẩn hoặc các tài liệu độc hại hay có hại khác về mặt công nghệ vào
          ứng dụng/trang web. Người dùng không được cố truy cập trái phép vào
          ứng dụng/trang web, máy chủ nơi lưu trữ ứng dụng/trang web, hoặc bất
          kỳ máy chủ, máy tính hoặc cơ sở dữ liệu nào có kết nối với nền tảng
          này. Hành vi vi phạm mục này có thể cấu thành tội phạm. AkzoNobel sẽ
          báo cáo bất kỳ hành vi vi phạm nào cho cơ quan thực thi pháp luật có
          thẩm quyền và hợp tác với họ bằng cách tiết lộ tên của Người dùng. Nếu
          có bất kỳ hành vi vi phạm nào xảy ra, quyền truy cập của Người dùng
          vào ứng dụng/trang web sẽ bị chặn ngay lập tức. Trong phạm vi cho phép
          của luật hiện hành, Akzo Nobel từ chối toàn bộ trách nhiệm hoặc nghĩa
          vụ đối với Người dùng khi có bất kỳ vấn đề nào phát sinh trong hợp
          đồng, lỗi hoặc vấn đề nào khác bao gồm nhưng không giới hạn ở mọi
          thiệt hại gián tiếp hoặc do hậu quả trực tiếp hay mất lợi nhuận/thương
          vụ. Tại mọi thời điểm, Người dùng sẽ chịu trách nhiệm cho mọi thiệt
          hại phát sinh với AkzoNobel và đền bù cho bất kỳ khiếu nại nào chống
          lại AkzoNobel có thể phát sinh trong quá trình sử dụng hoặc liên quan
          đến việc sử dụng Nền tảng/Trang web. Các Điều khoản này được soạn thảo
          bằng tiếng Anh và ngôn ngữ địa phương của quốc gia khác nếu cần. Nếu
          Điều khoản này đã được dịch sang bất kỳ ngôn ngữ nào khác ngoài tiếng
          Anh, văn bản bằng tiếng Anh sẽ được ưu tiên áp dụng. Tất cả các mục
          trong Điều khoản và đều kiện sử dụng chung này được điều chỉnh theo
          luật có hiệu lực tại [tên quốc gia]. Đối với mọi vấn đề liên quan đến
          việc giải thích, tuân thủ hoặc bất kỳ câu hỏi nào khác liên quan đến
          Điều khoản và điều kiện sử dụng chung này, các bên đồng ý đưa ra xem
          xét trong phạm vi quyền hạn duy nhất của tòa án tại địa điểm/thành phố
          nơi AkzoNobel có văn phòng đăng ký
        </BodyText>
      </LegacyBody>
    </LegacyContainer>
  );
};

export default LegalBodyVietnam;
