import React, { useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AddUserCancelImage,
  PreviewSupervisorAddMemberSideBar,
  AddUserDiv,
  AdminUserAddUseImage,
  AdminUserAddtext,
  RoleText,
  PreviewStaffDiv,
  PreviewStaffSupervisorOuterCheckBox,
  PreviewStaffSupervisorInnerCheckBox,
  PreviewStaffSupervisorText,
  SubTitleText,
  PreviewSupervisorDiv,
  SendInviteButton,
  ErrorMessage,
  PreviewSupervisorAddMemberModalDiv,
} from "../../styles/PreviewSupervisorDashboardStyles";
import "../../styles/personDetailsInput.css";
import CANCEL_IMAGE from "../../images/cancel.svg";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import { Formik } from "formik";
import { addUserValidationSchema } from "../../validations/Validations";
import Select from "react-select";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorAddMember = ({
  openAddMember,
  toggleOpenAddMember,
  country,
  previewSupervisorAddUser,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [isPreviewStaffSelected, setIsPreviewStaffSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const previewSupervisorFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const previewSupervisorSuccessCallback = () => {
    setErrorMessage(null);
    toggleOpenAddMember();
  };

  if (width < 1100) {
    return (
      <PreviewSupervisorAddMemberModalDiv openSideMenu={openAddMember}>
        <AddUserCancelImage src={CANCEL_IMAGE} onClick={toggleOpenAddMember} />
        <AddUserDiv>
          <AdminUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <AdminUserAddtext><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ADD_MEMBER"}
            /></AdminUserAddtext>
        </AddUserDiv>
        <Formik
          initialValues={{ email: "", name: "" }}
          onSubmit={(values, actions) => {
            previewSupervisorAddUser({
              email: values.email,
              name: values.name,
              country,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              previewSupervisorSuccessCallback,
              previewSupervisorFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={country}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={true}
                    value={country}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>

                <div
                  className="form-control"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0px auto",
                  }}
                >
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EMAIL"}
            /></label>
                  <div className="border-around"></div>
                </div>

                <div
                  className="form-control"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px auto",
                  }}
                >
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NAME"}
            /></label>
                  <div className="border-around"></div>
                </div>
                <RoleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ROLE"}
            /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF"}
            />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF_ROLE"}
            />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR"}
            />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR_ROLE"}
            />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                <SendInviteButton
                  type="submit"
                  onClick={formikProps.handleSubmit}
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isDisabled={!(formikProps.isValid && formikProps.dirty)}
                >
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SUBMIT_BUTTON"}
            />
                </SendInviteButton>
              </>
            );
          }}
        </Formik>
      </PreviewSupervisorAddMemberModalDiv>
    );
  } else {
    return (
      <PreviewSupervisorAddMemberSideBar openSideMenu={openAddMember}>
        <AddUserCancelImage src={CANCEL_IMAGE} onClick={toggleOpenAddMember} />
        <AddUserDiv>
          <AdminUserAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <AdminUserAddtext><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ADD_MEMBER"}
        /></AdminUserAddtext>
        </AddUserDiv>
        <Formik
          initialValues={{ email: "", name: "" }}
          onSubmit={(values, actions) => {
            previewSupervisorAddUser({
              email: values.email,
              name: values.name,
              country,
              user_type: isPreviewStaffSelected
                ? "Preview Staff"
                : "Preview Supervisor",
              previewSupervisorSuccessCallback,
              previewSupervisorFailureCallback,
            });
          }}
          validationSchema={addUserValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    placeholder={country}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={true}
                    value={country}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      singleValue: (styles) => ({
                        color: "#545850",
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                        marginTop: "20px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#545850",
                        width: "280px",
                      }),
                    }}
                  />
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange("email")}
                    onBlur={formikProps.handleBlur("email")}
                  />
                  <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"EMAIL"}
            /></label>
                  <div className="border-around"></div>
                </div>

                <div className="form-control">
                  <input
                    required
                    type="text"
                    name="input"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange("name")}
                    onBlur={formikProps.handleBlur("name")}
                  />
                  <label><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NAME_DETAILS"}
            /></label>
                  <div className="border-around"></div>
                </div>
                <RoleText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ROLE"}
            /></RoleText>
                <PreviewStaffDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(true)}
                  >
                    {isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF"}
            />
                  </PreviewStaffSupervisorText>
                </PreviewStaffDiv>
                <SubTitleText>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF_ROLE"}
            />
                </SubTitleText>
                <PreviewSupervisorDiv>
                  <PreviewStaffSupervisorOuterCheckBox
                    onClick={() => setIsPreviewStaffSelected(false)}
                  >
                    {!isPreviewStaffSelected && (
                      <PreviewStaffSupervisorInnerCheckBox />
                    )}
                  </PreviewStaffSupervisorOuterCheckBox>

                  <PreviewStaffSupervisorText>
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR"}
            />
                  </PreviewStaffSupervisorText>
                </PreviewSupervisorDiv>
                <SubTitleText>
                <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_SUPERVISOR_ROLE"}
            />
                </SubTitleText>
                {errorMessage !== null && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
                <SendInviteButton
                  type="submit"
                  onClick={formikProps.handleSubmit}
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isDisabled={!(formikProps.isValid && formikProps.dirty)}
                >
                  <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"SUBMIT_BUTTON"}
            />
                </SendInviteButton>
              </>
            );
          }}
        </Formik>
      </PreviewSupervisorAddMemberSideBar>
    );
  }
};

export default PreviewSupervisorAddMember;
