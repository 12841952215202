/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  ConsumerDetailReportContainer,
  ConsumerDetailReportHeader,
  DeluxePreviewServiceImage,
  ConsumerDetailBody,
  FooterDiv,
  SpanReviewText,
  WriteReviewText,
  DownloadButton,
  ShareOnSocials,
  HorizontalRuler,
  BottomRow,
  LogoutButton,
  ShareSocialsImage,
  ShareSocialsDiv,
  ErrorMessage,
  ConsumerDetailNotificationContainer,
} from "../../styles/ConsumerDetailReportStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import {
  ACCESS_KEY_ID,
  BUCKET_NAME,
  CONSUMER_HOME_ROUTE,
  CONSUMER_UPLOAD_IMAGE_ROUTE,
  CONSUMER_WRITE_REVIEW,
  HOME_PAGE_ROUTE,
  SECRET_ACCESS_KEY,
  TOKEN_NAME,
} from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";
import FACBOOK_SOCIAL from "../../images/facebook_social.svg";
import WHATSAPP_IMAGE from "../../images/whatsapp.svg";
import { Link } from "react-router-dom";
import { ColourizationContext } from "../../context/ColourizationContext";

import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import ResultSideBar from "../../components/result/ResultSideBar";
import ConsumerDetailReportResultModal from "../../components/consumer-detail-report/ConsumerDetailReportResultModal";
import ConsumerDetailReportOriginal from "../../components/consumer-detail-report/ConsumerDetailReportOriginal";
import ConsumerDetailReportResult from "../../components/consumer-detail-report/ConsumerDetailReportResult";
import ConsumerDetailReportBack from "../../components/consumer-detail-report/ConsumerDetailReportBack";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AWS from "aws-sdk";
import ConsumerDetailReportRecommendedProduct from "../../components/consumer-detail-report/ConsumerDetailReportRecommendedProduct";
import ConsumerDetailReportPainter from "../../components/consumer-detail-report/ConsumerDetailReportPainter";
import ConsumerDetailReportNearByStores from "../../components/consumer-detail-report/ConsumerDetailReportNearByStores";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerDetailReport = ({ history, location }) => {
  const [selectedModalIndex, setSelectedModalIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [openResultMenu, setOpenResultMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleResultMenuDrawer = () =>
    setOpenResultMenu((previousState) => !previousState);
  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const { width } = useWindowDimensions();

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const colourizationContext = useContext(ColourizationContext);
  const { state, requestReport, checkSurvey, reportDownload } =
    useContext(ConsumerContext);

  const toggleModal = () => setShowModal((previousState) => !previousState);

  const requestReportSuccessCallback = () => {};

  const requestReportFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const goBackHandler = () => {
    history.push(CONSUMER_HOME_ROUTE);
    window.location.reload()
  };

  const recolourAnotherHandler = () => {
    history.push(CONSUMER_UPLOAD_IMAGE_ROUTE);
  };

  const writeReviewHandler = () => {
    history.push(CONSUMER_WRITE_REVIEW, {
      request_id: location.state?.item.request_id,
    });
  };

  const nextModalImageClickHandler = () => {
    if (
      selectedModalIndex <
      state.data?.result?.report_details?.results.length - 1
    ) {
      setSelectedModalIndex(selectedModalIndex + 1);
    }
  };

  const previousModalImageClickHandler = () => {
    if (selectedModalIndex > 0) {
      setSelectedModalIndex(selectedModalIndex - 1);
    }
  };

  const viewDetailHandler = (index) => {
    setSelectedItem(index);
    toggleResultMenuDrawer();
  };

  const checkSurveySuccessCallback = (data) => {
    setIsSurveySubmitted(data.survey_exists);
  };

  const checkSurveyFailureCallback = () => {
    setIsSurveySubmitted(false);
  };

  useEffect(() => {
    checkSurvey({
      request_id: location.state?.item.request_id,
      checkSurveySuccessCallback,
      checkSurveyFailureCallback,
    });
    requestReport({
      request_id: location.state?.item.request_id,
      requestReportSuccessCallback,
      requestReportFailureCallback,
    });
  }, []);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const downloadImage = (fileName) => {
    console.log("-------")
    s3.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: fileName,
      },
      function (error, data) {
        console.log("~~~~~~")
        if (error != null) {
          console.log("errorrrrr")
          alert(`${error.name} ${error.originalError}  ${error.message}`);
        } else {
          let blob = new Blob([data.Body], { type: data.ContentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "file.png";
          link.click();
        }
      }
    );
  };

  const downloadSingleImageHandler = (imageUrl) => {
    window.open(imageUrl);

    // amarisServer
    //   .get(imageUrl, {
    //     responseType: "blob",
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "file.jpeg"); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };
  const reportDownloadSuccessCallback = (data) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.pdf";
    document.body.appendChild(link);
    window.open(link, "_blank")
  };

  const reportDownloadFailureCallback = (msg) => {
    console.log(msg);
    alert("unable to download report");
  };

  const downloadReportClickHandler = () => {
    reportDownload({
      request_id: location.state?.item.request_id,
      reportDownloadSuccessCallback,
      reportDownloadFailureCallback,
    });
  };
  const downImageHandler = () => {
    if (state.data?.result?.report_details?.results?.length > 0) {
      for (
        var i = 0;
        i < state.data?.result?.report_details?.results?.length;
        i++
      ) {
        const fileName = state.data?.result?.report_details?.results[
          i
        ].result_image
          .split("amazonaws.com/")[1]
          .split("?")[0];
        downloadImage(fileName);
        // amarisServer
        //   .get(state.data?.result?.report_details?.results[i].result_image, {
        //     responseType: "blob",
        //   })
        //   .then((response) => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement("a");
        //     link.href = url;
        //     link.setAttribute("download", "file.jpeg"); //or any other extension
        //     document.body.appendChild(link);
        //     link.click();
        //   })
        //   .catch((err) => {
        //     alert(err);
        //   });
      }
    } else {
      alert("No Image to download");
    }
  };

  const shareFile = () => {
    let filesArray = [];
    if (state.data?.result?.report_details?.results?.length > 0) {
      if (navigator.canShare) {
        for (
          var i = 0;
          i < state.data?.result?.report_details?.results?.length;
          i++
        ) {
          const fileName = state.data?.result?.report_details?.results[
            i
          ].result_image
            .split("amazonaws.com/")[1]
            .split("?")[0];
          s3.getObject(
            {
              Bucket: BUCKET_NAME,
              Key: fileName,
            },
            function (error, data) {
              if (error != null) {
                alert(`${error.name} ${error.originalError}  ${error.message}`);
              } else {
                let blob = new Blob([data.Body], { type: data.ContentType });
                blob.lastModifiedDate = new Date();
                blob.name = fileName;
                filesArray.push(blob);
              }
            }
          );
        }

        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              title: "Pictures",
              text: "Our Pictures.",
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => alert("Sharing failed", error));
        } else {
          alert(`Your system doesn't support sharing files.`);
        }
      } else {
        alert(`Your system doesn't support sharing files.`);
      }
    } else {
      alert("No Image to share");
    }
  };

  // const facebookHandler = async () => {
  //   const shareData = {
  //     title: "MDN",
  //     text: "Learn web development on MDN!",
  //     url: "https://developer.mozilla.org",
  //   };
  //   try {
  //     await navigator.share(shareData);
  //     alert("Success");
  //   } catch (err) {
  //     alert(err);
  //   }
  // };

  return (
    <>
      <ResultSideBar
        openResultMenu={openResultMenu}
        toggleResultMenuDrawer={toggleResultMenuDrawer}
        results={state.data?.result?.report_details?.results}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        downloadSingleImageHandler={downloadSingleImageHandler}
        width={width}
      />
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? { value: decodedToken?.country, label: decodedToken?.country }
            : countryValue
        }
      />
      <ConsumerDetailReportContainer
        applyFilter={openLogoutMenu || openResultMenu || showModal}
      >
        <ConsumerDetailReportHeader>
          <Link to={HOME_PAGE_ROUTE}>
            <DeluxePreviewServiceImage
              src={DELUXE_PREVIEW_SERVICE_IMAGE}
              alt="Deluxe Preview"
            />
          </Link>
          {decodedToken && (
            <LogoutButton onClick={toggleLogoutMenu}>
              <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"LOGOUT"}
              />
            </LogoutButton>
          )}
        </ConsumerDetailReportHeader>

        <ConsumerDetailBody>
          <ConsumerDetailReportBack
            goBackHandler={goBackHandler}
            state={state}
            recolourAnotherHandler={recolourAnotherHandler}
            width={width}
            downImageHandler={downImageHandler}
            downloadReportClickHandler={downloadReportClickHandler}
            selectedCountry={
              decodedToken
                ? { value: decodedToken?.country, label: decodedToken?.country }
                : countryValue
            }
          />
          {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ConsumerDetailReportResult
            setSelectedModalIndex={setSelectedModalIndex}
            state={state}
            toggleModal={toggleModal}
            viewDetailHandler={viewDetailHandler}
            selectedCountry={
              decodedToken
                ? { value: decodedToken?.country, label: decodedToken?.country }
                : countryValue
            }
          />
          <ConsumerDetailReportOriginal 
            state={state}
            selectedCountry={
              decodedToken
                ? { value: decodedToken?.country, label: decodedToken?.country }
                : countryValue
            }
          />
          <HorizontalRuler />
          <BottomRow>
            <ConsumerDetailReportRecommendedProduct
              list={state.data?.result?.report_details?.product_details}
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
            />
            <ConsumerDetailReportNearByStores
              list={state.data?.result?.report_details?.shop_details}
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
            />
            <ConsumerDetailReportPainter
              list={state.data?.result?.report_details?.painter_details}
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
            />
          </BottomRow>
          <FooterDiv>
            {width > 1100 && (
              <DownloadButton onClick={downloadReportClickHandler}>
                 <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"DOWNLOAD"}
              />
              </DownloadButton>
            )}
            {isSurveySubmitted ? (
              <WriteReviewText>
                <LanguageSelector
                  selectedCountry={
                    decodedToken
                      ? {
                          value: decodedToken?.country,
                          label: decodedToken?.country,
                        }
                      : countryValue
                  }
                  text={"THANK_YOU_FOR_RATING"}
                />
              </WriteReviewText>
            ) : (
              <WriteReviewText onClick={writeReviewHandler}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <LanguageSelector
                    selectedCountry={
                      decodedToken
                        ? {
                            value: decodedToken?.country,
                            label: decodedToken?.country,
                          }
                        : countryValue
                    }
                    text={"HELP_US_GET_BETTER"}
                  />
                  <SpanReviewText>
                    <div style={{ marginLeft: "5px" }}>
                      <LanguageSelector
                        selectedCountry={
                          decodedToken
                            ? {
                                value: decodedToken?.country,
                                label: decodedToken?.country,
                              }
                            : countryValue
                        }
                        text={"RATE_YOUR_EXPERIENCE"}
                      />
                    </div>
                  </SpanReviewText>{" "}
                </div>
              </WriteReviewText>
            )}
            <ShareOnSocials>
              <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"SHARE_ON_SOCIALS"}
              />
            </ShareOnSocials>
            
            <div className="fb-share-button" data-href="https://www.duluxpreviewservice.com/" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.duluxpreviewservice.com%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>              
            {/* <ShareSocialsDiv>
            <ShareSocialsImage
                src={FACBOOK_SOCIAL}
                alt="Facebook"
                onClick={facebookHandler}
              /> 
              <ShareSocialsImage
                src={WHATSAPP_IMAGE}
                onClick={shareFile}
                alt="Whatsapp"
              /> 
            </ShareSocialsDiv> */}
          </FooterDiv>
          <HorizontalRuler />
          <ConsumerDetailNotificationContainer>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DISCLAIMER_AI"}
            />
          </ConsumerDetailNotificationContainer>
        </ConsumerDetailBody>
      </ConsumerDetailReportContainer>
      {showModal && (
        <ConsumerDetailReportResultModal
          toggleModal={toggleModal}
          previousModalImageClickHandler={previousModalImageClickHandler}
          state={state}
          selectedModalIndex={selectedModalIndex}
          downloadSingleImageHandler={downloadSingleImageHandler}
          nextModalImageClickHandler={nextModalImageClickHandler}
          width={width}
          selectedCountry={
            decodedToken
              ? { value: decodedToken?.country, label: decodedToken?.country }
              : countryValue
          }
        />
      )}
    </>
  );
};

export default ConsumerDetailReport;
