import React, { useState, useEffect, useContext } from "react";
import { Context as AdminContext } from "../../context/AdminContext";
import AdminLeftNavBar from "../../components/admin/AdminLeftNavBar";
import AdminLogout from "../../components/admin/AdminLogout";
import {
  AdminDashboardContainer,
  DrawerVerticalDivider,
} from "../../styles/AdminStyles";
import Cookies from "js-cookie";
import {
  //RESULT_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import AdminCustomers from "./AdminCustomers";
import AdminDashboard from "./AdminDashboard";
import AdminRequests from "./AdminRequests";
import AdminUsers from "./AdminUsers";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Admin = ({ history, location }) => {
  const drawerMenuList = [
    "Dashboard",
    "Requests",
    "Preview Team",
    "Customers",
    "Logout",
  ];

  const { width } = useWindowDimensions();

  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const [addUser, setAddUser] = useState(false);
  const [manageUser, setManageUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [tempRequestList, setTempRequestList] = useState([]);

  const { adminRequestLists } = useContext(AdminContext);

  const getAdminRequests = () => {
    adminRequestLists({
      adminRequestsSuccessCallback,
      adminRequestsFailureCallback,
    });
  };

  const adminRequestsFailureCallback = (msg) => {
    console.log(msg);
  };

  const adminRequestsSuccessCallback = (data) => {
    setRequestList(data.requests);
    setTempRequestList(data.requests);
    // updatePagination(data.requests);
  };

  // useEffect(() => {
  //     getAdminRequests();
  // }, [archivedFlag]);

  useEffect(() => {
    const menuFromPrevious =
      history.location.state?.menu || location.state?.menu;
    // const menuFromPrevious = location.state?.menu
    // const menuFromPrevious = location.state?.menu
    // const menuFromPrevious = location.state?.menu
    if (menuFromPrevious !== undefined) {
      setSelectedMenu(menuFromPrevious);
    }
  }, [history]);

  useEffect(() => {
    if (history.location.state?.requestList) {
      // console.log("Request list exists setting request list")
      setRequestList(history.location.state?.requestList);
      setTempRequestList(history.location.state?.requestList);
    } else {
      // console.log("Request list doesn't exist")
      getAdminRequests();
    }
  }, []);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);
  const toggleAddUser = (country) =>
    setAddUser((previousState) => !previousState);
  const toggleManageUser = (data) => {
    setSelectedUser(data);
    setManageUser((previousState) => !previousState);
  };

  const logoutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    history.replace(HOME_PAGE_ROUTE);
  };

  return (
    <>
      <AdminLogout
        openSideMenu={openLogoutMenu}
        logOutHandler={logoutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
      />
      <AdminDashboardContainer applyFilter={openLogoutMenu}>
        <AdminLeftNavBar
          history={history}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          drawerMenuList={drawerMenuList}
          applyFilter={addUser || manageUser}
          toggleLogoutMenu={toggleLogoutMenu}
          width={width}
        />
        {width > 1100 && <DrawerVerticalDivider />}
        {selectedMenu === "Dashboard" && <AdminDashboard width={width} />}
        {selectedMenu === "Requests" && (
          <AdminRequests
            history={history}
            width={width}
            getAdminRequests={getAdminRequests}
            requestList={requestList}
            tempRequestList={tempRequestList}
            setRequestList={setRequestList}
            setTempRequestList={setTempRequestList}
            tabSelectedNew={location.state?.tabSelected}
            pageSelectedResolved={location.state?.selectedPageForPending}
            pageSelectedPending={location.state?.selectedPageForResolved}
          />
        )}
        {selectedMenu === "Preview Team" && (
          <AdminUsers
            addUser={addUser}
            manageUser={manageUser}
            toggleAddUser={toggleAddUser}
            toggleManageUser={toggleManageUser}
            selectedUser={selectedUser}
            width={width}
          />
        )}
        {selectedMenu === "Customers" && <AdminCustomers width={width} />}
      </AdminDashboardContainer>
    </>
  );
};

export default Admin;
