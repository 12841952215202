import React from "react";
import {
  PrivacyPolicyContainer,
  PrivacyPolicyBody,
  PrivacyPolicyHeaderText,
  BodyText,
} from "../../styles/PrivacyPolicyStyles";

const PrivacyPolicyBodyIndonesia = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyBody>
        {/* <PrivacyPolicyHeaderText>Kebijakan Privasi</PrivacyPolicyHeaderText>
        <BodyText>
          Kami mengakui bahwa privasi sangat penting dan menanganinya secara
          serius. Kebijakan Privasi ini (“Kebijakan Privasi”) menjelaskan
          kebijakan dan prosedur kami mengenai pengumpulan, penggunaan dan
          pengungkapan informasi Anda, apabila Anda menggunakan Situs Web. Kami
          tidak akan menggunakan atau berbagi informasi Anda dengan siapa pun,
          kecuali sebagaimana dijelaskan dalam Kebijakan Privasi ini. Kebijakan
          Privasi ini adalah bagian dari, serta disatukan dalam, dan harus
          dibaca beserta dengan Persyaratan Penggunaan (“Persyaratan”). Istilah
          dalam huruf besar yang digunakan dalam Kebijakan Privasi ini, tetapi
          tidak ditentukan di sini, akan memiliki arti yang diberikan ke istilah
          tersebut dalam Persyaratan. Kapan Pernyataan Privasi ini berlaku?
          Kebijakan Privasi ini berlaku untuk (a) Pengguna terdaftar, dan (b)
          semua orang (termasuk individual) selain dari Pengguna terdaftar, yang
          secara langsung atau tidak langsung, mengakses situs web. Untuk tujuan
          apa kami memproses data pribadi Anda? Kami menggunakan data pribadi
          Anda untuk berbagai tujuan. Di bawah ini, Anda akan mendapatkan
          rangkuman jenis data pribadi dan tujuan penggunaannya. Kami memproses
          data teknis, seperti alamat IP Anda, peramban yang Anda gunakan,
          halaman yang dikunjungi, situs yang dikunjungi sebelum / sesudah, dan
          durasi kunjungan dalam rangka meningkatkan fungsionalitas Situs Web,
          dan memungkinkan Anda untuk menemukan informasi yang Anda cari, secara
          sederhana dan cepat. Data tersebut juga digunakan untuk menyelesaikan
          kesulitan teknis atau untuk meningkatkan aksesibilitas bagian tertentu
          Situs Web. Untuk tujuan ini , kami juga menggunakan cookie. Apabila
          memilih untuk mendaftar dengan kami, Anda perlu memberikan data
          pribadi, seperti nama Anda, alamat email yang valid, alamat tempat
          tinggal atau bisnis Anda serta informasi lainnya yang terkait, agar
          kami dapat membuat Akun untuk Anda. Data pribadi Anda memungkinkan
          kami mengelola akun Anda, menjamin kerahasiaan dan melangsungkan akses
          keamanan. Kami juga memproses data, seperti nama tampilan, peran dan
          data akses Anda. Sebagai contoh, kami dapat mengubah kata sandi bagi
          Anda. Namun demikian, kami tidak dapat melihat kata sandi Anda. Kami
          menggunakan data pribadi agregat untuk menganalisis perilaku
          pengunjung dan menyesuaikan penawaran kami sebagaimana kesesuaiannya
          untuk memastikan bahwa penawaran tersebut relevan bagi para pelanggan
          kami. Hal ini berarti bahwa kami menganalisis, seberapa sering Anda
          mengunjungi aplikasi / situs web kami. Berdasarkan informasi ini, kami
          dapat menyesuaikan penawaran dan newsletter kami. Kami juga dapat
          melakukan survei tren pasar melalui analisis statistik untuk
          mengevaluasi dan mengadaptasi produk serta iklan kami menurut
          pengembangan baru, tetapi hasil survei tersebut hanya diungkapkan
          secara agregat. Kami dapat membeli data tambahan dari sumber publik
          untuk melengkapi database kami untuk tujuan yang diuraikan di atas.
          Kami dapat mengirim email yang berisi promosi, berita dan undangan
          untuk berpartisipasi dalam berbagai acara. Jika Anda memilih untuk
          berpartisipasi dalam salah satu dari aktivitas ini yang mana pun, kami
          memerlukan data pribadi Anda agar dapat mengiklankan dan
          menyelenggarakan berbagai acara tersebut. Untuk ini, kami memproses
          nama, alamat, alamat email dan partisipasi Anda dalam aktivitas. Kami
          mungkin menghubungi Anda dan/atau mengirimkan SMS kepada Anda untuk
          mengumpulkan detail penggunaan dan pengalaman Anda pada Platform/situs
          web, termasuk detail produk yang digunakan untuk layanan pengecatan.
          Anda setuju dan mengizinkan, bahwa kami dapat mengumpulkan, menangani,
          memproses, menggunakan, menyimpan, mentransfer, menukar, berbagi,
          menyebarkan, menghancurkan, atau memanfaatkan informasi pribadi Anda.
          Tanpa mengabaikan apa pun yang bertentangan, Anda selanjutnya dengan
          tegas setuju dan mengizinkan bahwa kami dapat menggunakan informasi
          pribadi Anda untuk tujuan yang diberikan di atas dan berbagi informasi
          pribadi tersebut tanpa batasan kepada pihak ketiga dengan cara yang
          tertera di bawah ini. Siapa yang memiliki akses ke data Anda? Kami
          memperlakukan data Anda dengan hati-hati dan secara rahasia; kami
          tidak berbagi data Anda dengan pihak ketiga yang tidak secara
          eksplisit dinyatakan dalam Kebijakan Privasi ini. Dengan entitas
          lainnya dalam grup AkzoNobel: Kami dapat berbagi informasi mengenai
          konsumen kami dalam bentuk nama samaran, anonim atau gabungan dengan
          anggota Grup AkzoNobel lainnya sehingga informasi tersebut dapat
          digunakan misalnya, untuk analisis tren. Harap diperhatikan bahwa data
          dapat ditransfer dan/atau di-host di luar Indonesia ke beberapa
          lokasi. Dengan penyedia layanan: Dengan menggunakan beberapa
          perusahaan yang memproses data atas nama kami, seperti perusahaan
          host, layanan pengiriman. Semua perusahaan ini menggunakan informasi
          Anda atas nama kami, dan tunduk pada aturan ketat yang harus diikuti
          apabila memproses informasi Anda dan standar perlindungan data yang
          serupa seperti yang kami laksanakan. Kami tidak memperbolehkan
          perusahaan tersebut menggunakan data dengan cara lain yang tidak
          sesuai dengan instruksi kami. Pihak ketiga lainnya: Kami mungkin
          diwajibkan untuk mengungkapkan informasi pribadi atas permintaan
          lembaga pemerintah atau untuk menjaga ketertiban umum. Kami juga dapat
          berbagi data Anda dengan para mitra saluran kami, termasuk
          distributor, dealer dan/atau peritel. Kami hanya akan berbagi
          informasi Anda dengan perusahaan atau individu lain jika kami memiliki
          persetujuan Anda untuk melakukan hal itu. Bagaimana cara menyikapi
          berbagai tautan ke situs dan media sosial lain?
        </BodyText>
        */}
        <PrivacyPolicyHeaderText>Kebijakan Privasi</PrivacyPolicyHeaderText>
        <BodyText>
          Kami mengakui bahwa privasi sangat penting dan menanganinya secara
          serius. Kebijakan Privasi ini (“Kebijakan Privasi”) menjelaskan
          kebijakan dan prosedur kami mengenai pengumpulan, penggunaan dan
          pengungkapan informasi Anda, apabila Anda menggunakan Situs Web. Kami
          tidak akan menggunakan atau berbagi informasi Anda dengan siapa pun,
          kecuali sebagaimana dijelaskan dalam Kebijakan Privasi ini. Kebijakan
          Privasi ini adalah bagian dari, serta disatukan dalam, dan harus
          dibaca beserta dengan Persyaratan Penggunaan (“Persyaratan”). Istilah
          dalam huruf besar yang digunakan dalam Kebijakan Privasi ini, tetapi
          tidak ditentukan di sini, akan memiliki arti yang diberikan ke istilah
          tersebut dalam Persyaratan. Kapan Pernyataan Privasi ini berlaku?
          Kebijakan Privasi ini berlaku untuk (a) Pengguna terdaftar, dan (b)
          semua orang (termasuk individual) selain dari Pengguna terdaftar, yang
          secara langsung atau tidak langsung, mengakses situs web. Untuk tujuan
          apa kami memproses data pribadi Anda? Kami menggunakan data pribadi
          Anda untuk berbagai tujuan. Di bawah ini, Anda akan mendapatkan
          rangkuman jenis data pribadi dan tujuan penggunaannya. Kami memproses
          data teknis, seperti alamat IP Anda, peramban yang Anda gunakan,
          halaman yang dikunjungi, situs yang dikunjungi sebelum / sesudah, dan
          durasi kunjungan dalam rangka meningkatkan fungsionalitas Situs Web,
          dan memungkinkan Anda untuk menemukan informasi yang Anda cari, secara
          sederhana dan cepat. Data tersebut juga digunakan untuk menyelesaikan
          kesulitan teknis atau untuk meningkatkan aksesibilitas bagian tertentu
          Situs Web. Untuk tujuan ini , kami juga menggunakan cookie. Apabila
          memilih untuk mendaftar dengan kami, Anda perlu memberikan data
          pribadi, seperti nama Anda, alamat email yang valid, alamat tempat
          tinggal atau bisnis Anda serta informasi lainnya yang terkait, agar
          kami dapat membuat Akun untuk Anda. Data pribadi Anda memungkinkan
          kami mengelola akun Anda, menjamin kerahasiaan dan melangsungkan akses
          keamanan. Kami juga memproses data, seperti nama tampilan, peran dan
          data akses Anda. Sebagai contoh, kami dapat mengubah kata sandi bagi
          Anda. Namun demikian, kami tidak dapat melihat kata sandi Anda. Kami
          menggunakan data pribadi agregat untuk menganalisis perilaku
          pengunjung dan menyesuaikan penawaran kami sebagaimana kesesuaiannya
          untuk memastikan bahwa penawaran tersebut relevan bagi para pelanggan
          kami. Hal ini berarti bahwa kami menganalisis, seberapa sering Anda
          mengunjungi aplikasi / situs web kami. Berdasarkan informasi ini, kami
          dapat menyesuaikan penawaran dan newsletter kami. Kami juga dapat
          melakukan survei tren pasar melalui analisis statistik untuk
          mengevaluasi dan mengadaptasi produk serta iklan kami menurut
          pengembangan baru, tetapi hasil survei tersebut hanya diungkapkan
          secara agregat. Kami dapat membeli data tambahan dari sumber publik
          untuk melengkapi database kami untuk tujuan yang diuraikan di atas.
          Kami dapat mengirim email yang berisi promosi, berita dan undangan
          untuk berpartisipasi dalam berbagai acara. Jika Anda memilih untuk
          berpartisipasi dalam salah satu dari aktivitas ini yang mana pun, kami
          memerlukan data pribadi Anda agar dapat mengiklankan dan
          menyelenggarakan berbagai acara tersebut. Untuk ini, kami memproses
          nama, alamat, alamat email dan partisipasi Anda dalam aktivitas. Kami
          mungkin menghubungi Anda dan/atau mengirimkan SMS kepada Anda untuk
          mengumpulkan detail penggunaan dan pengalaman Anda pada Platform/situs
          web, termasuk detail produk yang digunakan untuk layanan pengecatan.
          Anda setuju dan mengizinkan, bahwa kami dapat mengumpulkan, menangani,
          memproses, menggunakan, menyimpan, mentransfer, menukar, berbagi,
          menyebarkan, menghancurkan, atau memanfaatkan informasi pribadi Anda.
          Tanpa mengabaikan apa pun yang bertentangan, Anda selanjutnya dengan
          tegas setuju dan mengizinkan bahwa kami dapat menggunakan informasi
          pribadi Anda untuk tujuan yang diberikan di atas dan berbagi informasi
          pribadi tersebut tanpa batasan kepada pihak ketiga dengan cara yang
          tertera di bawah ini. Siapa yang memiliki akses ke data Anda? Kami
          memperlakukan data Anda dengan hati-hati dan secara rahasia; kami
          tidak berbagi data Anda dengan pihak ketiga yang tidak secara
          eksplisit dinyatakan dalam Kebijakan Privasi ini.
        </BodyText>
        <ul>
          <li>
            Dengan entitas lainnya dalam grup AkzoNobel: Kami dapat berbagi
            informasi mengenai konsumen kami dalam bentuk nama samaran, anonim
            atau gabungan dengan anggota Grup AkzoNobel lainnya sehingga
            informasi tersebut dapat digunakan misalnya, untuk analisis tren.
            Harap diperhatikan bahwa data dapat ditransfer dan/atau di-host di
            luar Indonesia ke beberapa lokasi.
          </li>
          <li>
            Dengan penyedia layanan: Dengan menggunakan beberapa perusahaan yang
            memproses data atas nama kami, seperti perusahaan host, layanan
            pengiriman. Semua perusahaan ini menggunakan informasi Anda atas
            nama kami, dan tunduk pada aturan ketat yang harus diikuti apabila
            memproses informasi Anda dan standar perlindungan data yang serupa
            seperti yang kami laksanakan. Kami tidak memperbolehkan perusahaan
            tersebut menggunakan data dengan cara lain yang tidak sesuai dengan
            instruksi kami.
          </li>
          <li>
            Pihak ketiga lainnya: Kami mungkin diwajibkan untuk mengungkapkan
            informasi pribadi atas permintaan lembaga pemerintah atau untuk
            menjaga ketertiban umum. Kami juga dapat berbagi data Anda dengan
            para mitra saluran kami, termasuk distributor, dealer dan/atau
            peritel.
          </li>
        </ul>
        <BodyText>
          Kami hanya akan berbagi informasi Anda dengan perusahaan atau individu
          lain jika kami memiliki persetujuan Anda untuk melakukan hal itu.
          Bagaimana cara menyikapi berbagai tautan ke situs dan media sosial
          lain?
        </BodyText>

        <PrivacyPolicyHeaderText>Situs eksternal</PrivacyPolicyHeaderText>
        <BodyText>
          Di Situs Web, Anda dapat menemukan beberapa tautan ke situs lain.
          Meskipun semua situs ini dipilih dengan hati-hati, namun kami tidak
          bertanggung jawab atas pemrosesan data pribadi Anda melalui situs lain
          ini. Oleh karena itu, Persyaratan dan Kebijakan Privasi ini tidak
          berlaku untuk penggunaan situs-situs tersebut.
        </BodyText>
        <PrivacyPolicyHeaderText>Media sosial</PrivacyPolicyHeaderText>
        <BodyText>
          Situs Web dapat menyediakan plug-in sosial dari berbagai jaringan
          sosial. Jika Anda memilih untuk berinteraksi dengan jaringan sosial
          (dengan mendaftarkan akun, misalnya), aktivitas Anda pada Situs Web
          juga akan tersedia untuk jaringan sosial, seperti Facebook dan
          Twitter. Jika Anda terhubung ke salah satu dari jaringan sosial ini
          sewaktu kunjungan Anda ke Situs Web, jaringan sosial dapat menambahkan
          informasi tersebut ke masing-masing profilnya pada platform. Jika Anda
          berinteraksi dengan salah satu dari plug-in sosial, informasi itu akan
          ditransfer ke jaringan sosial. Jika Anda tidak ingin data tersebut
          ditransfer, putuskan hubungan dari jaringan sosial Anda sebelum
          memasuki Situs Web kami. Bagaimana kami melindungi data? Data pribadi
          Anda dirahasiakan secara ketat dan kami menerapkan tindakan keamanan
          teknis serta organisasi yang komprehensif dan wajar terhadap
          kehilangan atau pemrosesan data tersebut yang menyalahi hukum, dengan
          mematuhi ketetapan Undang-Undang Teknologi Informasi, 2000. Untuk
          tujuan ini, kami menggunakan beberapa teknik keamanan, termasuk server
          yang aman, firewall dan enkripsi, selain perlindungan fisik lokasi
          tempat data disimpan.
        </BodyText>
        <PrivacyPolicyHeaderText>Penahanan data</PrivacyPolicyHeaderText>
        <BodyText>
          Kami tidak akan menahan data pribadi Anda untuk jangka waktu yang
          lebih lama daripada yang diperlukan untuk mencapai tujuan atas
          pengumpulannya atau untuk jangka waktu maksimal yang ditentukan oleh
          undang-undang. Jika Anda adalah pengguna terdaftar, Anda memiliki hak
          berikut berdasarkan Kebijakan Privasi: Hak data pribadi: Anda akan
          berhak, atas permintaan, untuk mengakses dan meninjau setiap “data
          atau informasi pribadi yang sensitif”, yang diberikan oleh Anda dan
          ditahan oleh kami. Setelah menemukan bahwa “data atau informasi
          pribadi yang sensitif”, yang diberikan tidak akurat atau tidak lengkap
          dengan cara apa pun, Anda berhak meminta perubahan atau amandemen yang
          sesuai untuk data tersebut. Jika memungkinkan untuk dilakukan, kami
          dapat membuat perubahan yang sesuai dalam database-nya untuk
          mencerminkan amandemen yang diminta tersebut. Hak Akun: Anda dapat
          mengontrol pengaturan akun Anda dan mengelola komunikasi apa yang Anda
          terima dari kami, dan cara Anda menerimanya. Anda juga dapat
          memodifikasi kata sandi atau menutup akun Anda jika Anda memilih untuk
          melakukannya. Hak Persetujuan: Anda juga akan memiliki opsi untuk
          menarik kembali persetujuan terdahulu yang diberikan kepada kami.
          Dalam kasus tersebut, kami mungkin tidak lagi berkewajiban untuk terus
          menyediakan Platform/Situs Web.
        </BodyText>
      </PrivacyPolicyBody>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyBodyIndonesia;
