import React, { useContext, useRef, useState } from "react";
import ConsumerDragAndDropContainer from "../../components/consumer-upload-image/ConsumerDragAndDropContainer";
import ConsumerUploadImageHeader from "../../components/consumer-upload-image/ConsumerUploadImageHeader";
import ConsumerUploadSelectedImage from "../../components/consumer-upload-image/ConsumerUploadSelectedImage";
import {
  ConsumerContainer,
  NextButton,
  UploadImageButton,
  UploadImageText,
} from "../../styles/ConsumerUploadImageStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_IMAGE_OPTIONS_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import { ColourizationContext } from "../../context/ColourizationContext";
// import { Context as AuthContext } from "../../context/AuthContext";
import BackButton from "../../components/BackButton";
import ConsumerReferenceUploadInstructions from "../../components/consumer-reference-image/ConsumerReferenceUploadInstructions";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const ConsumerUploadImage = ({ history }) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  console.log(countryValue, countryValue.value)
  const { width } = useWindowDimensions();

  const imageRef = useRef(null);

  const colourizationContext = useContext(ColourizationContext);
  // const { state, verifyEmail, verifyEmailOTP } = useContext(AuthContext);

  // const [openSideMenu, setOpenSideMenu] = useState(false);
  // const [email, setEmail] = useState("");
  const [image, setImage] = useState([
    // {
    //   imageUrl: "",
    //   imageName: "1234.png",
    //   imageSize: "12 mb",
    // },
  ]);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  let reader;

  const uploadImageClickHandler = () => {
    imageRef.current.click();
  };

  const cancelUploadedImageHandler = () => {
    setImage([]);
  };

  const createListItem = (evt, file, imageHeight, imageWidth) => {
    console.log(file.type);
    console.log(file);
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      alert(
        language["YOUR_IMAGE_MUST_BE_5_MB"][
          (countryValue.value || decodedToken.country ).toLowerCase()
        ] || language["YOUR_IMAGE_MUST_BE_5_MB"]["Singapore"]
      );
    } else {
      if (
        (countryValue.value || decodedToken.country) === "Vietnam" &&
        (imageHeight < 256 || imageWidth < 256)
      ) {
        const text =
          language["WARN_IMAGE_SIZE_TOO_SMALL"][
            (countryValue.value || decodedToken.country).toLowerCase()
          ];
        alert(`${text} + ${imageHeight} x ${imageWidth}`);
      } else if (
        (countryValue.value || decodedToken.country) === "Vietnam" &&
        fileSize < 0.8
      ) {
        alert("Hình ảnh tải lên phải lớn hơn hoặc bằng 800 KB.");
      } else {
        setImage([
          {
            imageUrl: evt.target.result,
            imageName: file.name,
            imageSize: fileSize.toFixed(2) + " mb",
          },
        ]);
        colourizationContext.setColourizationDetails({
          raw_image: evt.target.result,
          colourization_type: "",
          reference: "",
          theme: "",
          lead_colour: "",
          email: "",
          name: "",
          city: "",
          country: "",
        });
      }
    }
  };

  const processWithUploadedImageDimensions = (callback, args) => {
    var image = new Image();
    image.src = reader.result;
    image.onload = function () {
      args.push(this.height);
      args.push(this.width);
      callback(...args);
    };
  };

  const readFile = (input) => {
    let allowedFileTypes = ["image/png", "image/jpeg"];
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        reader = new FileReader();

        reader.onloadend = (function (file) {
          if (allowedFileTypes.includes(file.type)) {
            return function (evt) {
              processWithUploadedImageDimensions(createListItem, [evt, file]);
            };
          } else {
            if ( countryValue.value == "Vietnam"|| 
              decodedToken.country === "Vietnam" 
            )
              alert("Vui lòng tải lên hình ảnh ở định dạng JPEG hoặc PNG.");
            else {
              alert("Please upload image in JPEG or PNG format.");
            }
          }
        })(input.files[i]);
        reader.readAsDataURL(input.files[i]);
      }
    }
  };

  const goToImageOptions = () => {
    history.push(CONSUMER_IMAGE_OPTIONS_ROUTE);
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  const [data, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  // const toggleSideMenu = () =>
  //   setOpenSideMenu((previousState) => !previousState);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={
          decodedToken
            ? {
                value: decodedToken?.country,
                label: decodedToken?.country,
              }
            : countryValue
        }
      />
      <ConsumerContainer applyFilter={openLogoutMenu}>
        <input
          type="file"
          id="file"
          ref={imageRef}
          style={{ display: "none" }}
          onChange={(event) => {
            readFile(event.target);
          }}
          multiple={false}
        />
        <ConsumerUploadImageHeader
          width={width}
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          goBackHandler={goBackHandler}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        {(countryValue.value || decodedToken.country) === "India" ? (
          <UploadImageText>
            Upload an image of the interior wall you wish to re-paint
          </UploadImageText>
        ) : null}
        {image.length === 0 ? (
          width > 1100 && (
            <ConsumerDragAndDropContainer
              data={data}
              dispatch={dispatch}
              reader={reader}
              createListItem={createListItem}
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
            />
          )
        ) : (
          <ConsumerUploadSelectedImage
            width={width}
            image={image}
            uploadImageClickHandler={uploadImageClickHandler}
            cancelUploadedImageHandler={cancelUploadedImageHandler}
            selectedCountry={
              decodedToken
                ? {
                    value: decodedToken?.country,
                    label: decodedToken?.country,
                  }
                : countryValue
            }
          />
        )}
        {image.length === 0 ? (
          <UploadImageButton onClick={uploadImageClickHandler}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"UPLOAD_IMAGE"}
            />
          </UploadImageButton>
        ) : (
          <NextButton onClick={goToImageOptions}>
            <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"NEXT"}
            />
          </NextButton>
        )}
        <ConsumerReferenceUploadInstructions
          width={width}
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
        />
        {/* {colourizationContext.colourizationDetails.email !== "" && (
          <LoginToUploadButton onClick={toggleSideMenu}>
            Login to upload more than one image
          </LoginToUploadButton>
        )} */}
      </ConsumerContainer>
    </>
  );
};

export default ConsumerUploadImage;
