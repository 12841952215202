import styled from "styled-components";

export const ConsumerWriteReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const ConsumerWriteReviewHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100px;
  /* @media (max-width: 1500px) { 
    padding: 0px;
    margin: 0px;
  } */
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const DeluxePreviewServiceImage = styled.img`
  margin: 0px 4vw 0px 6vw;
  align-self: center;
  object-fit: cover;
  @media (max-width: 1100px) {
    padding: 0px;
    margin: 0px;
  }
`;

export const ConsumerWriteReviewBodyRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BackDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 100px;
  justify-content: center;
  /* flex: 0.1; */
`;

export const BackImage = styled.img`
  margin: 0px 10px;
  object-fit: contain;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const BackText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #545850;
  margin: 0;
`;

export const WriteReviewBodyMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    margin: 0 10px;
  }
`;

export const TitleText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin: 10px 0;
`;

export const WriteReviewImage = styled.img`
  object-fit: contain;
`;

export const QuestionnarieDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionarrieText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  color: #545850;
`;

export const RangeSliderTextDiv = styled.div`
  width: ${(props) => `${props.width}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
`;

export const RangeSliderText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
`;

export const RangerInputSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  padding: 0;
  z-index: -1;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2fc38c;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2fc38c;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }
`;

export const RangeProgressBar = styled.div`
  width: ${(props) => `${props.sliderValue}%`};
  height: 10px;
  background: #012169;
  border-radius: 5px;
  position: absolute;
  top: 6px;
  left: 0px;
  padding: 0;
  margin: 0;
`;

export const SubmitButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  width: 100px;
  margin-bottom: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;
