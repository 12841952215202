import React, { useState, useContext } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  DownloadInvoiceSideBarDiv,
  FilterDateDiv,
  FilterDateAddUseImage,
  FilterDateAddtext,
  AddUserCancelImage,
  DoneButton,
  ByTime,
  DownloadInvoiceModalBarDiv,
} from "../../styles/AdminDashboardStyles";
import { ADMIN_DASHBOARD_ROUTE } from "../../utils/Constants";
import CANCEL_IMAGE from "../../images/cancel.svg";
import Select from "react-select";
import MY_TEAM_BLACK_IMAGE from "../../images/my_team_black.svg";
import { Context as AdminContext } from "../../context/AdminContext";
import LanguageSelector from "../../language/LanguageSelector";

const AdminDashboardDownloadInvoiceDrawer = ({
  history,
  downloadInvoiceDrawer,
  toggleDownloadInvoiceDrawer,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];
  const years = [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
  ];

  const { adminInvoiceDownload } = useContext(AdminContext);

  const [tempFromDateYear, setTempFromDateYear] = useState(years[0]);
  const [tempFromMonth, setTempFromMonth] = useState(months[0]);
  const [tempToDateYear, setTempToDateYear] = useState(years[0]);
  const [tempToMonth, setTempToMonth] = useState(months[0]);

  const adminInvoiceSuccessCallback = (data) => {
    console.log("admin invoice success##############")

    // const blob = new Blob([fileData], { type: fileDataContentType });
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'randomFileName';
    // link.click();

    const url = window.URL.createObjectURL(new Blob([data], { type: "application/zip" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = "invoice.zip";
    document.body.appendChild(link);
    link.click();
  };

  const adminInvoiceFailureCallback = (msg) => {
    console.log(msg);
    alert("unable to download invoice");
  };

  const submitHandler = () => {
    adminInvoiceDownload({
      tempFromDateYear: tempFromDateYear.value,
      tempFromMonth: tempFromMonth.value,
      tempToDateYear: tempToDateYear.value,
      tempToMonth: tempToMonth.value,
      adminInvoiceSuccessCallback,
      adminInvoiceFailureCallback,
    });
  };

  if (width < 1100) {
    return (
      <DownloadInvoiceModalBarDiv openSideMenu={downloadInvoiceDrawer}>
        <AddUserCancelImage
          src={CANCEL_IMAGE}
          onClick={toggleDownloadInvoiceDrawer}
        />
        <FilterDateDiv>
          <FilterDateAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <FilterDateAddtext><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD_INVOICE"}
            /></FilterDateAddtext>
        </FilterDateDiv>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FROM"}
            /></ByTime>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Select
            value={tempFromMonth}
            onChange={setTempFromMonth}
            options={months}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
                margin: "10px",
              }),
            }}
          />
          <Select
            value={tempFromDateYear}
            onChange={setTempFromDateYear}
            options={years}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
                margin: "10px",
              }),
            }}
          />
        </div>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TO"}
            /></ByTime>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Select
            value={tempToMonth}
            onChange={setTempToMonth}
            options={months}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
                margin: "10px",
              }),
            }}
          />
          <Select
            value={tempToDateYear}
            onChange={setTempToDateYear}
            options={years}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
                margin: "10px",
              }),
            }}
          />
        </div>
        <DoneButton type="submit" onClick={submitHandler}>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            />
        </DoneButton>
      </DownloadInvoiceModalBarDiv>
    );
  } else {
    return (
      <DownloadInvoiceSideBarDiv openSideMenu={downloadInvoiceDrawer}>
        <AddUserCancelImage
          src={CANCEL_IMAGE}
          onClick={toggleDownloadInvoiceDrawer}
        />
        <FilterDateDiv>
          <FilterDateAddUseImage src={MY_TEAM_BLACK_IMAGE} />
          <FilterDateAddtext><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD_INVOICE"}
            /> </FilterDateAddtext>
        </FilterDateDiv>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"FROM"}
            /></ByTime>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Select
            value={tempFromMonth}
            onChange={setTempFromMonth}
            options={months}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
              }),
            }}
          />
          <Select
            value={tempFromDateYear}
            onChange={setTempFromDateYear}
            options={years}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
              }),
            }}
          />
        </div>
        <ByTime><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"TO"}
            /></ByTime>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Select
            value={tempToMonth}
            onChange={setTempToMonth}
            options={months}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
              }),
            }}
          />
          <Select
            value={tempToDateYear}
            onChange={setTempToDateYear}
            options={years}
            styles={{
              control: (styles) => ({
                ...styles,
                color: "#012169",
                cursor: "pointer",
                width: "140px",
              }),
            }}
          />
        </div>
        <DoneButton type="submit" onClick={submitHandler}>
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"DOWNLOAD"}
            />
        </DoneButton>
      </DownloadInvoiceSideBarDiv>
    );
  }
};

export default AdminDashboardDownloadInvoiceDrawer;
