import React from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ConsumerLogoutSideBarDiv,
  LogoutText,
  ButtonBar,
  CancelButton,
  LogoutButton,
  ConsumerLogoutModalBarDiv,
} from "../../styles/ConsumerLogoutStyles";
import CONSUMER_LOGOUT from "../../images/consumer_logout.png";
import LanguageSelector from "../../language/LanguageSelector";

const AdminLogout = ({
  openSideMenu,
  logOutHandler,
  toggleLogoutMenu,
  width,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  if (width < 1100) {
    return (
      <ConsumerLogoutModalBarDiv openSideMenu={openSideMenu}>
        <img src={CONSUMER_LOGOUT} alt="Logout" />
        <LogoutText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASK_LOGOUT"}
            /></LogoutText>
        <ButtonBar>
          <CancelButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CANCEL_LOGOUT"}
            /></CancelButton>
          <LogoutButton onClick={logOutHandler}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"YES_LOGOUT"}
            />
          </LogoutButton>
        </ButtonBar>
      </ConsumerLogoutModalBarDiv>
    );
  } else {
    return (
      <ConsumerLogoutSideBarDiv openSideMenu={openSideMenu}>
        <img src={CONSUMER_LOGOUT} alt="Logout" />
        <LogoutText><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASK_LOGOUT"}
            /></LogoutText>
        <ButtonBar>
          <CancelButton onClick={toggleLogoutMenu}><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"CANCEL_LOGOUT"}
            /></CancelButton>
          <LogoutButton onClick={logOutHandler}>
          <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"YES_LOGOUT"}
            />
          </LogoutButton>
        </ButtonBar>
      </ConsumerLogoutSideBarDiv>
    );
  }
};

export default AdminLogout;
