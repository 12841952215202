import React from "react";
import {
  ConsumerLogoutSideBarDiv,
  LogoutText,
  ButtonBar,
  CancelButton,
  LogoutButton,
  ConsumerLogoutModalBarDiv,
} from "../../styles/ConsumerLogoutStyles";
import CONSUMER_LOGOUT from "../../images/consumer_logout.png";
import LanguageSelector from "../../language/LanguageSelector";

const ConsumerLogoutDrawer = ({
  openSideMenu,
  logOutHandler,
  toggleLogoutMenu,
  width,
  selectedCountry,
}) => {
  if (width < 1100) {
    return (
      <ConsumerLogoutModalBarDiv openSideMenu={openSideMenu}>
        <img src={CONSUMER_LOGOUT} alt="Logout" />
        <LogoutText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"ASK_LOGOUT"}
          />
        </LogoutText>
        <ButtonBar>
          <CancelButton onClick={toggleLogoutMenu}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"CANCEL_LOGOUT"}
            />
          </CancelButton>
          <LogoutButton onClick={logOutHandler}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"YES_LOGOUT"}
            />
          </LogoutButton>
        </ButtonBar>
      </ConsumerLogoutModalBarDiv>
    );
  } else {
    return (
      <ConsumerLogoutSideBarDiv openSideMenu={openSideMenu}>
        <img src={CONSUMER_LOGOUT} alt="Logout" />
        <LogoutText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"ASK_LOGOUT"}
          />
        </LogoutText>
        <ButtonBar>
          <CancelButton onClick={toggleLogoutMenu}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"CANCEL_LOGOUT"}
            />
          </CancelButton>
          <LogoutButton onClick={logOutHandler}>
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"YES_LOGOUT"}
            />
          </LogoutButton>
        </ButtonBar>
      </ConsumerLogoutSideBarDiv>
    );
  }
};

export default ConsumerLogoutDrawer;
