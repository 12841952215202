/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  AdminTableHeaderData,
  AdminUserTable,
  AdminTableBody,
  AdminTableRow,
  AdminTableBodyData,
  ManageuserIcon,
} from "../../styles/AdminUserStyles";
import GREEN_ARROW_IMAGE from "../../images/green_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const AdminUsersList = ({
  users,
  toggleManageUser,
  textFilterName,
  selectedFilterOption,
  allUsers,
  setPaginationList,
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      setRequestList(
        users?.filter(
          (value) => value.name != null && value.name.includes(textFilterName)
        )
      );
    } else {
      if (selectedFilterOption === "Latest First") {
        setRequestList(users.reverse());
      } else {
        setRequestList(users);
      }
    }
  }, [textFilterName, users, selectedFilterOption]);

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      const filteredUsers = users?.filter(
        (value) => value.name != null && value.name.includes(textFilterName)
      );
      updatePagination(filteredUsers);
    } else {
      updatePagination(allUsers);
    }
  }, [textFilterName]);

  return (
    <AdminUserTable>
      <AdminTableBody>
        <AdminTableRow>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"COUNTRY"}
        /></AdminTableHeaderData>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"NAME_DETAILS"}
        /></AdminTableHeaderData>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"POSITION"}
        /></AdminTableHeaderData>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"OPEN_REQUESTS"}
        /></AdminTableHeaderData>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"TOTAL_REQUESTS"}
        /></AdminTableHeaderData>
          <AdminTableHeaderData><LanguageSelector
          selectedCountry={
            decodedToken
              ? {
                  value: decodedToken?.country,
                  label: decodedToken?.country,
                }
              : countryValue
          }
          text={"ACTIONS"}
        /></AdminTableHeaderData>
        </AdminTableRow>
      </AdminTableBody>
      {requestList &&
        requestList?.length > 0 &&
        requestList?.map((item, index) => {
          return (
            <AdminTableBody key={`${index}`}>
              <AdminTableRow isEven={index % 2 === 0 ? true : false}>
                <AdminTableBodyData>{item.country}</AdminTableBodyData>
                <AdminTableBodyData>{item.name}</AdminTableBodyData>
                <AdminTableBodyData>{item.user_type === "Preview Staff"? <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PREVIEW_STAFF"}
            /> : 
               <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"PREVIEW_SUPERVISOR"}
              /> }</AdminTableBodyData>
                <AdminTableBodyData>{item?.pending_requests}</AdminTableBodyData>
                <AdminTableBodyData>{item?.total_requests}</AdminTableBodyData>
                <AdminTableBodyData>
                  <ManageuserIcon
                    src={GREEN_ARROW_IMAGE}
                    alt="Manage User"
                    onClick={() => {
                      toggleManageUser(item);
                    }}
                  />
                </AdminTableBodyData>
              </AdminTableRow>
            </AdminTableBody>
          );
        })}
    </AdminUserTable>
  );
};

export default AdminUsersList;
