import React, { useReducer } from "react";
import { CHANGE_COLOURIZATION_DETAILS } from "../utils/Constants";

const initialState = {
  colourizationDetails: {},
};

const ColourizationContext = React.createContext({
  colourizationDetails: {},
  setColourizationDetails: (colourizationDetail) => {},
});

function colourizationReducer(state, action) {
  switch (action.type) {
    case CHANGE_COLOURIZATION_DETAILS:
      return {
        ...state,
        colourizationDetails: action.payload,
      };
    default:
      return state;
  }
}

function ColourizationProvider(props) {
  const [state, dispatch] = useReducer(colourizationReducer, initialState);

  function setColourizationDetails(colourizationDetails) {
    dispatch({
      type: CHANGE_COLOURIZATION_DETAILS,
      payload: colourizationDetails,
    });
  }

  return (
    <ColourizationContext.Provider
      value={{
        colourizationDetails: state.colourizationDetails,
        setColourizationDetails,
      }}
      {...props}
    />
  );
}

export { ColourizationContext, ColourizationProvider };
