import React, { useReducer } from "react";
import { CHANGE_ADMIN_DETAILS } from "../utils/Constants";

const initialState = {
  adminDetails: {},
};

const AdminDetailsContext = React.createContext({
  adminDetails: {},
  setAdminDetails: (adminDetails) => {},
});

function adminDetailReducer(state, action) {
  switch (action.type) {
    case CHANGE_ADMIN_DETAILS:
      return {
        ...state,
        adminDetails: action.payload,
      };
    default:
      return state;
  }
}

function AdminDetailsProvider(props) {
  const [state, dispatch] = useReducer(adminDetailReducer, initialState);

  function setAdminDetails(adminDetails) {
    dispatch({
      type: CHANGE_ADMIN_DETAILS,
      payload: adminDetails,
    });
  }

  return (
    <AdminDetailsContext.Provider
      value={{
        adminDetails: state.adminDetails,
        setAdminDetails,
      }}
      {...props}
    />
  );
}

export { AdminDetailsContext, AdminDetailsProvider };
