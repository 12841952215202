import React, { useEffect, useState } from "react";
import {
  HomeSociaMediaContainer,
  AddSomeColorToFeedText,
  FacebookLogo,
  YoutubeLogo,
} from "../../styles/HomeStyles";
import FACEBOOK_IMAGE from "../../images/facebook-hover.svg";
import YOUTUBE_IMAGE from "../../images/youtube-hover.svg";
import INSTAGRAM_IMAGE from "../../images/instagram-hover.svg";
import LINE_IMAGE from "../../images/line_green.svg";

import {
  ID_FACEBOOK_URL,
  ID_YOUTUBE_URL,
  ID_INSTAGRAM_URL,
  ML_FACEBOOK_URL,
  ML_YOUTUBE_URL,
  ML_INSTAGRAM_URL,
  SG_FACEBOOK_URL,
  SG_YOUTUBE_URL,
  SG_INSTAGRAM_URL,
  VIETNAM_FACEBOOK_URL,
  VIETNAM_YOUTUBE_URL,
  INDIA_FACEBOOK_URL,
  INDIA_YOUTUBE_URL,
  INDIA_INSTAGRAM_URL,
  PAKISTAN_FACEBOOK_URL,
  PAKISTAN_YOUTUBE_URL,
  PAKISTAN_INSTAGRAM_URL,
  SRI_LANKA_FACEBOOK_URL,
  SRI_LANKA_YOUTUBE_URL,
  THAILAND_FACEBOOK_URL,
  THAILAND_YOUTUBE_URL,
  THAILAND_INSTAGRAM_URL,
  THAILAND_LINE_URL,
  
} from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector"

const HomeSocialMedia = ({ selectedCountry }) => {

  const [facebookUrl, setFacebookUrl] = useState(SG_FACEBOOK_URL);
  const [youtubeUrl, setYoutubeUrl] = useState(SG_YOUTUBE_URL);
  const [instagramUrl, setInstagramUrl] = useState(SG_INSTAGRAM_URL);
  const [lineUrl, setLineUrl] = useState(null);

  useEffect(() => {
    if (selectedCountry.value === "Singapore") {
      setFacebookUrl(SG_FACEBOOK_URL);
      setYoutubeUrl(SG_YOUTUBE_URL);
      setInstagramUrl(SG_INSTAGRAM_URL);
      setLineUrl(null);
    } else if (selectedCountry.value === "Malaysia") {
      setFacebookUrl(ML_FACEBOOK_URL);
      setYoutubeUrl(ML_YOUTUBE_URL);
      setInstagramUrl(ML_INSTAGRAM_URL);
      setLineUrl(null);
    } else if (selectedCountry.value === "Indonesia") {
      setFacebookUrl(ID_FACEBOOK_URL);
      setYoutubeUrl(ID_YOUTUBE_URL);
      setInstagramUrl(ID_INSTAGRAM_URL);
      setLineUrl(null);
    } else if (selectedCountry.value === "Pakistan") {
      setFacebookUrl(PAKISTAN_FACEBOOK_URL);
      setYoutubeUrl(PAKISTAN_YOUTUBE_URL);
      setInstagramUrl(PAKISTAN_INSTAGRAM_URL);
      setLineUrl(null);
    } else if (selectedCountry.value === "SriLanka") {
      setFacebookUrl(SRI_LANKA_FACEBOOK_URL);
      setYoutubeUrl(SRI_LANKA_YOUTUBE_URL);
      setInstagramUrl(null);
      setLineUrl(null);
    } else if (selectedCountry.value === "Thailand") {
      setFacebookUrl(THAILAND_FACEBOOK_URL);
      setYoutubeUrl(THAILAND_YOUTUBE_URL);
      setInstagramUrl(THAILAND_INSTAGRAM_URL);
      setLineUrl(THAILAND_LINE_URL);
    } else if (selectedCountry.value === "Vietnam") {
      setFacebookUrl(VIETNAM_FACEBOOK_URL);
      setYoutubeUrl(VIETNAM_YOUTUBE_URL);
      setInstagramUrl(null);
      setLineUrl(null);
    } else if (selectedCountry.value === "India") {
      setFacebookUrl(INDIA_FACEBOOK_URL);
      setYoutubeUrl(INDIA_YOUTUBE_URL);
      setInstagramUrl(INDIA_INSTAGRAM_URL);
      setLineUrl(null);
    }
  }, [selectedCountry]);

  return (
    <HomeSociaMediaContainer>
      <AddSomeColorToFeedText>
        <LanguageSelector selectedCountry={selectedCountry} text={"ADD_SOME_COLOUR_TO_YOUR_FEED"}/>
      </AddSomeColorToFeedText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
          <FacebookLogo
            src={FACEBOOK_IMAGE}
            alt="Facebook"
          />
        </a>
        {instagramUrl !== null &&
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
          <FacebookLogo
            src={INSTAGRAM_IMAGE}
            alt="Youtube"
          />
        </a>}
        {lineUrl !== null &&
        <a href={lineUrl} target="_blank" rel="noopener noreferrer">
          <FacebookLogo
            src={LINE_IMAGE}
            alt="Youtube"
          />
        </a>}
        <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
          <YoutubeLogo
            src={YOUTUBE_IMAGE}
            alt="Youtube"
          />
        </a>
      </div>
    </HomeSociaMediaContainer>
  );
};

export default HomeSocialMedia;
