import React from "react";
import {
  ComingSoonDiv,
  ComingSoonText,
  SituationImage,
  SituationText,
} from "../../styles/ConsumerImageOptionStyles";
import { BrowseSituationContainer } from "../../styles/ConsumerBrowseColourStyles";

const ConsumerSituationContainer = ({
  image,
  text,
  isComingSoon,
  clickHandler,
  isImageTypeOption
}) => {
  return (
    <BrowseSituationContainer
      isComingSoon={isComingSoon}
      isImageTypeOption={isImageTypeOption}
      onClick={clickHandler}
    >
      <SituationImage src={image} alt="Situation" />
      <SituationText>{text}</SituationText>
      {isComingSoon && (
        <ComingSoonDiv>
          <ComingSoonText>Coming Soon</ComingSoonText>
        </ComingSoonDiv>
      )}
    </BrowseSituationContainer>
  );
};

export default ConsumerSituationContainer;
