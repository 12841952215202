/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import {
  ResolvedRequestTable,
  ResolvedRequestRow,
  ResolvedRequestTableHeader,
  ResolvedRequestTableData,
  ArrowImage,
} from "../../styles/PreviewStaffDashboardStyles";
import BLACK_ARROW_IMAGE from "../../images/black_arrow.svg";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewStaffDashboardResolvedRequests = ({
  list,
  previewSupervisorResultHandler,
  textFilterName,
  setPaginationList,
  allRequests,
  selectedFilterOption,
  selectedPageForResolved,
}) => {
  const [requestList, setRequestList] = useState([]);
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      setRequestList(
        list
          ?.filter(
            (value) =>
              value.assigned.includes(textFilterName) ||
              value.email.includes(textFilterName)
          )
          .slice(
            selectedPageForResolved === 1
              ? 0
              : 15 * (selectedPageForResolved - 1),
            15 * selectedPageForResolved
          )
      );
    } else {
      if (selectedFilterOption === "Latest First") {
        setRequestList(
          list
            .reverse()
            .slice(
              selectedPageForResolved === 1
                ? 0
                : 15 * (selectedPageForResolved - 1),
              15 * selectedPageForResolved
            )
        );
      } else {
        setRequestList(
          list.slice(
            selectedPageForResolved === 1
              ? 0
              : 15 * (selectedPageForResolved - 1),
            15 * selectedPageForResolved
          )
        );
      }
    }
  }, [textFilterName, list, selectedFilterOption]);

  const updatePagination = (tempList) => {
    setPaginationList([]);
    const division = Math.ceil(tempList.length / 15);
    const upTo = division === 0 ? 1 : division;
    for (let i = 0; i < upTo; i++) {
      setPaginationList((oldArray) => [...oldArray, i]);
    }
  };

  useEffect(() => {
    if (
      textFilterName !== "" &&
      textFilterName !== null &&
      textFilterName !== undefined
    ) {
      const filteredList = list?.filter(
        (value) =>
          value.assigned.includes(textFilterName) ||
          value.email.includes(textFilterName)
      );
      updatePagination(filteredList);
    } else {
      updatePagination(allRequests);
    }
  }, [textFilterName]);

  return (
    <ResolvedRequestTable>
      <tbody>
        <ResolvedRequestRow>
          <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUEST_ID"}
            /></ResolvedRequestTableHeader>
          {/* <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ASSIGNED_TO"}
            /></ResolvedRequestTableHeader> */}
          <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTER_EMAIL"}
            /></ResolvedRequestTableHeader>
          <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"REQUESTER_TYPE"}
            /></ResolvedRequestTableHeader>
          <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RECOLOUR_TYPE"}
            /></ResolvedRequestTableHeader>
          <ResolvedRequestTableHeader><LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"VIEW_RESULT"}
            /></ResolvedRequestTableHeader>
        </ResolvedRequestRow>
        {requestList.map((item, index) => {
          return (
            <ResolvedRequestRow
              key={`${index}`}
              isEven={index % 2 === 0 ? true : false}
            >
              <ResolvedRequestTableData>
                {item.request_id}
              </ResolvedRequestTableData>
              {/* <ResolvedRequestTableData>
                {item.assigned}
              </ResolvedRequestTableData> */}
              <ResolvedRequestTableData>{item.email}</ResolvedRequestTableData>
              <ResolvedRequestTableData>
              {item.customer_type === null ? "None" : item.customer_type === "Wall Owner" ? <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"WALL_OWNER"}
              /> :item.customer_type}
              </ResolvedRequestTableData>
              <ResolvedRequestTableData>
              {item.colourization_type === "Lead Colour"? <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"LEAD_COLOUR"}
            /> : 
                item.colourization_type === "Reference"? <LanguageSelector
                selectedCountry={
                  decodedToken
                    ? {
                        value: decodedToken?.country,
                        label: decodedToken?.country,
                      }
                    : countryValue
                }
                text={"REFERENCE"}
              /> : <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"THEME"}
            />}
              </ResolvedRequestTableData>
              <ResolvedRequestTableData>
                <ArrowImage
                  src={BLACK_ARROW_IMAGE}
                  alt="View Result"
                  onClick={() => previewSupervisorResultHandler(item)}
                />
              </ResolvedRequestTableData>
            </ResolvedRequestRow>
          );
        })}
      </tbody>
    </ResolvedRequestTable>
  );
};

export default PreviewStaffDashboardResolvedRequests;
