import createDataContext from "./createDataContext";
import amarisServer from "../api/amarisServer";
import {
  COLOURIZATION_REQUEST_API,
  CONSUMER_REQUEST_HISTORY_API,
  CONSUMER_REQUEST_REPORT_API,
  IMAGE_GALLERY_API,
  SOMETHING_WENT_WRONG,
  USER_SURVEY_API,
  CONSUMER_USER_DETAIL_API,
  COLOUR_THEME_PALETTE_API,
  TOKEN_NAME,
  CHECK_SURVEY_API,
  GEOCODING_API,
  GEOCODING_API_KEY,
  FAMILY_COLOUR_API,
  FAMILY_COLOUR_SUGGESTION_API,
  SEARCH_COLOUR_API,
  CONSUMER_REFRESH_TOKEN_API,
  COLOURIZATION_REQUEST_REPORT_DOWNLOAD_API,
  CHECK_REQUEST_LIMIT_API,
} from "../utils/Constants";
import Cookies from "js-cookie";
// import { CONSUMER_RESULT_ROUTE, TOKEN_NAME } from "../../utils/Constants";

const consumerReducer = (state, action) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        ...{ loading: false },
        ...{ errorMessage: action.payload },
      };
    case "imageGallery":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "colourizationRequest":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "requestHistory":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "requestReport":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "submitSurvey":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "familyColour":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "familyColourSuggestion":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "searchColour":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "themePalette":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "checkRequestLimit":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "requestAdditionalDetails":
      return {
        ...state,
        ...{ data: action.payload },
        ...{ loading: false },
        ...{ errorMessage: "" },
      };
    case "inProgress":
      return { ...state, ...{ loading: true }, ...{ errorMessage: "" } };
    default:
      return state;
  }
};

const submitSurvey = (dispatch) => {
  return async ({
    request_id,
    email,
    recommend,
    // ease_of_access,
    ease_of_use,
    detailing,
    // fulfill_requirement,
    time_to_deliver,
    submitSuverySuccessCallback,
    submitSurveyFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        USER_SURVEY_API,
        {
          request_id,
          email,
          recommend,
          // ease_of_access,
          ease_of_use,
          detailing,
          // fulfill_requirement,
          time_to_deliver,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "submitSurvey",
            payload: response.data,
          });
          submitSuverySuccessCallback();
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          submitSurveyFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        submitSurveyFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const requestReport = (dispatch) => {
  return async ({
    request_id,
    requestReportSuccessCallback,
    requestReportFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        CONSUMER_REQUEST_REPORT_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          if (
            response.data.request_details?.colourization_type.toLowerCase() ===
            "theme"
          ) {
            amarisServer
              .post(
                COLOUR_THEME_PALETTE_API,
                {
                  theme_name: response.data.request_details.theme_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((colourPaletteResponse) => {
                if (colourPaletteResponse.status === 200) {
                  dispatch({
                    type: "requestReport",
                    payload: {
                      result: response.data,
                      palette: colourPaletteResponse.data,
                    },
                  });
                  requestReportSuccessCallback();
                } else {
                  dispatch({
                    type: "error",
                    payload: SOMETHING_WENT_WRONG,
                  });
                  requestReportFailureCallback(
                    colourPaletteResponse?.response?.data?.detail?.message ||
                      SOMETHING_WENT_WRONG
                  );
                }
              })
              .catch((colourPaletteError) => {
                dispatch({
                  type: "error",
                  payload: SOMETHING_WENT_WRONG,
                });
                requestReportFailureCallback(
                  colourPaletteError?.response?.data?.detail?.message ||
                    SOMETHING_WENT_WRONG
                );
              });
          } else {
            dispatch({
              type: "requestReport",
              payload: { result: response.data },
            });
            requestReportSuccessCallback();
          }
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          requestReportFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        requestReportFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const requestHistory = (dispatch) => {
  return async ({
    email,
    requestHistorySuccessCallback,
    requestHistoryFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        CONSUMER_REQUEST_HISTORY_API,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "requestHistory",
            payload: response.data,
          });
          requestHistorySuccessCallback();
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          requestHistoryFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        requestHistoryFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const colourizationRequest = (dispatch) => {
  return async ({
    history,
    raw_image,
    colourization_type,
    reference,
    theme,
    lead_colour,
    email,
    name,
    city,
    phone,
    address,
    country,
    remarks,
    floors_number,
    floor_area,
    rooms_number,
    customer_code,
    customer_type,
    wall_owner_name,
    user_email,
    wall_owner_address,
    mobile,
    colourizationRequestSuccessCallback,
    colourizationRequestFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    await amarisServer
      .post(
        CONSUMER_USER_DETAIL_API,
        {
          email,
          user_type: "Consumer",
          name,
          city,
          phone,
          address,
          country,
          customer_code,
          customer_type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((userDetailResponse) => {
        if (
          userDetailResponse.status === 200 &&
          userDetailResponse.data.status_code === 1100
        ) {
          console.log("!!!!!",colourization_type)
            colourizationRequestSuccessCallback(colourization_type);

          amarisServer
            .post(
              COLOURIZATION_REQUEST_API,
              {
                raw_image,
                colourization_type,
                reference_image: reference,
                theme,
                lead_colour,
                email,
                name,
                city,
                country,
                remarks,
                floors_number,
                floor_area,
                rooms_number,
                wall_owner_name,
                user_email,
                address: wall_owner_address,
                mobile,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )


      // const refreshTokenResponse = await amarisServer.post(
      //   CONSUMER_REFRESH_TOKEN_API,
      //   {
      //     email,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // Cookies.set(TOKEN_NAME, refreshTokenResponse.data.token, {
      //   expires: 30,
      // });

    } 
    }) 
    
        
};
}

const imageGallery = (dispatch) => {
  return async ({
    category,
    filter,
    imageGallerySuccessCallback,
    imageGalleryFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        IMAGE_GALLERY_API,
        {
          category,
          filter,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "imageGallery",
            payload: response.data,
          });
          imageGallerySuccessCallback(response.data);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          imageGalleryFailureCallback();
          alert(response?.data?.detail?.status_message || SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        imageGalleryFailureCallback();
        alert(error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG);
      });
  };
};

const checkSurvey = (dispatch) => {
  return async ({
    request_id,
    checkSurveySuccessCallback,
    checkSurveyFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        CHECK_SURVEY_API,
        {
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        if (response.status === 200) {
          checkSurveySuccessCallback(response.data);
        } else {
          checkSurveyFailureCallback();
        }
      })
      .catch((error) => {
        checkSurveyFailureCallback();
      });
  };
};

const getGeocodingDetails = (dispatch) => {
  return async ({
    lat,
    lng,
    getGeocodingSuccessCallback,
    getGeocodingFailureCallback,
  }) => {
    amarisServer
      .get(`${GEOCODING_API}${lat},${lng}&key=${GEOCODING_API_KEY}`)
      .then((response) => {
        getGeocodingSuccessCallback(response.data);
      })
      .catch((error) => {
        getGeocodingFailureCallback();
      });
  };
};

const familyColour = (dispatch) => {
  return async ({
    colour_family,
    familyColourSuccessCallback,
    familyColorFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        FAMILY_COLOUR_API,
        { colour_family },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "familyColour",
            payload: response.data,
          });
          familyColourSuccessCallback(response.data.colours);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          familyColorFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        familyColorFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const familyColourSuggestion = (dispatch) => {
  return async ({
    colour_name,
    familyColourSuggestionSuccessCallback,
    familyColourSuggestionFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        FAMILY_COLOUR_SUGGESTION_API,
        { colour_name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "familyColourSuggestion",
            payload: response.data,
          });
          familyColourSuggestionSuccessCallback(
            response.data.colour_suggestion_details
          );
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          familyColourSuggestionFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        familyColourSuggestionFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const searchColour = (dispatch) => {
  return async ({
    colour_name,
    searchColourSuccessCallback,
    searchColourFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(
        SEARCH_COLOUR_API,
        { colour_name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status_code === 1100) {
          dispatch({
            type: "searchColour",
            payload: response.data,
          });
          searchColourSuccessCallback(response.data.colour);
        } else {
          dispatch({
            type: "error",
            payload: SOMETHING_WENT_WRONG,
          });
          searchColourFailureCallback(
            response?.data?.detail?.status_message || SOMETHING_WENT_WRONG
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        searchColourFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const themePalette = (dispatch) => {
  return async ({
    themeName,
    themePaletteSuccessCallback,
    themePaletteFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    console.log("123");
    amarisServer
      .post(COLOUR_THEME_PALETTE_API, {
        theme_name: themeName,
      })
      .then((response) => {
        console.log("456");
        themePaletteSuccessCallback(response.data);
        dispatch({
          type: "themePalette",
        });
      })
      .catch((error) => {
        console.log("789");
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        themePaletteFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

const checkRequestLimit = (dispatch) => {
  return async ({
    email,
    checkRequestLimitSuccessCallback,
    checkRequestLimitFailureCallback,
  }) => {
    dispatch({ type: "inProgress" });
    amarisServer
      .post(CHECK_REQUEST_LIMIT_API, {
        email: email,
      })
      .then((response) => {
        checkRequestLimitSuccessCallback(response.data);
        dispatch({
          type: "checkRequestLimit",
        });
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: SOMETHING_WENT_WRONG,
        });
        checkRequestLimitFailureCallback(
          error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
        );
      });
  };
};

// const requestAdditionalDetails = (dispatch) => {
//   return async ({
//     request_id,
//     floors_number,
//     floor_area,
//     rooms_number,
//     requestAdditionalDetailsSuccessCallback,
//     requestAdditionalDetailsFailureCallback,
//   }) => {
//     const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
//     dispatch({ type: "inProgress" });
//     amarisServer
//       .post(CONSUMER_REQUEST_ADDITIONAL, 
//         {
//           request_id: request_id,
//           floors_number: floors_number,
//           floor_area: floor_area,
//           rooms_number: rooms_number
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//       .then((response) => {
//         requestAdditionalDetailsSuccessCallback(response.data);
//         dispatch({
//           type: "requestAdditionalDetails",
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: "error",
//           payload: SOMETHING_WENT_WRONG,
//         });
//         requestAdditionalDetailsFailureCallback(
//           error?.response?.data?.detail?.message || SOMETHING_WENT_WRONG
//         );
//       });
//   };
// };


const reportDownload = (dispatch) => {
  return async ({
    request_id,
    reportDownloadSuccessCallback,
    reportDownloadFailureCallback,
  }) => {
    const token = Cookies.get(TOKEN_NAME) ? Cookies.get(TOKEN_NAME) : null;
    amarisServer
      .post(COLOURIZATION_REQUEST_REPORT_DOWNLOAD_API,
        {
          request_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        })
      .then((response) => {
        reportDownloadSuccessCallback(response.data);
      })
      .catch((error) => {
        reportDownloadFailureCallback(JSON.stringify(error));
      });
  };
};
export const { Provider, Context } = createDataContext(
  consumerReducer,
  {
    imageGallery,
    submitSurvey,
    colourizationRequest,
    requestHistory,
    requestReport,
    checkSurvey,
    getGeocodingDetails,
    familyColour,
    familyColourSuggestion,
    searchColour,
    themePalette,
    reportDownload,
    checkRequestLimit,
  },
  []
);
