import React from "react";
import {
  PendingButton,
  PendingResolvedContainerDiv,
  ResolvedButton,
  ArchivedButton
} from "../../styles/PreviewSupervisorDashboardStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  TOKEN_NAME,
} from "../../utils/Constants";
import LanguageSelector from "../../language/LanguageSelector";

const PreviewSupervisorDashboardPendingResolvedContainer = ({
  tabSelected,
  setTabSelected,
  pendingListLength,
  resolvedListLength,
  archivedListLength,
  togglePending,
  toggleResolved,
  toggleArchived
}) => {
  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));

  return (
    <PendingResolvedContainerDiv>
      <PendingButton
        tabSelected={tabSelected}
        onClick={togglePending}
      >
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"PENDING"}
            />{` (${pendingListLength})`}
      </PendingButton>
      <ResolvedButton
        tabSelected={tabSelected}
        onClick={toggleResolved}
      >
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"RESOLVED"}
            />{` (${resolvedListLength})`}
      </ResolvedButton>
      <ArchivedButton
        tabSelected={tabSelected}
        onClick={toggleArchived}
      >
        <LanguageSelector
              selectedCountry={
                decodedToken
                  ? {
                      value: decodedToken?.country,
                      label: decodedToken?.country,
                    }
                  : countryValue
              }
              text={"ARCHIVED"}
            />{` (${archivedListLength})`}
      </ArchivedButton>
    </PendingResolvedContainerDiv>
  );
};

export default PreviewSupervisorDashboardPendingResolvedContainer;
