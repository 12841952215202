import React, { useContext, useRef, useState } from "react";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import ConsumerImageOptionHeader from "../../components/consumer-image-options/ConsumerImageOptionHeader";
import ConsumerReferenceImageDragAndDrop from "../../components/consumer-reference-image/ConsumerReferenceImageDragAndDrop";
import ConsumerReferenceImageSelectedImage from "../../components/consumer-reference-image/ConsumerReferenceImageSelectedImage";
import ConsumerReferenceImageSideBarDiv from "../../components/consumer-reference-image/ConsumerReferenceImageSideBarDiv";
import {
  ConsumerReferenceImageContainer,
  ConsumerReferenceImageFooter,
  UploadImageButton,
  ChooseFromDuluxGallery,
  NextButton,
  ReferenceImageText,
} from "../../styles/ConsumerReferenceImageStyles";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  CONSUMER_DULUX_PREVIEW_ROUTE,
  HOME_PAGE_ROUTE,
  TOKEN_NAME,
} from "../../utils/Constants";
import { ColourizationContext } from "../../context/ColourizationContext";
import BackButton from "../../components/BackButton";
import ConsumerReferenceUploadInstructions from "../../components/consumer-reference-image/ConsumerReferenceUploadInstructions";
import ConsumerLogoutDrawer from "../../components/consumer/ConsumerLogoutDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LanguageSelector from "../../language/LanguageSelector";
import { language } from "../../language/language";

const ConsumerReferenceImage = ({ history }) => {
  const imageRef = useRef(null);

  const { width } = useWindowDimensions();

  const token = Cookies.get(TOKEN_NAME);
  const decodedToken = token !== undefined ? jwt_decode(token) : false;
  const countryValue = JSON.parse(localStorage.getItem("country"));
  const selectedCountry = decodedToken
      ? {
          value: decodedToken?.country,
          label: decodedToken?.country,
        }
      : countryValue
  
  let lowercase_country = countryValue.value.charAt(0).toLowerCase() + countryValue.value.slice(1);

  const { state, imageGallery } = useContext(ConsumerContext);
  const colourizationContext = useContext(ColourizationContext);

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [image, setImage] = useState([]);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("Interior");
  const [filterApplied, setFilterApplied] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    theme: "",
    filterName: "",
  });
  const [selectedImage, setSelectedImage] = useState({
    theme: "",
    filter: "",
    imageName: "",
    image_id: ""
  });
  const [galleryImages, setGalleryImages] = useState([]);

  const toggleLogoutMenu = () =>
    setOpenLogoutMenu((previousState) => !previousState);

  const exteriorFilterList = [language["RESTFUL"][lowercase_country], language["PLAYFUL"][lowercase_country], language["CALM"][lowercase_country], language["BOLD"][lowercase_country], language["RESET"][lowercase_country]];
  const interiorFilterList = [language["RESTFUL"][lowercase_country], language["PLAYFUL"][lowercase_country], language["CALM"][lowercase_country], language["BOLD"][lowercase_country], language["RESET"][lowercase_country]];

  let reader;

  const uploadImageClickHandler = () => {
    imageRef.current.click();
  };

  const cancelUploadedImageHandler = () => {
    setImage([]);
  };

  const createListItem = (evt, file) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 5) {
      alert("Uploaded image should be less than 5 mb");
    } else {
      setImage([
        {
          imageUrl: evt.target.result,
          imageName: file.name,
          imageSize: fileSize.toFixed(2) + " mb",
        },
      ]);

      const value = colourizationContext.colourizationDetails;
      colourizationContext.setColourizationDetails({
        raw_image: value.raw_image,
        colourization_type: value.colourization_type,
        reference: evt.target.result,
        theme: "",
        lead_colour: "",
        email: value?.email || decodedToken?.email,
        name: "",
        city: "",
        country: "",
      });
    }
  };

  const readFile = (input) => {
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        reader = new FileReader();
        reader.onloadend = (function (file) {
          return function (evt) {
            createListItem(evt, file);
          };
        })(input.files[i]);
        reader.readAsDataURL(input.files[i]);
      }
    }
  };

  const goToDuluxPreviewHandler = () => {
    history.push(CONSUMER_DULUX_PREVIEW_ROUTE);
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  const [data, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  const toggleSideMenu = () =>
    setOpenSideMenu((previousState) => !previousState);
  const interiorThemeHandler = () => setSelectedTheme("Interior");
  const exteriorThemeHandler = () => setSelectedTheme("Exterior");
  const toggleFilterApplied = () =>
    setFilterApplied((previousState) => !previousState);

  const logOutHandler = () => {
    Cookies.remove(TOKEN_NAME);
    colourizationContext.setColourizationDetails({ email: "" });
    history.replace(HOME_PAGE_ROUTE);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <ConsumerLogoutDrawer
        openSideMenu={openLogoutMenu}
        logOutHandler={logOutHandler}
        toggleLogoutMenu={toggleLogoutMenu}
        width={width}
        selectedCountry={selectedCountry}
      />
      <ConsumerReferenceImageSideBarDiv
        openSideMenu={openSideMenu}
        toggleSideMenu={toggleSideMenu}
        interiorThemeHandler={interiorThemeHandler}
        selectedTheme={selectedTheme}
        exteriorThemeHandler={exteriorThemeHandler}
        toggleFilterApplied={toggleFilterApplied}
        filterApplied={filterApplied}
        exteriorFilterList={exteriorFilterList}
        interiorFilterList={interiorFilterList}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        galleryImages={galleryImages}
        imageGallery={imageGallery}
        state={state}
        colourizationContext={colourizationContext}
        setImage={setImage}
        setGalleryImages={setGalleryImages}
        width={width}
        countryValue={countryValue}
        decodedToken={decodedToken}
        selectedCountry={selectedCountry}
      />
      <ConsumerReferenceImageContainer
        applyFilter={openSideMenu || openLogoutMenu}
      >
        <input
          type="file"
          id="file"
          accept="image/*"
          ref={imageRef}
          style={{ display: "none" }}
          onChange={(event) => {
            readFile(event.target);
          }}
          multiple={false}
        />
        <ConsumerImageOptionHeader
          decodedToken={decodedToken}
          logOutHandler={toggleLogoutMenu}
          width={width}
          selectedCountry={selectedCountry}
          goBackHandler={goBackHandler}
        />
        {width > 1100 && <BackButton goBackHandler={goBackHandler} />}
        <ReferenceImageText>
          <LanguageSelector
            selectedCountry={selectedCountry}
            text={"THE_REFERENCE_IMAGE_YOU_UPLOAD"}
          />
        </ReferenceImageText>
        {image.length === 0 ? (
          width > 1100 && (
            <ConsumerReferenceImageDragAndDrop
              data={data}
              dispatch={dispatch}
              reader={reader}
              createListItem={createListItem}
              selectedCountry={selectedCountry}
            />
          )
        ) : (
          <ConsumerReferenceImageSelectedImage
            selectedCountry={selectedCountry}
            image={image}
            uploadImageClickHandler={uploadImageClickHandler}
            cancelUploadedImageHandler={cancelUploadedImageHandler}
            width={width}
          />
        )}
        <ConsumerReferenceImageFooter>
          {image.length === 0 ? (
            <UploadImageButton
              onClick={uploadImageClickHandler}
              disabled={openSideMenu}
            >
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"UPLOAD_IMAGE"}
              />
            </UploadImageButton>
          ) : (
            <NextButton onClick={goToDuluxPreviewHandler}>
              <LanguageSelector
                selectedCountry={selectedCountry}
                text={"NEXT"}
              />
            </NextButton>
          )}
          <ChooseFromDuluxGallery
            onClick={toggleSideMenu}
            disabled={openSideMenu}
          >
            <LanguageSelector
              selectedCountry={selectedCountry}
              text={"CHOOSE_FROM_GALLERY"}
            />
          </ChooseFromDuluxGallery>
        </ConsumerReferenceImageFooter>
        <ConsumerReferenceUploadInstructions
          selectedCountry={selectedCountry}
        />
      </ConsumerReferenceImageContainer>
    </>
  );
};

export default ConsumerReferenceImage;
