import React from "react";
import { Link } from "react-router-dom";
import {
  PreviewSupervisorDashboardHeader,
  DeluxePreviewServiceImage,
  LogoutButton,
} from "../../styles/PreviewSupervisorTouchUpStyles";
import { HOME_PAGE_ROUTE } from "../../utils/Constants";
import DELUXE_PREVIEW_SERVICE_IMAGE from "../../images/deluxe_preview_service.svg";

const PreviewSupervisorTouchUpHeader = () => {
  return (
    <PreviewSupervisorDashboardHeader>
      <Link to={HOME_PAGE_ROUTE}>
        <DeluxePreviewServiceImage
          alt="Deluxe Preview"
          src={DELUXE_PREVIEW_SERVICE_IMAGE}
        />
      </Link>
    </PreviewSupervisorDashboardHeader>
  );
};

export default PreviewSupervisorTouchUpHeader;
