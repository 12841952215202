import styled from "styled-components";

export const PreviewSupervisorResultContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const PreviewSupervisorResultHeader = styled.div`
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  /* @media (max-width: 1500px) {
    padding: 0px; 
    margin: 0px;
  } */
`;

export const DeluxePreviewServiceImage = styled.img`
  padding: 0px 4vw 0px 6vw;
  align-self: center;
  @media (max-width: 1100px) {
    padding: 0px;
  }
`;

export const LogoutButton = styled.button`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0 4vw 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const PaletteDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin: 10px 4px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const ModalPaleeteDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin: 2px 30px;
  position: relative;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
  &:hover ${ToolTip} {
    visibility: visible;
  }
  @media (max-width: 1100px) {
    margin: 2 10px;
  }
`;

export const PaletteListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;

export const HeaderHorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px solid #d7d4cb;
`;

export const OriginalRefernceImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const OriginalBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 0;
`;

export const OriginalReferenceImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const OriginalReferenceImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  @media (max-width: 1100px) {
    width: 200px;
    height: 200px;
  }
`;

export const ReferenceBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalRuler = styled.hr`
  opacity: 0.5;
  border: 1px dashed #d7d4cb;
  transform: rotate(180deg);
  margin: 10px 60px;
`;

export const RecommendedProductsColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RecommendedProductsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RecommendedProductAnchor = styled.a`
  margin: 0 4px;
  :hover {
    cursor: pointer;
  }
`;

export const RecommendedProductsImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 4px 0;
`;

export const NearByStoresDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1100px) {
    margin: 10px 0;
  }
`;

export const BottomText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const RecommendedProductsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 0;
  max-width: 60px;
  word-wrap: break-word;
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 20px;
`;

export const DownloadButton = styled.button`
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  margin: 0 20px 0 0;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const BackButton = styled.button`
  background: #ffffff;
  border: 1px solid #545850;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #545850;
  outline: none;
  padding: 4px 20px;
  :hover {
    cursor: pointer;
  }
`;

export const PreviewSupervisorResultBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
`;

export const ResultsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const ResultImageDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 30px;
`;

export const ResultColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  height: 300px;
  width: 300px;
  object-fit: cover;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    height: 200px;
    width: 200px;
  }
`;

export const PaletteWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NearByStoreDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
`;

export const NearByImage = styled.img`
  border-radius: 4px;
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const NearByStoreText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const NearByStoreSubTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
  color: #000000;
`;

export const NearByPainters = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NearByPaintBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
`;

export const NearByPainterImage = styled.img`
  border-radius: 4px;
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const NearByPainterTitleText = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const NearByPainterSubTitleText = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const ResultModal = styled.div`
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.29);
  backdrop-filter: blur(24px);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

export const ResultBodyModal = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  position: fixed;
  background: #fff;
  border-radius: 20px;
  @media (max-width: 1100px) {
    flex-direction: row;
  }
`;

export const LeftArrowImage = styled.img`
  transform: rotate(180deg);
  width: 50px;  
  height: auto;
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    transform: rotate(180deg);
    width: 20px;  
    height: auto;
  }
`;

export const RightArrowImage = styled.img`
  width: 50px;  
  height: auto;
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 20px;  
    height: auto;
  }
`;

export const ModalImage = styled.img`
  width: 80%;
  height: 80%;
  border-radius: 10px;
  object-fit: contain;
  margin: 20px auto;
  @media (max-width: 1100px) {
    width: 60%;
    height: 80%;
  }
`;

export const ModalPalette = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const CloseModalImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 30px;
  :hover {
    cursor: pointer;
  }
`;

export const ModalImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

export const ModalImageDownloadButton = styled.button`
  text-decoration: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  width: 130px;
  outline: none;
  margin: 0 0 20px 0;
  :hover {
    cursor: pointer;
  }
`;

export const OptionTextStyle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  display: flex;
`;

export const ViewDetailsButton = styled.button`
  outline: none;
  background: #ffffff;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  margin: 10px 0;
  width: 140px;
  :hover {
    cursor: pointer;
  }
`;

export const PreviewSupervisorResultSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "38vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
`;

export const PreviewSupervisorResultModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 90%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
`;

export const CancelDrawerImage = styled.img`
  align-self: flex-end;
  margin: 20px 30px;
  :hover {
    cursor: pointer;
  }
`;

export const DrawerResultsText = styled.p`
  font-family: "Colour-Sans-New";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 0 30px;
`;

export const OptionDrawerText = styled.p`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 20px 30px;
`;

export const MainDrawerImage = styled.img`
  border: 2px solid rgba(82, 82, 82, 0.1);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  width: 84%;
  height: 300px;
  object-fit: fill;
  margin: 0 30px;
  @media (max-width: 1100px) {
    height: 200px;
    width: 80%;
  }
`;

export const DrawerPaleetteRow = styled.div`
  display: flex;
  margin: 20px 30px;
  @media (max-width: 1100px) {
    margin: 20px 0px;
  }
`;

export const DrawerPalette = styled.div`
  border-radius: 3px;
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  margin: 0 20px;
  @media (max-width: 1100px) {
    margin: 0px 8px;
  }
  :hover {
    cursor: pointer;
  }
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export const DrawerButtonRow = styled.div`
  display: flex;
  margin: 20px 30px;
  align-self: flex-end;
  @media (max-width: 1100px) {
    margin: 20px auto;
    align-self: center;
  }
`;

export const DrawerDownloadImage = styled.button`
  outline: none;
  background: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 30px;
  :hover {
    cursor: pointer;
  }
`;

export const DrawerColorText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 3px auto;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 8px;
  }
`;

export const ColorText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  margin: 3px auto;
  text-align: center;
`;

export const DrawerDoneButton = styled.button`
  outline: none;
  border: none;
  padding: 4px 20px;
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`;

export const PaginationDiv = styled.div`
  display: flex;
  align-self: center;
  margin-top: 30px;
  flex-direction: row;
`;

export const ErrorMessage = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: red;
  margin: 0 40px;
  text-align: center;
`;

export const ResultBottomModalDiv = styled.div`
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
