import React from "react";
import { Formik } from "formik";
import "../../styles/floatingLabelInput.css";
import { SendOTP } from "../../styles/InternalLoginStyles";
import { loginEmailValidationSchema } from "../../validations/Validations";
import LanguageSelector from "../../language/LanguageSelector";

const InternalLoginForm = ({
  setEmail,
  toogleOTPMenu,
  verifyEmail,
  verifyEmailSuccessCallback,
  verifyEmailFailureCallback,
  selectedCountry
}) => {
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values, actions) => {
        setEmail(values.email);
        toogleOTPMenu();
        verifyEmail({
          email: values.email,
          verifyEmailSuccessCallback,
          verifyEmailFailureCallback,
        });
      }}
      validationSchema={loginEmailValidationSchema}
    >
      {(formikProps) => {
        return (
          <>
            <div
              className="form-control"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <input
                required
                type="email"
                name="input"
                value={formikProps.values.email}
                onChange={formikProps.handleChange("email")}
                onBlur={formikProps.handleBlur("email")}
              />
              <label>
                <LanguageSelector selectedCountry={selectedCountry} text={"EMAIL"} />
              </label>
              <div className="border-around"></div>
            </div>
            <SendOTP
              type="submit"
              onClick={formikProps.handleSubmit}
              disabled={!(formikProps.isValid && formikProps.dirty)}
              isDisabled={!(formikProps.isValid && formikProps.dirty)}
            >
              <LanguageSelector selectedCountry={selectedCountry} text={"SEND_OTP"} />
            </SendOTP>
          </>
        );
      }}
    </Formik>
  );
};

export default InternalLoginForm;
