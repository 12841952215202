import styled from "styled-components";

export const ConsumerReferenceImageContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
  background-color: ${(props) =>
    props.applyFilter === true ? "#E5E5E5" : "#FFF"};
  -webkit-filter: ${(props) =>
    props.applyFilter === true ? "blur(8px)" : "blur(0px)"};
`;

export const DragDropImagesContainerDiv = styled.div`
  background: #ffffff;
  border: 1px dashed #2fc38c;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin: 2vh auto;
  padding: 3vh 0px;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 44vw;
  min-width: 44vw;
  max-width: 44vw;
  @media (max-width: 1200px) {
    margin: 5vh auto;
    min-width: 90vw;
    max-width: 90vw;
  }
  @media (max-width: 1000px) {
    margin: 5vh auto;
    min-width: 80vw;
    max-width: 80vw;
    padding: 3vh 2vw;
  }
`;

export const DragAndDropImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  margin-left: 2vw;
`;

export const ConsumerReferenceImageFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 25%;
  margin: auto;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const UploadImageButton = styled.button`
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  background: #2fc38c;
  margin: 40px auto;
  outline: none;
  padding: 4px 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 150px;
    margin: 20px 0;
  }
`;

export const CancelImage = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  @media (max-width: 1100px) {
    margin: 0px;
    /* width: 3vw;
    height: 3vw; */
  }
`;

export const NextButton = styled.button`
  background: #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 4px 40px;
  margin: 40px auto;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 150px;
    margin: 10px 0;
  }
`;

export const ChooseFromDuluxGallery = styled.button`
  background: #ffffff;
  border: 1px solid #2fc38c;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #2fc38c;
  outline: none;
  padding: 6px 8px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 180px;
    margin: 10px 0;
  }
`;

export const SelectedImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10vh 0;
  position: relative;
`;

export const SelectedImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.08));
  position: relative;
  @media (max-width: 1100px) {
    width: 100px;
    height: 100px;
  }
`;

export const SelectedImageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1vw;
  align-items: flex-start;
`;

export const SelectedImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  margin: 0;
`;

export const SelectedImageSizeText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  margin: 4px 0px;
`;

export const ChangeButton = styled.button`
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #2fc38c;
  background-color: #fff;
  display: flex;
  outline: none;
  padding: 4px 10px;
  margin: 4px 0px;
  :hover {
    cursor: pointer;
  }
`;

export const ConsumerSideBarModalBarDiv = styled.div`
  display: ${(props) => (props.openSideMenu === true ? "flex" : "none")};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 80%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
  transition: 0.2s;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  align-items: center;
  flex-direction: column;
`;

export const ConsumerSideBarDiv = styled.div`
  height: 100%;
  width: ${(props) => (props.openSideMenu === true ? "32vw" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
`;

export const SideMenuHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3vh 2vw 2vh 2vw;
  align-items: center;
  @media (max-width: 1100px) {
    padding: 20px;
  }
`;

export const SideMenuTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageGalleryText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin: 0;
`;

export const GalleryDiv = styled.div`
  display: flex;
  text-align: center;
`;

export const CorrectMarkIcon = styled.img`
  vertical-align: middle;
  font-size: 40px;
  width: 50px;
  height: 50px;
`;

export const TitleContainer = styled.div`
  border: 1px solid #666;
  border-radius: 6px;
  display: inline-block;
  margin: 40px;
  padding: 20px;
`;

export const DontGalleryColumn = styled.div`
  box-sizing: border-box;
  align-items: center;
  background: translucent;
  display: flex-top;
  width: 50%;
  padding: 10px;
  margin-top: 50px;
  margin-left: 50px;
  flex-wrap: wrap;
`;

export const DoGalleryColumn = styled.div`
  box-sizing: border-box;
  align-items: center;
  background: translucent;
  display: flex-top;
  width: 50%;
  padding: 10px;
  margin-top: 50px;
  margin-left: 50px;
  flex-wrap: wrap;
`;

export const GalleryRow = styled.div`
  :after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const GalleryGridItem = styled.div`
  min-width: 250px;
  max-width: 250px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 250px;
  flex: 1 1 250px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
`;

export const Gallery = styled.div`
  margin: 2px;
  border: 1px solid #ccc;
  float: left;
  width: 160px;
  }
`;

export const GridItemFigureImg = styled.img`
  width: 100%;
  height: 150px;
`;

export const GridItemFigCaption = styled.div`
  padding: 15px;
  text-align: center;
  font-size: 12px;
`;

export const CurratedInsprationsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545850;
  margin: 0;
`;

export const DoneButton = styled.button`
  border: 1px solid #2fc38c;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  margin: 0px 0px 0px 20px;
  outline: none;
  padding: 4px 30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #2fc38c;
  :hover {
    cursor: pointer;
  }
`;

export const ThemeDiv = styled.div`
  background: #f1f1f1;
  padding: 1vh 2vw;
  /* display: flex;
  flex-direction: column; */
  @media (max-width: 1100px) {
    width: 100%;
    padding: 10px 0px;
  }
`;

export const InteriorExteriorDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1100px) {
    margin-left: 30px;
  }
`;

export const InteriorExteriorTextDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InteriorButton = styled.button`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.selectedTheme === true ? "600" : "normal")};
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0px 10px 0px 0px;
  border-bottom: ${(props) =>
    props.selectedTheme === true ? "2px solid #2FC38C" : "none"};
  outline: none;
  :hover {
    cursor: pointer;
  }
  background: none;
`;

export const ExteriorButton = styled.button`
  background: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.selectedTheme === true ? "600" : "normal")};
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${(props) =>
    props.selectedTheme === true ? "2px solid #2FC38C" : "none"};
  :hover {
    cursor: pointer;
  }
`;

export const FilterImage = styled.img`
  height: 12px;
  width: 12px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    margin-right: 20px;
    height: 25px;
    width: 25px;
  }
`;

export const FilterDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  @media (max-width: 1100px) {
    margin-left: 20px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const FilterOptionDiv = styled.div`
  background: ${(props) =>
    props.selectedReset == true ? "#FF0000" : "#FFFFFF"};
  border: ${(props) =>
    props.filterSelected === true ? "1px solid #EFBF42" : "1px solid #d7d4cb"};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  margin: 2px 3px;
`;

export const FilterOptionText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${(props) =>
    props.selectedReset == true ? "#FFFFFF" : "#545850"};
  margin: 0;
`;

export const SelectedThemeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectedChildThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ThemeImage = styled.img`
  filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.11));
  border-radius: 5px;
  width: 180px;
  height: 180px;
  border: ${(props) =>
    props.selectedImage === true ? "2px solid #EFBF42" : "none"};
  object-fit: cover;
`;

export const ThemeText = styled.p`
  width: 180px;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

export const ReferenceImageText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #545850;
  text-align: center;
  margin: 4vh 0 0 0;
`;

export const ImageInstructionsFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-left: 50px;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageInstructionsFooter2 = styled.div`
 display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 50px;
  margin-left: 50px;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ImageInstructionsBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1100px) {
    margin: 10px 0;
    align-self: center;
  }
`;

export const ImageInstructionsBackgroundDiv = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border-radius: 50%;
  padding: 4px;
`;

export const ImageInstructionsBodyColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  margin: 0 4px;
  @media (max-width: 1100px) {
    margin: 0 10px;
  }
`;

export const ImageInstructionsText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
  margin: 0;
  width: 300px;
`;

export const DosAndDontsTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  line-height: 100%;
  color: #333333;
  display: inline;
`;

export const ReduceImageSize = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: teal;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;
