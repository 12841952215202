import React, { useState, useContext } from "react";
import {
  InternalLoginContainer,
  OnBoardingImage,
  WelcomeText,
  EnterEmailText,
  ErrorMessage,
} from "../../styles/InternalLoginStyles";
import OnBoarding from "../../images/onboarding.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Context as AuthContext } from "../../context/AuthContext";
import { ColourizationContext } from "../../context/ColourizationContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import InternalLoginForm from "../../components/internal-login/InternalLoginForm";
import InternalHomeHeader from "../../components/internal-login/InternalHomeHeader";
import InternalLoginOtpSideBar from "../../components/internal-login/InternalLoginOtpSideBar";

const InternalLogin = ({ history }) => {
  const [selectedCountry, setSelectedCountry] = useState({
    value: "Singapore",
    label: "Singapore",
  });
  const [email, setEmail] = useState("");
  const [showOTPMenu, setShowOTPMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { width } = useWindowDimensions();

  const { state, verifyEmail, verifyEmailOTP } = useContext(AuthContext);
  const colourizationContext = useContext(ColourizationContext);
  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );

  const verifyEmailSuccessCallback = () => {};

  const verifyEmailFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const toogleOTPMenu = () => setShowOTPMenu((previousState) => !previousState);

  return (
    <>
      <InternalLoginOtpSideBar
        toogleOTPMenu={toogleOTPMenu}
        state={state}
        verifyEmailOTP={verifyEmailOTP}
        email={email}
        history={history}
        colourizationContext={colourizationContext}
        previewSupervisorDetailContext={previewSupervisorDetailContext}
        verifyEmail={verifyEmail}
        width={width}
        showOTPMenu={showOTPMenu}
      />
      <InternalLoginContainer applyFilter={showOTPMenu}>
        <InternalHomeHeader
          toggleSideMenu={toogleOTPMenu}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          showLogin={false}
          width={width}
          history={history}
        />
        {width > 1100 && <OnBoardingImage src={OnBoarding} />}
        <WelcomeText>Welcome Preview Service members!</WelcomeText>
        <EnterEmailText>
          Enter your log in email to use Dulux Preview Service. We will <br />
          e-mail you an OTP verification code to login.
        </EnterEmailText>

        <InternalLoginForm
          setEmail={setEmail}
          toogleOTPMenu={toogleOTPMenu}
          verifyEmail={verifyEmail}
          verifyEmailSuccessCallback={verifyEmailSuccessCallback}
          verifyEmailFailureCallback={verifyEmailFailureCallback}
        />

        {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InternalLoginContainer>
    </>
  );
};

export default InternalLogin;
