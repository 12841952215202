import React, { useState, useContext, useEffect } from "react";
import {
  InternalLoginContainer,
  OnBoardingImage,
  WelcomeText,
  EnterEmailText,
  ErrorMessage,
} from "../../styles/InternalLoginStyles";
import OnBoarding from "../../images/onboarding.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as ConsumerContext } from "../../context/ConsumerContext";
import { ColourizationContext } from "../../context/ColourizationContext";
import { PreviewSupervisorDetailsContext } from "../../context/PreviewSupervisorDetailsContext";
import InternalLoginForm from "../../components/internal-login/InternalLoginForm";
import InternalHomeHeader from "../../components/home/HomeHeader";
import InternalLoginOtpSideBar from "../../components/internal-login/InternalLoginOtpSideBar";
import LanguageSelector from "../../language/LanguageSelector"

const UserLogin = ({ history }) => {
  const [selectedCountry, setSelectedCountry] = useState({
    value: "Singapore",
    label: "Singapore",
  });
  const [email, setEmail] = useState("");
  const [showOTPMenu, setShowOTPMenu] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [geoFlag, setGeoFlag] = useState(false);

  const { width } = useWindowDimensions();

  const { state, verifyEmail, verifyEmailOTP } = useContext(AuthContext);
  const colourizationContext = useContext(ColourizationContext);
  const previewSupervisorDetailContext = useContext(
    PreviewSupervisorDetailsContext
  );
  const { getGeocodingDetails } = useContext(
    ConsumerContext
  );

  const verifyEmailSuccessCallback = () => {};

  const verifyEmailFailureCallback = (msg) => {
    setErrorMessage(msg);
  };

  const toogleOTPMenu = () => setShowOTPMenu((previousState) => !previousState);

  const countries = ["Singapore", "Malaysia", "Indonesia", "Pakistan", "Sri Lanka", "Thailand", "Vietnam"]

  const getGeocodingSuccessCallback = (data) => {
    for(var res_index=0; res_index<data.results.length; res_index++){
        data.results[res_index].address_components.map((addr) => {
          if (geoFlag === false && addr.types.includes("country") && addr.types.includes("political")) {
            var result = countries.filter(
              (x) => x.toLowerCase() === addr.long_name.toLowerCase()
            );
            if (result.length > 0) {
              setSelectedCountry({
                value: addr.long_name,
                label: addr.long_name,
              });
            }
            setGeoFlag(true);
          }
          return true;
        });
    }
  };

  const getGeocodingFailureCallback = () => {
    setSelectedCountry({
      value: "Singapore",
      label: "Singapore",
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        getGeocodingDetails({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          getGeocodingSuccessCallback,
          getGeocodingFailureCallback,
        });
      });
    } else {
      setSelectedCountry({
        value: "Singapore",
        label: "Singapore",
      });
    }
  };
  
  useEffect(() => {
    if(geoFlag === false){
      getLocation();
    }
    if(navigator.cookieEnabled) {
      console.log("Set local")
      localStorage.setItem('country', JSON.stringify({
        value: "Singapore",
        label: "Singapore",}));
    }
    else{
      alert("Enable 3rd party cookies to use this site.");
    }
  }, []);

  return (
    <>
      <InternalLoginOtpSideBar
        toogleOTPMenu={toogleOTPMenu}
        state={state}
        verifyEmailOTP={verifyEmailOTP}
        email={email}
        history={history}
        colourizationContext={colourizationContext}
        previewSupervisorDetailContext={previewSupervisorDetailContext}
        verifyEmail={verifyEmail}
        width={width}
        showOTPMenu={showOTPMenu}
        selectedCountry={selectedCountry}
      />
      <InternalLoginContainer applyFilter={showOTPMenu}>
        <InternalHomeHeader
          toggleSideMenu={toogleOTPMenu}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          showLogin={false}
          width={width}
          history={history}
        />
        {width > 1100 && <OnBoardingImage src={OnBoarding} />}
        <WelcomeText><LanguageSelector selectedCountry={selectedCountry} text={"WELCOME_BACK"} /></WelcomeText>
        <EnterEmailText>
        <LanguageSelector selectedCountry={selectedCountry} text={"ENTER_THE_EMAIL"} />
        </EnterEmailText>

        <InternalLoginForm
          setEmail={setEmail}
          toogleOTPMenu={toogleOTPMenu}
          verifyEmail={verifyEmail}
          verifyEmailSuccessCallback={verifyEmailSuccessCallback}
          verifyEmailFailureCallback={verifyEmailFailureCallback}
          selectedCountry={selectedCountry}
        />

        {errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InternalLoginContainer>
    </>
  );
};

export default UserLogin;
