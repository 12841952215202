import React from "react";
import {
  BackImage,
  BackText,
  ConsumerDetailBackRow,
  BackDiv,
  ResultsText,
  RecolourButton,
  DownloadButton,
} from "../../styles/ConsumerDetailReportStyles";
import LEFT_ARROW_IMAGE from "../../images/left_arrow_image.svg";
import LanguageSelector from "../../language/LanguageSelector"

const ConsumerDetailReportBack = ({
  goBackHandler,
  state,
  recolourAnotherHandler,
  width,
  downImageHandler,
  selectedCountry,
  downloadReportClickHandler
}) => {
  if (width < 1100) {
    return (
      <ConsumerDetailBackRow>
        <ResultsText>
          <LanguageSelector selectedCountry={selectedCountry} text={"HERE_ARE_YOUR_RESULT"} />
          {`${state.data?.result?.request_details?.request_id}`}
        </ResultsText>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RecolourButton onClick={recolourAnotherHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"RE_COLOUR_ANOTHER"} />
          </RecolourButton>
          <DownloadButton onClick={downloadReportClickHandler}>
            <LanguageSelector selectedCountry={selectedCountry} text={"DOWNLOAD"} />
          </DownloadButton>
        </div>
      </ConsumerDetailBackRow>
    );
  } else {
    return (
      <ConsumerDetailBackRow>
        <BackDiv>
          <BackImage src={LEFT_ARROW_IMAGE} onClick={goBackHandler} />
          <BackText><LanguageSelector selectedCountry={selectedCountry} text={"BACK"} /></BackText>
          <ResultsText>
            <div style={{display: "flex", flexWrap: "wrap"}}>
            <LanguageSelector selectedCountry={selectedCountry} text={"HERE_ARE_YOUR_RESULT"} />
             <div style={{marginLeft: "5px"}}>
             {`${state.data?.result?.request_details?.request_id}`}
             </div>
            </div>
          </ResultsText>
        </BackDiv>
        <RecolourButton onClick={recolourAnotherHandler}>
        <LanguageSelector selectedCountry={selectedCountry} text={"RE_COLOUR_ANOTHER"} />
        </RecolourButton>
      </ConsumerDetailBackRow>
    );
  }
};

export default ConsumerDetailReportBack;
